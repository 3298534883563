import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Plus, Minus } from "phosphor-react";
import {
  _l,
  checklistItem,
  showError,
  utf8CompareString,
} from "../../hooks/utilities";
import { DropdownItem, UncontrolledDropdown } from "reactstrap";
import Button from "react-bootstrap/Button";
import CustomerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { updateFloorSetting } from "../../actions/benchmark-actions/benchmark-actions";

const AddNewTypeOfSpace = ({
  floor,
  setTypeOfSpaceArray,
  show,
  setShow,
  checklists,
  setChecklists,
  typeOfSpaceArray,
}) => {
  const dispatch = useDispatch();

  const [typeOfSpaceData, setTypeOfSpaceData] = useState({
    title: "",
    quantity: 1,
    id: 0,
  });

  const selectedOffice = localStorage.getItem("selectedOffice");
  const { floorDetails } = useSelector(
    (state) => state.benchmarkReducer
  );

  const appendBlankChecklistItemsToNewSpace = (spaces) => {
    let currentChecklistItems = checklists.slice();
    spaces.forEach((space) => {
      space.details.forEach((spaceItem) => {
        if (
          spaceItem.floor_id == floor.id &&
          !currentChecklistItems.find(
            (w) =>
              w.description == "" &&
              w.type_of_space_item_id == spaceItem.id &&
              w.floor_id == floor.id
          )
        ) {
          currentChecklistItems.push({
            ...checklistItem,
            room_id: space.id,
            id: crypto.randomUUID(),
            type_of_space_item_id: spaceItem.id,
            description: "",
            slot: "",
          });
        }
      });
    });
    setChecklists(currentChecklistItems);
  };

  const addNewTypeOfSpace = async () => {
    if (!typeOfSpaceData.title.trim().length) {
      showError("l_invalid_space_label");
      return false;
    } else if (
      isNaN(typeOfSpaceData.quantity) ||
      Number(typeOfSpaceData.quantity) <= 0
    ) {
      showError("l_please_enter_quantity");
      return false;
    }
    try {
      const {type_of_space} = floorDetails;
      let previousQuantity = 0;
      const currentSpace = type_of_space.find((w) => w.title.trim() == typeOfSpaceData.title.trim());

      if(currentSpace)
      {
        previousQuantity = Number(currentSpace.quantity);
      }

      const response = await CustomerServices.addFloorItem(
        selectedOffice,
        typeOfSpaceData.quantity,
        [typeOfSpaceData.title],
        "type_of_space",
        floor.id,
        previousQuantity
      );
      if (response.status) {
        dispatch(updateFloorSetting(floor, response.data.type_of_space));
        const newAddedSpace = response.data.type_of_space.find((w) =>
          utf8CompareString(w.title.trim(), typeOfSpaceData.title.trim())
        );
        if (newAddedSpace) {
          let updatedSpaceArray = typeOfSpaceArray.slice();
          const existingSpaceIndex = updatedSpaceArray.findIndex((w) => w.title && newAddedSpace.title && w.title.trim() === newAddedSpace.title.trim());
          if(existingSpaceIndex > -1)
          {
            updatedSpaceArray[existingSpaceIndex] = newAddedSpace;
            setTypeOfSpaceArray(updatedSpaceArray);
          }
          else {
            setTypeOfSpaceArray(typeOfSpaceArray.concat(newAddedSpace));
          }
          appendBlankChecklistItemsToNewSpace(response.data.type_of_space);
          setTypeOfSpaceData({ ...typeOfSpaceData, quantity: 1, title: "" });
        } else {
          showError("l_something_went_wrong");
          console.error("Not able to find the space from response");
        }
        setShow(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const addEditQuantity = (quantity) => {
    if (quantity < 0 && typeOfSpaceData.quantity <= 1) {
      return;
    }
    setTypeOfSpaceData({
      ...typeOfSpaceData,
      quantity: quantity + Number(typeOfSpaceData.quantity),
    });
  };

  useEffect(() => {
    return () => {
      setTypeOfSpaceData({ ...typeOfSpaceData, quantity: 1, title: "" });
    };
  }, []);

  return (
    <>
      <UncontrolledDropdown
        isOpen={show}
        container="body"
        className="dropdown-menu w-350 p-10"
      >
        <DropdownItem tag="div" className="p-0 radius_3 no-hover">
          <div className="row g-2">
            <div className="col-xl-6 flex-grow-1">
              <Form.Label className="input-label no-asterisk mb-2">
                {_l("l_floor_system_generated_title")}
              </Form.Label>
              <Form.Control
                className="bg-transparent light-theme-white-bg"
                placeholder={_l("l_floor_system_generated_title")}
                type="text"
                value={floor.title}
                disabled={true}
              />
            </div>
          </div>
          <div className="row pt-15">
            <Form.Label className="input-label no-asterisk mb-2">
              {_l("l_space_name")}
            </Form.Label>
            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
              <div className="d-flex align-items-center justify-content-between w-100 ">
                <div className="d-flex align-items-center justify-content-between form-control border-0 flex-grow-1 lightthemebg">
                  <div className="with_separator_10 flex-grow-1 ps-0">
                    <input
                      type="text"
                      className="bg-transparent border-0 "
                      placeholder={_l("l_meeting_room")}
                      value={typeOfSpaceData.title}
                      onChange={(e) => {
                        setTypeOfSpaceData({
                          ...typeOfSpaceData,
                          title: e.target.value,
                        });
                      }}
                      onKeyUp={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between with_separator_10 pe-0 w-30">
                    <a href="#/"
                      className={`c-font f-22 line-height-0 ${
                        typeOfSpaceData.quantity <= 1 ? "for-disabled" : ""
                      }`}
                      onClick={() => {
                        addEditQuantity(-1);
                      }}
                    >
                      <Minus size={18} className="c-icons" weight="light" />
                    </a>
                    <input
                      type="number"
                      className="bg-transparent border-0 text-center "
                      name=""
                      value={typeOfSpaceData.quantity}
                      onChange={(e) => {
                        setTypeOfSpaceData({
                          ...typeOfSpaceData,
                          quantity:
                            Number(e.target.value) < 0
                              ? Math.abs(e.target.value)
                              : e.target.value,
                        });
                      }}
                    />
                    <a href="#/"
                      className="c-font f-22 line-height-0"
                      onClick={() => {
                        addEditQuantity(1);
                      }}
                    >
                      <Plus size={16} className="c-icons" weight="light" />
                    </a>
                  </div>
                </div>
              </div>
            </Form.Group>
          </div>
        </DropdownItem>
        <DropdownItem tag="div" className="p-0 radius_3 no-hover">
          <div className="border-0 g-2 row justify-content-end border-top pt-1">
            <div className="col-xl-6">
              <Button
                className="w-100 border w-100 h-100 text-center lightthemebtnborder"
                variant="secondary"
                size="sm"
                onClick={() => {
                  setShow(false);
                  setTypeOfSpaceData({ ...typeOfSpaceData, quantity: 1, title: "" });
                }}
              >
                {_l("l_close")}
              </Button>
            </div>
            <div className="col-xl-6">
              <Button
                className="w-100"
                variant="primary"
                size="sm"
                onClick={addNewTypeOfSpace}
              >
                {_l("l_add")}{" "}
              </Button>
            </div>
          </div>
        </DropdownItem>
      </UncontrolledDropdown>
    </>
  );
};

export default AddNewTypeOfSpace;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChannelCard from "./ChannelCard";
import ViewChannelCard from "./ViewChannelCard";
import { _l, accessPremiumFeature } from "../../hooks/utilities";
import { Plus } from "phosphor-react";

import $ from "jquery";
import {
  ATTACH_BASE_URL,
  USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";

const LeftChannelList = ({
  groupList,
  changeGroupId,
  assignedAM,
  viewGroupList,
  openViewGroup,
  isVirtualSpace,
  groupDetails,
  setShow,
  setViewGroupDetails,
  setSearchStringLeftChannel,
  searchStringLeftChannel,
  clearRightBoardsearch
}) => {
  const {
    openGeneralChatGroup
  } = useSelector((state) => state.chat);
  const {
    selectedProject,selectedUserForChat
  } = useSelector((state) => state.customer);
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [filterByRoleArr, setFilterByRoleArr] = useState([]);
  const [filterByRoleKey, setFilterByRoleKey] = useState("");
  let userType = localStorage.getItem("user_type");
  const isLiteVersionChat = localStorage.getItem("generalChatForLiteVersion");
  useEffect(() => {
    setSearchStringLeftChannel("");
    if(openGeneralChatGroup == 1){
      groupList && groupList.map((item) => {
        if(item.type == 4){
          setTimeout(() => {
            changeGroupId(item.groupId, item.otherUserId, item.type);
          }, 700);
        }
      });
    }
  }, [groupList]);

  const setFilterByRole = (roleKey = "") => {
    if (roleKey == "clear" || filterByRoleKey == roleKey) {
      setFilterByRoleKey("");
      setFilterByRoleArr([]);
    } else {
      var roleArr = [];
      if (roleKey == "myr_staff") {
        roleArr = [
          "Account Executive",
          "Account Manager",
          "Technical Manager",
          "Myr Support",
          "Myr Staff"
        ];
      } else if (roleKey == "provider") {
        roleArr = ["Operator Admin", "Operator Manager", "Operator Employee"];
      } else if (roleKey == "tenants") {
        roleArr = ["Customer Admin", "Customer Manager", "Customer Employee"];
      }
      setFilterByRoleKey(roleKey);
      setFilterByRoleArr(roleArr);
    }
  };
  return (
    <div className={`left-content-part w-300 h-100 border-right res-d-none-imp p-0 ${(selectedUserForChat && Object.keys(selectedUserForChat).length > 0 && isLiteVersionChat == "false") || (selectedUserForChat && selectedUserForChat.generalCaht == "false") ? "d-none" : ""}`}>
      <div className="d-flex align-items-center border-bottom">
        <div className="comment-input-box p-2 flex-grow-1">
          <input
            type="text"
            className="form-control  base-body-color with-base-bg border-0"
            placeholder={`${_l("l_search")}`}
            data-bs-toggle="tooltip"
            value={searchStringLeftChannel}
            onChange={(e) => {setSearchStringLeftChannel(e.target.value)}}
          />
        </div>
      </div>
      {showFilterArea ? (
        <div className="d-flex align-items-center border-bottom">
          <li className="three-row-btn p-2 w-100">
            <div className="d-flex align-items-center p-1 three-row-btn-bg w-100">
              <label
                className={`row-btn-label ${filterByRoleKey == "myr_staff" ? "active-language" : ""}`}
              >
                <input
                  type="radio"
                  name="type-selet-radio"
                  class="hidden-input"
                  value={filterByRoleKey == "myr_staff" ? true : false}
                  checked={filterByRoleKey == "myr_staff" ? true : false}
                  onClick={() => { setFilterByRole("myr_staff"); }}
                />
                <div className="row-btn-box c-font f-12 p-1">
                {_l("l_support_myrai")}
                </div>
              </label>
              <label
                className={`row-btn-label ${filterByRoleKey == "provider" ? "active-language" : ""}`}
              >
                <input
                  type="radio"
                  name="type-selet-radio"
                  class="hidden-input"
                  value={filterByRoleKey == "provider" ? true : false}
                  checked={filterByRoleKey == "provider" ? true : false}
                  onClick={() => { setFilterByRole("provider"); }}
                />
                <div className="row-btn-box c-font f-12 p-1">{_l("l_provider")}</div>
              </label>
              <label
                className={`row-btn-label ${filterByRoleKey == "tenants" ? "active-language" : ""}`}
              >
                <input
                  type="radio"
                  name="type-selet-radio"
                  class="hidden-input"
                  value={filterByRoleKey == "tenants" ? true : false}
                  checked={filterByRoleKey == "tenants" ? true : false}
                  onClick={() => { setFilterByRole("tenants"); }}
                />
                <div className="row-btn-box c-font f-12 p-1">{_l("l_tenants")}</div>
              </label>
            </div>
          </li>
        </div>
      ) : (
        <></>
      )}

      <div className="comman_vertical_btn_h scroll_110px">
        <div className="comman-content-scroll-wrapper">
          
          <ul className="comman-verticle-tab">
          {userType == "staff" && !isVirtualSpace && selectedUserForChat == undefined && isLiteVersionChat == "false"  && localStorage.getItem("isLiteVersion") == "false"  ? !$.isEmptyObject(assignedAM) ? (
              <>
                <li className="comman-list p-0 custom-modal-section-sticky">
                  <a href="#/" className="w-100 position-relative tab_link  left-chat ">
                    <div className="d-flex">
                      <div className="c-list-icon">
                        <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                            style={{
                              backgroundImage: `url('${
                                assignedAM.displayPicture != ""
                                  ? ATTACH_BASE_URL + assignedAM.displayPicture
                                  : USR_IMG_PLACEHOLDER
                              }')`,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                        <div className="top-part pb-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="chat-u-name text-truncate">
                              {assignedAM.fname + " " + assignedAM.lname}
                            </div>
                          </div>
                        </div>
                        <div className="bottom-part color-white-60">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="user-type">
                              <div className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light me-1">
                                <span className="color-white">
                                  {assignedAM.myrUserType2}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </>
            ) : (
              <div className="comman-placeholder-wrapper w-100">
                <div className="d-flex flex-column justify-content-center h-100 align-items-center m-auto">
                  <span className="color-white-80 py-2 title-fonts">
                  {_l("l_account_manager_not_found")}
                  </span>
                </div>
              </div>
            )
            : <></>
            }
            <li className="p-0 custom-modal-section-sticky radius_3">
              <a href="#/" className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 d-flex justify-content-between">
                <span className="title-fonts">
                  <div className="d-flex align-item-center">
                  {_l("l_users")} 
                    <span className="ms-2 opacity-0 spinner-border spinner-border-sm"></span>
                  </div>
                </span>
                <div
                  className="d-flex z-index-2 justify-content-end on-hover-active-toggle-img light-theme-img "
                  onClick={() => {
                    accessPremiumFeature(selectedProject, () => {
                      setViewGroupDetails(false);
                      setShow(true);
                    }, 0);
                  }}
                   
                   
                   
                >
                  <Plus size={18} weight="light" className="c-icons" />
                </div>
              </a>
            </li>
          </ul>
          <ul
            className="comman-content-scroll comman-verticle-tab d-flex flex-column"
            id="group_list"
          
          >
            {groupList.filter((group) =>
              filterByRoleKey != ""
                ? group.groupName
                    .toLowerCase()
                    .includes(searchStringLeftChannel.toLowerCase()) &&
                  filterByRoleArr.includes(group.myrUserType2)
                : group.groupName
                    .toLowerCase()
                    .includes(searchStringLeftChannel.toLowerCase())
            ).length > 0 ? (
              groupList
                .filter((group) =>
                  filterByRoleKey != ""
                    ? group.groupName
                        .toLowerCase()
                        .includes(searchStringLeftChannel.toLowerCase()) &&
                      filterByRoleArr.includes(group.myrUserType2)
                    : group.groupName
                        .toLowerCase()
                        .includes(searchStringLeftChannel.toLowerCase())
                )
                .map((group, index) => {
                  return (
                    <ChannelCard 
                    group={group} 
                    changeGroupId={changeGroupId} 
                    setSearchStringLeftChannel={setSearchStringLeftChannel}
                    clearRightBoardsearch={clearRightBoardsearch}
                    />
                  );
                })
            ) : (
              <CommanPlaceholder imgType="no-userfound" mainWidth="m-auto" placeholderText = {_l("l_no_user")} />
            )}
            {userType == "staff" && !isVirtualSpace && selectedUserForChat == undefined && isLiteVersionChat == "false" && localStorage.getItem("isLiteVersion") == "false" ? (
              <>
                <li className="p-0 custom-modal-section-sticky radius_3" >
                  <a href="#/" className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 ">
                    <span className="title-fonts">
                      <div className="d-flex align-item-center">
                      {_l("l_view")} 
                        <span className="ms-2 opacity-0 spinner-border spinner-border-sm"></span>
                      </div>
                    </span>
                  </a>
                </li>
                {viewGroupList && viewGroupList.length > 0 ? (
                  viewGroupList.map((group, index) => {
                    return (
                      <ViewChannelCard
                        group={group}
                        openViewGroup={openViewGroup}
                      />
                    );
                  })
                ) : (
                  <CommanPlaceholder imgType="no-userfound" placeholderText = {_l("l_no_user")} />
                )}
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LeftChannelList;

import React, { useState } from "react";
import moment from "moment";
import UploadSimpleIcon from "../../assets/icons/UploadSimpleIcon";
import EyeIcon from "../../assets/icons/EyeIcon";
import CustomerServices from "../../services/customer-services";
import Button from "react-bootstrap/Button";
import CommanCreditNotModal from "../Modals/CommanCreditNotModal";
import CommanCreditModal from "../Modals/CommanCreditModal";
import { _l } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import {
  showFullScreenLoader,
  hideFullScreenLoader,
  showMessage,
} from "../../actions/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";

const TransactionCard = ({
  transaction,
  handleShow,
  getTransacitonDetails,
  uploadInvoice,
  isManualInvoices,
  setDocumentMiniModalData
}) => {
  const [showCreditNotModal, setShowCreditNotModal] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [showCreditNoteStatus, setshowCreditNoteStatus] = useState(false);
  const [raiseAmoutToggle, setRaiseAmountToggle] = useState(true);
  const messagesStates = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const createCreditNote = (data) => {
    dispatch(showFullScreenLoader());
    CustomerServices.createCreditNote(data.id, data.amount).then((res) => {
      if (res.status) {
        dispatch(hideFullScreenLoader());
        setRaiseAmountToggle(false);
        setTimeout(function () {
          setShowCreditNotModal(false);
          window.dispatchEvent(new Event("getTransactions"));
        }, 1000);
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };
  const user_type = localStorage.getItem("user_type");
  var CheckIconGreen = require("../../assets/icons/" +
    "CheckIconGreen").default;
  var XIcon = require("../../assets/icons/" + "XIcon").default;

  const declineCreditNote = (id) => {
    dispatch(showFullScreenLoader());
    CustomerServices.declineCreditNote(id).then((res) => {
      if (res.status) {
        window.dispatchEvent(new Event("getTransactions"));
        dispatch(hideFullScreenLoader());
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const createCredit = (data) => {
    dispatch(showFullScreenLoader());
    CustomerServices.createCredit(data.id, data.amount).then((res) => {
      if (res.status) {
        window.dispatchEvent(new Event("getTransactions"));
        setShowCreditModal(false);
        dispatch(hideFullScreenLoader());
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const showSubscriptionDetails = () => {
    const subscriptionData = {
        id: transaction.subscription_id,
        hash: transaction.s_hash,
        type: "subscription",
    };
    setDocumentMiniModalData(subscriptionData, "l_subscriptions");
  };

  const openBenchmarkView = () => {
    if(user_type === "operator")
    {
      window.open(`/connect-provider?space=${transaction.project_id}&provider-type=proposal&id=${transaction.schedule_id}`, "_blank")
    }
    else {
      window.open(`/connect-provider?space=${transaction.project_id}&provider-type=benchmark&id=${transaction.schedule_id}`, "_blank")
    }
  };

  return (
    <a href="#/" className="notification-box task-list-card list-view radius_3">
      <div className="d-flex justify-content-between task-list-detail">
        <div
          className="left-notification w-auto flex-grow-1 text-truncate"
          onClick={() => {
            handleShow("mywalletdetiloffcanvas");
            getTransacitonDetails(transaction);
          }}
        >
          <div className="d-flex align-items-center">
            <div className="user-imge">
              <div
                data-bs-toggle="tooltip"
                className="comman-image-box h32w32 rounded-circle"
              >
                <div
                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                  style={{
                    backgroundImage: `url('${transaction.profile_image}')`,
                  }}
                ></div>
              </div>
            </div>
            <div className="notification-detail w100minus40 task-list-name">
              <div className="d-flex align-items-center">
                <span className="text-truncate title-fonts c-font f-12 text-truncate">
                  {transaction.title}
                </span>
                <span className="c-font color-white-60 d-block f-12 fw-normal ps-2 text-truncate">
                  [{moment(transaction.date).format("DD/MM/y")}]
                </span>
              </div>
              <div className="d-flex align-items-center pt-1">
                <div className="d-flex align-content-center body-fonts c-font color-white-60 f-10 text-truncate">
                  <span className="d-block text-truncate">
                    {transaction.office_address}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wallet-amout-part d-flex align-itens-center">
          {transaction.credit_status == 1 &&
          user_type == "contact" &&
          !transaction.refund_id ? (
            <div className="badge badge-in-progress rounded-pill text-nowrap list-toggle-view-none">
              <span className="color-blue">{_l("l_c_requested")}</span>
            </div>
          ) : transaction.credit_status == 3 ? (
            <div className="badge badge-red rounded-pill text-nowrap list-toggle-view-none">
              <span className="color-red">{_l("l_declined")}</span>
            </div>
          ) : transaction.credit_status == 2 ? (
            <div className="badge badge-done rounded-pill text-nowrap list-toggle-view-none">
              <span className="color-green">{_l("l_success")}</span>
            </div>
          ) : transaction.credit_status == 1 && transaction.refund_id ? (
            <div className="badge badge-warning rounded-pill text-nowrap list-toggle-view-none">
              <span className="color-orange">{_l("l_inprocess")}</span>
            </div>
          ) : transaction.credit_status == 1 &&
            !transaction.refund_id &&
            user_type == "operator" ? (
            <span>
              <a href="#/"
                onClick={() => {
                  declineCreditNote(transaction.credit_note_id);
                }}
                 
                 
                 
              >
                <XIcon />
              </a>
              <a href="#/"
                onClick={() => {
                  setShowCreditModal(true);
                }}
                 
                 
                 
              >
                <CheckIconGreen />
              </a>
            </span>
          ) : (
            <></>
          )}
          {transaction.total_credit ? (
            <div className="with_separator_10 before-dnone my-auto">
              <Button
                variant="white-05"
                size="smaller"
                className=" with_separator_10 d-flex align-items-center p-1"
                onClick={() => {
                  if (
                    !transaction.total_created_credit &&
                    user_type == "contact"
                  ) {
                    setShowCreditNotModal(true);
                  }
                }}
              >
                {_l("l_credit_note")}
                <div className="ps-1 title-fonts">
                  [<span>&euro;</span>
                  <span>{transaction.total_credit}</span>]
                </div>
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div
            className="badge rounded-pill text-nowrap list-toggle-view-none me-10px h32wauto c-font f-12 d-flex align-items-center"
            onClick={showSubscriptionDetails}
          >
            <span>{_l("l_subscription")}</span>
          </div>
          {transaction.schedule_id > 0 ? (
            <div
              className="badge rounded-pill text-nowrap list-toggle-view-none me-10px h32wauto c-font f-12 d-flex align-items-center"
              onClick={openBenchmarkView}
            >
              <span>{_l("l_benchmark")}</span>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex align-content-center">
            {transaction.tags && transaction.tags.length ? (
              transaction.tags.map((tag) => {
                if (["invoice", "Cleaning"].includes(tag)) return;
                return (
                  <div className="badge rounded-pill text-nowrap list-toggle-view-none me-10px h32wauto c-font f-12 d-flex align-items-center">
                    <span>{_l(`${tag}`)}</span>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
          <div class="progress-wrapper-link pt-1 d-flex justify-content-center with_separator_10 before-dnone ps-0">
            <div class="progress-slider flex-grow-1">
              <div class="d-flex align-items-center">
                <div class="progress w_100">
                  <div
                    class="progress-bar"
                    style={{ width: transaction.task_progress + `%` }}
                  ></div>
                </div>
                <div class="progress-text c-font f-12 title-fonts">
                  {transaction.task_progress}%
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-content-center">
            <div className="comman_action_icon card-view-less-padding d-flex">
              <span className="badge with-min-max-width c-text normal_pill px-2 radius_3 text-truncate green-text fw-semibold d-flex align-items-center c-font f-12">
                {transaction.amount}
              </span>
            </div>
          </div>
        </div>
        {isManualInvoices && transaction.transaction_type == "invoice" ? (
          !transaction.file_url ? (
            <div className="comman_action_icon with_separator_10">
              <div className="d-flex align-items-center">
                <a href="#/" className="action_icon with_bg">
                  <input
                    type="file"
                    className="absolute-input"
                    onChange={(event) => {
                      uploadInvoice(event, transaction.id);
                    }}
                  />
                  <UploadSimpleIcon className="HW18" />
                </a>
              </div>
            </div>
          ) : (
            <div className="comman_action_icon with_separator_10">
              <div className="d-flex align-items-center">
                <a
                  className="action_icon with_bg"
                  target={"_blank"}
                  href={transaction.file_view}
                >
                  <EyeIcon className="HW18" />
                </a>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      <CommanCreditNotModal
        show={showCreditNotModal}
        handleClose={() => {
          setShowCreditNotModal(false);
          setshowCreditNoteStatus(true);
        }}
        handleSubmit={(data) => {
          createCreditNote(data);
        }}
        data={transaction}
        raiseToggle={raiseAmoutToggle}
      />
      <CommanCreditModal
        show={showCreditModal}
        handleClose={() => {
          setShowCreditModal(false);
        }}
        handleSubmit={(data) => {
          createCredit(data);
        }}
        data={transaction}
        raiseToggle={true}
      />
      {messagesStates && messagesStates.fullScreenLoader ? (
        <FullScreenLoader />
      ) : (
        <></>
      )}
    </a>
  );
};

export default TransactionCard;

import React, {  } from "react";
import { UserList, Files, FileText, CalendarBlank, CaretRight, Receipt, Buildings,BezierCurve } from "phosphor-react";
import { _l, accessPremiumFeature } from "../../hooks/utilities";
import { useNavigate } from "react-router-dom";
import AddScheduleIcon from "../../assets/icons/AddScheduleIcon";
import CreateBenchmarkIcon from "../../assets/icons/CreateBenchmarkIcon";
import { useDispatch } from "react-redux";
import { accordionsEventKey, setSelectedGloablView } from "../../actions/customer";

const SettingMenu = ({preEstiPageDisable , removeVirtualSpace, userType, setSettingTopicName, setTopicMainKey, closeModal = ()=>{} }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const viewPage = window.location.pathname;
    
    const components = {
        CalendarBlank,
        Receipt,
        FileText,
        UserList,
        Files,
        Buildings,
        BezierCurve,
        AddScheduleIcon,
        CreateBenchmarkIcon,
    };
    const SettingMenuArray = userType === "staff" ? [
        {
            title: _l("l_calendar"),
            subtitle: _l("l_upload_to_complete_anything"),
            type: "CalendarBlank",
            handler: () => {
                closeModal();
                navigate('/full_calendar');
            },
            active : true 

        },
        {
            title: _l("l_invoice"),
            subtitle: _l("l_add_a_flame_for_urgent_things"),
            type: "Receipt",
            handler: () => {
                closeModal();
                navigate("/invoice");
            },
            active : true 
        },
        {
            title: _l("l_document"),
            subtitle: _l("l_convert_anything_into_a_task"),
            type: "FileText",
            handler: () => {
                closeModal();
            },
            active : true 
        },
        {
            title: _l("l_proposals"),
            subtitle: _l("l_make_you_remind_anything_important"),
            type: "FileText",
            handler: () => {
                closeModal();
                navigate("/proposals", { state: { from: viewPage } });
            },
            active : userType == "operator" || userType == "staff" ? true : false
        },
        {
            title: _l("l_clients"),
            subtitle: _l("l_assign_anything_to_anyone"),
            type: "UserList",
            handler: () => {
                closeModal();
                navigate("/clients");
            },
            active : true 
        },
        {
            title: _l("l_estimate"),
            subtitle: _l("l_anything_invoice_estimate_etc"),
            type: "FileText",
            handler: () => {
                closeModal();
                navigate("/estimate");
            },
            active : true 
        },
        {
            title: _l("l_tasks"),
            subtitle: _l("l_anything_topic_User_space_etc"),
            type: "FileText",
            handler: () => {
                closeModal();
                navigate("/tasks");
            },
            active : true 
        },
        {
            title: _l("l_subscriptions"),
            subtitle: _l("l_choose_a_date_due_or_start_date"),
            type: "FileText",
            handler: () => {
                closeModal();
                navigate("/subscription");
            },
            active : true 
        },
        {
            title: _l("l_schedule"),
            subtitle: _l("l_someone_for_notify_him"),
            type: "AddScheduleIcon",
            handler: () => {
                closeModal();
                navigate("/schedule");
            },
            active : true 
        },
        {
            title: _l("l_credit_note"),
            subtitle: _l("l_relate_to_anything"),
            type: "FileText",
            handler: () => {
                closeModal();
                navigate("/credit_notes");
            },
            active : true 
        },
        {
            title: _l("l_benchmark"),
            subtitle: _l("l_someone_for_notify_him"),
            type: "CreateBenchmarkIcon",
            handler: () => {
                closeModal();
                navigate("/benchmark");
            },
            active : true 
        },
        {
            title: _l("l_reports"),
            subtitle: _l("l_relate_to_anything"),
            type: "Files",
            handler: () => {
                closeModal();
            },
            active : true 
        },
    ] : 
    [
        {
            title: _l("l_company_profile"),
            subtitle: _l("l_upload_to_complete_anything"),
            type: "Buildings",
            handler: () => {
                closeModal();
                accessPremiumFeature(0, () => {
                    // navigate("/companyprofile");
                    setSettingTopicName("l_company_profile");
                    setTopicMainKey("company-profile");
                  }, true);
            },
            active : true 

        },
        {
            title: _l("l_third_party_integration"),
            subtitle: _l("l_third_party_integration_description"),
            type: "BezierCurve",
            handler: () => {
                closeModal();
                setSettingTopicName("l_third_party_integration");
                setTopicMainKey("third_party_integrations");
            },
            active : true 
        },
    ];
    const SettingMenuRight = [
        {
            title: _l("l_staff"),
            handler: () => {
                closeModal();
                navigate("/staff");
            },
        },
        {
            title: _l("l_email_templates"),
            handler: () => {
                closeModal();
            },
        },
        {
            title: _l("l_user_journey_management"),
            handler: () => {
                closeModal();
            },
        },
        {
            title: _l("l_roles"),
            handler: () => {
                closeModal();
            },
        },
        {
            title: _l("l_leads_settings"),
            handler: () => {
                closeModal();
                navigate("/leads");
                setTimeout(() => {
                    dispatch(accordionsEventKey(false));
                }, 300);
                
                localStorage.setItem("fav_dashboard", "my_tasks")
                dispatch(setSelectedGloablView("liteVersionView"))
            },
        },
        {
            title: _l("l_general_settings"),
            handler: () => {
                closeModal();
            },
        },
        {
            title: _l("l_equipment"),
            handler: () => {
                closeModal();
                navigate("/equipmentListing");
            },
        },
        {
            title: _l("l_company_profile"),
            handler: () => {
                closeModal();
                if (preEstiPageDisable == true) {
                    return;
                  }
                  removeVirtualSpace(1);
                  navigate("/companyprofile");
                //   onPagechangeToggle();
            },
        },
    ];
    const FaIconDynamicNew = ({ type }) => {
        const FaIcon = components[type];
        return <FaIcon size={18} className="m-auto" weight={`${type == "Circle" ? "fill" : "light"}`} />
    }
    return (
        <>
            <div className={`setting-menu-main ${userType == "staff" ? "" :"with-half-width"}`}>
                <div className="h-100 setting-menu-body">
                    <div className="setting-menu-left w100minus100per flex-grow-1 pe-4">
                        <div className="row comman-verticle-tab m-0">
                            {SettingMenuArray && SettingMenuArray.length > 0 && SettingMenuArray.map((settingList, index) => {
                                return (
                                    <a href="#/" key={index} className={`comman-list with-after-60 second-last-child-none p-0 px-3 ${userType == "staff" ? "col-xl-6" :""}  ${settingList.active ? "" : "d-none"}`} onClick={settingList.handler}>
                                        <div className="topic-main-box">
                                            <div className="d-flex card-list-top on-hover-active-toggle-img text-on-hover-active-green py-3">
                                                <div className="comman_action_icon me-3">
                                                    <div className="h30w30  with-bg d-flex bg-white-05 p-5px box-shadow-2 on-hover-active-toggle-img radius_3">
                                                        <FaIconDynamicNew className="m-auto c-icons" type={settingList.type} />
                                                    </div>
                                                </div>
                                                <div className="card-list-heading text-truncate">
                                                    <div className="title-fonts c-font f-14  text-truncate">{settingList.title}</div>
                                                    <div className="c-font color-white-60 f-12 text-truncate">
                                                        <span className="hover-span">{settingList.subtitle}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                    {
                        userType === "staff"
                        ?
                        <div className="setting-menu-right pe-2">
                            <div className="title-fonts c-font f-14  text-truncate p-3">{_l("l_settings")}</div>
                            <ul className="h_50 overflow-auto ps-1">
                                {SettingMenuRight.length > 0 && SettingMenuRight.map((settingRightBox, index) => {
                                    return (
                                        <li key={index} className={`p-2 ${index == 0 ? "pt-0" : ""}`}>
                                            <a href="#/" className="d-flex card-list-top on-hover-active-toggle-img text-on-hover-active-green"onClick={settingRightBox.handler}>
                                                <div className="comman_action_icon me-2">
                                                    <CaretRight size={18} weight="light" className="c-icons" />
                                                </div>
                                                <div className="card-list-heading text-truncate">
                                                    <div className="c-font color-white-80 f-12 text-truncate hover-span">{settingRightBox.title}</div>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
            
        </>
    );
};
export default SettingMenu;
import React, { useEffect, useState } from "react";
import { leftPanelMouseUpEvent, removeExpandedformIntro } from "../hooks/utilities";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import { useDispatch } from "react-redux";
import AddTenants from "../components/ExploreFeatures/Tenants/AddTenants";

const Tenants = () => {

  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(localStorage.getItem("spaces")) : [];
 
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("mouseup", leftPanelMouseUpEvent);
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );
    return () => {
      document.removeEventListener("mouseup", leftPanelMouseUpEvent);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex flex-column h-100">
        <AddTenants />
      </div>
    </React.Fragment>
  );
};

export default Tenants;

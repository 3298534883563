import React from 'react';
 

function PushPinIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M5.89062 10.5L3.39062 13M10.0344 1.8562L6.14065 5.74995C6.14065 5.74995 4.4094 4.8812 2.55315 6.3812C2.49853 6.4254 2.45383 6.48061 2.42199 6.54325C2.39014 6.60588 2.37185 6.67452 2.36831 6.7447C2.36477 6.81487 2.37606 6.88501 2.40145 6.95052C2.42684 7.01604 2.46575 7.07547 2.51565 7.12495L9.25315 13.8624C9.30358 13.9138 9.3645 13.9536 9.43173 13.9792C9.49897 14.0048 9.57094 14.0156 9.64274 14.0109C9.71453 14.0061 9.78446 13.9859 9.84774 13.9517C9.91102 13.9175 9.96616 13.87 10.0094 13.8124C10.5344 13.1187 11.3594 11.6812 10.6406 10.2499L14.5344 6.35619C14.5816 6.30967 14.6192 6.25421 14.6448 6.19305C14.6704 6.13189 14.6836 6.06625 14.6836 5.99995C14.6836 5.93364 14.6704 5.868 14.6448 5.80684C14.6192 5.74568 14.5816 5.69022 14.5344 5.6437L10.7469 1.8562C10.7004 1.80895 10.6449 1.77144 10.5838 1.74583C10.5226 1.72022 10.457 1.70703 10.3906 1.70703C10.3243 1.70703 10.2587 1.72022 10.1975 1.74583C10.1364 1.77144 10.0809 1.80895 10.0344 1.8562Z" fill="none" />
			</svg>
		</React.Fragment>
	)
}
export default PushPinIcon

import React from "react";
import { _l } from "../../hooks/utilities";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "phosphor-react";

const OnboardingFooter = ({
  nextButtonText,
  backButtonText,
  skipButtonText,
  backBtnHandler,
  nextBtnHandler,
  skipButtonHandler,
  currentStep,
  totalSteps,
  hideBackButton,
  loadingNextStep,
  loader,
  hideNextButton = false,
  hideSkipButton = true,
}) => {

  return (
    <>
      <div className={`onboarding-footer p-15 position-sticky-bottom mt-auto`}>
        <div className="d-flex align-items-start justify-content-between">
          <div className="min-width-125">
            {backButtonText && !hideBackButton ? (
              <a href="#/"
                className="border-0 btn c-font color-white f-15 title-fonts btn-sm d-none"
                onClick={backBtnHandler}
              >
                <ArrowLeft size={18} weight="light" className="c-icons" />
                <span className="ms-2">{_l(backButtonText)}</span>
              </a>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
          {nextButtonText ? (
            <>
              {currentStep ? (
                <div className="d-flex align-items-center justify-content-center onboarding-footer-tab m-auto">
                  {Array.from({ length: totalSteps }).map((step, index) => {
                    return (
                      <span
                        key={index}
                        className={`footer-tab ${
                          currentStep == index + 1 ? "active" : ""
                        }`}
                      ></span>
                    );
                  })}
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
             {hideNextButton ? <></> : <div className="d-flex right-side-button ms-auto">
              <Button
                disabled={loadingNextStep || loader}
                variant={"primary btn-sm"}
                className={`text-nowrap ${!backButtonText ? "mx-auto d-none" : "d-none"}`}
                onClick={nextBtnHandler}
                id="footer-primary-button"
              >
                {loadingNextStep || loader ? (
                 (
                  <React.Fragment>
                    {_l("l_please_wait")}
                  </React.Fragment>
                )
                ) : (
                  <React.Fragment>
                    {loadingNextStep || loader ? _l('l_please_wait') : _l(nextButtonText)}
                  </React.Fragment>
                )}
              </Button>
              </div>}
            </>
          ) : (
            <React.Fragment></React.Fragment>
          )}
            {/* {skipButtonText || !hideSkipButton ? (
              <Button variant="secondary btn-sm"
                className={`c-font color-white-60 f-15 title-fonts ms-2`} 
                onClick={skipButtonHandler}
              >
                <span className="">{_l(skipButtonText)}</span>
              </Button>
            ) : (
              <React.Fragment></React.Fragment>
            )} */}
        </div>
      </div>
    </>
  );
};

export default OnboardingFooter;

import React, { useEffect, useState } from "react";
import { _l, fetchProviderListFromIDB, showError } from "../../hooks/utilities";
import { ChatCircle, DotsNine } from "phosphor-react";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { useDispatch, useSelector } from "react-redux";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import customerServices from "../../services/customer-services";
import { getMentionList, getProviderList, getProviderListTableData, leftPanelFilters, setLeftPanelTab, setProvideClientList, updateProviderList } from "../../actions/customer";
import CommanLoader from "../Loader/CommanLoader";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ProviderListActionModal from "./ProviderListActionModal";
 
import GeneralListing from "../TaskModalComponents/GeneralListing";
import CommentMinified from "../Comments/CommentMinified";
import documentsServices from "../../services/documents-services";
import ImageViewer from "../Offcanvas/ImageViewer";

let STAUSTAB = [
    {
        title: "l_my_providers",
        color: "in-progress-white",
        name: "MyProviders",
    },
    {
        title: "l_active",
        color: "done",
        name: "Active",
    },
    {
        title: "l_invited",
        color: "bg-warning",
        name: "Invited",
    },
    {
        title: "l_past",
        color: "in-progress-blue",
        name: "Past",
    },
];

let showComments = false;

const ProviderList = () => {
    const dispatch = useDispatch();
    const { providerList, taskFilters,globalSearchString, providerListDataLoading, leftPanelTab, agentList,clientList, defaultTopics, companyEmployeeData, filterProjectID, spaces } = useSelector((state) => state.customer);
    const mentionsList = useSelector((state) => state.customer.mentionList);

    const [selectedTab, setSelectedTab] = useState("Active");
    const [showCommanoffCanvasModal, setShowCommanoffCanvasModal] = useState(false);
    const [providerListLocal, setProviderListLocal] = useState([]);
    const [counts, setCounts] = useState({MyProviders :0, Active: 0, Invited: 0, Past: 0 });
    const [modalData, setModalData] = useState({});
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [selectedCategoryTittles, setSelectedCategoryTittles] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [providerActionModal, setproviderActionModal] = useState(-1)
    const [clientListLocalData, setClientListLocalData] = useState([])
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [commentsList, setCommentsList] = useState([]);
    const [selectChatUserId, setSelectChatUserId] = useState(-1)
    const [replyCommentData, setReplyCommentData] = useState({});
    const [fileUploadLoader, setFileUploadLoader] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [attachments, setAttachments] = useState({
        file_array: [],
        date: "",
        currentIndex: 0,
      });
    const userType = localStorage.getItem('user_type');
    const contact_id = localStorage.getItem("contact_id");
    const staffId = localStorage.getItem("staff_id");

    const getCompanyDetails = (user, tab_name) => {
        setShowCommanoffCanvasModal(true)
        try {
            customerServices.getSelectedProviderDetails( user.userid, tab_name).then((res)=>{
                if (res && res.status) {
                    setModalData({...res.data, name : user.company, id: user.userid });
                    dispatch(getProviderListTableData(res.data))
                    localStorage.setItem("currentCompanyID",user.userid);
                }else{
                    showError(res.message)
                }
            })
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (window.location.pathname == "/providerlist") {
            dispatch(setLeftPanelTab("my-providers"));
        }
        // dispatch(leftPanelFilters({
        //     filter_key : 21,
        //     main_key : "providers",
        //     main_sub_key : "providers",
        //     sub_category : [],
        //     title : "Cleaning"
        // }))
        dispatch(leftPanelFilters("","","all"));
    }, []);
  
    useEffect(() => {
        if (providerList && providerList.length && userType != "operator") {
            setProviderListLocal(providerList.filter((w) => w.is_existing == 1));
            setCounts({
                MyProviders : providerList.filter((sub) => sub.provider_assignee && sub.provider_assignee.map((item) => item.assignee_id).includes(staffId)).length,
                Active: providerList.filter((w) => w.is_existing == 1).length,
                Invited: providerList.filter((w) => w.is_existing == 2).length,
                Past: providerList.filter((w) => w.is_existing == 0).length,
            });
        }
    }, [providerList]);

    useEffect(() => {
        switch (selectedTab) {
            case "Active":
                setProviderListLocal(providerList.filter((w) => w.is_existing == 1));
                break;
            case "Invited":
                setProviderListLocal(providerList.filter((w) => w.is_existing == 2));
                break;
            case "Past":
                setProviderListLocal(providerList.filter((w) => w.is_existing == 0));
                break;
            case "MyProviders":
                setProviderListLocal(providerList.filter((sub) => sub.provider_assignee && sub.provider_assignee.map((item) => item.assignee_id).includes(staffId)));
                break;
            default:
                setProviderListLocal(providerList.filter((w) => w.is_existing == 1));
                break;
        }
    }, [selectedTab, providerList]);

    useEffect(() => {
        let ids = [];
        let names = {}
        if (defaultTopics && defaultTopics.length && filterProjectID == 0) {
            defaultTopics.find((item) => item.main_key == "providers").categories.filter((category) => Number(category.filter_key) > 0).map((f) =>{
                ids.push(f.filter_key)
                // names.push({ "id" : f.filter_key , "tittle" : f.title})
                // tittles.push({filter_key, tittle})
                names[f.filter_key] = f.title;
            })
            setSelectedCategoryIds(ids) 
            setSelectedCategoryTittles(names)
        }
    }, [defaultTopics]);

    useEffect(() => {
        if (selectedCategoryIds && selectedCategoryIds.length && userType != "operator") {
            dispatch(getProviderList(selectedCategoryIds)) 
        }else{
            fetchProviderListFromIDB(0 , "companyList", localStorage.getItem("client_id"), dispatch);
        }
    }, [selectedCategoryIds]);
    
    useEffect(() => {
        if (clientList && clientList.length && userType == "operator") {
            setClientListLocalData(clientList.filter((w) => w.is_active == 1));
            setCounts({
                Active: clientList.filter((w) => w.is_active == 1).length,
                Past: clientList.filter((w) => w.is_active == 0).length,
            });
        }
    }, [clientList]);

    useEffect(() => {
        if (userType == "operator") {
            switch (selectedTab) {
                case "Active":
                    setClientListLocalData(clientList.filter((w) => w.is_active == 1));
                    break;
                case "Past":
                    setClientListLocalData(clientList.filter((w) => w.is_active == 0));
                    break;
                default:
                    setClientListLocalData(clientList.filter((w) => w.is_active == 1));
                    break;
            }
        }
    }, [selectedTab, clientList]);


    const CategoryTittles = ({ids}) => {
        // const tittlesArr = ids.map((i) => ({
        //     id: i,
        //     name: selectedCategoryTittles[i]
        // }))
        // return (
        //     <>
        //     {tittlesArr[0].name}
        //     {
        //         tittlesArr.length > 1
        //             ? <div className="h20w20 rounded-circle with_overlay dropstart d-inline-flex ps-4">
        //                 <UncontrolledDropdown className="align-items-center d-flex dropdown justify-content-center w-100">
        //                     <DropdownToggle data-toggle="dropdown" tag="div" className="comman_action_icon">
        //                         <div className="m-auto c-font d-flex align-itmes-center justify-content-center h32w32 rounded-circle action_icon with_bg">
        //                             {`+ ${tittlesArr.length - 1}`}
        //                         </div>
        //                         <GeneralListing
        //                             unControlList={true}
        //                             list={tittlesArr.slice(1).map((category) => {
        //                                 return {
        //                                     name: category.name,
        //                                     id: category.id,
        //                                 };
        //                             })}
        //                         />
        //                     </DropdownToggle>
        //                 </UncontrolledDropdown>
        //             </div>
        //             : <></>
        //     }
        // </>
        // )
    }

    const getTaskComments = async (id) => {
        showComments = true;
        setCommentsLoading(true);
        try {
          const comments = await documentsServices.getComments(
            id,
            "provider_page"
          );
          if (comments.data) {
            setCommentsList(
              comments.data.map((comment) => {
                return {
                  id: comment.id,
                  content: comment.content,
                  contact_id: comment.contact_id,
                  staffid: comment.staffid,
                  full_name: comment.contact_full_name || comment.staff_full_name,
                  profile_url: comment.addedfrom_details.profile_url,
                  attachments: comment.attachments,
                  date: comment.time,
                  dateadded: comment.dateadded,
                  is_allowed_to_edit: comment.is_allowed_to_edit,
                  parent_comment_details: comment.parent_comment_details
                    ? comment.parent_comment_details
                    : "",
                  parent_comment_id: comment.parent_comment_id
                    ? comment.parent_comment_id
                    : 0,
                };
              })
            );
          }
        } catch (e) {
          console.error(e);
        } finally {
          setCommentsLoading(false);
        }
    };

    const getMentionlist = (id) => {
        dispatch(getMentionList(id, "provider_page"));
    };

    const handleAddEditComment = async (content, files, id) => {
        setFileUploadLoader(true);
        try {
          const response = await documentsServices.addDocumentComment(
            "provider_page",
            selectChatUserId,
            content,
            0,
            id,
            files,
            0,
            0,
            replyCommentData.id
          );
          if (id > 0) {
            const commentIndex = commentsList.findIndex((w) => w.id == id);
            setCommentsList(
              Object.values({
                ...commentsList,
                [commentIndex]: { ...commentsList[commentIndex], content },
              })
            );
          } else {
            const staffid = localStorage.getItem("staff_id");
            const profile_url = localStorage.getItem("contact_image");
            const full_name = localStorage.getItem("full_name");
            let attachments = [];
    
            if (files) {
              Array.from(files).map((file) => {
                attachments.push({
                  file_url: URL.createObjectURL(file),
                  file_name: file.name,
                });
              });
            }
    
            var addComments = {
              id: response.data.comment_id,
              content: content,
              contact_id: contact_id,
              staffid: staffid,
              full_name: full_name,
              profile_url: profile_url,
              is_allowed_to_edit: "1",
              attachments: attachments,
              date: response.data.time,
              dateadded: new Date(),
            };
            if (
              replyCommentData &&
              replyCommentData.content != "" &&
              replyCommentData.id != ""
            ) {
              addComments.parent_comment_details = {
                id: replyCommentData.id,
                content: replyCommentData.content,
                file_id: "0",
              };
              addComments.parent_comment_id = replyCommentData.id;
            } else if (
              replyCommentData.attachments &&
              replyCommentData.attachments[0].file_url != ""
            ) {
              let replyCommentDatas = replyCommentData.attachments[0];
              addComments.parent_comment_details = {
                content: "[task_attachment]",
                file_id: replyCommentDatas.file_id,
                file_name: replyCommentDatas.file_name,
                file_url: replyCommentDatas.file_url,
              };
              addComments.parent_comment_id = replyCommentData.id;
            }
            setCommentsList(commentsList.concat([addComments]));
            let updateproviderLists = providerList && providerList.map((comt) => {
                if (comt.userid == selectChatUserId) {
                    comt.comment_count = comt.comment_count ? comt.comment_count + 1 : 1 ;
                }
                return comt;
            })
            dispatch(updateProviderList(updateproviderLists));
            setFileUploadLoader(false);
          }
        } catch (e) {
          setFileUploadLoader(false);
          console.error(e);
        }
    };

    const handleImagePreview = (comment) => {
        setShowImagePreview(true);
        setAttachments({
          ...attachments,
          file_array: comment.attachments,
          currentIndex: 0,
          date: comment.dateadded,
        });
      };
    
      const closePreview = () => {
        setAttachments({ ...attachments, file_array: [], currentIndex: 0 });
        setShowImagePreview(false);
      };
    
      const changeAttachment = (direction) => {
        if (direction === "prev" && attachments.currentIndex > 0) {
          setAttachments({
            ...attachments,
            currentIndex: attachments.currentIndex--,
          });
        } else if (
          direction === "next" &&
          attachments.currentIndex <= attachments.current.file_array.length
        ) {
          setAttachments({
            ...attachments,
            currentIndex: attachments.currentIndex++,
          });
        }
    };

    const handleDeleteComment = (comment) => {
        try {
            documentsServices.removeComment(
                selectChatUserId,
                "provider_page",
                comment.id
            ).then((res) => {
                if (res.status) {
                    setCommentsList(commentsList.filter((x) => x.id != comment.id));
                    let updateproviderList = providerList && providerList.map((comt) => {
                        if (comt.userid == selectChatUserId) {
                            comt.comment_count = comt.comment_count - 1;
                        }
                        return comt;
                    })
                    dispatch(updateProviderList(updateproviderList));
                }
            })

        } catch (e) {
          console.error(e);
        }
    };
    return (
        <><div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian position-relative px20py20">
                <div className="comman-tab-wrapper  pb-20px">
                    <div className="d-flex align-items-center">
                        {STAUSTAB.filter((tab)=> ["my-clients","my-agents" ].includes(leftPanelTab)  ?  !["Invited", "MyProviders"].includes(tab.name) : tab ).map((tab) => {
                            return (
                                <div className={`tab-wrapper d-flex align-items-center ${selectedTab === tab.name ? "active" : ""}`}>
                                    <a href="#/" className="tab-name d-flex align-items-center bg-white-03-solid" onClick={() => { setSelectedTab(tab.name); }} >
                                        <span class={`c-dots  c-7 rounded-circle me-10px ${tab.color}`} ></span>
                                        <span>{_l(tab.title)}</span>
                                        <span className="ms-1">{`[${
                                            tab.name == "Active"
                                                ? counts.Active
                                                : tab.name == "Invited"
                                                    ? counts.Invited
                                                    : tab.name == "Past"
                                                        ? counts.Past
                                                        : tab.name == "MyProviders"
                                                            ? counts.MyProviders
                                                            : 0
                                        }]`}</span>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="comman_vertical_btn_h  comman-white-box-shadow light-theme-white-bg">
                    <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
                        <table className="dataTable text-center tablewithborder">
                            <thead className="comman-white-box-shadow">
                                <tr>
                                    <th style={{ width: "15%" }} className="text-start">{_l("l_company_name")}</th>
                                    {leftPanelTab != "my-clients" && <th style={{ width: "5%" }} className="text-start">{_l("l_type_of_service")}</th>}
                                    <th style={{ width: "8%" }} className="text-start">{_l("l_contact_name")}</th>
                                    {leftPanelTab != "my-clients" && <th style={{ width: "5%" }} className="text-center">{_l("l_responisble_contact")}</th>}
                                    {/* <th style={{ width: "3%" }} className="text-start">{_l("l_status")}</th> */}
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_task_score")}</th>
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_risk_score")}</th>
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_spaces")}</th>
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_action")}</th>
                                </tr>
                            </thead>
                            <tbody style={{ verticalAlign: "middle" }}>
                                {!["my-clients","my-agents" ].includes(leftPanelTab)&& providerListLocal && providerListLocal.filter((w)=> (filterProjectID > 0 && leftPanelTab == "my-providers" ? w.project_ids.includes(filterProjectID) :  w) && (w.company.toLowerCase().includes(globalSearchString.toLowerCase()) || w.fullname.toLowerCase().includes(globalSearchString.toLowerCase()))).length > 0 ?
                                <>
                                    {providerListLocal.filter((w)=> (filterProjectID > 0 && leftPanelTab == "my-providers" ? w.project_ids.includes(filterProjectID) :  w) && (w.company.toLowerCase().includes(globalSearchString.toLowerCase()) || w.fullname.toLowerCase().includes(globalSearchString.toLowerCase()))).map((user, index) => {
                                        let  setProvidersAssinee = companyEmployeeData;
                                        setProvidersAssinee = setProvidersAssinee && setProvidersAssinee.filter((sub) => user.provider_assignee && user.provider_assignee.map((item) => item.assignee_id).includes(sub.staff_id));
                                        let spacesAssignee = spaces && spaces.filter((item) =>  (user.project_ids && user.project_ids.length > 0 ? user.project_ids : []).includes(item.project_id))
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <a href="#/" className="name-with-progress max-w300 d-block" onClick={() => { getCompanyDetails(user, 'company_profile'); }} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${user.profile_image_url}')`, }}></div>
                                                            </div>
                                                            <div className="ps-2 w100minus70 text-start flex-grow-1">
                                                                <div className="d-flex flex-column">
                                                                    <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                                                                        {user.company}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                {/* <CategoryTittles ids={user.category}/> */}
                                                Cleaning
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                    {user.fullname}
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                    {/* {setProvidersAssinee && setProvidersAssinee.length > 0 ? 
                                                        setProvidersAssinee.map((assig) => {
                                                        return (
                                                                <a className="comman-image-box multi-wrapper-border h32w32 rounded-circle" href="#/"
                                                                     
                                                                     
                                                                     >
                                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: assig.profile_image ? `url(${assig.profile_image})` : `url('https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg')`}}></div>
                                                                </a>
                                                            )
                                                        }) 
                                                        : <></>} */}
                                                            <div
                                                                className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus ${
                                                                setProvidersAssinee && setProvidersAssinee.length >= 1
                                                                    ? ""
                                                                    : "d-none"
                                                                }`}
                                                            >
                                                                {setProvidersAssinee && setProvidersAssinee.length >= 1 ? (
                                                                setProvidersAssinee.map((assignee, index) => {
                                                                    if (index < 1)
                                                                    return (
                                                                        <a
                                                                        href="#/"
                                                                        data-bs-toggle="tooltip"
                                                                        className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                                                       
                                                                         
                                                                         
                                                                         
                                                                         
                                                                        key={index}
                                                                        >
                                                                        <div
                                                                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                                            style={{
                                                                            backgroundImage: `url('${
                                                                                assignee.profile_image
                                                                            }')`,
                                                                            }}
                                                                        ></div>
                                                                         
                                                                        </a>
                                                                    );
                                                                })
                                                                ) : (
                                                                <></>
                                                                )}
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="div">
                                                                        {setProvidersAssinee && setProvidersAssinee.length > 1 ? (
                                                                        <a
                                                                            href="#/"
                                                                            className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                             
                                                                             
                                                                             
                                                                             
                                                                            // onClick={handleTaskAssigneeData}
                                                                        >
                                                                            <div className="unread_count m-auto c-font f-12">
                                                                            +{setProvidersAssinee.length - 1}
                                                                            </div>
                                                                        </a>
                                                                        ) : (
                                                                        <></>
                                                                        )}
                                                                        {setProvidersAssinee && setProvidersAssinee.length ? (
                                                                        <GeneralListing
                                                                            handler={() => {}}
                                                                            list={
                                                                            setProvidersAssinee &&
                                                                            setProvidersAssinee.length &&
                                                                            setProvidersAssinee.map((item, index) => {
                                                                                return {
                                                                                name: item.full_name,
                                                                                image:
                                                                                    item.profile_image,
                                                                                id: index,
                                                                                };
                                                                            })
                                                                            }
                                                                            unControlList = {true}
                                                                        />
                                                                        ) : (
                                                                        <></>
                                                                        )}
                                                                    </DropdownToggle>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        </div>
                                                </td>
                                                {/* <td>
                                                    <div className="d-flex align-items-center max-width75">
                                                        <span class="c-dots  c-7 done rounded-circle me-10px"></span>
                                                        <span className="title-fonts">Active</span>
                                                    </div>
                                                </td> */}
                                                <td className="title-fonts text-center">
                                                    {user.total_task}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.risk_score == "" ? 0 : Number(user.risk_score).toFixed(2)}
                                                </td>
                                                <td className="title-fonts text-center"> 
                                                    {/* {user.space_count} */}
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div
                                                            className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus ${
                                                            spacesAssignee && spacesAssignee.length >= 1
                                                                ? ""
                                                                : "d-none"
                                                            }`}
                                                        >
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="div">
                                                                    {spacesAssignee && spacesAssignee.length > 0 ? (
                                                                    <a
                                                                        href="#/"
                                                                        className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                         
                                                                         
                                                                         
                                                                         
                                                                    >
                                                                        <div className="unread_count m-auto c-font f-12">
                                                                            {spacesAssignee.length }
                                                                        </div>
                                                                    </a>
                                                                    ) : (
                                                                    <></>
                                                                    )}
                                                                    {spacesAssignee && spacesAssignee.length ? (
                                                                    <GeneralListing
                                                                        handler={() => {}}
                                                                        list={
                                                                        spacesAssignee &&
                                                                        spacesAssignee.length &&
                                                                        spacesAssignee.map((item, index) => {
                                                                            return {
                                                                            name: item.office_title,
                                                                            image:
                                                                                item.profile_image,
                                                                            id: index,
                                                                            };
                                                                        })
                                                                        }
                                                                        unControlList = {true}
                                                                    />
                                                                    ) : (
                                                                    <></>
                                                                    )}
                                                                </DropdownToggle>
                                                            </UncontrolledDropdown>
                                                            </div>
                                                        </div>
                              
                                                </td>
                                                <td>
                                                    <div className="align-items-center comman_action_icon d-flex justify-content-center gap10px">
                                                        <div
                                                            className={`dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                                                        >
                                                            <UncontrolledDropdown className="w-100">
                                                            <DropdownToggle
                                                                data-toggle="dropdown"
                                                                tag="span"
                                                                className="comman_action_icon w-100 d-flex close-toast border-0"
                                                            >
                                                                <a
                                                                href="#/"
                                                                 
                                                                 
                                                                 
                                                                 
                                                                onClick={(e) => {
                                                                    setSelectChatUserId(user.userid)
                                                                    getMentionlist(user.userid);
                                                                    getTaskComments(user.userid);
                                                                }}
                                                                >
                                                                <div className="d-flex align-items-center w-100">
                                                                    <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                                                    <ChatCircle
                                                                        size={18}
                                                                        weight="light"
                                                                        className="c-icons"
                                                                    />
                                                                    {user.unread_comment_count ? <span className={`rounded-pill badge-custom p-0`} >
                                                                        {user.unread_comment_count}
                                                                    </span> : <></>}
                                                                    </div>

                                                                    <span
                                                                    className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                                                    >
                                                                    {user.comment_count > 0
                                                                        ? user.comment_count
                                                                        : <></>}
                                                                    </span>
                                                                    {/* )} */}
                                                                </div>
                                                                </a>
                                                            </DropdownToggle>
                                                            <DropdownItem toggle={false} className="p-0">
                                                                {
                                                                <CommentMinified
                                                                    comments={commentsList}
                                                                    disabled={false}
                                                                    handleAddComment={handleAddEditComment}
                                                                    deleteComment={handleDeleteComment}
                                                                    id={user.userid}
                                                                    task={user}
                                                                    loading={commentsLoading}
                                                                    mentionList={mentionsList.map((name) => {
                                                                    let id =
                                                                        name.is_team && name.is_team == 1
                                                                        ? "-" + name.id
                                                                        : name.id;
                                                                    return {
                                                                        display: name.name,
                                                                        id: id,
                                                                        image: name.profile_image || "",
                                                                        isteam: name.is_team ? 1 : 0,
                                                                    };
                                                                    })}
                                                                    handleImagePreview={handleImagePreview}
                                                                    unContolList={true}
                                                                    totalUnreadCommentCount={user.unread_comment_count}
                                                                    showComments={showComments}
                                                                    setReplyCommentData={setReplyCommentData}
                                                                    fileLoader={fileUploadLoader}
                                                                />
                                                                }
                                                            </DropdownItem>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                        <UncontrolledDropdown 
                                                            setActiveFromChild={true}
                                                        >
                                                        <DropdownToggle className="border-0 p-0">
                                                            <a
                                                                href="#/"
                                                                className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                                                                onClick={() => {providerActionModal > -1 ? setproviderActionModal(-1) : setproviderActionModal(index)}}
                                                            >
                                                            <DotsNine
                                                                size={18}
                                                                className="c-icons "
                                                                weight="light"
                                                            />
                                                            </a>
                                                        </DropdownToggle>
                                                        <DropdownItem toggle={false} className="p-0"> 
                                                        {providerActionModal === index &&
                                                        <div>
                                                        <ProviderListActionModal
                                                            data ={user}
                                                            actionList={["Assignee"]}
                                                        />
                                                        </div>
                                                        }
                                                        </DropdownItem>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                    {/* <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <a className="action_icon with_bg h32w32">
                                                            <DotsNine size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div> */}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                                : (!["my-clients","my-agents" ].includes(leftPanelTab)) ? <tr className="">
                                    <td className="border-0" colSpan={8}>
                                        <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                            <div className="d-flex flex-column align-items-center">
                                                <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                                            </div>
                                        </div>
                                    </td>
                                </tr> : <React.Fragment></React.Fragment>
                               }

                                {["my-agents"].includes(leftPanelTab) && agentList && agentList.length > 0 ?
                                <>
                                    {agentList.filter((w)=> w.firstname.toLowerCase().includes(globalSearchString.toLowerCase()) || w.lastname.toLowerCase().includes(globalSearchString.toLowerCase())).map((user, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <a href="#/" className="name-with-progress max-w300 d-block" onClick={() => { }} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${user.profile_image}')`, }}></div>
                                                            </div>
                                                            <div className="ps-2 w100minus70 text-start flex-grow-1">
                                                                <div className="d-flex flex-column">
                                                                    <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                                                                        {user.company}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                    {user.firstname  + " " + user.lastname}
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center max-width75">
                                                        <span class="c-dots  c-7 done rounded-circle me-10px"></span>
                                                        <span className="title-fonts">Active</span>
                                                    </div>
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.total_task}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.risk_score == "" ? 0 : Number(user.risk_score).toFixed(2)}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.space_count}
                                                </td>
                                                <td>
                                                    <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <UncontrolledDropdown
                                                            setActiveFromChild={true}
                                                        >
                                                        <DropdownToggle className="border-0 p-0">
                                                            <a
                                                                href="#/"
                                                                className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                                                                onClick={() => {providerActionModal > -1 ? setproviderActionModal(-1) : setproviderActionModal(index)}}
                                                            >
                                                            <DotsNine
                                                                size={18}
                                                                className="c-icons "
                                                                weight="light"
                                                            />
                                                            </a>
                                                        </DropdownToggle>
                                                        <DropdownItem toggle={false} className="p-0">
                                                            {providerActionModal === index &&
                                                                <div>
                                                                <ProviderListActionModal
                                                                    data ={user}
                                                                    actionList={["Assignee"]}
                                                                />
                                                                </div>
                                                            }
                                                        </DropdownItem>
                                                        
                                                        </UncontrolledDropdown>
                                                    </div>
                                                    {/* <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <a className="action_icon with_bg h32w32">
                                                            <DotsNine size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div> */}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                                : ["my-agents"].includes(leftPanelTab)  ?<tr className="">
                                    <td className="border-0" colSpan={7}>
                                        <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                            <div className="d-flex flex-column align-items-center">
                                                <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                : <React.Fragment></React.Fragment>
                               }
                                {["my-clients" ].includes(leftPanelTab) && clientListLocalData && clientListLocalData.length > 0 ?
                                <>
                                    {clientListLocalData.filter((w)=> w.company && w.company.toLowerCase().includes(globalSearchString.toLowerCase()) || w.contact_name && w.contact_name.toLowerCase().includes(globalSearchString.toLowerCase())).map((user, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <a href="#/" className="name-with-progress max-w300 d-block"  >
                                                        <div className="d-flex align-items-center">
                                                            <div className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${user.company_logo_url}')`, }}></div>
                                                            </div>
                                                            <div className="ps-2 w100minus70 text-start flex-grow-1">
                                                                <div className="d-flex flex-column">
                                                                    <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                                                                        {user.company}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                    {user.contact_name}
                                                </td>
                                                {/* <td>
                                                    <div className="d-flex align-items-center max-width75">
                                                        <span class={`c-dots  c-7  rounded-circle me-10px ${user.is_active ? 'done' : 'in-progress-blue'} `}></span>
                                                        <span className="title-fonts">{user.is_active ? _l("l_active") : _l("l_past")}</span>
                                                    </div>
                                                </td> */}
                                                <td className="title-fonts text-center">
                                                    {user.score || 0}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.risk_score == "" ? 0 : Number(user.risk_score).toFixed(2)}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.space_count}
                                                </td>
                                                <td>
                                                    <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <UncontrolledDropdown
                                                            setActiveFromChild={true}
                                                        >
                                                        <DropdownToggle className="border-0 p-0">
                                                            <a
                                                                href="#/"
                                                                className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                                                                // onClick={() => {providerActionModal > -1 ? setproviderActionModal(-1) : setproviderActionModal(index)}}
                                                            >
                                                            <DotsNine
                                                                size={18}
                                                                className="c-icons "
                                                                weight="light"
                                                            />
                                                            </a>
                                                        </DropdownToggle>
                                                        <DropdownItem toggle={false} className="p-0">
                                                            {providerActionModal === index &&
                                                                <div>
                                                                <ProviderListActionModal
                                                                    data ={user}
                                                                />
                                                                </div>
                                                            }
                                                        </DropdownItem>
                                                        
                                                        </UncontrolledDropdown>
                                                        
                                                    </div>
                                                    {/* <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <a className="action_icon with_bg h32w32">
                                                            <DotsNine size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div> */}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                                : ["my-clients" ].includes(leftPanelTab) ? <tr className="">
                                    <td className="border-0" colSpan={7}>
                                        <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                            <div className="d-flex flex-column align-items-center">
                                                <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                                            </div>
                                        </div>
                                    </td>
                                </tr> : <React.Fragment></React.Fragment>
                               }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            {showCommanoffCanvasModal ?   
            <CommanOffCanvas
                show={showCommanoffCanvasModal}
                handleClose={() => {
                    setShowCommanoffCanvasModal(false);
                    setModalData({});
                }}
                data={modalData}
                docType={"provider_page"}
                isCommentsection={true}
                isFullScreen={true}
            />
            : <></>
            }
            {showImagePreview ? (
                <ImageViewer
                    CloseCommentModal={closePreview}
                    attachmentDate={attachments.date}
                    attachmentURL={
                        attachments.file_array[attachments.currentIndex].file_url
                    }
                    changeAttach={changeAttachment}
                    handleAction={() => {}}
                    isChat={false}
                    showNextBtn={attachments.file_array.length > 0}
                    showPrevBtn={attachments.file_array.length > 0}
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </>
    );
};

export default ProviderList;

import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import { _l, getDatePickerLocale, getDatePickerTimeCaption, getFileType, validateEmail } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { Editor } from "@tinymce/tinymce-react";
import { showMessage } from "../../actions/messages";
import { useDispatch } from "react-redux";
import { leadBulkAction } from "../../actions/leads-actions/leads-actions";
import documentsServices from "../../services/documents-services";
import moment from "moment";
import { CalendarBlank, Paperclip, X } from "phosphor-react";
import Lightbox from "yet-another-react-lightbox";
import AddScheduleEmailModal from "./AddScheduleEmailModal";
import { Spinner } from "react-bootstrap";

const AddEmailTemplatesModalNew = ({
  show,
  handleClose,
  lead,
  isLeadContact = false,
  checkedBulkLeads,
  bulkEmail = false,
  type,
  from,
  data,
  createEstimateHandler = () => {}
}) => {
  const [emailTemplateDatas, setEmailTemplateDatas] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState({});
  const [fromEmail, setFromEmail] = useState(localStorage.getItem('email'));
  const [toEmail, setToEmail] = useState(type != "estimate" && lead ?  lead.email : '');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [subject, setSubject] = useState(data && data.subject ? data.subject :'');
  const [content, setContent] = useState({});
  const [allContactsSend, setAllContactsSend] = useState(false);
  const [estimatePdfCheck, setEstimatePdfCheck] = useState(false);
  const [estimateEmailList, setEstimateEmailList] = useState([]);
  const staffId = localStorage.getItem("staff_id") ? localStorage.getItem("staff_id") : false; 
  const clientId = localStorage.getItem("virtualSpaceClientId") ? localStorage.getItem("virtualSpaceClientId") : false; 
  const [emails, setEmails] = useState(data && data.sender_emails ? data.sender_emails : []);
  const [imageBuffer, setImageBuffer] = useState(data && data.email_images ? data.email_images : []);
  const [imagePreviews, setImagePreviews] = useState(data && data.email_images ? data.email_images : []);
  const [previewSrc, setPreviewSrc] = useState([{src : '', type : ''}])
  const [openpdfGallery, setOpenpdfGallery] = useState(false);
  const [emailMessages, setemailMessages] = useState(data && data.msg_body ? data.msg_body :'');
  const [showScheduleMailModule, setShowScheduleMailModule] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [loader, setLoader] = useState(false);

  const fileUpload = useRef(null);
  const editorRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
  
    if (type == "estimate") {
      customerServices.estimateSendEmails(staffId, clientId).then((res) => {
        if (res.status) {
          setEstimateEmailList(
            res.data.client_contact.map((temp) => {
              return {
                value : temp.id,
                label : temp.email,
              } 
            })
          )
        }
      })
    } 
    if (!["isFromEmailFooter"].includes(type)) {
      customerServices.getLeadEmailTemplate().then((res) => {
        if (res.status) {
          setEmailTemplateDatas(
            res.data.map((temp) => {
              return {
                value : temp.emailtemplateid,
                label : temp.name,
                content : temp.message,
                slug : temp.slug,
                subject : temp.subject
              }
            })
          )
        }
      })
    }
    if (bulkEmail && type != "estimate") {
      let emailArray = [];
      checkedBulkLeads && checkedBulkLeads.forEach((key) => {
        emailArray.push(`${key.email}`)
    });
    setToEmail(emailArray);
    }
  
  }, [])

  useEffect(() => {
    if (emailTemplateDatas && emailTemplateDatas.length > 0) {
      const initialTemp = emailTemplateDatas[0]
      setEmailTemplate(Object.assign({}, initialTemp));
      setContent(initialTemp.content);
      setSubject(initialTemp.subject);
    }
  }, [emailTemplateDatas])
  
  const validationLeads = () => {
    let flag = true;
    if( type != "estimate" && (!checkedBulkLeads || !checkedBulkLeads.length) &&  bulkEmail)
    {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_no_leads_selected')));
    }else if (appointmentDate == "" || appointmentDate == undefined) {
      dispatch(
        showMessage("unsucess", _l("l_error"), type == "estimate" ? _l("l_select_reminder_date") : _l("l_appointment_date_required"))
      );
      flag = false;
    }else if (emailTemplate.slug == "" || emailTemplate.slug == undefined) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_add_email_templates"))
      );
      flag = false;
    }else if (subject == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_subject_is_required"))
      );
      flag = false;
    } else if (type == "estimate" && (!toEmail.value || toEmail.value == "")){
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_to_email_is_required")));
      flag = false
    }
    return flag;
  }

  const emailTemplateSend = () => {
    if (!validationLeads()){
      return false;
    }
    try {
      if (bulkEmail) {
        let data = {
          emailtemplateid : emailTemplate.value,
          template_slug : emailTemplate.slug,
          subject: subject,
          email_template_custom : content,
          reminder_date : appointmentDate,
          all_contacts : allContactsSend ? 1 : 0,
          to_email : fromEmail
        };
        checkedBulkLeads.forEach((key, index) => {
          data[`lead_ids[${index}]`] = key.id;
      });
        data[`slug[0]`] = "lead_mass_send_email"
      dispatch(leadBulkAction(data));
      handleClose();
      }else{
        let id = '';
        if (bulkEmail) {
          id = '';
        }else if( lead && lead.id){
          id = lead.id
        }else{
          id = lead.document_id 
        }
      customerServices.leadsSendEmail(id, fromEmail, toEmail, appointmentDate, emailTemplate.slug, emailTemplate.value, subject, content, isLeadContact ? 1 : 0).then((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
          handleClose();
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
          handleClose(); 
        }
      })
      }
    } catch (error) {
      console.error("emailTemp", error);
    }
  }

  const estimateEmailSend = () => {
    if (!validationLeads()){
      return false;
    }
    try {
      documentsServices.sendEstimateAsEmail(staffId, toEmail.value, lead.estimate_id, estimatePdfCheck, moment(appointmentDate).format("YYYY-MM-DD HH:mm:ss")).then((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
          handleClose();
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
          handleClose(); 
        }
      })
      
    } catch (error) {
      console.error("emailTemp", error);
    }
  }
  
  const createNewEstimate = () => {
    if (!validationLeads()){
      return false;
    } else {
      const sendEmailData = {
        email_send_to_id: toEmail.value,
        reminder_date: moment(appointmentDate).format("YYYY-MM-DD HH:mm:ss"),
        attachpdf: estimatePdfCheck
      }
      createEstimateHandler(sendEmailData)
    }

  }

  const handleAddEmail = () => {
    if (toEmail.trim() !== '') {
      if (!validateEmail(toEmail.trim())) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email"))
        );
      }else{
        setEmails([...emails, toEmail.trim()]);
        setToEmail(''); 
      }
    }
  };

  const removeMailID = (mail) => {
    setEmails(emails.filter((w)=> w !== mail))
  }
  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      previrewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });
    setImagePreviews(imagePreviews.concat(previrewArray));
  };

  const removeImageSelection = (name) => {
    const updatedImagePreviews = Array.from(imagePreviews).filter(
      (img) => img.name !== name
    );
    const updatedImageBuffer = imageBuffer.filter((file) => file.name !== name);
    setImageBuffer(updatedImageBuffer);
    setImagePreviews(updatedImagePreviews)
  };
  const validationEmailsend = () => {
    let flag = true;
    if (subject == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_subject_is_required"))
      );
      flag = false;
    }else if (emails.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_to_email_is_required"))
      );
      flag = false;
    }else if (emailMessages == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_content_required"))
      );
      flag = false;
    }
    return flag;
  }
  const sendEmail = () => {
    if (!validationEmailsend()){
      return false;
    }
    try {
      setLoader(true);
      customerServices.sendCreateEmail(subject, emailMessages, emails, imageBuffer).then(((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
          handleClose();
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
          handleClose(); 
        }
        setLoader(false);
      }))
    } catch (error) {
      console.log("error", error);
    }
  }
  const scheduleMail = (date) => {
    if (!validationEmailsend()){
      return false;
    }
    try {
      setLoader(true);
      customerServices.sendCreateScheduleEmail(subject, emailMessages, emails, imageBuffer, date).then(((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
          setShowScheduleMailModule(false);
          handleClose();
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
          setShowScheduleMailModule(false);
          handleClose(); 
        }
        setLoader(false);
      }))
    } catch (error) {
      console.log("error", error);
    }
  };
  const getMaxTime = () => {
    let formattedTimeStamp = moment();
    formattedTimeStamp.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 59,
    });
    return new Date(formattedTimeStamp);
  };

  const addOneHour = (date) => {
    try {
      let formattedTimeStamp = moment(date);
      formattedTimeStamp = formattedTimeStamp.add(75, "minutes");
      return new Date(formattedTimeStamp);
    } catch (error) {
      console.log("error", error);
    }
  };

  const minSelectableTime = addOneHour(new Date());

  const minTimeHour = (date) => {
    try {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() - newDate.getHours());
      newDate.setMinutes(newDate.setMinutes() - newDate.setMinutes());
      return newDate;
    } catch (error) {
      console.log("error", error);
    }
  };

  const minSelectableTimeHour = minTimeHour(new Date());
  
  const changeTimeAndDate = (date) => {
    let setdateTime = moment(new Date());
    setdateTime = setdateTime.add(75, "minutes");
    if (new Date(setdateTime) > new Date(date)) {
      setStartDate(new Date(setdateTime));
    }else{
      setStartDate(date);
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{type == "isFromEmailFooter" ? _l("l_create_email") : _l("l_add_email_templates") }</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="form-wrapper-main">
            <div className="row m-0">
            {!["isFromEmailFooter", "estimate"].includes(type) ?
              <Form.Group className="col-xl-6 c-input-box position-relative  mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_from")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_from")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={fromEmail}
                  disabled
                />
              </Form.Group>
              : <></>
            }
              <Form.Group className={`${!["isFromEmailFooter", "estimate"].includes(type) ? "col-xl-6" : "col-xl-12"} c-input-box position-relative mb-3`}>
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_to")}
                </Form.Label>
                { type == "estimate"
                  ? <Select 
                      className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh h_45"
                      classNamePrefix="react-select"
                      options={estimateEmailList}
                      value={toEmail}
                      onChange={(e) => {
                        setToEmail(e)
                      }}  
                    />
                  : <Form.Control
                    placeholder={_l("l_to")}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={toEmail}
                    type="text"
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && type == "isFromEmailFooter") {
                        handleAddEmail();
                      }
                    }}
                    onChange={(e) => { setToEmail(e.target.value)}}
                    disabled = {type == "isFromEmailFooter" ? false : true}
                    />
                }
              </Form.Group>
              {type == "isFromEmailFooter" && emails && emails.length > 0 && <div className="pb-4">
                  {emails && emails.length > 0 && 
                  emails.map((mail,index)=>{
                    return(<>
                    <div
                      key={index}
                      className='badge c-font f-12 p-2 rounded-pill me-1 mt-2'
                    >
                       {mail}
                      <a href="#/" className='ps-1' onClick={() => removeMailID(mail)}>
                        <X size={10}  weight="light" className="c-icons" />
                      </a>
                    </div>
                    </>)
                  })}
                  </div>}
              {!["isFromEmailFooter"].includes(type) && 
              <Form.Group className="col-xl-6 c-input-box position-relative mb-3 ">
              <Form.Label className="input-label ">
                  { type == "estimate" ? _l("l_select_reminder_date") : _l("l_appointement_date")}
                </Form.Label>
                  <div className="d-flex align-items-center">
                    <div
                      className="custom-datepicker w-100"
                      data-bs-toggle="tooltip"
                      title="Aug, 8 2022"
                    >
                      <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                        <CalendarBlankIcon className="HW18 opacity-60" />
                        <DatePicker
                          portalId="custom_datepicker"
                          calendarClassName="custom-datepicker"
                          selected={appointmentDate}
                          onChange = {((date) => setAppointmentDate(date))}
                          showTimeSelect
                          timeCaption={getDatePickerTimeCaption()}
                          locale={getDatePickerLocale()}
                          disabledKeyboardNavigation
                          placeholderText={ type == "estimate" ? _l("l_select_reminder_date") : _l("l_appointement_date")}
                          dateFormat="dd/MM/yyyy hh:mm a"
                          selectsStart
                          defaultMenuIsOpen
                          calendarStartDay={1}
                        ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                      </div>
                    </div>
                  </div>
              </Form.Group>}
            { !["estimate", "isFromEmailFooter"].includes(type) ?
              <Form.Group className="col-xl-6 c-input-box position-relative mb-3 z-index-3">
                <Form.Label className="input-label ">
                  {_l("l_email_templates")}
                </Form.Label>
                <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                <Select 
                   className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh h_45"
                   classNamePrefix="react-select"
                  options={emailTemplateDatas}
                  value={
                    emailTemplateDatas ? 
                     emailTemplateDatas.find((x) => x.label === emailTemplate.label)
                    : ''
                  }
                  onChange={(e) => {
                    setEmailTemplate(e);
                    setContent(e.content);
                    setSubject(e.subject); 
                  }}  
                />
                </div>
              </Form.Group>
              : <></>
            }
            { !["estimate"].includes(type) ?
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label">
                  {_l("l_subject")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_subject")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Group>
              : <></>
            }
              { type == "estimate"
                ? <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                    <div className="form-check with-span pt-0 pb-0">
                    <div className="d-flex align-items-center">
                      <Form.Label className="form-check-label text_wrap mx-3 ms-0 mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={estimatePdfCheck}
                          onChange={() => {
                            setEstimatePdfCheck(!estimatePdfCheck)
                          }}
                        />
                        <span>{_l('l_attach_estimate_pdf')}</span>
                      </Form.Label>
                    </div>
                    </div>
                  </Form.Group> 
                : <></>
              }
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_content")}
                </Form.Label>
                {["isFromEmailFooter"].includes(type) && 
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="h-160px"
                    type="text"
                    value={emailMessages}
                    onChange={(e) => setemailMessages(e.target.value)}
                    />
                  }
              </Form.Group>
              {bulkEmail ? 
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
              <div className="form-check with-span pt-0 pb-0">
                <div className="d-flex align-items-center">
                  <Form.Label className="form-check-label text_wrap mx-3 ms-0 mb-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={allContactsSend}
                      onChange={() => {setAllContactsSend(!allContactsSend)}}
                    />
                    <span>{_l('l_leads_bulk_mail_to_all_contacts')}</span>
                  </Form.Label>
                </div>
              </div>
              </Form.Group>
              : <></>}
              {!["isFromEmailFooter"].includes(type) && 
              <div className="Editorbox">
              <Editor
                apiKey='xrpwo6eqys7s27x5mygatu72wyyt5bi8r3k4omn5s0jr83gk'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={emailTemplate.content}
                onChange={(e) => {setContent(e.level.content)}}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color:#ffffff;}'
                }}
              />
              </div>}
              {type == "isFromEmailFooter" && imagePreviews.length ? ( 
                  <div className="upload-image-preview p-15">
                    <div className="d-flex align-items-center justify-content-start">
                      {imagePreviews.map((preview, index) => {
                        return (
                          <div
                            key={index}
                            className="comman-image-box h25w25 radius_3 upload-image with-margin"
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                              style={{
                                backgroundImage: `url(
                                  '${preview.url || preview}'
                                )`,
                              }}
                              onClick={() => {
                                setOpenpdfGallery(true);
                                if (getFileType(preview.name || preview) === "pdf") {
                                  setPreviewSrc([{ src : preview.url || preview, type : "pdf"}])
                                  console.log("preview.url", preview.url);
                                } else {
                                  setPreviewSrc([{ src : preview.url || preview , type : "image"}]);
                                }
                              }}
                            ></div>
                            <a href="#/"
                              className="upload-close"
                              onClick={() => {
                                removeImageSelection(preview.name || preview);
                              }}
                            >
                              <X size={10} className="c-icons" weight="light" />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              {type == "isFromEmailFooter" &&
              <div className="row align-items-end pb-3">
              <Form.Group className="col-12  c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_choose_file")}
                </Form.Label>
                <div className="d-flex align-items-center">
                  <div className="comment-input-box flex-grow-1 me-2">
                    <div
                      className="input_box_main with_file_upload"
                       
                       
                       
                       
                      
                    >
                      <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center " >
                        <Form.Control
                          className="hidden-input"
                          type="file"
                          multiple
                          onChange={(event) => {
                            let commentsfiles = [];
                            Array.from(event.target.files).map((file) => {
                              let tempImg = file;
                              commentsfiles.push(tempImg);
                            });
                            setImageBuffer(imageBuffer.concat(commentsfiles));
                            preparePreview(event.target.files);
                          }}
                          ref={fileUpload}
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                        />
                        <div className="custom_file">
                          <Paperclip
                            size={18}
                            className="upload_icon c-icons"
                            weight="light"
                          />
                          <div className="btn btn-sm btn-white-10 border-0">
                            <span className="c-font f-10 color-white-60">
                              {" "}
                              {_l("l_upload_file")}
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </Form.Group>
              </div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
            {type == "isFromEmailFooter" && 
              <div
                className="border comman_action_icon custom-datepicker h32w32 on-hover-bg-white-05 withinputabsolute"
                
                 
              >
                <div
                  className={`action_icon align-items-center text-truncate d-flex h-100 w-100 justify-content-center light-theme-img on-hover-active-toggle-img on-hover-color-green bg-transparent with_bg position-relative  ${
                    startDate
                      ? "active"
                      : ""
                  }`}
                >
                  <CalendarBlank
                    size={18}
                    weight="light"
                    className="c-icons m-0"
                  />
                  {startDate?
                  <>
                  <span class="with_separator2_10"></span> 
                    <span className="ms-10px title-fonts">{`${moment(startDate).format(
                    "DD/MM/yyyy hh:mm a"
                  )}`}</span>
                  </>
                  :
                  <></>
                  }
                  <DatePicker
                    portalId="custom_datepicker"
                    calendarClassName="custom-datepicker"
                    timeCaption={getDatePickerTimeCaption()}
                    locale={getDatePickerLocale()}
                    className="close-toast"
                    onChange={(date) => {
                      changeTimeAndDate(date);
                    }}
                    showTimeSelect
                    selected={startDate}
                    selectsStart
                    dateFormat="dd/MM/yyyy hh:mm a"
                    calendarStartDay={1}
                    minDate = {new Date()}
                    minTime={moment(new Date()).format("YYYY-MM-DD") == moment(startDate || new Date()).format("YYYY-MM-DD") ? minSelectableTime : minSelectableTimeHour}
                    maxTime ={getMaxTime()}
                  >
                    <div className="datepicker-label">
                      {_l("l_select_date_and_time")}
                    </div>
                  </DatePicker>
                </div>
              </div>
            }
          <Button variant="primary" size="sm" onClick={() => {
            if (type == "isFromEmailFooter") {
              if (startDate) {
                scheduleMail(startDate)
              }else{
                sendEmail();
              }
            }else {
              if (from == "existingEstimate" ) {
                estimateEmailSend()
              } else if (from == "newEstimate") {
                createNewEstimate()
              } else {
                emailTemplateSend()
              }
            }
            }}>
              {loader 
                  ? <>
                    {_l("l_please_wait")} <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                    />
                  </>
                  :_l("l_send")}
          </Button>
        </Modal.Footer>
      </Modal>
      {openpdfGallery && 
        <Lightbox 
          open={openpdfGallery}
          close={() => setOpenpdfGallery(false)}
          slides={previewSrc}
        />}

        {showScheduleMailModule && 
          <AddScheduleEmailModal
            show={showScheduleMailModule}
            handleClose={() => {
              setShowScheduleMailModule(false);
            }}
            handleSubmit={scheduleMail}
        />}
    </>
  );
};

export default AddEmailTemplatesModalNew;

import Accordion from "react-bootstrap/Accordion";
import React, { useEffect, useState } from "react";
 
import { _l } from "../../hooks/utilities";
import { Trash, NotePencil, Check, PushPin, DotsNine } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import Form from "react-bootstrap/Form";
import { selectedTypeOfSapceId } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { updateFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import $ from "jquery"

function TypeOfSpaceAccordians({ typeOfSpaceItemData,removeFloorItem,addFloor,handleChangeCordinate,roomTitle,floorId,selectedRoom, setSelectedRoom }) {
  const dispatch = useDispatch();
  const [getTypeOfSapceItems, setGetTypeOfSapceItems] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [iconToggle, setIconToggle] = useState(0);
  
  const {getSelectedTypeOfSpaceId} = useSelector((state) => state.customer);
  const editName = (name, value, key) => {
    let Arr = getTypeOfSapceItems
    Arr[key] = {
        ...Arr[key],
        [name]: value
    }
    setGetTypeOfSapceItems(Arr.slice());
}
  useEffect(() => {
    setGetTypeOfSapceItems([]);
    if (typeOfSpaceItemData) {
      setGetTypeOfSapceItems(typeOfSpaceItemData);
    }
  }, [typeOfSpaceItemData]);
  const spaceFloorPlan = useSelector(
    (state) => state.benchmarkReducer.floorDetails
  );
  useEffect(() =>{
    if (selectedRoom) {
      setSelectedId(selectedRoom.roomId);
    }
  },[selectedRoom])
  return (
    <div className="custom-accordian-main ">
      <Accordion className="withoutborder-base-modal-bg list-view-toggle mt-0" defaultActiveKey={"0"}>
        {getTypeOfSapceItems.length ? (
          getTypeOfSapceItems.map((tos, index) => {
            return (
              <Accordion.Item eventKey={index} className="active mb-2px" >
                <div className={`accordian-header-box d-flex align-content-center accordian-main-header title-fonts task-list-card list-view p-1 radius_5 task-list-card w-100 ${tos.id == selectedId ? "active" : ""}`}>
                  <Accordion.Header onClick={() => {setSelectedRoom({...tos,roomId:tos.id,roomName:tos.room_title})}}>
                    <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                      <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                        {selectedId == tos.id ?
                          <Form.Control type="text" className="bg-transparent p-0 border-0" value={tos.title} 
                          onChange={(e) => {
                            editName("title",e.target.value ,index)
                          }} />
                          :
                          tos.title
                        }
                      </div>
                    </div>
                  </Accordion.Header>
                  <div className="align-items-center d-flex flex-grow-1 justify-content-start mr-10 z-index-3">
                    {
                      iconToggle == tos.id ?
                        <>
                        <div className="comman_action_icon d-flex" >
                          <a href="#/" className="d-flex action_icon with_bg with-border-active ms-10px h32w32" onClick={() => { setSelectedId(tos.id) }}
                             
                             
                             
                             
                          >
                            <NotePencil size={18} weight="light" className="c-icons m-auto" />
                          </a>
                          <a href="#/" className="d-flex action_icon with_bg with-border-active ms-10px h32w32" onClick={() => {
                            if (getTypeOfSapceItems.length > 1) {
                              removeFloorItem("space_item", tos.id, "item_id")
                              handleChangeCordinate([]);
                            }
                          }}
                             
                             
                             
                             
                          >
                            <Trash size={18} weight="light" className="c-icons m-auto" />
                          </a>
                          <a href="#/" className="d-flex action_icon with_bg with-border-active ms-10px h32w32"
                            onClick={() => {
                              dispatch(selectedTypeOfSapceId(tos))
                              handleChangeCordinate([]);
                              setSelectedId(tos.id)
                              if ($('.blank_anchor').length) {
                                $('.blank_anchor').remove();
                              }
                            }}
                             
                          >
                            <PushPin size={18} weight={getSelectedTypeOfSpaceId && getSelectedTypeOfSpaceId.id == tos.id || (tos.poi_set && tos.poi_set.length > 0) ? "fill" : "light"} className="c-icons m-auto" />
                          </a>
                          {
                            selectedId == tos.id ?
                              <a href="#/" className="d-flex action_icon with_bg with-border-active ms-10px h32w32"
                                onClick={() => {
                                  addFloor(tos.room_id, tos.id, tos.title, tos.poi_set);
                                  setSelectedId(0);
                                }} 
                              >
                                <Check size={18} weight="light" className="c-icons m-auto" />
                              </a>
                              : <></>
                          }
                          </div>
                          </>
                        : <></>}
                    <div className="comman_action_icon">
                      <a href="#/"
                        className={`d-flex action_icon with_bg with-border-active ms-10px h32w32 ${iconToggle == tos.id ? "active" : ""}`}
                        onClick={() => {
                          setIconToggle(iconToggle == tos.id ? 0 : tos.id);
                        }}
                      >
                        <DotsNine size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                  </div>
                   
                </div>
                <Accordion.Body className="pt-3">
                  {tos.poi_set && tos.poi_set.length ? (
                    tos.poi_set.map((cc, innerIndex) => {
                      return (
                        <div className="align-items-center bg-white-03 d-flex justify-content-between mb-10 p-10 radius_3" key={innerIndex}>
                          <div className="d-flex align-items-center">
                            <div className="c-font f-12 fw-light me-2">{_l("l_point")}-{innerIndex + 1} : </div>
                            <div className="badge badge-white rounded-pill c-font f-12 fw-light me-1">x: {cc.web_x.toFixed(8)}</div>
                            <div className="badge badge-white rounded-pill c-font f-12 fw-light me-1">y: {cc.web_y.toFixed(8)}</div>
                            <div className="badge badge-white rounded-pill c-font f-12 fw-light me-1">z: {cc.web_z.toFixed(8)}</div>
                          </div>
                          <a href="#/" className="d-flex z-index-2 with_separator_10 pe-0"
                            onClick={() => {
                              let tempArr = spaceFloorPlan.type_of_space
                              let tempIndex = tempArr.findIndex((dd) => dd.title == roomTitle)
                              if (tempIndex > 0) { 
                                tempArr[tempIndex].details.filter((de) => de.floor_id == floorId)[index].poi_set = tempArr[tempIndex].details.filter((de) => de.floor_id == floorId)[index].poi_set.filter((poi,key) => key != innerIndex);
                              }
                              dispatch(updateFloorDetails({...spaceFloorPlan,type_of_space:tempArr}));
                              dispatch(selectedTypeOfSapceId(tos))
                              handleChangeCordinate([]);
                            }}
                             
                             
                             
                             
                          >
                            <Trash size={18} weight="light" className="c-icons m-auto" />
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <CommanPlaceholder mainWidth="pb-4" /> </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  );
}

export default TypeOfSpaceAccordians;

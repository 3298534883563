import React, { useEffect, useState } from "react";
import { X,NotePencil } from "phosphor-react";
import { _l } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import AccessSapceEdit from "../../Modals/AccessSapceEdit";
import ReactHtmlParser from "react-html-parser";
import { DropdownMenu } from "reactstrap";

const AccessDescriptionBox = () => {
  const { accessOfficeData, spaces } = useSelector((state) => state.customer);
  const [show, setShow] = useState(false);
  const [space, setSpace] = useState("");
  useEffect(() => {
    if (spaces && spaces.length > 0) {
      let selectedSpace = spaces.filter((s) => s.project_id == localStorage.getItem("selectedOffice"));
      setSpace(selectedSpace[0]);
    }
  },[spaces, localStorage.getItem("selectedOffice")])
  return (
    <>
      
        <DropdownMenu container="body" className={`dropdown-menu w-250 overflowscroll`}
        aria-labelledby="AccessDescriptionBox"
      >
        <div>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail c-font f-14 title-fonts text-truncate color-white">
              {_l('l_access_office')}
              </div>
              { space && space.is_allowed_to_edit == 1 && (localStorage.getItem("contact_role") && localStorage.getItem("contact_role") != 3 )?
              <div className="right-option">
                <a href="#/" className="right-image"
                onClick={() => setShow(true)}
                >
                  <NotePencil size={16}  weight="light" className="c-icons" />
                </a>
                <a href="#/" className="right-image ms-3 d-none" data-bs-dismiss="dropdown">
                  
                <X size={18}  weight="light" className="c-icons" />
                </a>
              </div>
              :
              <></>
            }
            </div>
          </div>
        </div>
        <li>
          <hr className="dropdown-divider mt-0" />
        </li>
        <div className="access-box-main">
          <div className="row gx-2">
            <div className="col-xl-6 title-fonts mb-3">
              <div className="c-font f-12 color-white-60 mb-1 text-truncate">{_l("l_name")} :</div>
              <div className="text-truncate">{accessOfficeData.name && accessOfficeData.name != "" ? accessOfficeData.name : "-"}</div>
            </div>
            <div className="col-xl-6 title-fonts mb-3">
              <div className="c-font f-12 color-white-60 mb-1 text-truncate">{_l("l_mobile_number")} :</div>
              <div className="text-truncate">{accessOfficeData.phonenumber && accessOfficeData.phonenumber ? accessOfficeData.phonenumber : "-"}</div>
            </div>
            <div className="col-xl-6 title-fonts mb-3">
              <div className="c-font f-12 color-white-60 mb-1 text-truncate">{_l("l_access_code")} :</div>
              <div className="text-truncate">{accessOfficeData.accese_code && accessOfficeData.accese_code != "" ? accessOfficeData.accese_code : "-"}</div>
            </div>
            <div className="col-xl-6 title-fonts mb-3">
              <div className="c-font f-12 color-white-60 mb-1 text-truncate">{_l("l_wi_fi_code")} :</div>
              <div className="text-truncate">{accessOfficeData.wifi_code && accessOfficeData.wifi_code ? accessOfficeData.wifi_code : "-"}</div>
            </div>
            <div className="col-xl-6 title-fonts mb-3">
              <div className="c-font f-12 color-white-60 mb-1 text-truncate">{_l("l_ai_landline_no")} :</div>
              <div className="text-truncate">{accessOfficeData.ai_phone_number && accessOfficeData.ai_phone_number ? accessOfficeData.ai_phone_number : "-"}</div>
            </div>
            <div className="col-12">
              <div className="c-font f-12 color-white-60 title-fonts mb-1 text-truncate">{_l("l_description")} :</div>
              <div className="">{accessOfficeData.description && accessOfficeData.description ? ReactHtmlParser(accessOfficeData.description.replace(/(?:\r\n|\r|\n)/g, '<br>'))  : "-"}</div>
            </div>
          </div>
        </div>
      </DropdownMenu>
      {
        <AccessSapceEdit 
        show={show}
        handleClose={() => setShow(false)}
        accessOfficeData={accessOfficeData}
        />
      }
    </>
  );
};

export default AccessDescriptionBox;

import React, { useState } from 'react'
import PlacesAutocomplete from "react-places-autocomplete";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Minus, Plus } from "phosphor-react";
import { handleNumericInput, _l , getDatePickerLocale } from "../../hooks/utilities";
import {
    geocodeByAddress,
} from "react-places-autocomplete";
import DatePicker from "react-datepicker";
import Select from "react-select";
function AddSpaceLead({ spaceDetails, setSpaceDetails, regionsList }) {
    const [typeOfSpaceTitle, setTypeOfSpaceTitle] = useState("");
    const [typeOfSpaceQuantity, setTypeOfSpaceQuantity] = useState(1);
    const optionsStrict = {
        componentRestrictions: {
            country: ["fr", "uk", "de", "esp", "ita", "bel", "che"],
        },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["address"],
    };
    const autosearchPlaces = ({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
    }) => {
        return (
            <div>
                <Form.Control
                    placeholder={`${_l("l_address_placeholder")}`}
                    type="text"
                    name="address_3dplan"
                    {...getInputProps({
                        placeholder: "Avenue de France, Paris, France",
                    })}
                />
                <div className="custom-map-suggestion border-0">
                    {loading && <div>{_l("l_loading")}</div>}
                    {suggestions.map((suggestion, index) => {
                        return (
                            <div {...getSuggestionItemProps(suggestion)} key={index}>
                                <a>{suggestion.description}</a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const handleSelect = async (value) => {


        geocodeByAddress(value)
            .then((results) => {
                for (var i = 0; i < results[0].address_components.length; i++) {
                    var addressType = results[0].address_components[i].types[0];
                    if (addressType === "country") {
                        setSpaceDetails({
                            ...spaceDetails,
                            address: value,
                            country_id: results[0].address_components[i].short_name
                        })
                    }
                }
            })
            .catch((error) => console.error("Error", error));
    };
    const spaceTypeOptions = [
        {
            value: "1",
            label: _l('l_office'),
        },
        {
            value: "2",
            label: _l('l_coworking_and_flex_office'),
        },
        {
            value: "3",
            label: _l('l_building'),
        },
        {
            value: "4",
            label: _l('l_multi_properties'),
        },
        {
            value: "5",
            label: _l('l_hotel_and_retail'),
        },
        {
            value: "6",
            label: _l('l_property_manager_and_facility_manager'),
        },
    ];
    const handleChangeTypes = (name, val, k) => {
        const arr = spaceDetails.typeOfSpace.filter((file) => {
            return file !== val
        });
        arr[k] = {
            ...arr[k],
            [name]: val
        }
        setSpaceDetails({
            ...spaceDetails,
            typeOfSpace: [...arr]
        })

    };
    const addTypeOfSpace = () => {
        if (typeOfSpaceTitle.trim() != "") {
            setSpaceDetails({
                ...spaceDetails, ['typeOfSpace']: [{
                    title: typeOfSpaceTitle,
                    quantity: typeOfSpaceQuantity
                }].concat(spaceDetails.typeOfSpace)
            })
            setTypeOfSpaceQuantity(1);
            setTypeOfSpaceTitle("");
        }
    }
    return (
        <div className="form-wrapper-main pt-0">
            <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_space_title")}
                    </Form.Label>
                    <Form.Control
                        placeholder={`${_l("l_space_title_placeholder")}`}
                        type="text"
                        name=""
                        value={spaceDetails.title ? spaceDetails.title : ''}
                        onChange={(e) => setSpaceDetails({
                            ...spaceDetails,
                            title: e.target.value,
                        })}
                    />
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_space_address")}
                    </Form.Label>
                    <PlacesAutocomplete
                        value={spaceDetails.address ? spaceDetails.address : ''}
                        onChange={(e) => {
                            setSpaceDetails({
                                ...spaceDetails,
                                address: e,
                            })
                        }}
                        onSelect={handleSelect}
                        searchOptions={optionsStrict}
                    >
                        {autosearchPlaces}
                    </PlacesAutocomplete>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_space_type")}
                    </Form.Label>
                    <Select
                        className="custom-select-menu"
                        classNamePrefix="react-select"
                        placeholder={_l("l_select_space_type")}
                        options={spaceTypeOptions}
                        closeMenuOnSelect={true}
                        onChange={(value) => {
                            setSpaceDetails({
                                ...spaceDetails,
                                space_type: value,
                            })
                        }}
                        value={spaceDetails.space_type ? spaceDetails.space_type : ''}
                    ></Select>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box position-relative">
                    <Form.Label className="input-label no-asterisk ">
                        {_l("l_select_region")}
                    </Form.Label>
                    <Select
                        className="custom-select-menu"
                        classNamePrefix="react-select"
                        placeholder={_l("l_select_region")}
                        options={regionsList}
                        closeMenuOnSelect={true}
                        onChange={(value) => {
                            setSpaceDetails({
                                ...spaceDetails,
                                region_id: value,
                            })
                        }}
                        value={spaceDetails.region_id ? spaceDetails.region_id : ''}
                    ></Select>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                        {_l("l_superficy")} (m²)
                    </Form.Label>
                    <div className="d-flex align-items-center justify-content-between form-control">
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={() => {
                                if (spaceDetails.superfice > 0) {
                                    let tempSuperficy =
                                        spaceDetails.superfice != "" ? parseInt(spaceDetails.superfice) - 50 : 0;
                                    setSpaceDetails({
                                        ...spaceDetails,
                                        superfice: tempSuperficy,
                                    })
                                }
                            }}
                        >
                            <Minus size={18} className="c-icons" weight="light" />
                        </a>
                        <input
                            className="form-check-input bg-transparent w-100 text-center"
                            placeholder="0"
                            type="number"
                            value={spaceDetails.superfice}
                            onChange={(e) => {
                                let tempSuperficy = handleNumericInput(e, spaceDetails.superfice);
                                setSpaceDetails({
                                    ...spaceDetails,
                                    superficy: Number(tempSuperficy).toString(),
                                })
                            }}
                            name=""
                        />
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={() => {
                                let tempSuperficy = parseInt(spaceDetails.superfice) + 50;
                                setSpaceDetails({
                                    ...spaceDetails,
                                    superfice: tempSuperficy,
                                })
                            }}
                        >
                            <Plus size={18} className="c-icons" weight="light" />
                        </a>
                    </div>
                </Form.Group>
                <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                        {_l("l_number_of_desk")}
                    </Form.Label>
                    <div className="d-flex align-items-center justify-content-between form-control">
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={(e) => {
                                if (spaceDetails.number_of_desk > 0) {
                                    let tempDesk =
                                        spaceDetails.number_of_desk != "" ? parseInt(spaceDetails.number_of_desk) - 1 : 1;
                                    setSpaceDetails({
                                        ...spaceDetails,
                                        number_of_desk: tempDesk,
                                    })
                                }
                            }}
                        >
                            <Minus size={18} className="c-icons" weight="light" />
                        </a>
                        <input
                            className="form-check-input bg-transparent w-100 text-center"
                            placeholder="0"
                            type="number"

                            value={spaceDetails.number_of_desk}
                            onChange={(e) => {
                                let tempDesk = handleNumericInput(e, spaceDetails.number_of_desk);

                                setSpaceDetails({
                                    ...spaceDetails,
                                    number_of_desk: tempDesk,
                                })
                            }}
                        />
                        <a href="#/"
                            className="c-font f-22 line-height-0"
                            onClick={() => {
                                let tempDesk =
                                    spaceDetails.number_of_desk != "" ? parseInt(spaceDetails.number_of_desk) + 1 : 1;
                                setSpaceDetails({
                                    ...spaceDetails,
                                    number_of_desk: tempDesk,
                                })
                            }}
                        >
                            <Plus size={18} className="c-icons" weight="light" />
                        </a>
                    </div>
                </Form.Group>
                <div className="col-xl-6 mb-3 d-none">
                    <Form.Label className="input-label no-asterisk custom-datepicker">
                        {" "}
                        {_l("l_start_date")}
                    </Form.Label>
                    <DatePicker
                        selected={spaceDetails.startDate}
                        onChange={(date) => {
                            if (date != "") {
                                setSpaceDetails({
                                    ...spaceDetails,
                                    startDate: date,
                                })
                            }
                        }}
                        placeholderText={`${_l("l_start_date_placeholder")}`}
                        locale={getDatePickerLocale()}
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                </div>
                <div className="col-xl-6 mb-3 d-none">
                    <Form.Label className="input-label no-asterisk ">
                        {" "}
                        {_l("l_end_date")}
                    </Form.Label>
                    <DatePicker
                        selected={spaceDetails.endDate}
                        onChange={(date) => {
                            if (date != "") {
                                setSpaceDetails({
                                    ...spaceDetails,
                                    endDate: date,
                                })
                            }
                        }}
                        placeholderText={`${_l("l_end_date_placeholder")}`}
                        locale={getDatePickerLocale()}
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                </div>
                <Form.Group className=" c-input-box  position-relative ">
                    <Form.Label className="input-label no-asterisk">{_l('l_type_of_space_label')}</Form.Label>
                    <div className="d-flex flex-wrap align-items-center">
                        {
                            spaceDetails.typeOfSpace && spaceDetails.typeOfSpace.map((top, k) => {

                                return <div key={k} className="checkbox-border-wrapper position-relative w-50">
                                    <div className="checkbox-with-border">
                                        <Form.Label className="form-label mb-0 title-fonts flex-grow-1">
                                            <div className="d-flex align-items-center">
                                                <div className="check-text with_separator_10 flex-grow-1 ps-0 c-font f-14">

                                                    <input
                                                        className="border-0 bg-transparent"
                                                        type="text"
                                                        placeholder={`${_l("l_enter_text")}`}
                                                        value={top.title}
                                                        onChange={(e) => {
                                                            handleChangeTypes("title", e.target.value, k)
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center with_separator_10 pe-0">
                                                    <a href="#/" className=""
                                                        onClick={() => {
                                                            if (top.quantity > 1) {
                                                                handleChangeTypes("quantity", top.quantity - 1, k)
                                                            }
                                                        }}
                                                    > <Minus size={14} className="c-icons" weight="light" /> </a>
                                                    <span className="px-2 c-font f-13">{top.quantity}</span>
                                                    <a href="#/" className=" "
                                                        onClick={() => handleChangeTypes("quantity", parseInt(top.quantity) + 1, k)}
                                                    ><Plus size={14} className="c-icons" weight="light" /></a>
                                                </div>
                                            </div>
                                        </Form.Label>
                                    </div>
                                </div>
                            })
                        }
                    </div>

                </Form.Group>
                <Form.Group>
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="checkbox-border-wrapper position-relative">
                            <div className="checkbox-with-border">
                                <Form.Label className="form-label mb-0 title-fonts">
                                    <div className="d-flex align-items-center">
                                        <input
                                            className="border-0 bg-transparent"
                                            type="text"
                                            placeholder={`${_l("l_enter_text")}`}
                                            value={typeOfSpaceTitle}
                                            onChange={(e) => setTypeOfSpaceTitle(e.target.value)}
                                        />
                                        <div className="d-flex align-items-center with_separator_10 pe-0">
                                            <a href="#/" className="" onClick={() => {
                                                if (typeOfSpaceQuantity > 1) {
                                                    setTypeOfSpaceQuantity(typeOfSpaceQuantity - 1)
                                                }
                                            }
                                            }
                                            > <Minus size={14} className="c-icons" weight="light" /> </a>
                                            <span className="px-2 c-font f-13 lh-sm">{typeOfSpaceQuantity}</span>
                                            <a href="#/" className=""
                                                onClick={() => setTypeOfSpaceQuantity(typeOfSpaceQuantity + 1)}
                                            ><Plus size={14} className="c-icons" weight="light" /></a>
                                        </div>
                                    </div>
                                </Form.Label>
                            </div>
                        </div>
                        <Button variant="white-05 " size="md"
                            onClick={() => addTypeOfSpace()}>
                            <Plus size={14} weight="light" className="c-icons" />
                            <span className="ps-1">{_l('l_add_type_of_space')}</span>
                        </Button>
                    </div>
                </Form.Group>
            </div>
        </div >
    )
}

export default AddSpaceLead
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, showError, showSuccess } from "../../hooks/utilities";
import { Spinner } from "react-bootstrap";
import userpaymnetServices from "../../services/userpaymnet-services";
import { showMessage } from "../../actions/messages";
import { useDispatch } from "react-redux";

const IBanEditModal = ({
  show,
  handleClose,
  isForAdd,
  ibanDetails,
  setIBanDetails,
  handleSave,
  loader = false,
  redirectIsActive = true,
}) => {
  const [paymentaccountTab, setPaymentaccountTab] = useState("Stripe");
  const [addLoader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const addNewIBAN = () =>{
    setLoader(true);
    try {
      userpaymnetServices.setNewIBANForWallet(
        ibanDetails.account_iban,
        ibanDetails.company_address,
        ibanDetails.city,
        ibanDetails.postal_code,
        ibanDetails.estimate_id,
        0,
        ibanDetails.company_name
      ).then((res)=>{
        if (res && res.status) {
          if (res.data && res.data.site) {
            window.open(res.data.site, "_blank");
          }else{

          }
              setIBanDetails({
                  account_iban:"",
                  company_name:"",
                  city: "",
                  postal_code:"",
                  company_address:""
              });
          setLoader(false);
          handleClose();
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
              setIBanDetails({
                  account_iban:"",
                  company_name:"",
                  city: "",
                  postal_code:"",
                  company_address:""
              });
          setLoader(false);
          handleClose();

        }
      })
    } catch (error) {
      
    }

  } 

  const editIBANDetails = () => {
    if (ibanDetails.company_name == "") {
      showError("l_company_name_required")

    } else if(ibanDetails.account_iban == ""){
      showError("l_invalid_iban");

      }else if(ibanDetails.company_address == ""){
      showError("l_please_enter_company_address");
      
      }else if(ibanDetails.city == ""){
      showError("l_please_enter_valid_city");
      
      }else if(ibanDetails.postal_code == ""){
        showError("l_please_enter_valid_postal_code");
        
    }else{
    setLoader(true);
      try {
        userpaymnetServices.setNewIBANForWallet(
          ibanDetails.account_iban,
          ibanDetails.company_address,
          ibanDetails.city,
          ibanDetails.postal_code,
          0,
          0,
          ibanDetails.company_name,
          ibanDetails.first_name,
          ibanDetails.last_name,
          ibanDetails.email,
          undefined,
          0,
          undefined,
          ibanDetails.id
        ).then((res) => {
          if (res && res.status) {
            showSuccess("Successfully Edited")
          } else {
            dispatch(showMessage("unsucess", _l("l_error"), res.message));
          }
        })
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    }

  }
  

  return (
    
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{ isForAdd ? _l("l_iban_add") :  _l("l_iban_edit")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-lg-6 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_iban_edit")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_account_iban_placeholder")}
                  type="text"
                  name=""
                  value={ibanDetails.account_iban}
                  onChange={(e) => {
                    const reg = new RegExp("^[A-Za-z0-9 ]+$");
                    const inputValue = e.target.value;
                    if (reg.test(inputValue) || inputValue == ""){
                      setIBanDetails({...ibanDetails, account_iban : e.target.value});
                    }
                  }}
                />
              </Form.Group>
              <Form.Group className="col-lg-6 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_company_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_company_name")}
                  type="text"
                  name=""
                  value={ibanDetails.company_name}
                  onChange={(e) => {
                    const reg = new RegExp("^[A-Za-z0-9d, ]+$");
                    const inputValue = e.target.value;
                    if (reg.test(inputValue) || inputValue == ""){
                      setIBanDetails({...ibanDetails,company_name : inputValue});
                    }
                  }}
                />
              </Form.Group>
              <Form.Group className="col-lg-6 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_city")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_city_placeholder")}
                  type="text"
                  name=""
                  value={ibanDetails.city}
                  onChange={(e)=>{
                    setIBanDetails({...ibanDetails,city : e.target.value});

                  }}
                />
              </Form.Group>
              <Form.Group className="col-lg-6 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_postal_code")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_postal_address_placeholder")}
                  type="number"
                  name=""
                  value={ibanDetails.postal_code}
                  onChange={(e)=>{
                    setIBanDetails({...ibanDetails,postal_code : e.target.value});
                  }}
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_billing_address")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_billing_address_placeholder")}
                  type="text"
                  as="textarea"
                  rows={4}
                  name=""
                  value={ibanDetails.company_address}
                  onChange={(e) => {
                    setIBanDetails({...ibanDetails,company_address : e.target.value})
                  }}
                />

              </Form.Group>
              <Form.Group className="col-12 c-input-box position-relative mb-3 for-disabled">
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Check
                      type="checkbox"
                      label={"Stripe"}
                      id="disabled-default"
                      className="me-3"
                      checked={paymentaccountTab == "Stripe"}
                      onClick={() => {
                        setPaymentaccountTab("Stripe");
                      }}
                      disabled={true}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Form.Check
                      type="checkbox"
                      label={"Gocardless"}
                      id="disabled-default"
                      className="me-3"
                      checked={paymentaccountTab == "Stripe"}
                      onClick={() => {
                        setPaymentaccountTab("Stripe");
                      }}
                      disabled={true}

                    />
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_cancel")}
          </Button>
          <Button className={`${loader || addLoader ? "for-disabled" : ""}`} variant="primary" size="sm" onClick={()=>{
            if (isForAdd) {
              addNewIBAN();
            } else {
              editIBANDetails();
            }
          }}>{ loader || addLoader ? _l("l_please_wait") : _l("l_save")}
          {loader || addLoader? 
          <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
          : <></>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IBanEditModal;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l } from "../../hooks/utilities";
import { UserList, ArrowSquareOut, Hash, At, CalendarBlank, Plus, ChatCircle, UserCircleGear, PushPin, Repeat, Circle,PlayCircle,CheckCircle,X } from "phosphor-react";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";

const ToolsInformationModal = ({ show, handleClose }) => {
    const [IframeLink, setIframeLink] = useState("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4");
    const [IframeBoxDesc, setIframeBoxDesc] = useState("l_status_description");
    const [IframeBoxTitle, setIframeBoxTitle] = useState("l_status");
    const [Icondisplay, setIconDisplay] = useState("Circle");
    const [WatchVideo,setWatchVideo]=useState(false);
    
    var links = [
        { endpoint: '/america' },
        { endpoint: '/canada' },
        { endpoint: '/norway' },
        { endpoint: '/bahamas' }
      ];
      const listItems = links.map((link) =>
        Icondisplay == "Circle" ? ( <li className="d-flex align-items-start pb-2" key={link.endpoint}>
            <div className="h20w20 d-flex">
                <CheckCircle size={16} weight="light" class="c-icons" />
            </div>
                <div className="w100minus20 ps-2">{link.endpoint}</div>
            </li>)
        :  <></>
    );

    const ToolsBoxArray = [
        {
            title: _l("l_status"),
            subtitle: _l("l_upload_to_complete_anything"),
            type: "Circle",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4");
                setIframeBoxDesc("l_status_description");
                setIframeBoxTitle("l_status");
            },
        },
        {

            title: _l("l_priority"),
            subtitle: _l("l_add_a_flame_for_urgent_things"),
            type: "HighPriorityActiveIcon",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4");
                setIframeBoxDesc("l_urgent_description");
                setIframeBoxTitle("l_urgent");
            },
        },
        {
            title: _l("l_transform"),
            subtitle: _l("l_convert_anything_into_a_task"),
            type: "Repeat",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4");
                setIframeBoxDesc("l_transform_description");
                setIframeBoxTitle("l_transform");
            },
        },
        {
            title: _l("l_pin"),
            subtitle: _l("l_make_you_remind_anything_important"),
            type: "PushPin",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4");
                setIframeBoxDesc("l_pin_description");
                setIframeBoxTitle("l_pin");
            },
        },
        {
            title: _l("l_assign"),
            subtitle: _l("l_assign_anything_to_anyone"),
            type: "UserCircleGear",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4");
                setIframeBoxDesc("l_assign_description");
                setIframeBoxTitle("l_assign");
            },
        },
        {
            title: _l("l_comments"),
            subtitle: _l("l_anything_invoice_estimate_etc"),
            type: "ChatCircle",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4");
                setIframeBoxDesc("l_comment_description");
                setIframeBoxTitle("l_comments");
            },
        },
        {
            title: _l("l_create"),
            subtitle: _l("l_anything_topic_User_space_etc"),
            type: "Plus",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4");
                setIframeBoxDesc("l_create_description");
                setIframeBoxTitle("l_create");
            },
        },
        {
            title: _l("l_calendar"),
            subtitle: _l("l_choose_a_date_due_or_start_date"),
            type: "CalendarBlank",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4");
                setIframeBoxDesc("l_calendar_description");
                setIframeBoxTitle("l_calendar");
            },
        },
        {
            title: _l("l_mention"),
            subtitle: _l("l_someone_for_notify_him"),
            type: "At",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4");
                setIframeBoxDesc("l_mention_description");
                setIframeBoxTitle("l_mention");
            },
        },
        {
            title: _l("l_about"),
            subtitle: _l("l_relate_to_anything"),
            type: "Hash",
            handler: () => {
                setIframeLink("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4");
                setIframeBoxDesc("l_about_description");
                setIframeBoxTitle("l_about");
            },
        },
    ];
    const IframeBoxArray = [

        {
            iframe: "",
            subtitle: "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",

        },
    ];
    const FaIconDynamic = ({ type }) => {
        const FaIcon = components[type];
        return <FaIcon size={`${type == "Plus" ? "16" : "20"}`} className={`m-auto ${type === 'Circle' || type === 'PushPin' ? "Push-Pin-fill" : ""}`} color={`${type == "CalendarBlank" || type == "At" || type == "UserCircleGear" || type == "ChatCircle" || type == "Hash" || type == "Repeat" ? "currentColor" : type == "Plus" ? "#000" : "currentColor"}`} weight={`${type == "Circle" || type == "PushPin" ? "fill" : "light"}`} />

    }

    const components = {
        UserList,
        ArrowSquareOut,
        Hash,
        At,
        CalendarBlank,
        Plus,
        ChatCircle,
        UserCircleGear,
        PushPin,
        Repeat,
        HighPriorityActiveIcon,
        Circle
    };
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="custom-modal-style"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="xl"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_l("l_tools_information")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4 pb-0">
                    <div className="form-wrapper-main comman_action_icon pt-0">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">
                                    {ToolsBoxArray.length > 0 && ToolsBoxArray.map((toolBox, index) => {
                                        return (
                                            <a href="#/" key={index} className={` col-xl-6 col-12 mb-4 ${index == "8" || index == "9" ? "pt-4 border-top" : ""} `} onClick={() => {
                                                toolBox.handler()
                                                setIconDisplay(toolBox.type)
                                            }}>
                                                <div className={`tools-main-box bg-white-03 p-15 d-flex align-items-center c-list-icon radius_5 h-100 ${toolBox.type == Icondisplay ? "active" : ""}`}>
                                                    <div className={`h40w40  d-flex align-items-center justify-content-center p-1 action_icon with_bg position-relative`}>
                                                        <span class={`rounded-pill badge-custom p-0 ${index == "5" ? "" : "d-none"}`}>8</span>
                                                        {index == "6" ? (
                                                            <div className="h25w25 rounded-circle  bg-gradient-green d-flex">
                                                                <FaIconDynamic className="m-auto" type={toolBox.type} />
                                                            </div>
                                                        ) : (
                                                            <FaIconDynamic className="m-auto" type={toolBox.type} />
                                                        )}
                                                    </div>
                                                    <div className="c-list-detail c-font f-14 ps-3 w100minus40">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="listing pe-3 w100minus30">
                                                                <div className="c-font f-14 fw-semibold title-fonts text-truncate">{toolBox.title}</div>
                                                                <div className="c-font color-white-60 f-12 line-clamp-2">{toolBox.subtitle}</div>
                                                            </div>
                                                            <div className="action_icon align-items-center border d-flex h30w30 p-1 radius_3">
                                                                <ArrowSquareOut size={16} className="c-icons" weight="light" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="description-part">
                                    <div className="desc-head pb-4">
                                        <div className="d-flex ">
                                            <div className={`h40w40  d-flex p-1 action_icon with_bg light-theme-white-bg`}>
                                                <div className={`h25w25 rounded-circle d-flex align-items-center justify-content-center m-auto ${Icondisplay == "Plus" ? "bg-gradient-green" : ""}`}>
                                                    <FaIconDynamic className="m-auto" type={Icondisplay} />
                                                </div>
                                            </div>
                                            <div className="w100minus40 ps-3">
                                                <div className="title-fonts fw-semibold c-font f-16 pb-2">{_l(IframeBoxTitle)}</div>
                                                <div className="color-white-80 pb-3">{_l(IframeBoxDesc)}</div>
                                                <Button variant="white-03" onClick={()=>{
                                                    setWatchVideo(true)
                                                }} >
                                                    <div className="d-flex align-items-center">
                                                        <PlayCircle size={14} weight="light" class="c-icons" />
                                                        <span className="ps-2">{_l("l_how_it_works")}</span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="key-features border-top pt-4">
                                        <div className="title-fonts fw-semibold pb-2">{_l("l_key_features")}</div>
                                        <div className="features-desc">
                                           
                                                    {
                                                        Icondisplay =="Circle" ? (
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_status_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_status_key_feature_2")}</div>
                                                                </li>
                                                            </ul>
                                                        ) : Icondisplay =="HighPriorityActiveIcon" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_priority_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_priority_key_feature_2")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="Repeat" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_recurrent_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_recurrent_key_feature_2")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="PushPin" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_pin_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_pin_key_feature_2")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="UserCircleGear" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_assign_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_assign_key_feature_1")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="ChatCircle" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_comment_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_comment_key_feature_1")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="Plus" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_add_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_add_key_feature_2")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="CalendarBlank" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_calendar_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_calendar_key_feature_2")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="At" ?  ( 
                                                            <ul>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_mention_key_feature_1")}</div>
                                                                </li>
                                                                <li className="d-flex align-items-start pb-2">
                                                                    <div className="h20w20 d-flex">
                                                                        <CheckCircle size={16} weight="light" class="c-icons" />
                                                                    </div>
                                                                    <div className="w100minus20 ps-2">{_l("l_mention_key_feature_2")}</div>
                                                                </li>
                                                            </ul>
                                                        )
                                                        : Icondisplay =="Hash" ?  ( <ul>
                                                            <li className="d-flex align-items-start pb-2">
                                                                <div className="h20w20 d-flex">
                                                                    <CheckCircle size={16} weight="light" class="c-icons" />
                                                                </div>
                                                                <div className="w100minus20 ps-2">{_l("l_about_key_feature_1")}</div>
                                                            </li>
                                                            <li className="d-flex align-items-start pb-2">
                                                                <div className="h20w20 d-flex">
                                                                    <CheckCircle size={16} weight="light" class="c-icons" />
                                                                </div>
                                                                <div className="w100minus20 ps-2">{_l("l_about_key_feature_2")}</div>
                                                            </li>
                                                        </ul>)
                                                        : <></>
                                                    }
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                WatchVideo ? (
                    <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    className="custom-modal-style p-3"
                    fullscreen
                >
                    <a href="#/" className="p-2 end-0 position-absolute top-0" onClick={()=>{setWatchVideo(false)}} > 
                        <X size={24} weight="light" class="c-icons " />
                    </a>
                    <iframe className="radius_3 w-100 h-100" src={IframeLink} allow="autoplay"></iframe>
                </Modal>
                ) : <></>
            }
           
        </>
    )
}

export default ToolsInformationModal
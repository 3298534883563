import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { _l } from "../../hooks/utilities";
import IconComponentWrapper from "./IconComponentWrapper";
import { MagnifyingGlass, Paperclip } from "phosphor-react";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import { Spinner } from "react-bootstrap";
import { getDefaultTopics } from "../../actions/customer";


const EditProfileCategoryIcon = ({ show, onClose, setGroupIconURL  }) => {


    const { groupDetails } = useSelector((state) => state.chat);
    const [selectedPic, setSelectedPic] = useState("https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg")
    const [iconType, setIconType] = useState("")
    const [imgFiles, setImgFiles] = useState(null)
    const [searchString, setSearchString] = useState("")
    const [loader, setLoader] = useState(false)
    const staffId = localStorage.getItem("staff_id")
    const contactId = localStorage.getItem("contact_id")
    const clientId = localStorage.getItem("client_id")
    const [IconComponent, SetIconComponent] = useState(<></>)
    const dispatch = useDispatch()

    const uploadImg = () => {
        setLoader(true);
        const { mainKey, mainSubKey, filterKey } = groupDetails.extraData;
        customerServices.addClientTopicLogo(mainKey, mainSubKey, filterKey, staffId, contactId, clientId, iconType, imgFiles)
        .then((res) => {
            if (res.status == 1) { 
                setLoader(false)
                dispatch(getDefaultTopics());
                dispatch(showMessage("sucess", _l("l_sucess"), _l(res.message)));
                onClose()
            } else {
                dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
                setLoader(false)
            }
        })
    }

    return (
       
            <Modal
                className="modal fade custom-modal-style overflow-hidden"
                tabIndex="-1"
                aria-labelledby="addNewCategoryModal"
                aria-hidden="true"
                size="lg"
                show={show}
                onHide={onClose}
                centered
            >
                <Modal.Header closeButton className="">
                    <Modal.Title>{_l('l_set_topic_icon')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-hiiden-auto with-custom-height80vh p-0">
                    <div className="form-wrapper-main pt-0">
                        <div className="custom-modal-section-sticky">
                            <div className="p-3 text-center  pb-1">
                                <div
                                    className="h100w100 comman-round-box rounded-circle m-auto bg-white-05 d-flex align-items-center justify-content-center"
                                >
                                {
                                    iconType == "icon" 
                                    ? <IconComponent size={52}/>
                                    : <div
                                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                            style={{ backgroundImage: `url('${selectedPic}')` }}
                                        >
                                        </div>
                                }
                                </div>

                            </div>
                            <div className="comment-input-box flex-grow-1  p-4">
                            <div className="input_box_main with_file_upload"  
                                 
                                 
                                 >
                                <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center ">
                                    <Form.Control
                                        className="hidden-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => {
                                            if (event.target.files && event.target.files[0] != null) {
                                                const imgPrview = URL.createObjectURL(event.target.files[0])
                                                setIconType("file")
                                                setSelectedPic(imgPrview);
                                                setGroupIconURL({ file: imgPrview, type: "file" });
                                                setImgFiles(event.target.files)
                                            }
                                        }}
                                    />
                                    <div className="custom_file">
                                        <Paperclip size={18} className="upload_icon c-icons" weight="light" />
                                        <div className="btn btn-sm btn-white-10 border-0 bg-white-10-solid-imp">
                                            <span className="c-font f-10 color-white-60"> {_l('l_upload_file')}</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="border-bottom mt-2"></div>
                        </div>
                        <div className="row flex-wrap gap-4 p-20">
                            <IconComponentWrapper  
                                searchVal={searchString}
                                setIcon={(Icon) => {
                                    setIconType("icon")
                                    setSelectedPic(Icon.render.displayName)
                                    setImgFiles(Icon.render.displayName)
                                    setGroupIconURL({ file: Icon, type: "icon" })
                                    SetIconComponent(Icon)
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top">
                    <div className="d-flex align-items-center justify-content-betweeen flex-grow-1">
                        <div className={`align-items-center commn-search d-flex justify-content-between position-relative radius_3 rounded-0 topicsearch z-index-25 flex-grow-1`} >
                            <div className="comment-input-box  flex-grow-1">
                              <input
                                type="text"
                                className="form-control  d-flex align-items-center border-0 comman_action_icon p-0 h-42px shadow-none"
                                placeholder={`${_l(
                                  "l_search"
                                )}`}
                                value={searchString}
                                onChange={(e) => setSearchString(e.target.value)}
                            />
                            </div>
                            <a href="#/" className="comman_action_icon d-flex ">
                              <div className="d-flex action_icon h32w32 with_bg align-items-center m-auto shadow-none commn-search-bg light-theme-grey-light-bg-imp light-theme-border">
                                <MagnifyingGlass
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </div>
                            </a>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                    <Button variant="secondary" size="" onClick={onClose}>
                        {_l('l_close')}
                    </Button>
                    <Button variant="primary" size="" onClick={uploadImg}>
                        { loader
                            ? _l("l_please_wait")
                            : _l("l_save")
                        }
                        { loader ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className={`ms-2 `}
                                aria-hidden="true"
                            />
                        ) : (
                            <></>
                        )}
                    </Button>
                    </div>
                </Modal.Footer>
            </Modal>

    );
};

export default EditProfileCategoryIcon;

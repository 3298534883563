import React, { useEffect, useRef, useState } from "react";
import { getClientIdByProject, _l, getDatePickerLocale, getPreviewFromURL } from "../../../hooks/utilities";
import Form from 'react-bootstrap/Form';
import { Plus, Minus, CalendarBlank, UploadSimple, X, ArrowSquareRight, MapTrifold } from "phosphor-react";
import DatePicker from "react-datepicker";
import ScheduleFooter from "./ScheduleFooter";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { createSchedule, createScheduleDetails, getFloorDetails, updateTypeOfSpaceList } from "../../../actions/benchmark-actions/benchmark-actions";
import addDays from "date-fns/addDays";
import { showMessage } from "../../../actions/messages";
import {
    getBenchmarkDetail, getBenchmarkList

} from "../../../actions/customer";
import CustomerServices from "../../../services/customer-services";
 
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";

const CreteScheduleType = ({ handleStep, isCreateBenchmark, isEdit, readOnlyMode }) => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [basicDetails, setBasicDetails] = useState({ amount: 0, isUnKnownBudget: false });
    const stepSize = 50;

    const [_3DPlansList, set_3DPlansList] = useState([]);
    const { createScheduleDetail, floorDetails } = useSelector((state) => state.benchmarkReducer);
    const { benchmarkDetail, benchmarkList } = useSelector(
        (state) => state.customer
    );

    const [imagePreviews, setImagePreviews] = useState([]);
    const [contractPreviews, setContractPreviews] = useState([]);
    const [selectContractFiles, setSelectContractFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [existingAttachments, setExistingAttachments] = useState([]);
    const [existingContractAttachments, setExistingContractAttachments] =
        useState([]);
    const [updatedContractFiles, setUpdatedContractFiles] = useState([]);
    const [updatedSelectedFiles, setUpdatedSelectedFiles] = useState([]);
    const [updatedFiles, setUpdatedFiles] = useState([]);
    const [updatedSelectedContractFiles, setUpdatedSelectedContractFiles] =
        useState([]);
    const [typeOfSpaceList, setTypeOfSpaceList] = useState([]);

    const dispatch = useDispatch();
    const userType = localStorage.getItem("user_type");
    const projectId = useSelector((state) => state.customer.selectedProject);
    const unKnownbudget = useRef();
    let clientId = useRef(0);
    const [loader, setLoader] = useState(false);
    let startDateValidationMessage = "";

    useEffect(() => {
        if (floorDetails && floorDetails.type_of_space && floorDetails.type_of_space.length) {
            setTypeOfSpaceList(floorDetails.type_of_space);
        }
    }, [floorDetails])

    useEffect(() => {
        if (createScheduleDetail && Object.keys(createScheduleDetail).length) {
            setBasicDetails(createScheduleDetail)
        } else {
            setBasicDetails({})
        }
    }, [JSON.stringify(createScheduleDetail)]);

    useEffect(() => {
        if (isCreateBenchmark) {
            CustomerServices.get3DPlans(projectId).then((res) => {
                if (res.status && res.data) {
                    set_3DPlansList(res.data);
                }
            });
        }

        if (!isEdit) {
            dispatch(getFloorDetails(projectId))
        }

        clientId.current = getClientIdByProject();
    }, [projectId]);

    useEffect(() => {
        if (benchmarkDetail) {
            let previewArray = [];
            let previewContractArray = [];
            let attachmentsArray = [];
            let attachmentsContractArray = [];
            if (benchmarkDetail.attachments) {
                benchmarkDetail.attachments.map((img) => {
                    if (img.is_contract_file == 1) {
                        if (img.attachment_url && img.attachment_url != "") {
                            previewContractArray.push({
                                name: img.attachment_url.split("/").pop(),
                                url: img.attachment_url,
                                file_id: img.file_id,
                            });
                            attachmentsContractArray.push({
                                file_id: img.file_id,
                                file_name: img.attachment_url.split("/").pop(),
                            });
                        }
                    } else {
                        if (img.attachment_url && img.attachment_url != "") {
                            previewArray.push({
                                name: img.attachment_url.split("/").pop(),
                                url: img.attachment_url,
                                file_id: img.file_id,
                            });
                            attachmentsArray.push({
                                file_id: img.file_id,
                                file_name: img.attachment_url.split("/").pop(),
                            });
                        }
                    }
                });
                setImagePreviews(previewArray);
                setExistingAttachments(attachmentsArray);
                setExistingContractAttachments(attachmentsContractArray);
                setContractPreviews(previewContractArray);
            }

        }
    }, [benchmarkDetail]);

    const updateDate = (date, type) => {
        dispatch(createScheduleDetails({ ...basicDetails, [`${type}`]: date }));
    }

    const handleSave = (tab) => {
        if (tab == 'prev') {
            setLoader(false)
            handleStep('SelectCategory')
            return
        }
        handleSaveSchedule();
    };

    const getBasicDetails = (key) => {
        return basicDetails && basicDetails[`${key}`] ? basicDetails[`${key}`] : ''
    };

    const updateUnKnownDate = (value, type) => {
        dispatch(createScheduleDetails({ ...basicDetails, [`${type}`]: value }));
    };

    const updateBasicInfo = (basicInfo) => {
        dispatch(createScheduleDetails({ ...basicDetails, [`basicInfo`]: basicInfo }));
    };

    const preparePreview = (
        files,
        remove = false,
        flag = "",
        previewRemoveUrl = ""
    ) => {
        let previewArray = [];
        Array.from(files).map((img) => {
            previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
        });

        if (remove) {
            if (flag != "") {
                setContractPreviews(previewArray);
            } else {
                setImagePreviews(previewArray);
            }
        } else {
            if (flag != "") {
                setContractPreviews(contractPreviews.concat(previewArray));
            } else {
                setImagePreviews(imagePreviews.concat(previewArray));
            }
        }
    };

    const selectFiles = (event, flag = "") => {
        const extensions = [
            "png",
            "jpg",
            "pdf",
            "doc",
            "docx",
            "xls",
            "xlsx",
            "txt",
            "jpeg"
        ];
        if (event.target.files) {
            for (let file = 0; file < event.target.files.length; file++) {
                let fileExtension = event.target.files[file].name.split(".").pop();
                let fileSize = event.target.files[file].size;

                if (extensions.indexOf(fileExtension) == -1) {
                    var message = flag != "" ? _l("l_upload_contract") : _l("l_document");
                    dispatch(
                        showMessage("unsucess", _l('l_error'), _l("l_please_upload_valid") + ' ' + message)
                    );
                    return false;

                }
                //10mb => 10485760
                if (fileSize > 10485760) {
                    dispatch(
                        showMessage("unsucess", _l('l_error'), _l("l_please_upload_maximum_20kb"))
                    );
                    return false;
                }
            }
        }
        if (getBasicDetails('benchmarkId') && parseInt(getBasicDetails('benchmarkId')) > 0) {
            let previewArray = [];
            Array.from(event.target.files).map((img) => {
                previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
            });
            if (flag != "") {
                // setUpdatedContractFiles(previewArray);
                setUpdatedSelectedContractFiles(
                    Array.from(updatedSelectedContractFiles).concat(
                        Array.from(event.target.files)
                    )
                );
            } else {
                // setUpdatedFiles(previewArray);
                setUpdatedSelectedFiles(
                    Array.from(updatedSelectedFiles).concat(
                        Array.from(event.target.files)
                    )
                );
            }
        } else {
            const fileListLength =
                flag != ""
                    ? Array.from(selectContractFiles).length
                    : Array.from(selectedFiles).length;
            if (fileListLength > 0) {
                if (flag != "") {
                    setSelectContractFiles(
                        Array.from(selectContractFiles).concat(
                            Array.from(event.target.files)
                        )
                    );
                } else {
                    setSelectedFiles(
                        Array.from(selectedFiles).concat(Array.from(event.target.files))
                    );
                }
            } else {
                if (flag != "") {
                    setSelectContractFiles(event.target.files);
                } else {
                    setSelectedFiles(event.target.files);
                }
            }
        }
        preparePreview(event.target.files, false, flag);
    };

    const removeImage = (name, flag = "", fileId = 0, urlRmove) => {
        if (getBasicDetails('benchmarkId') && parseInt(getBasicDetails('benchmarkId')) > 0) {
            const targetImage = benchmarkDetail.attachments.filter((img) =>
                fileId != 0
                    ? img.file_id.includes(fileId)
                    : img.attachment_url.includes(name)
            );

            if (targetImage.length > 0) {
                CustomerServices.removeBenchmarkAttachment(
                    projectId,
                    targetImage[0].file_id,
                    parseInt(getBasicDetails('benchmarkId'))
                ).then((res) => {
                    if (res.status) {
                        if (flag != "") {
                            setContractPreviews(
                                contractPreviews.filter(
                                    (image) => image.file_id != targetImage[0].file_id
                                )
                            );
                        } else {
                            setImagePreviews(
                                imagePreviews.filter(
                                    (image) => image.file_id != targetImage[0].file_id
                                )
                            );
                        }
                    }
                });
            } else {
                if (flag != "") {
                    const updatedFileList = Array.from(
                        updatedSelectedContractFiles
                    ).filter((img) => img.name != name);
                    setUpdatedSelectedContractFiles(updatedFileList);
                    preparePreview(updatedFileList, true, flag, urlRmove);
                } else {
                    const updatedFileList = Array.from(updatedSelectedFiles).filter(
                        (img) => img.name != name
                    );
                    setUpdatedSelectedFiles(updatedFileList);
                    preparePreview(updatedFileList, true, "", urlRmove);
                }
            }
        } else {
            if (flag != "") {
                contractPreviews.forEach((thumbnail) => {
                    URL.revokeObjectURL(thumbnail.url);
                });
            } else {
                imagePreviews.forEach((thumbnail) => {
                    URL.revokeObjectURL(thumbnail.url);
                });
            }
            if (flag != "") {
                const updatedFileList = Array.from(selectContractFiles).filter(
                    (img) => img.name != name
                );
                setSelectContractFiles(updatedFileList);
                preparePreview(updatedFileList, true, flag);
            } else {
                const updatedFileList = Array.from(selectedFiles).filter(
                    (img) => img.name !== name
                );
                setSelectedFiles(updatedFileList);
                preparePreview(updatedFileList, true);
            }
        }
        if (flag != "") {
            setExistingContractAttachments(
                existingContractAttachments.filter((x) => x.file_name == name)
            );
        } else {
            setExistingAttachments(
                existingAttachments.filter((x) => x.file_name == name)
            );
        }
    };

    const validStartDate = () => {
        if (getBasicDetails('startDate') === undefined) {
            return true;
        } else if (endDate && moment(getBasicDetails('endDate')).diff(startDate) > 0) {
            return true;
        } else if (moment().diff(getBasicDetails('startDate')) <= -10) {
            return true;
        } else {
            if (moment().diff(getBasicDetails('startDate')) <= 10) {
                startDateValidationMessage = "l_please_select_valid_start_date";
            }
            else {
                startDateValidationMessage = "l_please_select_the_start_date";
            }
            return false;
        }
    };

    const handleSaveSchedule = () => {
        if (!getBasicDetails('isUnKnownStartDate') && !validStartDate()) {

            dispatch(
                showMessage("unsucess", _l('l_error'), _l(startDateValidationMessage))
            );
            setLoader(false);
            return false
        } else if (!unKnownbudget.current.checked && getBasicDetails('amount') <= 0) {
            let tempName = userType == "operator" ? _l("l_amount") : _l("l_budget");
            dispatch(
                showMessage("unsucess", _l('l_error'), _l("l_please_add") + ' ' + tempName)
            );
            setLoader(false);
            return false

        } else {
            let scheduleEmail = getBasicDetails('selectedSchedule').email ? getBasicDetails('selectedSchedule').email : "";
            let contract_end_date = getBasicDetails('endDate') != "" ? moment(new Date(getBasicDetails('endDate'))).format("YYYY-MM-DD") : "";

            CustomerServices.addEditBenchmarkStep1(
                projectId,
                getBasicDetails('isUnKnownStartDate') ? "" : getBasicDetails('startDate'),
                isCreateBenchmark ? getBasicDetails('selectedLinks') ? getBasicDetails('selectedLinks') : [] : [],
                isCreateBenchmark ? getBasicDetails('needToHire') != '' ? getBasicDetails('needToHire') : false : null,
                isCreateBenchmark ? getBasicDetails('furnished') != '' ? getBasicDetails('furnished') : false : null,
                getBasicDetails('providerCategory'),
                selectedFiles,
                getBasicDetails('benchmarkId') != '' && getBasicDetails('master_id') == 0 ? getBasicDetails('benchmarkId') : 0,
                getBasicDetails('basicInfo'),
                getBasicDetails('isUnKnownBudget') ? "" : getBasicDetails('amount'),
                getBasicDetails('isUnKnownStartDate') ? 1 : 0,
                getBasicDetails('isUnKnownBudget') ? 1 : 0,
                [],
                isCreateBenchmark ? "" : scheduleEmail,
                selectContractFiles,
                getBasicDetails('isUnKnownStartDate')
                    ? ""
                    : contract_end_date != "1970-01-01"
                        ? contract_end_date
                        : "",
                clientId.current,
                isCreateBenchmark ? getBasicDetails('selectedBenchmarkOperator') : [],
                0, !isCreateBenchmark, getBasicDetails('master_id')
            ).then((res) => {
                if (res.status && res.data) {
                    if (!getBasicDetails('benchmarkId') || getBasicDetails('benchmarkId') < 1 || getBasicDetails('master_id') > 0) {
                        dispatch(createScheduleDetails({ ...basicDetails, ['benchmarkId']: res.data.benchmark_id }));

                        let tempBudget = getBasicDetails('isUnKnownBudget') ? "0" : getBasicDetails('amount');
                        let newSchedule = [
                            {
                                id: res.data.benchmark_id,
                                recursive: "1",
                                total_task: "0",
                                start_date: getBasicDetails('startDate'),
                                is_schedule: "1",
                                staff_id: localStorage.getItem("staff_id"),
                                provider_id: getBasicDetails('providerCategory'),
                                max_budget: "€ " + tempBudget,
                                end_date: getBasicDetails('isUnKnownStartDate') ? "" : contract_end_date,
                                project_id: projectId,
                                schedule_type: "Schedule",
                                status_text: "draft",
                                status: 1,
                                assignees: [],
                                creator_details: {
                                    staff_id: localStorage.getItem("staff_id"),
                                    full_name: localStorage.getItem("full_name"),
                                    profile_image_url: localStorage.getItem("contact_image"),
                                },
                                category: getBasicDetails('selectedSchedule').provider_name
                                    ? getBasicDetails('selectedSchedule').provider_name
                                    : "",
                            },
                        ];
                        dispatch(createSchedule(newSchedule[0]));
                        newSchedule.concat(
                            benchmarkList && benchmarkList.length ? benchmarkList : []
                        );

                        dispatch(getBenchmarkList(projectId, getBasicDetails('providerCategory'), newSchedule));
                        dispatch(
                            getBenchmarkDetail(
                                res.data.benchmark_id,
                                clientId.current
                            )
                        );
                    } else {
                        if (
                            (updatedSelectedFiles &&
                                Array.from(updatedSelectedFiles).length) ||
                            (updatedSelectedContractFiles &&
                                Array.from(updatedSelectedContractFiles).length)
                        ) {
                            CustomerServices.addNewAttachmentToBenchmark(
                                projectId,
                                getBasicDetails('benchmarkId'),
                                Array.from(updatedSelectedFiles).length
                                    ? updatedSelectedFiles
                                    : "",
                                Array.from(updatedSelectedContractFiles).length
                                    ? updatedSelectedContractFiles
                                    : ""
                            ).then((res) => {
                                if (res.status) {
                                    setUpdatedFiles([]);
                                    setUpdatedSelectedFiles([]);
                                    setUpdatedContractFiles([]);
                                    setUpdatedSelectedContractFiles([]);
                                    dispatch(
                                        getBenchmarkDetail(
                                            getBasicDetails('benchmarkId'),
                                            clientId.current
                                        )
                                    );
                                }
                            });
                        }
                        dispatch(createScheduleDetails(basicDetails))
                    }

                    dispatch(getBenchmarkList(projectId, getBasicDetails('providerCategory')));

                    handleStep('create-schedule')
                } else {
                    dispatch(
                        showMessage("unsucess", _l('l_error'), res.message ? res.message : _l("l_something_went_wrong"))
                    );
                    return false
                }
                if (res.status == 1) {
                    return true
                }
            }).finally(() => {
                setLoader(false);
            });

        }

    };

    const selectTypeOfSpace = (spaceIds, checked) => {
        dispatch(updateTypeOfSpaceList(spaceIds, checked));
    };

    const allSpaceSelected = () => {
        let selectedSpaces = typeOfSpaceList.filter((x) => x.checked && x.is_default != 1);
        return selectedSpaces.length > 0 && selectedSpaces.length === (typeOfSpaceList.length - 1); //Subtracting the default type of space
    }

    return (
        <div className={`d-flex flex-column h-100 `}>
            <div className="flex-grow-1 overflow-hiiden-auto">
                <Form.Group className={`${readOnlyMode ? 'for-disabled' : ''}`}>
                    <Form.Group className=" c-input-box pb-3 position-relative ">
                        <div className="d-flex align-items-center justify-content-between">
                        <Form.Label className="input-label no-asterisk">{_l('l_type_of_space_label')}</Form.Label>
                       
                            {typeOfSpaceList && typeOfSpaceList.length > 2
                                ?
                                <div className="d-flex align-items-center res-space-between py-2"      >
                                    
                                    <div className="form-check d-flex justify-content-end align-items-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={allSpaceSelected()} onChange={(e) => { selectTypeOfSpace(typeOfSpaceList.filter(x => x.is_default != 1).map((x) => x.id), e.target.checked) }}
                                            disabled={readOnlyMode || (isEdit && getBasicDetails('is_allow_to_edit') && getBasicDetails('is_allow_to_edit') == 0)}

                                        />
                                    </div>
                                    <Form.Label className="input_label pe-4 form-label p-0 ms-2 c-font f-13">
                                        {allSpaceSelected() ? _l("l_deselect_all") : _l("l_select_all")}
                                    </Form.Label>
                                </div>
                                :
                                <React.Fragment></React.Fragment>
                            }
                            </div>
                            <div className="d-flex flex-wrap">
                            {
                                typeOfSpaceList && typeOfSpaceList.length ?
                                    typeOfSpaceList.map((space, index) => {
                                        return (
                                            <div key={index} className={`checkbox-border-wrapper with-fix-width position-relative ${Boolean(space.checked) ? 'active' : ''} ${space.is_default == 1 ? 'd-none' : ''}`}>

                                                <div className="checkbox-with-border">
                                                    <div className="form-label mb-0 title-fonts w-100">
                                                        <div className="d-flex align-items-center w-100">
                                                            <div className="position-relative">
                                                                <Form.Check type="checkbox" checked={Boolean(space.checked)} onChange={(e) => { selectTypeOfSpace([space.id], e.target.checked) }} />
                                                            </div>
                                                            <div className="check-text text-truncate with_separator_10 d-flex w-100 c-font f-14 ps-10px">
                                                                <input type="text" className="bg-transparent border-0 text-start line-height-1 flex-grow-1 text-truncate" placeholder="Space Name"     disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 
                                            </div>
                                        )
                                    })
                                    :
                                    <></>
                            }
                            </div>

                    </Form.Group>
                    <div className="row">
                        <Form.Group className="col-xl-4 flex-grow-1 c-input-box pb-3 position-relative">
                            <Form.Label className="input-label no-asterisk">
                                {_l('l_service_start_date')}
                            </Form.Label>
                            <div
                                className={`custom-datepicker w-100 react-datepicker-wrapper-w-100 ${(getBasicDetails('is_allow_to_edit') || getBasicDetails('benchmarkId') == "" || !readOnlyMode) ? "" : "for-disabled" || getBasicDetails('isUnKnownStartDate') ? "for-disabled" : ""}`}
                                data-bs-toggle="tooltip"

                            >
                                <div className="d-flex align-items-center form-control  color-white-60 dropdown w-100">
                                    <CalendarBlank size={18} weight="light" className="c-icons" />
                                    <DatePicker
                                        className="w-100"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        selected={!getBasicDetails('isUnKnownStartDate') && getBasicDetails('startDate') ? getBasicDetails('startDate') : ''}
                                        onChange={(date) => {
                                            dispatch(createScheduleDetails({ ...basicDetails, startDate: date, isUnKnownStartDate: false }));
                                        }}
                                        disabledKeyboardNavigation
                                        placeholderText={_l('l_select_start_date')}
                                        locale={getDatePickerLocale()}
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        minDate={isCreateBenchmark ? addDays(new Date(), 10)  : addDays(new Date(), 1)}
                                        defaultMenuIsOpen
                                        readOnly={(getBasicDetails('is_allow_to_edit') && !getBasicDetails('isUnKnownStartDate') || getBasicDetails('benchmarkId') == "") ? false : getBasicDetails('is_allow_to_edit') || getBasicDetails('isUnKnownStartDate')}
                                        calendarStartDay={1}
                                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                </div>
                            </div>
                            <div className="form-check with-span py-0">
                                <div className="d-flex align-items-center">
                                    <Form.Label className="form-check-label mt-2 mb-0 w-100">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) => {

                                                updateUnKnownDate(e.target.checked, 'isUnKnownStartDate')
                                            }}
                                            checked={getBasicDetails('isUnKnownStartDate')}
                                            // disabled={!isUnKnownStartDate && startDate && benchmarkStatus}
                                            readOnly={getBasicDetails('is_allow_to_edit') && !getBasicDetails('isUnKnownStartDate') ? false : getBasicDetails('is_allow_to_edit') || getBasicDetails('isUnKnownStartDate')}

                                        />
                                        <span className="c-font f-12 ps-1">{_l("l_dont_know_my_service_start_date")} </span>
                                    </Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        {
                            isCreateBenchmark ? <></> :
                                <Form.Group className="col-xl-4 flex-grow-1 c-input-box pb-3 position-relative">
                                    <Form.Label className="input-label no-asterisk">
                                        {_l('l_contract_reminder_end_date')}
                                    </Form.Label>
                                    <div
                                        className="custom-datepicker w-100 react-datepicker-wrapper-w-100"
                                        data-bs-toggle="tooltip"
                                        title={moment().format("DD/MM/YYYY")}
                                    >
                                        <div className="d-flex align-items-center form-control  color-white-60 dropdown w-100">
                                            <CalendarBlank size={18} weight="light" className="c-icons" />
                                            <DatePicker
                                                className="w-100"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                selected={getBasicDetails('isUnKnownStartDate') ? '' : getBasicDetails('endDate')}
                                                onChange={(date) => updateDate(date, 'endDate')}
                                                locale={getDatePickerLocale()}
                                                disabledKeyboardNavigation
                                                placeholderText={_l('l_contract_reminder_end_date')}
                                                dateFormat="dd/MM/yyyy"
                                                selectsStart
                                                defaultMenuIsOpen
                                                minDate={getBasicDetails('startDate') != "" ? addDays(getBasicDetails('startDate'), 30) : ""}
                                                calendarStartDay={1}

                                            ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                        </div>
                                    </div>
                                </Form.Group>
                        }

                        <Form.Group className="col-xl-4 flex-grow-1 c-input-box d-none">
                            <Form.Label className="input-label no-asterisk  color-white-60">
                                {_l("l_approximate")}{" "}
                                {userType == "operator" ? _l("l_amount") : _l("l_budget")}
                            </Form.Label>
                            <div className="d-flex align-items-center justify-content-between form-control">
                                <a href="#/"
                                    className="c-font f-22 line-height-0"
                                    onClick={() => {
                                        if (getBasicDetails('amount') > stepSize && !getBasicDetails('isUnKnownBudget')) {
                                            let tempBudget = getBasicDetails('amount') != '' ? getBasicDetails('amount') : 0
                                            setBasicDetails({ ...basicDetails, [`amount`]: parseInt(tempBudget) - stepSize, ['isUnKnownBudget']: false })
                                        }

                                    }}

                                >
                                    <Minus size={18} weight="light" className="c-icons" />
                                </a>
                                <input
                                    type="number"
                                    className="bg-transparent border-0 text-center line-height-1"
                                    placeholder="0"
                                    name="budget"
                                    onChange={(e) => {
                                        if (parseInt(e.target.value))
                                            setBasicDetails({ ...basicDetails, [`amount`]: parseInt(e.target.value) })

                                    }}
                                    value={getBasicDetails('isUnKnownBudget') ? "" : getBasicDetails('amount')}
                                />
                                <a href="#/"
                                    className="c-font f-22 line-height-0"
                                    onClick={() => {
                                        if (!getBasicDetails('isUnKnownBudget')) {
                                            let tempBudget = getBasicDetails('amount') != '' ? getBasicDetails('amount') : 0
                                            setBasicDetails({ ...basicDetails, [`amount`]: parseInt(tempBudget) + stepSize, ['isUnKnownBudget']: false })
                                        }
                                    }}

                                >
                                    <Plus size={18} weight="light" className="c-icons" />
                                </a>
                            </div>
                            <div className="form-check with-span py-0">
                                <div className="d-flex align-items-center">
                                    <Form.Label className="form-check-label mt-2 mb-0 w-100">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"

                                            ref={unKnownbudget}
                                            onChange={(e) => setBasicDetails({ ...basicDetails, [`isUnKnownBudget`]: e.target.checked })}
                                            checked={getBasicDetails('isUnKnownBudget')}

                                        />
                                        <span className="c-font f-12 ps-1">
                                            {_l("l_approximate_i_dont_know_my")}{" "}
                                            {userType == "operator" ? _l("l_amount") : _l("l_budget")}
                                        </span>
                                    </Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        {
                            isCreateBenchmark ?
                                <div className="col-6 d-flex flex-column justify-content-center pb-3 ">
                                    <div className="form-control">
                                        <div className="3d-map-box">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <MapTrifold size={18} weight="light" className="c-icons" />
                                                    <div className="title-fonts ms-2">{_l('l_3d_map')}</div>
                                                </div>
                                                <div className="c-input-box w-50 position-relative">
                                                    {_3DPlansList.length ? (
                                                        _3DPlansList.map((link, index) => {
                                                            let isChecked = getBasicDetails('selectedLinks') != '' && getBasicDetails('selectedLinks').length
                                                                ? getBasicDetails('selectedLinks').filter(
                                                                    (selectLink) => selectLink.link == link.link
                                                                )
                                                                : [];
                                                            return (
                                                                <React.Fragment>
                                                                    <div className="d-flex comment-input-box w-100 text-end">
                                                                        <div className="form-check-label text_wrap me-2 mb-0 w-100">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type="checkbox"
                                                                                // defaultChecked={false}
                                                                                checked={isChecked.length ? true : false}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        let tempLink = getBasicDetails('selectedLinks') != '' ? getBasicDetails('selectedLinks') : []
                                                                                        // setBasicDetails({ ...basicDetails, ['selectedLinks']: [...tempLink, link] })
                                                                                        dispatch(createScheduleDetails({ ...basicDetails, ['selectedLinks']: [...tempLink, link] }));

                                                                                    } else {
                                                                                        let tempLink = getBasicDetails('selectedLinks').filter(
                                                                                            (linktemp) =>
                                                                                                linktemp.link != link.link
                                                                                        );
                                                                                        dispatch(createScheduleDetails({ ...basicDetails, ['selectedLinks']: tempLink }));
                                                                                        // setBasicDetails({ ...basicDetails, ['selectedLinks']: tempLink })
                                                                                    }
                                                                                }}
                                                                                disabled={readOnlyMode || (isEdit && getBasicDetails('is_allow_to_edit') && getBasicDetails('is_allow_to_edit') == 0)}

                                                                            />
                                                                            <a href="#/" className="" style={{ 'max-width': 'calc(100% - 26px)' }}
                                                                                 
                                                                                 
                                                                                 >{link.link} </a>
                                                                            <div className="send-arrow-btn with_position_right_zero d-none">
                                                                                <ArrowSquareRight size={18} weight="light" className="c-icons" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                     
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            isCreateBenchmark ? <></> :
                                <Form.Group className="col-xl-6 flex-grow-1 c-input-box">
                                    <Form.Label className="input-label no-asterisk">
                                        {_l("l_contract_upload")}
                                    </Form.Label>
                                    {
                                        readOnlyMode
                                        ?
                                        <React.Fragment></React.Fragment>
                                        :
                                        <div className="form-control file-upload position-relative p-3">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <input
                                                    className="z-index-3 hidden-input"
                                                    type="file"
                                                    multiple
                                                    onInput={(e) => selectFiles(e, "contract")}
                                                    accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.jpeg"
                                                    disabled={readOnlyMode || (isEdit && getBasicDetails('is_allow_to_edit') && getBasicDetails('is_allow_to_edit') == 0)}
                                                />
                                                <UploadSimple size={18} weight="light" className="c-icons m-0" />
                                                <span className="color-white-60 ps-2">
                                                    {_l("l_browse_file")}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    <div className="upload-image-preview d-flex flex-wrap p-0 mt-2 for-accessible">
                                        {/* <div className={`comman_top_header p-0 ${isSchedule == '1' ? '' : selectedSchedule ? Object.keys(selectedSchedule).length == 0 ? 'd-none' : '' : 'd-none'}`}>
                </div> */}
                                        {contractPreviews.length ? (
                                            contractPreviews.map((preview, index) => {
                                                return (
                                                    <li className="p-2" key={index}>
                                                        <div className="comman-image-box h50w50 radius_3 upload-image ">
                                                            <div
                                                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                                                style={{ backgroundImage: `url(${preview.url})` }}
                                                            ></div>
                                                            <a href="#/"
                                                                className={`upload-close ${readOnlyMode ? 'd-none' : ''}`}
                                                                onClick={() => {
                                                                    removeImage(
                                                                        preview.name,
                                                                        "contract",
                                                                        preview.file_id ? preview.file_id : 0,
                                                                        preview.url
                                                                    );
                                                                }}

                                                            >
                                                                <X size={10} weight="light" className="c-icons" />
                                                            </a>
                                                        </div>
                                                        <div className="image-text pt-2 h50w50 h-auto">
                                                            <div className="text-truncate c-font f-11">
                                                                {" "}
                                                                {preview.name}{" "}
                                                            </div>
                                                            <div className="text-truncate c-font f-11 color-white-60 d-none">
                                                                size : 50.00 KB
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        ) :
                                        readOnlyMode && !contractPreviews.length
                                            ?
                                                <CommanPlaceholder imgType="no-images" placeholderText = {_l("l_no_attachment")} />
                                            :
                                         (
                                            <></>
                                        )}
                                    </div>
                                </Form.Group>
                        }

                        <Form.Group className="col-xl-6 c-input-box flex-grow-1 pb-2">
                            <Form.Label className="input-label no-asterisk">
                                {_l("l_document_upload")}
                            </Form.Label>
                            {
                                readOnlyMode
                                ?
                                    <React.Fragment></React.Fragment>
                                :
                                    <div className="form-control file-upload position-relative p-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input
                                                className="z-index-3 hidden-input"
                                                type="file"
                                                multiple
                                                onInput={(e) => selectFiles(e)}
                                                accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.jpeg"
                                                disabled={readOnlyMode || (isEdit && getBasicDetails('is_allow_to_edit') && getBasicDetails('is_allow_to_edit') == 0)}
                                            />
                                            <UploadSimple size={18} weight="light" className="c-icons m-0" />
                                            <span className="color-white-60 ps-2">
                                                {_l("l_browse_file")}
                                            </span>
                                        </div>
                                    </div>
                            }
                            <div className="upload-image-preview d-flex flex-wrap p-0 mt-2 for-accessible">
                                {/* <div className="comman_top_header p-0"></div> */}
                                {imagePreviews && imagePreviews.length ? (
                                    imagePreviews.map((preview, index) => {
                                        let attachment = {};
                                        attachment.url = getPreviewFromURL(preview.name);

                                        return (
                                            <li className="p-2" key={index}>
                                                <div className="comman-image-box h50w50 radius_3 upload-image ">
                                                    {
                                                        typeof attachment.url === "string"
                                                            ?

                                                            <div
                                                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                                                style={{ backgroundImage: `url(${preview.url})` }}
                                                                onClick={() => window.open(preview.url, '_blank')}
                                                                 
                                                                 
                                                                 
                                                            ></div>
                                                            :
                                                            <div className="h-100 w-100 d-flex cursor-pointer" onClick={() => window.open(preview.url, '_blank')} style={{
                                                                alignContent: 'center',
                                                                flexWrap: 'wrap'
                                                            }}>
                                                                <attachment.url size={72} weight="light" className="c-icons" />
                                                            </div>
                                                    }
                                                    <a href="#/"
                                                        className={`upload-close ${readOnlyMode ? 'd-none' : ''}`}
                                                        onClick={() => {
                                                            removeImage(
                                                                preview.name,
                                                                "",
                                                                preview.file_id ? preview.file_id : 0,
                                                                preview.url
                                                            );
                                                        }}
                                                    >
                                                        <X size={10} weight="light" className="c-icons" />
                                                    </a>
                                                </div>
                                                <div className="image-text pt-2 h50w50 h-auto">
                                                    <div className="text-truncate c-font f-11">
                                                        {" "}
                                                        {preview.name}{" "}
                                                    </div>
                                                    <div className="text-truncate c-font f-11 color-white-60 d-none">
                                                        size : 50.00 KB
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                ) :
                                readOnlyMode && !imagePreviews.length
                                ?
                                    <CommanPlaceholder imgType="no-images" placeholderText = {_l("l_no_attachments")} />
                                :
                                 (
                                    <></>
                                )}
                            </div>
                        </Form.Group>

                        <Form.Group className="col-xl-12 c-input-box pb-3 pt-2 for-accessible">
                            <Form.Control
                                as="textarea"
                                placeholder={`${_l("l_enter_details_placeholder")}`}
                                aria-label="With textarea"
                                value={getBasicDetails('basicInfo')}
                                onChange={(e) => {
                                    updateBasicInfo(e.target.value)
                                }}
                                disabled={readOnlyMode || (isEdit && getBasicDetails('is_allow_to_edit') && getBasicDetails('is_allow_to_edit') == 0)}
                            />
                        </Form.Group>
                        <Form.Group
                            className={`col-xl-12 mb-2  ${!isCreateBenchmark ? "d-none" : ""}`}
                        >
                            <div className="row">
                                <div className="col-xl-4 flex-grow-1">
                                    <div className="d-flex align-items-center res-space-between py-2">
                                        <Form.Label className="input_label pe-4">
                                            {_l("l_need_to_hire_people")}
                                        </Form.Label>
                                        <div className="form-check form-switch d-flex justify-content-end align-items-center">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                checked={getBasicDetails('needToHire')}
                                                onChange={(e) => {
                                                    dispatch(createScheduleDetails({ ...basicDetails, [`needToHire`]: e.target.checked }));
                                                    // setBasicDetails({ ...basicDetails, [`needToHire`]: e.target.checked })

                                                }}
                                                disabled={readOnlyMode || (isEdit && getBasicDetails('is_allow_to_edit') && getBasicDetails('is_allow_to_edit') == 0)}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 flex-grow-1">
                                    <div className="d-flex align-items-center res-space-between py-2">
                                        <Form.Label className="input_label pe-4">
                                            {_l("l_is_furnished")}
                                        </Form.Label>
                                        <div className="form-check form-switch d-flex justify-content-end align-items-center">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                checked={getBasicDetails('furnished')}
                                                onChange={(e) => {
                                                    dispatch(createScheduleDetails({ ...basicDetails, [`furnished`]: e.target.checked }));
                                                    // setBasicDetails({...basicDetails, [`furnished`]: e.target.checked })
                                                }}
                                                disabled={readOnlyMode || (isEdit && getBasicDetails('is_allow_to_edit') && getBasicDetails('is_allow_to_edit') == 0)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </div >
                </Form.Group>
            </div>
            <div className={`d-flex mt-auto w-100 `}>
                {!readOnlyMode ?
                    <ScheduleFooter currentStep={2} selectedTab={`create-schedule-type`} handleStep={handleSave} isCreateBenchmark={isCreateBenchmark} providerCategory={basicDetails.providerCategory} loader={loader} setLoader={setLoader} isEdit={isEdit} /> :
                    <></>
                }
            </div>
        </div>
    );
};

export default CreteScheduleType;
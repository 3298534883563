import React, { useEffect, useState } from "react";
import { MagnifyingGlass } from "phosphor-react";
import { _l } from "../../hooks/utilities";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { getDefaultTopics } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { DropdownItem, DropdownMenu } from "reactstrap";

const SapceListDropdown = ({
  openSpaceListDropdown,
  SpaceList,
  setOpenSpaceListDropdown,
  handler,
  selectedSpace,
  isFromTaskCard = false,
  unContolList=false,
  setSelectedSpace=()=>{}
}) => {
    const {virtualSpaceList, leftPanelTab} = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [searchSpace, setsearchSpace] = useState()
  const [spaceListItems, setspaceListItems] = useState([])

  useEffect(() => {
    SpaceList = SpaceList.concat(virtualSpaceList)
    let tempselectSpace = SpaceList.filter((space) =>space.id == selectedSpace || space.project_id == selectedSpace)
    let tempSpace = SpaceList.filter((space) =>space.id != selectedSpace && space.project_id != selectedSpace)
    setspaceListItems(tempselectSpace.concat(tempSpace))
    
  }, [SpaceList])
  const SapceDropdownContain = (
    <>
    <DropdownItem className="p-0 no-hover" toggle={false}>
        <div className="dropdown-header d-flex  w-100">
          <div className="comment-input-box flex-grow-1">
            <input type="text"
              className="form-control input_caret_transparent bg-transparent with-base-bg border-0 flex-grow-1 p-0 c-list-detail c-font f-14  fw-normal title-fonts text-truncate color-white"
              value={_l("l_all_spaces")} />
          </div>
        </div>
        <li>
          <hr className="dropdown-divider m-0" />
        </li>
        <div className="comman-search-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
          <div className="d-flex  w-100">
            <div className="comment-input-box flex-grow-1">
              <input id="contactSearch" type="text"
                className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font"
                placeholder={`${_l("l_search")}`} value={searchSpace} onChange={(e)=> {
              setsearchSpace(e.target.value);
              }}
              />
            </div>
            <a href="#/" className="comman_action_icon">
              <div className="d-flex h-100">
                <div className="action_icon h25w25 h-100">
                  <MagnifyingGlass size={14} weight="light" className="c-icons" />
                </div>
              </div>
            </a>
          </div>
        </div>
        </DropdownItem>
      <div className="overflowscroll">
        {spaceListItems.length > 0 && spaceListItems.filter((space)=> space.office_title &&
        space.office_title.toLowerCase().includes(searchSpace && searchSpace.length > 0 ? searchSpace.toLowerCase() : "") ||
        space.name && space.name.toLowerCase().includes(searchSpace && searchSpace.length > 0 ? searchSpace.toLowerCase() :
        "")).map((space, index) => {
        return (
        <>
          <li>
            <a href="#/" key={index} className={`dropdown-item text_wrap 
            ${(selectedSpace && selectedSpace.length > 0 
              ? selectedSpace.includes(space.project_id) 
              : selectedSpace !=undefined && selectedSpace !=0 && (space.project_id ? space.project_id == selectedSpace : space.id==selectedSpace)) 
                  ? 'active with-tick' : '' } `}
            onClick={()=> {
              handler(space)
              setSelectedSpace(space.project_id)
              if(isFromTaskCard = false && leftPanelTab != "my-providers"){
              dispatch(getDefaultTopics(space.project_id,"","","",[]));
              }
    
              }}
              >
              <div className="d-flex align-items-center">
                <div className="c-list-icon">
                  <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage:
                      `url('${space.logo}')`, }}></div>
                  </div>
                </div>
                <div className="ps-2 w100minus20 text_wrap">
                  <div onClick={()=> setOpenSpaceListDropdown(false)} className="c-list-detail c-font f-14 text-truncate color-white">
                    {space.office_title || space.name}
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <hr className="dropdown-divider my-0" />
          </li>
        </>
        );
        })}
      </div>
      {SpaceList && SpaceList.length == 0 ?
      <CommanPlaceholder imgWidth="h60w60" placeholderText={_l('l_no_space')} />
      :
      <></>}
    </>
  )

  return (
    <>
      {
        unContolList ? (
          <DropdownMenu container="body" direction="down" className={`w-200 `}>{SapceDropdownContain}</DropdownMenu>
        ) : (
          <ul className="dropdown-menu w-200" aria-labelledby="SapceListDropdown">{SapceDropdownContain}</ul>
        )
      }
    </>
  );
};

export default SapceListDropdown
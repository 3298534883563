import React, { useEffect, useRef, useState } from 'react'
import { _l, getScheduleTitle } from '../../hooks/utilities'
import { CaretDoubleDown, ChatCircle, ChatDots, DotsNine } from 'phosphor-react'
import CommentMinified from '../Comments/CommentMinified'
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import CommanPlaceholder from '../Placeholder/CommanPlaceholder';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

const ScheduleTable = ({data}) => {
  const {  providerlistTableData } = useSelector((state) => state.customer);
  const [scheduleData, setScheduleData] = useState(
      data.data && data.data.length > 0 ? data.data : []
    );
  const [spacePage, setSpacePage] = useState(1);
  const [loder, setLoder] = useState({
  spaceLoader: false,
  });
  
  const statusClassArr = {
    1: "text-warning",
    2: "text-danger",
    3: "c-text done",
    4: "c-text in-progress-blue",
    5: "text-danger",
  };
  let statusClass = useRef();

  const scroll = (status) => {
  let page;
  let taskStaus = status;
  if (status == 1) {
      page = spacePage + 1;
      setSpacePage(page);
  }
  taskStaus = [status];
  if (status == 1) {
      setLoder({ ...loder, ["spaceLoader"]: false });
  }
  };
  function getStatusText(item) {
      if (item.status == 3 || item.status == 4) {
        if (!item.startdate) {
          statusClass.current = "text-warning";
          return _l("l_waiting_for_start_date");
        } else if (item.is_assigneed == 0) {
          statusClass.current = "text-warning";
          return _l("l_waiting_for_assignee");
        } else {
          statusClass.current = "text-done";
          return item.status_text;
        }
      } else {
        statusClass.current = "text-done";
        return item.status_text;
      }
  }

  const openScheduleModal = (item) => {
    let externalURL = "";
    externalURL = `/connect-provider?space=${item.project_id}&provider-type=schedule&id=${item.id}`;
    if (externalURL !== "") {
      window.open(externalURL, "_blank");
      return;
    }
  }
  useEffect(() => {
    setScheduleData(providerlistTableData.schedule);
  }, [JSON.stringify(providerlistTableData)])
  return (
    <>
            <table className="dataTable text-center tablewithborder">
        <thead className="comman-white-box-shadow">
          <tr>
            <th style={{ width: "15%" }} className="text-start">
              {_l("l_schedule_name")}
            </th>
            <th style={{ width: "6%" }} className="text-start">
              {_l("l_number_of_item")}
            </th>
            <th style={{ width: "3%" }} className="text-start">
              {_l("l_status")}
            </th>
            <th style={{ width: "1%" }} className="text-center">
              {_l("l_start_date")}
            </th>
            <th style={{ width: "5%" }} className="text-center">
              {_l("l_end_date")}
            </th>
            <th style={{ width: "2%" }} className="text-center">
              {_l("l_amount")}
            </th>
            <th style={{ width: "2%" }} className="text-start">
              {_l("l_type")}
            </th>
            <th style={{ width: "2%" }} className="text-center">
              {_l("l_action")}
            </th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
          {scheduleData && scheduleData.length ? (
              <>
                  {scheduleData .slice(
                      0,
                      spacePage > 1 ? (spacePage - 1) * 10 + 10 : spacePage * 10
                      ).map((item) => {
                  return (
                      <>
                      <tr>
                          <td className='pe-0'>
                              <a href='#/' className='name-with-progress  d-block' onClick={() => {openScheduleModal(item)} }>
                                  <div className='d-flex align-items-center'>
                                      <div className='h35w35 comman-round-box with-bg rounded-circle bg-white-05'>
                                          <div className='comman-bg-img h-100 w-100 bg-style-cover rounded-circle' style={{
                                          backgroundImage: `url('https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                                        }}></div>
                                      </div>
                                      <div className='ps-2 w100minus70 text-start flex-grow-1'>
                                          <div className='d-flex flex-column'>
                                              <div className='title-fonts text-truncate text-start max-width-200 fw-semibold'>{getScheduleTitle(item)}</div>
                                              <div className='c-font color-white-60 f-10 lh-1 location me-2 fw-normal'>{item.office_title}</div>
                                          </div>
                                      </div>
                                  </div>
                              </a>
                          </td>
                          <td className='title-fonts text-center'>{item.list_order || 0}</td>
                          <td className='comman_action_icon'>
                              <div className='d-flex align-items-center '>
                                  {/* <span className='c-dots c-7 rounded-circle me-2 bg-warning'></span> */}
                                  <span class={`c-font f-12 text-truncate text-uppercase ${statusClass.current}`}>{getStatusText(item)}</span>
                              </div>
                          </td>
                          <td class="title-fonts text-center text-truncate">{item.startdate ? moment(item.startdate).format("DD/MM/YYYY") : ''}</td>
                          <td class="title-fonts text-center text-truncate">{item.end_date ? moment(item.end_date).format("DD/MM/YYYY") : ''}</td>
                          <td class="title-fonts text-center color-green">{item.max_budget}</td>
                          <td class="title-fonts text-truncate text-start max-width75">
                            {item.recursive === "1"
                                  ? _l("l_recurrent")
                                  : _l("l_onetime")}
                          </td>
                          <td className='p-2'>
                              <div className='d-flex  align-items-center justify-content-end comman_action_icon'>
                                  {/* comment icon start */}
                                      <div
                                      className={`dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                                      >
                                      <UncontrolledDropdown className="w-100">
                                          <DropdownToggle
                                          data-toggle="dropdown"
                                          tag="span"
                                          className="comman_action_icon w-100 d-flex close-toast border-0"
                                          >
                                          <a
                                              href="#/"
                                               
                                               
                                               
                                               
                                          >
                                              <div className="d-flex align-items-center w-100">
                                              <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                                  <ChatCircle
                                                  size={18}
                                                  weight="light"
                                                  className="c-icons"
                                                  />
                                                  <span
                                                  className={`rounded-pill badge-custom p-0`}
                                                  >
                                                  0
                                                  </span>
                                              </div>
                                              {/* {isLiteVersion ? (<></>) : ( */}
                                              <span
                                                  className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                              >
                                                  0
                                              </span>
                                              {/* )} */}
                                              </div>
                                          </a>
                                          </DropdownToggle>
                                          {/* <DropdownItem toggle={false} className="p-0">
                                          {
                                              <CommentMinified
                                              
                                              />
                                          }
                                          </DropdownItem> */}
                                      </UncontrolledDropdown>
                                      </div>
                                      {/* comment icon end */}
                                      {/* Chat icon start */}
                                      
                                          <a href="#/" className='action_icon with_bg h32w32 light-theme-img close-toast me-10px'>
                                          <ChatDots
                                                  size={18}
                                                  weight="light"
                                                  className="c-icons"
                                                  />
                                          </a>
                                    
                                      {/* Chat icon end */}
                            <a href="#/" className={`action_icon h32w32 with_bg with-border-active`}>
                              <DotsNine size={18} weight="light" className="c-icons" />
                            </a>
                          </div>
                                    
                              
                          </td>
                      </tr>
                      </>
                  );
                  })}
                  <tr>
                      {(scheduleData.length >= (spacePage - 1) * 10 + 10 &&
                      scheduleData.length != (spacePage - 1) * 10 + 10) ||
                      loder.spaceLoader ? (
                      <>
                          <td className="border-0" colSpan={12}>
                          <a
                              href="#/"
                              className="btn btn-white-03 w-100 view-more-btn"
                              onClick={() => {
                              setLoder({ ...loder, ["spaceLoader"]: true });
                              scroll(1);
                              }}
                          >
                              <div className="d-flex align-item-center justify-content-center">
                              <span className=" title-fonts c-font f-12 ms-2">
                                  {_l("l_view_more_btn")} (
                                  {spacePage > 1
                                  ? scheduleData.length - ((spacePage - 1) * 10 + 10)
                                  : scheduleData.length - 10}
                                  )
                              </span>
                              <CaretDoubleDown
                                  size={12}
                                  className="c-icons mx-2"
                                  weight="light"
                              />
                              <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  className={`ms-2 ${
                                  loder.spaceLoader ? "opacity-1" : "opacity-0"
                                  }`}
                                  aria-hidden="true"
                              />
                              </div>
                          </a>
                          </td>
                      </>
                      ) : (
                      <></>
                      )}
                  </tr>
              </>
              ) : (
              <>
                  {" "}
                  <tr className="">
                  <td className="border-0" colSpan={8}>
                      <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                      <div className="d-flex flex-column align-items-center">
                          <CommanPlaceholder
                          imgType="request"
                          placeholderText={_l("l_no_data")}
                          />
                      </div>
                      </div>
                  </td>
                  </tr>
              </>
              )}
            
        </tbody>
      </table>
    </>
  )
}

export default ScheduleTable
import React from 'react'
import { _l, formateDate } from '../hooks/utilities'
import moment from 'moment';
import { DropdownItem, DropdownToggle,  UncontrolledDropdown } from 'reactstrap';
import UncontrolledGeneralListing from './TaskModalComponents/UncontrolledGeneralListing';
import { useSelector } from 'react-redux';
import User_2 from "../assets/images/default-image-placeholder.png";
import CommanPlaceholder from './Placeholder/CommanPlaceholder';
import $ from "jquery";
import CheckedDropdown from './Dropdowns/CheckedDropdown';
import CommentMinified from './Comments/CommentMinified';
import ImageUploadDropdown from './Dropdowns/ImageUploadDropdown';
import { ChatCircle, Image } from 'phosphor-react';


const CommanCustomFullcalendar = ({
    type_calendar,
    setDropdownToggle = () => {},
    daysOfWeek,
    calendarWeekData,
    calendarMonthData,
    dayofWek,
    events,
    activeTaskID = false,
    dropdownToggle = false,
    OpenCommanOffCanvasModal,
    selectedDateIndex,
    commonModalData
}) => {
    const {
        defaultTopics,
        commanoffcanvasIsOpen,
        mentionList,
        isDayGridWeek,
        isDayGridMonth,
        isDayGridDay,
        globalSearchString,
        displayThreeDays,
    } = useSelector((state) => state.customer);

    const statusClass = {
        1: "bg-notstarted",
        5: "bg-done",
        4: "bg-inprogress",
        3: "bg-request",
        16: "bg-done",
        28: "bg-yellow",
        30: "bg-inprogress"
      };

  return (
    <div className='comman-contnet-wrapper h_calc77 mt-10 border-0 comman-main-box h-100 ps-10px m-0 pt-10px padding-bottom-100px' onMouseLeave={() =>{setDropdownToggle(false)}}>
        {/* week view start */}
        {isDayGridWeek  ? <div className='CustomFullCalendar Weekview d-flex flex-column' >
            {<div className={`modal-overlay`}></div>}
            {/* {<div className={`modal-overlay ${dropdownToggle && commanoffcanvasIsOpen == true ? "" : "box-shadow-none"}`}></div>} */}
            <div className='d-flex flex-grow-1' 
            // onMouseLeave={()=>{setDropdownToggle(false)}}
            >
                {calendarWeekData && calendarWeekData.length > 0 && calendarWeekData.filter((week,index)=> commanoffcanvasIsOpen ? index => (selectedDateIndex - 1) && index <= (selectedDateIndex + 1) : index > -1 && index < 7 ).map((week, index) => {
                    if(events.filter((event)=>{
                    let event_date = "";
                    if (event.startdate != null) {
                        event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                    }
                    return(week.date == moment(event_date).format("YYYY-MM-DD"))
                        }).length == 0 || events.length == 0){
                    return(<>
                    { commanoffcanvasIsOpen ? index >= (selectedDateIndex - 1) && index <= (selectedDateIndex + 1) ? 
                        <CommanPlaceholder mainWidth={`calednarPlacehoder`} imgType="request" placeholderText={_l("l_no_data")} />  :
                        <></>
                        :<CommanPlaceholder mainWidth={`calednarPlacehoder ${displayThreeDays ? "" : "w7days"}`} imgType="request" placeholderText={_l("l_no_data")} /> 
                    }
                    </>)
                    }else{
                    return (
                        <>
                        <div className={`Weekbox pb-5 ${index} ${displayThreeDays ? "width33per" : ""} ${commanoffcanvasIsOpen ? index >= (selectedDateIndex - 1) && index <= (selectedDateIndex + 1) ? "width33per" : "d-none" :""} `} key={index} >
                            {events && events.length > 0 && events.filter((event, index)=>event.name.toLocaleLowerCase().includes( globalSearchString.toLocaleLowerCase()) ).map((event, index) => {
                                let event_date = "";
                                if (event.startdate != null) {
                                    event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                                }
                                let task_assignee = [];
                                let taskData = event
                                if (type_calendar == "lead") {
                                    if (taskData.assigned_details && Object.keys(taskData.assigned_details).length > 0) {
                                        task_assignee.push({
                                            name: taskData.assigned_details.full_name,
                                            profile_image: taskData.assigned_details.profile_image,
                                            // id: assignee.filter_key,
                                        });
                                    }
                                } else {
                                    if (taskData.assignee_list_DEV && taskData.assignee_list_DEV.length > 0) {
                                        taskData.assignee_list_DEV.forEach((assignee) => {
                                            // if (taskData.schedule_assignees.length > 0) {
                                            //     task_assignee = [];
                                            //     task_assignee = taskData.schedule_assignees ? taskData.schedule_assignees : []
                                            // }

                                            if (assignee && assignee.categories && ["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(assignee.main_key)) {
                                                assignee && assignee.categories.map((taskAssign) => {
                                                    defaultTopics &&
                                                        defaultTopics.forEach((topic) => {
                                                            if (["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(topic.main_key)) {
                                                                if (topic.main_key == assignee.main_key) {
                                                                    topic.categories.forEach((provider) => {
                                                                        if (taskAssign.filter_key == provider.filter_key) {
                                                                            // if (taskData.schedule_assignees.length == 0) {
                                                                                task_assignee.push({
                                                                                    name: provider.title,
                                                                                    profile_image: provider.image,
                                                                                    id: provider.filter_key,
                                                                                });
                                                                            // }
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        });
                                                });
                                            }
                                        })
                                    }
                                }
                                if (week.date == moment(event_date).format("YYYY-MM-DD")) {
                                    return (
                                        <> 
                                            <div className={`WeekDetailbox calendar-box-bg radius_3 ${(type_calendar == "lead" ? index : event.id) == activeTaskID || (commonModalData && commonModalData.id == event.id) ? "z-index-7 opacity-100" : ""} ${dropdownToggle ? "CalendarOverlay" : ""}`} key={index}
                                                // onMouseEnter={(e) => {
                                                //     handleOnMouseEnter(e, event.id);
                                                //     setTaskcreateId(event.id);
                                                //     if (commanoffcanvasIsOpen == false) {
                                                //         setTempHoverTask(event)
                                                //         setEventInfo(e);
                                                //         setShowCalendarDropDown(false);
                                                //         if ($('.popoveraroowposition').hasClass('show')) {
                                                //             $('.popoveraroowposition').removeClass('show');
                                                //             setDropdownToggle(false);
                                                //             dispatch(SetSelectedTask(""))
                                                //         }
                                                //     }
                                                // }}
                                                // onMouseLeave={handleOnMouseLeave}
                                                onClick={() => {
                                                    OpenCommanOffCanvasModal(event, week, index)
                                                }}
                                            >
                                            <a href="#/" className={`WeekDetailHeader c-font f-12 d-block 
                                            ${event.is_requested && event.is_requested == 1 
                                                ? "bg-request" 
                                                : statusClass[event.status]}
                                            ${dropdownToggle ? "CalendarOverlay" : ""}`}
                                            onClick={()=>{
                                                // OpenCommanOffCanvasModal(event, week, index)
                                            }}>
                                                    <div className='d-flex align-items-center justify-content-center fw-semibold text-truncate'>
                                                        <div className='me-2'>{moment(event_date).format("DD/MM hh:mm a")}</div>
                                                        <div className='text-truncate'>{event.name}</div>
                                                    </div>
                                            </a>
                                            <div className='WeekDetail p-15 radius_3'>
                                                <a href="#/" className='CalendarProfile d-block'
                                                    onClick={(e) => {
                                                        if (!commanoffcanvasIsOpen && !$('.fade').hasClass('show') && !$('.dropdown-menu').hasClass('show')) {
                                                            // setselectedEventDate(week.date)
                                                            // OpenCommanOffCanvasModal(event, week, index)
                                                        }
                                                    }}>
                                                        <div className='position-relative   assignee-list-wrapper'
                                                        //  onMouseEnter={(e) => { handleOnMouseEnter(e, event.id) }} 
                                                        //  onMouseLeave={handleOnMouseLeave}
                                                            >
                                                            <a href="#/" className='multi_inner_wrapper with-min-width d-flex with_minus justify-content-start align-items-center'>
                                                                <div className='comman-image-box h50w50 rounded-circle one assignee-list-wrapper card-view-less-padding'>
                                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${task_assignee.length > 0 && task_assignee[0].profile_image ? task_assignee[0].profile_image :  User_2})` }}></div>
                                                                </div>
                                                            </a>
                                                            {task_assignee && task_assignee.length > 1 ?
                                                                <div className={`multi_image_wrapper dropdown-center dropdown`}>
                                                                    <div className="multi_inner_wrapper d-flex position-relative">
                                                                        
                                                                        {task_assignee && task_assignee.length > 1 ?
                                                                            <>
                                                                                <a href="#/" className={`comman-image-box with_ml_minus_10px h25w25 with_overlay d-flex rounded-circle border-2 `}>
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle tag="div" className="dropdown dropdown-center team-list-dropdown h25w25" >
                                                                                            <div
                                                                                                className="d-flex rounded-circle h-100"
                                                                                                type="button"
                                                                                                data-bs-toggle="dropdown"
                                                                                                data-bs-auto-close="outside"
                                                                                                aria-expanded="false"
                                                                                            >
                                                                                                <div className="unread_count m-auto">
                                                                                                    +{task_assignee ? task_assignee.length - 1 : " "}
                                                                                                </div>
                                                                                            </div>
                                                                                            <UncontrolledGeneralListing list={task_assignee ? task_assignee.map((data, index) => {
                                                                                                return {
                                                                                                    name: data.name,
                                                                                                    image: data.profile_image ? data.profile_image : '',
                                                                                                    id: index,
                                                                                                };
                                                                                            }) : <></>} />
                                                                                        </DropdownToggle>
                                                                                    </UncontrolledDropdown>
                                                                                </a>
                                                                            </>
                                                                            : <></>
                                                                        }

                                                                    </div>
                                                                     
                                                                </div> : <></>}
                                                        </div>
                                                    <div className='color-white-60 c-font f-14 pt-2 text-center text-truncate'>{task_assignee.length > 0 && task_assignee[0].name ? task_assignee[0].name : taskData.creator_name ? taskData.creator_name : ""}</div>
                                                </a>
                                                <div className='progress-slider-with-action-icon flex-grow-1 pt-1'>
                                                    <div className='slider-progress flex-grow-1 pb-10px c-font f-14'>
                                                        {/* <UncontrolledDropdown className='progress-slider w-100'>
                                                            <DropdownToggle data-toggle="dropdown" tag="a" className="progress-slider w-100" onClick={() => {
                                                                // setOpenCheckList(true)
                                                                // dispatch(getTaskChecklist(event.id, taskData.rel_id));
                                                            }}
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                data-bs-auto-close="outside"> */}
                                                                <div className='progress-slider w-100'> 
                                                                    <div className="progress-slider w-100">
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='progress'>
                                                                                <div className='progress-bar' style={{ width: `${event.checklist_progress}%` }}></div>
                                                                            </div>
                                                                            <div className='progress-text title-fonts color-white-80 text-center ps-2 c-font f-14'>{event.checklist_progress || 0}%</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            {/* </DropdownToggle>
                                                            <DropdownItem toggle={false} className="p-0"> */}
                                                                {/* <CheckedDropdown
                                                                    unContolList={true}
                                                                    setOpenCheckList={setOpenCheckList}
                                                                    project_id={taskData.rel_id}
                                                                    openCheckList={openCheckList}
                                                                    task_id={event.id}
                                                                    taskDetails={taskData}
                                                                    task_status={taskData.status}
                                                                    isSystemTask={
                                                                        taskData.task && parseInt(taskData.is_allowed_to_edit) != 1
                                                                            ? true
                                                                            : null
                                                                    }
                                                                    isChecklistPermission={
                                                                        taskData.task &&
                                                                            taskData.is_requested == 1 &&
                                                                            taskData.request_task_id > 0
                                                                            ? 1
                                                                            : 0
                                                                    }
                                                                    pageName={"calendar"}
                                                                /> */}
                                                            {/* </DropdownItem>
                                                        </UncontrolledDropdown> */}
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center comman_action_icon'>
                                                        <div className='dropup  d-flex align-items-center action_icon with_bg h32wauto me-10px'>
                                                            {/* <UncontrolledDropdown className='progress-slider w-100'>
                                                                <DropdownToggle tag={'a'} className='before-dnone before_dnone comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                    type="button"
                                                                    id="writeComMentDropdown"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    data-bs-auto-close="outside"
                                                                    onClick={() => {
                                                                        // getTaskComments(event.id);
                                                                        // getMentionlist(event.id);
                                                                        // setTaskcreateId(event.id);
                                                                    }}> */}
                                                                    <div className='progress-slider w-100'> 
                                                                        <div className="before-dnone before_dnone comman_action_icon d-flex radius_3 w-100 calender-action-icon">
                                                                            <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                <div className="position-relative">
                                                                                        <ChatCircle size={16} className="c-icons" weight="light" />
                                                                                        <span
                                                                                            className={`rounded-pill badge-custom p-0 ${parseInt(event.unread_comment_count) > 0
                                                                                                    ? ""
                                                                                                    : "d-none"
                                                                                                }`}
                                                                                            data-unread-cmt-id={event.id}
                                                                                        >
                                                                                            {event.unread_comment_count}
                                                                                        </span>
                                                                                </div>
                                                                                <span className="d-flex justify-content-center flex-grow-1">{event.comments_count}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                {/* </DropdownToggle>
                                                                <DropdownItem toggle={false} className="p-0"> */}
                                                                    {/* <CommentMinified
                                                                        comments={commentsList}
                                                                        disabled={
                                                                            leadDetailsData &&
                                                                            parseInt(leadDetailsData.request_task_id) > 0 }
                                                                        handleAddComment={handleAddEditComment}
                                                                        deleteComment={handleDeleteComment}
                                                                        id={event.id}
                                                                        loading={commentsLoading}
                                                                        mentionList={mentionList.map((name) => {
                                                                            let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                                                                            return {
                                                                                display: name.name,
                                                                                id: id,
                                                                                image: name.profile_image || "",
                                                                                isteam: name.is_team ? 1 : 0,
                                                                            };
                                                                            })}
                                                                        handleImagePreview={""}
                                                                        pageName={pageName}
                                                                        task={leadDetailsData}
                                                                        unContolList={true}
                                                                        showComments={showComments}
                                                                    /> */}
                                                                {/* </DropdownItem>
                                                            </UncontrolledDropdown> */}
                                                        </div>
                                                        <div className='dropup  d-flex align-items-center action_icon with_bg h32wauto'>
                                                            <div className='w-100'>
                                                                {/* <UncontrolledDropdown className='comman_action_icon w-100 d-flex close-toast border-0'>
                                                                    <DropdownToggle tag={'a'} className='before-dnone before_dnone comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                        type="button"
                                                                        id="imageLstAndUploadDropdown"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                        data-bs-auto-close="outside"
                                                                        onClick={(e) => {
                                                                            // setOpenAttachmentsDropdown(true);
                                                                            // setTempTaskId(event.id);
                                                                            // setTaskcreateId(event.id);
                                                                        }}> */}
                                                                        <div className='comman_action_icon w-100 d-flex close-toast border-0'>
                                                                            <div className="before-dnone before_dnone comman_action_icon d-flex radius_3 w-100 calender-action-icon">
                                                                                <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                    <div className="position-relative">
                                                                                        <Image size={16} className="c-icons" weight="light" />
                                                                                    </div>
                                                                                    <span className="d-flex justify-content-center flex-grow-1">{event.attachments_count || 0}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    {/* </DropdownToggle>
                                                                    <DropdownItem toggle={false} className="p-0"> */}
                                                                        {/* <ImageUploadDropdown
                                                                            unContolList={true}
                                                                            setOpenAttachmentsDropdown={
                                                                                setOpenAttachmentsDropdown
                                                                            }
                                                                            openAttachmentsDropdown={openAttachmentsDropdown}
                                                                            task_id={event.id}
                                                                            // project_id={projectId}
                                                                            updateImageCount={setAttchamentCount}
                                                                            Request_task_converted={
                                                                                leadDetailsData &&
                                                                                    parseInt(leadDetailsData.request_task_id) > 0 
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            taskDetails={event}
                                                                            pageName={pageName}
                                                                        /> */}
                                                                    {/* </DropdownItem>
                                                                </UncontrolledDropdown> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> </>)
                                }
                            })}
                        </div> 
                        </>)}
                })}
            </div>
        </div> : <></>}
        {/* week end */}

        {/* month view start */}
        {isDayGridMonth ? <div className='CustomFullCalendar Monthview '>
        <div className='modal-overlay'></div>
        <div className='d-flex flex-wrap MonthHeader light-theme-grey-light-bg-imp' onMouseLeave={()=>setDropdownToggle(false)}>

        {daysOfWeek.map((weekDay, index) => {
                return(<>
                <div className='MonthHeaderbox title-fonts c-font f-14 fw-semibold'>{_l(`l_${weekDay}`)}</div>
                </>)
        })}
        </div>
            <div className='d-flex flex-wrap border-start border-end'>
                    {calendarMonthData.filter((date, index) => index < dayofWek).map((month, key) => {
                        for (let i = 0; i < dayofWek; i++) {
                            return (<>
                                <div className='Monthbox disabled' >
                                    <div className='text-start title-fonts c-font f-14 fw-semibold p-15'></div>
                                </div>
                            </>)
                        }
                    })}
                {calendarMonthData && calendarMonthData.length > 0 && calendarMonthData.map((month, index) => {
                    return (<>
                        <div className='Monthbox' key={index}
                            style={{backgroundColor: `${(formateDate(month.date) < formateDate(localStorage.getItem("fromDate")) || formateDate(month.date) > formateDate(localStorage.getItem("toDate")))? "var(--color-white-03)": "" }`}}>
                            <div className='text-start title-fonts c-font f-14 fw-semibold p-15'>{moment(month.date).format("D")}</div>
                            {events && events.length > 0 && events.filter((event)=>event.name.toLocaleLowerCase().includes( globalSearchString.toLocaleLowerCase())).map((event, index) => {
                                let event_date = "";
                                if (event.startdate != null) {
                                    event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                                } else if (event.duedate && event.duedate != null) {
                                    event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                                } 
                                let task_assignee = [];
                                let taskData = event
                                if (type_calendar == "lead") {
                                    if (taskData.assigned_details && Object.keys(taskData.assigned_details).length > 0) {
                                        task_assignee.push({
                                            name: taskData.assigned_details.full_name,
                                            profile_image: taskData.assigned_details.profile_image,
                                            // id: assignee.filter_key,
                                        });
                                    }
                                } else {
                                    if (taskData.assignee_list_DEV && taskData.assignee_list_DEV.length > 0) {
                                        taskData.assignee_list_DEV.forEach((assignee) => {
                                            // if (taskData.schedule_assignees.length > 0) {
                                            //     task_assignee = [];
                                            //     task_assignee = taskData.schedule_assignees ? taskData.schedule_assignees : []
                                            // }
                                            if (assignee && assignee.categories && ["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(assignee.main_key)) {
                                                assignee && assignee.categories.map((taskAssign) => {
                                                    defaultTopics &&
                                                        defaultTopics.forEach((topic) => {
                                                            if (["myr_staff_task", "my_people", "task_topic", "my_operator"].includes(topic.main_key)) {
                                                                if (topic.main_key == assignee.main_key) {
                                                                    topic.categories.forEach((provider) => {
                                                                        if (taskAssign.filter_key == provider.filter_key) {
                                                                            // if (taskData.schedule_assignees.length == 0) {
                                                                                task_assignee.push({
                                                                                    name: provider.title,
                                                                                    profile_image: provider.image,
                                                                                    id: provider.filter_key,
                                                                                });
                                                                            // }
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        });
                                                });
                                            }
                                        })
                                    }
                                }
                                if ( month.date == moment(event_date).format("YYYY-MM-DD")) {
                                    return (<>
                                        {/* {[popoverPosition].map((placement, index) => (   */}
                                        <div className={`MonthEventbox p-5px d-flex align-items-center justify-content-between radius_3 m-1 cursor-pointer ${(type_calendar == "lead" ? index : event.id) == activeTaskID ? "z-index-7" : ""} 
                                        ${event.is_requested && event.is_requested == 1 
                                            ? "bg-request" 
                                            : statusClass[event.status]} 
                                        ${dropdownToggle ? "CalendarOverlay" : ""}`}
                                            onClick={() => {
                                                console.log("commanoffcanvasIsOpen", commanoffcanvasIsOpen);
                                                OpenCommanOffCanvasModal(event, month, index);
                                            }}
                                        >
                                            <a href="#/" className='c-font f-12  f-black d-flex align-items-center w100minus50 text-truncate' >
                                                 
                                                        <div className='d-flex text-truncate' 
                                                        >
                                                            <div className='me-1'>{moment(event_date).format("hh:mm a")}</div>
                                                            <div className='text-truncate'>{event.name}</div>
                                                        </div>
                                            </a>
                                            <div className='CalendarProfile '>
                                                <div className='multi_image_wrapper dropdown-center dropdown'>
                                                    <div className='multi_inner_wrapper d-flex position-relative '>
                                                        <a href="#/" className='comman-image-box h20w20 rounded-circle multi-wrapper-border me-1 border-2'>
                                                            <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url(${task_assignee.length > 0 && task_assignee[0].profile_image ? task_assignee[0].profile_image : event.schedule_id > 0 ? User_2 : taskData.creator_profile_image ? taskData.creator_profile_image : ""})` }}></div>
                                                        </a>
                                                            {task_assignee && task_assignee.length > 1 ?
                                                                <>
                                                                    <a href="#/" className={`comman-image-box h20w20 with_overlay d-flex rounded-circle border-2 `}>
                                                                        <UncontrolledDropdown className='w-100'>
                                                                            <DropdownToggle tag="a" className="dropdown dropdown-center team-list-dropdown w-100" >
                                                                                <div
                                                                                    className="d-flex rounded-circle"
                                                                                    type="button"
                                                                                    data-bs-toggle="dropdown"
                                                                                    data-bs-auto-close="outside"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <div className="c-font f-12 m-auto unread_count">
                                                                                        +{task_assignee ? task_assignee.length - 1 : " "}
                                                                                    </div>
                                                                                </div>
                                                                                <UncontrolledGeneralListing list={task_assignee ? task_assignee.map((data, index) => {
                                                                                    return {
                                                                                        name: data.name,
                                                                                        image: data.profile_image ? data.profile_image : '',
                                                                                        id: index,
                                                                                    };
                                                                                }) : <></>} />
                                                                            </DropdownToggle>
                                                                        </UncontrolledDropdown>
                                                                    </a>
                                                                </>
                                                                : <></>
                                                            }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ))} */}
                                    </>)
                                }
                            })}
                        </div>
                    </>)
                })}
            </div>
        </div> : <></>}
        {/* month view end  */}

        {/* day view start */}
        {isDayGridDay ? 
            <>{calendarWeekData && calendarWeekData.length > 0 && calendarWeekData.filter((data, index) => index > 2 && index < 4).map((day, index) => {
                if(events.filter((event)=>{let event_date = "";
                if (event.startdate != null) {
                    event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                } else if (event.duedate != null) {
                    event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                } else {
                    event_date = moment(event.dateadded).format("YYYY-MM-DD HH:mm:ss");
                }
                return(day.date == moment(event_date).format("YYYY-MM-DD"))
                    }).length == 0 && day.date == formateDate(localStorage.getItem("selectedDate"))){
                return(<>
                    <CommanPlaceholder mainWidth={` ${displayThreeDays ? "" : "w7days"}`} imgType="request" placeholderText={_l("l_no_data")} />
                
                </>)
                }else{
                    return (<>
            <div className='CustomFullCalendar Dayview' onMouseLeave={()=>setDropdownToggle(false)} >
                <div className='modal-overlay'></div>
                        {events && events.length > 0 && events.filter((event)=>event.name.toLocaleLowerCase().includes( globalSearchString.toLocaleLowerCase())).map((event, index) => {
                            let event_date = "";
                            if (event.startdate != null) {
                                event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                            } else if (event.duedate && event.duedate != null) {
                                event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                            } else if (event.dateadded) {
                                event_date = moment(event.dateadded).format("YYYY-MM-DD HH:mm:ss");
                            }
                            let task_assignee = [];
                            let taskData = event
                            if (type_calendar == "lead") {
                                if (taskData.assigned_details && Object.keys(taskData.assigned_details).length > 0) {
                                    task_assignee.push({
                                        name: taskData.assigned_details.full_name,
                                        profile_image: taskData.assigned_details.profile_image,
                                        // id: assignee.filter_key,
                                    });
                                }
                            } else {
                                if (taskData.assignee_list_DEV && taskData.assignee_list_DEV.length > 0) {
                                    taskData.assignee_list_DEV.forEach((assignee) => {
                                        // if (taskData.schedule_assignees.length > 0) {
                                        //     task_assignee = [];
                                        //     task_assignee = taskData.schedule_assignees ? taskData.schedule_assignees : []
                                        // }
                                        if (assignee && assignee.categories && ["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(assignee.main_key)) {
                                            assignee && assignee.categories.map((taskAssign) => {
                                                defaultTopics &&
                                                    defaultTopics.forEach((topic) => {
                                                        if (["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(topic.main_key)) {
                                                            if (topic.main_key == assignee.main_key) {
                                                                topic.categories.forEach((provider) => {
                                                                    if (taskAssign.filter_key == provider.filter_key) {
                                                                        // if (taskData.schedule_assignees.length == 0) {
                                                                            task_assignee.push({
                                                                                name: provider.title,
                                                                                profile_image: provider.image,
                                                                                id: provider.filter_key,
                                                                            });
                                                                        // }
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    });
                                            });
                                        }
                                    })
                                }
                            }
                            if (day.date == moment(event_date).format("YYYY-MM-DD")) {
                                return (<>
                                    {/* {[popoverPosition].map((placement, index) => ( */}
                                        <div className={`d-flex flex-column ${(type_calendar == "lead" ? index : event.id) == activeTaskID ? "z-index-7" : ""} `}
                                            // onMouseEnter={(e) => {
                                            // handleOnMouseEnter(e, event.id)
                                            // setTaskcreateId(event.id);
                                            //     if (commanoffcanvasIsOpen == false) {
                                            //         setTempHoverTask(event)
                                            //         setEventInfo(e);
                                            //         setShowCalendarDropDown(false);
                                            //         if ($('.popoveraroowposition').hasClass('show')) {
                                            //             $('.popoveraroowposition').removeClass('show');
                                            //             setDropdownToggle(false);
                                            //             dispatch(SetSelectedTask(""))
                                            //         }
                                            //     }
                                            // }}
                                            // onMouseLeave={handleOnMouseLeave}
                                            onClick={() => {
                                                // OpenCommanOffCanvasModal(event, day);
                                            }}>
                                            <div className={`daylist m-2 ${dropdownToggle ? "CalendarOverlay" : ""}`} onClick={()=>{
                                                    // OpenCommanOffCanvasModal(event, day);
                                            
                                            }}>
                                                <a href="#/" className={`DayViewHeader c-font f-12 p-5px radiustop d-block
                                                 ${event.is_requested && event.is_requested == 1 
                                                    ? "bg-request" 
                                                    : statusClass[event.status]}`} 
                                                onClick={()=>{
                                                    OpenCommanOffCanvasModal(event, day, index);
                                                }}>
                                                    <div className='d-flex align-items-center fw-semibold f-black'>
                                                        <div className='me-2'>{moment(event_date).format("hh:mm a")}</div>
                                                        <div className='text-truncate'>{event.name}</div>
                                                    </div>
                                                </a>
                                                <div className='WeekDetail p-15 calendar-box-bg'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                    {/* <OverlayTrigger
                                                                // trigger={['hover','focus']}
                                                                // "click",
                                                                delay={{ show: 2000 }}
                                                                key={index}
                                                                placement={placement}
                                                                onEnter={() => getspacedata()}
                                                                onToggle={(e) => { setDropdownToggle(e) }}
                                                                // show={SelectedTaskId == eventInfo.event.id }
                                                                show={(dropdownToggle == true) && tempHoverTask && tempHoverTask.id ? tempHoverTask.id == event.id : false}
                                                                // show={true}
                                                                overlay={
                                                                    <Popover id={`popover-positioned-${placement}`} className={` popoveraroowposition border-0 ${commanoffcanvasIsOpen == false ? selectedTask == undefined && selectedTask == "d-none" ? "w-400 " : "w-400 " : "d-none"}`}
                                                                        onMouseEnter={() => handleOnMouseEnter(event.id)}
                                                                        onMouseLeave={handleOnMouseLeave}>
                                                                        {commanoffcanvasIsOpen == false ? <>
                                                                            {selectedTask != undefined && selectedTask != "" ?
                                                                                <Popover.Body className="p-0">
                                                                                    <CalendarDropdown
                                                                                        projectId={selectedProject}
                                                                                        taskId={taskId}
                                                                                        providers={providers}
                                                                                        dayClick={dayClick}
                                                                                        meetingUrl={meetingUrl}
                                                                                        docType={"task"}
                                                                                        pageName={pageName}
                                                                                        data={{ contacts: shareContacts }}
                                                                                        isOutlookCalActive={isOutlookCalendarActive}
                                                                                        handler={handleSelectedTask}
                                                                                        selectCalIds={selectCalIds}
                                                                                        setShowTaskInfo={setShowTaskInfo}
                                                                                        taskNameOrStatusUpdate={(
                                                                                            task_id,
                                                                                            task_name_status,
                                                                                            flag = ""
                                                                                        ) => {
                                                                                            let eventUpdate = events.map((event) => {
                                                                                                if (task_id == event.id) {
                                                                                                    if (flag != "") {
                                                                                                        if (task_name_status == 1) {
                                                                                                            event["color"] = "#b3b3b3";
                                                                                                        } else if (task_name_status == 4) {
                                                                                                            event["color"] = "#03A9F4";
                                                                                                        } else if (task_name_status == 5) {
                                                                                                            event["color"] = "#00ff6a";
                                                                                                        }
                                                                                                    } else {
                                                                                                        event["title"] = task_name_status;
                                                                                                    }
                                                                                                    return event;
                                                                                                }
                                                                                                return event;
                                                                                            });
                                                                                            setEvents(eventUpdate);
                                                                                        }}
                                                                                        setActiveTaskID={setActiveTaskID}
                                                                                        isFromCalendar={true}
                                                                                        setSelectedTaskId={setEventInfo}
                                                                                        startdate={startDate}
                                                                                        endDate={endDate}
                                                                                        setDropdownToggle={setDropdownToggle}
                                                                                    />
                                                                                </Popover.Body>
                                                                                : <></>}
                                                                        </> : <></>}
                                                                    </Popover>
                                                                }
                                                            >  */}
                                                        <a href="#/" className='CalendarProfile flex-grow-1 w-75' 
                                                        onClick={(e) => {
                                                            if (!commanoffcanvasIsOpen && !$('.fade').hasClass('show') && !$('.dropdown-menu').hasClass('show')) {
                                                                OpenCommanOffCanvasModal(event, day, index);
                                                            }
                                                        }}
                                                        // onMouseEnter={(e) => { handleOnMouseEnter(e, event.id) }} 
                                                        // onMouseLeave={handleOnMouseLeave}
                                                        >
                                                            <div className='d-flex align-items-center'>
                                                                <div className='position-relative   assignee-list-wrapper'>
                                                                    <div className='multi_inner_wrapper with-min-width d-flex with_minus justify-content-start align-items-center'>
                                                                        <div className='comman-image-box h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding'>
                                                                            <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url(${task_assignee.length > 0 && task_assignee[0].profile_image ? task_assignee[0].profile_image : event.schedule_id > 0 ? User_2 : taskData.creator_profile_image ? taskData.creator_profile_image : ""})` }}></div>
                                                                        </div>
                                                                        {task_assignee && task_assignee.length > 1 ?
                                                                        <div className={`multi_image_wrapper dropdown-center dropdown`}>
                                                                            <div className="multi_inner_wrapper d-flex position-relative pe-2">
                                                                                
                                                                                {task_assignee && task_assignee.length > 1 ?
                                                                                    <>
                                                                                        <div className={`comman-image-box h25w25 with_overlay d-flex rounded-circle border-2 `} 
                                                                                        onClick={(event)=>event.preventDefault()}>
                                                                                            <UncontrolledDropdown>
                                                                                                <DropdownToggle tag="div" className="dropdown dropdown-center team-list-dropdown h25w25" >
                                                                                                    <div
                                                                                                        className="d-flex rounded-circle h-100"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="dropdown"
                                                                                                        data-bs-auto-close="outside"
                                                                                                        aria-expanded="false"
                                                                                                    >
                                                                                                        <div className="unread_count m-auto">
                                                                                                            +{task_assignee ? task_assignee.length - 1 : " "}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <UncontrolledGeneralListing list={task_assignee ? task_assignee.map((data, index) => {
                                                                                                        return {
                                                                                                            name: data.name,
                                                                                                            image: data.profile_image ? data.profile_image : '',
                                                                                                            id: index,
                                                                                                        };
                                                                                                    }) : <></>} />
                                                                                                </DropdownToggle>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>
                                                                                    </>
                                                                                    : <></>
                                                                                }

                                                                            </div>
                                                                             
                                                                        </div> : <></>}
                                                                    </div>
                                                                </div>
                                                                <div className='color-white-60 c-font f-14 ps-3'>{task_assignee.length > 0 && task_assignee[0].name ? task_assignee[0].name : taskData.creator_name ? taskData.creator_name : ""}</div>
                                                            </div>
                                                        </a>
                                                        {/* </OverlayTrigger> */}
                                                        <div className='progress-slider-with-action-icon w-25'>
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <div className='slider-progress  flex-grow-1 p-15 py-0 c-font f-14 min-width200'>
                                                                    {/* <UncontrolledDropdown className='progress-slider w-100'>
                                                                        <DropdownToggle data-toggle="dropdown" tag="a" className="progress-slider w-100" onClick={() => {
                                                                            setOpenCheckList(true)
                                                                            dispatch(getTaskChecklist(event.id, taskData.rel_id));
                                                                        }}
                                                                            type="button"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                            data-bs-auto-close="outside"> */}
                                                                            <div className='progress-slider w-100'>
                                                                                <div className='progress-slider w-100'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <div className='progress'>
                                                                                            <div className='progress-bar' style={{ width: `${event.checklist_progress}%` }}></div>
                                                                                        </div>
                                                                                        <div className='progress-text title-fonts color-white-80 text-center ps-2 c-font f-14'>{event.checklist_progress}%</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        {/* </DropdownToggle>
                                                                        <DropdownItem toggle={false} className="p-0">
                                                                            <CheckedDropdown
                                                                                unContolList={true}
                                                                                setOpenCheckList={setOpenCheckList}
                                                                                project_id={taskData.rel_id}
                                                                                openCheckList={openCheckList}
                                                                                task_id={event.id}
                                                                                taskDetails={taskData}
                                                                                task_status={taskData.status}
                                                                                isSystemTask={
                                                                                    taskData.task && parseInt(taskData.is_allowed_to_edit) != 1
                                                                                        ? true
                                                                                        : null
                                                                                }
                                                                                isChecklistPermission={
                                                                                    taskData.task &&
                                                                                        taskData.is_requested == 1 &&
                                                                                        taskData.request_task_id > 0
                                                                                        ? 1
                                                                                        : 0
                                                                                }
                                                                                pageName={"calendar"}
                                                                            />
                                                                        </DropdownItem>
                                                                    </UncontrolledDropdown> */}
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-center'>
                                                                    <div className='dropup  d-flex align-items-center pe-2'>
                                                                        {/* <UncontrolledDropdown className='progress-slider w-100'>
                                                                            <DropdownToggle tag={'a'} className='before-dnone before_dnone bg-white-05 comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                                type="button"
                                                                                id="writeComMentDropdown"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                data-bs-auto-close="outside"
                                                                                onClick={() => {
                                                                                    getTaskComments(event.id);
                                                                                    getMentionlist(event.id);
                                                                                }}> */}
                                                                                <div className='progress-slider w-100'>
                                                                                    <div className="before-dnone before_dnone bg-white-05 comman_action_icon d-flex radius_3 w-100 calender-action-icon">
                                                                                        <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                            <div className="position-relative">
                                                                                                <ChatCircle size={16} className="c-icons" weight="light" />
                                                                                                <span
                                                                                                    className={`rounded-pill badge-custom p-0 ${parseInt(event.unread_comment_count) > 0
                                                                                                        ? ""
                                                                                                        : "d-none"
                                                                                                        }`}
                                                                                                    data-unread-cmt-id={event.id}
                                                                                                >
                                                                                                    {event.unread_comment_count}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            {/* </DropdownToggle>
                                                                            <DropdownItem toggle={false} className="p-0">
                                                                                <CommentMinified
                                                                                    comments={commentsList}
                                                                                    disabled={
                                                                                        selectedTask &&
                                                                                        parseInt(selectedTask.request_task_id) > 0 &&
                                                                                        selectedTask.is_requested == 1}
                                                                                    handleAddComment={handleAddEditComment}
                                                                                    deleteComment={handleDeleteComment}
                                                                                    id={event.id}
                                                                                    loading={commentsLoading}
                                                                                    mentionList={mentionList.map((name) => {
                                                                                        let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                                                                                        return {
                                                                                            display: name.name,
                                                                                            id: id,
                                                                                            image: name.profile_image || "",
                                                                                            isteam: name.is_team ? 1 : 0,
                                                                                        };
                                                                                        })}
                                                                                    handleImagePreview={""}
                                                                                    pageName={pageName}
                                                                                    task={selectedTask}
                                                                                    unContolList={true}
                                                                                    showComments={showComments}
                                                                                />
                                                                            </DropdownItem>
                                                                        </UncontrolledDropdown> */}
                                                                    </div>
                                                                    <div className='dropup  d-flex align-items-center'>
                                                                        <div className='w-100'>
                                                                            {/* <UncontrolledDropdown className='comman_action_icon w-100 d-flex close-toast border-0'>
                                                                                <DropdownToggle tag={'a'} className='me-0 before-dnone before_dnone bg-white-05 comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                                    type="button"
                                                                                    id="imageLstAndUploadDropdown"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                    data-bs-auto-close="outside"
                                                                                    onClick={(e) => {
                                                                                        setOpenAttachmentsDropdown(true);
                                                                                        setTempTaskId(event.id);
                                                                                    }}> */}
                                                                                    <div className='comman_action_icon w-100 d-flex close-toast border-0'>
                                                                                        <div className="me-0 before-dnone before_dnone bg-white-05 comman_action_icon d-flex radius_3 w-100 calender-action-icon">
                                                                                            <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                                <div className="position-relative">
                                                                                                    <Image size={16} className="c-icons" weight="light" />
                                                                                                </div>
                                                                                                <span className="d-flex justify-content-center flex-grow-1">{event.attachments_count}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                {/* </DropdownToggle>
                                                                                <DropdownItem toggle={false} className="p-0">
                                                                                    <ImageUploadDropdown
                                                                                        unContolList={true}
                                                                                        setOpenAttachmentsDropdown={
                                                                                            setOpenAttachmentsDropdown
                                                                                        }
                                                                                        openAttachmentsDropdown={openAttachmentsDropdown}
                                                                                        task_id={event.id}
                                                                                        project_id={projectId}
                                                                                        updateImageCount={setAttchamentCount}
                                                                                        Request_task_converted={
                                                                                            selectedTask &&
                                                                                                parseInt(selectedTask.request_task_id) > 0 &&
                                                                                                selectedTask.is_requested == 1
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        taskDetails={event}
                                                                                        pageName={pageName}
                                                                                    />
                                                                                </DropdownItem>
                                                                            </UncontrolledDropdown> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* ))} */}
                                </>)
                            }
                        })}
            </div> </> )}
            })} </> : <></>}
        {/* day view end */}
          
    </div>
  )
}

export default CommanCustomFullcalendar
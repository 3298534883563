import React, { useEffect, useState } from "react";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { _l } from "../../../hooks/utilities";
import CustomWhatsappIcon from "../../../assets/icons/CustomWhatsappIcon";
import CustomGmailIcons from "../../../assets/icons/CustomGmailIcons";
import CustomSlackIcon from "../../../assets/icons/CustomSlackIcon";
import { XCircle, NotePencil, Trash, Diamond } from "phosphor-react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import documentsServices from "../../../services/documents-services";
import { showConfirmation, showMessage, toastCloseAction } from "../../../actions/messages";
import { updateOnboardProviderList } from "../../../actions/documents-actions/documents-actions";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import Spinner from "react-bootstrap/Spinner";

const PilotOnboardingProviders = ({ data, toolsList, setSelectedTab, projectId, isExternal}) => {
  const regex = /^[0-9\b]+$/;
  const onBoardProviderList = useSelector((state) => state.documentsReducer.onBoardProviderList);
  const { getDefaultTopicList } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [tools, setTools] = useState([]);
  const [cost, setCost] = useState(0);
  const [meeting, setMeeting] = useState(0);
  const [saving, setSaving] = useState(0);
  const [people, setPeople] = useState(0);
  const [providerType, setProviderType] = useState("");
  const [onBoardProvider, setOnBoardProvider] = useState([]);
  const [documentProviderId, setDocumentProviderId] = useState(0);
  const [categoryListing, setCategoryListing] = useState([]);
  const [providerBtnToggle, setProviderBtnToggle] = useState(false);
  const [documentId, setDocumentId] = useState(0);
  const [loder, setLoder] = useState(0);
  const deleteTools = (id) => {
    setTools(tools.filter(t => t.value != id))
  }
  useEffect(() => {
    if (onBoardProviderList) {
      setOnBoardProvider(onBoardProviderList);
    }
  }, [onBoardProviderList])
  useEffect(() => {
    if (data) {
      setDocumentId(data.document_id);
    }
  }, [data])
  useEffect(() => {
    if (getDefaultTopicList && getDefaultTopicList.length > 0) {
          setCategoryListing(
            getDefaultTopicList.map((item1) => {
              return {
                value: parseInt(item1.id),
                label: item1.name,
              };
            })
          );
    }
  }, [getDefaultTopicList]);
  const deleteConfirm = (id) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_you_want_to_delete_provider"),
        _l("l_yes"),
        _l("l_no"),
        undefined,
        () => deleteProvider(id)
      )
    )
  }
  const deleteProvider = (document_provider_id) => {
    documentsServices.deleteOnboardProvider(projectId, documentId, document_provider_id ? document_provider_id : 0).then(
      (res) => {
        if (res.status == 1) {
          let data = onBoardProviderList.filter(pv => pv.id != document_provider_id)
          dispatch(updateOnboardProviderList(data))
          dispatch(toastCloseAction());
        }
      }
    );
  }
  const clearData = () => {
    setProviderType("");
    setTools([]);
    setCost(0);
    setMeeting(0);
    setPeople(0);
    setSaving(0);
    setDocumentProviderId(0);
    setProviderBtnToggle(false)
  }
  const handleSave = () => {
    if(providerType == ""){
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_select_provider")));
    }else{
    setLoder(true)
    documentsServices.addEditOnboardProvider(projectId, documentId, providerType.label, cost, meeting, tools, people, saving, documentProviderId ? documentProviderId : 0).then(
      (res) => {
        setLoder(false)
        if (res.status == 1) {
          let data = onBoardProviderList || []
          if (documentProviderId > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].id == documentProviderId) {
                data[i] = {
                  cost: cost,
                  id: documentProviderId,
                  meeting: meeting,
                  saving: saving,
                  people: people,
                  project_id: projectId,
                  provider_type: providerType.label,
                  tools: tools.map((t) => t.label)
                }
              }
            }
          } else {
            data.push({
              cost: cost,
              id: res.data.id,
              meeting: meeting,
              saving: saving,
              people: people,
              project_id: projectId,
              provider_type: providerType.label,
              tools: tools.map((t) => t.label)
            })
          }
          dispatch(updateOnboardProviderList(data))
          clearData()
        }
      }
    );
    }
  };
  const setProviderData = (p) => {
    if (p.tools.length > 0) {
      setTools(p.tools);
      setTools(toolsList.map(t => p.tools.includes(t.label) ? t : ''));
    }
    setCost(p.cost);
    setPeople(p.people);
    setProviderType(categoryListing.filter(c => c.label == p.provider_type)[0]);
    setMeeting(p.meeting);
    setDocumentProviderId(p.id)
    setProviderBtnToggle(true);

  }
  return (
    <>
    <div className="flex-grow-1 overflow-hiiden-auto">
      <ul className="w-100 mt-3 pb-3 mb-3 border-bottom">
        {
          onBoardProvider.length > 0 ? onBoardProvider.map((p, k) => {
            return (
              <li className="w-100">
                <a href="#/" className="task-list-card list-view w-100 p-2 d-flex align-items-center border">
                  <div className="d-flex flex-grow-1 w100minus100per">
                    <div className="flex-grow-1 fw-semibold text-truncate title-fonts w100minus100per">{p.provider_type}</div>
                  </div>
                  <div className="flex-grow-1 maxw100minus180 text-truncate d-flex">
                    <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l('l_cost')} </span><span className="c-font f-12 title-fonts">: {p.cost}</span></div>
                    <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l('l_people')} </span><span className="c-font f-12 title-fonts">: {p.people}</span></div>
                    <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l("l_meeting")} </span><span className="c-font f-12 title-fonts">: {p.meeting}</span></div>
                    <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l("l_saving")} </span><span className="c-font f-12 title-fonts">: {p.saving}</span></div>
                    <div className="with_separator_10"><span className="c-font color-white-60 f-12 fw-light title-fonts">{_l("l_tools")} </span><span className="c-font f-12 title-fonts">:
                      {
                        p.tools.map((t) => {
                          return (
                            t == "WhatsApp" ? <CustomWhatsappIcon className="h25w25 mx-1" /> : t == "Slack" ? <CustomSlackIcon className="h20w20 mx-1" /> : t == "Gmail" ? <CustomGmailIcons className="h25w25 mx-1" /> : <></>
                          )
                        })
                      }
                    </span></div>
                  </div>
                  <div class="d-flex  justify-content-start z-index-3 comman_action_icon">
                    <a href="#/" class="action_icon with_bg h30w30 d-flex me-2"
                      onClick={() => setProviderData(p)}
                    >
                      <NotePencil size={18} className="c-icons " weight="light" />
                    </a>
                    <a href="#/" class="action_icon with_bg h30w30 d-flex"
                      onClick={() => deleteConfirm(p.id)}>
                      <Trash size={18} className="c-icons " weight="light" />
                    </a>
                  </div>
                </a>
              </li>
            )
          })
            :
            <CommanPlaceholder imgType = "request"   />
        }
      </ul>
      <Form className="row">
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupPassword">
          <Form.Label className="input-label no-asterisk ">{_l('l_provider_type')}</Form.Label>
          <Select
            className="custom-select-menu"
            classNamePrefix="react-select"
            options={categoryListing}
            closeMenuOnSelect={true}
            placeholder={_l("l_provider_type")}
            value={providerType}
            onChange={(value) => {
              setProviderType(value)
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l('l_cost')}</Form.Label>
          <Form.Control type="number" placeholder={_l('l_cost')} onChange={(e) => setCost(regex.test(e.target.value) ? e.target.value : 0)} value={cost} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_saving")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_saving")} pattern="[0-9]*" onChange={(e) => setSaving(regex.test(e.target.value) ? e.target.value : 0 )} value={saving} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l('l_people')}</Form.Label>
          <Form.Control type="number" placeholder={_l('l_people')} onChange={(e) => setPeople(regex.test(e.target.value) ? e.target.value : 0)} value={people} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_meeting")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_meeting")} onChange={(e) => setMeeting(regex.test(e.target.value) ? e.target.value : 0)} value={meeting} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupPassword">
          <Form.Label className="input-label no-asterisk ">{_l("l_tools")}</Form.Label>
          <Select
            value={tools}
            className="custom-select-menu max-h95 multi-select-with-scroll select53px"
            classNamePrefix="react-select"
            options={toolsList}
            isMulti
            closeMenuOnSelect={false}
            placeholder={`${_l('l_select_tool')}`}
            onChange={(value) => {
              setTools(value);
            }}
          />
        </Form.Group>
      </Form>
      <div className="d-flex align-items-center">
        {
          tools.map((t, k) => {
            return (
              t != "" ?
                <div className="badge rounded-pill text-nowrap d-flex align-items-center me-2" key={k}>
                  {t.value == 1 ?
                    <CustomWhatsappIcon className="h25w25" />
                    : t.value == 2 ?
                      <CustomGmailIcons className="h25w25" />
                      : t.value == 3 ?
                        <CustomSlackIcon className="h20w20" />
                        :
                        <></>
                  }
                  <span className="c-font color-white-80 f-12 flex-grow-1 px-2 text-truncate">{t.label}</span> <a href="#/" onClick={() => deleteTools(t.value)}><XCircle size={18} className="c-icons m-0 opacity-50" weight="light" /></a>
                </div>
                :
                <></>
            );
          })
        }
      </div>
      </div>
      <div className="d-flex justify-content-between">
        <Button variant="secondary" size="sm"  onClick={() => setSelectedTab(isExternal ? "facility" : "tenant")}>{_l("l_previous")}</Button>
        <div className="d-flex align-items-center">
        <Button variant="secondary" size="sm" className="me-2"
          onClick={() => handleSave()}
        >
          <span>
            {loder
              ? _l('l_please_wait')
              : _l(!providerBtnToggle ? 'l_add_new_provider' : 'l_edit_provider' )}
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
              : <></>
            }
            </span>
        </Button>
        <Button variant="primary" size="sm"  onClick={() => setSelectedTab("summary")}>{_l("l_next")}</Button>
        </div>
       
        
      </div>
    
    </>
  );
};


export default PilotOnboardingProviders;


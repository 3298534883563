import React, { useEffect, useState } from 'react'
import { _l } from '../../hooks/utilities'
import { CaretRight, Hash } from 'phosphor-react'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import CommanPlaceholder from '../Placeholder/CommanPlaceholder'

const CheckListTemplates = ({
  copyTemplateChecklist,
  templatesList,
  setShowChecklistTemplateModal,
  cheklistLength = 0,
  setChecklistMode,
  setShowFloorList,
  switchToClassicView = () => {},
  showFloorList,
  setSelectedFloor

}) => {
  const [templates, setTemplates] = useState([])
  useEffect(() => {
    let checklistTemplate = [];
    let viewList = [];
    if (templatesList && templatesList.length > 0) {
      templatesList.map((p) => {
        if (viewList.includes(p.view)) {
          let index = viewList.indexOf(p.view);
          checklistTemplate[index].checklist.push(p);
        } else {
          viewList.push(p.view);
          checklistTemplate.push({ view: p.view, checklist: [p] });
        }
      })
      setTemplates(checklistTemplate)
    }
  }, [JSON.stringify(templatesList)])
  return (
    <>
      <DropdownMenu className="w-200 " aria-labelledby="CheckListTemplatesDropdown">
        <UncontrolledDropdown setActiveFromChild={true} direction="end" className="w-200">
          <DropdownToggle toggle="false" className="w-100 custom-btn-dropdown-items" >
            <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => { }}>
              <div className="d-flex align-items-center">
                <div className="c-list-icon">
                  <Hash size={20} weight="light" className="c-icons" />
                </div>
                <div className="ps-2 w100minus40 text_wrap">{_l("l_checklist_classic")}</div>
                <div className="c-list-icon">
                  <CaretRight size={14} weight="light" className="c-icons" />
                </div>
              </div>
            </a>
          </DropdownToggle>
          <DropdownMenu className="w-200 overflowscroll">
            {
              templates && templates.filter((view) => view.view == "classic").length > 0 ? templates.filter((view) => view.view == "classic").map((view) => {
                return (
                  view.checklist && view.checklist.length > 0 && view.checklist.map((list, index) => {
                    return (<>
                      <li className="hr_sepretr">
                        <a href="#/"
                          key={index}
                          className={`dropdown-item  text_wrap `}
                          onClick={() => {
                          }}
                        >
                          <div className="d-flex align-items-center text-truncate">
                            <div className="c-list-icon">
                              <div className="h25w25 d-flex align-items-center">
                                <Hash size={16} weight="light" className="c-icons" />
                              </div>
                            </div>
                            <div
                              className="ps-2 w100minus30 text_wrap"

                            >
                              <div
                                onClick={() => {
                                  copyTemplateChecklist(list)
                                  if (list.view == "classic") {
                                    setShowFloorList(false)
                                    switchToClassicView()
                                    setChecklistMode(list.view)
                                  } else if (list.view == "floor") {
                                    setChecklistMode(list.view)
                                  }
                                }}
                                className="c-list-detail c-font f-14 text-truncate ">
                                {list.title}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    </>)
                  })
                )
              })
                :
                <>
                  <CommanPlaceholder imgType="no-images" placeholderText={_l("l_no_checklist_templates")} />
                </>
            }
          </DropdownMenu>

        </UncontrolledDropdown>
        <UncontrolledDropdown setActiveFromChild={true} direction="end" className="w-200">
          <DropdownToggle toggle="false" className="w-100 custom-btn-dropdown-items" >
            <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => { }}>
              <div className="d-flex align-items-center">
                <div className="c-list-icon">
                  <Hash size={20} weight="light" className="c-icons" />
                </div>
                <div className="ps-2 w100minus40 text_wrap">{_l("l_checklist_floorwise")}</div>
                <div className="c-list-icon">
                  <CaretRight size={14} weight="light" className="c-icons" />
                </div>
              </div>
            </a>
          </DropdownToggle>
          <DropdownMenu className="w-200 overflowscroll">
            {
              templates && templates && templates.filter((view) => view.view =="floor").length > 0 ?
                templates.filter((view) => view.view == "floor").map((view) => {
                  return (
                    view.checklist && view.checklist.length > 0 && view.checklist.map((list, index) => {
                      return (<>
                        <li className="hr_sepretr">
                          <a href="#/"
                            key={index}
                            className={`dropdown-item  text_wrap `}
                            onClick={() => {
                            }}
                          >
                            <div className="d-flex align-items-center text-truncate">
                              <div className="c-list-icon">
                                <div className="h25w25 d-flex align-items-center">
                                  <Hash size={16} weight="light" className="c-icons" />
                                </div>
                              </div>
                              <div
                                className="ps-2 w100minus30 text_wrap"

                              >
                                <div
                                  onClick={() => {
                                    copyTemplateChecklist(list)
                                    if (list.view == "classic") {
                                      setShowFloorList(false)
                                      switchToClassicView()
                                      setChecklistMode(list.view)
                                    } else if (list.view == "floor" ) {
                                    setSelectedFloor({ id: -1, title: "" });
                                    setShowFloorList(!showFloorList)
                                    setChecklistMode(list.view)
                                  }
                                }}
                                  className="c-list-detail c-font f-14 text-truncate ">
                                  {list.title}
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </>)
                    })
                  )
                })
                : <>
                  <CommanPlaceholder imgType="no-images" placeholderText={_l("l_no_checklist_templates")} />
                </>
            }
          </DropdownMenu>

        </UncontrolledDropdown>
        <UncontrolledDropdown setActiveFromChild={true} direction="end" className="w-200 for-disabled d-none">
          <DropdownToggle toggle="false" className="w-100 custom-btn-dropdown-items" >
            <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => { }}>
              <div className="d-flex align-items-center">
                <div className="c-list-icon">
                  <Hash size={20} weight="light" className="c-icons" />
                </div>
                <div className="ps-2 w100minus40 text_wrap">{_l("l_intro_footer_my_space")}</div>
                <div className="c-list-icon">
                  <CaretRight size={14} weight="light" className="c-icons" />
                </div>
              </div>
            </a>
          </DropdownToggle>
          <DropdownMenu className="w-200 overflowscroll">
            {
              templates && templates.filter((view) => view.view =="space").length > 0 ? templates.filter((view) => view.view == "space").map((view) => {
                return (
                  view.checklist && view.checklist.length > 0 && view.checklist.map((list, index) => {
                    return (<>
                      <li className="hr_sepretr">
                        <a href="#/"
                          key={index}
                          className={`dropdown-item  text_wrap `}
                          onClick={() => {
                          }}
                        >
                          <div className="d-flex align-items-center text-truncate">
                            <div className="c-list-icon">
                              <div className="h25w25 d-flex align-items-center">
                                <Hash size={16} weight="light" className="c-icons" />
                              </div>
                            </div>
                            <div
                              className="ps-2 w100minus30 text_wrap"

                            >
                              <div
                                onClick={() => {
                                  copyTemplateChecklist(list)
                                  setChecklistMode(list.view)
                                  if (list.view == "classic") {
                                    setShowFloorList(false)
                                  } else if (list.view == "floor") {
                                    setShowFloorList(true)
                                  }
                                }}
                                className="c-list-detail c-font f-14 text-truncate ">
                                {list.title}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    </>)
                  })
                )
              })
                :
                <>
                  <CommanPlaceholder imgType="no-images" placeholderText={_l("l_no_checklist_templates")} />
                </>
            }
          </DropdownMenu>

        </UncontrolledDropdown>

        {cheklistLength > 0 ? <li className='comman-list border'>
          <a href="#/" className={` d-flex   ${cheklistLength == 0 ? "for-disabled" : ""}`}>
            <button className='btn btn-primary btn-sm w-100'>
              <div className="comment-input-box flex-grow-1">
                <div
                  className="input_caret_transparent bg-transparent with-base-bg border-0 flex-grow-1 p-0 c-list-detail c-font f-14  fw-normal title-fonts text-truncate"
                  onClick={() => { setShowChecklistTemplateModal(true) }}>
                  {_l("l_save_as_template")}
                </div>
              </div>
            </button>
          </a>
        </li> : <></>}
      </DropdownMenu>
    </>
  )
}

export default CheckListTemplates
import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import TaskDetail from "../TaskDetail";
import jquery from "jquery";
import { getSelectedTask } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import CommentDropdown from "../Dropdowns/CommentDropdown";
 
import CommanLoader from "../Loader/CommanLoader";
import { ArrowSquareUpRight,ChatCircle,Info,UserCircle } from "phosphor-react";

const EmployeeListing = ({ list, setTaskList, projectId, showLoader, getTaskList }
) => {

  const dispatch = useDispatch();
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const [openCommentTab, setOpenCommentTab] = useState(false);
  const [taskId, setTaskId] = useState(0)

  const [modalShow, setModalShow] = useState(false);
  const FullLeftPanelWidth = jquery('.employeeListingDropDownminHeight').width();
  const FullLeftPanelHeight = jquery('.employeeListingDropDownminHeight').height();
  const [heightWidth, setHeightWidth] = useState({});
  let IdName = 'writeComMentDropdown';
  const getTask = (id, is_requested = null) => {
    if (!selectedTask || selectedTask.id !== id) {
      dispatch(
        getSelectedTask(
          localStorage.getItem("selectedOffice"),
          id,
          is_requested
        )
      );
    }
    setTaskId(id)

  };
  const updatedCommentCount = (id) => {
    let taskTemp = getTaskList.map((task) => {
      if (task.id == id) {
        task['comments_count'] = parseInt(task['comments_count']) + 1
        return task
      }
      return task;
    })

    setTaskList(taskTemp)
  }
  useEffect(() => {
    if (Object.keys(heightWidth).length) {
      IdName = 'writeComMentDropdown';
      let tempWidth = heightWidth.width;
      let tempHeight = heightWidth.height;
      if (heightWidth.width + jquery("." + IdName).width() > FullLeftPanelWidth) {
        tempWidth = heightWidth.width - jquery("." + IdName).width()
      }
      if (heightWidth.height + jquery("." + IdName).height() > FullLeftPanelHeight) {
        tempHeight = heightWidth.height
      }
      jquery("#commentDropDownOpen").css({
        position: 'absolute',
        left: 255,
        top: Math.abs(tempHeight),
      }
      );
    }
  }, [heightWidth])
  return (
    <></>
    // <ul
    //   className="dropdown-menu w-300"
    //   aria-labelledby="CategoryFilterDropdown"
    //   id="employeeListingDropDown"
    // >
    //   <div className="overflowscroll employeeListingDropDownminHeight">
    //     <li className="custom-modal-section-sticky p-2">
    //       <a href="#/" className="c-font f-12 title-fonts color-white-60">{_l("l_task_list")} </a>
    //     </li>
    //     <li>
    //       <hr className="dropdown-divider my-0" />
    //     </li>
    //     {
    //       showLoader ? (
    //         <div>
    //           <CommanLoader className={`dropdown-loader comman-main-loader-wrapper position-absolute`} />
    //         </div>
    //       ) : (
    //         <>
    //           {list.length ? (
    //             list.map((item, index) => {
    //               return (
    //                 <React.Fragment key={index}>
    //                   <li className="hr_sepretr">
    //                     <a href="#/" className={`dropdown-item p-10`} >
    //                       <div className="d-flex align-items-center text-truncate ">
    //                         <div className="c-list-icon">
    //                           <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle" 
    //                              
    //                              >
    //                             <div
    //                               className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
    //                               style={{ backgroundImage: `url('${item.image}')` }}
    //                             ></div>
    //                           </div>
    //                         </div>
    //                         <div className="ps-2 w100minus20 text_wrap">
    //                           <div className="d-flex align-items-center" 
    //                              
    //                              >
    //                             <div className="c-list-detail c-font f-14 text-truncate fw-semibold title-fonts">
    //                               {item.task_name ? item.task_name : ''}
    //                             </div>
    //                             <div className="mx-1"> <Info size={16} color="#fca21f" weight="light" /></div>
    //                           </div>
    //                         </div>
    //                         <div className="d-flex align-items-center comman_action_icon ms-2 writeComMentDropdown"
    //                           // id="writeComMentDropdown"
    //                           // type='button'
    //                           // data-bs-toggle="dropdown"
    //                           // aria-expanded="false"
    //                           // data-bs-auto-close="outside"
    //                            
    //                            
    //                             onClick={(event) => {
    //                             if (taskId != item.id) {
    //                               setTaskId(item.id);
    //                               setOpenCommentTab(true)
    //                             }
    //                             let width = event.pageX
    //                             let height = event.pageY
  
    //                             setHeightWidth({ width: width, height: height })
  
    //                           }}>
    //                           <div className="action_icon with_bg position-relative">
    //                           <ChatCircle size={16} className="c-icons" weight="light" />
    //                             <span
    //                               className={`rounded-pill badge-custom p-0  ${parseInt(item.unread_comment_count) == 0 ? "d-none" : ""}`}
    //                             // data-unread-cmt-id={item.id}
    //                             >
    //                               {item.unread_comment_count ? item.unread_comment_count : 0}
    //                             </span>
    //                           </div>
    //                         </div>
    //                         <div className="d-flex align-items-center comman_action_icon ms-2" onClick={() => {
    //                           getTask(item.id, null);
    //                           setModalShow(true)
    //                         }}>
    //                           <div className="action_icon with_bg position-relative">
    //                           <ArrowSquareUpRight size={16} className="c-icons" weight="light" />
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </a>
    //                   </li>
    //                 </React.Fragment>
    //               );
    //             })
    //           ) : (
    //             <li>
    //               <div className="comman-placeholder-wrapper w-100 py-0 d-flex h-100">
    //                 <div className="d-flex flex-column align-items-center m-auto">
    //                 <UserCircle size={32} className="small-image" weight="light" />
    //                   <span className="color-white-80 py-2 title-fonts">
    //                   {_l('l_no_employee_listing')}
    //                   </span >
    //                 </div >
    //               </div >
    //             </li >
    //           )}
    //         </>
    //       )
    //     }
    //   </div>
    //   {
    //     modalShow ? (
    //       <TaskDetail
    //         projectId={localStorage.getItem('selectedOffice')}
    //         taskId={taskId}
    //         offCanvasshow={modalShow}
    //         offCanvashandleShow={setModalShow}
    //         offCanvashandleClose={() => {
    //           jquery(".task-list-accordian").removeClass("list-view-toggle");
    //           setModalShow(false);
    //           localStorage.removeItem("SelectedTask");
    //           localStorage.removeItem("TaskType");
    //         }}
    //         handler={() => {
    //           setModalShow(false)
    //         }}
    //         attachmentCountUpdate={() => { }}
    //         taskDeleted={() => { }}
    //         convertRequestTaskId={() => { }}
    //       />
    //     ) : <></>
    //   }
    //   {/* {

    //     openCommentTab ? */}
    //   <CommentDropdown
    //     openCommentTab={openCommentTab}
    //     setOpenCommentTab={setOpenCommentTab}
    //     task_id={taskId}
    //     projectId={localStorage.getItem('selectedOffice')
    //     }
    //     updatedCommentCount={(id) => { updatedCommentCount(taskId) }}
    //     callbackFunction={() => { }}
    //     Request_task_converted={false}
    //   />
    //   {/* : <></>
    //   } */}
    //    
    // </ul >
  );
};

export default EmployeeListing;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, countryCodedigitLength, showError } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import ReactHtmlParser from "react-html-parser";
import { useDispatch } from "react-redux";
import { editSpaceAccess } from "../../actions/customer";
import { showMessage } from "../../actions/messages";
import Spinner from "react-bootstrap/esm/Spinner";
import PhoneInput from "react-phone-input-2";

const AccessSapceEdit = ({ accessOfficeData, show, handleClose }) => {

  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [wifiCode, setWifiCode] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [spacePhoneNumber, setSpacePhoneNumber] = useState('');
  const [loader, setLoader] = useState(false);
  const [spaceEmail, setSpaceEmail] = useState("");
  const [numberFormate, setNumberFormate] = useState('');
  const [numberlength, setNumberLength]= useState({max : 14, min : 9});
  const projectId = localStorage.getItem("selectedOffice");

  useEffect(() => {
    if (accessOfficeData) {
      if (accessOfficeData.phonenumber) { 
        let mobile = accessOfficeData.phonenumber.split(" ")[1];
        let c_Code = accessOfficeData.phonenumber.split(" ")[0];
        setCountryCode(c_Code);
        setphoneNumber(c_Code.slice(1) + mobile);
        let maxLength = countryCodedigitLength(c_Code)
        setNumberLength(maxLength)
      }
      setName(accessOfficeData.name)
      setAccessCode(accessOfficeData.accese_code)
      setWifiCode(accessOfficeData.wifi_code)
      setSpaceEmail(accessOfficeData.space_email ? accessOfficeData.space_email.replace("@mg.myr.ai", "") : "");
      if(accessOfficeData.description){
        setDescription(accessOfficeData.description)
      }
      if(accessOfficeData.ai_phone_number){
        setSpacePhoneNumber(accessOfficeData.ai_phone_number)
      }
    }
  }, [accessOfficeData])

  const handleSave = async () => {
           
    if (phoneNumber.trim() == "" || !phoneNumber.length || (numberlength.max != phoneNumber.length && numberlength.min != phoneNumber.length)) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_mobile")));
    } else if(spacePhoneNumber != null && spacePhoneNumber.trim() == "" || !spacePhoneNumber.length || (numberlength.max != spacePhoneNumber.length && numberlength.min != spacePhoneNumber.length)) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_mobile_number")));
    } else {
    setLoader(true);
    const emailString = `${spaceEmail}@mg.myr.ai`;
    await CustomerServices.addSpacePhoneNumber(spacePhoneNumber, projectId).catch((e) => {
      console.error(e);
    })
    CustomerServices.editAccessOfficeDetail(accessCode, wifiCode, `${countryCode} ${phoneNumber.slice(countryCode.length - 1)}`, description).then((res) => {
      setLoader(false);
      if (res.status == 1) {
        handleClose()
        dispatch(showMessage("sucess", _l('l_success'), _l(res.message)))
        dispatch(editSpaceAccess({
          accese_code: accessCode,
          description: description,
          name: name,
          phonenumber: `${countryCode} ${phoneNumber.slice(countryCode.length - 1)}`,
          wifi_code: wifiCode,
          space_email: `${spaceEmail}`,
          ai_phone_number: `${countryCode} ${spacePhoneNumber.slice(countryCode.length - 1)}`
        }))
      }
    })
    if(spaceEmail)
    { 
      CustomerServices.addSpaceEmail(emailString, projectId).catch((e) => {
       console.error(e);
      })
    }
    }
  }

  const checkEmailExists = async() => {
    if(!spaceEmail.trim().length)
    {
      return;
    }
    try{
      const emailString = `${spaceEmail}@mg.myr.ai`;
      const response = await CustomerServices.checkSpaceEmailExists(emailString);
      if(!response.status)
      {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }
  useEffect(() => {
    if (numberFormate.dialCode) {
      setCountryCode(`+${numberFormate.dialCode}`)
        const dialcodeL = Number(numberFormate.dialCode.length);
        let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
        if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
            if (["49", "44"].includes(numberFormate.dialCode)) {
                setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
            }else{
                setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
            }
        }else{
            setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
        }
    }
  }, [numberFormate])
  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l('l_edit') + " " +_l('l_access_office')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main">
              <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_full_name_label')}
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    type="text"
                    disabled
                    value={name}
                  />
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box position-relative pb-3 select-half-separator">
                    <Form.Label className="input-label no-asterisk no-asterisk">
                      {_l("l_mobile_number")}
                    </Form.Label>
                    <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                        <PhoneInput
                        className="p-10"
                            autoFormat={true}
                            enableLongNumbers = {true}
                            countryCodeEditable={false}
                            disableCountryGuess={true}
                            searchStyle={{"border-color" : "red"}}
                            disableSearchIcon={true}
                            enableSearch={true}
                            localization={""}
                            country='fr'
                            preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                            name="mobile_number"
                            value={phoneNumber}
                            onChange={(phone, formattedValue) => {
                                setNumberFormate(formattedValue);
                                setphoneNumber(phone);
                            }}
                            isValid={(value) => {
                                if (value.length > numberlength.max) {
                                  setphoneNumber(value.slice(0,numberlength.max));
                                } else {
                                    return true;
                                }
                            }}
                        />
                    </div>
                  </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_access_code')} 
                  </Form.Label>
                  <Form.Control
                    placeholder={_l('l_access_code')} 
                    type="text"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_space_email')} 
                  </Form.Label>
                  <div className="row g-0">
                    <div className="col-7">
                      <Form.Control
                        placeholder={_l('l_employee_name_placeholder')} 
                        type="text"
                        value={spaceEmail}
                        onChange={(e) => setSpaceEmail(e.target.value)}
                        onBlur={checkEmailExists}
                      />
                    </div>
                    <div className="col-5">
                      <span className="form-control for-disabled color-white-60">@mg.myr.ai</span>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_wi_fi_code')}
                  </Form.Label>
                  <Form.Control
                    placeholder={_l('l_wi_fi_code')}
                    type="text"
                    value={wifiCode}
                    onChange={(e) => setWifiCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box position-relative pb-3 select-half-separator">
                    <Form.Label className="input-label no-asterisk no-asterisk">
                      {_l("l_ai_landline_no")}
                    </Form.Label>
                    <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                        <PhoneInput
                            className="p-10"
                            autoFormat={true}
                            enableLongNumbers = {true}
                            countryCodeEditable={false}
                            disableCountryGuess={true}
                            searchStyle={{"border-color" : "red"}}
                            disableSearchIcon={true}
                            enableSearch={true}
                            localization={""}
                            country='fr'
                            preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                            name="mobile_number"
                            value={spacePhoneNumber}
                            onChange={(phone, formattedValue) => {
                                setNumberFormate(formattedValue);
                                setSpacePhoneNumber(phone);
                            }}
                            isValid={(value) => {
                                if (value.length > numberlength.max) {
                                  setSpacePhoneNumber(value.slice(0,numberlength.max));
                                } else {
                                    return true;
                                }
                            }}
                        />
                    </div>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
              {_l('l_access_description')}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={`${_l("l_type_description_here")}`}
                type="text"
                value={ReactHtmlParser(description)}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
            </Button>
            <Button 
              variant="primary" disabled={loader} size="sm" 
              onClick={() => {
                handleSave()
              }}>
              {loader ? _l('l_please_wait') : _l('l_save')}
              {loader && <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true" />}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AccessSapceEdit;

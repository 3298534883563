import React from 'react';
 

function DotsThreeVerticalIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M8.39062 4.625C8.7358 4.625 9.01562 4.34518 9.01562 4C9.01562 3.65482 8.7358 3.375 8.39062 3.375C8.04545 3.375 7.76562 3.65482 7.76562 4C7.76562 4.34518 8.04545 4.625 8.39062 4.625Z"/>
<path d="M8.39062 8.625C8.7358 8.625 9.01562 8.34518 9.01562 8C9.01562 7.65482 8.7358 7.375 8.39062 7.375C8.04545 7.375 7.76562 7.65482 7.76562 8C7.76562 8.34518 8.04545 8.625 8.39062 8.625Z" />
<path d="M8.39062 12.625C8.7358 12.625 9.01562 12.3452 9.01562 12C9.01562 11.6548 8.7358 11.375 8.39062 11.375C8.04545 11.375 7.76562 11.6548 7.76562 12C7.76562 12.3452 8.04545 12.625 8.39062 12.625Z"/>
</svg>


		</React.Fragment>
	)
}

export default DotsThreeVerticalIcon
import React, { useEffect } from 'react'
import { ArrowRight, CalendarBlank, Check, Envelope, Phone } from 'phosphor-react'
import { _l, hideLeftPanelNew } from '../hooks/utilities'

const SetupAccountAI = () => {

    useEffect(() => {
        hideLeftPanelNew();
    },)
    
  return (
   <>
   <div className='schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3  d-flex flex-column'>
      <div className='pt-20pximp custom-accordian-main flex-grow-1 h-100 overflow-auto res-padding-right0 task-list-accordian pb-15px'>
              <div className='backdroplighttheme canimation d-flex daily-schedule-main flex-column flex-grow-1 h-100 w-800px'>
                <div className='align-items-center row flex-column'>
                  <div className='col-lg-6 text-center'>
                    <div className='align-items-center bgOfficelisting comman-round-box d-flex h50w50 justify-content-center m-auto mb-4'>
                      <div className='comman-bg-img h-100 w-100 bg-style-cover' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1711881027254-e9ff3234d9d8?q=80&w=1947&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}></div>
                    </div>
                    <div className="c-font f-24 fw-semibold title-fonts">Hello , John</div>
                    <div className="c-font color-white-60 f-22 pb-10px pt-10px">Can I help you with anything?</div>
                    <div className="c-font f-14 opacity-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non convallis augue.</div>
                  </div>
                </div>
                <div className='mt-auto'>
                  <div className='d-flex g-0 justify-content-between'>
                    <div className='select-input-cox space-select-box bgleftpanelnew'>
                      <input className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                        name="chatoption"
                        checked
                       
                        type="radio"
                      />
                      <a href="#/" className="check-image">
                        <div className="check-image-box">
                          <Check size={14} className="c-icons color-white-03-solid" />
                        </div>
                      </a>
                      <div>
                        <div className='bg-white-03 comman_action_icon d-flex flex-column h-135 m-auto p-20 radius_3'>
                          <div className='action_icon h40w40 rounded-circle white-border-box with_bg'>
                            <Phone size={24}  weight="light" className="c-icons" />
                          </div>
                          <div class="c-font f-18 fw-semibold pt-15px">Setup My Phone</div>
                          <div class="color-white-60 mt-1">Most visit place</div>
                        </div>
                      </div>
                    </div>
                    <div className='select-input-cox space-select-box bgleftpanelnew'>
                      <input className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                        name="chatoption"
                        checked
                       
                        type="radio"
                      />
                      <a href="#/" className="check-image">
                        <div className="check-image-box">
                          <Check size={14} className="c-icons color-white-03-solid" />
                        </div>
                      </a>
                      <div>
                        <div className='bg-white-03 comman_action_icon d-flex flex-column h-135 m-auto p-20 radius_3'>
                          <div className='action_icon h40w40 rounded-circle white-border-box with_bg'>
                            <Envelope size={24}  weight="light" className="c-icons" />
                          </div>
                          <div class="c-font f-18 fw-semibold pt-15px">Setup my email</div>
                          <div class="color-white-60 mt-1">Most visit place</div>
                        </div>
                      </div>
                    </div>
                    <div className='select-input-cox space-select-box bgleftpanelnew'>
                      <input className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                        name="chatoption"
                        checked
                       
                        type="radio"
                      />
                      <a href="#/" className="check-image">
                        <div className="check-image-box">
                          <Check size={14} className="c-icons color-white-03-solid" />
                        </div>
                      </a>
                      <div>
                        <div className='bg-white-03 comman_action_icon d-flex flex-column h-135 m-auto p-20 radius_3'>
                          <div className='action_icon h40w40 rounded-circle white-border-box with_bg'>
                            <CalendarBlank size={24}  weight="light" className="c-icons" />
                          </div>
                          <div class="c-font f-18 fw-semibold pt-15px">Setup My Calendar</div>
                          <div class="color-white-60 mt-1">Most visit place</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='comman_action_icon d-flex gap10 mt-4 position-sticky start-0'>
                   
                    
                      <div className='chat-main-box d-flex flex-column m-auto h64px w-100'>
                        <div className='border comment-input-box p15-10 bg-white-03-solid h-100'>
                        <div className="d-flex align-items-center position-relative">
                          <div className="h32w32 comman-image-box rounded-circle">
                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1711881027254-e9ff3234d9d8?q=80&w=1947&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}></div>
                          </div>
                          <div className="mension-box">
                            <textarea
                              placeholder={_l('l_write_comment_placeholder')}
                              className="d-flex align-items-center p-0 border-0 bg-transparent px-2"
                              rows="1"
                            ></textarea>
                          </div>
                          <a href="#/" className="send-arrow-btn with_position_right_zero">
                              <ArrowRight size={18}  weight="light" className="c-icons" />
                          </a>
                        </div>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
      </div>
    </div>
   </>
  )
}

export default SetupAccountAI
import { CloudArrowUp, Plus, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { _l, getPreviewFromURL } from "../../hooks/utilities";
import ScheduleSetup from "../Modals/OnboardingReusableComponents/ScheduleSetup";
import moment from "moment";
import Select from "react-select";
import customerServices from "../../services/customer-services";


const AgentInformation = ({
  slots,
  setSlots,
  documents,
  setDocuments,
  onlyTraining,
  type= "",
  setSelectedCompany = () =>{}
}) => {
  const [companyListOptions, setCompanyListOptions] = useState([]);

  
  const appendSlots = () => {
    const blankSlots = slots.filter((w) =>
      Object.values(w.slots).every((z) => z == 0)
    );

    if (!blankSlots.length) {
      setSlots(
        slots.concat({
          start_time: "",
          end_time: "",
          rate: "",
          slots: {
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
            sun: 0,
          },
        })
      );
    }
  };

  const handleDocumentUpload = (documentType, files) => {
    if (documents[documentType].attachments) {
      setDocuments({
        ...documents,
        [documentType]: {
          ...documents[documentType],
          file :files,
          preview: Array.from(documents[documentType].preview).concat(
            Array.from(files).map((w) => {
              return {
                name: w.name,
                type: w.type,
                url: URL.createObjectURL(w),
              };
            })
          ),
          attachments: Array.from(documents[documentType].attachments).concat(
            files
          ),
        },
      });
    } else {
      setDocuments({
        ...documents,
        [documentType]: {
          ...documents[documentType],
          file :files,
          preview: Array.from(files).map((w) => {
            return {
              name: w.name,
              type: w.type,
              url: URL.createObjectURL(w),
            };
          }),
          attachments: files,
        },
      });
    }
  };

  const removeAttachment = (documentType, attachment) => {
    setDocuments({
      ...documents,
      [documentType]: {
        ...documents[documentType],
        attachments: Array.from(documents[documentType].attachments).length === 1 ? undefined : Array.from(documents[documentType].attachments).filter(
          (w) => w.name != attachment.name
        ),
        preview: documents[documentType].preview.filter(
          (w) => w.name != attachment.name
        ),
      },
    });
  };

  const getDifferenceInHours = (start_time, end_time) => {
     return Number(Math.abs(moment.duration(moment(end_time).diff(moment(start_time))).asHours()).toFixed(2));
  }

  const calculateAccumulateHours = (dayName) => {
    let totalHours = 0;

    if (dayName === "all") {
      slots.forEach((slot) => {
        Object.keys(slot.slots).forEach((day) => {
          if (slot.slots[day] == 1 && slot.start_time && slot.end_time) {
            totalHours += getDifferenceInHours(slot.start_time, slot.end_time);
          }
        });
      });
    } else {
      slots.forEach((slot) => {
        if (slot.slots[dayName] == 1 && slot.start_time && slot.end_time) {
          totalHours += getDifferenceInHours(slot.start_time, slot.end_time);
        }
      });
    }

    return totalHours;
  };
  const getCompanyList = async () => {
    try {
      const response = await customerServices.getAllCompany();
      if (response.status == 1 && response.data.length) {
        let companyList = response.data
          .map((w) => {
            return { value: w.userid, label: w.company };
          })
          .filter((w) => w.label && w.label.length);
        companyList.sort((a, b) => a.label.localeCompare(b.label));
        setCompanyListOptions(companyList);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (type == "company_training") {
      getCompanyList()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-grow-1 h-100">
        <div className="w-auto h-100 d-flex flex-column flex-grow-1 pb-0">
          {onlyTraining ? (
            <React.Fragment>
             
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <div className="d-flex align-items-center justify-content-between mb-32px">
                  <Button
                    variant="white-05"
                    className="d-flex align-items-center"
                    onClick={appendSlots}
                  >
                    <Plus
                      weight="light"
                      height="12"
                      width="12"
                      className="c-icons me-2"
                    />
                    <span>{_l("l_add_new_duration")}</span>
                  </Button>
                  <div className="d-flex align-items-center">
                    <span>{_l("l_available")}</span>
                    <Form.Check
                      className={`ms-2`}
                      type="switch"
                      disabled
                      checked
                    />
                  </div>
                </div>
                <div className="d-flex fw-bold align-items-center justify-content-between bg-white-03 p-20px light-theme-grey-light-bg">
                  <div className="">{_l("l_my_availability")}</div>
                  <div className="d-flex fw-bold align-items-center justify-content-between">
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("mon")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_week_single_letter_monday")}
                      </div>
                    </div>
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("tue")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_week_single_letter_tuesday")}
                      </div>
                    </div>
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("wed")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_week_single_letter_wednesday")}
                      </div>
                    </div>
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("thu")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_week_single_letter_thrusday")}
                      </div>
                    </div>
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("fri")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_week_single_letter_friday")}
                      </div>
                    </div>
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("sat")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_week_single_letter_saturday")}
                      </div>
                    </div>
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("sun")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_week_single_letter_sunday")}
                      </div>
                    </div>
                    <div className="ms-4">
                      <div>{calculateAccumulateHours("all")}</div>
                      <div className="fw-light c-font f-11">
                        {_l("l_total_hours")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          
          <div className="d-flex flex-column flex-grow-1 overflow-auto mt-10px">
          <div className="">
                <ScheduleSetup setSlots={setSlots} slots={slots} hideRateField={true} />
              </div>

          {type == "company_training" && <Form.Group className="c-input-box pb-3 position-relative">
                <Form.Label className="no-asterisk white-03-solid px-1 py-2 title-fonts fw-bold">
                  {_l("l_company")}
                </Form.Label>
                <Select
                  className="custom-select-menu"
                  classNamePrefix="react-select"
                  placeholder={`${_l("l_select_company_type")}`}
                  options={companyListOptions}
                  closeMenuOnSelect={true}
                  onChange={(value) => {
                    setSelectedCompany(value);
                  }}
                />
              </Form.Group>  }  
          <div className="bg-white-03-solid px-1 py-2 title-fonts fw-bold">
            {_l("l_document_upload")}
          </div>
          <Row className="mt-3">
            {Object.keys(documents).map((documentType, key) => {
              return (
                <Col xs="3" key={key}>
                  <div className="c-font f-13 opacity-50 title-fonts mb-2">
                    {_l(documents[documentType].title)}
                  </div>
                  <div
                    className="form-control file-upload with-bg position-relative p-3 px-3 py-4 text-center"
                    style={{ height: "120px" }}
                  >
                    <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                      <input
                        className="z-index-3 hidden-input m-auto"
                        type="file"
                        multiple={documentType !== "profile_image"}
                        accept="image/*,.pdf"
                        onInput={(e) => {
                          handleDocumentUpload(documentType, e.target.files);
                        }}
                      />
                      <CloudArrowUp
                        weight="light"
                        height="25"
                        width="25"
                        className="c-icons opacity-50 mb-0"
                      />
                      <span className="c-font color-white-60 f-12 pt-2">
                        {_l("l_click_to_upload")}
                      </span>
                    </div>
                  </div>
                  <div className="upload-image-preview pt-3 d-flex flex-wrap">
                    {documents[documentType].preview.map((attachment, key) => {
                      let preview = {};
                      preview.url = getPreviewFromURL(attachment.name);
                      return (
                        <div className="h60w60 me-2" key={key}>
                          <div className="comman-image-box h40w40 radius_3 upload-image with-margin">
                            {typeof preview.url === "string" ? (
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                style={{
                                  backgroundImage: `url(${attachment.url})`,
                                }}
                                 
                                 
                                 
                              ></div>
                            ) : (
                              <div
                                className="h-100 w-100 d-flex cursor-pointer"
                                style={{
                                  alignContent: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <preview.url
                                  size={72}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                            )}
                            <a href="#/"
                              className="upload-close"
                              onClick={() =>
                                removeAttachment(documentType, attachment)
                              }
                            >
                              <X size={10} weight="light" className="c-icons" />
                            </a>
                          </div>
                          <div className="c-font color-white-60 f-12 text-truncate">
                            {attachment.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              );
            })}
          </Row>
          
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AgentInformation;

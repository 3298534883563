import { _l } from "../../hooks/utilities";
import DocumentsServices from "../../services/documents-services";
import { EQUIPMENT_DETAIL_DATA, GET_COMMENTS_DURING_AUDIO_CALL, STAFF_DETAIL } from "../action-type";
import { showMessage } from "../messages";
import {
  ADD_DOCUMENT_COMMENT,
  GET_COMMENTS,
  REMOVE_DOCUMENT_COMMENT,
  GET_SUBSCRIPTION_DETAILS,
  GET_ESTIMATE_DETAILS,
  GET_INVOICE_DETAILS,
  GET_CREDIT_NOTE_DETAILS,
  GET_DOCUMENT_DETAILS,
  GET_ONBOARD_DETAILS,
  GET_ONBOARD_PROVIDER_LIST,
  GET_ONBOARD_DOCUMENT_LIST,
  GET_DOCUMENT_TYPE
} from "./documents-action-types";

export const addEditDocumentComment =
  (rel_type, rel_id, content, is_external = false, comment_id, files, setShowCommentModal, setType, master_record_id, bulk_rel_ids, coOrdinates, is_ai_comment, lease_id, setAiIsTypingLease = ()=>{}, hash_id) =>
    async (dispatch) => {
      return DocumentsServices.addDocumentComment(
        rel_type,
        rel_id,
        content,
        is_external,
        comment_id,
        files,
        master_record_id,
        bulk_rel_ids,
        undefined,
        coOrdinates,
        is_ai_comment,
        lease_id,
      ).then((data) => {
        if (data.status == 1) {
          setShowCommentModal(true);
          setAiIsTypingLease(false);
          setType("")
          dispatch({
            type: ADD_DOCUMENT_COMMENT,
            payload: { rel_id, comment_id, content, rel_type, data: data.data, coOrdinates },
          });
          if (is_ai_comment) {
            dispatch(getComments(rel_id, rel_type, is_external, hash_id));
          }
        }else{
          setAiIsTypingLease(false)
        }
      });
    };

export const getComments = (rel_id, rel_type, is_external, hash, mark_all_as_read = 0, master_record_id) => (dispatch) => {
  return DocumentsServices.getComments(rel_id, rel_type, is_external, hash, mark_all_as_read, master_record_id).then(
    (res) => {
      if(res.status && res.data && res.data.length)
      {
        let sortedComments = res.data.slice();
        sortedComments.sort((a,b) => Number(a.id) - Number(b.id));
        dispatch({
          type: GET_COMMENTS,
          payload: {
            id: rel_id,
            hash: "0",
            rel_type: rel_type,
            comments: sortedComments,
          },
        });       
          dispatch({
            type: GET_COMMENTS_DURING_AUDIO_CALL,
            payload:sortedComments,
          });
        }
      else {
        dispatch({
          type: GET_COMMENTS,
          payload: {
            id: rel_id,
            hash: "0",
            rel_type: rel_type,
            comments: [],
          },
        });
        dispatch({
          type: GET_COMMENTS_DURING_AUDIO_CALL,
          payload:[],
        });
      }
    }
  );
};

export const setComments = (comments, rel_id, rel_type) => (dispatch) => {
  dispatch({
    type: GET_COMMENTS,
    payload: {
      id: rel_id,
      hash: "0",
      rel_type: rel_type,
      comments
    },
  });
}

export const removeDocumentComment = (rel_id, rel_type, comment_id, is_external, master_record_id) => (dispatch) => {
  return DocumentsServices.removeComment(rel_id, rel_type, comment_id, is_external, master_record_id).then(
    (data) => {
      dispatch({
        type: REMOVE_DOCUMENT_COMMENT,
        payload: {
          rel_id,
          rel_type,
          comment_id
        },
      });
    }
  );
};

export const getSubscriptionDetail = (projectId, id) => (dispatch) => {
  return DocumentsServices.getSubscriptionDetail(projectId, id).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_SUBSCRIPTION_DETAILS,
          payload: data.data,
        });
      }
    }
  );
};

export const getEstimateDetail = (projectId, id) => (dispatch) => {
  return DocumentsServices.getEstimateDetail(projectId, id).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_ESTIMATE_DETAILS,
          payload: data.data,
        });
      }
    }
  );
};
export const updateStaffDate = (
  staffid,
  fname = "",
  lname = "",
  email = "",
  phone = "",
  hourly_rate = 0.00,
  skype = "",
  facebook = "",
  linkedin = "",
  type = 0,
  sex = "",
  status = 0,
) => (dispatch) => {
  return DocumentsServices.updateStaffDate(staffid, fname, lname, email, phone, hourly_rate, skype, facebook, linkedin, type, sex, status).then(
    (data) => {
      if(data.status == 1)
      {
        dispatch(getStaffDeatil(staffid));
        dispatch(
          showMessage("sucess", _l("l_success"), "Staff data updated successfully...!")
        );
      }
    }
  )
}
export const updateCustomerData = (
  userid,
  comapny = "",
  vat = "",
  phone = "",
  website = "",
  language = "",
  address = "",
  city = "",
  state = 0,
  zipCode = 0,
  country = 0,
  status = 0,
) => (dispatch) => {
  return DocumentsServices.updateCustomerData(userid, comapny, vat, phone, website, language, address, city, state, zipCode, country, status).then(
    (data) => {
      if(data.status == 1)
      {
        dispatch(getStaffDeatil(userid));
        dispatch(
          showMessage("sucess", _l("l_success"), "Customer data updated successfully...!")
        );
      }
    }
  )
}
export const getPilotOnboardingDocumentDetail = (projectId, id) => (dispatch) => {
  return DocumentsServices.getPilotOnboardingDocumentDetail(projectId, id).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_ONBOARD_DETAILS,
          payload: data.data,
        });
      }
    }
  );
};
export const getStaffDeatil = (id) => (dispatch) => {
  return DocumentsServices.getStaffDeatil(id).then((data) => {
    if(data.status == 1){
      dispatch({
        type: STAFF_DETAIL,
        payload: data.data,
      })
    }
  })
}
export const getEquipmentDetail = (id) => (dispatch) => {
  return DocumentsServices.getEquipmentDetail(id).then((data) => {
    if(data.status == 1){
      dispatch({
        type: EQUIPMENT_DETAIL_DATA,
        payload: data.data,
      })
    }
  })
}
export const updatePilotOnboardingDocumentDetail = (data) => (dispatch) => {
  dispatch({
    type: GET_ONBOARD_DETAILS,
    payload: data,
  });
};

export const setDocumentType = (docType) => (dispatch) => {
  dispatch({
    type: GET_DOCUMENT_TYPE,
    payload: docType,
  });
};

export const getDocumentDetail = (projectId, id) => (dispatch) => {
  return DocumentsServices.getDocumentDetail(projectId, id).then(
    (response) => {
      if (response.status == 1) {
        dispatch({
          type: GET_DOCUMENT_DETAILS,
          payload: response.data
        });
      }
    }
  );
};

export const getInvoiceDetail = (projectId, id, is_app_invoice = 0) => (dispatch) => {
  return DocumentsServices.getInvoiceDetail(projectId, id, "", is_app_invoice).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_INVOICE_DETAILS,
          payload: data.data,
        });
      }
    }
  );
};

export const getCreditNoteDetail = (projectId, id) => (dispatch) => {
  return DocumentsServices.getCreditNoteDetail(projectId, id).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_CREDIT_NOTE_DETAILS,
          payload: { data: data.data, id }
        });
      }
    }
  );
};

export const getOnboardDocumentList = (projectId) => (dispatch) => {
  return DocumentsServices.getPilotOnboardingDocumentList(projectId).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_ONBOARD_DOCUMENT_LIST,
          payload: data.data
        });
      }
    }
  );
};

export const updatedOnboardDocumentList = (updateData) => (dispatch) => {
  dispatch({
    type: GET_ONBOARD_DOCUMENT_LIST,
    payload: updateData
  });
}

export const getOnboardProviderList = (projectId, document_id) => (dispatch) => {
  return DocumentsServices.getOnboardProviderList(projectId, document_id).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_ONBOARD_PROVIDER_LIST,
          payload: data.data
        });
      }
    }
  );
};

export const updateOnboardProviderList = (updateData) => (dispatch) => {
  dispatch({
    type: GET_ONBOARD_PROVIDER_LIST,
    payload: updateData
  });
};


import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { CalendarBlank,ArrowSquareUpRight } from "phosphor-react";
 
import moment from "moment";
import { _l , getDatePickerLocale } from "../../../../hooks/utilities";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";

const LeadCalls = ({ data }) => {
  const [leadCallLogs, setLeadCallLogs] = useState(data);

  useEffect(() => {
    setLeadCallLogs(data);
  }, [JSON.stringify(data)]);

  return (
    <div className="d-flex flex-column flex-grow-1 p-10 py-0">
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-column flex-grow-1">
          <div className="comman-content-scroll-wrapper d-flex flex-column flex-grow-1">
            <div className="comman-content-scroll d-flex flex-column flex-grow-1">
              <div className="comman-data-table small-data-table d-flex flex-column flex-grow-1">
                <table className="dataTable d-flex flex-column flex-grow-1">
                {leadCallLogs && leadCallLogs.length ? (
                  <thead className="bg-transparent">
                    <tr className="bg-white-03">
                      <th style={{ width: "3%" }}>{_l('l_id')}</th>
                      <th style={{ width: "30%" }}>{_l('l_from')}</th>
                      <th style={{ width: "15%" }}>{_l('l_to')}</th>
                      <th style={{ width: "7%" }}>{_l('l_status')}</th>
                      <th style={{ width: "10%" }}>{_l('l_duration')}</th>
                      <th style={{ width: "15%" }}>{_l('l_date')}</th>
                      <th style={{ width: "10%" }}>{_l('l_recording')}</th>
                    </tr>
                  </thead>
                  ) : (
                                        <></>
                                        )
                                        }
                  <tbody className="d-flex flex-column flex-grow-1">
                    {leadCallLogs && leadCallLogs.length ? (
                      leadCallLogs.map((log, index) => {
                        return (
                          <tr key={index} className="c-font f-12 ps-3">
                            <td className="">
                              <a href="#/" className="c-font color-white-80 f-14">1</a>
                            </td>
                            <td className="">
                              <a href="#/"
                                className="c-font color-white-80 f-14 text-truncate m-w150 d-block"
                                 
                                 
                                 
                              >
                                {log.number_from}
                              </a>
                            </td>
                            <td className="">
                              <a href="#/"
                                className="c-font color-white-80 f-14 text-truncate m-w100 d-block"
                                 
                                 
                                 
                              >
                                {log.number_to}
                              </a>
                            </td>
                            <td className="">
                              <a href="#/"
                                className="c-font color-white-80 f-14 text-truncate m-w100 d-block"
                                 
                                 
                                 
                              >
                                {log.status}
                              </a>
                            </td>
                            <td className="">
                              <a href="#/" className="c-font color-white-80 f-14 text-truncate m-w100 d-block">
                              </a>
                            </td>
                            <td className="">
                              <div className="white-border-box color-white-60 d-flex d-none ">
                                <a href="#/" className="custom-datepicker datepicker-input w-90 m-auto ms-2 c-font ">
                                  <div className="d-flex align-items-center ">
                                  <CalendarBlank size={16}  weight="light" className="c-icons" />
                                    <DatePicker
                                      showTimeSelect
                                      locale={getDatePickerLocale()}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText={`${_l(
                                        "l_select_date"
                                      )}`}
                                      calendarStartDay={1}
                                    />
                                  </div>
                                </a>
                              </div>
                              <span className="c-font color-white-80 f-14 ">
                                {moment(log.start_time).format("DD/MM/YYYY")}
                              </span>
                            </td>
                            <td className="text-center">
                              <div className="d-flex justify-content-center">
                                <a
                                  className="comman_action_icon"
                                  href={log.recording}
                                  target={"_blank"}
                                  rel="noopener noreferrer"
                                >
                                  <div
                                    className="h25w25 d-flex align-items-center"
                                     
                                     
                                     
                                  >
                                    <ArrowSquareUpRight size={16} className="c-icons" weight="light" />
                                  </div>
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <CommanPlaceholder imgType="calls" mainWidth="m-auto" placeholderText = {_l("l_no_calls")} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
       
    </div>
  );
};
export default LeadCalls;

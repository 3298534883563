import React, { useEffect, useState } from "react";
import StepperFooter from "../components/SolarExternalPages/StepperFooter";
import StepperHeader from "../components/SolarExternalPages/StepperHeader";
import MapScreen from "../components/SolarExternalPages/MapScreen";
import { _l, showError, validateEmail } from "../hooks/utilities";
import moment from "moment";
import AuthServices from "../services/auth-services";
import CustomerServices from "../services/customer-services";
import SuperficyInput from "../components/SolarExternalPages/SuperficyInput";
import PeopleSelection from "../components/SolarExternalPages/PeopleSelection";
import DatePicker from "react-datepicker";
import Budget from "../components/SolarExternalPages/Budget";
import NameInput from "../components/SolarExternalPages/NameInput";
import CustomPhoneInput from "../components/SolarExternalPages/PhoneInput";
import EmailInput from "../components/SolarExternalPages/EmailInput";
import BenchmarkServices from "../services/benchmark-services";
import { showMessage } from "../actions/messages";
import AcknowledgeScreen from "../components/Modals/OnboardingReusableComponents/AcknowledgeScreen";
import { ACKNOWLEDGEMENT_STEPS } from "../constants/constants";
import { useDispatch } from "react-redux";
import { login } from "../actions/auth";
import { useNavigate } from "react-router";

const STEPS = {
  BACK: -1,
  NEXT: 1,
  SKIP: 0,
};

const STEPS_LIST = [
  "address",
  "superficy",
  "people-count",
  "date-selection",
  "budget",
  "full-name",
  "phonenumber",
  "email",
  "password",
  "stepper-loader",
  "proposal-list",
  "proposal-view",
  "congratulations",
  "task-calendar-view",
];

let solarInfo = {};

const SolarExternalBenchmark = () => {
  const [currentStep, setCurrentStep] = useState("address");
  const [regionList, setRegionList] = useState([]);
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const [userInfo, setUserInfo] = useState({
    address: "Avenue De France, Paris, France",
    superficy: "",
    startDate: new Date(),
    budget: "",
    number_of_people: "",
    fullName: "",
    phonenumber: "",
    email: "",
    password: "",
    benchmarkDetails: {},
    region_name: "Île-de-France",
    region_id: 158,
    tasks: [],
  });
  const [textContent, setTextContent] = useState({
    headerTitle: "",
    stepTitle: "",
    stepDescription: "",
    backButtonText: "",
    nextButtonText: "",
    stepperProgress: 0,
  });
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createSolarBenchmark = async () => {
    try {
      setShowLoading(true);
      const response = await BenchmarkServices.createSolarBenchmark(userInfo);
      if (response.status) {
        showMessage("l_benchmark_created");
        dispatch(login(userInfo.email, userInfo.password)).then(() => {
          navigate("/aichat?plan=customer_plan_1");
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShowLoading(false);
    }
  };

  const setSolarInfo = (updatedSolarInfo) => {
    solarInfo = {...solarInfo, ...updatedSolarInfo};
  };

  const handleAddressValidation = (direction) => {
    if (direction === STEPS.NEXT) {
      if (!solarInfo.address || !solarInfo.address.trim().length) {
        showError("l_please_enter_address");
        return false;
      } else if (
        solarInfo.region_id == 0 &&
        !solarInfo.region_name.trim().length
      ) {
        showError("l_please_enter_valid_address");
        return false;
      } else {
        setUserInfo({...userInfo, ...solarInfo});
        setCurrentStep("superficy");
        setTextContent({
          ...textContent,
          backButtonText: "l_back",
          headerTitle: "l_solar_superficy_step_header",
          nextButtonText: "l_next",
          stepDescription: "l_solar_superificy_step_description",
          stepperProgress: 100 / STEPS_LIST.length,
          stepTitle: "l_solar_superificy_step_title",
        });
        return true;
      }
    }
    return false;
  };

  const handleSuperficyValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("address");
      setTextContent({
        ...textContent,
        backButtonText: "",
        headerTitle: "l_solar_address_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_solar_address_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_solar_address_step_title",
      });
    } else {
      if (
        !userInfo.superficy ||
        isNaN(Number(userInfo.superficy)) ||
        !Number(userInfo.superficy)
      ) {
        showError("l_please_enter_superficy");
        return false;
      } else {
        setCurrentStep("people-count");
        setTextContent({
          ...textContent,
          backButtonText: "l_back",
          headerTitle: "l_solar_people_count_step_header",
          nextButtonText: "l_next",
          stepDescription: "l_solar_people_count_step_description",
          stepperProgress: 100 / STEPS_LIST.length,
          stepTitle: "l_solar_people_count_step_title",
        });
      }
    }
  };

  const handlePeopleCountValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("superficy");
      setTextContent({
        ...textContent,
        backButtonText: "",
        headerTitle: "l_solar_superficy_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_solar_superficy_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_solar_superficy_step_title",
      });
    } else {
      if (
        !userInfo.number_of_people ||
        isNaN(Number(userInfo.number_of_people)) ||
        !Number(userInfo.number_of_people)
      ) {
        showError("l_please_add_number_of_people");
        return false;
      } else {
        setCurrentStep("date-selection");
        setTextContent({
          ...textContent,
          backButtonText: "l_back",
          headerTitle: "l_solar_date_selection_step_header",
          nextButtonText: "l_next",
          stepDescription: "l_solar_date_selection_step_description",
          stepperProgress: 100 / STEPS_LIST.length,
          stepTitle: "l_solar_date_selection_step_title",
        });
      }
    }
  };

  const handleDateValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("people-count");
      setTextContent({
        ...textContent,
        backButtonText: "",
        headerTitle: "l_people_count_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_people_count_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_people_count_step_title",
      });
    } else {
      if (!userInfo.startDate || !moment(userInfo.startDate).isValid()) {
        showError("l_please_select_valid_start_date");
        return false;
      } else {
        setCurrentStep("budget");
        setTextContent({
          ...textContent,
          backButtonText: "l_back",
          headerTitle: "l_solar_budget_step_header",
          nextButtonText: "l_next",
          stepDescription: "l_solar_budget_step_description",
          stepperProgress: 100 / STEPS_LIST.length,
          stepTitle: "l_solar_budget_step_title",
        });
      }
    }
  };

  const handleBudgetValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("date-selection");
      setTextContent({
        ...textContent,
        backButtonText: "l_back",
        headerTitle: "l_solar_date_selection_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_solar_date_selection_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_solar_date_selection_step_title",
      });
    } else {
      if (
        !userInfo.budget ||
        isNaN(Number(userInfo.budget)) ||
        !Number(userInfo.budget)
      ) {
        showError("l_please_add_budget");
        return false;
      } else {
        setCurrentStep("full-name");
        setTextContent({
          ...textContent,
          backButtonText: "l_back",
          headerTitle: "l_solar_user_full_name_step_header",
          nextButtonText: "l_next",
          stepDescription: "l_solar_user_full_name_step_description",
          stepperProgress: 100 / STEPS_LIST.length,
          stepTitle: "l_solar_user_full_name_step_title",
        });
      }
    }
  };

  const handleFullNameValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("budget");
      setTextContent({
        ...textContent,
        backButtonText: "l_back",
        headerTitle: "l_solar_budget_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_solar_budget_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_solar_budget_step_title",
      });
    } else {
      if (!userInfo.fullName || !userInfo.fullName.trim().length) {
        showError("l_please_enter_name");
        return false;
      } else {
        setCurrentStep("phonenumber");
        setTextContent({
          ...textContent,
          backButtonText: "l_back",
          headerTitle: "l_solar_user_phonenumber_step_header",
          nextButtonText: "l_next",
          stepDescription: "l_solar_user_phonenumber_step_description",
          stepperProgress: 100 / STEPS_LIST.length,
          stepTitle: "l_solar_user_phonenumber_step_title",
        });
      }
    }
  };

  const handlePhonenumberValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("full-name");
      setTextContent({
        ...textContent,
        backButtonText: "l_back",
        headerTitle: "l_solar_full_name_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_solar_full_name_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_solar_full_name_step_title",
      });
    } else {
      if (!userInfo.phonenumber || !userInfo.phonenumber.trim().length) {
        showError("l_phone_number_cannot_be_empty");
        return false;
      } else {
        setCurrentStep("email");
        setTextContent({
          ...textContent,
          backButtonText: "l_back",
          headerTitle: "l_solar_user_email_step_header",
          nextButtonText: "l_next",
          stepDescription: "l_solar_user_email_step_description",
          stepperProgress: 100 / STEPS_LIST.length,
          stepTitle: "l_solar_user_email_step_title",
        });
      }
    }
  };

  const handleEmailValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("phonenumber");
      setTextContent({
        ...textContent,
        backButtonText: "l_back",
        headerTitle: "l_solar_phonenumber_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_solar_phonenumber_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_solar_phonenumber_step_title",
      });
    } else {
      if (!userInfo.email || !validateEmail(userInfo.email)) {
        showError("l_please_enter_valid_email");
        return false;
      } else {
        AuthServices.checkEmailforSetPassword(userInfo.email).then(
          (response) => {
            if (response.status) {
              showError("l_email_already_exist");
            } else {
              setCurrentStep("password");
              setTextContent({
                ...textContent,
                backButtonText: "l_back",
                headerTitle: "l_solar_user_password_step_header",
                nextButtonText: "l_next",
                stepDescription: "l_solar_user_password_step_description",
                stepperProgress: 100 / STEPS_LIST.length,
                stepTitle: "l_solar_user_password_step_title",
              });
            }
          }
        );
      }
    }
  };

  const handlePasswordValidation = (direction) => {
    if (direction === STEPS.BACK) {
      setCurrentStep("email");
      setTextContent({
        ...textContent,
        backButtonText: "l_back",
        headerTitle: "l_solar_email_step_header",
        nextButtonText: "l_next",
        stepDescription: "l_solar_email_step_description",
        stepperProgress: 100 / STEPS_LIST.length,
        stepTitle: "l_solar_email_step_title",
      });
    } else {
      if (!userInfo.password || !userInfo.password.length) {
        showError("l_please_enter_password");
        return false;
      } else {
        createSolarBenchmark();
      }
    }
  };

  const changeStep = (direction) => {
    switch (currentStep) {
      case "address":
        handleAddressValidation(direction);
        break;
      case "superficy":
        handleSuperficyValidation(direction);
        break;
      case "people-count":
        handlePeopleCountValidation(direction);
        break;
      case "date-selection":
        handleDateValidation(direction);
        break;
      case "budget":
        handleBudgetValidation(direction);
        break;
      case "full-name":
        handleFullNameValidation(direction);
        break;
      case "phonenumber":
        handlePhonenumberValidation(direction);
        break;
      case "email":
        handleEmailValidation(direction);
        break;
      case "password":
        handlePasswordValidation(direction);
        break;
      case "stepper-loader":
        break;
      case "proposal-list":
        break;
      case "proposal-view":
        break;
      case "congratulations":
        break;
      case "task-calendar-view":
        break;
    }
  };

  useEffect(() => {
    const fetchedRegions = () => {
      CustomerServices.getRegionList()
        .then((res) => {
          if (res.status == 1 && res.data) {
            setRegionList(
              res.data.map((data) => {
                return {
                  label: data.name,
                  value: data.id,
                };
              })
            );
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setTimeout(() => {
            setIsPageLoaded(true);
          }, 1000);
        });
    };

    fetchedRegions();
  }, []);

  return (
    <div className="d-flex flex-column flex-grow-1">
      {showLoading ? (
        <div className="absoluteLoader calendar-modal-color">
          <AcknowledgeScreen
            afterDismissAction={() => {}}
            delay={7000}
            steps={ACKNOWLEDGEMENT_STEPS["customer_plan_1"]}
            description={
              "l_feature_allows_experience_entirely_new_way_interacting"
            }
            title={"l_onboarding_acknowledgement_header_title"}
          />
        </div>
      ) : (
        <React.Fragment>
          <StepperHeader
            backButtonHandler={() => changeStep(STEPS.BACK)}
            nextButtonHandler={() => changeStep(STEPS.NEXT)}
            textContent={textContent}
          />
          {isPageLoaded ? (
            <React.Fragment>
              <div className={`${currentStep === "address" ? "h-100" : "d-none"}`}>
                <MapScreen
                  solarInfo={solarInfo}
                  setSolarInfo={setSolarInfo}
                  regionList={regionList}
                  setRegionList={setRegionList}
                />
              </div>
              {currentStep === "superficy" ? (
                <SuperficyInput
                  type={"number"}
                  value={userInfo.superficy}
                  placeholder={`${1500} ${_l("l_sq_meter")}`}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, superficy: e.target.value })
                  }
                />
              ) : currentStep === "people-count" ? (
                <PeopleSelection
                  name={"people-count"}
                  people={userInfo.number_of_people}
                  setPeople={(people) =>
                    setUserInfo({
                      ...userInfo,
                      number_of_people: Number(people),
                    })
                  }
                />
              ) : currentStep === "date-selection" ? (
                <div className="d-flex justify-content-center">
                  <DatePicker
                    selected={userInfo.startDate}
                    onChange={(date) => {
                      setUserInfo({ ...userInfo, startDate: date });
                    }}
                    inline
                    showTimeSelect
                  />
                </div>
              ) : currentStep === "budget" ? (
                <Budget
                  type={"number"}
                  value={userInfo.budget}
                  placeholder={`${100}`}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, budget: e.target.value })
                  }
                />
              ) : currentStep === "full-name" ? (
                <NameInput
                  type={"text"}
                  value={userInfo.fullName}
                  placeholder={_l("l_full_name_placeholder")}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, fullName: e.target.value })
                  }
                />
              ) : currentStep === "phonenumber" ? (
                <CustomPhoneInput
                  onChange={(phone) =>
                    setUserInfo({ ...userInfo, phonenumber: phone })
                  }
                />
              ) : currentStep === "email" ? (
                <EmailInput
                  type={"text"}
                  value={userInfo.email}
                  placeholder={_l("l_assignee_email_placeholder")}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, email: e.target.value })
                  }
                />
              ) : currentStep === "password" ? (
                <EmailInput
                  type={"password"}
                  value={userInfo.password}
                  placeholder={_l("*****")}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, password: e.target.value })
                  }
                />
              ) : (
                <></>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <StepperFooter />
        </React.Fragment>
      )}
    </div>
  );
};

export default SolarExternalBenchmark;

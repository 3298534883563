import React, { useEffect, useRef, useState } from "react";
import { X, UploadSimple, Plus, Check, FilePdf } from "phosphor-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import Select from "react-select";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import { getFloorDocumentByFloorId } from "../../actions/customer";
import Spinner from "react-bootstrap/Spinner";


const FloorDocument = ({ show, handleClose, data }) => {
  const dispatch = useDispatch();
  const [addDetails, setAddetails] = useState({
    assignee: '',
    category: ''
  });
  const fileInputRef = useRef();
  const [floorList, setFloorList] = useState([]);
  const [file, setFile] = useState([]);
  const [previewArray, setPreviewArray] = useState([]);
  const [title, setTitle] = useState(data && data.id > 0 ? data.title : "");
  const [description, setDescription] = useState("");
  const [floorId, setFloorId] = useState("");
  const [spaceDocumentId, setSpaceDocumentId] = useState("");
  const [loder, setLoder] = useState(false);

  const [addCategory, setAddCategory] = useState(true);

  useEffect(() => {
    setFloorId(getSelectedFloorId)
  }, [show])
  useEffect(() => {
    if (data) {
      setFloorId(data.floor_id);
      setDescription(data.description);
      setTitle(data.title);
      setPreviewArray(data.attachments ? data.attachments.map((item) => { return { name: item.file_url.split("/").slice(-1)[0], url: item.file_url, id: item.id }; }) : [])
      setSpaceDocumentId(data.id)
    }
  }, [data])
  const { getSapceFloorList, getSelectedFloorId } = useSelector(
    (state) => state.customer
  );
  useEffect(() => {
    if (getSapceFloorList) { 
      setFloorList(getSapceFloorList.floor.map((f) => {
              return { label: f.title, value: f.id }
            }));
    }
  },[JSON.stringify(getSapceFloorList)])
  const handleFileUpload = (files) => {
    if (files) {
      let thumbnails = [];
      let newFiles = [];
      Array.from(files).map((img) => {
        thumbnails.push({ name: img.name, url: URL.createObjectURL(img) });
        newFiles.push(img);
      });

      if (file && Array.from(file).length) {
        /**Remove duplicate attachments from array */
        newFiles = Array.from(newFiles).filter(
          (x) => Array.from(file).findIndex((w) => w.name == x.name) === -1
        );
        thumbnails = thumbnails.filter(
          (x) => previewArray.findIndex((w) => w.name == x.name) === -1
        );
        setFile(newFiles);
        setPreviewArray(thumbnails);
      } else {
        setFile(files);
        setPreviewArray(thumbnails);
      }
    }
  };
  const removeAttachment = (ref) => {
    if(spaceDocumentId > 0 && ref.id > 0){
      customerServices.removeSpaceDocumentAttachment(spaceDocumentId, ref.id).then((res) => {
        if (res.status == 1) { 
          dispatch(toastCloseAction());
          setPreviewArray(previewArray.filter((x) => x.name !== ref.name));
        }
      });
    }else{
      let currentFiles = file;
      currentFiles = Array.from(currentFiles).filter(
        (file) => file.name !== ref.name
        );
        setFile(currentFiles);
        setPreviewArray(previewArray.filter((x) => x.name !== ref.name));
        dispatch(toastCloseAction());
      }
  };
  const addDocument = () => {
    if(!title || title == ""){
      dispatch(showMessage('unsucess', _l('l_error'), _l("l_please_add_title")));
    }else if(!floorId || floorId == "" || floorId == 0){
      dispatch(showMessage('unsucess', _l('l_error'), _l("l_please_select_floor")));
    }else if((!spaceDocumentId || spaceDocumentId == "" || spaceDocumentId == 0) && (!file || (file && file.length == 0))){
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_select_file')));
    }else{
      setLoder(true);
      customerServices.addSpaceDocument(floorId, title, description, file, spaceDocumentId).then((res) => {
        setLoder(false);
        if (res.status == 1) {
          handleClose();
          clearForm()
          dispatch(getFloorDocumentByFloorId(floorId));
        }else{
          dispatch(showMessage('unsucess', _l('l_error'), res.message));
        }
      });
    }
  }
  const deleteAttechmentConfirm = (ref) => {
    dispatch(showConfirmation(_l("l_are_you_sure"),_l("l_you_want_to_delete_file"), _l("l_yes"), _l("l_no"), undefined, () => {removeAttachment(ref);}));
  }
  const clearForm = () => {
    setDescription('');
    setTitle('');
    setFile([]);
    setPreviewArray([]);
    setFloorId('')
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose()
          clearForm()
        }}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{spaceDocumentId > 0 ? _l("l_edit_floor_document") : _l("l_add_floor_document")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            
              <Form.Group className="c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_title")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_title")}`}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </Form.Group>
              <Form.Group className="c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_description")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_description")}`}
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </Form.Group>
              
                <div className="row c-input-box  position-relative align-items-center">
                  {
                    addCategory ? (
                      <Form.Group className="col-12 c-input-box pb-3 position-relative comment-input-box">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_select_floor")}
                        </Form.Label>
                        <div className="position-relative">
                          <Select className="custom-select-menu w-100 form-control ps-0 py-0 wirh-border-none pe-0"
                            classNamePrefix="react-select"
                            options={floorList}
                            closeMenuOnSelect={true}
                            placeholder={`${_l("l_please_select")}`}
                            onChange={(e) => setFloorId(e.value)}
                            value={floorList.filter(f => f.value == floorId)[0]}
                            isDisabled={true}
                          />
                          <a href="#/" className="send-arrow-btn d-none" onClick={() => setAddCategory(false)}>
                            <div
                              alt={"White arrow"}
                              className="img-width-18 m-auto d-none"
                            >
                              <Plus size={16} weight="light" className="c-icons" />
                            </div>
                          </a>
                        </div>
                      </Form.Group>) : (
                      <Form.Group className="col-12 c-input-box pb-3 position-relative comment-input-box">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_add_category")}
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            placeholder={_l("l_add_category_placeholder")}
                            type="text"
                            name=""
                            value={addDetails.category}
                            onChange={(e) => {
                              setAddetails({ ...addDetails, ['category']: e.target.value })
                            }}
                          />
                          <a href="#/" className="send-arrow-btn" onClick={() => setAddCategory(true)}>
                            <div
                              alt={"White arrow"}
                              className="img-width-18 m-auto"
                            >
                              <Check size={16} weight="light" className="c-icons" />
                            </div>
                          </a>
                        </div>
                      </Form.Group>)
                  }
                </div>
              
              <Form.Group className="c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_select_box")}
                </Form.Label>
                <div className="form-control file-upload position-relative with-bg">
                  <div className="d-flex align-items-center justify-content-center">
                    <Form.Control className="  hidden-input" type="file"
                      ref={fileInputRef}
                      accept=".jpg, .jpeg, application/pdf, .glb, .png"
                      onChange={(e) => {
                        handleFileUpload(e.target.files);
                      }}
                    />
                    <UploadSimple size={18} className="c-icons m-0" weight="light" />
                    <span className="color-white-60 ps-2">
                      {_l("l_browse_file")}
                    </span>
                  </div>
                </div>
                <div className="upload-image-preview mt-4">
                  {previewArray.map((thumb, index) => {
                    return <div key={index} className="d-flex align-items-center justify-content-start mb-3">
                      <div className="comman-image-box h40w40 radius_3 upload-image">
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                          style={{
                            backgroundImage: `url('${thumb.url}')`,
                          }}
                        ><FilePdf size={24} color="#00ff6a" weight="light" className="c-icons d-none" /></div>
                        <a href="#/" className="upload-close"
                          onClick={() => {
                            deleteAttechmentConfirm(thumb);
                          }}>
                          <X size={14} className="c-icons" weight="light" />
                        </a>
                      </div>
                      <div class="image-text w100minus50 ps-3 text-truncate">
                        <div class="text-truncate c-font f-13">{thumb.name}</div>
                        <div class="text-truncate c-font f-11 color-white-60 "></div>
                      </div>
                    </div>
                  })}
                </div>
              </Form.Group>
            
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={() => {
            handleClose()
            clearForm()
          }}>
            {_l('l_close')}
          </Button>
          <Button 
            variant="primary" size="sm"
            onClick = {() => addDocument()}
            disabled={loder ? true : false}
          >
            { loder ? _l('l_please_wait') : _l('l_save') }
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2`}
              aria-hidden="true"
            />
            : <></>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FloorDocument;

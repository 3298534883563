import { Check, PencilSimple, Trash } from "phosphor-react";
import React, { useRef, useState } from "react";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import { _l, showError } from "../../../hooks/utilities";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import BenchmarkServices from "../../../services/benchmark-services";

const BenchmarkPerUnit = ({unitsList, setUnitsList}) => {
  const [editOngoingIndex, setEditOngoingIndex] = useState(unitsList && unitsList.length == 1 ? 0 : -1);

  const { benchmarkDetail } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const itemInputRef = useRef();

  const addUnitItemToList = () => {
    if (
      !itemInputRef.current ||
      !itemInputRef.current.value ||
      !itemInputRef.current.value.trim()
    ) {
      showError("l_please_enter_valid_description");
      return false;
    }
    if (
      unitsList.some(
        (unit) =>
          unit.description &&
          unit.description.trim().toLowerCase() ==
            itemInputRef.current.value.trim().toLowerCase()
      )
    ) {
      showError("l_unit_already_exists");
      return finalPropsSelectorFactory;
    }
    setUnitsList(
      unitsList.concat({
        id: 0,
        description: itemInputRef.current.value.trim(),
        monthly: false,
        half_yearly: false,
        yearly: false,
        quarterly: false,
        key: crypto.randomUUID(),
      })
    );
    itemInputRef.current.value = "";
  };

  const deleteUnitItem = (unitData) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        "l_delete_unit",
        _l("l_delete"),
        _l("l_cancel"),
        () => {
          dispatch(toastCloseAction());
        },
        async () => {
          try {
            if (unitData.id > 0) {
              const response = await BenchmarkServices.deleteBenchmarkUnitItem(
                benchmarkDetail.id,
                benchmarkDetail.project_id,
                unitData.id
              );
              if (response.status) {
                setUnitsList(
                  unitsList.filter((unit) => unit.id !== unitData.id)
                );
              }
            } else {
              setUnitsList(
                unitsList.filter((unit) => unit.key !== unitData.key)
              );
            }
          } catch (e) {
            console.error(e);
          }
          finally {
            dispatch(toastCloseAction());
          }
        }
      )
    );
  };

  return (
    <div>
      {
        benchmarkDetail && benchmarkDetail.is_opened == 1
        ?
        <div className="end-0 gy-0 gx-2 m-0 mb-4 z-index-2 bg-white-03-solid-imp position-sticky top-0">
          <div className="col-xl-12 c-input-box position-relative ">
            <div className="d-flex align-items-center justify-content-between w-100 ">
              <div className="d-flex align-items-center justify-content-between form-control flex-grow-1 h_53px comman_action_icon">
                <div className="with_separator_10 flex-grow-1 ps-0">
                  <input
                    type="text"
                    className="bg-transparent border-0 "
                    placeholder={_l("l_item_name")}
                    ref={itemInputRef}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        addUnitItemToList();
                      }
                    }}
                  />
                </div>
              </div>
              <a
                href="#/"
                className="align-items-center d-flex form-control h-100 h50w50 justify-content-center ms-3 px-2"
                onClick={addUnitItemToList}
              >
                <div className="h-100">
                  <Check size={20} className="c-icons" weight="light" />
                </div>
              </a>
            </div>
          </div>
        </div>
        :
        <React.Fragment></React.Fragment>
      }
      <div className="flex-grow-1">
        <div className="row m-0 g-0  overflow-auto">
          <div className="h-100">
            {unitsList.length ? (
              <React.Fragment>
                <div className="comman-data-table GeneralItemTable shadow-none">
                  <table className="dataTable comman-table smaller-table">
                    <thead className="fw-normal c-font f-14 bg-white-03-solid  ">
                      <tr className="p-5px bg-white-03 radius_3">
                        <th class="fw-normal py-2" style={{ width: "2%" }}>
                          #
                        </th>
                        <th className="fw-normal py-2" style={{ width: "76%" }}>
                          {_l("l_item")}
                        </th>
                        <th class="fw-normal py-2 text-center on-hover-hide-show-main">
                          <th class="d-flex flex-column p-0 ">
                            <tr>M</tr>
                          </th>
                        </th>
                        <th class="fw-normal py-2 text-center on-hover-hide-show-main">
                          <th class="d-flex flex-column p-0 ">
                            <tr>Q</tr>
                          </th>
                        </th>
                        <th class="fw-normal py-2 text-center on-hover-hide-show-main">
                          <th class="d-flex flex-column p-0 ">
                            <tr>S</tr>
                          </th>
                        </th>
                        <th class="fw-normal py-2 text-center on-hover-hide-show-main">
                          <th class="d-flex flex-column p-0 ">
                            <tr>Y</tr>
                          </th>
                        </th>
                        {
                          benchmarkDetail && benchmarkDetail.is_opened == 1
                          ?
                          <React.Fragment>
                            <th class="fw-normal py-2 text-center on-hover-hide-show-main">
                              <th class="d-flex flex-column p-0 ">
                                <tr></tr>
                              </th>
                            </th>
                            <th class="fw-normal py-2 text-center on-hover-hide-show-main">
                              <th class="d-flex flex-column p-0 ">
                                <tr></tr>
                              </th>
                            </th>
                          </React.Fragment>
                          :
                          <React.Fragment></React.Fragment>
                        }
                      </tr>
                    </thead>
                    <tbody className="bgOfficelisting">
                      {unitsList.map((unit, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td
                              className="text-truncate max-width-100 for-accessible"
                               
                               
                               
                            >
                              <input
                                className="form-control pe-20pximp p-10 text-truncate for-accessible "
                                placeholder={`${_l("l_item_name")}`}
                                disabled={editOngoingIndex !== index}
                                value={unit.description}
                                onChange={(e) => {
                                  setUnitsList(
                                    unitsList.map((unit, position) => {
                                      if (position === index) {
                                        unit.description = e.target.value;
                                      }
                                      return unit;
                                    })
                                  );
                                }}
                              />
                            </td>
                            <td className="text-center ">
                              <label for="" className="">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  checked={unit.monthly}
                                  disabled={benchmarkDetail && benchmarkDetail.is_opened != 1}
                                  onChange={(e) => {
                                    setUnitsList(
                                      unitsList.map((unit, position) => {
                                        if (position === index) {
                                          unit.monthly = e.target.checked;
                                          if (e.target.checked) {
                                            unit.quarterly = false;
                                            unit.half_yearly = false;
                                            unit.yearly = false;
                                          }
                                        }
                                        return unit;
                                      })
                                    );
                                  }}
                                />
                              </label>
                            </td>
                            <td className="text-center ">
                              <label for="" className="">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  checked={unit.quarterly}
                                  disabled={benchmarkDetail && benchmarkDetail.is_opened != 1}
                                  onChange={(e) => {
                                    setUnitsList(
                                      unitsList.map((unit, position) => {
                                        if (position === index) {
                                          unit.quarterly = e.target.checked;
                                          if (e.target.checked) {
                                            unit.monthly = false;
                                            unit.half_yearly = false;
                                            unit.yearly = false;
                                          }
                                        }
                                        return unit;
                                      })
                                    );
                                  }}
                                />
                              </label>
                            </td>

                            <td className="text-center ">
                              <label for="" className="">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  checked={unit.half_yearly}
                                  disabled={benchmarkDetail && benchmarkDetail.is_opened != 1}
                                  onChange={(e) => {
                                    setUnitsList(
                                      unitsList.map((unit, position) => {
                                        if (position === index) {
                                          unit.half_yearly = e.target.checked;
                                          if (e.target.checked) {
                                            unit.monthly = false;
                                            unit.quarterly = false;
                                            unit.yearly = false;
                                          }
                                        }
                                        return unit;
                                      })
                                    );
                                  }}
                                />
                              </label>
                            </td>
                            <td className="text-center ">
                              <label for="" className="">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  disabled={benchmarkDetail && benchmarkDetail.is_opened != 1}
                                  checked={unit.yearly}
                                  onChange={(e) => {
                                    setUnitsList(
                                      unitsList.map((unit, position) => {
                                        if (position === index) {
                                          unit.yearly = e.target.checked;
                                          if (e.target.checked) {
                                            unit.monthly = false;
                                            unit.quarterly = false;
                                            unit.half_yearly = false;
                                          }
                                        }
                                        return unit;
                                      })
                                    );
                                  }}
                                />
                              </label>
                            </td>
                            {
                              benchmarkDetail && benchmarkDetail.is_opened == 1
                              ?
                              <React.Fragment>
                                <td className="comman_action_icon text-center">
                                  {editOngoingIndex === index ? (
                                    <a
                                      className="action_icon with_bg h32w32"
                                      onClick={() => {
                                        setEditOngoingIndex(-1);
                                      }}
                                    >
                                      <Check
                                        size={16}
                                        className="c-icons"
                                        weight="light"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      className="action_icon with_bg h32w32"
                                      onClick={() => {
                                        setEditOngoingIndex(index);
                                      }}
                                    >
                                      <PencilSimple
                                        size={16}
                                        className="c-icons"
                                        weight="light"
                                      />
                                    </a>
                                  )}
                                </td>
                                <td className="comman_action_icon text-center">
                                  <a
                                    className="action_icon with_bg h32w32"
                                    onClick={() => {
                                      deleteUnitItem(unit);
                                    }}
                                  >
                                    <Trash
                                      size={16}
                                      className="c-icons"
                                      weight="light"
                                    />
                                  </a>
                                </td>
                              </React.Fragment>
                              :
                              <React.Fragment></React.Fragment>
                            }
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ) : (
              <CommanPlaceholder
                mainSpacing="h-100 m-auto"
                imgType="todo-task"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenchmarkPerUnit;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isPremuimEstimateDisable,
  setSelectedGloablView,
  setSelectedLanguage,
} from "../actions/customer";
import { DEFAULT_PLACEHOLDER } from "../actions/action-type";
import {
  _l,
  favoriteDdashboardSelected,
  loadDataToIDB,
  validateEmail,
} from "../hooks/utilities";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  Buildings,
  CaretRight,
  EnvelopeSimple,
  LightbulbFilament,
  Password,
  Pencil,
  Percent,
  Phone,
  UserCircle,
  UserCircleGear,
  Wrench,
} from "phosphor-react";
import CelebrationIcon from "../assets/icons/CelebrationIcon";
import PhoneInput from "react-phone-input-2";
import { showError } from "./../hooks/utilities";
import RegistrationFlowHeader from "../components/RegistrationFlow/RegistrationFlowHeader";
import authServices from "./../services/auth-services";
import { login } from "../actions/auth";
import AuthService from "../services/auth-services";
import RadioButtonWithIcon from "../components/Leads/RadioButton";
import { showMessage } from "../actions/messages";
import {
  ACKNOWLEDGEMENT_STEPS,
  CUSTOM_CLIENT_IDS,
  CUSTOM_HOMEPAGE_URLS,
  DEFAULT_ADDRESS,
  DEFAULT_REGION_ID,
  DEFAULT_REGION_NAME,
} from "../constants/constants";
import { fetchAuthToken } from "../actions/chat-auth";
import ListingWithCheckbox from "../components/Modals/OnboardingReusableComponents/ListingWithCheckbox";
import customerServices from "../services/customer-services";
import Address from "../components/SpaceOnBoarding/SpaceDetails/Address";
import AcknowledgeScreen from "../components/Modals/OnboardingReusableComponents/AcknowledgeScreen";
import { Button } from "react-bootstrap";

const RadioButtonArray = ({ data, name, onChange, onClick, value, hashID }) => {
  return (
    <div className="d-flex justify-content-center flex-wrap">
      {data.map((values, key) => {
        return (
          <RadioButtonWithIcon
            key={key}
            {...values}
            onChange={onChange}
            onClick={onClick}
            name={name}
            checked={value === values.value}
            disabled={hashID != null}
          />
        );
      })}
    </div>
  );
};

const REGISTER_USER_TYPE = {
  CUSTOMER: 1,
  PROVIDER: 2,
  AGENT: 3,
};

const RegistrationFlowNew = () => {

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const hashID = searchParams.get("link"); 

  const [value, setValue] = useState();
  const [loader, setLoader] = useState(false);
  const [currentStep, setCurrentStep] = useState("fullname");
  const [titleText, setTitleText] = useState("");
  const [description, setDescription] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [hideLeftPanel, setHideLeftPanel] = useState(true);
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState();
  const [userProfileUrl, setUserProfileUrl] = useState(
    "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg"
  );
  const [companyProfileUrl, setCompanyProfileUrl] = useState(
    ""
  );
  const [categoryListing, setCategoryListing] = useState([]);
  const [selectedcategoryListing, setSelectedCategoryListing] = useState([]);
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const [regionList, setRegionList] = useState([]);
  const [spaceDetail, setSpaceDetail] = useState({
    address: DEFAULT_ADDRESS,
    title: "",
    region_id: DEFAULT_REGION_ID,
    office_id: "",
    superfice: 1000,
    number_of_desk: 10,
    country_id: "",
    is_edit: "",
    logo: "",
    is_virtual_space: 0,
    space_type: "",
    space_logo: "",
    floor_count: 1,
    space_id: 0,
    id: 0,
    floors: [],
    region_name: DEFAULT_REGION_NAME,
  });
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [numberFormate, setNumberFormate] = useState("");
  const [numberlength, setNumberLength] = useState({ max: 10, min: 9 });
  const [showLoading, setShowLoading] = useState(false);


  const userTypes = [
    {
      title: "l_company_title_one",
      description: "l_company_description_one",
      value: REGISTER_USER_TYPE.CUSTOMER,
      Icon: { name: Buildings, className: "c-icons stroke-width-5px h60w60" },
    },
    {
      title: "l_company_title_two",
      description: "l_company_description_two",
      value: REGISTER_USER_TYPE.PROVIDER,
      Icon: {
        name: UserCircleGear,
        className: "c-icons stroke-width-5px h60w60",
      },
    },
    {
      title: "l_company_title_three",
      description: "l_company_description_three",
      value: REGISTER_USER_TYPE.AGENT,
      Icon: {
        name: Wrench,
        className: "c-icons stroke-width-5px h60w60",
      },
    },
  ];

  const [textContent, setTextContent] = useState({
    stepTitle: "",
    stepperProgress: 0,
  });

  const languageSelect = [
    { value: "english", label: _l("l_english") },
    { value: "french", label: _l("l_french") },
  ];
  const [language, setlanguage] = useState(localStorage.getItem("language"));

  const [formData, setFormData] = useState({
    fullName: "",
    phonenumber: "",
    email: "",
    password: "",
    image: "",
    userType: "",
    companyname: "",
    companyaddress: DEFAULT_ADDRESS,
    vat: "",
    companyprofilepic: "",
    countryCode: "",
  });

  const STEPS_LIST = [
    "fullname",
    "phonenumber",
    "email",
    "password",
    "profilepic",
  ];

  const handleStepChange = (direction) => {
    if (direction > 0) {
      switch (currentStep) {
        case "fullname":
          if (formData.fullName != "") {
            setCurrentStep("phonenumber");
            setTextContent({
              stepTitle: _l("l_register_your_name_step_heading"),
              stepperProgress: Math.trunc((1 / STEPS_LIST.length) * 100),
            });
          } else {
            showError(_l("l_please_enter_name"));
          }
          break;
        case "phonenumber":
          if (
            formData.phonenumber != "" &&
            (numberlength.max == formData.phonenumber.length ||
              numberlength.min == formData.phonenumber.length)
          ) {
            setCurrentStep("email");
            setBtnDisable(true);
            setTextContent({
              stepTitle: _l("l_register_contact_number_step_heading"),
              stepperProgress: Math.trunc((2 / STEPS_LIST.length) * 100),
            });
          } else {
            showError(_l("l_please_enter_valid_mobile_number"));
          }
          break;
        case "email":
          if (formData.email != "") {
            if (validateEmail(formData.email)) {
              let isValid = 1;
              authServices
                .checkEmailforSetPassword(formData.email)
                .then((res) => {
                  isValid = res.status;
                });
              if (isValid == 1) {
                setCurrentStep("password");
                setTextContent({
                  stepTitle: _l("l_register_your_email_required_step_heading"),
                  stepperProgress: Math.trunc((3 / STEPS_LIST.length) * 100),
                });
              } else {
                showError(_l("l_invalid_email"));
              }
            } else {
              showError(_l("l_invalid_email"));
            }
          } else {
            showError("l_please_enter_email");
          }
          break;
        case "password":
          if (formData.password != "") {
            if (termsAndCondition) {
              setCurrentStep("profilepic");
              setTextContent({
                stepTitle: "l_register_your_secret_code_step_heading",
                stepperProgress: Math.trunc((4 / STEPS_LIST.length) * 100),
              });
            } else {
              showError(_l("l_please_accept_terms_and_conditions"));
            }
          } else {
            showError(_l("l_please_enter_password"));
          }
          break;
        case "profilepic":
          if (formData.image != "") {
            if (formData.userType == 3) {
              setCurrentStep("companyadress");
            }
            setTextContent({
              stepTitle: _l("l_register_let’s_add_ersonal_touch_step_heading"),
              stepperProgress: Math.trunc((5 / STEPS_LIST.length) * 100),
            });
          handleRegistration(formData, 1);
          } else {
            showError(_l("l_upload_image"));
          }
          break;
        case "servicetype":
          if (formData.userType === 3) {
            setCurrentStep("companyadress");
            // handleRegistration(formData, 2);
          } else if (formData.userType === 2) {
            setCurrentStep("categoryList");
          } else if (formData.userType !== 2 && formData.userType > 0) {
            setCurrentStep("companyname");
          } else {
            showError(_l("l_please_select_user_type"));
          }
          // if (formData.servicetype != "") {
          //     setCurrentStep("companyname");
          // } else {
          //     showError(_l("l_please_enter_company_name"));
          // }
          break;
        case "categoryList":
          if (formData.userType === 3) {
            setCurrentStep("allset");
          } else if (selectedcategoryListing.length) {
            setCurrentStep("companyname");
          } else {
            showError(_l("l_please_select_category"));
          }
          break;
        case "companyname":
          if (formData.companyname != "") {
            setCurrentStep("companyadress");
          } else {
            showError(_l("l_please_enter_company_name"));
          }
          break;
        case "companyadress":
          if (formData.companyadress != "") {
            if (formData.userType === 3) {
              setCurrentStep("categoryList");
            } else if (formData.userType === 2) {
              setCurrentStep("vatfield");
            } else {
              setCurrentStep("companyprofilepic");
            }
          } else {
            showError(_l("l_please_enter_the") + " " + _l("l_office_address"));
          }
          break;
        case "vatfield":
          if (formData.vat != "") {
            setCurrentStep("companyprofilepic");
          } else {
            showError("l_please_set_vat_rate");
          }
          break;
        case "companyprofilepic":
          if (formData.companyprofilepic != "") {
            setCurrentStep("allset");
          } else {
            showError(_l("l_upload_image"));
          }
          break;
        case "allset":
          handleRegistration(formData, 2);
          break;
      }
    } else {
      switch (currentStep) {
        case "phonenumber":
          setCurrentStep("fullname");
          setTextContent({
            stepTitle: _l("l_setup_your_account"),
            stepperProgress: Math.trunc((0 / STEPS_LIST.length) * 100),
          });
          break;
        case "email":
          setCurrentStep("phonenumber");
          setBtnDisable(false);
          setTextContent({
            stepTitle: _l("l_setup_your_account"),
            stepperProgress: Math.trunc((1 / STEPS_LIST.length) * 100),
          });
          break;
        case "password":
          setCurrentStep("email");
          setTextContent({
            stepTitle: _l("l_setup_your_account"),
            stepperProgress: Math.trunc((2 / STEPS_LIST.length) * 100),
          });
          break;
        case "profilepic":
          setCurrentStep("password");
          setTextContent({
            stepTitle: _l("l_setup_your_account"),
            stepperProgress: Math.trunc((3 / STEPS_LIST.length) * 100),
          });
          break;
        case "categoryList":
          setCurrentStep("servicetype");
          break;
        case "companyname":
          setCurrentStep("categoryList");
          break;
        case "companyadress":
          setCurrentStep("companyname");
          break;
        case "vatfield":
          setCurrentStep("companyadress");
          break;
        case "companyprofilepic":
          if (formData.userType === 2) {
            setCurrentStep("vatfield");
          } else {
            setCurrentStep("companyadress");
          }
          break;
        case "allset":
          setCurrentStep("companyprofilepic");
          break;
      }
    }
  };

  const loginUserStep = (username, password) => {
    AuthService.login(username, password).then((data) => {
      let userData = data.data;
      setUserData(userData);
      setCurrentStep("servicetype");
      // setHideLeftPanel(false);
      if (userData.space_setup_pending == 1) {
        localStorage.setItem("space_setup_pending", 1);
      } else {
        localStorage.removeItem("space_setup_pending");
      }

      if (userData.ai_space_initialization_date) {
        localStorage.setItem(
          "ai_space_initialization_date",
          userData.ai_space_initialization_date
        );
      } else {
        localStorage.removeItem("ai_space_initialization_date");
      }
      if (data.status != 1) {
        return data;
      }
    });
  };

  const handleRegistration = async (formData, step) => {
    try {
      //   setLoadingNextStep(true);
      //   setNextButtonText("l_please_wait");
      if (step == 1) {
        setShowLoader(true);
        let phoneNumbers =
          formData.countryCode +
          " " +
          formData.phonenumber.slice(formData.countryCode.length - 1);
        const response = await authServices.userRegestartionNewFlow(
          formData.fullName,
          phoneNumbers,
          formData.email,
          formData.password,
          formData.image
        );
        if (response.status > 0) {
          loginUserStep(formData.email, formData.password);
          setShowLoader(false);
        } else {
          showError(_l(response.message));
          setCurrentStep("email");
          setShowLoader(false);
        }
      } else {
        setShowLoader(true);
        const response = await authServices.userRegestartionNewFlowStep2(
          userData.client_id,
          userData.contact_id,
          userData.staff_id,
          formData.companyname,
          formData.companyaddress,
          158,
          formData.userType,
          formData.vat,
          formData.companyprofilepic,
          selectedcategoryListing
        );
        if (response.status > 0) {
          setShowLoading(true)
          // dispatch(login(formData.email, formData.password));

          localStorage.setItem("space_setup_pending", 1);
          userLogin();
          setShowLoader(false);
        } else {
          showError(response.message);
          setShowLoader(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      //   setNextButtonText(nextButtonTxt);
      //   setLoadingNextStep(false);
    }
  };

  const userLogin = () => {
    localStorage.removeItem("guest_id");
    localStorage.setItem("is_registered", 1);
    dispatch(login(formData.email, formData.password)).then(async (res) => {
      const staff_id = localStorage.getItem("staff_id");

      await loadDataToIDB(staff_id);

      const activeSpace = localStorage.getItem("is_active_space");

      if (
        !localStorage.getItem("accessToken") ||
        (res && res.status && res.status != 1)
      ) {
        // swal("Oops!", "Invalid Credentials", "error");
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
      } else {
        // localStorage.setItem("password", passwordFieldRef.current.value);
        const client_id = localStorage.getItem("client_id");
        const scheduleId = sessionStorage.getItem("schedule_id");
        const projectId = sessionStorage.getItem("project_id");
        let user_type = localStorage.getItem("user_type");
        let contact_role = localStorage.getItem("contact_role");
        sessionStorage.removeItem("schedule_id");
        sessionStorage.removeItem("project_id");

        let homepage = "/aichat";

        if (user_type != "staff" && contact_role != 3) {
          homepage = "/dashboard";
          localStorage.setItem("showintro", true);
        } else {
          homepage = "/";
          localStorage.setItem("showintro", true);
        }

        if (
          CUSTOM_CLIENT_IDS.includes(client_id) &&
          CUSTOM_HOMEPAGE_URLS[client_id]
        ) {
          homepage = CUSTOM_HOMEPAGE_URLS[client_id];
        }
        if (scheduleId > 0) {
          localStorage.setItem("selectedOffice", projectId);
          navigate("/aichat");
          dispatch(setSelectedGloablView("AichatView"));
        }
        if (
          isNaN(activeSpace) ||
          Number(activeSpace) <= 0 ||
          localStorage.getItem("membership_package_id") == 1
        ) {
          navigate("/aichat");
          dispatch(setSelectedGloablView("AichatView"));
        } else {
          favoriteDdashboardSelected(localStorage.getItem("fav_dashboard"));
          navigate(homepage);
          // dispatch(setSelectedGloablView(favDashboard));
        }
      }
      dispatch(fetchAuthToken(formData.email));
      setShowLoading(false)
    });
  };

  const getCategories = async () => {
    try {
      let isDefault = 1;
      const response = await customerServices.getDefaultTopicList(isDefault);
      if (response.status) {
        let allCategories = response.data.map((w) => {
          return {
            label: w.name,
            value: w.id,
            client_id: w.client_id,
            is_default: w.is_default,
          };
        });
        allCategories.sort((a, b) => a.label.localeCompare(b.label));
        const defaultCategories = allCategories.filter(
          (w) => w.is_default == 1
        );
        const otherCategories = allCategories.filter(
          (w, index) =>
            allCategories.findIndex(
              (z, i) =>
                z.label &&
                w.label &&
                z.label.trim().toLowerCase() === w.label.trim().toLowerCase() &&
                i !== index
            ) === -1 && w.is_default == 0
        );
        setCategoryListing(defaultCategories.concat(otherCategories));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddNewCategory = async (label) => {
    if (
      formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
      formData.userSubscriptionPlan == 2
    ) {
    } else {
      setCategoryListing(
        [{ label, value: 0, checked: true }].concat(categoryListing)
      );
    }
  };

  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem("language", language);
  };
  
  const updateTextualContent = (stepName) => {
    let titleTxt = "";
    let descriptionTxt = "";

    switch (stepName) {
      case "fullname":
        titleTxt = "your_name";
        descriptionTxt = "l_user_type_description";
        break;
    }
    setTitleText(titleTxt);
    setDescription(descriptionTxt);
  };

  useEffect(() => {
    // if (userDataGlobal != undefined && userDataGlobal == user_id) {
    if (localStorage.getItem("accessToken")) {
      if (localStorage.getItem("membership_package_id") == 1) {
        // navigate("/premiumsubscription");
        navigate("/myprofile");
        dispatch(isPremuimEstimateDisable());
      } else {
        navigate("/");
        dispatch(setSelectedGloablView("liteVersionView"));
      }
    }
    document.body.classList.add("glow-shadow");
    // }

    if (hashID != null) {
      customerServices.getInviteUserDetails(hashID).then((res)=>{
        if (res && res.status) {        
            setFormData({
              ...formData,
              userType: 3,
              fullName:res.data ? res.data.firstname + " " + res.data.lastname : "",
              phonenumber :res.data ? res.data.phonenumber : "",
              email :res.data ? res.data.email : ""
            })
            getCategories();
        }
      })
    }
  }, []);

  useEffect(() => {
    document.body.classList.remove("with-left-penel");
    // document.body.classList.add('ps-0');
    return () => {
      document.body.classList.add("with-left-penel");
      // document.body.classList.remove('');
    };
  }, []);

  useEffect(() => {
    updateTextualContent(currentStep);
    if (currentStep == "allset") {
      setTimeout(() => {
        setShowLoading(true)
      }, 1000);
      setTimeout(() => {
        handleRegistration(formData, 2);
      }, 2000);
    }
  }, [currentStep]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/aichat`);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const selectedCategories = categoryListing.filter((w) => w.checked);

    setSelectedCategoryListing(selectedCategories);
  }, [JSON.stringify(categoryListing)]);

  useEffect(() => {
    if (numberFormate.dialCode) {
      setFormData({ ...formData, countryCode: `+${numberFormate.dialCode}` });
      const dialcodeL = Number(numberFormate.dialCode.length);
      let digitlength = numberFormate.format
        .match(/\./g)
        .slice(numberFormate.dialCode.length);
      if (["32", "352", "49", "44"].includes(numberFormate.dialCode)) {
        if (["49", "44"].includes(numberFormate.dialCode)) {
          setNumberLength({ max: 11 + dialcodeL, min: 10 + dialcodeL });
        } else {
          setNumberLength({ max: 10 + dialcodeL, min: 9 + dialcodeL });
        }
      } else {
        setNumberLength({
          max: digitlength.length + dialcodeL,
          min: digitlength.length + dialcodeL,
        });
      }
    }
  }, [numberFormate]);

  return (
    <>
     {showLoading ? (
        <div className="absoluteLoader ">
          <AcknowledgeScreen
            afterDismissAction={() => {}}
            delay={7000}
            steps={ACKNOWLEDGEMENT_STEPS[ formData.userType == 3 ?"agent_flow" : formData.userType == 2 ? "provider_flow" : "customer_flow"]}
            description={
              "l_feature_allows_experience_entirely_new_way_interacting"
            }
            title={"l_onboarding_acknowledgement_header_title"}
          />
        </div>
      ) : (
        <>
      <RegistrationFlowHeader
        profileImageURL={DEFAULT_PLACEHOLDER}
        hideLeftPanel={hideLeftPanel}
        setlanguage={setlanguage}
        language={language}
        setLanguage={setLanguage}
        languageSelect={languageSelect}
        pageName={"registrationPage"}
        nextBtnHandler={() => {
          handleStepChange(1);
        }}
        prevBtnHandler={() => {
          handleStepChange(-1);
        }}
        currentStep={currentStep}
        textContent={textContent}
        showLoader={showLoader}
      />
      <div className="d-flex flex-column h-100 RegNew">
        <div className="d-flex flex-column  h-100 regNewcontent mt-0 pb-0">
          {currentStep == "fullname" ? (
            // <RegistrationFlowMiddleCopmonent
            // formData={formData}
            // setFormData={setFormData}
            // currentStep={currentStep}
            // title={titleText}
            // description={description}

            // />
            // Profile name start //
                  <div class="d-flex flex-column  h-100 regNewcontent mt-0 pb-0 pt-0">
                     <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
                    <div className="col-lg-7 mx-auto row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-7 m-auto">
                        <div className="py-30px position-relative text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="px-3">
                              <div class="c-font f-20 fw-semibold title-fonts"> {_l("l_register_your_name_step_title")}</div>
                              <div class="c-font f-16 pt-15px sub-header text-center">
                                <span>{_l("l_register_your_name_step_description")}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                    </div>
                    <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                      <div className="d-none d-lg-block col-lg-2"></div>
                      <div className="align-items-center col-lg-7 d-flex mx-auto">
                        <Form.Group className={`c-input-box position-relative w-100`}>
                          <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_full_name")}</label>
                          <div className="inputWrapper d-flex align-items-center">
                            <div className="with_separator_10 pe-20pximp iconabsolute">
                              <UserCircle size={24} weight="light" className="c-icons mx-0" />
                            </div>
                            <Form.Control
                              placeholder={_l("l_full_name_placeholder")}
                              className="regFormInput text-center px4pxpy55px  w-100"
                              type="text"
                              autoFocus={true}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  fullName: e.target.value,
                                });
                              }}
                              onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                  handleStepChange(1);
                                }
                              }}
                              value={formData.fullName}
                            />

                            <div className="comman_action_icon absolutebtn">
                              <a onClick={() => handleStepChange(1)} className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none">
                                <CaretRight size={18} weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2"></div>
                    </div>
                    <div>
                      <div className="col-lg-7 mt-auto mx-auto row mb-20">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-7 m-auto">
                          <div className="border  g-0 p-10 radius10 ">
                            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                              {_l("l_register_your_name_step_sub_title")}
                            </div>
                            <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                              {_l("l_register_your_name_step_sub_description")}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    </div>
                  </div>
            
          ) : // Profile name end //
          currentStep == "phonenumber" ? (
            // Phone number start //
            <div className="d-flex flex-column  h-100 regNewcontent mt-0 pb-0 pt-0">
               <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
                <div className="col-lg-7 mx-auto row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-7 m-auto">
                        <div className="py-30px position-relative text-center">
                          <div className="d-flex align-items-center justify-content-center">
                          <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                            {_l("l_register_contact_number_step_title")}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                              {_l("l_register_contact_number_step_description")}
                            </span>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                      <div className="d-none d-lg-block col-lg-2"></div>
                      <div className="align-items-center col-lg-7 d-flex mx-auto">
                        <Form.Group className={`c-input-box position-relative w-100`}>
                          <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_phone_number")}</label>
                          <div className="inputWrapper d-flex align-items-center w-100 inputw100imp">
                            <div className="with_separator_10 pe-20pximp iconabsolute">
                              <Phone size={24} weight="light" className="c-icons mx-0" />
                            </div>
                            <PhoneInput
                              inputClass="title-fonts w100imp box-shadow-none border-0"
                              autoFormat={true}
                              disableSearchIcon={true}
                              enableSearch={true}
                              disableCountryGuess={true}
                              countryCodeEditable={false}
                              enableAreaCodes={true}
                              enableLongNumbers={true}
                              localization={""}
                              country="fr"
                              preferredCountries={[
                                "fr",
                                "de",
                                "be",
                                "es",
                                "ch",
                                "gb",
                                "pl",
                                "lu",
                              ]}
                              value={formData.phonenumber}
                              placeholder="780 1250 456"
                              isValid={(value, country) => {
                                if (value.length > numberlength.max) {
                                  setFormData({
                                    ...formData,
                                    phonenumber: value.slice(0, numberlength.max),
                                  });
                                } else {
                                  return true;
                                }
                              }}
                              onChange={(phone, formattedValue) => {
                                setFormData({ ...formData, phonenumber: phone });
                                setNumberFormate(formattedValue);
                              }}
                              onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                  handleStepChange(1);
                                }
                              }}
                              inputProps={{
                                autoFocus: true,
                              }}
                            />
                            <div className="comman_action_icon absolutebtn">
                              <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                <CaretRight onClick={() => handleStepChange(1)} size={18} weight="light" className="c-icons" />
                              </a>
                          </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2"></div>
                    </div>
                    <div>
                      <div className="col-lg-7 mt-auto mx-auto row mb-20">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-7 m-auto">
                          <div className="border  g-0 p-10 radius10 ">
                            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                            {_l("l_register_contact_number_step_sub_title")}
                            </div>
                            <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                            {_l(
                              "l_register_contact_number_step_sub_description"
                            )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
              </div>
            </div>
          ) : // Phone number end //
          currentStep == "email" ? (
            // email name start
            <div className="d-flex flex-column  h-100 regNewcontent mt-0 pb-0 pt-0">
               <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
              <div className="col-lg-7 mx-auto row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-7 m-auto">
                        <div className="py-30px position-relative text-center">
                          <div className="d-flex align-items-center justify-content-center">
                          <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                          {_l("l_register_your_email_required_step_title")}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                            {_l(
                                "l_register_your_email_required_step_description"
                              )}
                            </span>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                </div>
               
                <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                      <div className="d-none d-lg-block col-lg-2"></div>
                      <div className="align-items-center col-lg-7 d-flex mx-auto">
                        <Form.Group className={`c-input-box position-relative w-100`}>
                          <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_email")}</label>
                          <div className="inputWrapper d-flex align-items-center">
                            <div className="with_separator_10 pe-20pximp iconabsolute">
                              <EnvelopeSimple size={24} weight="light" className="c-icons mx-0" />
                            </div>
                            <Form.Control
                          placeholder="Email Address"
                          className="regFormInput text-center"
                          type="email"
                          autoFocus={true}
                          value={formData.email}
                          onChange={(e) => {
                            setFormData({ ...formData, email: e.target.value });
                          }}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              handleStepChange(1);
                            }
                          }}
                          disabled={hashID != null}
                          // onBlur={() => handleEmailCheck(formData.email)}
                        />

                            <div className="comman_action_icon absolutebtn">
                              <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                <CaretRight onClick={() => handleStepChange(1)} size={18} weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2"></div>
                    </div>
               
                <div>
                      <div className="col-lg-7 mt-auto mx-auto row mb-20">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-7 m-auto">
                          <div className="border  g-0 p-10 radius10 ">
                            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                            {_l(
                              "l_register_your_email_required_step_sub_title"
                            )}
                            </div>
                            <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                            {_l(
                              "l_register_your_email_required_step_sub_description"
                            )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
             
             </div>
            </div>
          ) : // email name end
          currentStep == "password" ? (
            // Password start
            <div className="d-flex flex-column  h-100 regNewcontent mt-0 pb-0 pt-0">
                <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
              <div className="col-lg-7 mx-auto row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-7 m-auto">
                        <div className="py-30px position-relative text-center">
                          <div className="d-flex align-items-center justify-content-center">
                          <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                          {_l("l_register_your_secret_code_step_title")}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                            {_l(
                                "l_register_your_secret_code_step_description"
                              )}
                            </span>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                </div>

                <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                      <div className="d-none d-lg-block col-lg-2"></div>
                      <div className="align-items-center col-lg-7 d-flex m-auto flex-column">
                        <Form.Group className={`c-input-box position-relative w-100`}>
                          <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_password")}</label>
                          <div className="inputWrapper d-flex align-items-center">
                            <div className="with_separator_10 pe-20pximp iconabsolute">
                              <Password size={24} weight="light" className="c-icons mx-0" />
                            </div>
                            <Form.Control
                          placeholder="Enter password"
                          className="regFormInput text-center"
                          type="password"
                          autoFocus={true}
                          value={formData.password}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              handleStepChange(1);
                            }
                          }}
                        />

                            <div className="comman_action_icon absolutebtn">
                              <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                <CaretRight onClick={() => handleStepChange(1)} size={18} weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </Form.Group>
                        <div class="d-flex pt-30px w-100">
                        <div class="pe-2">
                        <input
                          className="form-check-input light-theme-border"
                          type="checkbox"
                          value={termsAndCondition}
                          onChange={(e) => {
                            setTermsAndCondition(e.target.checked);
                          }}
                        />
                          </div>
                          {_l("l_i_accept_terms_and_conditions")}
                        <a
                          href="https://myr.ai/cgsc.html"
                          class="color-green ms-1 light-theme-color-green"
                          target="_blank"
                        >
                          CGSC
                        </a>
                        <a
                          href="https://myr.ai/cgsp.html"
                          class="color-green ms-1 light-theme-color-green"
                          target="_blank"
                        >
                          CGSP
                        </a>
                        <span class="mx-1">&amp;</span>
                        <a
                          href="https://myr.ai/privacy-policy.html"
                          class="color-green ms-1 light-theme-color-green"
                          target="_blank"
                        >
                          RGPD
                        </a>
                          </div>
                      </div>
                      <div className="col-lg-2"></div>
                    </div>

                    <div>
                      <div className="col-lg-7 mt-auto mx-auto row mb-20">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-7 m-auto">
                          <div className="border  g-0 p-10 radius10 ">
                            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                            {_l("l_strong_password")}
                            </div>
                            <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                            {_l(
                                "l_register_your_secret_code_step_sub_description"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
             
          
            </div>
           
            </div>
          ) : // Password  end
          currentStep == "profilepic" ? (
            // Profile picture start
            <div className="d-flex flex-column  h-100 regNewcontent mt-0 pb-0 pt-0">
              <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
                <div className="col-lg-7 mx-auto row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-7 m-auto">
                        <div className="py-30px position-relative text-center">
                          <div className="d-flex align-items-center justify-content-center">
                          <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                          {_l(
                              "l_register_let’s_add_ersonal_touch_step_title"
                            )}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                            {_l(
                                "l_register_let’s_add_ersonal_touch_description"
                              )}
                            </span>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                </div>
              
                <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                      <div className="d-none d-lg-block col-lg-2"></div>
                      <div className="align-items-center col-lg-7 d-flex mx-auto justify-content-center">
                      <Form.Group
                      className={`c-input-box position-relative text-center `}
                    >
                      <div className="form-control  file-upload with-bg position-relative comman-round-box d-flex flex-column p-0 file-upload-main base-modal-backdrop-bg">
                        <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                          <input
                            className="z-index-3 hidden-input m-auto"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.target.files[0];

                              if (file) {
                                // Check if the selected file is an image
                                if (file.type.startsWith("image/")) {
                                  // Create an image element to check dimensions
                                  const img = new Image();
                                  img.src = URL.createObjectURL(file);

                                  setUserProfileUrl(
                                    URL.createObjectURL(
                                      event.target.files[0]
                                    )
                                  );
                                  setFormData({
                                    ...formData,
                                    image: event.target.files,
                                  });

                                  // img.onload = () => {
                                  //   // Check if the image dimensions are 800x800
                                  //   if (
                                  //     img.width === 800 &&
                                  //     img.height === 800
                                  //   ) {
                                  //     // Set the selected file if it meets the criteria
                                  //     setUserProfileUrl(
                                  //       URL.createObjectURL(
                                  //         event.target.files[0]
                                  //       )
                                  //     );
                                  //     setFormData({
                                  //       ...formData,
                                  //       image: event.target.files,
                                  //     });
                                  //   } else {
                                  //     showError("l_image_validation");
                                  //   }
                                  // };
                                } else {
                                  showError(
                                    "Please select a valid image file."
                                  );
                                }
                              }
                            }}
                          />
                          <div
                            className="m-auto text-center comman-bg-img h-100 w-100 bg-style-cover d-flex flex-column rounded-circle position-relative"
                            style={{
                              backgroundImage: `url('${userProfileUrl}`,
                            }}
                          ></div>
                          <a href="#/" className="remove-img large abspotion">
                            <Pencil
                              size={14}
                              weight="light"
                              className="c-icons m-auto"
                            />
                          </a>
                        </div>
                        <div className="bg-base-header-color bottom-0 c end-0 h-100 position-absolute start-0 top-0 w-100 d-none">
                          <div
                            className="bg-style-cover comman-bg-img h-100 w-100"
                            style={{
                              backgroundImage: `url('${userProfileUrl}')`,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div class="c-font f-22 pt-15px sub-header text-center">
                        <span>{_l("l_Profile Picture")}</span>
                      </div>
                    </Form.Group>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="d-flex justify-content-center align-items-center mt-15px w-100">
                    <a
                      href="#/"
                      className="btn c-font f-14 fw-semibold title-fonts color-blue"
                      onClick={() => {
                        handleRegistration(formData, 1);
                      }}
                    >
                      {_l("l_skip")}
                    </a>
                    {/* <Button variant="primary" className="w-auto ms-3"  >{_l("l_submit")}</Button> */}
                  </div>
                    </div>
               
             

                <div>
                      <div className="col-lg-7 mt-auto mx-auto row mb-20">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-7 m-auto">
                          <div className="border  g-0 p-10 radius10 ">
                            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                            {_l(
                              "l_register_lets_add_ersonal_touch_step_sub_title"
                            )}
                            </div>
                            <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                        <div className="text-center">
                          <div className="c-font color-white-60 f-12 text-start">
                            <ul className="list-styl-disc">
                              <li>{_l("l_upload_your_picture")}</li>
                              <li>{_l("l_pic_change")}</li>
                            </ul>
                          </div>
                        
                      </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
              </div>
            </div>
          ) : // Profile picture end
          currentStep == "servicetype" ? (
            // create account option start
            <>
            <div className="onboarding-wrapper-main d-flex onboarding-with-bg  m-auto h-100 flex-column light-theme-white-bg">
                <div className="mx-auto row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-7 m-auto">
                    <div className="p-20 position-relative text-center onboarding-header">
                      <div className="d-flex align-items-center justify-content-center">
                        <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                            {_l(
                              "l_register_please_select_your_service_type_step_title"
                            )}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center py-10px">
                            <span>
                              {_l(
                                "l_register_please_select_your_service_type_description"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <RadioButtonArray
                  data={userTypes}
                  name={"user-type"}
                  onClick={(e) => {
                    setFormData({
                      ...formData,
                      userType: Number(e.target.value),
                    });
                    if (e.target.value == 2 || e.target.value == 3) {
                      getCategories();
                    }
                  }}
                  value={Number(formData.userType)}
                  hashID={hashID}
                />
                </div>
              </>
          ) : // create account option end
          currentStep == "categoryList" ? (
            <>
              <>
              <div className="onboarding-wrapper-main d-flex onboarding-with-bg  m-auto h-100 flex-column light-theme-white-bg">
                  
                 
                    <div className="m-auto">
                      <div className="onboarding-header p-20 position-relative pt-0 text-center pb-10px">
                        <div className="d-flex align-items-center justify-content-center">
                          <div class=" px-3">
                            <div class="c-font f-20 fw-semibold title-fonts">
                              {_l("l_service_categories")}
                            </div>
                            <div class="c-font f-16 pt-15px sub-header text-center">
                              <span>
                                {_l("l_service_categories_description")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                  <div className="px-3 w-100 overflow-scroll">
                   
                    <div className="m-auto">
                      <ListingWithCheckbox
                        list={categoryListing}
                        setList={setCategoryListing}
                        handleAddNewCategory={(label) =>
                          handleAddNewCategory(label)
                        }
                        isRegister={true}
                      />
                    </div>
                  </div>
                  </div>
                  <div className="col-lg-7 mt-auto mx-auto row mb-20 d-none">
                    
                    <div className=" m-auto">
                      <div className="border  g-0 p-10 radius10 ">
                        <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                          {_l("l_strong_password")}
                        </div>
                        <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                          <ul className="list-styl-disc">
                            <li>{_l("l_password_your_account")}</li>
                            <li>{_l("l_uppercase_lowercase_letters")}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
              </>
              {/* <div className="onboarding-wrapper-main m-auto h-100 d-flex flex-column with-modified-width onboarding-with-bg ">
                        
                         </div> */}
            </>
          ) : // category selection end
          currentStep == "companyname" ? (
            // Company plateform  start
            <div className="d-flex flex-column  h-100 regNewcontent mt-0 pb-0 pt-0">
              <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
              <div className="col-lg-7 mx-auto row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-7 m-auto">
                        <div className="py-30px position-relative text-center">
                          <div className="d-flex align-items-center justify-content-center">
                          <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                          {_l(
                              "l_register_introduce_your_company_the_platform_step_title"
                            )}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                            {_l(
                                "l_register_introduce_your_company_the_platform_description"
                              )}
                            </span>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                </div>
               
                {/* <div className="d-flex flex-column justify-content-center align-items-center h-100 flex-grow-1"> */}
                <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                      <div className="d-none d-lg-block col-lg-2"></div>
                      <div className="align-items-center col-lg-7 d-flex mx-auto">
                        <Form.Group className={`c-input-box position-relative w-100`}>
                          <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_company_name")}<span className="c-font f-14imp ms-1">*</span></label>
                          <div className="inputWrapper d-flex align-items-center">
                            <div className="with_separator_10 pe-20pximp iconabsolute">
                              <Buildings size={24} weight="light" className="c-icons mx-0" />
                            </div>
                            <Form.Control
                          placeholder="Company Name"
                          className="regFormInput text-center"
                          type="text"
                          autoFocus={true}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              companyname: e.target.value,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              handleStepChange(1);
                            }
                          }}
                          value={formData.companyname}
                        />

                            <div className="comman_action_icon absolutebtn">
                              <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                <CaretRight onClick={() => handleStepChange(1)} size={18} weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2"></div>
                    </div>
                <div>
                      <div className="col-lg-7 mt-auto mx-auto row mb-20">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-7 m-auto">
                          <div className="border  g-0 p-10 radius10 ">
                            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                            {_l(
                              "l_register_introduce_your_company_the_platform_step_sub_title"
                            )}
                            </div>
                            <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                            <div className="text-center">
                          <div className="c-font color-white-60 f-12 text-start">
                            {_l(
                              "l_register_introduce_your_company_the_platform_step_sub_description"
                            )}
                          </div>
                        </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
               
                {/* </div> */}
              </div>
            </div>
          ) : // Company plateform  end
          currentStep == "companyadress" ? (
            // Map Address start
            <>
            <div className="onboarding-wrapper-main d-flex onboarding-with-bg  m-auto h-100 flex-column light-theme-white-bg">
               
                  <div className=" m-auto">
                    <div className="p-20 pt-0 position-relative text-center">
                      <div className="d-flex align-items-center justify-content-center onboarding-header">
                        <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                            {_l(
                              "l_register_please_select_your_service_type_step_title"
                            )}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                              {_l(
                                "l_register_please_select_your_service_type_description"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
              <div className="px-3 flex-grow-1">
                <div className="d-flex flex-column justify-content-center align-items-center h-100 flex-grow-1 pt-40px overflow-hidden">
                  <div className="onboarding-map hide-magnify flex-grow-1 d-flex flex-column position-relative w-100">
                    <div className="map-shadow"></div>
                    <div className="end-0 position-absolute p-4 pt-5 start-0 top-0 w-100 z-index-2 container d-none">
                      <div className="row justify-content-center">
                        <div className="col-lg-7 c-input-box position-relative">
                          <input
                            className="form-control onboarding-map-input border"
                            placeholder={_l("l_address_placeholder")}
                            type="text"
                            autoFocus={true}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                companyaddress: e.target.value,
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                handleStepChange(1);
                              }
                            }}
                            value={formData.companyaddress}
                          />
                        </div>
                      </div>
                    </div>
                    <Address
                      coOrdinates={coOrdinates}
                      regionList={regionList}
                      setCoOrdinates={setCoOrdinates}
                      setRegionList={setRegionList}
                      setSpaceDetail={setSpaceDetail}
                      spaceDetail={spaceDetail}
                      isRegister={true}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </div>
                </div>
              </div>
              </div>
            </>
          ) : // Map Address end
          currentStep == "vatfield" ? (
            // Vat field  start
            <>
                <div className="col-lg-7 mx-auto row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-7 m-auto">
                    <div className="py-30px position-relative text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                            {_l("l_register_set_your_vat_rate_step_title")}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                              {_l(
                                "l_register_set_your_vat_rate_step_description"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                  <div className="d-none d-lg-block col-lg-2"></div>
                  <div className="align-items-center col-lg-7 d-flex mx-auto">
                    <Form.Group
                      className={`c-input-box position-relative w-100`}
                    >
                      <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_vat_rate")}</label>
                      <div className="inputWrapper d-flex align-items-center">
                        <div className="with_separator_10 pe-20pximp iconabsolute">
                          <Percent size={24} weight="light" className="c-icons mx-0" />
                        </div>
                        {/* <span className="inputBeforeBox"></span> */}
                        <Form.Control
                          placeholder="VAT Rate"
                          className="regFormInput text-center"
                          type="number"
                          autoFocus={true}
                          onChange={(e) => {
                            setFormData({ ...formData, vat: e.target.value });
                          }}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              handleStepChange(1);
                            }
                          }}
                          value={formData.vat}
                        />
                        <div className="comman_action_icon absolutebtn">
                          <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                            <CaretRight onClick={() => handleStepChange(1)} size={18} weight="light" className="c-icons" />
                          </a>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-7 mt-auto mx-auto row mb-20">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-7 m-auto">
                    <div className="border  g-0 p-10 radius10 ">
                      <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                        {_l("l_add_mobile_number")}
                      </div>
                      <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                        <ul className="list-styl-disc">
                          <li>{_l("l_business_profile")}</li>
                          <li>{_l("l_vat_enter")}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
            </>
          ) : // Vat field  end
          currentStep == "companyprofilepic" ? (
            // Company picture start
            <div className="d-flex flex-column  h-100 regNewcontent mt-0 pb-0 pt-0">
              <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
              <div className="col-lg-7 mx-auto row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-7 m-auto">
                        <div className="py-30px position-relative text-center">
                          <div className="d-flex align-items-center justify-content-center">
                          <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                          {_l(
                              "l_register_let’s_add_ersonal_touch_step_title"
                            )}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>
                            {_l(
                                "l_register_let’s_add_ersonal_touch_description"
                              )}
                            </span>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                </div>
               
                {/* <div className="d-flex flex-column justify-content-center align-items-center h-100 flex-grow-1"> */}
                <div className="col-lg-7 flex-grow-1 mx-auto row mb-70">
                      <div className="d-none d-lg-block col-lg-2"></div>
                      <div className="align-items-center col-lg-7 d-flex mx-auto justify-content-center flex-column">
                      <Form.Group
                      className={`c-input-box position-relative text-center `}
                    >
                      <div className="form-control  file-upload with-bg position-relative comman-round-box d-flex flex-column p-0 file-upload-main base-modal-backdrop-bg">
                        <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                          <input
                            className="z-index-3 hidden-input m-auto"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file) {
                                // Check if the selected file is an image
                                if (file.type.startsWith("image/")) {
                                  // Create an image element to check dimensions
                                  const img = new Image();
                                  img.src = URL.createObjectURL(file);

                                  setCompanyProfileUrl(
                                    URL.createObjectURL(
                                      event.target.files[0]
                                    )
                                  );
                                  setFormData({
                                    ...formData,
                                    companyprofilepic: event.target.files,
                                  });

                                  // img.onload = () => {
                                  //   // Check if the image dimensions are 800x800
                                  //   if (
                                  //     img.width === 800 &&
                                  //     img.height === 800
                                  //   ) {
                                  //     // Set the selected file if it meets the criteria
                                  //     setCompanyProfileUrl(
                                  //       URL.createObjectURL(
                                  //         event.target.files[0]
                                  //       )
                                  //     );
                                  //     setFormData({
                                  //       ...formData,
                                  //       companyprofilepic: event.target.files,
                                  //     });
                                  //   } else {
                                  //     showError("l_image_validation");
                                  //   }
                                  // };
                                } else {
                                  showError(
                                    "Please select a valid image file."
                                  );
                                }
                              }
                            }}
                          />
                          {companyProfileUrl != "" ?
                          <div
                            className="m-auto text-center comman-bg-img h-100 w-100 bg-style-cover d-flex flex-column rounded-circle"
                            style={{
                              backgroundImage: `url('${companyProfileUrl}')`,
                            }}
                          ></div>
                          : <Buildings size={16} weight="light" className="c-icons h130w130" />
                            }
                        </div>
                        <div className="bg-base-header-color bottom-0 c end-0 h-100 position-absolute start-0 top-0 w-100 d-none">
                          <div
                            className="bg-style-cover comman-bg-img h-100 w-100"
                            style={{
                              backgroundImage: `url('${companyProfileUrl}')`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </Form.Group>
                    <div class="c-font f-22 pt-15px sub-header text-center">
                      <span>{_l("l_profile_company_picture")}</span>
                    </div>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="d-flex justify-content-center align-items-center mt40px w-100">
                    <a
                      href="#/"
                      className="btn c-font f-14 fw-semibold title-fonts color-blue"
                      onClick={() => {
                        setCurrentStep("allset");
                      }}
                    >
                      {_l("l_skip")}
                    </a>
                    {/* <Button variant="primary" className="w-auto ms-3"  >{_l("l_submit")}</Button> */}
                  </div>
                    </div>
               
                <div>
                      <div className="col-lg-7 mt-auto mx-auto row mb-20">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-7 m-auto">
                          <div className="border  g-0 p-10 radius10 ">
                            <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                            {_l(
                              "l_register_lets_add_ersonal_touch_step_sub_title"
                            )}
                            </div>
                            <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                            <div className="text-center">
                          <div className="c-font color-white-60 f-12 text-start">
                            <ul className="list-styl-disc">
                              <li>{_l("l_upload_your_picture")}</li>
                              <li>{_l("l_pic_change")}</li>
                            </ul>
                          </div>
                        </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
               
                {/* </div> */}
              </div>
            </div>
          ) : // Company picture end
          currentStep == "allset" ? (
            // all set start
            <div className="container h-100">
              <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-7 m-auto">
                    <div className="onboarding-header p-20px position-relative text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <div class=" px-3">
                          <div class="c-font f-20 fw-semibold title-fonts">
                            {_l("l_just_moment")}
                          </div>
                          <div class="c-font f-16 pt-15px sub-header text-center">
                            <span>{_l("l_unique_offerings")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                {/* <div className="d-flex flex-column justify-content-center align-items-center h-100 flex-grow-1"> */}
                <div className="row w-100">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-6 m-auto text-center title-fonts">
                    <div className="text-center mb-10">
                      <CelebrationIcon />
                    </div>
                    <div className="c-font color-white f-22 form-label fw-semibold mb-0 pb-20px ">
                      {_l("l_congratulations")}
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div></div>
                {/* </div> */}
              </div>
            </div>
          ) : (
            // all set end
            <></>
          )}
        </div>
      </div>
      </>
      )}
    </>
  );
};

export default RegistrationFlowNew;

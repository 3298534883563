import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _l, fetchEquipmentListFromIDB, insertEquipmentListData, showError, showLeftPanelNew } from "../hooks/utilities";
import EquipmentTable from "../components/Providertables/EquipmentTable";
import customerServices from "../services/customer-services";
import equipmentsServices from "../services/equipments-services";
import { showConfirmation, showMessage, toastCloseAction } from "../actions/messages";
import { setLeftPanelTab } from "../actions/customer";

const Equipment = () => {
    const {commanoffcanvasIsOpen} = useSelector((state) => state.customer)
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    const [projectId, setProjectId] = useState(
      spaces && spaces.length ? spaces[0].project_id : 0
    );
    const [equipmentData, setEquipmentData] = useState({})

    const dispatch = useDispatch();

    useEffect(() => {
      if (commanoffcanvasIsOpen) {
        
      } else {
        showLeftPanelNew(); 
      }
    })
    useEffect(() => {
     try {
     fetchEquipmentListFromIDB().then((w) => setEquipmentData({data : w}));
     
      customerServices.getEquipmentList().then((res) => {
        if (res && (res.status || res.data.length > 0)) {
          insertEquipmentListData(res.data)
          setEquipmentData(res)
        }else{
          showError(res.message)
        }
      })
     } catch (error) {
      
     }
     dispatch(setLeftPanelTab("equipments"))
    }, [])
    
  const editEquipment = (equipment) => {
    if (equipment.id > 0) {
      window.open(
        `/equipments?space=${equipment.project_id}&equipment=${equipment.id}`,
        "_blank"
      );
    } else if (equipment.equipment_id > 0 && equipment.id) {
      window.open(
        `/equipments?space=${equipment.project_id}&equipment=${equipment.equipment_id}&item=${equipment.id}`,
        "_blank"
      );
    }
  };

  const deleteEquipment = (item) => {
    dispatch(
        showConfirmation(
        _l("l_delete"),
        _l("l_delete_equipment_confirmation"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
            dispatch(toastCloseAction());
            try {
                const response = await equipmentsServices.deleteEquipment(
                item.id,
                item.project_id
                );
                if (response.status) {
                  setEquipmentData({...equipmentData, data : equipmentData.data.filter((equip) => equip.id != item.id) })
                  dispatch(showMessage("sucess", _l("l_success"), _l("l_equipment_remove")));
                } else {
                showError(response.message);
                }
            } catch (e) {
            console.error(e);
            }
        }
        )
    );
  };

    return (
      <>
          <div className="h-100 comman-white-box-shadow light-theme-white-bg mt-10px ms-10px">
            <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
              <EquipmentTable isFrom = {"equipmentTab"} data = {equipmentData} editEquipment = {editEquipment} deleteEquipment={deleteEquipment}/>
            </div>
          </div>
      </>
    );
  };

export default Equipment
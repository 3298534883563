import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { _l, showError, showSuccess } from "../../hooks/utilities";
import { MagnifyingGlass, Pencil, UserCircle, Wrench, X } from "phosphor-react";
import Select from "react-select";
 
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import vapiService from "../../services/vapi-service";
import { getCustomerSpaces, getDefaultTopics } from "../../actions/customer";
import { COUNTRY_JSON } from "../../constants/constants";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const CreateAiAssistant = ({
  show = true,
  handleClose = () => {},
  getPeopleList = () => {},
  assistantData = {},
}) => {
  const { defaultTopics, taskFilters } = useSelector((state) => state.customer);
  let selectedLanguage = localStorage.getItem("language");
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState({ url: "" });
  const [showSearch, setShowSearch] = useState({
    providerSearch: false,
    tenetsSearch: false,
    equipmentsearch: false,
  });
  const [searchString, setsearchString] = useState({
    providerSearchString: "",
    tenetsSearchString: "",
    equipmentsearchString: "",
  });
  const [spaceOPtions, setSpaceOPtions] = useState([]);
  const [assistantProfileData, setAssistantProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    countryCode: "+33",
    gender: "m",
    language: selectedLanguage == "english"  ? 12 : 21,
    voice: "",
    spaces: [],
    firstMessage: "",
    generalContext: "",
    selectedProviders: [
      {
        main_key: "providers",
        categories: [],
      },
    ],
    selectedTenets: [
      {
        main_key: "my_contact",
        categories: [],
      },
    ],
    selectedEquipments: [
      {
        main_key: "equipments",
        categories: [],
      },
    ],
    profilePicture: "",
    topics: [],
    mobile_key: "",
  });
  const [numberFormate, setNumberFormate] = useState("");
  const [topics, setTopics] = useState({
    providers: [],
    my_contact: [],
    equipments: [],
  });
  const [temptopics, setTempTopics] = useState({
    providers: [],
    my_contact: [],
    equipments: [],
  });
  const [languageOptions, setLanguageOptions] = useState(
   [
    {
      label : "English",
      value : 12
    },
      {
      label : "French",
      value : 21
    }
  ]);
  const [countryCodes, setCountryCodes] = useState(COUNTRY_JSON);
  const [countryCodesWithFlag, setCountryCodesWithFlag] = useState(COUNTRY_JSON);
  const [selectedCountry, setselectedCountry] = useState({
    label: "🇺🇸 United States +1",
    value: "+1",
  });

  const [mobileOptions, setMobileOptions] = useState([]);
  const [mobileNumberArray, setMobileNumberArray] = useState([]);
  const [currentTab, setCurrentTab] = useState("tab1");
  const charLimit = 3000;
  const remainingChars =
    charLimit - assistantProfileData.generalContext.trim().length;

  const genderOption = [
    { value: "m", label: "Male" },
    { value: "f", label: "Female" },
  ];

  const handleChangeData = (value, key, value2, key2) => {
    if (value2 && key2) {
      setAssistantProfileData({
        ...assistantProfileData,
        [key]: value,
        [key2]: value2,
      });
    } else {
      setAssistantProfileData({ ...assistantProfileData, [key]: value });
    }
  };

  const handleSelectionBox = (value, key, isMulti) => {
    if (isMulti) {
      let arr = [];
      value.map((d) => {
        arr.push(d.value);
      });
      handleChangeData(arr, key);
    } else {
      handleChangeData(value.value, key);
    }
  };

  const handleSwitchAction = (value, key, mKey) => {
    let ids = assistantProfileData[key][0].categories;

    if (ids.includes(value)) {
      ids = ids.filter((id) => id != value);
    } else {
      ids.push(value);
    }
    let finalArr = [
      {
        main_key: mKey,
        categories: ids,
      },
    ];
    handleChangeData(finalArr, key);
  };

  const handleSearch = (value, key) => {
    if (key == "provider") {
      if (value && value.trim() != "") {
        setTopics({
          ...topics,
          providers: topics.providers.filter((cat) =>
            cat.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
          ),
        });
      } else {
        setTopics({ ...topics, providers: temptopics.providers.slice() });
        return;
      }
    }

    if (key == "equipment") {
      if (value && value.trim() != "") {
        setTopics({
          ...topics,
          equipments: topics.equipments.filter((cat) =>
            cat.title
              .toLocaleLowerCase()
              .includes(searchString.equipmentsearchString.toLocaleLowerCase())
          ),
        });
      } else {
        setTopics({ ...topics, equipments: temptopics.equipments.slice() });
        return;
      }
    }

    if (key == "tenets") {
      if (value && value.trim() != "") {
        setTopics({
          ...topics,
          my_contact: topics.my_contact.filter((cat) =>
            cat.title
              .toLocaleLowerCase()
              .includes(searchString.tenetsSearchString.toLocaleLowerCase())
          ),
        });
      } else {
        setTopics({ ...topics, my_contact: temptopics.my_contact.slice() });
        return;
      }
    }
  };

  const isValidData = () => {
    if (currentTab == "tab1") {
      if (assistantProfileData.firstName.trim() == "") {
        showError("l_please_enter_first_name");
        return false;
      } else if (assistantProfileData.lastName.trim() == "") {
        showError("l_please_enter_last_name");
        return false;
      } else if (assistantProfileData.email.trim() == "") {
        showError("l_please_enter_email");

        return false;
      } else if (!emailRegex.test(assistantProfileData.email)) {
        showError("l_please_enter_valid_email");
        return false;
      } else if (assistantProfileData.phoneNumber.trim() == "") {
        showError("l_please_enter_valid_phone_number_with_country_code");
        return false;
      } else if (assistantProfileData.language == "") {
        showError("l_language_error");

        return false;
      }
    }
    if (currentTab == "tab2") {
      if (assistantProfileData.firstMessage.trim() == "") {
        showError("l_first_message_error");

        return false;
      } else if (assistantProfileData.generalContext.trim() == "") {
        showError("l_general_context_error");

        return false;
      }
      else if (
        assistantProfileData.selectedProviders.length == 0 &&
        assistantProfileData.selectedTenets.length == 0 &&
        assistantProfileData.selectedTenets.length == 0
      ) {
        showError("l_select_topic");

        return false;
      }
    }
    return true;
  };

  const handleSave = async () => {
    setLoader(true);
    try {
      let response = await vapiService.createAssistant(assistantProfileData);

      if (response.status) {
        showSuccess("l_assistant_created");
        dispatch(
          getDefaultTopics(
            localStorage.getItem("selectedOffice"),
            "",
            "",
            "Dashboard",
            taskFilters
          )
        );
        getPeopleList();
        handleClose();
       dispatch(getCustomerSpaces(localStorage.getItem("client_id"), localStorage.getItem("contact_id")));

      } else {
        showError(response.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("!!! Error savingAssistant !!!", error);
    }
  };

  const countryCodesPrefix = {
    '+1': 'US', // United States
    '+44': 'GB', // United Kingdom
    '+33': 'FR', // France
    '+49': 'DE', // Germany
    '+91': 'IN', // India
};

function getCountryCode(mobileNumber) {
    // Normalize the input by removing spaces and non-numeric characters except '+'
    const cleanedNumber = mobileNumber.replace(/\D/g, '');

    // Check if the number starts with '+'
    if (cleanedNumber.startsWith('0')) {
        return 'Invalid number format';
    }

    // Extract the country code by checking against known prefixes
    for (const [code, country] of Object.entries(countryCodesPrefix)) {
        if (cleanedNumber.startsWith(code.substring(1))) {
            return code;
        }
    }

    return 'Country code not found';
}
  useEffect(() => {
    let spaceList = localStorage.getItem("spaces")
      ? JSON.parse(localStorage.getItem("spaces"))
      : [];
    let spaceArr = spaceList.map((space) => {
      return {
        label: space.office_title || space.postal_address,
        value: space.project_id,
      };
    });
    setSpaceOPtions(
      [
        {
          label: "All spaces",
          value: "all",
        },
      ].concat(spaceArr)
    );
  }, []);

  useEffect(() => {
    if (numberFormate.dialCode) {
      setAssistantProfileData({
        ...assistantProfileData,
        countryCode: `+${numberFormate.dialCode}`,
      });
    }
  }, [numberFormate]);

  useEffect(() => {
    if (defaultTopics) {
      let tempTopic = {};
      defaultTopics.forEach((topic) => {
        if (
          ["equipments", "providers", "my_contact"].includes(topic.main_key)
        ) {
          tempTopic = { ...tempTopic, [topic.main_key]: topic.categories };
        }
      });
      setTopics(tempTopic);
      setTempTopics(tempTopic);
    }
  }, [defaultTopics]);

  useEffect(() => {}, [searchString, topics]);

  useEffect(() => {

    vapiService.getAssistantMobileNumbers().then((res) => {
      if (res && res.status && res.data) {
        let arr = res.data
        .map((v) => {
          return {
            label: v.number,
            value: v.number,
          };
        });
        setMobileNumberArray(res.data
      );
        setMobileOptions(arr);
      } else {
      }
    });

  }, []);

  useEffect(() => {
    if (Object.keys(assistantData).length > 0) {
      setUserProfileImage({ url: assistantData.profile_image });
      setAssistantProfileData({
        ...assistantProfileData,
        firstName: assistantData.firstname,
        lastName: assistantData.lastname,
        email: assistantData.email,
        phoneNumber: assistantData.phone_number,
        gender: assistantData.gender,
        language: assistantData.language_id,
        voice: assistantData.ai_voice,
        spaces: assistantData.project_ids,
        firstMessage: assistantData.first_message,
        generalContext: assistantData.system_prompt,
        profilePicture: { url: assistantData.profile_image },
        topics: assistantData.topic_setting,
      });
    }
  }, [assistantData]);

  return (
    <>
      <Modal
        show={show}
        size={"xl"}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-modal-style overflowhidden"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Ai assistant"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            {currentTab == "tab1" ? (
              <>
                <div className="col-lg-12 d-flex justify-contnet-center">
                  <div
                    className="form-control  file-upload with-bg position-relative comman-round-box d-flex flex-column p-0 file-upload-main"
                  >
                    <a href="#/" className="remove-img large abspotion">
                      <Pencil
                        size={14}
                        weight="light"
                        className="c-icons m-auto"
                      />
                    </a>
                    <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                      <input
                        className="z-index-3 hidden-input m-auto"
                        type="file"
                        accept="image/*"
                        id="user_profile_image"
                        onChange={(e) => {
                          if (e.target.files.length == 1) {
                            handleChangeData(e.target.files, "profilePicture");
                            Array.from(e.target.files).map((img) => {
                              setUserProfileImage({
                                name: img.name,
                                url: URL.createObjectURL(img),
                              });
                            });
                          } else {
                          }
                        }}
                      />
                      <div
                        className="m-auto text-center comman-bg-img h-100 w-100 bg-style-cover d-flex flex-column rounded-circle"
                        style={{
                          backgroundImage: `url(${userProfileImage.url})`,
                        }}
                      >
                        <div className="m-auto rounded-circle">
                          {userProfileImage.url !== "" ? (
                            <></>
                          ) : (
                            <>
                              <div>
                                <UserCircle
                                  size={65}
                                  weight="light"
                                  className="c-icons m-0 mb-2 opacity-60 "
                                />
                              </div>
                              <div className="c-font color-white f-14 fw-bold title-fonts">
                                {_l("l_profile")} {_l("l_image")}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                  <div className="row">
                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative pe-3">
                      <Form.Label className="input-label no-asterisk">
                        {_l("l_first_name")}
                      </Form.Label>
                      <Form.Control
                        placeholder={`${_l("l_first_name")}`}
                        type="text"
                        name=""
                        value={assistantProfileData.firstName}
                        onChange={(e) => {
                          handleChangeData(e.target.value, "firstName");
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative ">
                      <Form.Label className="input-label no-asterisk">
                        {_l("l_last_name")}
                      </Form.Label>
                      <Form.Control
                        placeholder={`${_l("l_last_name")}`}
                        type="text"
                        name=""
                        value={assistantProfileData.lastName}
                        onChange={(e) => {
                          handleChangeData(e.target.value, "lastName");
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative pe-3">
                      <Form.Label className="input-label no-asterisk">
                        {_l("l_email")}
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          className="w-60"
                          placeholder={`${_l("l_email_placeholder")}`}
                          type="text"
                          name=""
                          value={assistantProfileData.email.split("@")[0]}
                          onChange={(e) => {
                            handleChangeData(
                              e.target.value + "@mg.myr.ai",
                              "email"
                            );
                          }}
                        />
                        <div className="w-40">
                          <span className="form-control for-disabled color-white-60">
                            @mg.myr.ai
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative z-5">
                      <Form.Label className="input-label  no-asterisk">
                        {_l("l_mobile_number")}
                      </Form.Label>
                      <div className="d-flex align-items-center">
                        <Select
                          className="custom-select-menu menulistabs w90px"
                          classNamePrefix="react-select"
                          placeholder={"🇺🇸"}
                          closeMenuOnSelect={true}
                          options={countryCodes.sort((a, b) =>
                            a.label
                              .toLowerCase()
                              .localeCompare(b.label.toLowerCase())
                          )}
                          isSearchable={true}
                          value={countryCodesWithFlag.find(
                            (countryCode) =>
                              countryCode.value.startsWith(getCountryCode(assistantProfileData.phoneNumber))
                          )}
                          isDisabled={true}
                          onChange={(value) => {
                            setselectedCountry(value);
                            handleSelectionBox(value, "countryCode", false);
                            handleChangeData("", "phoneNumber", false);
                          }}
                        />
                        <Select
                          className="custom-select-menu w-100 border-start"
                          classNamePrefix="react-select"
                          placeholder={_l("l_select_phonenumber")}
                          closeMenuOnSelect={true}
                          options={mobileOptions.filter((v) =>
                            v.value.startsWith(selectedCountry.value) &&  v.value != assistantProfileData.phoneNumber
                          )}
                          
                          value={
                            assistantProfileData.phoneNumber &&
                            assistantProfileData.phoneNumber != ""
                              ? mobileNumberArray.find(
                                  (value) =>
                                    value.value ==
                                    assistantProfileData.phoneNumber
                                )
                              : ""
                          }
                          onChange={(value) => {
                            handleSelectionBox(value, "phoneNumber", false);
                            handleChangeData(
                              value.value,
                              "phoneNumber",
                              mobileNumberArray.filter(
                                (n) => n.number ? n.number == value.value : n.value == value.value
                              )[0].id,
                              "mobile_key"
                            );
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative z-3">
                      <Form.Label className="input-label">
                        {_l("l_voice_selection")}
                      </Form.Label>
                      <div className="d-flex flex-grow-1 align-items-center">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder={_l("l_voice_placeholder")}
                          closeMenuOnSelect={true}
                          options={genderOption}
                          value={genderOption.find(
                            (value) =>
                              value.value == assistantProfileData.gender
                          )}
                          onChange={(value) => {
                            handleSelectionBox(value, "gender", false);
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative z-0">
                      <Form.Label className="input-label">
                        {_l("l_language_selection")}
                      </Form.Label>
                      <div className="d-flex flex-grow-1 align-items-center">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder={_l("l_language_placeholder")}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={true}
                          options={languageOptions.filter((l) => l.value != assistantProfileData.language)}
                          value={languageOptions.find(
                            (l) => l.value == assistantProfileData.language
                          )}
                          onChange={(value) => {
                            handleSelectionBox(value, "language", false);
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="d-none col-xl-6 c-input-box pb-3 position-relative">
                      <Form.Label className="input-label">
                        {_l("l_space_selection")}
                      </Form.Label>
                      <div className="d-flex flex-grow-1 align-items-center">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder={_l("l_select_space_selection")}
                          closeMenuOnSelect={true}
                          options={
                            assistantProfileData.spaces &&
                            assistantProfileData.spaces.includes("all")
                              ? []
                              : spaceOPtions
                          }
                          isMulti={true}
                          value={
                            assistantProfileData.spaces &&
                            assistantProfileData.spaces.includes("all")
                              ? [
                                  {
                                    label: "All spaces",
                                    value: "all",
                                  },
                                ]
                              : spaceOPtions.filter(
                                  (s) =>
                                    assistantProfileData.spaces &&
                                    assistantProfileData.spaces.includes(
                                      s.value
                                    )
                                )
                          }
                          onChange={(value) => {
                            if (value.length > 0 && value[0].value == "all") {
                                let spaceId = []
                                spaceOPtions.forEach((s) => {
                                  spaceId.push(s.value)
                                });
                                handleChangeData(spaceId, "spaces", true);
                            }else if(value.length == 0){
                              handleChangeData([], "spaces", true);
                            } else {
                              handleSelectionBox(value, "spaces", true);
                            }
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                
              </>
            ) : (
              <>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center ">
                    <Form.Label className="input-label no-asterisk">
                      {_l("l_first_message")}
                    </Form.Label>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name="first_message"
                    value={assistantProfileData.firstMessage}
                    onChange={(e) => {
                      handleChangeData(e.target.value, "firstMessage");
                    }}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_general_context")}
                    </Form.Label>
                    <Form.Label
                      className={`input-label no-asterisk ${
                        remainingChars <= 0 ? "text-warning" : ""
                      }`}
                    >
                      {String(remainingChars) + "/" + String(charLimit)}
                    </Form.Label>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name="general_context"
                    value={assistantProfileData.generalContext}
                    onChange={(e) => {
                      if (e.target.value.slice(0, 3000).length <= charLimit) {
                        handleChangeData(
                          e.target.value.slice(0, 3000),
                          "generalContext"
                        );
                      }
                    }}
                    onBlur={() => {
                      handleChangeData(
                        assistantProfileData.generalContext.trim(),
                        "generalContext"
                      );
                    }}
                  ></Form.Control>
                </Form.Group>

                <div className="border-top col-12 mb-20 mt-10"></div>
                {/* ----------------- Providers Start ------------------ */}
                <Form.Group className="col-lg-4  c-input-box  position-relative">
                  <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
                    <div>{`${_l("l_providers")}`}</div>
                    <div className="d-flex align-items-center gap10 comman_action_icon">
                      {/* search input */}

                      {showSearch.providerSearch ? (
                        <div class="searchToggle position-relative">
                          <div class="comment-input-box togglesearchfield">
                            <input
                              type="text"
                              class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                              placeholder="Search"
                              value={searchString.providerSearchString}
                              onChange={(e) => {
                                setsearchString({
                                  ...searchString,
                                  providerSearchString: e.target.value,
                                });
                                handleSearch(e.target.value, "provider");
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {showSearch.providerSearch ? (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              providerSearch: false,
                            });
                            handleSearch("", "provider");
                            setsearchString({
                              ...searchString,
                              providerSearchString: "",
                            });
                          }}
                        >
                          <X size={16} weight="light" className="c-icons" />
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              providerSearch: true,
                            });
                          }}
                        >
                          <MagnifyingGlass
                            size={16}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                      )}
                    </div>
                  </Form.Label>
                  <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                    {topics && topics.providers && topics.providers.length ? (
                      topics.providers.map((provider, index) => (
                        <>
                          <li
                            className="comman-list  with-after-50 p-0"
                            key={index}
                          >
                            <a
                              href="#/"
                              className="w-100 position-relative tab_link  left-chat "
                            >
                              <div className="d-flex align-items-center">
                                <div className="c-list-icon">
                                  <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                    <Wrench
                                      size={16}
                                      weight="light"
                                      className="c-icons h25w25"
                                    />
                                  </div>
                                </div>
                                <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                  <div className="top-part pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="chat-u-name text-truncate">
                                        {provider.title}
                                      </div>
                                      <div className="d-flex align-items-cente">
                                        <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                          {assistantProfileData.selectedProviders.includes(
                                            provider.filter_key
                                          ) ? (
                                            <></>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="form-check form-check-inline m-0 ps-2">
                                            <Form.Check
                                              className="with-no-label-space"
                                              type="switch"
                                              role="switch"
                                              checked={assistantProfileData.selectedProviders.some(
                                                (item) =>
                                                  item.categories.includes(
                                                    provider.filter_key
                                                  )
                                              )}
                                              onChange={() => {
                                                handleSwitchAction(
                                                  provider.filter_key,
                                                  "selectedProviders",
                                                  "providers"
                                                );
                                              }}
                                            />
                                            <label className="form-check-label p-0"></label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                             
                          </li>
                        </>
                      ))
                    ) : (
                      <>
                        <CommanPlaceholder placeholderText={_l("l_no_data")} />
                      </>
                    )}
                  </ul>
                </Form.Group>

                {/* ----------------- Providers end ------------------ */}

                {/* ----------------- Tenets Start ------------------ */}

                <Form.Group className="col-lg-4  c-input-box  position-relative">
                  <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
                    <div>{`${_l("l_tenets")}`}</div>
                    <div className="d-flex align-items-center gap10 comman_action_icon">
                      {/* search input */}

                      {showSearch.tenetsSearch ? (
                        <div class="searchToggle position-relative">
                          <div class="comment-input-box togglesearchfield">
                            <input
                              type="text"
                              class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                              placeholder="Search"
                              value={searchString.tenetsSearchString}
                              onChange={(e) => {
                                setsearchString({
                                  ...searchString,
                                  tenetsSearchString: e.target.value,
                                });
                                handleSearch(e.target.value, "tenets");
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* search icon start */}
                      {showSearch.tenetsSearch ? (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              tenetsSearch: false,
                            });
                            handleSearch("", "tenets");

                            setsearchString({
                              ...searchString,
                              tenetsSearchString: "",
                            });
                          }}
                        >
                          <X size={16} weight="light" className="c-icons" />
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              tenetsSearch: true,
                            });
                          }}
                        >
                          <MagnifyingGlass
                            size={16}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                      )}
                    </div>
                  </Form.Label>
                  <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                    {topics &&
                    topics.my_contact &&
                    topics.my_contact.length > 0 ? (
                      topics.my_contact.map((topic, index) => (
                        <>
                          <li
                            className="comman-list  with-after-50 p-0"
                            key={index}
                          >
                            <a
                              href="#/"
                              className="w-100 position-relative tab_link  left-chat "
                            >
                              <div className="d-flex align-items-center">
                                <div className="c-list-icon">
                                  <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                    <UserCircle
                                      size={25}
                                      weight="light"
                                      className="c-icons h25w25"
                                    />
                                  </div>
                                </div>
                                <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                  <div className="top-part pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="chat-u-name text-truncate">
                                        {topic.title}
                                      </div>
                                      <div className="d-flex align-items-cente">
                                        <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                          {assistantProfileData.selectedTenets.includes(
                                            topic.filter_key
                                          ) ? (
                                            <></>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="form-check form-check-inline m-0 ps-2">
                                            <Form.Check
                                              className="with-no-label-space"
                                              type="switch"
                                              role="switch"
                                              checked={assistantProfileData.selectedTenets.some(
                                                (item) =>
                                                  item.categories.includes(
                                                    topic.filter_key
                                                  )
                                              )}
                                              onChange={() => {
                                                handleSwitchAction(
                                                  topic.filter_key,
                                                  "selectedTenets",
                                                  "my_contact"
                                                );
                                              }}
                                            />
                                            <label className="form-check-label p-0"></label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                             
                          </li>
                        </>
                      ))
                    ) : (
                      <>
                        <CommanPlaceholder placeholderText={_l("l_no_data")} />
                      </>
                    )}
                  </ul>
                </Form.Group>
                {/* ----------------- Tenets end ------------------ */}

                {/* ----------------- Equipments Start ------------------ */}

                <Form.Group className="col-lg-4  c-input-box  position-relative">
                  <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
                    <div>{`${_l("l_equipments")}`}</div>
                    <div className="d-flex align-items-center gap10 comman_action_icon">

                      {showSearch.equipmentsearch ? (
                        <div class="searchToggle position-relative">
                          <div class="comment-input-box togglesearchfield">
                            <input
                              type="text"
                              class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                              placeholder="Search"
                              value={searchString.equipmentsearchString}
                              onChange={(e) => {
                                setsearchString({
                                  ...searchString,
                                  equipmentsearchString: e.target.value,
                                });
                                handleSearch(e.target.value, "equipment");
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {showSearch.equipmentsearch ? (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              equipmentsearch: false,
                            });
                            handleSearch("", "equipment");
                            setsearchString({
                              ...searchString,
                              equipmentsearchString: "",
                            });
                          }}
                        >
                          <X size={16} weight="light" className="c-icons" />
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32"
                          onClick={() => {
                            setShowSearch({
                              ...showSearch,
                              equipmentsearch: true,
                            });
                          }}
                        >
                          <MagnifyingGlass
                            size={16}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                      )}
                    </div>
                  </Form.Label>

                  <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                    {topics && topics.equipments && topics.equipments.length ? (
                      topics.equipments.map((equipment, index) => (
                        <li
                          className="comman-list  with-after-50 p-0"
                          key={index}
                        >
                          <a
                            href="#/"
                            className="w-100 position-relative tab_link  left-chat "
                          >
                            <div className="d-flex align-items-center">
                              <div className="c-list-icon">
                                <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                  <UserCircle
                                    size={25}
                                    weight="light"
                                    className="c-icons h25w25"
                                  />
                                </div>
                              </div>
                              <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                <div className="top-part pb-1">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="chat-u-name text-truncate">
                                      {equipment.title}
                                    </div>
                                    <div className="d-flex align-items-cente">
                                      <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                        {assistantProfileData.selectedEquipments.includes(
                                          equipment.filter_key
                                        ) ? (
                                          <></>
                                        ) : (
                                          <></>
                                        )}
                                        <div className="form-check form-check-inline m-0 ps-2">
                                          <Form.Check
                                            className="with-no-label-space"
                                            type="switch"
                                            role="switch"
                                            checked={assistantProfileData.selectedEquipments.some(
                                              (item) =>
                                                item.categories.includes(
                                                  equipment.filter_key
                                                )
                                            )}
                                            onChange={() => {
                                              handleSwitchAction(
                                                equipment.filter_key,
                                                "selectedEquipments",
                                                "equipments"
                                              );
                                            }}
                                          />
                                          <label className="form-check-label p-0"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                           
                        </li>
                      ))
                    ) : (
                      <>
                        <CommanPlaceholder placeholderText={_l("l_no_data")} />
                      </>
                    )}
                  </ul>
                </Form.Group>

                {/* ----------------- Equipments end ------------------ */}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          {currentTab == "tab1" ? (
            <>
                <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  if (!loader) {
                    if (isValidData()) {
                      if (Object.keys(assistantData) > 0) {
                        handleClose();
                      } else {
                        handleSave();
                      }
                    }
                  }
                }}
                disabled={loader}
              >
                {loader ? _l("l_please_wait") : _l("l_create")}
                {loader ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  setCurrentTab("tab1");
                }}
              >
                {_l("l_previous")}
              </Button>
              <Button variant="secondary" size="sm" onClick={handleClose}>
                {_l("l_close")}
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  if (!loader) {
                    if (isValidData()) {
                      if (Object.keys(assistantData) > 0) {
                        handleClose();
                      } else {
                        handleSave();
                      }
                    }
                  }
                }}
                disabled={loader}
              >
                {loader ? _l("l_please_wait") : _l("l_save")}
                {loader ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateAiAssistant;

import React, { useState } from "react";
import { _l } from "../../hooks/utilities";
import { ArrowRight, Star, } from "phosphor-react";
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import customerServices from "../../services/customer-services";
import { showMessage } from "../../actions/messages";
import { Spinner } from "react-bootstrap";

const StarRatingDropdown = ({
  handler = () => { },
  rating = 0,
}) => {
  let star = [
  {
    value :1,
    titte : "l_worst"
  },
  {
    value :2,
    titte : "l_bad"
  },
  {
    value :3,
    titte : "l_average"
  },
  {
    value :4,
    titte : "l_good"
  },
  {
    value :5,
    titte : "l_excellent"
  },
   ]

  const dispatch = useDispatch()

  let userType = localStorage.getItem("user_type");
  let contact_role = localStorage.getItem("contact_role");

  const selectedTask = useSelector((state) => state.customer.selectedTask);

  const [customRating, setCustomRating] = useState(rating)
  const [customRatingLoader, setCustomRatingLoader] = useState(false)

   const updateTaskRating = () => {
    if (customRating != "" && customRating > 0 && customRating != rating) {
      setCustomRatingLoader(true)
      customerServices.updateTaskRating(selectedTask.id, customRating )
      .then((res) => {
        if (res.status) {
        handler(0, customRating)
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setCustomRatingLoader(false))
    }
   }

// selectedTask.schedule_id > 0 && 
  return (
    <DropdownMenu container="body" className={`dropdown-menu w-280 ${userType == "contact" && JSON.parse(contact_role != 3 )? "" : "for-disabled"}`}>
      <ul className="">
        <li>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail c-font f-14 title-fonts text-truncate color-white">
                {_l("l_star_rating")}
              </div>
              {
                contact_role == 1 ? 
                <div className="d-flex w-50 align-items-center bg-white-03 radius_3 pe-2">
                  <input
                    type="number"
                    className="form-control with-base-bg py-2 f-11 c-font bg-transparent border-0"
                    placeholder={`${"0.0"}`}
                    onChange={(e) => {
                      setCustomRating(e.target.value);
                    }}
                    value={customRating}
                  />
                  <a className={`send-arrow-btn with_position_right_zero ps-2 d-flex align-items-center h32w32 ${customRatingLoader ? "for_disabled" : ""}`}        
                    onClick={() => updateTaskRating()}
                  >
                    { customRatingLoader 
                      ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      : <ArrowRight size={18} weight="light" className="c-icons"/>
                    }
                  </a>
                </div>
                : <></>
              }
            </div>
          </div>
        </li>
        <li>
          <hr className="dropdown-divider my-0" />
        </li>
        <li></li>
      </ul>
    
      <ul className="dropdown-contain overflowscroll" id="conversation-area">
        <div className="star-rating-main">
          <div className="d-flex align-items-center justify-content-between">
            {star.map((star, index) => {
              return (<>
                <a href="#/" className={`star-rating-items text-center d-flex flex-column ${index < rating ? "active" : ""}`} onClick={(e) => {
                  handler(star.value == rating ? 0 :star.value)
                }} >
                  <Star size={18} weight="fill" className="c-icons" />
                  <span className="c-font color-white-60 f-10 lh-1 location fw-normal mt-1">{_l(star.titte)}</span>
                </a>
              </>)
            })}
          </div>
        </div>
      </ul>
    </DropdownMenu>
  );
};

export default StarRatingDropdown;

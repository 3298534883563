import React,{useEffect, useState} from 'react'
import user_7 from "../../assets/images/users/user_7.jpg";
import { ChatCircle, Image } from "phosphor-react";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TaskListStatusDropdown from '../Dropdowns/TaskListStatusDropdown';
import { _l } from "../../hooks/utilities";
import CommentDropdown from '../Dropdowns/CommentDropdown';
import ImageUploadDropdown from '../Dropdowns/ImageUploadDropdown';
import CommanOffCanvas from '../Offcanvas/CommanOffCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { changePriority, getSelectedTask, updateTaskStatus } from '../../actions/customer';
import customerServices from '../../services/customer-services';
import moment from 'moment';
import { COUNT_LOCK_TASKS } from '../../actions/action-type';
import jQuery from 'jquery';

import { DEFAULT_PLACEHOLDER } from "../../actions/action-type";

const LockTaskCard = ({task, key}) => {
    const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
    const [taskStatus, setTaskStatus] = useState(task.status);
    const dispatch = useDispatch();
    const project_id = useSelector((state) => state.customer.selectedProject);
    const {globalView} = useSelector((state) => state.customer);
    const [updatedCommentCount, setUpdatedCommentCount] = useState(task.comments_count ? task.comments_count : 0);
    const [task_type, setTask_type] = useState('');
    const [openCommentTab, setOpenCommentTab] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [updatedAttachmentCount, setUpdatedAttachmentCount] = useState(task.attachments_count ?task.attachments_count:0 );
  const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
  const [taskPriority, setTaskPriority] = useState(2);

  useEffect(() => {
    setTaskStatus(task.status);
    setTask_type(task && task.is_requested  && task.is_requested == 1 ? 'request-task' : 'task');
  }, [task])

  useEffect(() => {
    if(window.location.pathname == '/liteversion' || globalView == "liteVersionView")
    {
      jQuery('.provider-list').addClass('d-none');
    } else {
      jQuery('.provider-list').removeClass('d-none');
    }
  }, [])
  const changeTaskPriority = (e) => {
    dispatch(
      changePriority(
        task.id,
        project_id,
        taskPriority == 3 ? 3 : 2,
        task.provider
      )
    ).then(() => {
      setTaskPriority(taskPriority == 3 ? 2 : 3);
    });
  };
    useEffect(() => {
        setTaskId(task ? task.id : 0);
        setTaskPriority(task.priority);
    }, [task])
    const changeTaskStatus = (statusId) => {
        if (statusId != "task_convert_to_request") {
          setTaskStatus(statusId);
        }
        dispatch(
          updateTaskStatus(
            task.id,
            project_id,
            statusId,
            task.is_requested,
            task.provider,
            task.id,
            task.name
          )
        ).then(() => {
          customerServices.getCountLockTask(project_id, moment(localStorage.getItem('__selectedDate')).format("YYYY-MM-DD"), localStorage.getItem('is_dashboard') ? localStorage.getItem('is_dashboard'): 0).then((res) => {
            if(res.status)
            {
                let data = res.data;
                dispatch({
                    type: COUNT_LOCK_TASKS,
                    payload: {data}
                });
            }
          })
        });
       
      };
      const handleSelectedTask = (
        taskId = 0,
        show = "",
        isRequestedTask = null,
        convertRequestTaskId = 0
      ) => {
        setTaskId(taskId);
        localStorage.setItem("SelectedTask", taskId);
        localStorage.setItem(
          "TaskType",
          isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
        );
      };
    
    return (
        <>
        <li key={key} className='align-items-center d-flex justify-content-between p-10 px-0 pt-0'>
            <div className='d-flex flex-grow-1 w100minus100per'>
                <div className="h20w20 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 ">
                  {
                    task.assignee_task && task.assignee_task.length > 1 ? (
                                                  <div className='multi_inner_wrapper with-min-width d-flex with_minus '>
                                                    <a href="#/" className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${DEFAULT_PLACEHOLDER}')` }} >

                                                        </div>
                                                    </a>
                                                    <a href="#/" className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <UncontrolledDropdown className='dropdown-center'>
                                                            <DropdownToggle tag="a" className=" dropdown team-list-dropdown" >
                                                                <a href="#/" className="comman-image-box h20w20 with_overlay rounded-circle d-flex radius_3">
                                                                    <div className="unread_count m-auto" >
                                                                        {task.assignee_task.length > 1 ?task.assignee_task.length: 0}
                                                                    </div>
                                                                </a>
                                                                <UncontrolledGeneralListing list={task.assignee_task.map((res,index) => {
                                                                    return {
                                                                        name: res.name,
                                                                        image: res.profile_image,
                                                                        id: index,
                                                                    };
                                                                })} />
                                                            </DropdownToggle>
                                                        </UncontrolledDropdown>
                                                    </a>
                                                  </div> 
                    )
                    :
                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${task.assignee_task && task.assignee_task.length > 0 && task.assignee_task[0].profile_image ? task.assignee_task[0].profile_image : DEFAULT_PLACEHOLDER}')` }}></div>
                  }
                </div>
                <a href="#/" 
                    className='c-font color-white-80 f-12 flex-grow-1 ms-2 text-truncate w100minus100per pe-2' 
                    onClick={() => { setShowDocumentCanvas(true);
                                    dispatch(getSelectedTask(project_id, task.id, 0)); }}
                >&nbsp; &nbsp; &nbsp;{task.name}</a>
            </div>
            <div className='d-flex'>
                <div className='d-flex pe-2'>
                    <div className="multi_image_wrapper dropdown dropdown-center">
                        {
                          task.attachments_count > 0 ? 
                          <div className="d-flex multi_inner_wrapper pe-2 position-relative">
                            <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('${user_7}')` }} >

                                </div>
                            </a>
                            <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('${user_7}')` }} >

                                </div>
                            </a>
                            <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropdown-center'>
                                <a href="#/" className="m-auto" type="button"
                                    id="imageLstAndUploadDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="5,5"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                     
                                     
                                     
                                    onClick={() =>{setOpenAttachmentsDropdown(true)}}
                                     >
                                        {updatedAttachmentCount
                                        }
                                </a>
                                <ImageUploadDropdown
                                setOpenAttachmentsDropdown={
                                    setOpenAttachmentsDropdown
                                  }
                                  openAttachmentsDropdown={
                                    openAttachmentsDropdown
                                  }
                                  task_id={task.id}
                                  project_id={project_id}
                                  updateImageCount={setUpdatedAttachmentCount}
                                  Request_task_converted={
                                    task &&
                                    parseInt(task.request_task_id) > 0 &&
                                    task.is_requested == 1
                                      ? true
                                      : false
                                  }
                                  taskDetails={task}
                                />
                            </div>
                        </div>
                        :
                        <div className='d-flex align-items-center'>
                          <div className='d-flex pe-2'>
                            <div className="multi_image_wrapper dropdown dropdown-center">
                              <div className="d-flex multi_inner_wrapper pe-2 position-relative">
                                <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropdown dropdown-center'>
                                  <a href="#/"   className="m-auto" type="button"
                                    id="imageLstAndUploadDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-offset="5,5"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                     
                                     
                                     
                                    onClick={() =>{setOpenAttachmentsDropdown(true)}}
                                     >
                                    <Image
                                      size={20}
                                      weight="light"
                                      className="c-icons"/>
                                   </a>
                                      <ImageUploadDropdown
                                        setOpenAttachmentsDropdown={setOpenAttachmentsDropdown}
                                        openAttachmentsDropdown={openAttachmentsDropdown}
                                        task_id={task.id}
                                        project_id={project_id}
                                        updateImageCount={setUpdatedAttachmentCount}
                                        Request_task_converted={task && parseInt(task.request_task_id) > 0 &&
                                                                task.is_requested == 1
                                                                ? true
                                                                : false
                                                                }
                                        taskDetails={task}
                                        />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                    </div>
                </div>
                <div className={`dropdown-center comman_action_icon with_separator_10 card-view-less-padding
                          `}>
                  <div
                    className={`white-border-box color-white-60 dropend d-flex align-items-center h25w25 action_icon with_bg border-0 action_icon with_bg dropdown-center`}
                    id="TaskListStatusDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                     
                     
                     
                    data-className="tooltip-main"
                  >
                    <div className="d-flex">
                      <span
                        className={`c-dots c-10 ${task && task.is_requested == 1? "bg-warning" :taskStatus == 1
                          ? "in-progress-white"
                          : taskStatus == 4
                            ? "in-progress-blue"
                            : "done"
                          } rounded-circle `}
                      ></span>
                        <TaskListStatusDropdown
                          key={2}
                          setTaskStatus={changeTaskStatus}
                          is_task={task.is_requested == 1? 1:0}
                        />
                    </div>
                  </div>
                </div>
                <div className="comman_action_icon with_separator_10 card-view-less-padding">
                          <div
                            className={`priority-checkbox span-13 white-border-box action_icon with_bg close-toast`}
                           
                             
                             
                             
                          >
                            <input
                              type="checkbox"
                              id="priority"
                              className="absolute-input"
                              checked={taskPriority == 3 ? true : false}
                              onChange={(e) => {
                                changeTaskPriority(e);
                              }}
                              disabled={
                                task && parseInt(task.request_task_id) > 0
                              }
                            />
                            <div className="check_box">
                              <HighPriorityActiveIcon className="HW18 check_span_img active" />
                              <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                            </div>
                          </div>
                        </div>
                <div className="dropdown comman_action_icon px-1 align-items-center d-flex dropdown-center">
                  <UncontrolledDropdown className="w-100 d-flex">
                    <DropdownToggle  data-toggle="dropdown" tag="span">
                    <a href="#/"
                        className="comman_action_icon w-100 d-flex"
                        type="button"
                        id="writeComMentDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                         
                         
                         
                         
                        onClick={() => {setOpenCommentTab(true)}}
                    >
                        <div className="d-flex align-items-center w-100">
                            <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img">
                                <ChatCircle
                                    size={16}
                                    weight="light"
                                    className="c-icons"
                                />
                                <span className="rounded-pill badge-custom p-0 d-none">5</span>
                            </div>
                            <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                            {updatedCommentCount  }
                            </span>
                        </div>
                    </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                    {
                        <CommentDropdown
                        openCommentTab={openCommentTab}
                        setOpenCommentTab={setOpenCommentTab}
                        task_id={task.id}
                        projectId={project_id}
                        updatedCommentCount={setUpdatedCommentCount}
                        unContolList={true}
                      />
                    }
                    </DropdownItem>
                    
                  </UncontrolledDropdown>
                </div>
            </div>
        </li>

        {showDocumentCanvas ? (
            <CommanOffCanvas
                data={task}
                setSelectedId={setTaskId}
                selectedTaskId={taskId}
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
              }}
              docType={"task"}
              canvasfullHeightwithHeaderFooter={true}
              onCloseShowLeftPanelDefault={true}
              handleParentComponentAction={(type, taskData) => {
                if (task_type == 'task') {
                  if (type == 'attachmentCountUpdate') {
                    return;
                  } else if (type == 'handleSelectedTask') {
                    handleSelectedTask(taskData.task_id, taskData.flag)
                    return;
                  } else if (type == 'convertedTaskAction') {
                    handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                    return;
                  }
                } else if (task_type == 'request-task') {

                  if (type == 'convertedTaskAction') {
                    handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                    return;
                  }
                }

              }}
            />
          ) : (
            <></>
          )}
        </>
    )
                               
}

export default LockTaskCard
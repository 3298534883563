import React from 'react';
 

function DownloadSimpleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M8.89062 2.5C8.89062 2.22386 8.66677 2 8.39062 2C8.11448 2 7.89062 2.22386 7.89062 2.5V8.29289L6.11918 6.52145C5.92392 6.32618 5.60733 6.32618 5.41207 6.52145C5.21681 6.71671 5.21681 7.03329 5.41207 7.22855L8.03707 9.85355C8.23233 10.0488 8.54892 10.0488 8.74418 9.85355L11.3692 7.22855C11.5644 7.03329 11.5644 6.71671 11.3692 6.52145C11.1739 6.32618 10.8573 6.32618 10.6621 6.52145L8.89062 8.29289V2.5ZM3.39062 9.5C3.39062 9.22386 3.16677 9 2.89062 9C2.61448 9 2.39062 9.22386 2.39062 9.5V13C2.39062 13.2652 2.49598 13.5196 2.68352 13.7071C2.87106 13.8946 3.12541 14 3.39062 14H13.3906C13.6558 14 13.9102 13.8946 14.0977 13.7071C14.2853 13.5196 14.3906 13.2652 14.3906 13V9.5C14.3906 9.22386 14.1668 9 13.8906 9C13.6145 9 13.3906 9.22386 13.3906 9.5V13H3.39062V9.5Z"/>
</svg>


		</React.Fragment>
	)
}

export default DownloadSimpleIcon
import React from "react";
import { _l } from "../../hooks/utilities";

const TaskListStatusDropdown = ({ setTaskStatus, is_task = 0,bulkAction, is_liteversion = false,isRequest = false, istask =false }) => {
  if (window.location.pathname == '/MyEmail') {
    return (
      <ul className="dropdown-menu w-140" aria-labelledby="TaskListStatusDropdown">
        <li className="dropdown-item hr_1" onClick={() => setTaskStatus(1)}>
          <div className="d-flex align-items-center">
            <span className="c-dots c-10 in-progress-red rounded-circle me-2"></span>
            <span> {_l("l_active")}</span>
          </div>
        </li>
        <li className="dropdown-item hr_1" onClick={() => setTaskStatus(0)}>
          <div className="d-flex align-items-center">
            <span className="c-dots c-10 in-progress-white rounded-circle me-2"></span>
            <span className="w100minus20 text-truncate"> {_l("l_not_active")}</span>
          </div>
        </li>
      </ul>
    );
  } else {
    return (
      <ul className="dropdown-menu w-140" aria-labelledby="TaskListStatusDropdown">
            <li className="dropdown-item hr_1" onClick={() => {
              if (bulkAction) {
                setTaskStatus(0,'task_convert_to_request',"status_change")
              } else if (is_liteversion) {
                setTaskStatus(0,'task_convert_to_request')
              } else {
                setTaskStatus('task_convert_to_request')  
              }
              }}>
              <div className="d-flex align-items-center">
                <span className="c-dots c-10 bg-warning rounded-circle me-2"></span>
                <span className="w100minus20 text-truncate text-uppercase"> {_l("l_request")}</span>
              </div>
            </li>

        <li className="dropdown-item hr_1" onClick={() => {
          if (bulkAction) {
            setTaskStatus(0,1,"status_change")
          } else {
            setTaskStatus(1)  
          }}}>
          <div className="d-flex align-items-center">
            <span className="c-dots c-10 in-progress-white rounded-circle me-2"></span>
            <span className="w100minus20 text-truncate text-uppercase"> {_l("l_todo_kpi_name")}</span>
          </div>
        </li>
        <li className="dropdown-item hr_1" onClick={() =>{
          if (bulkAction) {
            setTaskStatus(0,4,"status_change")
          } else {
            setTaskStatus(4)  
          }
        }}>
          <div className="d-flex align-items-center">
            <span className="c-dots c-10 in-progress-blue rounded-circle me-2"></span>
            <span className="w100minus20 text-truncate text-uppercase">{_l("l_ongoing_kpi")} </span>
          </div>
        </li>
        <li className="dropdown-item hr_1" onClick={() => {
          if (bulkAction) {
            setTaskStatus(0,5,"status_change")
          } else {
            setTaskStatus(5)  
          }
         }}>
          <div className="d-flex align-items-center">
            <span className="c-dots c-10 done rounded-circle me-2"></span>
            <span className="w100minus20 text-truncate text-uppercase">{_l("l_done")}</span>
          </div>
        </li>
        {
          window.location.pathname == "/leads" ?
            <li className="dropdown-item hr_1" onClick={() => setTaskStatus(0)}>
              <div className="d-flex align-items-center">
                <span className="c-dots c-10 in-progress-red rounded-circle me-2"></span>
                <span className="w100minus20 text-truncate">{_l("l_close")}</span>
              </div>
            </li>
            :
            ""
        }
      </ul>
    );
  }
};

export default TaskListStatusDropdown;

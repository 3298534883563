import React, { useEffect, useRef, useState } from "react";
import { _l } from "../../../hooks/utilities";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import userPlaceHolder from "../../../assets/images/user-placeholder.jpg";
 
import { Phone, EnvelopeSimple,CalendarBlank,UploadSimple,X,ArrowSquareRight,MapTrifold } from "phosphor-react";
import ScheduleFooter from "./ScheduleFooter";
import { showConfirmation, showMessage } from "../../../actions/messages";
import { createScheduleDetails } from "../../../actions/benchmark-actions/benchmark-actions";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
const ExistingProviderList = ({ handleStep, isCreateBenchmark, isEdit }) => {
    const [PeopleList, setPeopleList] = useState(['1', '2', '3']);
    const benchmarkReducer = useSelector((state) => state.benchmarkReducer);
    const [operatListShow, setOperatListShow] = useState(false);
    const userType = localStorage.getItem("user_type");
    const [operators, setOperators] = useState([]);
    const [providerCategory, setProviderCategory] = useState(0);
    const [selectedSchedule, setSelectedSchedule] = useState({});
    const dispatch = useDispatch();
    const isClientExist = localStorage.getItem("operatorClientId") == localStorage.getItem('client_id') ? 0 : 1
    const [selectedBenchmarkOperator, setSelectedBenchmarkOperator] = useState([]);
    const [loder, setLoder] = useState(false);
    useEffect(() => {
        if (benchmarkReducer.createScheduleDetail && Object.keys(benchmarkReducer.createScheduleDetail).length) {
            setProviderCategory(benchmarkReducer.createScheduleDetail.providerCategory ? benchmarkReducer.createScheduleDetail.providerCategory : 0);
            let tempOperatorShow = isEdit ? true : benchmarkReducer.createScheduleDetail.step1_selectedTab == "existing" && benchmarkReducer.createScheduleDetail.providerCategory > 0
            setOperatListShow(tempOperatorShow)
            setOperators(benchmarkReducer.createScheduleDetail.operators ? benchmarkReducer.createScheduleDetail.operators : [])
            setSelectedSchedule(benchmarkReducer.createScheduleDetail.selectedSchedule ? benchmarkReducer.createScheduleDetail.selectedSchedule : {})
            if (isCreateBenchmark) {
                setSelectedBenchmarkOperator(benchmarkReducer.createScheduleDetail.selectedBenchmarkOperator ? benchmarkReducer.createScheduleDetail.selectedBenchmarkOperator : [])
            }
        }
    }, [JSON.stringify(benchmarkReducer.createScheduleDetail)])
    const changeSchedule = (operator) => {
        if (Object.keys(selectedSchedule).length) {
            setSelectedSchedule(
                operator.email == selectedSchedule.email ? {} : operator
            );
            dispatch(createScheduleDetails(operator.email == selectedSchedule.email ? {} : operator, 'selectedSchedule'))
        } else {
            setSelectedSchedule(operator);
            dispatch(createScheduleDetails(operator, 'selectedSchedule'))
        }
    };
    const handleSave = () => {
        let isAllowNextStep = isCreateBenchmark ? selectedBenchmarkOperator.length : Object.keys(selectedSchedule).length
        setLoder(false)
        if (isAllowNextStep || isEdit || userType == "operator") {
            handleStep('create-schedule-type')
        } else {

            dispatch(
                showMessage("unsucess", _l('l_error'), _l('l_please_select_the_provider'))
            );
            return false;
        }
    }
    const setBenchmarkOperator = (selectedEmail) => {
        let checkedOperator = selectedBenchmarkOperator.filter((email) => email == selectedEmail)
        dispatch(createScheduleDetails({
            ...benchmarkReducer.createScheduleDetail, ['selectedBenchmarkOperator']: checkedOperator.length ? selectedBenchmarkOperator.filter((benchmarkOperator) => benchmarkOperator != selectedEmail) : [...selectedBenchmarkOperator, selectedEmail],
        }))


    }

    return (
        <>
            <div className="border-top overflow-hiiden-auto">
                {/* {PeopleList.map((item) => {
            return (
                <li className="dropdown-item px-0 py-2 with-border">
                    <div className="d-flex align-items-center">
                        <div className="c-list-icon">
                            <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${User_9}')` }}></div>
                            </div>
                        </div>
                        <div className="ps-2 w100minus20 d-flex align-items-center">
                            <div className="c-list-detail c-font f-14 text-truncate flex-grow-1">Brooklyn Simmons</div>
                            <Form.Check type="checkbox" />
                        </div>
                    </div>
                </li>
            )
        })} */}
                {operatListShow && operators && operators.length ? (
                    operators.map((operator, index) => {
                        let isChecked = selectedBenchmarkOperator.filter((benchmarkOperatorEmail) => benchmarkOperatorEmail == operator.email)
                        // ${!isCreateBenchmark? operator.email == selectedSchedule.email ? " bg-white-03": "": "" }
                        let isCheckedOperator = isClientExist == 1 && isCreateBenchmark ? true : isClientExist == 1 && !isCreateBenchmark

                        return (
                            <div
                                className={`row border-bottom py-2 m-0 `}
                                key={index} >
                                {/* {
                                    isCreateBenchmark || (isClientExist == 1 && userType == 'operator') ?
                                        <div className={`col-1 d-flex align-items-center justify-content-center ${userType == 'contact' || (isClientExist == 1 && userType == 'operator') ? '' : 'd-none'}`}>
                                            <Form.Check
                                                className=""
                                                type="checkbox"
                                                checked={userType == 'contact' ? isChecked.length ? true : false : isCheckedOperator}
                                                onChange={() => {
                                                    if (isCreateBenchmark && userType == 'contact') {
                                                        setBenchmarkOperator(operator.email)
                                                    }
                                                }}
                                                disabled={userType == 'contact' ? isEdit ? true : false : isClientExist == 1 && isCreateBenchmark ? true : false}
                                            />
                                        </div>
                                        :
                                        <div className={`col-1 d-flex align-items-center justify-content-center ${userType == 'contact' ? '' : 'd-none'}`}>
                                            <Form.Check
                                                className=""
                                                type="radio"
                                                checked={Object.keys(selectedSchedule).length > 0 && operator.email == selectedSchedule.email ? true : false}
                                                onClick={() => {
                                                    changeSchedule(operator);
                                                }}
                                                onChange={() => {

                                                }}
                                                disabled={isEdit ? true : false}
                                            />
                                        </div>
                                } */}


                                <div className="col-auto ps-0 d-flex align-items-center">
                                    {
                                        isCreateBenchmark || (isClientExist == 1 && userType == 'operator') ?
                                            <div className={` d-flex align-items-center justify-content-center pe-3 ${userType == 'contact' || (isClientExist == 1 && userType == 'operator') ? '' : 'd-none'}`}>
                                                <Form.Check
                                                    className="h20w20"
                                                    type="checkbox"
                                                    checked={userType == 'contact' ? isChecked.length ? true : false : isCheckedOperator}
                                                    onChange={() => {
                                                        if (isCreateBenchmark && userType == 'contact') {
                                                            setBenchmarkOperator(operator.email)
                                                        }
                                                    }}
                                                    disabled={isEdit || (userType == 'contact' ? false : isClientExist == 1 && isCreateBenchmark ? true : false)}
                                                />
                                            </div>
                                            :
                                            <div className={` d-flex align-items-center justify-content-center pe-3 ${userType == 'contact' ? '' : 'd-none'}`}>
                                                <Form.Check
                                                    className="h20w20"
                                                    type="radio"
                                                    checked={Object.keys(selectedSchedule).length > 0 && operator.email == selectedSchedule.email ? true : false}
                                                    onClick={() => {
                                                        changeSchedule(operator);
                                                    }}
                                                    onChange={() => {

                                                    }}
                                                    disabled={isEdit}
                                                />
                                            </div>
                                    }
                                    <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                        <div
                                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                            style={{
                                                backgroundImage: `url('${operator.profile_url
                                                    ? operator.profile_url
                                                    : userPlaceHolder
                                                    }')`,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="col-5 ps-0 with_separator_10 d-flex align-items-center"
                                     
                                     
                                     

                                >
                                    <div className="d-flex align-items-center w-100">
                                        <div className="c-list-icon">
                                            <div className=" comman-round-box with-bg ">
                                            <EnvelopeSimple size={18}  weight="light" className="c-icons" />
                                            </div>
                                        </div>
                                        <div className="c-list-detail c-font f-14 color-white-60 w100minus20 text-truncate px-2"
                                             
                                             
                                             >
                                            {operator.email ? operator.email : ""}
                                        </div>
                                         
                                    </div>
                                </div>

                                <div className="col-4 d-flex align-items-center">
                                    <div className="d-flex align-items-center w-100">
                                        <div className={`c-list-icon ${operator.alreadyExist || operator.full_name != '' ? "d-none" : ""}`}>
                                            <div className=" comman-round-box with-bg ">
                                            <Phone size={18}  weight="light" className="c-icons" />
                                            </div>
                                        </div>
                                        <div className="c-list-detail c-font f-14 color-white-60 w100minus20 text-truncate px-2"
                                             
                                             
                                             
                                        >
                                            {operator.full_name != '' ? operator.full_name : operator.alreadyExist ? operator.mobile : operator.mobile}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 d-flex align-items-center d-none">
                                    <div className="c-list-detail c-font f-14 color-white-60 w100minus20 text-truncate px-2"
                                         
                                         
                                         
                                    >
                                        {operator.full_name ? operator.full_name : "-"}
                                    </div>
                                </div>
                                {/* d-flex class remove for display none */}
                                <a href="#/" className="col-1 d-none">
                                <X size={16}  weight="light" className="c-icons" />
                                </a>

                            </div>

                        );
                    })
                ) : (
                    <>
                        <CommanPlaceholder imgType="no-user" mainSpacing="d-none" placeholderText = {_l('l_no_operator')} />

                    </>
                )}
            </div>
            <div className={`d-flex mt-auto w-100 position-sticky-bottom bg-base-header-color border-top`}>
                <ScheduleFooter selectedTab={`SelectCategory`} handleStep={handleSave} currentStep={1} isCreateBenchmark={isCreateBenchmark} providerCategory={providerCategory} loder={loder} setLoder={setLoder} isEdit={isEdit} />
            </div>
        </>

    );
};

export default ExistingProviderList;
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l } from "../../hooks/utilities";
import { NotePencil } from "phosphor-react";

const RobotPlanDetailModal = ({
  show,
  handleClose,
  handleOpenEdit,
  duration,
  surface,
  attachment = "",
  isExternal
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="flex-grow-1">Robot Plan Detail</Modal.Title>
          {
            isExternal ? 
            <></>
            :
          <div className="ps-2 comman_action_icon">
            <div className="with_separator_10 before-block me-2">
              <a href="#/" className="action_icon position-relative on-hover-active-toggle-img light-theme-img"
                onClick={() => {
                  handleOpenEdit()
                }}
              >
                <NotePencil size={20} weight="light" className="c-icons" />
              </a>
            </div>
          </div>
            }
        </Modal.Header>
        <Modal.Body className="">
          <div className="form-wrapper-main pt-0">
            <div className="align-items-center d-flex w-100 pb-3 border-bottom mb-3">
              <div className="flex-grow-1 text-center border-right">
                <div className="c-font f-16 title-fonts fw-semibold">{_l("l_superficy")}</div>
                <div className="c-font f-16">{surface}m<sup>2</sup></div>
              </div>
              <div className="flex-grow-1 text-center">
                <div className="c-font f-16 title-fonts fw-semibold">{_l("l_duration")}</div>
                <div className="c-font f-16">{duration}hrs</div>
              </div>
            </div>
            <div class="ratio ratio-21x9 radius_3">
            <div
              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
              style={{ backgroundImage: `url('${attachment != "" ? attachment : ""}')`}}
            ></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end d-none">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm" 
          onClick={() => {
            handleOpenEdit()
            handleClose()
          }}
          >{_l("l_edit")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RobotPlanDetailModal;

import React, { useState, useEffect } from 'react'
import { Info } from "phosphor-react";
import { _l } from '../../hooks/utilities';
import jquery from "jquery";

function EquipmentCard({ Equipment, projectId, taskList, setTaskList,getEquipment, index }) {

    const FullLeftPanelWidth = jquery('.fixed-left-panel').width();
    const FullLeftPanelHeight = jquery('.fixed-left-panel').height();
    const FullLeftPanelHeight2 = jquery('#employeeListingDropDown').height();
    const [heightWidth, setHeightWidth] = useState({});
    let IdName = 'employeeListingDropDown';

    useEffect(() => {
        if (Object.keys(heightWidth).length) {
            IdName = 'employeeListingDropDown';
            let tempWidth = heightWidth.width;
            let tempHeight = heightWidth.height;
            if (heightWidth.width + jquery("#" + IdName).width() > FullLeftPanelWidth) {
                
                tempWidth = heightWidth.width - jquery("#" + IdName).width()
            }
            if (heightWidth.height + jquery("#" + IdName).height() > FullLeftPanelHeight) {
                
                tempHeight = heightWidth.height
            }
            let setposition = {
                position: 'absolute',
                left: tempWidth,
                top: tempHeight > 600 ? Math.abs(tempHeight) - FullLeftPanelHeight2  : Math.abs(tempHeight),
                bottom:'auto',
            };
           
            
            
            jquery("#employeeListingDropDown").css(
                setposition
            );
        }
    }, [heightWidth])


    return (
        <>
        <tr className={`border-bottom-0 ${index == 0 ? "border-top-0" : "border-top"}`}>
            <td >
                <div className="name-with-progress max-w300">
                    <div className="d-flex align-items-center">
                        <a href="#/" className="h35w35 comman-round-box with-bg rounded-circle bg-white-05 p-5px">
                            <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{ backgroundImage: `url('${Equipment.equipment_url ? Equipment.equipment_url : ''}')` }}
                            ></div>
                        </a>
                        <div className="ps-2 w100minus70 text-start flex-grow-1">
                            <div className="d-flex flex-column">
                                <a href="#/" className="title-fonts text-truncate text-start max-width75">
                                    {Equipment.title? Equipment.title : ''}
                                </a>
                                <div className="progress-slider d-none">
                                    <div className="d-flex align-items-center">
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    width: `${Equipment.task_progress ? Equipment.task_progress : 0}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <div className="progress-text c-font f-12 title-fonts ps-2">
                                            {Equipment.task_progress ? Equipment.task_progress : 0}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td className="max-width-100 text-truncate text-center d-none">
                <a href="#/" className="employee-table-items">{Equipment.equipment_total_duration ? Equipment.equipment_total_duration : 0}</a>
            </td>
            <td className="max-width-100 text-truncate text-center d-none">
                <a href="#/" className="employee-table-items">{Equipment.equipment_total_surface ? Equipment.equipment_total_surface : 0}</a>
            </td>
            <td className='d-none'> 
                <a href="#/" className="employeeListingDropDownOpen employee-table-items"
                    type="button"
                     
                     
                     
                     
                    onClick={(event) => {
                        
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                        
                        
                    }}

                >
                    <span className=""> {Equipment.request_count ? Equipment.request_count : '0'}</span>
                    <span className={`color-white-60 c-font f-12 px-1 ${Equipment.requested_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Equipment.requested_task_total_time ? Equipment.requested_task_total_time : '-'}]</span>
                    <a><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
                </a>

            </td>
            <td className='d-none'>
                <a href="#/" className="employeeListingDropDownOpen employee-table-items"
                    type="button"
                     
                     
                     
                     
                    onClick={(event) => {
                        
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                        
                        
                    }}

                >
                    <span className=""> {Equipment.todo_count ? Equipment.todo_count : '0'}</span>
                    <span className={`color-white-60 c-font f-12 px-1 ${Equipment.to_do_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Equipment.to_do_task_total_time ? Equipment.to_do_task_total_time : '-'}]</span>
                    <a><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
                </a>

            </td>
            <td className='d-none'>
            <a href="#/" className='employeeListingDropDownOpen employee-table-items'>
                <span className=" "
                     
                     
                     
                     
                    onClick={(event) => {
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                    }}
                > {Equipment.ongoing_count ? Equipment.ongoing_count : '0'}</span><span className={`color-white-60 c-font f-12 px-1 ${Equipment.ongoing_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Equipment.ongoing_task_total_time ? Equipment.ongoing_task_total_time : '-'}]</span>
                <a><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
            </a>
            </td>
            <td className="d-none"><a href="#/" className='employeeListingDropDownOpen employee-table-items'>
                <span className=""
                     
                     
                     
                     
                    onClick={(event) => {
                        let width = event.pageX
                        let height = event.pageY
                        setHeightWidth({ width: width, height: height })
                    }}
                > {Equipment.completed_count ? Equipment.completed_count : '0'}</span><span className={`color-white-60 c-font f-12 px-1 ${Equipment.completed_task_total_time == '00:00' ? 'd-none' : 'd-none'}`}>[ {Equipment.completed_task_total_time ? Equipment.completed_task_total_time : '00:00'}]</span>
                <a href="#/" className="d-none ps-2"><Info size={16} color="#fca21f" className="d-none" weight="light" /></a>
            </a>
            </td>
        </tr>
            {getEquipment && getEquipment.filter((data) => data.equipment_id == Equipment.filter_key).map((data) => {
                return (<>
                    <tr className='border-0'>
                        <td class="p-2 ps-5">
                            <div className="name-with-progress max-w300">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center badge badge-white rounded-pill fw-light me-1 pill-task-active p-1">
                                            <span className={`c-dots c-7 rounded-circle me-1 ${data.on_going_task  >0 ? "done" : "bg-warning"} `}></span>
                                            <span>
                                                {data.on_going_task  >0 ? _l("l_online") : _l("l_offline")}
                                            </span>
                                        </div>
                                    </div>
                                    <a href="#/" className="h25w25 comman-round-box with-bg rounded-circle bg-white-05 p-5px d-none">
                                        <div
                                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                            style={{ backgroundImage: `https://images.unsplash.com/photo-1685495856559-5d96a0e51acb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDEzfGJvOGpRS1RhRTBZfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60` }}
                                        ></div>
                                    </a>
                                    <div className="ps-2 w100minus70 text-start flex-grow-1">
                                        <div className="d-flex flex-column">
                                            <a href="#/" className="title-fonts text-truncate text-start max-width75"
                                                 
                                                 
                                                 
                                            >
                                                {data.quantity_name}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="p-2 max-width-100 text-truncate text-center">
                            <a href="#/" className="employee-table-items">{data.equipment_total_duration ? data.equipment_total_duration : 0}</a>
                        </td>
                        <td className="p-2 max-width-100 text-truncate text-center">
                            <a href="#/" className="employee-table-items">{data.equipment_total_surface ? data.equipment_total_surface : 0}</a>
                        </td>
                        <td className='p-2'>
                            <a href="#/" className="employeeListingDropDownOpen employee-table-items"
                                type="button"
                                 
                                 
                                 
                                 
                            >  <span className="">{data.request_count}</span>
                            </a>
                        </td>
                        <td className='p-2'>
                            <a href="#/" className="employeeListingDropDownOpen employee-table-items"
                                type="button"
                                 
                                 
                                 
                                 >
                                <span className="">{data.todo_count}</span>
                            </a>
                        </td>
                        <td className='p-2'>
                            <a href="#/" className='employeeListingDropDownOpen employee-table-items'>
                                <span className=" "
                                     
                                     
                                     
                                     

                                >{data.ongoing_count}</span>
                            </a>
                        </td>
                        <td className='p-2'>
                            <a href="#/" className='employeeListingDropDownOpen employee-table-items'>
                                <span className=""
                                     
                                     
                                     
                                     
                                    onClick={(event) => {
                                        let width = event.pageX
                                        let height = event.pageY
                                        setHeightWidth({ width: width, height: height })
                                    }}
                                >{data.completed_count}</span>
                            </a>
                        </td>
                    </tr>
                </>)
            })}

        </>
    )
}

export default EquipmentCard
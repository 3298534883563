import React, { useState, useEffect } from "react";
import { PencilSimpleLine, Sun, GearSix, SignOut, Question, UserSwitch,CaretDown, Wrench, Gauge, EnvelopeSimple, BezierCurve, CurrencyEur, Rows } from "phosphor-react";
import jquery from "jquery";
import { TaskModalClose, _l, accessPremiumFeature, favoriteDdashboardSelected, formateDate, hideContactDirectory, hideRightPanelNew, loadDataToIDB, showLeftPanelNew } from "../../hooks/utilities";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { leftPanelFilters, setSpaceList, userLogout, isPremuimEstimateDisable, getCustomerSpaces, projectTaskCounts, setSelectedLanguage, setProjectTheme, setSelectedGloablView, lastSessionOfChat, setFetchedTaskandMessages, commanoffcanvasisOpen, SetSelectedTask, setBulkTaskRating, setDashBoardView, setLeftPanelTab, setOpenOrCloseContactDirectoryChat, dayGridDay, dayGridWeek, dayGridMonth, setDaterangeFilterApplied, setDaterangeFilter, setApiLoaderShow } from "../../actions/customer";
import { useDispatch } from "react-redux";
import { userOffline, connectToSocket, getGroupDetails } from "../../actions/chat";
import { adminLoginAsStaff } from "../../actions/admin-actions";
import Form from 'react-bootstrap/Form';
import CustomerServices from "../../services/customer-services";
import { showConfirmation, showFullScreenLoader, showMessage } from "../../actions/messages";
import ToolsInformationModal from "../Modals/ToolsInformationModal";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { loginAsUser } from "../../actions/auth";
import AuthServices from "../../services/auth-services";
import { Button } from "react-bootstrap";
import BenchmarkServices from "../../services/benchmark-services";
import { archieveIDBCollections, clearDatabase, restoreIDBSession } from "../../hooks/indexed_db_helpers";
import moment from "moment";
import { clearConverstion, endVapiCall, getTaskIdAfterCall, setAssistantCallID } from "../../actions/vapi";
import { LANGUAGE_WITH_FLAG_JSON } from "../../constants/constants";

const MyProfileDropDownNew = ({
  isExternalHeader=false, 
  user_name,
  userDescription,
  profileImageURL , 
  contactlistShow, 
  setContactlistShow, 
  setSelectedProject = () => {}, 
  selectLiteVersion = () => {},
  disabledThirdPartyToolAccess,
  setSettingTopicName,
  setTopicMainKey,
  unreadMessages,
  unreadComments,
  setSettingMenuRender,
  SettingMenuRender,
  estimateDetails,
  viewPage,
  proposalUnreadComments,
  setshowMyProfile,
  setSelectedSpace,
  handleChangeViewtoFavView = () => {},
}) => {

  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type")
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  const adminAsStaff = localStorage.getItem('adminArchieve') ? true : false;
  const contact_role = localStorage.getItem("contact_role");
  const [randomID, setRandomID] = useState(String(Math.random()));

  const adminAuth = useSelector((state) => state.adminReducer.loginState);

  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("language") || "french");
  const [hideLogoutSwitch, setHideLogoutSwitch] = useState(adminAsStaff);
  const [loginUserImage, setLoginUserImage] = useState(localStorage.getItem("contact_image"));
  const [loginUserEmail, setLoginUserEmail] = useState(localStorage.getItem("email"));
  const [loginUserName, setLoginUserName] = useState(localStorage.getItem("full_name"));
  const [preEstiPageDisable, setPreEstiPageDisable] = useState(false);
  const isPremiumEstimateEdit = useSelector((state) => state.customer.isPremiumEstimateAccess);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [recentLoginUserList, setRecentLoginUserList] = useState([]);
  const {themeColor, leftPanelTab} = useSelector((state) => state.customer)

  const logout = () => {
    setshowMyProfile(false);

    var chatUserId = localStorage.getItem("chatUserId");
    var language = localStorage.getItem("language");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    dispatch(userLogout());
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    localStorage.setItem('selectedTheme', LightTheme ? "light" : "dark");
    localStorage.setItem("language", language);
    navigate("/login");
    dispatch(
      leftPanelFilters('', '', 'all',)
    );
  }
  const handleSwitchLogin = async() => {
    setshowMyProfile(false);

    
    let email = "";
    email = localStorage.getItem("email");
    var chatUserId = localStorage.getItem("chatUserId");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    const archieve = JSON.parse(localStorage.getItem("adminArchieve"));
    const projectId = localStorage.getItem('selectedOffice');
    await archieveIDBCollections(email);
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    archieve.forEach((item) => {
      localStorage.setItem(item.key, item.value);
      if (item.key == "fav_dashboard") {
        favoriteDdashboardSelected(item.value);
      }
    });
    //Get Admin's Email
    email = localStorage.getItem("email");
    dispatch(adminLoginAsStaff(false));
    localStorage.removeItem("adminArchieve");
    await clearDatabase();
    await loadDataToIDB(localStorage.getItem("staff_id"))
    await restoreIDBSession(email);
    localStorage.setItem('selectedOffice', projectId)
    dispatch(showFullScreenLoader());
    dispatch(setSpaceList(JSON.parse(localStorage.getItem("spaces"))));
    dispatch(isPremuimEstimateDisable());
    setTimeout(() => {
      dispatch(connectToSocket());
    }, 500);
    dispatch(leftPanelFilters("","","all"))
    dispatch(getGroupDetails({}));
    dispatch(lastSessionOfChat([]));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(setSelectedGloablView("listView"));
    endVapiCall();
    dispatch(setAssistantCallID());
    dispatch(clearConverstion());
    dispatch(getTaskIdAfterCall(false));
    dispatch(setApiLoaderShow(false));


    dispatch(setLeftPanelTab("my-space"))
    showLeftPanelNew();
    setshowMyProfile(false);
    navigate("/redirect", {
      state: {
        url: "/",
      }
    });
  }
  let selectedtheme = localStorage.getItem('selectedTheme') == "light" ? true : false

  const [LightTheme, setLightTheme] = useState(selectedtheme);
  const [PauseMenu, setPauseMenu] = useState(false);
  const isAdmin = localStorage.getItem("admin");

  useEffect(() => {
    setHideLogoutSwitch(adminAuth);
    getUserLoginSessions();
  }, [adminAuth]);

  useEffect(() => {
    if (localStorage.getItem('selectedTheme') == "light") {
      jquery('html').addClass('light-theme');
      setLightTheme(true)
    } else {
      jquery('html').removeClass('light-theme');
      setLightTheme(false)
    }
  }, [LightTheme, selectedtheme]);

  useEffect(() => {
    setLoginUserImage(localStorage.getItem("contact_image"))
    setLoginUserName(localStorage.getItem("full_name"))
    setLoginUserEmail(localStorage.getItem("email"))
  }, [localStorage.getItem("contact_image"), localStorage.getItem("email"), localStorage.getItem("full_name")]);

  useEffect(() => {
    setPreEstiPageDisable(false)
    if (isPremiumEstimateEdit == true) {
      setPreEstiPageDisable(true)
    }
  }, [JSON.stringify(isPremiumEstimateEdit)])

  const setTheme = (theme) => {
    CustomerServices.setUserTheme(theme ? 1 : 0).then((res) => {
      localStorage.setItem('selectedTheme', theme ? "light" : "dark");
      setLightTheme(theme);
      dispatch(setProjectTheme(theme ? "light" :  "dark" ))
      setshowMyProfile(false);
    });
  }
  useEffect(() => {
    if (themeColor == "light") {
      setLightTheme(true);
    }else{
      setLightTheme(false);
    }

    
  }, [themeColor])
  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem('language', language);
    setCurrentLanguage(language);
    CustomerServices.setUserLanguage(language);
  }

  const relecentloginHandler = (email, clientId, project_id = 0, staff_id) => {
    dispatch(showFullScreenLoader());
    dispatch(
      loginAsUser(email, clientId, project_id)
    ).then(async() => {

      if (!localStorage.getItem("accessToken")) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
        );
      } else {
        localStorage.setItem("password", "12345");
        await clearDatabase();
        await restoreIDBSession(email);

        await loadDataToIDB(staff_id);
        if (localStorage.getItem("membership_package_id") == 1) {
           navigate("/myprofile");
        } else {
          const client_id = localStorage.getItem("client_id");
          const contact_id = localStorage.getItem("contact_id");
          localStorage.setItem("selectedOffice",project_id);
          dispatch(getCustomerSpaces(client_id, contact_id));
          dispatch(adminLoginAsStaff(true));
          dispatch(projectTaskCounts(project_id));
          navigate("/redirect", {
            state: {
              url: "/",
            },
          });
        }
      }
    });
  };

  const getUserLoginSessions = async() => {
    if(isAdmin || adminAsStaff || hideLogoutSwitch)
    {
      try {
        const response = await AuthServices.getUserRecentSessions();
        if(response && response.data && response.data.length)
        {
          setRecentLoginUserList(response.data);
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }
  };

  const discardExternalBenchmark = () => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_discard_external_benchmark"), _l("l_discard"), _l("l_cancel"), () => {}, async() => {
      try {
        const response = await BenchmarkServices.discardExternalBenchmark(hash);
        if(response.status)
        {
          navigate("/login");
          localStorage.clear();
          localStorage.setItem("version", process.env.REACT_APP_VERSION);
          window.location.reload();
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }))
  };

  const handleChangeTab = (tab, navigation) => {
    localStorage.setItem("currentLeftPanelTab", tab);
    dispatch(commanoffcanvasisOpen(false));

    dispatch(setLeftPanelTab(tab));
    if (navigation) {
    navigate(navigation);
    }
    
    if (tab == "my-contacts") {
    } else {      
      dispatch(setOpenOrCloseContactDirectoryChat(false));
      setContactlistShow(false);
      localStorage.removeItem("isContactDir");
    }

    if (tab == "my-calendar") {
      dispatch(setSelectedGloablView("calendarView"));
        dispatch(dayGridDay(false));
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(true));
        dispatch(setDaterangeFilterApplied(true));
        dispatch(leftPanelFilters("","","applyForMeAndMonth"));
        localStorage.setItem("fromDate",formateDate(moment(new Date()).startOf('month')));
        localStorage.setItem("toDate", formateDate(moment(new Date()).endOf('month')));
    } else {
      dispatch(dayGridDay(true));
      dispatch(dayGridWeek(false));
      dispatch(dayGridMonth(false));
      handleChangeViewtoFavView();
    }
    hideRightPanelNew();
    showLeftPanelNew();

    if (tab == "my-request") {
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }else{
      dispatch(setDaterangeFilter("today"))
      dispatch(setDaterangeFilterApplied(false));
      dispatch(leftPanelFilters("","","all"))
    }
    setshowMyProfile(false)

  }

  return (
    <>
    <div className={`myprofilesetting-menu-main w275px myprofilesetting-menu-main-close`} id="profile-menu">
    <ul aria-labelledby="headerUserDropdown">
      <li className="HeaderDropdownTitle">
        <a href="#/" className="dropdown-item no-hover" onClick={() => {
          if(isExternalHeader){}else{
          if (preEstiPageDisable == true) {
            return;
          }
          navigate("/myprofile");
          setshowMyProfile(false)
        }
        }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <div className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box">
                <div
                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                  style={{ backgroundImage: `url('${ isExternalHeader ? profileImageURL :loginUserImage}')` }}
                ></div>
              </div>
            </div>
            <div className="ps-2 w100minus40 title-fonts d-flex  flex-column">
              <div className="c-list-detail c-font f-14  fw-semibold text-truncate color-white">
               {isExternalHeader ? user_name && user_name != "" ? user_name : _l('l_welcome_myr_ai') : loginUserName}
                <div className="badge badge-white  lh-1 ms-2 px-2 py-1 rounded-pill "><span className="">v {process.env.REACT_APP_VERSION}</span></div>
              </div>
              <div className="c-font f-10 color-white-60 text-truncate">
                {isExternalHeader ?  userDescription : loginUserEmail}
              </div>
            
            </div>
          </div>
          {
            isExternalHeader ?
          <div className="pt-15">
                <Button className="w-100" onClick={discardExternalBenchmark}>{_l("l_discard")}</Button>
          </div>
                :
                <></>
              }
        </a>
      </li>
      <li>
        <hr className="dropdown-divider m-0" />
      </li>

        {isExternalHeader ? 
          <></> 
        :
          <li className=" position-relative">
          <div className="dropdown position-relative">
              <a href="#/" className="dropdown-item text-truncate  position-relative" type="button"
                id="CategoryFilterDropdown"
                data-bs-toggle="dropdown">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="c-font f-16 me-2">
                        {LANGUAGE_WITH_FLAG_JSON.find(lang => lang.language == currentLanguage).flag}
                    </div>
                    <div className="text-truncate">{_l(LANGUAGE_WITH_FLAG_JSON.find(lang => lang.language == currentLanguage).name)}</div>
                  </div>
                  <CaretDown size={15} className="c-icons m-0 opacity-50" weight="fill" />
                </div>  
              </a>
              <GeneralListing widthClass="w-280"
                list={LANGUAGE_WITH_FLAG_JSON && LANGUAGE_WITH_FLAG_JSON.map((item) => {
                  return {
                    name: _l(item.name),
                    flag : item.flag,
                    language : item.language
                  };
                })}

                handler={(data) => {
                  setLanguage(data.language);
                }}
                currentLanguage = {currentLanguage}
                type = "languageWithFlag"
              />
            </div>
          </li>
        }

      {isExternalHeader ? <></> :
      <li className={` ${contact_role == 3 ? "d-none" : ''}`}>
        <a className ={`dropdown-item on-hover-active-toggle-img text-on-hover-active-green ${leftPanelTab == "reporting-page" ? "active" : ""}`}
           
           
           
           
          onClick={() => {
            localStorage.setItem("currentLeftPanelTab", "reporting-page");
            dispatch(setLeftPanelTab("reporting-page"));
            setSelectedSpace({});
            dispatch(setFetchedTaskandMessages([]))
            dispatch(commanoffcanvasisOpen(false)) 
            dispatch(SetSelectedTask({}));
            TaskModalClose();
            dispatch(getGroupDetails({})); 
            if ((["operator","contact"].includes(userType) && contact_role != 3) || userType == "staff") {
              if (!contactlistShow) {
              dispatch(leftPanelFilters("","","topicFilterClear"))
              }
              navigate("/dashboard");
              dispatch(setDashBoardView("spaceView"))
              localStorage.removeItem("selectedOffice")
              localStorage.removeItem("selectedVirtualSpace")
              dispatch(setBulkTaskRating([]))
              setSelectedProject(0)
            }else{
              selectLiteVersion()
            }
            if (contactlistShow) {
              setContactlistShow(false)
              localStorage.removeItem("isContactDir")
            }
            setshowMyProfile(false)
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Gauge size={18} weight="light" className="c-icons me-2" />
              <div>{_l('l_reporting')}</div>
            </div>
          </div>
        </a>
      </li>}

      {isExternalHeader ? <></> :
      <li className="d-none">
        <a className="dropdown-item"
           
           
           
           
          onClick={() => {
            if(disabledThirdPartyToolAccess)
            {
              return false;
            }
            accessPremiumFeature(0, () => {
              setSettingTopicName("l_third_party_integration");
              setTopicMainKey("third_party_integrations");
            }, true);
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex ">
                {userType == "staff" ?
                  <EnvelopeSimple size={18} weight="light" className="c-icons me-2" />
                  :
                  <BezierCurve size={18} weight="light" className="me-2" />}
                <div className={`${userType == "staff" ? "" : "text-truncate w-70"}`}>
                  {userType == "staff" ? _l("l_email") : _l("l_third_party_integration")}
                </div>
              </div>
              <div className="d-flex mlminus10">
                {unreadComments ?
                  <div class="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill" >
                    <span class="m-auto ps-0 ">{unreadComments}</span>
                  </div>
                  : <></>}
                {unreadMessages ?
                  <div class="ms-1 with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill" >
                    <span class="m-auto ps-0 ">{unreadMessages}</span>
                  </div> : <></>}
              </div>
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader && localStorage.getItem("user_type") != "staff"  ? <></> :
      <li className={`${ parseInt(contact_role) != 1 ? "d-none" : ''}`}>
        <a className="dropdown-item"
         
         
         
         
        onClick={() => {
          if(!disabledThirdPartyToolAccess)
          {
            navigate("/premiumsubscription");
            setshowMyProfile(false);
          }
        }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <CurrencyEur size={18} weight="light" className="c-icons me-2" />
              <div>{_l('l_my_plan')}</div>
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader ? <></> :
      <li className={`${userType == "operator"  &&  (proposalUnreadComments > 0 || localStorage.getItem("is_registered") == 1 || localStorage.getItem("is_agent") == 1) ? "" : "d-none"}`}>
        <a className="dropdown-item"
           
           
          onClick={() => {
            if (localStorage.getItem("is_agent") == 1) {
              navigate("/onboarding-proposal")
              dispatch(leftPanelFilters("","","all"));
              hideContactDirectory();
              dispatch(setOpenOrCloseContactDirectoryChat(false));
            }else{
              navigate("/proposals", { state: { from: viewPage } });
            }
            setshowMyProfile(false);
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div className="d-flex ">
                <Rows size={18} weight="light" className="c-icons me-2" />
                <div>{localStorage.getItem("is_agent") == 1 ? _l('l_agent_offers') : _l('l_proposals')}</div></div>
              {
                proposalUnreadComments > 0
                ?
                <div class="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill" >
                  <span class="m-auto ps-0 ">{proposalUnreadComments}</span>
                </div>
                :
                <React.Fragment></React.Fragment>
              }
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader ? (<></>):(
      <li>
        <a href="#/" className={`dropdown-item text-truncate  ${preEstiPageDisable == true ? 'for-disabled' : ''}`} onClick={() => {
          if (preEstiPageDisable == true) {
            return;
          }
          navigate("/myprofile");
          setshowMyProfile(false);
        }}>
          <div className="d-flex align-items-center">
            <div className="" >
              {userType == "staff" ?
                <PencilSimpleLine size={18} className="c-icons me-2" weight="light" /> 
              :
                <GearSix size={18} weight="light" className={`c-icons me-2`} />
              }
              </div>
            <div className="text-truncate">{userType == "staff" ? _l('l_edit_profile') :_l('l_setting') + ' & ' +_l('l_edit_profile')}</div>
          </div>
        </a>
      </li>
      )}
      <li>
        <hr className="dropdown-divider m-0" />
      </li>
      {isAdmin || adminAsStaff || hideLogoutSwitch   ?
        <li>
         <div className="dropdown">
            <a href="#/" className="dropdown-item text-truncate" type="button"
              id="CategoryFilterDropdown"
              data-bs-toggle="dropdown">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div
                    className="me-2">
                    <UserSwitch size={20} className="c-icons" weight="light" />

                  </div>
                  <div className="text-truncate">{_l('l_recent_login')}</div>
                </div>
                <CaretDown size={15} className="c-icons m-0 opacity-50" weight="fill" />
              </div>  
            </a>
            <GeneralListing widthClass="w-280"
              list={recentLoginUserList && recentLoginUserList.map((item) => {
                return {
                  name: item.fullname,
                  image: item.profile_image_url,
                  email: item.email,
                  client_id: item.client_id,
                  project_id: item.project_id,
                  is_primary_user: item.is_primary_user,
                  staff_id: item.staff_id
                };
              })}

              handler={(data) => {
                relecentloginHandler(data.email, data.client_id, data.project_id, data.staff_id)
              }}
              placeHolderName='No login user'
            />
          </div>
        </li>   
        :
          <React.Fragment></React.Fragment>
        }
      {isExternalHeader ? <></> :
      <li className="" onClick={() => setTheme(!LightTheme)}>
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Sun size={18} className="c-icons me-2" weight="light" />
              <div>{_l('l_appearance')}:{LightTheme ? _l('l_light') : _l('l_dark')}</div>
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={LightTheme}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                setTheme(!LightTheme);
                
              }}
            />
          </div>
        </div>
      </li>}
      {isExternalHeader ? (<></>):(<>
      <li className="">
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Wrench size={18} className="c-icons me-2" weight="light" />
              {_l('l_tools_information')}
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={showToolsModal}
              onChange={() => {
                setShowToolsModal(!showToolsModal);
              }}
            />
          </div>
        </div>
      </li>
      </>
      )}
      {PauseMenu ? (
        <React.Fragment>
          <li>
            <a href="#/" className="dropdown-item text-truncate">
              <div className="d-flex">
                <div className="me-2"> <GearSix size={20} className="c-icons" weight="light" /> </div>
                <div className="text-truncate">{_l('l_setting')}</div>
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="dropdown-item text-truncate">
              <div className="d-flex">
                <div
                  className="me-2">
                  <Question size={20} className="c-icons" weight="light" />
                </div>
                <div className="text-truncate">{_l('l_help')}</div>
              </div>
            </a>
          </li>
        </React.Fragment>
      ) : (<></>
      )}
      {isExternalHeader ? (<></>):(<>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li>
        {
          adminAsStaff || hideLogoutSwitch ?
            <>
              <a href="#/" className="dropdown-item text-truncate" onClick={handleSwitchLogin}>
                <div className="d-flex">
                  <div
                    className="me-2">
                    <SignOut size={20} className="c-icons" weight="light" />
                  </div>
                  <div className="text-truncate">{_l('l_switch_to_admin')}</div>
                </div>
              </a> 
            </>
            :
            <a href="#/" className="dropdown-item text-truncate" onClick={logout}>
              <div className="d-flex align-items-center">

                <div
                  className="me-2">
                  <SignOut size={18} className="c-icons" weight="light" />
                </div>
                <div className="text-truncate">{_l('l_logout')}</div>
              </div>
            </a>
        }
      </li>
      </>
      )}
      {
        showToolsModal ?
          <ToolsInformationModal
            show={showToolsModal}
            handleClose={() => {
              setShowToolsModal(false);
            }}
          />
          :
          <></>
      }
    </ul>
        </div>
    </>
  )
}

export default MyProfileDropDownNew
import React, { useState } from "react";
import { _l } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { File, Files, Plus } from "phosphor-react";
import LeaseModal from "../../Modals/LeaseModal";
import { setSelectedFloorIdsForLease } from "../../../actions/benchmark-actions/benchmark-actions";
import leaseServices from "../../../services/lease-services";

export function FloorListing({
  setFloor,
  buttonText,
  getSelectedItemsCountForFloor,
  getAvailableItemsCountForFloor,
  spaceDetail
}) {

  const dispatch = useDispatch();


  const userType = localStorage.getItem("user_type");
  const { floorDetails, floorIds } = useSelector((state) => state.benchmarkReducer);
  const [selectedFloorIds, setSelectedFloorIds] = useState(floorIds);
  const [showLeaseModal, setshowLeaseModal] = useState(false);
  const [leaseDetails, setleaseDetails] = useState({});
  const [documents, setDocuments] = useState([]);
  const [subLeaseId, setSubLeaseId] = useState(0);



  let defaultTypeOfSpace = floorDetails.type_of_space.find((w) => w.is_default == 1);

  const handleFloorSelection = (floorId) =>{
    let index = selectedFloorIds.findIndex((id) => id == floorId)
    if (index > -1) {
      dispatch(setSelectedFloorIdsForLease(selectedFloorIds.filter((id) => id != floorId)));
      setSelectedFloorIds(selectedFloorIds.filter((id) => id != floorId))
    } else {
      dispatch(setSelectedFloorIdsForLease(selectedFloorIds.concat([floorId])));
      setSelectedFloorIds(selectedFloorIds.concat([floorId]))
    }
  }

  const getLeaseDeatils = (floorlease_id, floor_sub_lease_id) => {
    leaseServices.getProjectSubLease(spaceDetail.project_id ,floorlease_id,floor_sub_lease_id).then((res)=>{
      if (res && res.status) {
        if (res.data && res.data.length  > 0) {
          let floorids = res.data[0].lease_area.map((floor) => {
            return floor.floor_id
          })
          setSubLeaseId(floor_sub_lease_id)
          setDocuments(res.data[0].documents && res.data[0].documents.length > 0 ? res.data[0].documents : [])
          setSelectedFloorIds(floorids)
          setleaseDetails(res.data[0]);
          setshowLeaseModal(true);
        }
      }
    })
  }
  
  
  return (
    <>
    <div className="row m-0 gy-2 gx-0">
      {[_l("l_assign"), _l("l_update")].includes(buttonText) && getAvailableItemsCountForFloor && getAvailableItemsCountForFloor(-1) ? (
        <div className=" col-xl-12 c-box-shadow-box" onClick={() => setFloor({id: -1})}>
          <div className="bg-white-03 on-hover-bg-white-05 p-2 radius_5 w-100 cursor-pointer lightthemebgblue">
            <div class="align-items-center d-flex justify-content-between">
              <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60 w100minus100per">
                {defaultTypeOfSpace ? defaultTypeOfSpace.title : ""}
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center w-100 ms-auto">
                  {getSelectedItemsCountForFloor &&
                  getAvailableItemsCountForFloor ? (
                    <div className="d-flex align-items-center overflow-auto mx-2">
                      <div className="d-flex ms-auto px-2">
                        <div className="left-box with_separator_10">
                          <div className="title-fonts fw-semibold text-center c-font f-13">
                            {getAvailableItemsCountForFloor
                              ? getAvailableItemsCountForFloor(-1)
                              : ""}
                          </div>
                          <div className="c-font f-11 opacity-50">
                            {_l("l_total")}
                          </div>
                        </div>
                        {
                          userType === "operator"
                          ?
                          <div className="left-box with_separator_10 pe-0">
                            <div className="title-fonts fw-semibold text-center c-font f-13">
                              {getSelectedItemsCountForFloor
                                ? getSelectedItemsCountForFloor(-1)
                                : ""}
                            </div>
                            <div className="c-font f-11 opacity-50">
                              {_l("l_selected")}
                            </div>
                          </div>
                          :
                          <React.Fragment></React.Fragment>
                        }
                      </div>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <a href="#/"
                    className="btn btn-white-05 btn-sm border-0"
                    onClick={() => setFloor({id: -1})}
                  >
                    {buttonText || _l("l_setup_floor")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <div className="row m-0 gx-0">
      {floorDetails.floor.map((floor, index) => {
        if(getAvailableItemsCountForFloor && !getAvailableItemsCountForFloor(floor.id))
        {
          return <React.Fragment></React.Fragment>
        }
        return (
          <div
            key={index}
            className=" col-xl-12 m-0"
          >
            <div className="task-list-card list-view p-10 cursor-pointer light-theme-border">
              <div class="align-items-center d-flex justify-content-between">
                <div className="form-check mb-1">
                  <input className="form-check-input mt-1" 
                  type="checkbox" 
                  id={`floorcheckbox`}
                  value={floor.id}
                  checked={selectedFloorIds.some((id) => id == floor.id)}
                  onChange={(e)=>{
                    handleFloorSelection(floor.id)
                  }}
                  />
                </div>
                <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60 w100minus100per ps-10px">
                  {floor.title}
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center w-100 ms-auto">
                    {getSelectedItemsCountForFloor &&
                    getAvailableItemsCountForFloor ? (
                      <div className="d-flex align-items-center overflow-auto mx-2">
                        <div className="d-flex ms-auto px-2">
                          <div className="left-box with_separator_10">
                            <div className="title-fonts fw-semibold text-center c-font f-13">
                              {getAvailableItemsCountForFloor
                                ? getAvailableItemsCountForFloor(floor.id)
                                : ""}
                            </div>
                            <div className="c-font f-11 opacity-50">
                              {_l("l_total")}
                            </div>
                          </div>
                          {
                            userType === "operator"
                            ?
                            <div className="left-box with_separator_10 pe-0">
                              <div className="title-fonts fw-semibold text-center c-font f-13">
                                {getSelectedItemsCountForFloor
                                  ? getSelectedItemsCountForFloor(floor.id)
                                  : ""}
                              </div>
                              <div className="c-font f-11 opacity-50">
                                {_l("l_selected")}
                              </div>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                          }
                        </div>
                      </div>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <a href="#/"
                      className="btn btn-white-05 btn-sm border-0 h32wauto d-flex align-items-center"
                      onClick={() => setFloor(floor)}
                    >
                      {buttonText || _l("l_setup_floor")}
                    </a>
                   {floor.sub_lease_id > 0 ?
                    <a href="#/"
                      className="btn btn-white-05 btn-sm border-0 h32w32 p-0 d-flex align-items-center ms-10px"
                      onClick={()=>{
                        getLeaseDeatils(floor.lease_id, floor.sub_lease_id)
                      }}
                    >
                      <Files size={18} weight="light" className="c-icons" />
                    </a> : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      </div>
    </div>
    {
      showLeaseModal ?
      <LeaseModal
      show={showLeaseModal}
      handleClose={()=>{
        setshowLeaseModal(false);
        setSelectedFloorIds([]);
        setSubLeaseId(0);
      }}
      spaceDetail={spaceDetail}
      isFrom={"floor-list"}
      leaseDetails={leaseDetails}
      selectedFloorIds={selectedFloorIds}
      setSelectedFloorIds={setSelectedFloorIds}
      detailsdocuments={documents}
      lease_sub_id={subLeaseId}
      /> : <></>
    }
    </>
  );
}

export default FloorListing

import DatePicker from "react-datepicker";
import Select from "react-select";
import { _l , getDatePickerLocale } from "../../../../hooks/utilities";
import React, { useState } from "react";
import { CalendarBlank } from "phosphor-react";


const InformationPaytabs = ({ data, contacts, selectedContactEmail, setSelectedContactEmail, handleSubscriptionBody }) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="">
      <div className="row">
        <div className="col-xl-6 py-2">
          <Select
          placeholder={_l("l_please_select")}
            className="custom-select-menu"
            classNamePrefix="react-select"
            options={contacts}
            closeMenuOnSelect={true}
            value={selectedContactEmail}
            onChange={(value) => {
              setSelectedContactEmail(value);
              handleSubscriptionBody('ContactEmail', value)
            }}
          />
        </div>
        <div className="col-xl-6 py-2 d-none">
          <div className="white-border-box color-white-60 p-2 h-100 form-control d-flex  ">
            <a href="#/" className="custom-datepicker datepicker-input w-100 ms-2 c-font">
              <div className="d-flex justify-content-between align-items-center">
                <DatePicker
                  showTimeSelect
                  dateFormat="dd/MM/yyyy"
                  locale={getDatePickerLocale()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  placeholderText={`${_l(
                    "l_select_date"
                  )}`}
                  calendarStartDay={1}
                ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                <CalendarBlank size={20}  weight="light" className="c-icons" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="">
        <div className=" d-flex flex-column">
          <div className="comman-content-scroll-wrapper">
            <div className="comman-content-scroll">
              <div className="comman-data-table">
                <table className="dataTable">
                  <thead className="bg-transparent p-0">
                    <tr className="p-0">
                      <th className="p-0" style={{ width: "10%" }}></th>
                      <th className="p-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-0 align-top">{_l("l_task_type")}:</td>
                      <td className="max-width-100 text-truncate border-0">
                        <div className="color-white-60 pt-2 c-font f-12">
                          {data ? data.task_type : ""}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-0 align-top">{_l("l_frequency")}:</td>
                      <td className="max-width-100 text-truncate border-0">
                        <div className="color-white-60 pt-2 c-font f-12">
                          {data ? data.frequency : ""}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="border-0 align-top">{_l("l_region_label")}:</td>
                      <td className="max-width-100 text-truncate border-0">
                        <div className="color-white-60 pt-2 c-font f-12">
                          {data ? data.region : ""}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InformationPaytabs;

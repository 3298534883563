import Vapi from "@vapi-ai/web";
import {
  _l,
  addexpandedClassIntoOffice,
  isCamAllowed,
  isMicAllowed,
  isMicFound,
  showLeftPanelNew,
  TaskModalClose,
} from "../hooks/utilities";
import { showMessage } from "./messages";
import {
  commanoffcanvasisOpen,
  setCallDisconnect,
  setCallFullscreen,
  setCallRecording,
} from "./customer";
import store from "../store";
import { SET_CALL_STATUS } from "./chat-action-type";
import {
  playCallAudio,
  playCallEndAudio,
  setCallisRecived,
  setMyStream,
  setRemoteStream,
  stopCapturingMedia,
} from "./chat";
import { CLEAR_PARTIALLY_CONVERSIONS, CLEAR_TRANSCRIPT, GET_ASSISTANT_CALL_TASK_ID, GET_PARTIALLY_CONVERSIONS, GET_TRANSCRIPT, IS_ASSISTANT_CALL, SET_CALL_ID } from "./vapi-action-type";

let privateKey = process.env.REACT_APP_VAPI_PRIVATE_KEY;

const vapi = new Vapi(privateKey);

const onSpeechStart = () => {
};
const onSpeechEnd = () => {
};

const onCallStartHandler = () => {
};

const onCallEnd = (e) => {
  endVapiCall(true);
  console.log("auto call ended.........","Call has stopped");
  localStorage.removeItem("assistantCallerID");
  store.dispatch(
    showMessage("unsucess", _l("l_call_status"), _l("l_call_ended"))
  );
  showLeftPanelNew();
};

const onVolumeLevel = () => {
};

const onMessageUpdate = (message) => {
  store.dispatch(setTranscript(message));
};

const onError = (e) => {
  console.error(e);
  console.log("!!!  ERROR !!!!", e);
  store.dispatch(
    showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
  );
};

vapi.on("speech-start", onSpeechStart);
vapi.on("speech-end", onSpeechEnd);
vapi.on("call-start", onCallStartHandler);
vapi.on("call-end", onCallEnd);
vapi.on("volume-level", onVolumeLevel);
vapi.on("message", onMessageUpdate);
vapi.on("error", onError);

export const startCall = async () => {
  store.dispatch(setAssistantCallID());
  store.dispatch(getTaskIdAfterCall(false));


  let assistantCallerID = localStorage.getItem("assistantCallerID")
    ? localStorage.getItem("assistantCallerID")
    : null;
  if (assistantCallerID) {
    let callerinfo = JSON.parse(localStorage.getItem("callerInfo"))
      ? JSON.parse(localStorage.getItem("callerInfo"))
      : {};
    let selectedTopicForCall = localStorage.getItem("selectedTopicForCall")
      ? JSON.parse(localStorage.getItem("selectedTopicForCall")) : localStorage.getItem("selectedTopic") ? JSON.parse(localStorage.getItem("selectedTopic")) :localStorage.getItem("callerInfo") ? JSON.parse(localStorage.getItem("callerInfo"))
      : {};

    const micFound = await isMicFound();
    let mediaStream ;

    if (!micFound) {
      store.dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          "Can not found mic in your system"
        )
      );
    } else {
        mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
      window.localStream = mediaStream;
      store.dispatch(setMyStream(mediaStream));
      const micAllowed = await isMicAllowed();
      const camAllowed = await isCamAllowed();


      if (!micAllowed) {
        store.dispatch(
          showMessage("unsucess", _l("l_error"), "Please allow mic permission")
        );
      }
      else {
        store.dispatch(setCallDisconnect(false));
        store.dispatch(setRemoteStream(false));

        let data = {};
        data.status = "initCall";
        data.callRole = "caller";
        data.role = 4;
        data.receiverInfo = {
          fname: selectedTopicForCall.title,
          lname: "",
          displayPicture: selectedTopicForCall.image,
          pushNtfIds: [],
          myrUserType: "client",
        };
        store.dispatch({
          type: SET_CALL_STATUS,
          payload: data,
        });
        const response = vapi.start(assistantCallerID);
        response.then((res) => {   
          if (res) {
            store.dispatch(setAssistantCallID(res.id));
            let data = {
              callerInfo: {
                fname: localStorage.getItem("firstname"),
                lname: localStorage.getItem("lastname"),
                displayPicture: localStorage.getItem("contact_image"),
                _id: localStorage.getItem("chatUserId"),
              },
              receiverInfo: {
                fname: selectedTopicForCall.title,
                lname: "",
                displayPicture: selectedTopicForCall.image,
                pushNtfIds: [],
                myrUserType: "client",
                isCameraOff: true,
              },
              role: 4,
              slug: "initCall",
              status: "inProgress",
              callRole: "caller",
            };
            store.dispatch(setCallisRecived(true));
            store.dispatch({
              type: SET_CALL_STATUS,
              payload: data,
            });
            store.dispatch({
              type: IS_ASSISTANT_CALL,
              payload: true,
            });
            store.dispatch(setRemoteStream(false));
          } else {
            endVapiCall();
          }
        });
      }
    }
  } else {
    store.dispatch(
      showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
    );
  }
};

export const endVapiCall = (isAuto = false) => {
  if (isAuto === false) {
    console.log("manual call ended");
  }

  store.dispatch({
    type: IS_ASSISTANT_CALL,
    payload: false,
  });
  let callCurrentStatus = store.getState().chat.callStatus.status
  if (callCurrentStatus == "inProgress") {
    store.dispatch(getTaskIdAfterCall(true));
  }
  
  store.dispatch({
    type: SET_CALL_STATUS,
    payload: {},
  });
  
  TaskModalClose();
  clearConverstion();
  store.dispatch(stopCapturingMedia());
  store.dispatch(setCallDisconnect(true));
  store.dispatch(setCallRecording(false));
  store.dispatch(setCallFullscreen(false));
  store.dispatch(commanoffcanvasisOpen(false));
  store.dispatch(setMyStream([]));
  store.dispatch(setCallisRecived(false));
  store.dispatch(playCallAudio("stop"));
  store.dispatch(playCallEndAudio());


  if (store.getState().customer.leftPanelTab == "homepage") {
    addexpandedClassIntoOffice();
  }

  showLeftPanelNew();
  vapi.stop();
};

export const isMuted = () => {
  vapi.isMuted();
};

export const setMicMuteOrUnmute = (flag) => {
  vapi.setMuted(flag);
};

export const setTranscript =
  (data = {}) =>
  (dispatch) => {
    dispatch({
      type: GET_TRANSCRIPT,
      payload: data,
    });
    dispatch({
      type: GET_PARTIALLY_CONVERSIONS,
      payload: data,
    });
  };

  export const clearConverstion = () => (dispatch) =>{
    dispatch({
      type: CLEAR_PARTIALLY_CONVERSIONS,
      payload: [],
    })
    dispatch({
      type: CLEAR_TRANSCRIPT,
      payload: [],
    })
  }

  export const setAssistantCallID = (id = "") => (dispatch) => {
    dispatch({
      type: SET_CALL_ID,
      payload: id,
    })

  }

  export const getTaskIdAfterCall = (flag = false) => (dispatch) => {
    dispatch({
      type: GET_ASSISTANT_CALL_TASK_ID,
      payload: flag,
    })

  }


import React from 'react';
 

function SmileyIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0323 9.75099C11.171 9.51216 11.0897 9.20618 10.8509 9.06756C10.6121 8.92894 10.3061 9.01018 10.1675 9.24901L11.0323 9.75099ZM9.50001 10.5961L9.74948 11.0294L9.74955 11.0294L9.50001 10.5961ZM6.49979 10.5961L6.25025 11.0294L6.25032 11.0294L6.49979 10.5961ZM5.83233 9.249C5.6937 9.01017 5.38772 8.92894 5.14889 9.06757C4.91007 9.2062 4.82884 9.51218 4.96747 9.751L5.83233 9.249ZM8 14.5C11.5898 14.5 14.5 11.5898 14.5 8H13.5C13.5 11.0376 11.0376 13.5 8 13.5V14.5ZM14.5 8C14.5 4.41015 11.5898 1.5 8 1.5V2.5C11.0376 2.5 13.5 4.96243 13.5 8H14.5ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8H2.5C2.5 4.96243 4.96243 2.5 8 2.5V1.5ZM1.5 8C1.5 11.5898 4.41015 14.5 8 14.5V13.5C4.96243 13.5 2.5 11.0376 2.5 8H1.5ZM5.75 8C6.44035 8 7 7.44035 7 6.75H6C6 6.88807 5.88807 7 5.75 7V8ZM7 6.75C7 6.05965 6.44035 5.5 5.75 5.5V6.5C5.88807 6.5 6 6.61193 6 6.75H7ZM5.75 5.5C5.05965 5.5 4.5 6.05965 4.5 6.75H5.5C5.5 6.61193 5.61193 6.5 5.75 6.5V5.5ZM4.5 6.75C4.5 7.44035 5.05965 8 5.75 8V7C5.61193 7 5.5 6.88807 5.5 6.75H4.5ZM10.25 8C10.9403 8 11.5 7.44036 11.5 6.75H10.5C10.5 6.88806 10.3881 7 10.25 7V8ZM11.5 6.75C11.5 6.05964 10.9403 5.5 10.25 5.5V6.5C10.3881 6.5 10.5 6.61194 10.5 6.75H11.5ZM10.25 5.5C9.55965 5.5 9 6.05965 9 6.75H10C10 6.61193 10.1119 6.5 10.25 6.5V5.5ZM9 6.75C9 7.44035 9.55965 8 10.25 8V7C10.1119 7 10 6.88807 10 6.75H9ZM10.1675 9.24901C9.94714 9.62861 9.63093 9.9437 9.25046 10.1628L9.74955 11.0294C10.2817 10.7229 10.7241 10.2821 11.0323 9.75099L10.1675 9.24901ZM9.25054 10.1628C8.87012 10.3818 8.43885 10.4971 7.9999 10.4971V11.4971C8.61401 11.4971 9.21732 11.3358 9.74948 11.0294L9.25054 10.1628ZM7.9999 10.4971C7.56096 10.4971 7.12968 10.3818 6.74926 10.1628L6.25032 11.0294C6.78248 11.3358 7.3858 11.4971 7.9999 11.4971V10.4971ZM6.74933 10.1628C6.36888 9.9437 6.05268 9.62861 5.83233 9.249L4.96747 9.751C5.27576 10.2821 5.71812 10.7229 6.25025 11.0294L6.74933 10.1628Z" fill="#A9B3B9"/>
</svg>
		</React.Fragment>
	)
}

export default SmileyIcon
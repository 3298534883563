import React, { useEffect, useState } from "react";
import moment from "moment";
import $ from "jquery";
import DeleteDropdown from "../Dropdowns/DeleteDropdown";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  ATTACH_BASE_URL,
  USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";
import { _l, hideLeftPanelNew } from "../../hooks/utilities";
import { DotsThreeVertical,PencilSimple, PlayCircle, PauseCircle, FilePdf, FileDoc, ArrowBendUpRight, UserCircle, UserSwitch, UserCirclePlus, UserPlus } from "phosphor-react";
 
import ReactHtmlParser from "react-html-parser";
import jquery from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTask, setSelectedPlanFromAichat } from "../../actions/customer";
import CustomerServices from "../../services/customer-services";
import TaskCard from "../TaskCard";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import ReadUnreadChecks from "../../assets/icons/ReadUnreadChecks";
import ScheduleCard from "../BenchmarkNew/ScheduleCard";
import { useNavigate } from "react-router-dom";
import { fetchMessages } from "../../actions/chat";
const MsgCard = ({ 
  msg, 
  setOpenGallery, 
  setImagePreviewSrc, 
  editMessage, 
  deleteMessage, 
  createTask, 
  taskLoader, 
  assigneeOptions, 
  initReply,
  assigneeDropdownList,
  todoTasksList,
  shareContacts,
  handleSelectedTask,
  getTaskList,
  searchString,
  setSearchString,
  messageList,
  pageName,
  chatAndTasks=[],
  fromReply =false,
  showOptions,
  setshowOptions,
  setUnreadMessage=()=>{},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {groupDetails} = useSelector((state) => state.chat);
  const [showConvertDropdown, setShowConvertDropdown] = useState(false);
  const [showTaskInfo, setShowTaskInfo] = useState(0);
  const [convertTaskId, setConvertTaskId] = useState(0)
  const [convertMessageId, setConvertMessageId] = useState("");
  const [clickedMsgId, setclickedMsgId] = useState(0)
  const [isfromChatPage, setIsfromChatPage] = useState(false);
  const [validUserList, setValidUserList] = useState([])
  var projectId = localStorage.getItem("selectedOffice");
  let spaceSetupPending  =localStorage.getItem("space_setup_pending");
  var chatUserId = localStorage.getItem("chatUserId");
  var msgType = msg.type;
  var isContactDir = groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey == "contact_directory";
  var msgContent =
    msgType != "text" ? encodeURI(ATTACH_BASE_URL + msg.message) : msg.message;
  var mediaClass = msgType == "image" ? "with-media" : "";
  var ownMsgClass = msg.user == chatUserId ? "right-side-msg" : "";
  if (localStorage.getItem("isViewGroup") == 1) {
    ownMsgClass = localStorage.getItem("viewGroupMember1") == msg.user ? "" : "right-side-msg";
  }
  var isOwnMsg = msg.user == chatUserId ? true : false;
  var msgDate = moment(msg.createdAt).format("HH:mm");
  var userImg = msg.senderImage != "" ? encodeURI(ATTACH_BASE_URL + msg.senderImage) : USR_IMG_PLACEHOLDER;
  var readMsgClass = "delivered-single";
  var badgeArray = {
    "task": "l_task",
    "request": "l_request",
    "convert_to_comment": "l_converted_as_comment",
    "convert_to_checklist": "l_converted_as_checklist"
  };
  if (msg.isDelivered == 1) {
    readMsgClass = "delivered";
  }
  if (msg.isRead == 1) {
    readMsgClass = "message-read";
  }
  var senderName = msg.user == chatUserId ? "" : msg.senderName;
  var isChatTask = msg.extraData && !$.isEmptyObject(msg.extraData) && ["task","request","convert_to_comment","convert_to_checklist"].includes(msg.extraData.dataType) ? true : false;

  const viewTask = (taskInfo) => {
    setShowTaskInfo(taskInfo.taskId); 
  }

  function urlify(text) {
    var urlRegex = /(?<!")(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
     }).replace(/<a[\s]+/gm, `<a class="color-green" target="_blank"`);  
  } 
  
  useEffect(() => {
    setIsfromChatPage(false)
    if(showTaskInfo > 0){
      dispatch(getSelectedTask(localStorage.getItem("selectedOffice"),showTaskInfo,0,1));
    }
  }, [showTaskInfo]);
useEffect(() => {
  let list = []
  if(msg && msg.recepients){
    msg && msg.recepients && msg.recepients.forEach((msgUser)=>{
      groupDetails && groupDetails.members && groupDetails.members.forEach((member)=>{
        if(msgUser.user == member._id){
          list.push(msgUser)
        }
      })

    })
  }
  setValidUserList(list) 
}, [JSON.stringify(msg), JSON.stringify(groupDetails)])

useEffect(() => {
  jquery(document).mouseup(function (e) {
   var container = jquery('.chat-page');
   if (!container.is(e.target) && container.has(e.target).length === 0) {
     setshowOptions(0)

   }
 });
})

  const callAddClientMembership = (btn) =>{
    hideLeftPanelNew();
    CustomerServices.addClientMembership(btn.id).then((res) => {
      if (res && res.status) {
      localStorage.setItem("packagePlanID", btn.id);
        if(Number(btn.id) == 5){
          navigate("/onboarding-proposal?tab=newProposal");
        }
        dispatch(fetchMessages(
          localStorage.getItem("chatUserId"),
          localStorage.getItem("activeGroupId"),
          0,
          () => { },
          1,
        ))
      }
    })
  }

  const handleMessageClick = (btn) => {
    if (btn.slug == "find_job") {
      CustomerServices.addClientMembership(btn.id).then((res) => {
        if (res && res.status) {
          navigate("/hire-new-agent?user=agent-find-job");
        }
      })
      localStorage.setItem("packagePlanID", btn.id);
    } else if (btn.slug == "company_training") {
      CustomerServices.addClientMembership(btn.id).then((res) => {
        if (res && res.status) {
          navigate("/hire-new-agent?user=company_training");
        }
      })
      localStorage.setItem("packagePlanID", btn.id);
    } else if (btn.id > 0) {
      switch (Number(btn.id)) {
        case 5:
          callAddClientMembership(btn);
          break;
        case 6:
          callAddClientMembership(btn);
          break;
        case 7:
          navigate("/hire-new-agent");
          localStorage.setItem("packagePlanID", btn.id);
          break;
        default:
          navigate("/space-onboarding");
          localStorage.setItem("packagePlanID", btn.id);
          break;
      }
      dispatch(setSelectedPlanFromAichat(btn));
      localStorage.setItem("packagePlan", JSON.stringify(btn));
    }
  }

  return (
    <div id={`msg-div-${msg._id}`} className={`chat-box width-custom  ${msg.isEdited == 1 ? "edited" : ""} ${ownMsgClass} with-padding ${(msg.extraData && msg.extraData.taskId == showTaskInfo) || (convertTaskId == msg._id) ? "task-active-border" : ""  }`}>
      <div className="d-flex flex-column w-100 " id="msg-card">
        <div className="d-flex align-items-center">
        <div className="h30w30 comman-image-box with-bg rounded-circle bg-white-05 mt-auto mb-20">
          <div
            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
            style={{
              backgroundImage: `url('${userImg}')`,
            }}
          ></div>
        </div>
        <div className="chat-msg">
          {senderName != "" &&
              <div className="mtminus5 c-font f-10 color-white-60 order-3">
                <div className="d-flex align-items-center">
                  <span>{senderName}</span>
                </div>
              </div>
            }
          <div className="d-flex rightsidecontentend  ">
        <UncontrolledDropdown setActiveFromChild={true} isOpen={showOptions == msg._id} direction="start" className={`pe-10px ps-10px ${ownMsgClass ? "order-0" : "order-3"}`} >
                <DropdownToggle id={`messageOptions`} className="border-0 line-height-0  p-0 d-flex align-items-center" onClick={() =>{
                if (showOptions == msg._id) {
                  setshowOptions(0)
                }else{
                  setshowOptions(msg._id)
                }
                }
                }>
                  <div 
                  className=""
                  type="button"
                  id={"commentOptionDropdown-"+msg._id}
                  data-bs-toggle="dropdown"
                  data-bs-outside="true"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                   
                   
                   
                   
                  onClick={() => {
                    setclickedMsgId(msg._id)
                    if (jquery(`.chat-page`).hasClass('show')) {
                      setIsfromChatPage(false)
                      if (clickedMsgId == 0) {
                        setIsfromChatPage(true)
                      } else {
                        setIsfromChatPage(false)
                        setclickedMsgId(0)
                      }           
                    } else {
                      setIsfromChatPage(false)
                    }
                  }}
                >
                  <DotsThreeVertical size={18}  weight="light" className="c-icons" />
                </div>
                </DropdownToggle>
                <DropdownItem toggle={false} className="p-0">
                {showOptions == msg._id ?
                <DeleteDropdown 
                  edit={isOwnMsg && msgType == "text" ? true : false} 
                  handleEdit={editMessage} 
                  newEditId={msg._id} 
                  handleDelete={() => deleteMessage(msg._id)} 
                  widthClass={"w-170"}
                  forCreateTask={msgType == "text" && !isChatTask && !isContactDir ? true : false}
                  createTask={createTask}
                  taskLoader={taskLoader}
                  assigneeOptions={assigneeOptions}
                  displayCopyBtn={msgType == "text" ? true : false}
                  textToCopy={msgContent}
                  isViewTask={isChatTask ? true : false}
                  viewTask={() => viewTask(msg.extraData)}
                  setShowConvertDropdown={setShowConvertDropdown}
                  setConvertMessageId={setConvertMessageId}
                  initReply={initReply}
                  isOwnMsg={isOwnMsg}
                  allowToDelete={isOwnMsg ? true : false}
                  setConvertTaskId={setConvertTaskId}
                  isfromChatPage={isfromChatPage}
                  setIsfromChatPage={setIsfromChatPage}
                  setshowOptions={setshowOptions}
                  unreadMessage={!ownMsgClass}
                  setUnreadMessage={setUnreadMessage}
                /> 
                : <></>}
                </DropdownItem>
              </UncontrolledDropdown>
              <div className="d-flex align-items-center flex-column">
          
            <div className="d-flex align-items-center">
              <div className="d-flex  flex-column">
            <div className={`chat-text-box with-media position-relative ${fromReply ? "min-w320 d-inline-flex flex-column":""} ${mediaClass}`}            
            onClick={() => {
              if (msg && msg.messageReply) {
                let el = document.getElementById(`msg-div-${msg.messageReply.messageId}`)
              if(el){
                jquery(`#msg-div-${msg.messageReply.messageId}`).addClass("replay-selected")
                el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
                setTimeout(() => {
                  jquery(`#msg-div-${msg.messageReply.messageId}`).removeClass("replay-selected")
                }, 2000);
              }
              }else if(msg && msg.extraData && msg.extraData.slug == "task_reply"){
                let el = document.getElementById(`task_${msg.extraData.task_id}`)
              if(el){
                jquery(`#task_${msg.extraData.task_id}`).addClass("replay-selected")
                el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
                setTimeout(() => {
                  jquery(`#task_${msg.extraData.task_id}`).removeClass("replay-selected")
                }, 2000);
              }

              }
            }}>
            {msg && msg.extraData && msg.extraData.slug  && msg.extraData.slug == "task_reply" && chatAndTasks.filter((task) => task.id == msg.extraData.task_id)[0] ? 
                  <TaskCard
                    key={msg.extraData.task_id}
                    task={chatAndTasks.filter((task) => task.id == msg.extraData.task_id)[0]}
                    project_id={projectId}
                    handler={handleSelectedTask}
                    pageName='ChatAi'
                    taskCheckUncheck={() => { }}
                    checkedTask={""}
                    setTaskPinned={() => {}}
                    setTaskId={() => { }}
                    setCallUserList={() => { }}
                    fromReply={true}
                  />
                  :<></>}
                {msg.extraData && msg.extraData.slug == "schedule_reply"? 
                   <ScheduleCard
                   benchmark={msg.extraData}
                   fromChat={true} 
                   fromReply={true}
                   />
                  :<></>}
              {!$.isEmptyObject(msg.messageReply) ?
              <div 
                className="reply-box"
                onClick={() => {
                  var nav = $('#msg-div-'+msg.messageReply.messageId);
                  if(nav.offset()){
                  }
                }}
              >
                <div className={`absolute-task-card p-15 bg-base-header-color ${showConvertDropdown || showTaskInfo ? "" :"d-none" }`}>
                  </div>
                <div className={`d-flex justify-content-between bgleftpanelnew lightthemebgblue radius_3 overflow-hidden ${msg.messageReply.type =="audio" ? "flex-column" : "align-items-center"}`}>
                  <div className="p-1">
                    <i className="font-italic">{msg.messageReply.userName}</i>
                    {msg.messageReply.type == "text" ?
                    <div className={`c-font color-white-60 f-13 ms-1 line-clamp-3 text-start`}>
                      {ReactHtmlParser(urlify(msg.messageReply.message.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                    </div>
                    : <></>}
                    {msg.messageReply.caption != "" ?
                    <div className={`c-font color-white-60 f-13 ms-1 line-clamp-3 text-start`}>
                      {ReactHtmlParser(urlify(msg.messageReply.caption.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                    </div>
                    : <></>}
                  </div>
                  {msg.messageReply.type != "text" && msg.messageReply.type !="audio" ?
                  <div className="h50w50 with-bg d-flex align-items-center bg-white-05">
                    <div className="comman-bg-img h-100 w-100 bg-style-cover " style={{ backgroundImage: `url('${encodeURI(ATTACH_BASE_URL + msg.messageReply.message)}')` }}></div>
                  </div>
                  :msg.messageReply.type =="audio" ?
                  <div className="chat-audio">
                  <AudioPlayer
                    style={{width: '300px', margin :"4px"}}
                    layout="horizontal"
                    className="audio_player"
                    showJumpControls={false}
                    showSkipControls={false}
                    customAdditionalControls={[]}
                    customVolumeControls={[]}
                    src={encodeURI(ATTACH_BASE_URL + msg.messageReply.message)}
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                  />
                </div>
                   : <></>}
                </div>
              </div>
              : <></>}
              {msgType == "audio" 
              ? <div className="chat-audio">
                  <AudioPlayer
                    style={{width: '300px'}}
                    layout="horizontal"
                    className="audio_player"
                    showJumpControls={false}
                    showSkipControls={false}
                    customAdditionalControls={[]}
                    customVolumeControls={[]}
                    src={msgContent}
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                  />
                </div>
              : msgType == "video"
                ? <div className="chat-video comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 position-relative ratio-16x9 h180w180">
                    <video controls className="comman-bg-img h-100 w-100 bg-style-cover radius_3" src={msgContent}></video>
                    <a href="#/" className="play-icon"><PlayCircle size={40} color="#00ff6a" weight="light" /></a>
                    <a href="#/" className="play-icon d-none"><PauseCircle size={40} color="#00ff6a" weight="light" /></a>
                  </div>
                : msgType == "pdf"
                  ? <a href={msgContent} target="_blank" className="chat-img comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3">
                      <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3"><FilePdf  color="#00ff6a" weight="light" className="h-100 w-100 " /></div>
                    </a>
                  : msgType == "other_file"
                    ? <a href={msgContent} target="_blank" className="chat-img comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3">
                        <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3"><FileDoc  color="#00ff6a" weight="light" className="h-100 w-100 " /></div>
                      </a>
                    : msgType == "image"
                      ? <div className="chat-img comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 mt-10"
                      onClick={(e)=>{
                      }}>
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                            style={{ backgroundImage: `url('${msgContent}')` }}
                            onClick={() => {
                              setImagePreviewSrc(msgContent);
                              setOpenGallery(true);
                            }}
                          ></div>
                        </div>
                      : <div className={`chat-text ${fromReply ? "p-2 ps-1 pb-1":""}`} 
                          id={`message-text-${msg._id}`} 
                          onClick={() => {
                            if(isChatTask){
                              viewTask(msg.extraData);
                            }
                          }}
                        >
                          {isChatTask ? 
                            <div className="w-100 mb-1">
                              <div className="badge badge-white rounded-pill fw-light">
                                <span className={`c-font f-12 ${msg.extraData.dataType == "request" ? "color-orange" : ""}`}>{_l(badgeArray[msg.extraData.dataType])}</span>
                              </div>
                            </div> 
                          : <></>
                          }
                          {spaceSetupPending == 1 ? ReactHtmlParser(msgContent) : ReactHtmlParser(urlify(msgContent.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                        </div>
              }
              {msg.type != "text" && msg.caption != "" ?
              <div className="reply-box">
                <div className="pt-2 flex-column d-flex reply-caption-box">
                  <div className="p-1">
                    <div className={`c-font color-white-60 f-13 ms-1`}>
                    {spaceSetupPending == 1 ? ReactHtmlParser(msg.caption) : ReactHtmlParser(urlify(msg && msg.caption && msg.caption.replace(/(?:\r\n|\r|\n)/g, '<br>')))}
                    </div>
                  </div>
                </div>
              </div>
              : <></>}
                      {["membership_plan","space_setting"].includes(msg && msg.extraData && msg.extraData.slug) ?
                        <div className=" d-grid gap-2 mt-15px">
                          {msg && msg.extraData && msg.extraData.slug && msg.extraData.slug == "membership_plan" ? <>
                            {msg.extraData.membershipPlan.map((btn) => {
                              return (<>
                                <div>
                                  <a className={`btn btn-white-05 border-0 p-10-15 min-w320 d-flex align-items-center justify-content-center `} onClick={() => {
                                      handleMessageClick(btn);                                   
                                  }} >
                                    {
                                      btn.id == 5 || btn.id == 1 ? <UserCirclePlus size={20} className="c-icons m-auto mx-0" weight="light" /> :
                                        btn.id == 6 || btn.id == 2 ? <UserSwitch size={20} className="c-icons m-auto mx-0" weight="light" /> :
                                          btn.id == 7 || btn.id == 4 ? <UserPlus size={20} className="" weight="light" /> :
                                            <>
                                              <UserCircle size={20} className="c-icons m-auto mx-0" weight="light" />
                                            </>
                                    }
                                    <span className="ms-10px">{btn.package_name}</span>
                                  </a>
                                </div>
                              </>)
                            })

                            }
                          </> : <></>}
                          {msg && msg.extraData && msg.extraData.slug && msg.extraData.slug == "space_setting" ? <>
                            {
                              msg && msg.extraData && msg.extraData.options && msg.extraData.options.length > 0 && msg.extraData.options.map((option, index) => {
                                return (<>
                                  <div>
                                    <a className={`btn btn-white-05 border-0 p-10-15 min-w320 d-flex align-items-center justify-content-center`}
                                      onClick={() => {
                                        dispatch(setSelectedPlanFromAichat(option));
                                        navigate(option.link)
                                      }} >
                                        
                                      <span className="">{option.title}</span>
                                    </a>
                                  </div>
                                </>)
                              })
                            }
                          </>
                            : <></>}
                        </div> : <></>}
              </div> 
                 
              <div className="chat-send-name c-font f-12 title-fonts color-white-60">
              <div className="d-flex align-items-center">
                <span>{msgDate}</span>
                <UncontrolledDropdown className={`delivered-status-icon ${readMsgClass}`}
                   
                   
                   
                   
                >
                  <DropdownToggle tag="a" className="d-flex with_separator2_10">
                    <ReadUnreadChecks size={18} weight="bold" 
                    strok1={validUserList.filter((msg)=> msg.isRead == 1).length > 0 ? localStorage.getItem("selectedTheme") == "light" ? "#53bdeb" : "#00FF6A" : localStorage.getItem("selectedTheme") == "light" ? "#59718C" : "#ffffff"} 
                    strok2={validUserList.filter((msg)=> msg.isRead == 1).length == validUserList.length ? localStorage.getItem("selectedTheme") == "light" ? "#53bdeb" : "#00FF6A" : localStorage.getItem("selectedTheme") == "light" ? "#b3b3b3" : "#ffffff"}/>
                    <span  className="ps-1" >{validUserList.filter((msg)=> msg.isRead == 1).length}</span>
                    <UncontrolledGeneralListing
                      list={msg.recepients.sort((a,b)=> b.isRead - a.isRead).map((item, index) => {
                        let memberDetails = groupDetails && groupDetails.members && groupDetails.members.filter((member) => member._id == item.user)[0] && groupDetails.members.filter((member) => member._id == item.user)[0] != undefined ? groupDetails.members.filter((member) => member._id == item.user)[0] : {}
                        if(Object.keys(memberDetails).length > 0)
                        {return {
                          name: memberDetails && memberDetails.fname + " "+ memberDetails.lname ,
                          image: memberDetails && memberDetails.displayPicture == "" ? USR_IMG_PLACEHOLDER : ATTACH_BASE_URL + memberDetails.displayPicture,
                          id: index,
                          isOnline: item.isOnline,
                          userId: memberDetails._id,
                          userType: memberDetails.myrUserType,
                          readTime: item.readAt,
                          isRead:item.isRead,
                          myrRole: memberDetails.myrRole
                        };}else{
                          return({})
                        }
                      })}
                      fromChat={false}
                      forCall={false}
                      fromAichat={false}
                      fromReadMsg={true}
                      sortList={false}
                    />
                  </DropdownToggle>
                </UncontrolledDropdown>
              </div>
            </div>
              </div>
              {msg.isEdited == 1 &&
              <div 
                className="edited_icon chat-action-option"
                 
                 
                 
              >
                <PencilSimple size={14} className="c-icons" weight="light" />
              </div>
              }
            </div>
            </div>
             
            </div>
            
        </div>
        </div>
        
      </div>
      <div className="position-absolute top-50 start-0 translate-middle-y replay-button d-none">
        <a href="#/" className="h25w25 d-flex comman-image-box with-bg rounded-circle bg-white-05">
          <ArrowBendUpRight size={14} className="c-icons m-auto" weight="light" />
        </a>
      </div>
    </div>



  );
};

export default MsgCard;

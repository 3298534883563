import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useDispatch } from "react-redux";
import { toastCloseAction } from "../../actions/messages";
import { CheckCircle ,Warning } from "phosphor-react";

const Toasts = ({ toastClass, message, header, delay, handler, confirmButtonText, confirmButtonHandler, cancelButtonText, cancelButtonHandler, closeOnlyToast }) => {
  const dispatch = useDispatch();
  /**
   * Toast class:
      1)toast-with-btn
      2)toast
      3)sucess
      4)unsucess
   */
  return (
    <React.Fragment>
      <div
        aria-live="polite"
        aria-atomic="true"
        className=" toast-main bd-example-toasts"
        style={{ minHeight: "240px" }} id="ToastClear"
      >
        <ToastContainer position="top-end" className="p-3">
          <Toast
            className={toastClass}
            // delay={500000000}
            delay={delay}
            autohide={toastClass !== "toast-with-btn"}
            onClose={()=>{handler();
              if (!closeOnlyToast) {
                cancelButtonHandler();
              }
              dispatch(toastCloseAction());
            }}
          >
            <Toast.Header className="">
              <CheckCircle size={18} weight="light" className="c-icons m-0 me-2 toast-status-img sucess-img" />
              <Warning size={18} weight="light" className="c-icons m-0 me-2 toast-status-img unsucess-img" />
              <div className="me-auto title-fonts fw-semibold color-white-80">
                {header}
              </div>
              {/* <div className="color-white-60 c-font f-13">
                {moment().fromNow()}
              </div> */}
            </Toast.Header>
            <Toast.Body className=" p-3 color-white-60">
              {message}
              <div className='progress-slider-with-action-icon w-100' style={{display: 'none'}}>
                <div className='progress-slider w-100'>
                    <div className='progress-slider w-100 d-flex align-items-center'>
                        <div className='progress count-loading-bar flex-grow-1'>
                            <div className='progress-bar progress-bar'></div>
                        </div>
                        <div className="progress-text c-font f-12 title-fonts ps-10px w-auto color-white">[80%]</div>
                    </div>
                </div>
              </div>
              <div className="toast-btn">
                {(confirmButtonText || cancelButtonText ) && <div className="mt-3 pt-3 border-top d-flex align-items-center justify-content-end">
                  {confirmButtonText && <button type="button" className="btn btn-primary btn-sm me-2" onClick={(event)=>{
                      if (event.detail === 1) {
                        confirmButtonHandler();
                        // dispatch(toastCloseAction()); 
                      }
                    }}>
                    {confirmButtonText}
                  </button>}
                  {cancelButtonText && <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    id="closeTaskModal"
                    data-bs-dismiss="toast"
                    onClick={()=>{
                      cancelButtonHandler();
                      dispatch(toastCloseAction());
                    }}
                  >
                    {cancelButtonText}
                  </button>}
                </div>}
              </div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

      {/* <div
        aria-live="polite"
        aria-atomic="true"
        className=" position-relative d-none "
      >
        <div className="toast-container p-3 top-0 end-0">
          <div className="toast unsucess fade show">
            <div className="toast-header "></div>
            <div className="toast-body"></div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Toasts;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomerServices from "../../services/customer-services";
import UserProfile from "./UserProfile";
import SpaceDetails from "./SpaceDetails/SpaceDetails";
import { getFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import { _l, showError } from "../../hooks/utilities";
import { setOnboardingStepData } from "../../actions/onboarding-actions/onboarding-actions";
import { SPACE_TYPE_OPTIONS } from "../../constants/constants";
import CommanLoader from "../Loader/CommanLoader";

const OnboardingEntryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];

  const userType = localStorage.getItem("user_type");

  const [onboardingStep, setOnboardingStep] = useState(document.referrer.includes("autologin") ? "user-profile" : "space-details");
  const [userProfileData, setUserProfileData] = useState();
  const [spaceBasicDetails, setSpaceBasicDetails] = useState();
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [loadingSpaceDetails, setLoadingSpaceDetails] = useState(false);

  const [searchParams] = useSearchParams();
  let projectId = Number(searchParams.get("space"));

  const getSpaceDetails = async (project_id) => {
    try {
      setLoadingSpaceDetails(true);
      const response = await CustomerServices.getSpaceDetails(project_id);
      if (response.status && response.data && Object.keys(response.data)) {
        let spaceData = response.data;
        const space = spaces.find((w) => w.project_id == project_id);

        if(space.is_paymented <= 0)
        {
          showError("l_unauthorized");
          navigate(
            location.state && location.state.from ? location.state.from : "/"
          );
        }

        //Operator can only edit space added by them
        else if (
          userType === "operator" &&
          Number(space.is_added_from_operator) == 0
          && Number(space.is_paymented) == 0
        ) {
          navigate(
            location.state && location.state.from ? location.state.from : "/"
          );
          showError("l_unauthorized");
        } else {
          const spaceType = SPACE_TYPE_OPTIONS.find(r => r.value == spaceData.space_type);
          setSpaceBasicDetails({
            address: spaceData.postal_address,
            title: spaceData.office_title,
            region_id: spaceData.region,
            office_id: 0,
            superfice: spaceData.area,
            number_of_desk: spaceData.number_of_desk,
            country_id: 0,
            is_edit: 1,
            id: spaceData.id,
            logo: spaceData.logo,
            is_virtual_space: 0,
            space_type: spaceType ? spaceType.value : 0,
            space_logo: spaceData.logo,
            floor_count: spaceData.floors && spaceData.floors.length ? spaceData.floors.length : 1,
            space_id: spaceData.id,
            project_id: spaceData.project_id,
            floors: spaceData.floors || [],
            ai_api_call_for_floor_data : spaceData.ai_api_call_for_floor_data,
            lease_id : spaceData.lease_id,
            space_records: spaceData.space_records
          });
          dispatch(getFloorDetails(project_id, 1));
          setOnboardingStep("space-details");
        }
      }
      else {
        navigate(
          location.state && location.state.from ? location.state.from : "/"
        );
        showError("l_unauthorized");
      }
    } catch (e) {
      console.error(e);
    }
    finally {
      setLoadingSpaceDetails(false);
    }
  };

  useEffect(() => {

    // Incase of edit, set space data from space array of existing space list, and fetch floor plan for individual space
    if (projectId) {
      getSpaceDetails(projectId);
    }

    // let element = document.getElementById("modified_height");
    // if(element)
    // {
    //   element.classList.add("modified-res-default-height");
    // } 

    // return () => {
    //   element = document.getElementById("modified_height");
    //   if(element)
    //   {
    //     element.classList.remove("modified-res-default-height");
    //   } 
    // }

  }, []);

  useEffect(() => {
    const getUserProfileData = async () => {
      setUserDataLoading(true);
      try {
        const response = await CustomerServices.getProfileDetails();
        setUserProfileData(response.data);
      } catch (e) {
        console.error(e);
      } finally {
        setUserDataLoading(false);
      }
    };

    if (onboardingStep === "user-profile" && (!userProfileData || !Object.keys(userProfileData).length)) {
      let element = document.getElementById("left-panel-main");
      if(element)
      {
        element.classList.add("d-none")
      }
      getUserProfileData();
      dispatch(
        setOnboardingStepData({
          headerTitle: _l("l_setup_profile"),
          headerDescription: _l("l_enter_profile_details"),
        })
      );
    }
  }, [onboardingStep])
  

  return (
    <React.Fragment>
        
            <React.Fragment>
              {onboardingStep == "user-profile" ? (
                <UserProfile
                  setOnboardingStep={setOnboardingStep}
                  profileData={userProfileData}
                  loader={userDataLoading}
                /> //User Profile Step
              ) : onboardingStep == "space-details" ? (
                <SpaceDetails
                  setOnboardingStep={setOnboardingStep}
                  spaceBasicDetails={spaceBasicDetails}
                  setSpaceBasicDetails={setSpaceBasicDetails}
                /> //Space Details Steps
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
        
    </React.Fragment>
  );
};

export default OnboardingEntryPage;

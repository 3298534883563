import React, { useState, useEffect } from "react";
import $ from "jquery";
import { _l } from "../../hooks/utilities";
import ProgressBarSmallLoader from "../Loader/ProgressBarSmallLoader";
import {
  MagnifyingGlass,
  Hash,
  UsersThree,
  Plus,
  CaretRight,
} from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import EquipmentsSubMenu from "./EquipmentsSubMenu";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { CUSTOM_ASSIGNEE_CHANGE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { getOperatorsByCategory } from "../../actions/customer";

const CurrentProviderDropdown = ({
  type,
  data,
  handler,
  setSearchAssignees,
  searchAssignees,
  provider,
  selectedId = [],
  action,
  selected,
  isRequest,
  openAssigneeDropdown = false,
  taskId,
}) => {
  const projectId = localStorage.getItem("selectedOffice");
  const staff_id = localStorage.getItem("staff_id");
  const contact_id = localStorage.getItem("contact_id");
  const contact_role = localStorage.getItem("contact_role");
  const userType = localStorage.getItem("user_type");

  const dispatch = useDispatch();

  const [assigneeData, setAssigneeData] = useState([]);
  const [assigneeDataList, setAssigneeDataList] = useState([]);
  const [isParentMenuLoaded, setIsParentMenuLoaded] = useState(false);
  const [defaultAssigneeData, setDefaultAssigneeData] = useState(
    data ? data : []
  );
  const [inviteeEmail, setInviteeEmail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeId, setActiveId] = useState([]);
  const [stop, setStop] = useState(true);
  const categoryNotShow = [
    "created_by_me",
    "assigned_to_me",
    "pinned",
    "overdue",
  ];
  const isVirtualSpace = localStorage.getItem("selectedVirtualSpace")
    ? true
    : false;
  const { taskFilters, leftPanelTab } = useSelector((state) => state.customer);

  const isShowDropdown = (index) => {
    setActiveId(activeId.map((w, i) => (w = w == true ? false : i == index)));
  };

  const AppendAssigneeUsingEmail = (email) => {
    if (email.includes("@") == true) {
      let data = [];
      data.push({
        id: email,
        name: email,
        is_assigned: 0,
        profile_image:
          "http://myr.ai/orb/assets/images/default-image-placeholder.svg",
      });
      setInviteeEmail(data);
    }
  };

  const settingOfId = (itemId) => {
    if (typeof itemId == "string") {
      let dataIds = itemId.split("@");
      if (dataIds[0].includes(".") == true) {
        return dataIds[0].replace(".", "_");
      } else {
        return dataIds[0];
      }
      return itemId;
    } else {
      return itemId;
    }
  };

  const ItemSearchContent = (
    <div
      className="comman-search-dropdown"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <div className="d-flex  w-100">
        <div className="comment-input-box flex-grow-1">
          <input
            id="contactSearch"
            type="text"
            className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font"
            placeholder={`${_l("l_search")}`}
            value={searchAssignees}
            onChange={(e) => {
              setSearchAssignees(e.target.value);
              if (
                provider == "google_calendars" ||
                provider == "outlook_calendars"
              ) {
                setTimeout(() => {
                  AppendAssigneeUsingEmail(e.target.value);
                }, 100);
              }
            }}
          />
        </div>
        <a href="#/" className="comman_action_icon">
          <div className="d-flex h-100">
            <div className="action_icon h25w25 h-100">
              <MagnifyingGlass size={14} weight="light" className="c-icons" />
            </div>
          </div>
        </a>
      </div>
    </div>
  );

  useEffect(() => {
    let teamArray = [];
    let staffArray = [];
    let newteamArray = [];
    let newstaffArray = [];

    if (data) {
      // setToggle(dropDownToggle)
      if (isVirtualSpace == true) {
        teamArray = data
          ? data.map((data) => {
              if (data.id > 0) {
                return data;
              }
            })
          : [];
        staffArray = data
          ? data.map((data) => {
              if (data.id < 0) {
                return data;
              }
            })
          : [];

        newteamArray = teamArray
          .filter((data) => data && data.name !== "undefined")
          .sort((a, b) => a.name.localeCompare(b.name));
        newstaffArray = staffArray
          .filter((data) => data && data.name !== "undefined")
          .sort((a, b) => a.name.localeCompare(b.name));

        setAssigneeDataList(newteamArray.concat(newstaffArray));
        let Arr = newteamArray.concat(newstaffArray);
        if (activeId.length == 0) {
          setActiveId(Array.from({ length: Arr.length }).fill(false));
        }
        setDefaultAssigneeData(newteamArray.concat(newstaffArray));
      } else {
        teamArray = data && data.length ? data.filter((w) => w.id < 0) : [];
        staffArray = data && data.length ? data.filter((w) => w.id > 0) : [];
        let myrSupport =
          data && data.length
            ? data.filter((w) => w.id == "myrai_support")
            : [];

        newteamArray = teamArray.sort((a, b) => a.name.localeCompare(b.name));
        newstaffArray = staffArray.sort((a, b) => a.name.localeCompare(b.name));

        setAssigneeDataList(myrSupport.concat(newteamArray, newstaffArray));

        let newArr = myrSupport.concat(newteamArray, newstaffArray);
        if (activeId.filter((ss) => ss == true).length == 0) {
          setActiveId(Array.from({ length: newArr.length }).fill(false));
        }
        setDefaultAssigneeData(newteamArray.concat(newstaffArray));
      }
    }
  }, [data]);

  useEffect(() => {
    if (!openAssigneeDropdown) {
      setActiveId(Array.from({ length: assigneeData.length }).fill(false));
    }
    else {
      //Timeout to get the parent menu load in order to avoid 
      setTimeout(() => {
        setIsParentMenuLoaded(true)
      });
    }
  }, [assigneeData, openAssigneeDropdown]);

  useEffect(() => {
    if (
      assigneeDataList &&
      assigneeDataList.length
    ) {
      setAssigneeData(assigneeDataList);
    }
  }, [JSON.stringify(selectedId), JSON.stringify(assigneeDataList)]);

  useEffect(() => {
    if (assigneeData && assigneeData.length) {
      $("#contactSearch").val("");
      assigneeData.map((item) => {
        if (typeof item.id == "string") {
          let dataIds = item.id.split("@");
          if (dataIds[0].includes(".") == true) {
            $("#task_" + dataIds[0].replace(".", "_")).addClass("d-none");
          } else {
            $("#task_" + dataIds[0]).addClass("d-none");
          }
        } else {
          $("#task_" + item.id).addClass("d-none");
        }
      });
    }
    $("#not_click_able").attr("disabled", "disabled");
  }, [assigneeData]);

  useEffect(() => {
    let tempcat = taskFilters.filter((topic) =>
      [
        "created_by_me",
        "assigned_to_me",
        "pinned",
        "overdue",
        "date_range_filter",
      ].includes(topic.filter_key)
    );
    if (
      taskFilters &&
      taskFilters.length > 0 &&
      activeId.length > 0 &&
      stop &&
      tempcat.length == 0 &&
      openAssigneeDropdown
    ) {
      let keys = assigneeData.findIndex(
        (dd) => dd.id == taskFilters[0].filter_key
      );
      if (keys > -1) {
        dispatch(
          getOperatorsByCategory(
            projectId || 0,
            assigneeData[keys].id,
            1,
            1,
            setLoader
          )
        );
        isShowDropdown(keys);
        setStop(false);
      }
    }
  }, [activeId, openAssigneeDropdown, JSON.stringify(assigneeData)]);

  return (
    <>
    {
      isParentMenuLoaded
      ?
        <DropdownMenu
          className={`w-200 overflowscroll AssigneeDropdownCloseOutside ${taskId}`}
          // className="dropdown-menu dropdown w-240px overflowscroll"
          aria-labelledby="CurrentProviderDropdown"
          data-popper-placement="start"
          id="scroller"
          container="body"
        >
         

          <DropdownItem
            className="position-sticky top-0 z-index-2 p-0"
            toggle={false}
          >
            {ItemSearchContent}
          </DropdownItem>
          {assigneeData &&
          assigneeData.filter(
            (assign) =>
              assign.name &&
              assign.name.toLowerCase().includes(searchAssignees.toLowerCase())
          ).length ? (
            assigneeData
              .filter(
                (assign) =>
                  assign.name &&
                  assign.name
                    .toLowerCase()
                    .includes(searchAssignees.toLowerCase())
              )
              .map((item, index) => {
                if (categoryNotShow.indexOf(item.id) > -1) {
                  return;
                }
                return (
                  <>
                    <UncontrolledDropdown
                      key={index}
                      isOpen={openAssigneeDropdown && activeId[index]}
                      setActiveFromChild={true}
                      direction="left"
                      className={`w-200 ${
                        staff_id == item.id || contact_id == item.id
                          ? ""
                          : ""
                      } ${
                        userType == "staff" && item.id == "myrai_support"
                          ? "d-none"
                          : ""
                      } ${
                        taskFilters.some(
                          (key) => key.main_key == "myr_support"
                        ) && item.type == "myr_support"
                          ? " "
                          : ""
                      }
                  ${
                    taskFilters.some((key) => key.main_key == "my_contact") &&
                    item.type == "my_contact"
                      ? ""
                      : ""
                  }`}
                    >
                      <DropdownToggle
                        toggle="false"
                        className="w-100 custom-btn-dropdown-items"
                        onClick={() => {
                          if (leftPanelTab == "my-space") {
                            isShowDropdown(index);
                          }
                        }}
                      >
                        <a
                          href="#/"
                          className={`dropdown-item custom-assignees custom-class-active hr_sepretr
                          ${staff_id == item.id ? "for-disabled bg-transparent" : ""} 
                          ${
                            // (item.type != "equipments" && !(item.type == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && contact_role != 3 && isRequest)) && (item.is_assigned == 1 ||
                            item.type != "equipments" &&
                            !(
                              item.type == "providers" &&
                              ((userType == "contact" &&
                                CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)) ||
                                userType == "staff") &&
                              isRequest &&
                              item.id != "myrai_support"
                            ) &&
                            (item.is_assigned == 1 ||
                              (selectedId &&
                                selectedId.filter(
                                  (assign) => assign.id == item.id
                                ).length > 0))
                              ? "active with-tick"
                              : ""
                          } ${
                            selected &&
                            selected.filter((se) => se.id == item.id).length > 0
                              ? "active with-tick"
                              : " "
                          } ${
                            (leftPanelTab !== "my-space" && selectedId &&
                                selectedId.filter(
                                  (act) => act.id == item.id
                                ).length > 0)
                              ? "active"
                              : ""
                          }
                          `}
                          data-assigneesId={`${item.id}`}
                          data-id={type && item.id ? `${type}_${item.id}` : ""}
                          onClick={() => {
                            if(staff_id == item.id)
                            {
                              return false;
                            }
                            if (leftPanelTab == "my-space") {
                              isShowDropdown(index);
                            }
                            if (action) {
                              handler(type, item.id, item);
                              // actionStausChange(0,"set_assignee",Arr)
                            } else {
                              if (item.is_tag) {
                                // if(item.type != "equipments" && !(item.type == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && contact_role != 3 && isRequest)){
                                if (
                                  (item.type != "equipments" &&
                                  !(
                                    item.type == "providers" &&
                                    ((userType == "contact" &&
                                      CUSTOM_ASSIGNEE_CHANGE.includes(
                                        contact_role
                                      )) ||
                                      userType == "staff") &&
                                    isRequest &&
                                    item.id != "myrai_support"
                                  )) || leftPanelTab !== "my-space"
                                ) {
                                  handler(type, item.id, item, "tag");
                                } else if (item.type == "providers") {
                                  dispatch(
                                    getOperatorsByCategory(
                                      projectId || 0,
                                      item.id,
                                      1,
                                      1,
                                      setLoader,
                                      1
                                    )
                                  );
                                }
                              } else if (!item.disabled) {
                                handler(type, item.id, item);
                              }

                              if (typeof item.id == "string") {
                                let dataIds = item.id.split("@");
                                if (dataIds[0].includes(".") == true) {
                                  $(
                                    "#task_" + dataIds[0].replace(".", "_")
                                  ).removeClass("d-none");
                                } else {
                                  $("#task_" + dataIds[0]).removeClass("d-none");
                                }
                              } else {
                                $("#task_" + item.id).removeClass("d-none");
                              }
                            }
                            setSearchAssignees("");
                          }}
                        >
                          <div
                            className="d-flex align-items-center text-truncate"
                            type="button"
                            id="EquipmentsSubMenu"
                            data-bs-toggle="dropdown"
                          >
                            <div
                              className={`c-list-icon ${
                                item.is_tag || item.profile_image == ""
                                  ? type == "task_topic"
                                    ? ""
                                    : "d-none"
                                  : ""
                              }`}
                            >
                              {item.name == "Selected Contacts" ||
                              item.name == "Suggested Contacts" ? (
                                <></>
                              ) : (
                                <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                  {/^[0-9-]+$/.test(item.id) ||
                                  type == "task_topic" ? (
                                    (type != "task_topic" && item.id > 0) ||
                                    (type == "task_topic" && item.id < 0) ? (
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('${item.profile_image}')`,
                                        }}
                                      ></div>
                                    ) : (
                                      <UsersThree
                                        size={18}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )}
                            </div>
                            {item.is_tag ? (
                              <div className="c-list-icon ">
                                <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                  <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                                    {item.main_sub_key == "team" ? (
                                      <UsersThree
                                        size={18}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    ) : item.profile_image &&
                                      item.profile_image != "" ? (
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('${item.profile_image}')`,
                                        }}
                                      ></div>
                                    ) : (
                                      <Hash
                                        size={16}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="ps-2 w100minus40 text_wrap">
                              <div className={"c-list-detail c-font f-14 text-truncate"}>
                                {item.name}
                              </div>
                            </div>
                            {/* {item.type == "equipments" || (type == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && contact_role != 3 && isRequest)? */}
                            {item.type == "equipments" ||
                            (type == "providers" &&
                              ((userType == "contact" &&  leftPanelTab == "my-space" &&
                                CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)) ||
                                userType == "staff") &&
                              isRequest &&
                              item.id != "myrai_support") ? (
                              <>
                                {item.subCategory &&
                                item.subCategory.filter((s, index, self) => s.is_assigned == 1 && (Object.keys(s).length > 1 || self.length == 1))
                                  .length > 0 ? (
                                  <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                    {" "}
                                    <span className="m-auto p-0">
                                      {item.subCategory &&
                                        item.subCategory.filter(
                                          (s) => s.is_assigned == 1
                                        ).length}
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                <div className="c-list-icon 23">
                                  <CaretRight
                                    size={14}
                                    weight="light"
                                    className={`c-icons ${leftPanelTab == "my-space" ? '' : 'd-none'}`}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </a>
                      </DropdownToggle>
                      {item.type == "equipments" ||
                      (type == "providers" &&
                        ((userType == "contact" &&
                          CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)) ||
                          userType == "staff") &&
                        isRequest &&
                        item.id != "myrai_support") ? (
                        <EquipmentsSubMenu
                          unContolList={true}
                          subCategory={item.subCategory}
                          item={item}
                          handler={handler}
                          loader={loader}
                          openAssigneeDropdown={
                            openAssigneeDropdown && activeId[index]
                          }
                          taskId={taskId}
                        />
                      ) : (
                        <></>
                      )}
                    </UncontrolledDropdown>
                  </>
                );
              })
          ) : assigneeData.length == 0 ? (
            // <li
            //   className="hr_sepretr"
            //   data-bs-toggle="dropdown"
            //   data-bs-auto-close="outside"
            //   aria-expanded="false"
            // >
            //   <a href="#/" className="dropdown-item custom-assignees custom-class-active">
            //     <div className="d-flex align-items-center text-truncate ">
            //       <div className="ps-2 w100minus40 text_wrap">
            //         <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            //           <div
            //              alt="NoTodoTask"
            //              className="big-image">
            //               {/* <NoSearch className="opacity-25" /> */}
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </a>
            // </li>
            <CommanPlaceholder
              imgWidth="h60w60"
              placeholderText={_l("l_no_data")}
            />
          ) : inviteeEmail && inviteeEmail.length ? (
            inviteeEmail.map((item, index) => {
              return (
                <DropdownItem className="hr_sepretr" key={index}>
                  <a
                    href="#/"
                    className={`dropdown-item custom-assignees ${
                      item.is_assigned == 1 ? "active with-tick" : ""
                    }`}
                    data-assigneesId={`${item.id}`}
                    onClick={() => {
                      if (item.is_tag) {
                        handler(type, item.id, item, "tag");
                      } else {
                        handler(type, item.id, item);
                      }
                      $("#contactSearch").val("");

                      if (typeof item.id == "string") {
                        let dataIds = item.id.split("@");
                        if (dataIds[0].includes(".") == true) {
                          $("#task_" + dataIds[0].replace(".", "_")).removeClass(
                            "d-none"
                          );
                        } else {
                          $("#task_" + dataIds[0]).removeClass("d-none");
                        }
                      } else {
                        $("#task_" + item.id).removeClass("d-none");
                      }
                    }}
                  >
                    <div className="d-flex align-items-center text-truncate ">
                      <div
                        className={`c-list-icon ${item.is_tag ? "d-none" : ""}`}
                      >
                        <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                          {<Plus size={16} weight="light" className="c-icons" />}
                        </div>
                      </div>
                      <div className="ps-2 w100minus40 text_wrap">
                        <div className={"c-list-detail c-font f-14 text-truncate"}>
                          {item.is_tag ? (
                            <Hash size={20} weight="light" className="c-icons" />
                          ) : (
                            <></>
                          )}
                          {item.name}
                        </div>
                      </div>
                      <div
                        id={
                          typeof item.id == "string"
                            ? "task_" + settingOfId(item.id)
                            : "task_" + item.id
                        }
                        className="w100minus90 text_wrap d-none"
                      >
                        <ProgressBarSmallLoader height={20} width={20} />
                      </div>
                    </div>
                  </a>
                </DropdownItem>
              );
            })
          ) : (
            <CommanPlaceholder
              imgWidth="h60w60"
              placeholderText={_l("l_no_data")}
            />
          )}
        </DropdownMenu>
        :
        <React.Fragment></React.Fragment>
    }
    </>
  );
};

export default CurrentProviderDropdown;

import React, { useEffect, useState } from "react";
import { _l } from "../hooks/utilities";
import { ChatCircle, DotsThreeVertical, FileText } from "phosphor-react";
import customerServices from "../services/customer-services";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PROPOSEL_LIST } from "../constants/constants";
import CommanPlaceholder from "../components/Placeholder/CommanPlaceholder";
import {
  getMentionList,
  selectJobProposalPage,
  setCategoryNotificationCounts,
  setSelectedProsalData,
  setSpaceNotificationCount,
  setVirtualSpaceNotificationCount,
} from "../actions/customer";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CommentMinified from "../components/Comments/CommentMinified";
import documentsServices from "../services/documents-services";
 
import moment from "moment";

const ProposalRegistration = () => {
  /* For Noramal */
  const AGENT_PROPSALS_STATUS = [
    {
      title: "l_new_proposal",
      value: "newProposalForAgent",
      status: 0,
    },
    {
      title: "l_pending",
      value: "pendingForAgent",
      status: 1,
    },
    {
      title: "l_accepted",
      value: "acceptedForAgent",
      status: 2,
    },
    {
      title: "l_declined",
      value: "declinedForAgent",
      status: 3,
    },
    {
      title: "l_contract",
      value: "contractForAgent",
      status: 4,
    },
  ];
  /* For Agent*/
  const JOB_PROPSALS_STATUS = [
    {
      title: "l_received",
      value: "newProposal",
      status: 0,
    },
    {
      title: "l_pending",
      value: "pending",
      status: 1,
    },
    {
      title: "l_accepted",
      value: "accepted",
      status: 2,
    },
    {
      title: "l_rejected",
      value: "declined",
      status: 3,
    },
    {
      title: "l_contract",
      value: "contract",
      status: 4,
    },
  ];

  //for company
  const COMAPNY_JOB_PROPSALS_STATUS = [
    {
      title: "l_open",
      value: "open",
      status: 0,
    },
    {
      title: "l_close",
      value: "closed",
      status: 1,
    }
  ];


  let showComments = false;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view");
  const currentTab = searchParams.get("tab");

  const mentionsList = useSelector((state) => state.customer.mentionList);
  const projectId = useSelector((state) => state.customer.selectedProject);

  const userType = localStorage.getItem("user_type");
  const contact_id = localStorage.getItem("contact_id") || 0;
  const staff_id = localStorage.getItem("staff_id") || 0;
  const contact_image = localStorage.getItem("contact_image");
  const full_name = localStorage.getItem("full_name");

  const [selectedTab, setSelectedTab] = useState(view == "job-prposal" && localStorage.getItem("is_agent") != 1  ? "open"  :  localStorage.getItem("is_agent") == 1 ? "newProposalForAgent":"newProposal");
  const [proposalList, setProposalList] = useState([]);
  const [proposalListTemp, setProposalListTemp] = useState([]);
  const [proposalListCounts, setproposalListCounts] = useState({
    newProposal: 0,
    pending: 0,
    accepted: 0,
    declined: 0,
    contract: 0,
    open: 0,
    close: 0,
    newProposalForAgent: 0,
    pendingForAgent: 0,
    acceptedForAgent: 0,
    declinedForAgent: 0,
    contractForAgent: 0,
  });
  const [commentsLoader, setCommentsLoader] = useState(false);
  const [benchmarkComments, setBenchmarkComments] = useState([]);
  const [benchmark, setBenchmark] = useState({});

  const handleList = (tab) => {
    switch (tab) {
      case "newProposal":
        setProposalList(
          proposalListTemp.filter(
            (prop) =>
             ( Number(prop.status) == 4 &&
              Number(prop.proposal_amount).toFixed() == 0) || (prop.agent_status == 4 && prop.requirement_id > 0 )
          )
        );
        break;
      case "pending":
        setProposalList(
          proposalListTemp.filter(
            (prop) =>
              (Number(prop.status) == 4 &&
              Number(prop.proposal_amount).toFixed() > 0) || (prop.agent_status == 3 && prop.requirement_id > 0 )
          )
        );
        break;
      case "accepted":
        setProposalList(
          proposalListTemp.filter((prop) => (Number(prop.status) == 3) || (prop.agent_status == 4 && prop.requirement_id > 0 ))
        );
        break;
      case "declined":
        setProposalList(
          proposalListTemp.filter((prop) => (Number(prop.status) == 2) || ((prop.agent_status == 2 || prop.agent_status == 5) && prop.requirement_id > 0))
        );
        break;
      case "open":
        setProposalList(
          proposalListTemp.filter((prop) => (Number(prop.is_opened) == 1))
        );
        break;
      case "closed":
        setProposalList(
          proposalListTemp.filter((prop) => (Number(prop.is_opened) != 1))
        );
        break;
      case "contract":
        setProposalList(
          proposalListTemp.filter((prop) => (Number(prop.is_opened) != 1))
        );
        break;
      case "newProposalForAgent":
        setProposalList(
          proposalListTemp.filter((prop) => (prop.status == 4 && prop.agent_status == 4))
        );
        break;
      case "pendingForAgent":
        setProposalList(
          proposalListTemp.filter((prop) => (prop.status == 4 && prop.agent_status == 3 && prop.is_company_contract_upload == 0))
        );
        break;
      case "acceptedForAgent":
        setProposalList(
          proposalListTemp.filter((prop) => (prop.status == 4 && prop.agent_status == 3 && prop.is_company_contract_upload == 1))
        );
        break;
      case "declinedForAgent":
        setProposalList(
          proposalListTemp.filter((prop) => ((prop.status == 5) || (prop.agent_status == 5 && prop.status == 2 ) || (prop.agent_status == 2)))
        );
        break;
      case "contractForAgent":
        setProposalList(
          proposalListTemp.filter((prop) => (prop.status == 3 && prop.agent_status == 3))
        );
        break;
      default:
        setProposalList(proposalListTemp);
        break;
    }
  };

  const handleAddComment = async (
    comment,
    files,
    id = 0,
    rel_id,
    rel_type,
    benchmark
  ) => {
    try {
      if ((comment && comment.trim().length) || files) {
        const response = await documentsServices.addDocumentComment(
          rel_type,
          rel_id,
          comment,
          undefined,
          id,
          files,
          benchmark.id
        );
        if (id > 0) {
          const commentIndex = benchmarkComments.findIndex((w) => w.id == id);
          setBenchmarkComments(
            Object.values({
              ...benchmarkComments,
              [commentIndex]: {
                ...benchmarkComments[commentIndex],
                content: comment,
              },
            })
          );
        } else {
          if (response.data.comment_id) {
            let attachments = [];

            if (files) {
              Array.from(files).map((file) => {
                attachments.push({
                  file_url: URL.createObjectURL(file),
                  file_name: file.name,
                });
              });
            }
            setBenchmarkComments(
              benchmarkComments.concat([
                {
                  content: comment,
                  full_name,
                  contact_id,
                  staffid: staff_id,
                  profile_url: contact_image,
                  id: response.data.comment_id,
                  attachments,
                  is_allowed_to_edit: 1,
                },
              ])
            );
            let tempList = proposalList;
            let index = tempList.findIndex((w) => w.id == benchmark.id);
            if (index > -1) {
              tempList[index].comments_count =
                tempList[index].comments_count + 1;
            }
            setProposalList(tempList);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getScheduleComments = async (rel_id, rel_type, benchmark) => {
    let unreadCount = benchmark.unread_comment_count;
    try {
      setCommentsLoader(true);
      showComments = true;
      dispatch(getMentionList(rel_id, rel_type));
      const response = await documentsServices.getComments(
        rel_id,
        rel_type,
        undefined,
        undefined,
        1,
        benchmark.id
      );
      if (response.data) {
        if (unreadCount > 0) {
          if (
            localStorage.getItem("selectedVirtualSpace") &&
            JSON.parse(localStorage.getItem("selectedVirtualSpace"))
          ) {
            dispatch(
              setVirtualSpaceNotificationCount(
                projectId,
                Number(benchmark.unread_comment_count),
                -1
              )
            );
          } else {
            dispatch(
              setSpaceNotificationCount(
                projectId,
                Number(benchmark.unread_comment_count),
                -1
              )
            );
          }
          dispatch(
            setCategoryNotificationCounts(
              "providers",
              benchmark.provider_id,
              benchmark.is_schedule == 0 ? "benchmark_count" : "schedule_count",
              Number(benchmark.unread_comment_count),
              -1
            )
          );
        }
        let formattedComments = response.data.map((comment) => {
          return {
            ...comment,
            profile_url: comment.addedfrom_details.profile_url,
            full_name: comment.addedfrom_details.full_name,
          };
        });
        formattedComments.sort((a, b) => Number(a.id) - Number(b.id));
        setBenchmarkComments(formattedComments);
        let tempList = proposalList;
        let index = tempList.findIndex((w) => w.id == benchmark.id);
        if (index > -1) {
          tempList[index].unread_comment_count = 0;
        }
        setProposalList(tempList);
        // unreadCount.current = 0;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setCommentsLoader(false);
    }
  };

  useEffect(() => {
    customerServices
      .getBenchmarks(0, 0, "", 0, 0, 0, 1, 10, 1, [], -1)
      .then((res) => {
        if (res && res.status) {
          setProposalList(res.data);
          setProposalListTemp(res.data);
        }
      });
      if (view == "job-proposal") {
        dispatch(selectJobProposalPage(true));
      }
  }, []);

  useEffect(() => {
    // handleList("newProposal")
    setproposalListCounts({
      newProposal: proposalListTemp.filter(
        (prop) =>
          (Number(prop.status) == 4 &&
          Number(prop.proposal_amount).toFixed() == 0) || (prop.agent_status == 4 && prop.requirement_id > 0 )
      ).length,
      pending: proposalListTemp.filter(
        (prop) =>
          (Number(prop.status) == 4 && Number(prop.proposal_amount).toFixed() > 0) || (prop.agent_status == 3 && prop.requirement_id > 0 )
      ).length,
      accepted: proposalListTemp.filter((prop) => (Number(prop.status) == 3) || (prop.agent_status == 4 && prop.requirement_id > 0 ))
        .length,
      declined: proposalListTemp.filter((prop) => (Number(prop.status) == 2) || ((prop.agent_status == 2 || prop.agent_status == 5) && prop.requirement_id > 0 ))
        .length,
      open : proposalListTemp.filter((prop) => (Number(prop.is_opened) == 1)).length,  
      close : proposalListTemp.filter((prop) => (Number(prop.is_opened) != 1)).length,
      newProposalForAgent : proposalListTemp.filter((prop) => (prop.status == 4 && prop.agent_status == 4)).length,
      pendingForAgent : proposalListTemp.filter((prop) => (prop.status == 4 && prop.agent_status == 3 && prop.is_company_contract_upload == 0)).length,
      acceptedForAgent : proposalListTemp.filter((prop) => (prop.status == 4 && prop.agent_status == 3 && prop.is_company_contract_upload == 1)).length,
      declinedForAgent :  proposalListTemp.filter((prop) => ((prop.status == 5) || (prop.agent_status == 5 && prop.status == 2 ) || (prop.agent_status == 2))).length,
      contractForAgent   : proposalListTemp.filter((prop) => (prop.status == 3 && prop.agent_status == 3)).length,
    });
  }, [JSON.stringify(proposalList)]);

  useEffect(() => {
    if (!currentTab) {
      if (view == "job-prposal" && localStorage.getItem("is_agent") != 1) {
        handleList("open")
      } else if (localStorage.getItem("is_agent") == 1) {
        handleList("newProposalForAgent")
      } {
        handleList("newProposal")
      }
    }
  }, [JSON.stringify(proposalListTemp)])

  useEffect(() => {
    if (currentTab) {
      switch (currentTab) {
        case "pending":
          handleList("pendingForAgent");
          setSelectedTab("pendingForAgent");
          break;
        case "newProposal":
          handleList("newProposalForAgent");
          setSelectedTab("newProposalForAgent");
          break;
        case "accepted":
          handleList("acceptedForAgent");
          setSelectedTab("acceptedForAgent");
          break;
        case "declined":
          handleList("declinedForAgent");
          setSelectedTab("declinedForAgent");
          break;
        case "contract":
          handleList("contractForAgent");
          setSelectedTab("contractForAgent");
          break;
      
        default:
          break;
      }
    }
  }, [currentTab, JSON.stringify(proposalListTemp)])
  
  
  return (
    <>
      <div className="col-12 col-xl-9 col-xxl-8 registration-content mx-auto row registration-step-header pt60px">
        <div class="col-lg-2"></div>
        <div class="col-lg-7 m-auto">
          <div class="py-30px position-relative text-center">
            <div class="d-flex align-items-center justify-content-center">
              <div class=" px-0 px-md-3">
                <div class="c-font f-20 fw-semibold title-fonts">
                  {view == "job-prposal" ? _l("l_job_proposal_title") :_l("l_myr_provider_proposal_list_title")}
                </div>
                <div class="c-font f-16 pt-15px sub-header text-center">
                  <span>
                    {_l("l_myr_provider_proposal_list_step_description")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2"></div>
      </div>
      <div className="flex-grow-1 d-flex flex-column overflow-hiiden-auto">
        <div className="row">
          {/* <div className="col-lg-2"></div> */}
          <div className="col-12 col-xl-9 col-xxl-9 registration-footer-content mx-auto">
            <div className="position-sticky top-0 bg-white-03-solid z-index-2">
              <div className="align-items-center justify-content-between btn-white-05 d-flex p-1 mb-20 radius_5 text-uppercase overflow-auto">
                {view == "job-prposal" && localStorage.getItem("is_agent") == 1 && JOB_PROPSALS_STATUS.map((proposal) => {
                  return (
                    <>
                      <label
                        className={`row-btn-label w-25`}
                        for="flexRadioDefault1"
                      >
                        <input
                          type="radio"
                          className="hidden-input"
                          name="proposal_status"
                          value={proposal.value}
                          checked={proposal.value == selectedTab}
                          onChange={(event) => {
                            setSelectedTab(event.target.value);
                            handleList(event.target.value);
                          }}
                        />
                        <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue">
                          <span>{_l(proposal.title)}</span>
                          <span className="ps-1">
                            -{" "}
                            {proposal.status == 0
                              ? proposalListCounts.newProposal
                              : proposal.status == 1
                              ? proposalListCounts.pending
                              : proposal.status == 2
                              ? proposalListCounts.accepted
                              : proposal.status == 3
                              ? proposalListCounts.declined
                              : 0}
                          </span>
                        </div>
                      </label>
                    </>
                  );
                })}
                {view == "job-prposal" && localStorage.getItem("is_agent") != 1 && COMAPNY_JOB_PROPSALS_STATUS.map((proposal) => {
                  return (
                    <>
                      <label
                        className={`row-btn-label w-50`}
                        for="flexRadioDefault1"
                      >
                        <input
                          type="radio"
                          className="hidden-input"
                          name="proposal_status"
                          value={proposal.value}
                          checked={proposal.value == selectedTab}
                          onChange={(event) => {
                            setSelectedTab(event.target.value);
                            handleList(event.target.value);
                          }}
                        />
                        <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue">
                          <span>{_l(proposal.title)}</span>
                          <span className="ps-1">
                            -{" "}
                            {proposal.value == "open"
                              ? proposalListCounts.open
                              : proposal.value == "closed"
                              ? proposalListCounts.close
                              : 0}
                          </span>
                        </div>
                      </label>
                    </>
                  );
                })}
                {!view && view != "job-prposal" && AGENT_PROPSALS_STATUS.map((proposal) => {
                  return (
                    <>
                      <label
                        className={`row-btn-label w-25`}
                        for="flexRadioDefault1"
                      >
                        <input
                          type="radio"
                          className="hidden-input"
                          name="proposal_status"
                          value={proposal.value}
                          checked={proposal.value == selectedTab}
                          onChange={(event) => {
                            searchParams.delete("tab");
                            searchParams.append("tab", 
                            event.target.value == "newProposalForAgent" ? "newProposal" : 
                            event.target.value == "pendingForAgent" ? "pending" : 
                            event.target.value == "acceptedForAgent" ? "accepted" : 
                            event.target.value == "declinedForAgent" ? "declined" : 
                            event.target.value == "declinedForAgent" ? "declined" : 
                            event.target.value == "contractForAgent" ? "contract" : 
                            "");
                            setSearchParams(searchParams);
                            setSelectedTab(event.target.value);
                            setSelectedTab(event.target.value);
                            handleList(event.target.value);
                          }}
                        />
                        <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue">
                          <span>{_l(proposal.title)}</span>
                          <span className="ps-1">
                            -{" "}
                            {proposal.status == 0
                              ? proposalListCounts.newProposalForAgent
                              : proposal.status == 1
                              ? proposalListCounts.pendingForAgent
                              : proposal.status == 2
                              ? proposalListCounts.acceptedForAgent
                              : proposal.status == 3
                              ? proposalListCounts.declinedForAgent
                              : proposal.status == 4
                              ? proposalListCounts.contractForAgent
                              : 0}
                          </span>
                        </div>
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
            {view == "job-prposal" ?
            <>
            {/* job proposalList start */}
            <div className="mx-auto d-grid gap-3">
            {proposalList.length > 0 &&
              proposalList &&
              proposalList.map((proposal) => {
                return (
                    <div className="border radius10 row g-0 comman-white-box-shadow overflow-hiiden-auto bg-white-03">
                      <div className="col-lg-9">
                        <div className="d-flex align-align-items-center h-100 flex-column p-20pximp gap-4 gap-md-0">
                          <div className="d-flex justify-content-between flex-wrap">
                            <div className="text-truncate  with_separator_10 before-dnone pe-20pximp">
                              <div className="d-flex align-items-center">
                                <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                                  { "#Proposal"+ " - "+ proposal.id }
                                </div>
                              </div>
                              <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                                {proposal.address}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between comman_action_icon">
                              <a
                                href="#/"
                                className="btn btn-primary position-relative btn-sm light-theme-blue-bg radius_5"
                                onClick={() => {
                                  // /connect-provider?space=${row.project_id}&provider-type=proposal&id=${row.id}
                                  window.open(
                                    `/connect-provider?space=${proposal.project_id}&provider-type=proposal&id=${proposal.id}&tab=send`
                                  );
                                  dispatch(setSelectedProsalData(proposal));
                                }}
                              >
                                <FileText
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                                <span className="ms-10px">
                                  {_l("l_view_proposal")}
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 d-flex flex-column">
                        <div className="border-start d-flex light-theme-grey-light-bg flex-grow-1 min-h-140 max-h-150">
                          {/* <img
                                  className="h-100 w-100 object-fit-cover"
                                  src={proposal.solar_map}
                                  alt={"Solar Map"}
                                /> */}

                          <iframe
                            width="100%"
                            height="100%"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src={`https://www.google.com/maps?q=${encodeURIComponent(
                              proposal.address
                            )}&output=embed`}

                            //   src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                          ></iframe>

                          {/* <MapIframe
                                  fullWidth
                                /> */}
                        </div> 
                      </div>
                    </div>
                 
                );
              })}
            {proposalList.length == 0 && (
              <CommanPlaceholder
                imgType="no-benchmark"
                placeholderText={_l("l_no_job_proposals")}
              />
            )}
          </div>
            {/* job proposalList end */}

            </>
             :
             <>
            {/* Proposal Cards Section start */}       
            <div className="mx-auto d-grid gap-3">
              {proposalList.length > 0 &&
                proposalList &&
                proposalList.map((proposal) => {
                  return (
                    proposal.requirement_id > 0 ? 
                    <>
                    <div className="border radius10 row g-0 comman-white-box-shadow overflow-hiiden-auto bg-white-03">
                        <div className="col-lg-9">
                          <div className="d-flex align-align-items-center h-100 flex-column p-20pximp gap-4 gap-md-0">
                            <div className="d-flex justify-content-between flex-wrap mb20px">
                              <div className="text-truncate  with_separator_10 before-dnone pe-20pximp">
                                <div className="d-flex align-items-center">
                                  <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                                    {proposal.requirement_id + "-"}{proposal.company_name ? proposal.company_name : _l("l_company")}
                                    {/* {proposal.id ? proposal.id  + "-" + proposal.category : "#proposal"+ "-" + proposal.proposal_id} */}

                                  </div>
                                  <div className={`badge h32wauto c-font f-12 rounded-pill c-text me-10px text-truncate ms-10px ${
                                    proposal.agent_status ==  4 ? "done"
                                        : proposal.agent_status ==  3 ? "text-warning"
                                        : proposal.agent_status == 2 ? "text-danger"
                                        : "text-danger"}`}>
                                  {
                                        proposal.agent_status ==  4 ? _l("l_received")
                                        : proposal.agent_status ==  3 ? _l("l_pending")
                                        : proposal.agent_status == 2 ? _l("l_rejected")
                                        :  _l("l_rejected") 
                                      }
                                  </div>
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                                  {proposal.address}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between comman_action_icon">
                                <a
                                  href="#/"
                                  className="btn btn-primary position-relative btn-sm light-theme-blue-bg radius_5"
                                  onClick={() => {
                                    // /connect-provider?space=${row.project_id}&provider-type=proposal&id=${row.id}
                                    navigate(`/job-proposal-onboarding?job=${proposal.requirement_id}&space=${proposal.project_id}&proposal=${proposal.proposal_id}&id=${proposal.id}`)
                                    dispatch(setSelectedProsalData(proposal));
                                  }}
                                >
                                  <FileText
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                  <span className="ms-10px">
                                    {_l("l_view_proposal")}
                                  </span>
                                </a>
                              </div>
                            </div>
                            <div className="align-items-start align-items-md-center flex-wrap d-flex flex-column  flex-md-row gap-3 gap-md-0">
                              <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                                <div className="c-font f-16 fw-semibold title-fonts">
                                  {moment(proposal.start_date).format("DD/MM/YYYY")}
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                  {_l("l_start_date")}
                                </div>
                              </div>
                              <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                                <div className="c-font f-16 fw-semibold title-fonts">
                                  {proposal.total_proposal}
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                  {_l("l_total_proposal")}
                                </div>
                              </div>
                              <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                                <div className="c-font f-16 fw-semibold title-fonts">
                                  {proposal.superficy > 0
                                    ? proposal.superficy
                                    : 0}
                                  m²
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                  {_l("l_superficy")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 d-flex flex-column">
                          <div className="border-start d-flex light-theme-grey-light-bg flex-grow-1 min-h-140 max-h-150">
                            <iframe
                              width="100%"
                              height="100%"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              src={`https://www.google.com/maps?q=${encodeURIComponent(
                                proposal.postal_address
                              )}&output=embed`}

                              //   src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                     <>
                      <div className="border radius10 row g-0 comman-white-box-shadow overflow-hiiden-auto bg-white-03">
                        <div className="col-lg-9">
                          <div className="d-flex align-align-items-center h-100 flex-column p-20pximp gap-4 gap-md-0">
                            <div className="d-flex justify-content-between flex-wrap mb20px">
                              <div className="text-truncate  with_separator_10 before-dnone pe-20pximp">
                                <div className="d-flex align-items-center">
                                  <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                                    {proposal.id + "-" + proposal.category}
                                  </div>
                                  <div class="ms-10px badge  badge-white rounded-pill fw-light h32wauto c-font f-12 d-flex align-items-center ">
                                    <span class="in-progress p-0">
                                      {proposal.recursive === "1"
                                        ? _l("l_recurrent")
                                        : _l("l_onetime")}
                                    </span>
                                  </div>
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                                  {proposal.address}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between comman_action_icon">
                                <a
                                  href="#/"
                                  className="btn btn-primary position-relative btn-sm light-theme-blue-bg radius_5"
                                  onClick={() => {
                                    // /connect-provider?space=${row.project_id}&provider-type=proposal&id=${row.id}
                                    navigate(
                                      `/view-onboarding-proposal?space=${proposal.project_id}&provider-type=proposal&id=${proposal.id}`
                                    );
                                    dispatch(setSelectedProsalData(proposal));
                                  }}
                                >
                                  <FileText
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                  <span className="ms-10px">
                                    {_l("l_view_proposal")}
                                  </span>
                                </a>
                                <UncontrolledDropdown
                                  className="dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-dnone pe-0"
                                   
                                   
                                   
                                   
                                  onClick={() => {
                                    setBenchmark(proposal);
                                  }}
                                >
                                  <DropdownToggle
                                    tag={"div"}
                                    key={proposal.id}
                                    className="comman_action_icon  d-flex"
                                    type="button"
                                    id={`minified_comment_${proposal.id}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                    onClick={() => {
                                      getScheduleComments(
                                        userType == "operator" &&
                                          proposal.proposal_id
                                          ? proposal.proposal_id
                                          : proposal.id,
                                        userType == "operator" &&
                                          proposal.proposal_id
                                          ? "proposal"
                                          : "schedule",
                                        proposal
                                      );
                                    }}
                                  >
                                    <div className="d-flex align-items-center w-100">
                                      <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative">
                                        {proposal.unread_comment_count ? (
                                          <span className=" rounded-pill badge-custom  d-flex p-2 light-pill ">
                                            {proposal.unread_comment_count}
                                          </span>
                                        ) : (
                                          <React.Fragment></React.Fragment>
                                        )}
                                        <ChatCircle
                                          size={18}
                                          className="c-icons"
                                          weight="light"
                                        />
                                      </div>
                                      <span
                                        className={`c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle`}
                                        data-cmt-cnt-id={proposal.id}
                                      >
                                        {proposal.comments_count}
                                      </span>
                                    </div>
                                  </DropdownToggle>
                                   
                                  {
                                    <CommentMinified
                                      task={{
                                        docType:
                                          userType == "operator" &&
                                          proposal.proposal_id
                                            ? proposal.proposal_id
                                            : proposal.id,
                                      }}
                                      unContolList={true}
                                      id={proposal.id}
                                      loading={commentsLoader}
                                      comments={benchmarkComments}
                                      mentionList={mentionsList.map((name) => {
                                        let id =
                                          name.is_team && name.is_team == 1
                                            ? "-" + name.id
                                            : name.id;
                                        return {
                                          display: name.name,
                                          id: id,
                                          image: name.profile_image || "",
                                          isteam: name.is_team ? 1 : 0,
                                        };
                                      })}
                                      handleAddComment={(
                                        commentInput,
                                        fileUplods,
                                        commentId
                                      ) => {
                                        handleAddComment(
                                          commentInput,
                                          fileUplods,
                                          commentId,
                                          userType == "operator" &&
                                            proposal.proposal_id
                                            ? proposal.proposal_id
                                            : proposal.id,
                                          userType == "operator" &&
                                            proposal.proposal_id
                                            ? "proposal"
                                            : "schedule",
                                          proposal
                                        );
                                      }}
                                      disabled={
                                        proposal.proposal_id &&
                                        proposal.proposal_status == 2
                                      }
                                      showComments={true}
                                    />
                                  }
                                </UncontrolledDropdown>
                                <a className="white-border-box me-0 color-white-60 action_icon with_bg  d-flex close-toast h32w32 ms-10px">
                                  <DotsThreeVertical
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="align-items-start align-items-md-center flex-wrap d-flex flex-column  flex-md-row gap-3 gap-md-0">
                              <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                                <div className="c-font f-16 fw-semibold title-fonts">
                                  {moment(proposal.start_date).format("DD/MM/YYYY")}
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                  {_l("l_start_date")}
                                </div>
                              </div>
                              <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                                <div className="c-font f-16 fw-semibold title-fonts">
                                  {proposal.max_budget}
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                  {_l("l_budget")}
                                </div>
                              </div>
                              <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                                <div className="c-font f-16 fw-semibold title-fonts">
                                  {proposal.superficy > 0
                                    ? proposal.superficy
                                    : 0}
                                  m²
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                  {_l("l_superficy")}
                                </div>
                              </div>
                              <div className="with_separator_10 flex-grow-1 ps-20pximp">
                                <div className="c-font f-16 fw-semibold title-fonts">
                                  {proposal.proposal_amount}
                                </div>
                                <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                  {_l("l_proposal") + " " + _l("l_amount")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 d-flex flex-column">
                          <div className="border-start d-flex light-theme-grey-light-bg flex-grow-1 min-h-140 max-h-150">
                            {/* <img
                                    className="h-100 w-100 object-fit-cover"
                                    src={proposal.solar_map}
                                    alt={"Solar Map"}
                                  /> */}

                            <iframe
                              width="100%"
                              height="100%"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              src={`https://www.google.com/maps?q=${encodeURIComponent(
                                proposal.address
                              )}&output=embed`}

                              //   src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>

                            {/* <MapIframe
                                    fullWidth
                                  /> */}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              {proposalList.length == 0 && (
                <CommanPlaceholder
                  imgType="no-benchmark"
                  placeholderText={_l("l_no_proposals_found")}
                />
              )}
              
            </div>
            {/* Proposal Cards Section End */}

            </>
            }
          </div>
          {/* <div className="col-lg-2"></div> */}
        </div>
      </div>
    </>
  );
};

export default ProposalRegistration;

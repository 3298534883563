import React, { useState, useRef } from "react";
import { ArrowBendDoubleUpLeft, Eraser } from "phosphor-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, showError} from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
 
import SignatureCanvas from "react-signature-canvas";
import PdfSignatureTittle from "./PdfSignatureTittle";

const UploadCompanyContractModal = ({
  show,
  handleClose,
  callBack = ()=>{},
  setSignature = ()=>{},
  loder = false,
  contract = ""
}) => {
  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];

  const fullName = localStorage.getItem("full_name");
  const email = localStorage.getItem("email");

  const [firstName, setFirstName] = useState(
      fullName ? fullName.split(" ")[0].toString() : ""
  );
  const [lastName, setLastName] = useState(
      fullName ? fullName.split(" ").slice(1).toString() : ""
  );
  const [emailAddress, setEmailAddress] = useState(email);
  const [totalPages, setTotalPages] = useState();
  const [eventForClick, seteventForClick] = useState({ pageIndex: 0, event: {} });
  const [showSelectionModal, setShowSelectionModal] = useState(false);

  const signaturepadRef = useRef();

  const clearSignaturePad = () => {
    if (signaturepadRef.current && signaturepadRef.current.clear) {
      signaturepadRef.current.clear();
    }
  };


  const undoSignature = () => {
    if (signaturepadRef.current && signaturepadRef.current.toData()) {
      let signaturePadData = signaturepadRef.current.toData();
      signaturePadData.pop();
      signaturepadRef.current.fromData(signaturePadData);
    }
  };


  const handleSave = () => {
    if (
        signaturepadRef &&
        signaturepadRef.current &&
        signaturepadRef.current.isEmpty()
    ) {
        showError(_l("l_please_add_the_signature"))
        return;
    } else {
        setSignature(signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png"));
        callBack(signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png"));
    }
}

    const handleSignatureChange = (event) => {
      totalPages.forEach((element, i) => {
        if (i == (eventForClick.pageIndex - 1)) {
          element.signature1for = event.target.value == "company" ? "Company signature" : "Agent signature"
          element.signature2for = event.target.value == "company" ? "Agent signature" : "Company signature"
        }
      })
      setShowSelectionModal(false);
    };


  return (
    <>   
      <div className="">
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_l("l_signature_conformation_of_identity")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=" py-0 mx-2">
            <div className="row ">
              <div className="col-lg-8 form-wrapper-main">
              <iframe
                height={"100%"}
                width={"100%"}
                 src={contract} 
                 title=""
                 onLoad={(e) => {
                  // Manipulate iframe after PDF is loaded
                  const iframe = e.target;
                  if (iframe.contentDocument) {
                    // Set initial zoom level
                    iframe.contentDocument.body.style.zoom = '90%';
                    // Hide left side (assuming scrollbar is on the right)
                    iframe.contentDocument.body.style.overflowX = 'hidden';
                  }
                }}
                 ></iframe>
      
              </div>
              <div className="form-wrapper-main col-lg-4 ps-20pximp">
                <div className="row">
                  <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_first_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_first_name_placeholder")}`}
                      type="text"
                      name=""
                      value={firstName}
                      disabled={true}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_last_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_last_name_placeholder")}`}
                      type="text"
                      name=""
                      value={lastName}
                      disabled={true}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_email")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_email_placeholder")}`}
                      type="email"
                      name=""
                      value={emailAddress}
                      disabled={true}
                      onChange={(e) => {
                        setEmailAddress(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-xl-12 c-input-box  position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_signature")}
                    </Form.Label>
                  </Form.Group>
                  <div className="col-xl-12  pb-3">
                    <SignatureCanvas
                      penColor="white"
                      ref={signaturepadRef}
                      canvasProps={{
                        height: 200,
                        className: "custom-canvas-style form-control p-0 w-100",
                      }}
                    />
                  </div>
                  <div className="col-xl-12">
                    <div className="d-flex">
                      <a href="#/"
                        className="h40w40 d-flex  form-control p-0  me-3"
                        onClick={undoSignature}
                         
                         
                         
                      >
                        <ArrowBendDoubleUpLeft
                          size={18}
                          className="c-icons"
                          weight="light"
                        />
                      </a>
                      <a href="#/"
                        className="h40w40 d-flex  form-control p-0"
                        onClick={clearSignaturePad}
                         
                         
                         
                         
                      >
                        <Eraser size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                    <div className="input-label no-asterisk title-fonts c-font f-12 color-white-60 mt-2">
                      {_l("l_singature_subtext")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => loder ? "" : handleSave()}
              disabled={loder}
            >
              {loder ? _l("l_please_wait") : _l("l_save")}
              {loder ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
         
      </div>
      {
        showSelectionModal ?
          <PdfSignatureTittle
            show={showSelectionModal}
            handleClose={() => {
              setShowSelectionModal(false)

            }}
            handleSignatureChange={handleSignatureChange}
          /> : <></>
      }
    </>
  );
};
export default UploadCompanyContractModal
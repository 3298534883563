import React from 'react'
import { _l } from '../../hooks/utilities';
import { useSelector } from 'react-redux';

const AllscreenToastLoader = () => {

  const { isGetTaskId, callID} = useSelector((state) => state.vapi);
  const { loaderMessage} = useSelector((state) => state.customer);

  return (
    <div className='allScreenLoader text-center'>
        <div className="d-flex align-items-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            {
              loaderMessage ? 
              <div className=" title-fonts ps-20pximp">{_l(`${loaderMessage}`)} </div>
              :
              callID && isGetTaskId? 
              <div className=" title-fonts ps-20pximp">{_l("l_task_summary_creating")} </div>
               : <div className=" title-fonts ps-20pximp">{_l("l_updating_schedule")} </div>
            }
        </div>
    </div>
  )
}

export default AllscreenToastLoader
import React, { useEffect, useState } from "react";
import ChatPage from "../components/Chat/ChatPage";
import { useDispatch, useSelector } from "react-redux";
import customerServices from "../services/customer-services";
import { fetchMessages, getGroupDetails } from "../actions/chat";
import { fullScreenLoaderChangeSpace, getTaskList, leftPanelFilters, setDaterangeFilter, setDaterangeFilterApplied, setFetchedTaskandMessages, setSelectedDateView, setShowWeekcalendardays, updateTaskList } from "../actions/customer";
import chatServices from "../services/chat-services";
import { showMessage } from "../actions/messages";
import { _l, formateDate, hideLeftPanelNew, showLeftPanelNew } from "../hooks/utilities";
import moment from "moment";
import jquery from "jquery";
import CommanLoader from "../components/Loader/CommanLoader";
import { fetchAuthToken } from "../actions/chat-auth";
import { json, useSearchParams } from "react-router-dom";
import AcknowledgeScreen from "../components/Modals/OnboardingReusableComponents/AcknowledgeScreen";
import { ACKNOWLEDGEMENT_STEPS } from "../constants/constants";
import jQuery from "jquery";

const STATIC_LOADER_TIME = 7000; //in milliseconds

const AiChat = () => {
    const dispatch = useDispatch();
    const spaces = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")): [] ;
    const {groupDetails, groupList} = useSelector((state) => state.chat);  
    const { taskFilters, tasklistLoader, lastChatSession,defaultTopics,lastAppliedFilter,virtualSpaceList, fullScreenLoadChanSpace, providerWallet, leftPanelTab} = useSelector((state) => state.customer);
    const spaceState = useSelector((state) => state.customer.spaces);
    let user_type = localStorage.getItem("user_type");
    let contact_role = localStorage.getItem("contact_role");
    let isRegisterUser = localStorage.getItem("space_setup_pending");
    let unreadMessage = localStorage.getItem("unread_chat_notification_count");
    const email = localStorage.getItem("email");
    const client_id = localStorage.getItem("client_id");
    const contact_id = localStorage.getItem("contact_id");
    const savedDate = localStorage.getItem("selectedDate") ? localStorage.getItem("selectedDate") : new Date(); 
    const selectedTopic = localStorage.getItem("selectedTopic")
    
    const [loder, setLoader] = useState(true);
    const [msgloader, setmsgloader] = useState(false)
    const [selectStartDate, setSelectStartDate] = useState(new Date());
    const [chatGroupID, setChatGroupID] = useState(0)
    const [chatStartDate, setchatStartDate] = useState(new Date())
    const [chatEndDate, setchatEndDate] = useState(new Date());
    const [welcomeLoaderTimer, setWelcomeLoaderTimer] = useState(STATIC_LOADER_TIME);
    var activeGroupId = localStorage.getItem("activeGroupId");
    let chatUserID = localStorage.getItem("chatUserId")

    const [searchParams, setSearchParams] = useSearchParams();
    const userPlan = searchParams.get("plan");
    const rel_type = searchParams.get("rel_type");
    const [projectId] = useState(
        // spaces && spaces.length ? spaces[0].project_id : 0
    );
      
    let virtualSpaceClientId = localStorage.getItem("virtualSpaceClientId");
    useEffect(() => {
        localStorage.setItem("selectedDate",formateDate(new Date()));
    }, [projectId])

    useEffect(() => {
        if (!loder) {
            setLoader(true)
        }
        
        if( spaceState.length == 0 && virtualSpaceList.length == 0 && taskFilters.length > 0){
            setLoader(false)
            // handleCreateChatGroup(0, "myr_support", user_type, localStorage.getItem("staff_id"));
        }
    }, [taskFilters])
    
    useEffect(() => {      
        let tempTopics = []
        let filter = []
        if (taskFilters.length == 0 && projectId > 0 && (spaceState.length > 0 || virtualSpaceList.length > 0 )&& virtualSpaceClientId != "undefined") {
            if ((user_type == "contact" || user_type == "operator") && contact_role == 3) {
                hideLeftPanelNew();
                handleCreateChatGroup(projectId,user_type == "contact" ? "my_contact" : "my_operator", "people", localStorage.getItem("staff_id"))
            } else {
                // if (user_type != "staff") {
                //     let cleaningArr = defaultTopics && defaultTopics.filter((topic) => topic.main_key == "providers")
                //     if (cleaningArr.length > 0) {
                //      let isCleaning = cleaningArr.categories && cleaningArr.categories.filter((category) => category.filter_key == 21  && category.is_active == 1)
                //      if (isCleaning && isCleaning.length > 0) {
                //       dispatch(leftPanelFilters("","","applyCleaningFilter"))
                //       handleCreateChatGroup(projectId,"providers", "providers", 21)      
                //      }
                //      return
                //     }
                // }
                showLeftPanelNew();
                if (defaultTopics && lastChatSession && lastChatSession.length > 0) { 
                    if (lastChatSession[0].main_key == "myr_support" && user_type != "staff") {
                        tempTopics = defaultTopics.filter((topic) => topic.main_key == "providers")              
                    } else {
                        tempTopics = defaultTopics.filter((topic) => topic.main_key == lastChatSession[0].main_key)
                    }
                }
                if (tempTopics.length) {
                    if (lastChatSession[0].filter_key ==  localStorage.getItem("staff_id") && user_type != "staff") {
                        filter = tempTopics[0].categories.filter((category) => category.filter_key == "myrai_support")              
                    } else {
                        filter = tempTopics[0].categories.filter((category) => category.filter_key == lastChatSession[0].filter_key && category.is_active == 1)
                    }
                }
                if (filter.length > 0) {
                    filter.map((category) => {
                        let mainKey = category.filter_key == "myrai_support" ? "myr_support" : user_type == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key
                        let filterKey = category.filter_key == "myrai_support" ? localStorage.getItem("staff_id") : category.filter_key
                        let mainSubKey =  category.filter_key == "myrai_support" ? user_type : category.main_sub_key
                        localStorage.setItem("myrUserId",category.contact_id)
                        localStorage.setItem("selectedTopic",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key, total_notification_count: category.total_notification_count, thread_id : category.thread_id ,filter_key: category.filter_key,image:category.image}))
                        handleCreateChatGroup(projectId,mainKey, mainSubKey, filterKey)
                        localStorage.setItem("callerInfo",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key, total_notification_count: category.total_notification_count, thread_id : category.thread_id ,filter_key: category.filter_key,image:category.image}))
                        handleCreateChatGroup(projectId,mainKey, mainSubKey, filterKey)
                    })
                }else if(defaultTopics && lastChatSession && lastChatSession.length > 0){
                    dispatch(leftPanelFilters("","","applyCleaningFilter"))
                }
            }
            // if (filter.length > 0) {
            //     filter.map((category) => {
            //         let mainKey = category.filter_key == "myrai_support" ? "myr_support" : user_type == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key
            //         let filterKey = category.filter_key == "myrai_support" ? localStorage.getItem("staff_id") : category.filter_key
            //         let mainSubKey =  category.filter_key == "myrai_support" ? user_type : category.main_sub_key
            //         // dispatch(
            //         //   leftPanelFilters({
            //         //     main_key: category.filter_key == "myrai_support" ? "providers" : user_type == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key ,
            //         //     filter_key: category.filter_key,
            //         //     title: category.title,
            //         //     image: category.image,
            //         //     main_sub_key: category.main_sub_key,
            //         //     sub_category: category.sub_category ? category.sub_category : [],
            //         //   })
            //         // );
            //         localStorage.setItem("myrUserId",category.contact_id)
            //         localStorage.setItem("selectedTopic",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key, contact_role : category.contact_role, total_notification_count: category.total_notification_count, thread_id : category.thread_id, filter_key: category.filter_key}))
            //         handleCreateChatGroup(projectId,mainKey, mainSubKey, filterKey)
            //     })
            // }
        } else if (spaceState.length == 0 && virtualSpaceList.length == 0 && taskFilters.length == 0 && localStorage.getItem("is_registered") && leftPanelTab != "my-agents") {
            dispatch(leftPanelFilters({
                main_key: "providers",
                filter_key: "myrai_support",
                title: "Myr.ai Support",
                image: "",
                main_sub_key: "providers",
                sub_category: [],
            }))
            // handleCreateChatGroup(0, "myr_support", user_type, localStorage.getItem("staff_id"));
        }
    }, [JSON.stringify(taskFilters), projectId, spaceState, virtualSpaceClientId, JSON.stringify(lastChatSession), JSON.stringify(defaultTopics)])

    useEffect(() => {
        if (spaceState.length == 0 && virtualSpaceList.length == 0) {
            
        }else{
        if (lastAppliedFilter.main_key != "date_range_filter") {
            setLoader(true);  
        }else if(lastAppliedFilter.title != "date_range_filter"){
            dispatch(fetchMessages(         
                localStorage.getItem("chatUserId"), 
                localStorage.getItem("activeGroupId"),
                0,
                setmsgloader,
                1,
                "",
                localStorage.getItem("fromDate"),
                localStorage.getItem("toDate"),
                ))
        }else{
            dispatch(fetchMessages(         
                localStorage.getItem("chatUserId"), 
                localStorage.getItem("activeGroupId"),
                0,
                setmsgloader,
                1,
                "",
                "",
                "",
            ))

        }
    }
    }, [lastAppliedFilter])
    

    useEffect(() => {
        if(activeGroupId != 0 && activeGroupId ){
            let groupID = activeGroupId
            setChatGroupID(groupID)
            let selectedTopicUnreadChatCount = localStorage.getItem("selectedTopicUnreadChatCount") && localStorage.getItem("selectedTopicUnreadChatCount") > 20 ? localStorage.getItem("selectedTopicUnreadChatCount") : 20
            dispatch(fetchMessages(          
                localStorage.getItem("chatUserId"), 
                localStorage.getItem("activeGroupId"),
                0,
                ()=>{},
                1,
                "",
                lastAppliedFilter.title != "date_range_filter" ? localStorage.getItem("fromDate") : "",
                lastAppliedFilter.title != "date_range_filter" ? localStorage.getItem("toDate") : "",
                selectedTopicUnreadChatCount
                ))      
            setLoader(false);
            chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
                if (res.success) {
                    localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
                }else{
                    setLoader(false);
                    dispatch(showMessage("unsucess", _l("l_error"),res.message));
                }
            });
        }else{
            setTimeout(() => {
                setLoader(false);
            }, 5000);
        }
    }, [activeGroupId]);

    useEffect(() => {
        if(rel_type){
            let tempProjectId = searchParams.get("project_id");
            let mainKey = "myr_support"
            let filterKey = localStorage.getItem("staff_id") 
            let mainSubKey = user_type 
            dispatch(leftPanelFilters({
                main_key:  "providers" ,
                filter_key: "myrai_support",
                title: "Myr.ai Support",
                image: "",
                main_sub_key: "providers",
                sub_category: [],
            }));
            handleCreateChatGroup(tempProjectId,mainKey, mainSubKey, filterKey)
        }
        // dispatch(setSelectedDateView("dayView"));
        // dispatch(setDaterangeFilterApplied(false));
        // dispatch(setDaterangeFilter("today"))
        // dispatch(setShowWeekcalendardays(true));

        document.body.classList.remove("px-0");
        document.body.classList.remove("glow-shadow");


    }, []);

    useEffect(() => {
        if(isRegisterUser == 1){
            if ((user_type == "operator" && spaces && spaces.length > 0)) {
                showLeftPanelNew();
            }else{
                hideLeftPanelNew();
                jquery("body").removeClass("px-2");
            }
        } 
    });

    useEffect(() => {
        if (isRegisterUser == 1 && chatUserID) {
            if ((user_type == "operator" && spaces && spaces.length > 0)) {
                showLeftPanelNew();
            }else{
                dispatch(fetchAuthToken(email, contact_id, "client"));
                let project_Id = localStorage.getItem("chat_project_id")
                dispatch(leftPanelFilters({
                    main_key:"providers" ,
                    filter_key: "myrai_support",
                    title: "Myr.ai Support",
                    image: "",
                    main_sub_key: "providers",
                    sub_category: [],
                }));
                handleCreateChatGroup(providerWallet == 0 ? 0 : project_Id,"myr_support",user_type,localStorage.getItem("staff_id"))    
            }
        }else if(isRegisterUser == 1 && taskFilters.length == 0){
            let project_Id = localStorage.getItem("chat_project_id")
            dispatch(leftPanelFilters({
                main_key:"providers" ,
                filter_key: "myrai_support",
                title: "Myr.ai Support",
                image: "",
                main_sub_key: user_type,
                sub_category: [],
            }));
            handleCreateChatGroup(project_Id,"myr_support",user_type,localStorage.getItem("staff_id"))   
        }
    }, [isRegisterUser, chatUserID]);

    // useEffect(() => {
    //     let preDate = ""
    //     if (taskFilters.length > 0 && isRegisterUser != 1) {
    //         let startDate = selectStartDate
    //         let endDate = selectStartDate

    //         let ChatStartDate = chatStartDate
    //         let ChatendDate = chatEndDate
    //         startDate = moment(calendarData && calendarData[0].date)
    //         endDate = moment(calendarData && calendarData[calendarData.length - 1].date)
    //         if(localStorage.getItem("is_external_user") == 1){
    //             dispatch(updateTaskList([]));
    //         }
    //         // else if (!moment(selectStartDate).isBetween(moment(ChatStartDate), moment(ChatendDate)) || activeGroupId != chatGroupID) {
    //         // console.log("selectStartDate",formateDate(selectStartDate),"ChatStartDate",formateDate(ChatStartDate),"ChatendDate",formateDate(ChatendDate))
    //         dispatch(getTaskList(
    //             client_id,
    //             contact_id,
    //             localStorage.getItem("selectedOffice"),
    //             activeTaskStatusFilters.length
    //             ? ""
    //             : moment(ChatAiDate).format("YYYY-MM-DD"),
    //             activeTaskStatusFilters.length ? 2 : 1,
    //             activeTaskStatusFilters,
    //             taskFilters,
    //             "",
    //             0,
    //             // 0,
    //             // ^ open this line and commment next line to get all the tasks of selectes topics,
    //             1,
    //             startDate,
    //             endDate,
    //             1
    //         ));

    //         if(localStorage.getItem("activeGroupId")){
    //             dispatch(fetchMessages(
    //                 localStorage.getItem("chatUserId"),
    //                 localStorage.getItem("activeGroupId"),
    //                 0,
    //                 setmsgloader,
    //                 1,
    //                 startDate,
    //                 endDate
    //             ));
    //         }
    //         // }
    //         moment(ChatStartDate).format("YYYY-MM-DD") < moment(startDate).format("YYYY-MM-DD") ? setchatStartDate(ChatStartDate) : setchatStartDate(startDate);
    //         moment(ChatendDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD") ? setchatEndDate(ChatendDate) : setchatEndDate(endDate);

    //         preDate = selectStartDate
    //     }
    // }, [taskFilters, activeTaskStatusFilters, selectStartDate, ChatAiDate])

    useEffect(() => {
        dispatch(setFetchedTaskandMessages([]))
        setchatStartDate(new Date())
        setchatEndDate(new Date())
        if(userPlan){
            setSearchParams(searchParams);
            let baseTimerValue = welcomeLoaderTimer;
            const interval = setInterval(() => {
                setWelcomeLoaderTimer(baseTimerValue);
                baseTimerValue -= 1000;
                if(baseTimerValue <= 0){
                    searchParams.delete("plan");
                    setSearchParams(searchParams);
                    clearInterval(interval);
                }
            }, 1000);
        }
        // setLoader(true)
        // dispatch(setTasklistLoader(false))
        localStorage.setItem("callTaskAPi",false);
    }, [])

    useEffect(() => {
        if((user_type == "contact" || user_type == "operator") && contact_role == 3 && projectId > 0){
            let unread = spaceState.filter((space)=> space.project_id == projectId)
            if(unread.length > 0){
                unread = unread[0].chat_notification_count
            }else{
                unread = 0
            }
            if(unread > 0){
                localStorage.setItem("unread_chat_notification_count", unread)
            }else{
                setTimeout(() => {
                    localStorage.removeItem("unread_chat_notification_count", unread)        
                },10000);
            }
        }else{
            let unread = spaceState.filter((space)=> space.project_id == projectId)
            if(unread.length > 0 && unread[0].chat_notification_count > 0){
                clearUnreadMessagesCount()
            }

        }
    }, [JSON.stringify(spaceState), user_type, projectId])

    // useEffect(() => {
    //     if (loder == false) {
    //         jQuery(".with-bottom-position").addClass("Footerfix");
    //         setTimeout(() => {
    //             jQuery(".with-bottom-position").removeClass("Footerfix");      
    //         }, 2000);    
    //     }
    // }, [loder])

    useEffect(() => {
        clearUnreadMessagesCount()
    }, [unreadMessage])  

    const handleCreateChatGroup =(projectId,main_key,main_sub_key,filter_key)=>{
        setLoader(true);
        setchatStartDate(new Date())
        setchatEndDate(new Date())
        setSelectStartDate(new Date())
        // dispatch(setSelectedDateForChatai(new Date()));
        localStorage.removeItem("firstDate");
        localStorage.removeItem("lastDate");
        dispatch(setFetchedTaskandMessages([]));
        // if(groupList && groupList.length > 0){
        //   let tempGroup = groupList.filter((group) => parseInt(group.extraData.filterKey) == parseInt(filter_key))
        //   if(tempGroup && tempGroup.length > 0){
        //    let groupID = tempGroup[0]._id
        //    localStorage.setItem("activeGroupId", groupID);
        //    chatServices.getGroupDetails(chatUserID, groupID).then((res) => {
        //     if (res.success) {
        //       var activeGroupId = localStorage.getItem("activeGroupId");
        //       if (chatUserID != "" && activeGroupId != "" && activeGroupId != undefined) {
        //         var socketData = {
        //           groupId: activeGroupId,
        //           userId: chatUserID,
        //         };
        //         dispatch(groupIn(socketData));
        //       }
        //         dispatch(getGroupDetails(res.result))
        //       dispatch(fetchMessages(chatUserID, groupID,0,setLoader));
        //     }else{
        //       dispatch(showMessage("unsucess", _l("l_error"),res.message));
        //     }
        //   });
        //   }
        // }else{
        customerServices.createChatGroup(projectId,main_key,main_sub_key,filter_key).then((res)=>{
            if(tasklistLoader == false){
                setLoader(false)
            }
            if(res.status && res.data){
                let groupID = res.data._id
                setChatGroupID(groupID)
                localStorage.setItem("activeGroupId", groupID);
                chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
                    if (res.success) {
                        dispatch(getGroupDetails(res.result))
                        localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
                        dispatch(fetchMessages(         
                            localStorage.getItem("chatUserId"), 
                            localStorage.getItem("activeGroupId"),
                            0,
                            ()=>{},
                            1,
                            ""
                            ))
                        setLoader(false)
                    }else{
                        setLoader(false)
                        dispatch(showMessage("unsucess", _l("l_error"),res.message));
                    }
                });        
            }else{
                dispatch(showMessage("unsucess", _l("l_error"),_l(res.message)));
                setLoader(false)
            }
        })
    }

     const clearUnreadMessagesCount = () => {
        setTimeout(() => {
            localStorage.removeItem("unread_chat_notification_count")        
        }, 10000);
     }
    useEffect(() => {
        if (!loder) {
            dispatch(fullScreenLoaderChangeSpace(false))
        }
    },[loder])
    
    return (
        <div className="d-flex flex-column flex-grow-1 overflow-auto ai-chat-header position-relative">
            {userPlan ?
            <div className="absoluteLoader">
                <AcknowledgeScreen
                    afterDismissAction={() => {}}
                    delay={7000}
                    steps={ACKNOWLEDGEMENT_STEPS[userPlan]}
                    description={"l_feature_allows_experience_entirely_new_way_interacting"}
                    title={"l_onboarding_acknowledgement_header_title"}
                />
            </div>
            :<React.Fragment>
                {fullScreenLoadChanSpace ? <></> 
                : <ChatPage
                    pageName="ChatAi"
                    chatAIgroupdeatils={groupDetails}
                    loder={false}
                    setLoder={() => {}}
                    setshowDate={setSelectStartDate}
                    selectStartDate={selectStartDate}
                    msgloader={msgloader}          
                /> 
                }
            </React.Fragment>
            }
        </div>
    );
};

export default AiChat;
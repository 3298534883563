import React, { useEffect, useRef, useState } from "react";
import {
  _l,
  formateDate,
  showError,
  getPreviewFromURL,
  getHoursBetweenDates,
} from "../hooks/utilities";
import OnboardingHeader from "../components/SpaceOnBoarding/OnboardingHeader";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../actions/messages";
import moment from "moment";
import ListingWithCheckbox from "../components/Leads/ListingWithCheckbox";
import CustomerServices from "../services/customer-services";
import OnboardingFooter from "../components/SpaceOnBoarding/OnboardingFooter";
import AgentInformation from "../components/RegistrationFlow/AgentInformation";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import AuthServices from "../services/auth-services";
import OperatorForm from "../components/RegistrationFlow/OperatorForm";
import {
  DEFAULT_ADDRESS,
  DEFAULT_REGION_ID,
  DEFAULT_REGION_NAME,
} from "../constants/constants";
import Address from "../components/SpaceOnBoarding/SpaceDetails/Address";
import customerServices from "../services/customer-services";
import documentsServices from "../services/documents-services";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  Check,
  CloudArrowUp,
  Plus,
  UserCircle,
  X,
} from "phosphor-react";
import ScheduleSetup from "../components/Modals/OnboardingReusableComponents/ScheduleSetup";
import CommanPlaceholder from "../components/Placeholder/CommanPlaceholder";
import XIcon from "../assets/icons/XIcon";
import { getCompanylistOfInvitation } from "../actions/customer";
import SetupAvilablityForOnBoarding from "../components/Modals/SetupAvilablityForOnBoarding";
import Geocode from "react-geocode";


const REGISTER_USER_TYPE = {
  CUSTOMER: 1,
  PROVIDER: 2,
  AGENT: 3,
};

const AgentHireFlow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const availabilityRef = useRef();

  const [searchParams] = useSearchParams();
  let type = searchParams.get("user");

  const { messageList } = useSelector((state) => state.chat);
  const { inviteeCompanyList } = useSelector((state) => state.customer);

  const [currentStep, setCurrentStep] = useState(
    type == "agent-find-job"
      ? "SetAvailability"
      : type == "company_training"
      ? "companySelection"
      : "categoryList"
  );
  const [formData, setFormData] = useState({
    registerUserType: 0,
    userSubscriptionPlan: 0,
  });
  const [agentDocuments, setAgentDocuments] = useState({
    id_card: {
      title: "l_id_card",
      preview: [],
      key :"id_card"
    },
    // company_id: {
    //   title: "l_company_id",
    //   preview: [],
    // },
    payment: {
      title: "l_iban_label",
      preview: [],
      key :"payment"
    },
    insurance: {
      title: "l_insurance_documents",
      preview: [],
      key :"insurance"
    },
    profile_image: {
      title: "l_profile_image_upload",
      preview: [],
      key :"profile_image"

    },
  });
  const [agentSlotsOld, setAgentSlotsOld] = useState([
    {
      start_time: "",
      end_time: "",
      rate: "",
      slots: {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
      },
    },
  ]);
  const [agentSlots, setAgentSlots] = useState([
    {
      fromDate:"",
      toDate:"",
      from: "",
      to: "",
      days: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      total_hours: "0",
      hours: 0,
    },
  ]);
  const [operatorFormData, setOperatorFormData] = useState({
    mission_address: "",
    mission_region: "",
    mission_region_name :"",
    service_start_date: moment(new Date()).format("YYYY-MM-DD"),
    service_end_date: formateDate(new Date(moment().add(10, "days"))),
    unknown_end_date: 0,
    contract_description: "",
    max_hiring : "",
    slots: [
      {
        start_time: "",
        end_time: "",
        rate: "",
        slots: {
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0,
        },
      },
    ],
  });
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const [contactDetail, setContactDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    country_code: "+33",
    company_id: 0,
    password: "",
    company_name: "",
    address: "",
    staff_id: "",
    contact_id: "",
    client_id: "",
    project_id: "",
    is_external: "",
    full_name: "",
    termsAndConditions: true,
    invoice_tax: { value: 0, label: "" },
  });
  const [spaceDetail, setSpaceDetail] = useState({
    address: DEFAULT_ADDRESS,
    region_id: DEFAULT_REGION_ID,
    region_name: DEFAULT_REGION_NAME,
  });
  const [imagePreviews, setImagePreviews] = useState({
    l_id_card_upload: { key: "id_card", previewArray: [{ url: "" }] },
    l_iban: { key: "payment", previewArray: [{ url: "" }] },
    l_insurance_upload: { key: "insurance", previewArray: [{ url: "" }] },
    l_profile_image: { key: "profile_image", previewArray: [{ url: "" }] },
  });

  const [backButtonText, setBackButtonText] = useState("");
  const [nextButtonText, setNextButtonText] = useState("");
  const [description, setDescription] = useState("");
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [categoryListing, setCategoryListing] = useState([]);
  const [selectedCategoryListing, setSelectedCategoryListing] = useState([]);
  const [lastMessage, setLastMessage] = useState("");
  const [operatorContract, setOperatorContract] = useState();
  const [operatorContractPreview, setOperatorContractPreview] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [profileImage, setProfileImage] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);

  const saveCategories = () => {
    const selectedCategories = selectedCategory.length > 0 ? selectedCategory.filter((w) => w.checked) : categoryListing.filter((w) => w.checked);
    setSelectedCategoryListing(selectedCategories);
    if (!selectedCategories.length) {
      showError("l_please_select_category");
    } else {
      setCurrentStep(
        type == "agent-find-job" || type == "company_training"
          ? "agentInformation"
          : "operatorAvailability"
      );
    }
  };

  const handleSetupAvailability = () => {
    let { slots } = operatorFormData;

    if (!validateSlots(slots)) {
      return false;
    }

    slots = slots.filter((slot) =>
      Object.values(slot.slots).some((w) => w == 1)
    );

    if (!operatorFormData.service_start_date) {
      showError("l_please_select_the_start_date");
    } else if (!slots.length) {
      showError("l_one_or_more_row_has_all_emply_slots");
    } else if (slots.filter((w) => !w.start_time || !w.end_time).length) {
      showError("l_start_and_end_time_mandatory");
    } else if(slots.filter((w) => (w.start_time != "" || w.end_time != "") && w.rate == "").length){
      showError("l_rate_by_hour_mandatory");
    }else if(operatorFormData.mission_address == ""){
      showError("l_please_enter_address")
    // }else if(!operatorContract){
    //   showError("l_upload_contract")
    }else if(operatorFormData.max_hiring == ""){
      showError("l_hiring_placeholder")
    }else{
      setLoadingNextStep(true);
      operatorFormData.slots.forEach((w) => {
        if (w.start_time && w.start_time != "" && moment(new Date(w.start_time)).isValid()) {
          w.start_time = moment(w.start_time).format("hh:mm A");
        }
        if (w.end_time && w.end_time != "" && moment(new Date(w.end_time)).isValid()) {
          w.end_time = moment(w.end_time).format("hh:mm A");
        }
      });
      let tempData =({...operatorFormData,slots: operatorFormData.slots.filter((w)=> w.start_time !="" && w.end_time != "")});
      AuthServices.hireNewAgent(tempData, operatorContract,selectedCategory.length > 0  ? selectedCategory : selectedCategoryListing).then(
        (res) => {
          if (res && res.status) {
            setTimeout(() => {
              navigate("/onboarding-proposal?view=job-prposal");
            }, 500);
          }
          setLoadingNextStep(false);
        }
      );
    }
  };

  const getDifferenceInHours = (start_time, end_time) => {
    return Number(
      Math.abs(
        moment.duration(moment(end_time).diff(moment(start_time))).asHours()
      ).toFixed(2)
    );
  };

  const calculateAccumulateHours = (dayName) => {
    let totalHours = 0;

    if (dayName === "all") {
      agentSlots.forEach((slot) => {
        Object.keys(slot.days).forEach((day) => {
          if (slot.days[day] == true && slot.from && slot.to) {
            totalHours += getDifferenceInHours(slot.from, slot.to);
          }
        });
      });
    } else {
      agentSlots.forEach((slot) => {
        if (slot.days[dayName] == true && slot.from && slot.to) {
          totalHours += getDifferenceInHours(slot.from, slot.to);
        }
      });
    }
    return totalHours;
  };

  const handleAgentRegistration = () => {
    setLoadingNextStep(true);
    documentsServices
      .uploadEmployeeDocuments(agentDocuments, localStorage.getItem("staff_id"))
      .then((response) => {
        if (response && response.status) {
          navigate("/aichat");
        } else {
          setLoadingNextStep(false);
          showError(response.message);
        }
      });
  };

  const handleAgentAvailability = () => {
    let validDocumentsUploaded = true;
    let skippedDocuments = [];

    Object.keys(agentDocuments).forEach((documentType) => {
      if (
        !["profile_image", "company_id"].includes(documentType) &&
        !agentDocuments[documentType].attachments
      ) {
        validDocumentsUploaded = false;
        skippedDocuments.push(documentType);
      }
    });

    if (
      formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
      formData.userSubscriptionPlan == 1 &&
      !validateSlots(agentSlots)
    ) {
      return false;
    }
    if (
      calculateAccumulateHours("all") != 0
    ) {
      if (validDocumentsUploaded) {
        handleAgentRegistration();
      } else {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            `${_l("l_upload_all_required_documents")}: ${skippedDocuments.join(
              ", "
            )}`
          )
        );
      }
    } else {
      showError("l_please_select_timeslot");
    }
  };

  const handleStepChange = (direction) => {
    if (direction > 0) {
      switch (currentStep) {
        case "address":
          if (spaceDetail.address != "") {
            setCurrentStep("categoryList");
          } else {
            showError(_l("l_please_enter_company_address"));
          }
          break;
        case "SetAvailability":
          if (
            calculateAccumulateHours("all") != 0
          ) {
            // SetAvailabilityForAgentRegistration();
            handleSave();
          } else {
            showError("l_please_select_timeslot");
          }
          break;
        case "uploadDocuments":
          handleAgentAvailability();
          break;
        case "categoryList":
          saveCategories();
          break;
        case "operatorAvailability":
          handleSetupAvailability();
          break;
        case "agentInformation":
          handleAgentAvailability();
          break;
      }
    } else {
      switch (currentStep) {
        case "operatorAvailability":
          setCurrentStep("categoryList");
          break;
        case "agentInformation":
          setCurrentStep("categoryList");
          break;
        case "categoryList":
          setCurrentStep("address");
          break;
        case "uploadDocuments":
          setCurrentStep("SetAvailability");
          break;
      }
    }
  };

  const validateSlots = (slots) => {
    let flag = true;
    slots = slots.filter((slot) =>
      Object.values(slot.slots).some((w) => w == 1)
    );
    if (!operatorFormData.service_start_date) {
      flag = false;
      showError("l_please_select_the_start_date");
    } else if (!slots.length) {
      flag = false;
      showError("l_one_or_more_row_has_all_emply_slots");
    } else if (slots.filter((w) => !w.start_time || !w.end_time).length) {
      flag = false;
      showError("l_start_and_end_time_mandatory");
    }
    // else if (slots.filter((w) => !w.rate || w.rate <= 0).length) {
    //   flag = false;
    //   showError("l_rate_is_required");
    // }
    return flag;
  };

  const updateTextualContent = (stepName) => {
    let backButtonTxt = "";
    let nextButtonTxt = "";
    let titleTxt = "";
    let descriptionTxt = "";

    switch (stepName) {
      case "SetAvailability":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_setup_avilability";
        descriptionTxt = "l_setup_availability_and_documents";
        break;
      case "companySelection":
        backButtonTxt = "";
        nextButtonTxt = "";
        titleTxt = "l_companies";
        descriptionTxt = "l_agent_company_description";
        break;
      case "uploadDocuments":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_upload_documents";
        descriptionTxt = "l_upload_documents_description";
        break;
      case "categoryList":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_service_categories";
        descriptionTxt = "l_service_categories_description";
        break;
      case "address":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_space_address_title";
        descriptionTxt = "l_space_address_subtitle";
        break;
      case "operatorAvailability":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_setup_avilability";
        descriptionTxt = "";
        break;
      case "agentInformation":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_setup_availability_and_documents";
        descriptionTxt = "";
    }
    setBackButtonText(backButtonTxt);
    setNextButtonText(nextButtonTxt);
    setTitleText(titleTxt);
    setDescription(descriptionTxt);
  };

  const addNewCategory = async (label) => {
    try {
      const response = await CustomerServices.addCategory(
        label,
        undefined,
        undefined,
        {},
        undefined,
        undefined,
        [],
        1
      );
      if (response.status == 1) {
        setCategoryListing(
          [{ label, value: 0, checked: true, addedRealTime: true }].concat(
            categoryListing
          )
        );
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };

  const handleAddNewCategory = async (label) => {
    if (
      formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
      formData.userSubscriptionPlan == 2
    ) {
      addNewCategory(label);
    } else {
      setCategoryListing(
        [{ label, value: 0, checked: true }].concat(categoryListing)
      );
    }
  };

  const getCategories = async () => {
    try {
      let isDefault = 1;
      const response = await CustomerServices.getDefaultTopicList(0);
      if (response.status) {
        let allCategories = response.data.map((w) => {
          return {
            label: w.name,
            value: w.id,
            client_id: w.client_id,
            is_default: w.is_default,
          };
        });
        allCategories.sort((a, b) => a.label.localeCompare(b.label));
        const defaultCategories = allCategories.filter(
          (w) => w.is_default == 1
        );
        const otherCategories = allCategories.filter(
          (w, index) =>
            allCategories.findIndex(
              (z, i) =>
                z.label &&
                w.label &&
                z.label.trim().toLowerCase() === w.label.trim().toLowerCase() &&
                i !== index
            ) === -1 && w.is_default == 0
        );
        setCategoryListing(defaultCategories.concat(otherCategories));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAccept = (details) => {
    customerServices.acceptCompanyInvitation(details.id, details.client_id).then((res)=>{
      if (res && res.status) {
        navigate("/aichat");
      }else{
        showError(res.message);
      }
    })
  }
  
  const handleReject = (details) => {
    customerServices.rejectCompanyInvitation(details.id, details.client_id).then((res)=>{
      if (res && res.status) {
        navigate("/aichat");
      }else{
        showError(res.message);
      }

    })
  }

  const handleSave = async() => {
    try {
      const res = await customerServices.addOperatorAvaibility(
        agentSlots,
        1,
        0
      );
      if (res.status == 1) {
        dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
        setCurrentStep("uploadDocuments")
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
    }
  };
  
  const SetupAvailability = ({
    slots,
    setSlots,
    documents,
    setDocuments,
    onlyTraining,
    type = "",
    setSelectedCompany = () => {},
  }) => {
    const appendSlots = () => {
      const blankSlots = slots.filter((w) =>
        Object.values(w.slots).every((z) => z == 0)
      );

      if (!blankSlots.length) {
        setSlots(
          slots.concat({
            start_time: "",
            end_time: "",
            rate: "",
            slots: {
              mon: 0,
              tue: 0,
              wed: 0,
              thu: 0,
              fri: 0,
              sat: 0,
              sun: 0,
            },
          })
        );
      }
    };
    return (
      <>
        <div className="d-flex flex-column flex-grow-1 h-100">
          <div className="w-auto h-100 d-flex flex-column flex-grow-1 pb-0">
            <div>
              <div className="d-flex align-items-center justify-content-between mb-32px">
                <Button
                  variant="white-05"
                  className="d-flex align-items-center"
                  onClick={appendSlots}
                >
                  <Plus
                    weight="light"
                    height="12"
                    width="12"
                    className="c-icons me-2"
                  />
                  <span>{_l("l_add_new_duration")}</span>
                </Button>
                <div className="d-flex align-items-center">
                  <span>{_l("l_available")}</span>
                  <Form.Check
                    className={`ms-2`}
                    type="switch"
                    disabled
                    checked
                  />
                </div>
              </div>
              <div className="d-flex fw-bold align-items-center justify-content-between bg-white-03 p-20px light-theme-grey-light-bg">
                <div className="">{_l("l_my_availability")}</div>
                <div className="d-flex fw-bold align-items-center justify-content-between">
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("mon")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_week_single_letter_monday")}
                    </div>
                  </div>
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("tue")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_week_single_letter_tuesday")}
                    </div>
                  </div>
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("wed")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_week_single_letter_wednesday")}
                    </div>
                  </div>
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("thu")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_week_single_letter_thrusday")}
                    </div>
                  </div>
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("fri")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_week_single_letter_friday")}
                    </div>
                  </div>
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("sat")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_week_single_letter_saturday")}
                    </div>
                  </div>
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("sun")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_week_single_letter_sunday")}
                    </div>
                  </div>
                  <div className="ms-4">
                    <div>{calculateAccumulateHours("all")}</div>
                    <div className="fw-light c-font f-11">
                      {_l("l_total_hours")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <ScheduleSetup
                setSlots={setSlots}
                slots={slots}
                hideRateField={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const UploadDocumentsForAvailabilty = ({
    documents,
    setDocuments,
  }) => {
    const handleDocumentUpload = (documentType, files) => {
      if (documentType === "profile_image") {
        Array.from(files).map((w) => {
          setProfileImage(URL.createObjectURL(w))
        })
      }
      if (documents[documentType].attachments) {
        setDocuments({
          ...documents,
          [documentType]: {
            ...documents[documentType],
            file: files,
            preview: Array.from(documents[documentType].preview).concat(
              Array.from(files).map((w) => {
                return {
                  name: w.name,
                  type: w.type,
                  url: URL.createObjectURL(w),
                };
              })
            ),
            attachments: Array.from(documents[documentType].attachments).concat(
              files
            ),
          },
        });
      } else {
        setDocuments({
          ...documents,
          [documentType]: {
            ...documents[documentType],
            file: files,
            preview: Array.from(files).map((w) => {
              return {
                name: w.name,
                type: w.type,
                url: URL.createObjectURL(w),
              };
            }),
            attachments: files,
          },
        });
      }
    };

    const removeAttachment = (documentType, attachment) => {
      setDocuments({
        ...documents,
        [documentType]: {
          ...documents[documentType],
          attachments:
            Array.from(documents[documentType].attachments).length === 1
              ? undefined
              : Array.from(documents[documentType].attachments).filter(
                  (w) => w.name != attachment.name
                ),
          preview: documents[documentType].preview.filter(
            (w) => w.name != attachment.name
          ),
        },
      });
    };
    return (
      <>
        <div className="">
          <div className="">
            <div className="">
             
              <div
                    className="form-control  file-upload with-bg position-relative mt-5 comman-round-box d-flex flex-column p-0 file-upload-main"
                    // style={{ height: "250px" }}
                  >                   
                    <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                      <input
                        className="z-index-3 hidden-input m-auto"
                        type="file"
                        accept="image/*"
                        onInput={(e) => {
                          handleDocumentUpload(
                            "profile_image",
                            e.target.files
                          );
                        }}
                        
                      />
                      <div
                        className="m-auto text-center comman-bg-img h-100 w-100 bg-style-cover d-flex flex-column rounded-circle"
                        style={{
                          backgroundImage: `url(${profileImage})`,
                        }}
                      >
                        {profileImage ? <></> :
                        <div className="m-auto rounded-circle">
                            <>
                              <div>
                                <UserCircle
                                  size={65}
                                  weight="light"
                                  className="c-icons m-0  opacity-60"
                                />
                              </div>
                              <div className="c-font color-white f-14 fw-bold title-fonts">
                                {_l("l_profile")} {_l("l_image")}
                              </div>
                              <div className="c-font color-white-60 f-10 ">
                                PNG, JPG ( MAX. 800 x 800 )
                              </div>
                            </>
                        </div>}
                      </div>
                    </div>
                    <div className="bg-base-header-color bottom-0 c end-0 h-100 position-absolute start-0 top-0 w-100 d-none">
                      <div
                        className="bg-style-cover comman-bg-img h-100 w-100"
                        style={{
                          backgroundImage: `url('')`,
                        }}
                      ></div>
                    </div>
                  </div>
              <Row className="mt-3">
                {Object.keys(documents).map((documentType, key) => {
                  return (
                    documentType != "profile_image" &&
                      <Col xs="4" key={key}>
                      <div className="c-font f-13 opacity-50 title-fonts mb-2">
                        {_l(documents[documentType].title)}
                      </div>
                      <div
                        className="form-control file-upload with-bg position-relative p-3 px-3 py-4 text-center"
                        style={{ height: "" }}
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 ">
                          <input
                            className="z-index-3 hidden-input m-auto"
                            type="file"
                            multiple={documentType !== "profile_image"}
                            accept="image/*,.pdf"
                            onInput={(e) => {
                              handleDocumentUpload(
                                documentType,
                                e.target.files
                              );
                            }}
                          />
                          <CloudArrowUp
                            weight="light"
                            height="25"
                            width="25"
                            className="c-icons opacity-50 m-0"
                          />
                          <span className="c-font color-white-60 f-12 ps-10px">
                            {_l("l_click_to_upload")}
                          </span>
                        </div>
                      </div>
                      <div className="upload-image-preview pt-3 d-flex flex-wrap">
                        {documents[documentType].preview.map(
                          (attachment, key) => {
                            let preview = {};
                            preview.url = getPreviewFromURL(attachment.name);
                            return (
                              <div className="h60w60 me-2" key={key}>
                                <div className="comman-image-box h40w40 radius_3 upload-image with-margin">
                                  {typeof preview.url === "string" ? (
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                      style={{
                                        backgroundImage: `url(${attachment.url})`,
                                      }}
                                       
                                       
                                       
                                    ></div>
                                  ) : (
                                    <div
                                      className="h-100 w-100 d-flex cursor-pointer"
                                      style={{
                                        alignContent: "center",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <preview.url
                                        size={72}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    </div>
                                  )}
                                  <a
                                    href="#/"
                                    className="upload-close"
                                    onClick={() =>
                                      removeAttachment(documentType, attachment)
                                    }
                                  >
                                    <X
                                      size={10}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                </div>
                                <div className="c-font color-white-60 f-12 text-truncate">
                                  {attachment.name}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  };

  const CompanySelectionStep = () => {
    return (
      <>
        <div className="mt-3">
          <div className="px-3 py-3 title-fonts fw-bold bg-white-03 light-theme-grey-light-bg">
            {_l("l_select_company_type")}
          </div>
          <div className="border-top mt-3">
            {inviteeCompanyList && inviteeCompanyList.length > 0 ? (
              inviteeCompanyList.map((company) => {
                return (
                  <>
                    <div className="mt-3 border border-5 radius10imp">
                      <div className="d-flex align-align-items-center h-100 flex-column p-20pximp gap-4 gap-md-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div className="text-truncate  with_separator_10 before-dnone pe-20pximp">
                            <div className="d-flex align-items-center">
                              <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                                {company.company}
                              </div>
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                             {company.address}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between comman_action_icon">
                            <a
                              href="#/"
                              className="btn btn-primary position-relative btn-sm light-theme-blue-bg radius_5"
                              onClick={() => {
                                handleAccept(company);
                              }}
                            >
                              <Check
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                              <span className="ms-10px">{_l("l_accept")}</span>
                            </a>
                            <a
                              href="#/"
                              className="btn btn-danger position-relative btn-sm light-theme-blue-bg radius_5 ml-10"
                              onClick={() => {
                                handleReject(company);
                              }}
                            >
                              <XIcon
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                              <span className="ms-10px">{_l("l_reject")}</span>
                            </a>
                          </div>
                        </div>
                        {/* <div className="align-items-start align-items-md-center flex-wrap d-flex flex-column  flex-md-row gap-3 gap-md-0 d-none">
                          <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              14/02/2024
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_start_date")}
                            </div>
                          </div>
                          <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              €12.00
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_budget")}
                            </div>
                          </div>
                          <div className="with_separator_10 flex-grow-1 ps-20pximp pe-20pximp">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              12m²
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_superficy")}
                            </div>
                          </div>
                          <div className="with_separator_10 flex-grow-1 ps-20pximp">
                            <div className="c-font f-16 fw-semibold title-fonts">
                              {120}
                            </div>
                            <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                              {_l("l_proposal") + " " + _l("l_amount")}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <></>
            )}

          </div>
          {
            inviteeCompanyList && inviteeCompanyList.length == 0 &&  
            <div className="d-flex flex-column">
            <div className="mt-3">
              <CommanPlaceholder
                imgType="no-notes"
                placeholderText={_l("l_no_company_list")}
              />
              </div>
            </div>          }
        </div>
      </>
    );
  };

  const selectSpaceRegion = (addressComponent) => {
    let regionId = 0;
    let regionName = "";
    let fetchedRegion = "";
    let index = addressComponent.findIndex(
      (add) => add.types && add.types.includes("administrative_area_level_2")
    );
    if (index === -1) {
      index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_1")
      );
    }
    if (index > -1) {
      fetchedRegion = addressComponent[index].long_name;
    }
    regionList.forEach((region) => {
      if (
        region.label.toLowerCase().trim() == fetchedRegion.toLowerCase().trim()
      ) {
        regionId = region.value;
        regionName = region.label;
      }
    });
    if (regionId == 0) {
      // setRegionList(regionList.concat([{ value: 0, label: fetchedRegion }]));
      return { region_id: 0, region_name: fetchedRegion };
    } else {
      return { region_id: regionId, region_name: regionName };
    }
  };

  const setRegionDetails = ()=>{
    if (operatorFormData.mission_address != "" && (operatorFormData.mission_region_name == "" || operatorFormData.mission_region != "")) {
      
      Geocode.fromAddress(operatorFormData.mission_address).then((response)=>{
        const { region_id, region_name } = selectSpaceRegion(response.results[0].address_components);
        // setOperatorFormData({ ...operatorFormData,  });
        setOperatorFormData({ ...operatorFormData, mission_region_name : region_name, mission_region: region_id});
      })
    }
  }
  useEffect(() => {
    updateTextualContent(currentStep);
  }, [currentStep]);

  useEffect(() => {
    getCategories();

    if (type == "company_training") {
      dispatch(getCompanylistOfInvitation());
    }
  }, []);

  useEffect(() => {
    if (messageList && messageList.length > 0) {
      setLastMessage(messageList[messageList.length - 1]);
    }
  }, [JSON.stringify(messageList)]);

  useEffect(() => {
    const getUploadKeyName = {
      l_id_card_upload: "id_card",
      l_iban: "payment",
      l_insurance_upload: "insurance",
      l_skill_certificate_upload: "skill",
      l_profile_image: "profile_image",
    };
    if (agentDocuments.profile_image.preview.length != 0) {
      setImagePreviews({
        ...imagePreviews,
        ["l_profile_image"]: {
          key: getUploadKeyName["l_profile_image"],
          files: agentDocuments.profile_image.file,
          previewArray: Array.from(agentDocuments.profile_image.file).map((w) => {
            return { ...w, name: w.name, url: URL.createObjectURL(w) };
          }),
        },
      });
    }
    if (agentDocuments.id_card.preview.length != 0) {
      setImagePreviews({
        ...imagePreviews,
        ["l_id_card_upload"]: {
          key: getUploadKeyName["l_id_card_upload"],
          files: agentDocuments.id_card.file,
          previewArray: Array.from(agentDocuments.id_card.file).map((w) => {
            return { ...w, name: w.name, url: URL.createObjectURL(w) };
          }),
        },
      });
    }
    if (agentDocuments.insurance.preview.length != 0) {
      setImagePreviews({
        ...imagePreviews,
        ["l_insurance_upload"]: {
          key: getUploadKeyName["l_insurance_upload"],
          files: agentDocuments.insurance.file,
          previewArray: Array.from(agentDocuments.insurance.file).map((w) => {
            return { ...w, name: w.name, url: URL.createObjectURL(w) };
          }),
        },
      });
    }
    if (agentDocuments.payment.preview.length != 0) {
      setImagePreviews({
        ...imagePreviews,
        ["l_iban"]: {
          key: getUploadKeyName["l_iban"],
          files: agentDocuments.payment.file,
          previewArray: Array.from(agentDocuments.payment.file).map((w) => {
            return { ...w, name: w.name, url: URL.createObjectURL(w) };
          }),
        },
      });
    }

  }, [JSON.stringify(agentDocuments)]);

  useEffect(() => {
    setSelectedCategoryListing(selectedCategory);
  }, [JSON.stringify(selectedCategory)])
  
  useEffect(() => {
    customerServices.getOperatorAvaibility().then((res)=>{
      if (res && res.status) {
        let tempslot =  res.data.operator_avaibility.map((week) => {
          const fromDate = week.from ? new Date(`01-01-2000 ${week.from}`) : "";
          const toDate = week.to ? new Date(`01-01-2000 ${week.to}`) : "";
          let totalhours = 0;
          let totalDays = 0;

          if(fromDate && toDate)
          {
            totalhours = getHoursBetweenDates(fromDate, toDate);
            totalDays = Object.values(week.days).filter(
              (s) => s == true
            ).length;
          }
          return {
            ...week,
            hours:
              week.total_hours /
              Object.values(week.days).filter((s) => s == true).length,
            fromDate: week.from
              ? new Date(`01-01-2000 ${week.from}`)
              : "",
            toDate: week.to ? new Date(`01-01-2000 ${week.to}`) : "",
            total_hours: totalhours * totalDays,
            hours: totalhours,
          };
        })
        let finalArray = tempslot.length > 0 ? tempslot.concat(agentSlots) : agentSlots
        setAgentSlots(finalArray);
      } else {
        
      }
    })
  }, [])
  


  return (
    <React.Fragment>
      <div className="d-flex flex-column h-100">
        <div className="onboarding-wrapper-main d-flex m-auto h-100 flex-column onboarding-with-bg with-modified-width">
          <OnboardingHeader
            backBtnHandler={() => {
              handleStepChange(-1);
            }}
            backButtonText={backButtonText}
            description={description}
            hideBackButton={
              type == "agent-find-job" || type == "company_training"
                ? currentStep == "SetAvailability"
                : currentStep == "categoryList"
            }
            loader={loadingNextStep}
            loadingNextStep={loadingNextStep}
            nextBtnHandler={() => {
              handleStepChange(1);
            }}
            nextButtonText={nextButtonText}
            status={""}
            statusClass={""}
            title={titleText}
            hideNextButton={false}
            titlePrefix={""}
          />
          <div className="onboarding-content-scroll-wrapper overflow-hiiden-auto d-flex flex-column">
            {currentStep == "categoryList" ? (
              <>
                <ListingWithCheckbox
                  list={categoryListing}
                  setList={setCategoryListing}
                  selectOnlyOne={true}
                  handleAddNewCategory={(label) => handleAddNewCategory(label)}
                  selectedCategory={selectedCategory}
                  setSelectedCategory ={setSelectedCategory}
                />
              </>
            ) : currentStep === "operatorAvailability" ? (
              <OperatorForm
                formData={operatorFormData}
                setFormData={setOperatorFormData}
                contract={operatorContract}
                setContract={setOperatorContract}
                contractAttachments={operatorContractPreview}
                setContractAttachments={setOperatorContractPreview}
                setRegionDetails={setRegionDetails}
                hideDocument={true}
              />
            ) : currentStep === "agentInformation" ? (
              <AgentInformation
                documents={agentDocuments}
                handleClose={() => {
                  handleStepChange(-1);
                }}
                handleSave={() => {
                  handleStepChange(1);
                }}
                onlyTraining={formData.userSubscriptionPlan == 2}
                setDocuments={setAgentDocuments}
                setSlots={setAgentSlots}
                slots={agentSlots}
                type={type}
                setSelectedCompany={setSelectedCompany}
              />
            ) : currentStep === "address" ? (
              <Address
                coOrdinates={coOrdinates}
                regionList={regionList}
                setCoOrdinates={setCoOrdinates}
                setRegionList={setRegionList}
                setSpaceDetail={setSpaceDetail}
                spaceDetail={spaceDetail}
              />
            ) : currentStep === "SetAvailability" ? (
              <SetupAvilablityForOnBoarding
                    ref={availabilityRef}
                    handleClose={() => {
                      
                    }}
                    show={ "availablity_setup"}
                    withinModal={true}
                    handleSave={handleSave}
                    durationData={agentSlots}
                    setDurationData={setAgentSlots}
                  />
              // <SetupAvailability
              //   documents={agentDocuments}
              //   handleClose={() => {
              //     handleStepChange(-1);
              //   }}
              //   handleSave={() => {
              //     handleStepChange(1);
              //   }}
              //   onlyTraining={formData.userSubscriptionPlan == 2}
              //   setDocuments={setAgentDocuments}
              //   setSlots={setAgentSlots}
              //   slots={agentSlots}
              //   type={type}
              //   setSelectedCompany={setSelectedCompany}
              // />
            ) : currentStep === "uploadDocuments" ? (
              <UploadDocumentsForAvailabilty
                documents={agentDocuments}
                handleClose={() => {
                  handleStepChange(-1);
                }}
                handleSave={() => {
                  handleStepChange(1);
                }}
                onlyTraining={formData.userSubscriptionPlan == 2}
                setDocuments={setAgentDocuments}
                setSlots={setAgentSlots}
                slots={agentSlots}
                type={type}
                setSelectedCompany={setSelectedCompany}
              />
            ) : currentStep === "companySelection" ? (
              <CompanySelectionStep />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {["operatorAvailability"].includes(currentStep) ? (
        <></>
      ) : (
        <OnboardingFooter
          backBtnHandler={() => {}}
          backButtonText={""}
          currentStep={""}
          hideBackButton={""}
          loader={false}
          loadingNextStep={false}
          nextBtnHandler={() => {}}
          nextButtonText={""}
          skipButtonHandler={() => {}}
          skipButtonText={""}
          totalSteps={5}
          hideNextButton={false}
          hideSkipButton={true}
        />
      )}

      {/* feed chat bottom popup start */}

      <a
        href="#/"
        className="bgOfficelisting border comman_action_icon feed-chat-main lightthemebtnborder p-10 radius_3 w_310"
        onClick={() => {
          navigate(`/aichat`);
        }}
        // style={{ marginLeft: "50px" }}
      >
        <div className="d-flex align-items-center text-truncate pb-2">
          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5 ">
            <div
              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
              style={{
                backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/myrtasklogo.png')`,
              }}
            ></div>
          </div>
          <div className="c-font f-14 fw-semibold ps-10px">
            Feedchat : Myr.ai support
          </div>
        </div>
        <div className="d-flex align-items-center text-truncate">
          <div className="d-flex align-items-center text-truncate">
            <span className="c-dots  c-7 in-progress-white rounded-circle me-3"></span>
            <div className="c-font f-12 me-10px">
              {lastMessage.createdAt
                ? moment(lastMessage.createdAt).format("YYYY/MM/DD HH:mm ")
                : ""}
            </div>
          </div>
          <div className="c-font f-12 me-10px text-truncate w100minus100per">
            {lastMessage.message ? ReactHtmlParser(lastMessage.message) : ""}
          </div>
        </div>
      </a>
      {/* feed chat bottom popup end */}
    </React.Fragment>
  );
};

export default AgentHireFlow;

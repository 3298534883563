
import React, { useEffect, useRef, useState } from "react";
 
import Form from "react-bootstrap/Form";
import ActionDropdown from "../../../Dropdowns/ActionDropdown";
import { _l } from "../../../../hooks/utilities";
import { DotsThreeVertical,X } from "phosphor-react";
import { useDispatch } from "react-redux";
import { removeLeadAttachment } from "../../../../actions/leads-actions/leads-actions";
import PaperclipIcon from "../../../../assets/icons/PaperclipIcon";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";

const LeadAttachments = ({
  data,
  attachmentsArray,
  setAttachmentsArray,
  previewArray,
  setPreviewArray,
  lead_id, uploadHandler
}) => {
  const [leadProfileAttchments, setLeadProfileAttchments] = useState(data);

  const fileInputRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setLeadProfileAttchments(data);

    return () => {
      previewArray.map((img) => {
        URL.revokeObjectURL(img.url);
      });
    };
  }, [JSON.stringify(data)]);

  const handleFileUpload = (files) => {
    if (files) {
      let thumbnails = [];
      let newFiles = [];
      Array.from(files).map((img) => {
        thumbnails.push({ name: img.name, url: URL.createObjectURL(img) });
        newFiles.push(img);
      });

      if (attachmentsArray && Array.from(attachmentsArray).length) {
        /**Remove duplicate attachments from array */
        newFiles = Array.from(newFiles).filter(
          (x) =>
            Array.from(attachmentsArray).findIndex((w) => w.name == x.name) ===
            -1
        );
        thumbnails = thumbnails.filter(
          (x) => previewArray.findIndex((w) => w.name == x.name) === -1
        );

        setAttachmentsArray(Array.from(attachmentsArray).concat(newFiles));
        setPreviewArray(previewArray.concat(thumbnails));
      } else {
        setAttachmentsArray(files);
        setPreviewArray(thumbnails);
      }

    }
  };

  const removeAttachment = (ref) => {
    let currentFiles = attachmentsArray;
    currentFiles = Array.from(currentFiles).filter(
      (file) => file.name !== ref.name
    );
    setAttachmentsArray(currentFiles);
    setPreviewArray(previewArray.filter((x) => x.name !== ref.name));
    fileInputRef.current.value = null;
  };

  const deleteAttachment = (id) => {
    dispatch(removeLeadAttachment(lead_id, id));
  };

  return (
    <div className="d-flex flex-wrap w-100 h-100 p-10 py-0">
      <div className=" res-height-auto d-flex flex-column w-100">
        <div className="h-100 task-checklist-wrapper d-flex flex-column h-100  ">
          <div className="comment-input-box w-100">
            <div className="input_box_main with_file_upload"  
               
               
               >
              <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center ">
                <Form.Control
                  className="  hidden-input"
                  type="file"
                  ref={fileInputRef}
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    handleFileUpload(e.target.files);
                  }}
                />
                <div className="custom_file">
                  <PaperclipIcon className="upload_icon HW18" />
                  <div className="btn btn-sm btn-white-10 border-0">
                    <span className="c-font f-10 color-white-60"> {_l('l_upload_file')}</span>
                  </div>
                </div>
              </label>
            </div>
          </div>
          {leadProfileAttchments.length > 0 ? (
            leadProfileAttchments.map((item) => {
              return (
                <div className="file-image-list">
                  <div className="c-list-icon w-100 position-relative with-overlay">
                    <div className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox">
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                        style={{ backgroundImage: `url('${item.file_url}')` }}
                      ></div>
                    </div>
                    <div className="line-height-0 file-action-option ">
                      <div className="dropdown h20w20  ">
                        <a href="#/"
                          type="button"
                          className="line-height-0 "
                          id="ActiveOfficeOptionsDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                           
                           
                           
                           
                        >
                          <DotsThreeVertical size={18} weight="light" className="c-icons" />
                        </a>
                        <ActionDropdown
                          actions={[
                            {
                              actionName: _l("l_delete"),
                              actionHandler: () => {
                                deleteAttachment(item.file_id);
                              },
                              actionIcon: "TrashIcon",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="max-w-130px pt-2">
                    <div className="c-font text-truncate f-14 color-white ">
                      {item.file_name}
                    </div>
                    <div className="c-font text-truncate f-12 color-white-60">
                      {item.file_size}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <CommanPlaceholder imgType="no-images" mainWidth="m-auto" placeholderText = {_l("l_no_files")} />
            </>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap upload-image-preview d-none">
        {previewArray.map((thumb, index) => {
          return (
            <div
              key={index}
              className="comman-image-box h50w50 radius_3 upload-image m-2"
            >
              <div
                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                style={{
                  backgroundImage: `url('${thumb.url}')`,
                }}
              ></div>
              <a href="#/"
                className="upload-close"
                onClick={() => {
                  removeAttachment(thumb);
                }}
              >
                <X size={10}  weight="light" className="c-icons" />
              </a>
            </div>
          );
        })}
      </div>
       
    </div>
  );
};
export default LeadAttachments;

import { Button, Form, Modal } from "react-bootstrap";
import {
  _l,
  createCSVFile,
  csvOnLoadHandler,
  showError,
  validateEmail,
} from "../../hooks/utilities";
import { Paperclip } from "phosphor-react";
import { useState } from "react";
import BenchmarkServices from "../../services/benchmark-services";
import { useNavigate } from "react-router-dom";
import React from "react";
import { PROVIDER_CATEGORY_BULK_IMPORT_SAMPLE_HEADER } from "../../constants/constants";
import CustomerServices from "../../services/customer-services";
const CSVParser = require("papaparse");

const CategoryAndProviderBulkImport = ({
  show,
  handleClose,
  selectedOffice,
  redirect,
  setLoader,
  setShow,
}) => {
  const [csvFile, setCsvFile] = useState("");
  const [processedCSV, setProcessedCSV] = useState("");

  const navigate = useNavigate();

  const handleImport = async () => {
    setCsvFile("");

    let providers = [];

    try {
      if (processedCSV) {
        CSVParser.parse(processedCSV, {
          complete: function (results) {
            const rows = results.data;
            for (const row of rows) {
              /**Skip the record if it does not have required information */
              if (
                row[0].trim().length &&
                row[1].trim().length &&
                validateEmail(row[2])
              ) {
                let fullName = row[3].split(" ");
                let providerData = {
                  company_name: row[0],
                  category: row[1],
                  email: row[2],
                  full_name: row[3],
                  firstname: fullName[0],
                  lastname: fullName.slice(1).join(" "),
                  phone_number: row[4],
                  position: row[5],
                  employees: [],
                };
                /**Get data of every employee, i.e. for columns per employee */
                for (
                  let employeeDataIndex = 6;
                  employeeDataIndex < row.length;
                  employeeDataIndex += 4
                ) {
                  let employeeFullName = row[employeeDataIndex + 1].split(" ");
                  providerData.employees.push({
                    email: row[employeeDataIndex] || "",
                    full_name: row[employeeDataIndex + 1] || "",
                    firstname: employeeFullName[0],
                    lastname: employeeFullName.slice(1).join(" "),
                    phone_number: row[employeeDataIndex + 2] || "",
                    position: row[employeeDataIndex + 3] || "",
                  });
                }
                providers.push(providerData);
              }
            }
            if (providers.length) {
              inviteProvidersWithCategories(providers);
            }
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShow(false);
    }
  };

  const downloadSample = async () => {
    let csvToArray = [PROVIDER_CATEGORY_BULK_IMPORT_SAMPLE_HEADER];
    /**
     * Dummy columns for employees
     */
    for (let employee = 0; employee < 100; employee++) {
      csvToArray[0] = csvToArray[0].concat([
        `Employee Email`,
        `Employee Name`,
        `Employee Number`,
        `Employee Position`,
      ]);
    }
    let categoriesList = [];
    const response = await CustomerServices.getDefaultTopicList(0);
    if (response.status) {
      let allCategories = response.data.map((w) => {
        return {
          label: w.name,
          value: w.id,
          client_id: w.client_id,
          is_default: w.is_default,
        };
      });
      allCategories.sort((a, b) => a.label.localeCompare(b.label));
      const defaultCategories = allCategories.filter((w) => w.is_default == 1);
      const otherCategories = allCategories.filter(
        (w, index) =>
          allCategories.findIndex(
            (z, i) =>
              z.label &&
              w.label &&
              z.label.trim().toLowerCase() === w.label.trim().toLowerCase() &&
              i !== index
          ) === -1 && w.is_default == 0
      );
      categoriesList = defaultCategories.concat(otherCategories);
    }
    categoriesList.forEach((category) => {
      csvToArray.push([
        _l("l_company_name"),
        category.label,
        _l("l_email_placeholder"),
        _l("l_provider_name_placeholder"),
        _l("l_phone_number_placeholder"),
        _l("l_accoun_manager"),
      ]);
    });
    createCSVFile(csvToArray, `${_l("l_template")}.csv`);
  };

  const inviteProvidersWithCategories = async (providers) => {
    providers.forEach((provider) => {
      provider.employees = provider.employees.filter(
        (w) => validateEmail(w.email) && w.full_name.trim().length
      );
    });
    try {
      setLoader(true);
      const response = await BenchmarkServices.inviteProvidersWithCategories(
        selectedOffice,
        providers
      );
      if (response.status) {
        if (redirect) {
          navigate(`/explore-features?space=${selectedOffice}`);
          document.getElementById("topic-providers").click();
        }
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setShow(false);
      setLoader(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{_l("l_import_items")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className=" pt-0">
        <div className="form-wrapper-main">
          <div className="row pb-3">
            <ol start={1} className="ps-4">
              <li className="with-listing-count pb-1">
                {_l("l_you_csv_data_string")}
                <b> UTF-8 </b> {_l("l_to_avoid_unnecessary")}{" "}
                <b>{_l("l_encoding_problems")}</b>
              </li>
            </ol>
            <div className="comman-data-table" style={{ overflow: "auto" }}>
              <table className="dataTable GeneralItemTable">
                <thead className="WithHeaderbg c-font f-13 text-center">
                  <tr>
                    <th className="">{_l("l_company_name")}</th>
                    <th className="">{_l("l_category")}</th>
                    <th className="">{_l("l_provider_email")}</th>
                    <th className="">{_l("l_provider_name")}</th>
                    <th className="">{_l("l_provider_phonenumber")}</th>
                    <th className="">{_l("l_provider_position")}</th>
                    <th className="">{_l("l_employee_email")}</th>
                    <th className="">{_l("l_employee_name")}</th>
                    <th className="">{_l("l_employee_phonenumber")}</th>
                    <th className="">{_l("l_employee_position")}</th>
                    <th className="">{_l("l_employee_email")}</th>
                    <th className="">{_l("l_employee_name")}</th>
                    <th className="">{_l("l_employee_phonenumber")}</th>
                    <th className="">{_l("l_employee_position")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="c-font f-13 text-center text-nowrap">
                    <td className="c-font f-13 text-nowrap">Company 1</td>
                    <td className="c-font f-13 text-nowrap">Category 1</td>
                    <td className="c-font f-13 text-center text-nowrap">
                      bossoperator@gmail.com
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      Boss Operator Name
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      +33-xxxxxxxxxx
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Manager
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      employee1@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Employee 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      +33-xxxxxxxxxx
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Accountant
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      employee2@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Employee 2
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      +33-xxxxxxxxxx
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Security
                    </td>
                  </tr>
                  <tr>
                    <td className="c-font f-13 text-nowrap">Company 2</td>
                    <td className="c-font f-13 text-nowrap">Category 1</td>
                    <td className="c-font f-13 text-center text-nowrap">
                      bossoperator@gmail.com
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      Boss Operator Name
                    </td>
                    <td className="c-font f-13 text-center text-nowrap">
                      +33-xxxxxxxxxx
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Manager
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      employee1@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Employee 1
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      +33-xxxxxxxxxx
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Accountant
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      employee2@gmail.com
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Employee 2
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      +33-xxxxxxxxxx
                    </td>
                    <td className="fw-normal py-2 align-top text-center">
                      Security
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row align-items-end pb-3">
            <Form.Group className="col-12  c-input-box  position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_choose_csv_file")}
              </Form.Label>
              <div className="d-flex align-items-center">
                <div className="comment-input-box flex-grow-1 me-2">
                  <div
                    className="input_box_main with_file_upload"
                     
                     
                     
                     
                  >
                    <label className="upload_inputbox w-100 upload_relative form-control height-45  p-0 d-flex align-items-center ">
                      <Form.Control
                        className="  hidden-input"
                        type="file"
                        onInput={(e) =>
                          csvOnLoadHandler(e, setCsvFile, setProcessedCSV)
                        }
                        accept=".csv"
                      />
                      <div className="custom_file">
                        <Paperclip
                          size={18}
                          className="upload_icon c-icons"
                          weight="light"
                        />
                        <div className="btn btn-sm btn-white-10 border-0">
                          <span className="c-font f-10 color-white-60">
                            {" "}
                            {_l("l_upload_file")}
                          </span>
                        </div>
                        <span className="px-1 c-font f-13 color-white-60">
                          {csvFile && csvFile[0] ? csvFile[0].name : ""}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="text-nowrap h_45">
                  <Button
                    variant="primary"
                    className="h-100"
                    size="sm"
                    onClick={downloadSample}
                  >{`${_l("l_download")} ${_l("l_template")}`}</Button>
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
        </Button>
        <Button variant="primary" size="sm" onClick={handleImport}>
          {_l("l_import")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryAndProviderBulkImport;

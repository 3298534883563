import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CornersOut, MicrophoneSlash, PhoneSlash, Record, Microphone } from "phosphor-react";
import { _l, hour, minute,  removeDuplicates,  second, setCallTimer, updateelapsedTime, updatestartTime } from "../../hooks/utilities";
 
import { Button } from "react-bootstrap";
import jquery from "jquery";
import { showMessage } from "../../actions/messages";
import peer from "../../actions/peer";
import ReactPlayer from "react-player";
import ChatServices from "../../services/chat-services";

import {
    endCall,
    muteCall,
    unMuteCall,
    setRemoteStream,
    negoNeeded,
    callNotRespond,
    callAction,
    sendCandidates
} from "../../actions/chat";

import {
    ATTACH_BASE_URL,
    USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";
import { getCommentsForAudiocall, getTasksDetailsForcallTask, setCallDisconnect, setCallFullscreen, setCallRecording, setShowMicDisaply, updateedTaskChecklist } from "../../actions/customer";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import customerServices from "../../services/customer-services";
import { endVapiCall } from "../../actions/vapi";

const FeedChatAudioCall = ({ callStatus }) => {

    const { callTimer, recordingCallTimer } = useSelector((state) => state.chat);

    const dispatch = useDispatch();
    const [isMute, setIsMute] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [isOtherRecording, setIsOtherRecording] = useState(false);
    const [callImage, setCallImage] = useState(USR_IMG_PLACEHOLDER);
    const [callName, setCallName] = useState("");
    const [timerInterval, setTimerInterval] = useState(0);
    const mediaRecorder = useRef(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [taskId, setTaskId] = useState(0);
    const [project_id, setProject_id] = useState(0);
    const [remoteAudioStream, setRemoteAudioStream] = useState([]);

    const mimeType = "audio/wav";
    var recIceCandidates = [];

    var projectId = localStorage.getItem("selectedOffice");

    const { myStream, remoteStream } = useSelector((state) => state.chat);
    const { callTask, callTaskDetails, callRecordingActive, callRecordingAction, callComments,taskChecklist, displayMic, isEndCall, isCallFullscreen } = useSelector((state) => state.customer);

    var startTime = 0;
    var elapsedTime = 0;
    let timer;
    let seconds = second, minutes = minute, hours = hour;
    
    const [showCallFullscreen, setShowCallFullscreen] = useState(false)
    useEffect(() => {
        console.log("test 555");
        setCallTimer();
    }, []);

    useEffect(() => {
        peer.peer.addEventListener("track", async (ev) => {
            const remoteStreams = ev.streams;
            dispatch(setRemoteStream(remoteStreams[0]));
        });
        peer.peer.addEventListener("negotiationneeded", async () => {
            if(!jquery.isEmptyObject(callStatus)){
                    dispatch(negoNeeded({
                        userId: callStatus.callerInfo._id,
                        receiverId: callStatus.receiverInfo._id,
                        callRole: callStatus.callRole
                    }));
            }
        });
        peer.peer.onicecandidate = evt => {
            var iceCandidate = evt.candidate;
            if(iceCandidate){
                recIceCandidates.push(iceCandidate);
            }
            setTimeout(() => {
                if (callStatus.receiverInfo && callStatus.receiverInfo._id) {              
                    dispatch(sendCandidates({
                        userId: callStatus.callerInfo._id,
                        receiverId: callStatus.receiverInfo._id,
                        callRole: callStatus.callRole,
                        candidates: recIceCandidates
                    }));
                }
            }, 300);
        };
    }, [callStatus]);

    useEffect(() => {
        if(myStream && myStream.getTracks && peer){
            try{
                for (const track of myStream.getTracks()) {
                    peer.peer.addTrack(track, myStream);
                }
            }catch(e){
                console.error(e);
            }
        }
    }, [myStream]);

    useEffect(() => {
        if(!jquery.isEmptyObject(callStatus)){
            if(callStatus.status == "notResponding"){
                autoEndTheCall();
            }else{
                if(callStatus.callRole == "caller"){
                    if(callStatus.receiverInfo){
                        if(callStatus.receiverInfo.displayPicture != ""){
                            if ((callStatus.receiverInfo.displayPicture).includes('https') || (callStatus.receiverInfo.displayPicture).includes('myr.ai')) {
                                setCallImage(encodeURI(callStatus.receiverInfo.displayPicture));
                            }else{
                                setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.receiverInfo.displayPicture));
                            }
                        }else{
                            setCallImage(USR_IMG_PLACEHOLDER);
                        }
                        setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
                        dispatch(setShowMicDisaply(callStatus.receiverInfo.isMute ? true : false))
                        setIsOtherRecording(callStatus.receiverInfo.isRecording ? true : false);
                    }
                }else{
                    if(callStatus.callerInfo){
                        if(callStatus.callerInfo && callStatus.callerInfo.displayPicture != ""){
                            setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.callerInfo.displayPicture));
                        }else{
                            setCallImage(USR_IMG_PLACEHOLDER);
                        }
                        setCallName(callStatus.callerInfo.fname+" "+callStatus.callerInfo.lname);
                        dispatch(setShowMicDisaply(callStatus.callerInfo.isMute ? true : false))
                        setIsOtherRecording(callStatus.callerInfo.isRecording ? true : false);
                    }
                }

                if (callStatus.task_id) {
                    setTaskId(callStatus.task_id)                    
                }

                if (callStatus.extraData) {
                    setProject_id(callStatus.extraData.projectId)                    
                }
                if (callStatus.extraData && callStatus.task_id) {
                    dispatch(getTasksDetailsForcallTask(callStatus.extraData.projectId,[callStatus.task_id]))
                
                }
            }
        }else{
            if(isRecording){
                recordAction("stop");
            }
            if(isOtherRecording){
                setIsOtherRecording(false);
            }
        }
    }, [JSON.stringify(callStatus)]);

    useEffect(() => {
        if (!jquery.isEmptyObject(callTask)) {
            dispatch(callAction({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole,
                actionKey: "NewTask",
                actionVal: callTask.task_id,
                extraData : {
                    projectId : localStorage.getItem("selectedOffice")
                }
                
            }));
            if (callTask.task_id) {
                setTaskId(callTask.task_id);  
                dispatch(getTasksDetailsForcallTask(localStorage.getItem("selectedOffice"),[callTask.task_id]))

            }
        }

    }, [JSON.stringify(callTask)])
    
    const disconnectCall = () => {
        if(callStatus.status == "initCall" && callStatus.callRole == "caller"){
            autoEndTheCall(false);
        }else{
            dispatch(endCall({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole
            }));
        }
        if(isRecording){
            recordAction("stop");
            dispatch(setCallRecording(false))
        }
        if(isOtherRecording){
            setIsOtherRecording(false);
        }
    };

    const muteTheCall = async () => {
        myStream.getAudioTracks()[0].enabled = false;
        setIsMute(true);
        dispatch(muteCall({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
        }));
    };

    const unMuteTheCall = () => {
        myStream.getAudioTracks()[0].enabled = true;
        setIsMute(false);
        dispatch(unMuteCall({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
        }));
    };

    const autoEndTheCall = (showMsg = true) => {
        if(showMsg){
            dispatch(showMessage("unsucess", _l("l_call_status"),  _l("l_not_responding")));
        }
        dispatch(callNotRespond({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            projectId: projectId,
            groupId: callStatus.extraData.groupId
        }));
        if(isRecording){
            recordAction("stop");
        }
        if(isOtherRecording){
            setIsOtherRecording(false);
        }
    };

    const doCallAction = (actionKey, actionVal) => {
        dispatch(callAction({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            actionKey: actionKey,
            actionVal: actionVal
        }));
        if(actionKey == "record"){
            recordAction(actionVal);
        }
    };

    const recordAction = (actionVal) => {
        if(actionVal == "start"){
            startTime = Date.now() - elapsedTime;
            updatestartTime(Date.now() - elapsedTime);
            setTimerInterval(setInterval(updateStopwatch, 1000));

            const audioContext = new AudioContext();
            var audioIn_01 = audioContext.createMediaStreamSource(myStream);
            var audioIn_02 = audioContext.createMediaStreamSource(remoteStream);
            var dest = audioContext.createMediaStreamDestination();
            audioIn_01.connect(dest);
            audioIn_02.connect(dest);

            // Record media
            const media = new MediaRecorder(dest.stream, { type: mimeType });
            mediaRecorder.current = media;
            mediaRecorder.current.start();
            let localAudioChunks = [];
            mediaRecorder.current.ondataavailable = (event) => {
                if (typeof event.data === "undefined") return;
                if (event.data.size === 0) return;
                localAudioChunks.push(event.data);
            };
            setAudioChunks(localAudioChunks);
        }else{
            clearInterval(timerInterval);
            startTime = 0;
            updatestartTime(0)
            elapsedTime = 0;
            updateelapsedTime(0)
            // Stop recording
            if (mediaRecorder && mediaRecorder.current) {
                mediaRecorder.current.stop();
                mediaRecorder.current.onstop = () => {
                    //creates a blob file from the audiochunks data
                    const audioBlob = new Blob(audioChunks, { type: mimeType });
                    setAudioChunks([]);
                    if (callStatus && callStatus.extraData && callStatus.extraData.groupId != "") {
                        // Send this recorded audio to chat group
                        const currentTS = Math.floor(Date.now() / 1000);
                        const recordedFile = new File([audioBlob], "Call Record (" + callName + ") - " + currentTS + ".wav", { type: mimeType });
                        var callGroupId = callStatus.extraData.groupId;
                        ChatServices.uploadChatMedia(recordedFile).then((res) => {
                            if (res.status == 1) {
                                var msgData = {
                                    groupId: callGroupId,
                                    userId: localStorage.getItem("chatUserId"),
                                    message: res.data.filename,
                                    type: "audio",
                                    caption: "⏺️ Call Recording (" + callName + ")"
                                };
                                customerServices.addLinkComment(
                                    taskId,
                                    project_id,
                                    "",
                                    "",
                                    undefined,
                                    undefined,
                                    0,
                                    [recordedFile]
                                ).then((res) => {
                                    if (res && res.status) {
                                        let url = ""
                                        dispatch(getCommentsForAudiocall([
                                            ...callComments,
                                            {
                                                id: res.data.comment_id,
                                                contact_id: localStorage.getItem("contact_id"),
                                                dateadded: new Date().toISOString(),
                                                contact_full_name: localStorage.getItem("full_name"),
                                                content: "⏺️ Call Recording (" + callName + ")",
                                                description: "⏺️ Call Recording (" + callName + ")",
                                                time: res.data.time,
                                                addedfrom_details: {
                                                    profile_url: localStorage.getItem("contact_image"),
                                                },
                                                staffid: localStorage.getItem("staff_id"),
                                                attachments: res.data.files_list.map((d) => {
                                                    url = d.image_url
                                                    return { ...d, id: d.file_id, file_name: d.image_url, file_url: d.image_url }
                                                }),
                                                is_allowed_to_edit: 1,
                                                profile_url: localStorage.getItem("contact_image"),
                                                parent_comment_details: res.data.parent_comment_details ? res.data.parent_comment_details : "",
                                                parent_comment_id: res.data.parent_comment_id
                                            }
                                        ]))
                                        dispatch(callAction({
                                            userId: callStatus.callerInfo._id,
                                            receiverId: callStatus.receiverInfo._id,
                                            callRole: callStatus.callRole,
                                            actionKey: "newComment",
                                            actionVal: res.data.comment_id,
                                            extraData: {
                                                projectId: localStorage.getItem("selectedOffice"),
                                                checklist: removeDuplicates(taskChecklist),
                                                comments: [
                                                    ...callComments,
                                                    {
                                                        id: res.data.comment_id,
                                                        contact_id: localStorage.getItem("contact_id"),
                                                        dateadded: new Date().toISOString(),
                                                        contact_full_name: localStorage.getItem("full_name"),
                                                        content: "⏺️ Call Recording (" + callName + ")",
                                                        description: "⏺️ Call Recording (" + callName + ")",
                                                        time: res.data.time,
                                                        addedfrom_details: {
                                                            profile_url: localStorage.getItem("contact_image"),
                                                        },
                                                        staffid: localStorage.getItem("staff_id"),
                                                        attachments: res.data.files_list.map((d) => {
                                                            return { ...d, id: d.file_id, file_name: d.image_url, file_url: d.image_url }
                                                        }),
                                                        is_allowed_to_edit: 0,
                                                        profile_url: localStorage.getItem("contact_image"),
                                                        parent_comment_details: res.data.parent_comment_details ? res.data.parent_comment_details : "",
                                                        parent_comment_id: res.data.parent_comment_id
                                                    }
                                                ]
                                            }
                                        }));
                                        customerServices.createTranscription(
                                            url,
                                            taskId
                                        ).then((res) => {
                                            if (res && res.status) {
                                                customerServices.getCheckList(taskId, project_id, false, true).then((res) => {
                                                    if (res && res.status) {
                                                        dispatch(callAction({
                                                            userId: callStatus.callerInfo._id,
                                                            receiverId: callStatus.receiverInfo._id,
                                                            callRole: callStatus.callRole,
                                                            actionKey: "newChecklist",
                                                            actionVal: res.data && res.data.length > 0 ? res.data[0].id : "test",
                                                            extraData: {
                                                                projectId: localStorage.getItem("selectedOffice"),
                                                                checklist: removeDuplicates(taskChecklist.concat(res.data))
                                                            }
                                                        }));
                                                        dispatch(updateedTaskChecklist(removeDuplicates(taskChecklist.concat(res.data))))
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            } else {
                                dispatch(showMessage("unsucess", _l("l_error"), res.message));
                            }
                        });
                    }
                };
            }
        }
        setIsRecording(actionVal == "start" ? true : false);
        dispatch(setCallRecording(actionVal == "start" ? true : false))
    };

    // Function to update the stopwatch display
    function updateStopwatch() {
        const currentTime = Date.now();
        elapsedTime = currentTime - startTime;
        updateelapsedTime(currentTime - startTime)
    }

    useEffect(() => {
        if (callRecordingAction !== "") {   
            doCallAction("record",callRecordingAction)  
        }
    }, [callRecordingAction])

    useEffect(() => {
        if (isEndCall === true) {
            disconnectCall();
            dispatch(setCallDisconnect(false));
        }
    }, [isEndCall])

    function isStreamValid(stream) {
        return !!stream && stream instanceof MediaStream && stream.getTracks().length > 0;
    }

    useEffect(() => {
        if(isStreamValid(remoteStream)){
            const audioTrack = remoteStream.getAudioTracks()[0];
            if(audioTrack){
                const audioStream = new MediaStream([audioTrack]);
                setRemoteAudioStream(audioStream);
            }
        }
    }, [remoteStream]);    

    return (
        <>
            <div className="audio-call-feed-chat-main d-flex align-items-center">
                <div className="d-flex align-items-center h-100 ">
                    <div className="align-items-center d-flex">
                        <div className="h75w75 profile-image-wave-main">
                            <div className="profile-image-wave">
                                <div className="profile-image-wave">
                                    <div className="profile-image-wave">
                                        <div className="comman-image-box d-flex h-100 m-auto rounded-circle w-100 position-relative">
                                            {displayMic && <div className="muteoption with_small_modal comman_action_icon position-absolute">
                                                <div className="action_icon with_bg radius_3">
                                                    <MicrophoneSlash size={18} weight="light" className="c-icons m-auto" />
                                                </div>
                                            </div>}
                                            <div className="align-items-center bg-style-cover comman-image-box-bg d-flex h-100 m-auto rounded-circle w-100" style={{ backgroundImage: `url(${callImage})` }} onClick={()=>{
                                                dispatch(getTasksDetailsForcallTask(project_id ? project_id : localStorage.getItem("selectedOffice"),[taskId]))
                                                dispatch(setCallFullscreen(true));
                                                setShowCallFullscreen(true)
                                            }}>
                                                <a href="#/" className="align-items-center d-flex h20w20 justify-content-center m-auto rounded-5 action-icon-opacity-transparent-bg">
                                                    <CornersOut size={12} weight="light" className="c-icons m-auto" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column text-truncate ms-10px width-160px">
                            <div className="c-font f-22 fw-semibold text-truncate">
                                {callName}
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="title-fonts color-white-60 c-font f-14">Duration:</div>
                                <div class="title-fonts color-white-60">
                                    <span class="me-1 ps-0 "><span class="fw-semibold c-font f-14" id="call-timer">{callTimer ? callTimer :"00:00"}</span></span>
                                </div>
                            </div>
                            {isRecording || callRecordingActive ? 
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="title-fonts color-green c-font f-14">Recording:</div>
                                <div class="title-fonts color-green">
                                    <span class="me-1 ps-0 "><span class="fw-semibold c-font f-14" id="stopwatch">{recordingCallTimer ? recordingCallTimer : "00:00"}</span></span>
                                </div>
                            </div>
                            : <></>}
                        </div>
                    </div>
                    <div className="audio-call-feed-chat-on-hover">
                        <div className="d-flex flex-column">
                            <div className="comman_action_icon ms-10px">
                                <a href="#/" className="d-flex action_icon with_bg h32w32" onClick={() => { doCallAction("record", callRecordingActive ? "stop" : "start"); }} >
                                    { isRecording || callRecordingActive ?
                                    <Record size={18} weight="fill" className="c-icons m-auto color-green" />
                                    : <Record size={18} weight="fill" className="c-icons m-auto" />
                                    }
                                </a>
                                <a href="#/" className="d-flex action_icon with_bg mt-10px h32w32">
                                    {isMute ?   
                                    <MicrophoneSlash size={18} weight="light" className="c-icons m-auto" onClick={() => { unMuteTheCall(); }} />
                                    : <Microphone size={18} weight="light" className="c-icons m-auto" onClick={() => { muteTheCall(); }} />
                                    }
                                </a>
                            </div>
                        </div>
                        <div className="d-flex h75w75 ms-10px">
                            <Button className="w-100 h-100 my-auto" variant="warning" onClick={() => { 
                                if (callStatus && callStatus.role && callStatus.role == 4) {
                                    endVapiCall()
                                } else {
                                    disconnectCall()
                                }
                                 }} >
                                <PhoneSlash size={32} weight="light" className="c-icons m-auto black-l-white opacity-75" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {myStream ? 
            <ReactPlayer
                playing
                url={remoteAudioStream}
                controls={true}
                className="d-none"
                id="remote-call-audio"
            />
            : <></>}
            {showCallFullscreen || isCallFullscreen ? 
            <CommanOffCanvas
                show={showCallFullscreen || isCallFullscreen}
                handleClose={()=>{
                    setShowCallFullscreen(false)
                    dispatch(setCallFullscreen(false));
                }}
                data={callTaskDetails && callTaskDetails.length > 0 ? callTaskDetails[0]: {}}
                docType={"task"}
                pageName={"task"}
                setSelectedId={()=>{}}
                iscallModal={true}
                handleParentComponentAction={()=>{}}
            />
            : <></>}
             
        </>
    );
};
export default FeedChatAudioCall;

import React, { useState } from "react";
import { useEffect } from "react";
import myrLogo from "../../assets/images/myr-ai-logo.svg";
import Select from "react-select";
import Form from "react-bootstrap/Form";

import LogoGreenDark from "../../assets/icons/LogoGreenDark";
import LogoGreenLight from "../../assets/icons/LogoGreenLight";
import EyeIcon from "../../assets/icons/EyeIcon";
import EyeSlashIcon from "../../assets/icons/EyeSlashIcon";
import { useNavigate } from "react-router-dom";
import { _l } from "../../hooks/utilities";
import { EyeSlash,Eye,ArrowSquareOut, Buildings } from "phosphor-react";
import Button from 'react-bootstrap/Button';
import { setExternalShowFullscreen, setSelectedLanguage } from "../../actions/customer";
import CustomerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import logo from "../../assets/images/myr-ai-logo-circle.svg";
import Signin from "../../assets/images/sign-in.svg";


const LeftSideComponent = ({ data ,type, docModalIsOpen}) => {
  const navigate = useNavigate();
  const languageSelect = [
    { value: "english", label: _l('l_EN') },
    { value: "french", label: _l('l_FR') },
  ];
  const { commanoffcanvasIsOpen, showExteenalFullscreen } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [language , setlanguage] = useState(localStorage.getItem("language"))

  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem("language",language)    
  }

  const {themeColor} = useSelector((state) => state.customer)
  const [LightTheme , setLightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);
  useEffect(() => {
    if (themeColor == "light") {
      jquery("html").addClass("light-theme")
      setLightTheme(true);
    }else{
      jquery("html").removeClass("light-theme")
      setLightTheme(false);
    }

    
  }, [themeColor])
  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      dispatch(setExternalShowFullscreen(true));
    }
  }, [])
  
  return (
    <div className="row flex-wrap flex-lg-nowrap Standalonemain">
      {(type === "leaseDocument" && !localStorage.getItem("accessToken")) || (showExteenalFullscreen && !localStorage.getItem("accessToken")) ?
      <div className="leftPanel70px p-0">
        <div className="d-flex flex-column h-100">
          <div className="p-10 border-bottom h70w70 d-flex">
            <div className="comman-round-box h40w40 m-auto">
              <div className="comman-bg-img h-100 w-100 bg-style-cover" style={{backgroundImage: `url(${ logo})`}}></div>
            </div>
          </div>
        <div className="mt-auto office-list-main border-top">
          <li className="office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ">
                <a href="#/"
                  onClick={() => navigate("/login")}
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle  comman-round-box ">
                  <div className="comman-round-box  title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                    <div className="p-20 py-0 text-center"> 
                      <div className="action_icon h32w32">
                       <img src={Signin} className="m-auto h28w28"/>
                      </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-10 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                    {_l("Login")}
                    </span>
                  </div>
                </a>
              </li>
          </div>
          </div>
      </div>
      :
    <div className={`col-lg-5 stand-alone-left-main  ${type == "dashboardDocuments" && docModalIsOpen ? "docmodal" : ""}`}>
      <div className="d-flex flex-column h-100">
        <div className="stand-alone-top h-100">
          {/* register-btn below */}
          <div className={`d-flex justify-content-between pt-0 ${type == "dashboardDocuments" && docModalIsOpen ? "flexcolumn" : "align-items-center"}`}>
              <a href="#/" className={`logo-img ${type == "dashboardDocuments" && docModalIsOpen ? "py-4" : ""}`}>
              {LightTheme ? < LogoGreenLight className="standalonelogo "/> :  <LogoGreenDark  className="standalonelogo"/>}
              </a>
              <div className="d-flex align-items-center">
                <Form.Group className="me-2 c-input-box position-relative">
                  <Select
                    className="custom-select-menu with-filter-select me-0 border-0 min-w-auto"
                    closeMenuOnSelect={true}
                    placeholder={`${_l(language ? language : "l_FR")}`}
                    classNamePrefix="react-select"
                    options={languageSelect}
                    value={languageSelect.filter(dd=> dd.value == language)}
                    onChange={(value)=>{setLanguage(value.value)
                    setlanguage(value.value)}}
                    // menuIsOpen={true}
                  />
                </Form.Group>
                <div
                  className=""
                  onClick={() => {
                    navigate("/login");
                  }}
                > 
                  <a href="#/" class="btn btn-white-03 ms-auto px-lg-4">{_l("l_login")}</a>
                </div>
              </div>
            </div>
          <div className="detail-title-part">
            <div className="text-capitalize c-font f-20 fw-semibold title-fonts title-text-part d-none">
              #{data.id} 
            </div>
            { type == "dashboardDocuments" ? <React.Fragment></React.Fragment> : <div className="badge rounded-pill rounded-pill"><span className="c-font f-12">{type}</span></div>}
            <div className="c-font fs-1 fw-semibold title-fonts title-text-part ">
              {data.name}
            </div>
          { type == "dashboardDocuments" && docModalIsOpen
            ? <></>
            : <div className="task-list-card list-view title-fonts title-text-part p-15 mt-3 no-hover">
              <div className="d-flex align-items-center justify-content-between">
                <div className="color-white-60 mb-1">{_l("l_project")}</div>
                
              </div>
              <div className="c-font f-18 fw-semibold">
                { data &&  data.project_details && data.project_details ? data.project_details.name : !docModalIsOpen || type == "spaceDocument" ? data.title : ""}
              </div>
              {
                <>
                <div className="border-bottom my-2"></div>
                <div className="color-white-60 mb-1">{!docModalIsOpen || type == "spaceDocument" ? _l("l_floor_name") : _l("l_client")}</div>
                <div className="c-font f-18 fw-semibold">{data.project_details && data.project_details ? data.project_details.company : !docModalIsOpen || type == "spaceDocument" ? data.floor_name : ""}</div>
                </>
              }
              </div>
          }
            <div className="subtitle-part color-white-60 d-none">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly
            </div>
          </div>
          {/*https://www.youtube.com/embed/vuGH4Lbz2Uk */}
          {type == "estimate" ? (
            <div>
              <div className="iframe-part">
                <iframe className="radius_3 w-100 h-200px" src="https://www.youtube.com/embed/vuGH4Lbz2Uk" title="What is Myr.ai?" allow="autoplay" allowfullscreen></iframe>
              </div>
              {/* <div className="download-btn pt-3"><Button variant="secondary">{_l("l_download")}</Button></div> */}
              <div className="use_cases_listing p-15 px-0 border-bottom comman_action_icon">
                  <div className="title-fonts fw-semibold">{_l("l_use_cases")}</div>
              </div>
              <ol className="comman_action_icon use_cases_listing_ol ps-3 mb-0" type="1">
                <li className="">
                  <a href="https://myr.ai/en/usecases/etude-de-cas-nexity" className="align-items-center d-flex justify-content-between text-truncate py-2" target="_blank">
                    <div className="text-truncate  w100minus20">Nexity Business Case</div>
                    <div  className="">
                      <ArrowSquareOut size={16} className="c-icons"  />
                    </div>
                  </a>
                </li>
                
              </ol>
            </div>
            ) : <></>}
          <div className="login-section d-none">
            <div className="login_page">
              <div className="login_box">
                {/* <div className="login-logo">
                                    <LogoGreenBlack className="header-logo-img logo-light" />
                                    <img
                                    alt="logo"
                                    src={myrLogo}
                                    className="header-logo-img logo-dark"
                                    />
                                </div> */}
                <div className="comman_title_white">Login</div>
                <div className="custom_input_box_main">
                  <div className="custom_input_box  with_before">
                    <label className="input_box_label">
                      <div className="title_text color-white-60">
                        Email Address
                        <span className="star_img">*</span>
                      </div>
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="input_box  autofill_off"
                      placeholder="email@gmail.com"
                    />
                  </div>
                  <div className="custom_input_box with_before">
                    <label className="input_box_label d-flex align-items-center justify-content-between">
                      <div className="title_text color-white-60">
                        Password
                        <span className="star_img">*</span>
                      </div>
                      <a href="#/" className="forger_link">Forgot Password ?</a>
                    </label>
                    <div className="active" id="password_class">
                      <div className="d-flex align-items-center justify-content-between">
                        <input
                          type="password"
                          className="input_box  autofill_off"
                          placeholder="*****"
                          autoComplete="new-password"
                        />
                        <a href="#/" className="">
                          <div
                            className="img-width-18 d-none"
                            data-bs-toggle="tooltip"
                            data-bs-title="Hide password"
                          >
                            <EyeSlashIcon className="HW18" />
                          </div>
                          <div
                            className="img-width-18"
                            data-bs-toggle="tooltip"
                            data-bs-title="Show password"
                          >
                            <EyeIcon className="HW18" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mtop20"></div>
                  <div className="login_btn d-flex align-items-center justify-content-start">
                    <button className="btn btn-primary me-4">Login</button>
                    <button className="btn btn-secondary">Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stand-alone-top standalonefooter">
          <div className="stand-alone-bottom">
            <div className="copy-right-text pt-0 opacity-60">
              <span>{_l("l_myr_copyright")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </div>
  );
};

export default LeftSideComponent;


import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { CalendarBlank, Star, Phone, DotsNine, PhoneCall, UserCircleGear, CaretDown, PencilSimpleLine, Trash, EnvelopeSimple, ChatCircle, Crown, DotsThreeVertical } from "phosphor-react";
import Form from "react-bootstrap/Form";
 
import GeneralListing from "../../../TaskModalComponents/GeneralListing";
import moment from "moment";
import { _l, formateDate, getDatePickerLocale, getDatePickerTimeCaption } from "../../../../hooks/utilities";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import UncontrolledGeneralListing from "../../../TaskModalComponents/UncontrolledGeneralListing";
import TaskListStatusDropdown from "../../../Dropdowns/TaskListStatusDropdown";
import { Accordion, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { showMessage } from "../../../../actions/messages";
import AddEmailTemplatesModalNew from "../../../Modals/AddEmailTemplatesModalNew";
import { useDispatch } from "react-redux";
import PremiumEstimateIconCrown from "../../../../assets/icons/PremiumEstimateIconCrown";
import CommentMinified from "../../../Comments/CommentMinified";
import { getLeadContactComments } from "../../../../actions/leads-actions/leads-actions";
import benchmarkServices from "../../../../services/benchmark-services";
import UnControlMainFiltersDropDown from "../../../TaskModalComponents/LeadsMainFiltersDropDown";
let showComments = false;


const LeadCommanCard = (
    { 
        data,
        type,
        handleDelete = ()=>{},
        handleEdit= ()=>{},
        handleAccordionToggle = () => {},
        index,
        openAccordion,
        setPrimaryContact = () => {}
     }) => {
    let User_2 = "https://myr.ai/orb/assets/images/default-image-placeholder.svg"
    const [showDefaultIcons, setShowDefaultIcons] = useState([]);
    const [showEmailTemp, setShowEmailTemp] = useState(false);
    const [leadContactProfile, setleadContactProfile] = useState(false)
    const [leadComments, setLeadComments] = useState([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const modalhandleleadClose = () => setShowEmailTemp(false);


    const showLeadContactComments = () => {
        showComments = true;
        setLoading(true);
        dispatch(getLeadContactComments(data.id, 'lead_contact'));
    };

    const handleAddComment = (comment) => {
        if (comment && comment.trim().length) {
            benchmarkServices.addEditChecklistComment(comment, '', data.id, '', 'lead_contact').then((res) => {
                if (res.status) {
                   
                    dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
                }else{
                    dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
                }
            })
        } else {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_valid_comment")
            )
          );
        }
    };

    useEffect(() => {
        if (data.comment_id > 0) {
            setLeadComments(data.contact_Comments);
            setLoading(false);
        }
    }, [data.contact_Comments]);
    return (
        <React.Fragment>

        {!["contactLeads", "email"].includes(type) ?
            <div className="task-list-card list-view  taskcardliteversion overflow-visible">
                <div className="task-list-wrapper">
                    <div className="d-flex w-100 align-items-start">
                        <div className="d-flex task-list-detail">
                            <div className="task-left flex-grow-1 width100minus160">
                                <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                                    { type == "contact" && <div className="task-creator-img">
                                        <div className="h20w20 d-flex align-items-center me-10px ">
                                            <div className="m-auto">
                                                <Form.Check type="checkbox" className="m-auto" />
                                            </div>
                                        </div>
                                    </div>}
                                    <a href="#/" className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task">
                                        <div className="flex-wrap d-flex  w-100 align-items-center comman_action_icon">
                                            <div className={`d-flex flex-column w100minus100per task-list-name text-truncate ${type == "contact" ? "ms-10px" : ""}`}>
                                                <div className="text-truncate w-100 mb-1">
                                                    {data.full_name ? data.full_name : data.subject ? data.subject : data.description ? data.description : _l("l_no_description") }
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                                        <span class=" p-0 ">
                                                            {type == "email" ?
                                                             data.date_opened ?  _l("l_open_date") + ": " + formateDate(data.date_opened) : _l("l_no_open_date") 
                                                             : "#"  + data.id}
                                                        </span>
                                                    </div>
                                                    {type =="appoinment" && <div className="c-font color-white-60 f-12 lh-1 location  fw-normal ml-10"
                                                         
                                                         
                                                         
                                                    >
                                                        {data.date ? moment(data.date).format("DD/MM/YYYY hh:mm a"):''}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center task-bottom-part-full ">
                                <div className="task-right-static-options">
                                    <div className="d-flex align-items-center comman_action_icon">
                                        <div className="align-items-center d-flex leads">


                                                {/* Edit icon start */}
                                                { showDefaultIcons.includes("edit")  && type =="appoinment" &&<div className="pe-10px">
                                                    <a className="action_icon with_bg h32w32 position-relative light-theme-img" onClick={()=>{
                                                        handleEdit(data);
                                                    }}>
                                                        <PencilSimpleLine size={18} weight="light" className="c-icons "/>
                                                    </a>
                                                </div> }
                                                {/* Edit icon end */}

                                                {/* Delete icon start */}
                                                { showDefaultIcons.includes("delete")  && type =="appoinment" && <div className="pe-10px">
                                                    <a className="action_icon with_bg h32w32 position-relative light-theme-img" onClick={()=>{
                                                        handleDelete(data.id);
                                                    }}>
                                                        <Trash size={18} weight="light" className="c-icons "/>
                                                    </a>
                                                </div>}
                                                {/* Delete icon end */}


                                            {/* email Pill start */}
                                            {type == "email" && showDefaultIcons.includes("pills") &&
                                             <div className="position-relative d-flex assignee-list-wrappercard-view-less-padding ">
                                                <div className="pe-10px">
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            data.opened == 1 ? <span class="badge badge-done  h32wauto rounded-pill  fw-light color-white-80 ">{_l('l_opened')} </span> :
                                                                <span class="badge badge-red rounded-pill h32wauto  fw-light color-white-80  ">{_l('l_not_opened')}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>}
                                            {/* email Pill end */}

                                            {/* Pill start */}
                                            {(data.position != null && data.position != "" && type == "contact") || (data.email && type == "email") ? <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                                                <div className="pe-10px">
                                                    <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto">
                                                        <span class=" p-0 ">
                                                            {data.position != null && data.position != "" && type == "contact" ? data.position 
                                                            : data.email && type == "email" ? data.email 
                                                            : data.isnotified > -1 && type == "appoinment" ? data.isnotified == 1 ?  _l("l_is_notified") + " : " + _l('l_yes') :  _l("l_is_notified") + " : " +_l('l_no') 
                                                            : ""}</span>
                                                    </div>
                                                </div>
                                            </div> :<></>}
                                            {/* Pill end */}

                                            {/* Pill start */}
                                            { (data.isnotified > -1 && type == "appoinment") && showDefaultIcons.includes('pills') ? <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                                                <div className="pe-10px">
                                                    <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto">
                                                        <span class=" p-0 ">
                                                            {data.isnotified > -1 && type == "appoinment" ? data.isnotified == 1 ?  _l("l_is_notified") + " : " + _l('l_yes') :  _l("l_is_notified") + " : " +_l('l_no') 
                                                            : ""}</span>
                                                    </div>
                                                </div>
                                            </div> :<></>}
                                            {/* Pill end */}

                                            {/* status  start*/}
                                            {type == "contact" && showDefaultIcons.includes('status') ?
                                                <div className={` dropdown dropdown-center comman_action_icon card-view-less-padding p-0 me-10px`}>
                                                    <div
                                                        className={`white-border-box me-0 color-white-60 action_icon with_bg h32w32 d-flex close-toast`}
                                                        type="button"
                                                        id="TaskListStatusDropdown"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                         
                                                         
                                                         
                                                         
                                                    >
                                                        <div className="d-flex align-items-center m-auto">
                                                            <span
                                                                className={`c-dots c-10 ${data.status == 28
                                                                    ? "bg-warning"
                                                                    : data.status == 16
                                                                        ? "in-progress-white"
                                                                        : data.status == 4
                                                                            ? "in-progress-blue"
                                                                            : "done"
                                                                    } rounded-circle`}
                                                            ></span>
                                                            <CaretDown
                                                                size={14}
                                                                weight="light"
                                                                className="c-icons d-none"
                                                            />
                                                            <TaskListStatusDropdown
                                                                setTaskStatus={() => { }}
                                                                is_task={0}
                                                            />

                                                        </div>
                                                    </div>
                                                     
                                                </div> : <></>}
                                            {/* staus end */}
                                            {/* task rating start */}
                                            <>
                                                {type =="contact" && showDefaultIcons.includes("star") ? <div className="  with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                                                    <a href="#/" className="comman_action_icon w-100 d-flex align-items-center close-toast border-0"  
                                                         
                                                         
                                                         >

                                                        <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                                            {parseInt(data.is_primary) > 0 ?
                                                                <Star size={18} className="c-icons" weight="fill" color="#00ff6a" />
                                                                :
                                                                <Star size={18} className="c-icons" weight="light" />}
                                                        </div>
                                                    </a>
                                                     
                                                </div> : <></>}
                                            </>
                                            {/* task rating end */}

                                            {/* call icon strat */}
                                            {type !="email" && showDefaultIcons.includes("call") && parseInt(data.addedfrom) > 0 ?
                                                <UncontrolledDropdown direction={"down"} className="comman_action_icon me-10px">
                                                    <DropdownToggle tag="div" className="" >
                                                        <a href="#/"
                                                            className="dropdown-item p-0"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-auto-close="outside"
                                                            aria-expanded="false"
                                                            data-popper-placement="left-start"
                                                        >

                                                            <div className="action_icon with_bg h32w32">

                                                                <PhoneCall size={18} className="c-icons" weight="light" />

                                                            </div>


                                                        </a>
                                                        <UncontrolledGeneralListing
                                                            list={data && data.assigned_list && data.assigned_list.length > 0 && data.assigned_list.map((item, index) => {
                                                                return {
                                                                    name: item.name,
                                                                    image: item.profile_image,
                                                                    id: index,
                                                                    isOnline: 0,
                                                                    userId: item.id,
                                                                    email: item.email,
                                                                    userType: item.user_type
                                                                };
                                                            })}
                                                            makeCall={() => { }}
                                                            forCall={true}
                                                        />
                                                    </DropdownToggle>
                                                     
                                                </UncontrolledDropdown>

                                                : <></>}
                                            {/* call icon end */}

                                            {/* assignees start */}
                                            {type == "contact" && <div className="position-relative pe-10px assignee-list-wrapper card-view-less-padding">
                                                {data.assigned_list &&
                                                    data.assigned_list.length ? (
                                                    <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-center">
                                                        <a href="#/" className="comman-image-box h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                            <div
                                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                                style={{
                                                                    backgroundImage: `url('${data.assigned_list[0].profile_image}')`,
                                                                }}
                                                            ></div>
                                                        </a>
                                                        {data && data.assigned_list && data.assigned_list.length > 1 ? (
                                                            <React.Fragment>
                                                                <a href="#/"
                                                                    className="comman-image-box h32w32 with_overlay d-flex rounded-circle"
                                                                    type="button"
                                                                    data-bs-toggle="dropdown"
                                                                    data-bs-auto-close="outside"
                                                                    aria-expanded="false"
                                                                     
                                                                     
                                                                     
                                                                >
                                                                    <div className="unread_count m-auto">
                                                                        +{data.assigned_list.length - 1}
                                                                    </div>
                                                                </a>
                                                                <GeneralListing
                                                                    list={data.assigned_list.map(
                                                                        (assignee) => {
                                                                            return {
                                                                                name: assignee.full_name,
                                                                                image: assignee.profile_image,
                                                                                id: assignee.staffid,
                                                                            };
                                                                        }
                                                                    )}
                                                                />
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment></React.Fragment>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>}
                                            {/* assignees end */}

                                            {/* appoinment remind start */}
                                           {type == "appoinment" && <div className="d-flex align-items-center me-10px"
                                                 
                                                 
                                                 
                                            >
                                                <div className="c-list-icon ">
                                                    <div className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle cursor-pointer">
                                                        <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${data && data.staff_profile_url  ? data.staff_profile_url : User_2}')` }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {/* appoinment remind end */}
                                            
                                            {/* appointme invitee strat */}
                                            { type == "appoinment" && <div className="d-flex align-items-center"
                                                 
                                                 
                                                 >
                                                <div className="c-list-icon ">
                                                    <div className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle cursor-pointer">
                                                        <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${data && data.invitee && data.invitee.profile_image_url ? data.invitee.profile_image_url : User_2}')` }}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ps-2 w100minus40 c-list-detail c-font f-14 text-truncate color-white-80 max-width75">{data.invitee.full_name}</div>
                                            </div>}
                                            {/* appointme invitee end */}

                                            {/* separator Start */}
                                            {showDefaultIcons.length > 0 || (data && data.assigned_list && data.assigned_list.length > 0) || data.email || data.isnotified > -1 ? <div class="px-0 with_separator2_10 me-10px"></div> : <></>}
                                            {/* separator end */}

                                            {/* calendar start */}
                                            <div className="comman_action_icon custom-datepicker w-330px withinputabsolute me-10px" data-bs-toggle="tooltip"
                                                 
                                                 
                                                 >
                                                <div className={`action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative `}>
                                                    <CalendarBlank
                                                        size={18}
                                                        weight="light"
                                                        className="c-icons"
                                                    />
                                                    <DatePicker
                                                        portalId="custom_datepicker"
                                                        calendarClassName="custom-datepicker"
                                                        timeCaption={getDatePickerTimeCaption()}                                                        
                                                        calendarStartDay={1}
                                                        locale={getDatePickerLocale()}
                                                        className="close-toast"
                                                        placeholderText={ _l("l_duedate")}
                                                        showTimeSelect
                                                        dateFormat="dd/MM/yyyy hh:mm a"
                                                        readOnly={true}
                                                    >
                                                        <div className="datepicker-label">{_l("l_select_date_and_time")}</div>
                                                    </DatePicker>
                                                </div>
                                            </div>
                                            {/* calendar end */}

                                            {/* assigne to btn start */}
                                           {type =="contact" && <div className={` card-view-none me-10px `} >
                                                <div className="d-flex align-items-center">
                                                    <div className="dropup dropup-center">

                                                        <div className={`action_icon with_bg on-hover-active-toggle-img text-on-hover-active-green light-theme-img   color-white-80 calendarpadding d-flex align-items-center ${data && data.assigned_list && data.assigned_list.length > 0  ? "h32w32 p-0" : "h32wauto"}   `}>
                                                            <UserCircleGear
                                                                size={18}
                                                                weight="light"
                                                                className="c-icons"
                                                            />
                                                            {data && data.assigned_list && data.assigned_list.length > 0 ? <></> :<span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">{_l('l_assign_to')}</span>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>}
                                            {/* assigne to btn end */}
                                            <div className="">
                                                <a href="#/" className={`action_icon h32w32 with_bg with-border-active`}
                                                    onClick={() => {
                                                        showDefaultIcons.length > 0 ? 
                                                        setShowDefaultIcons([]) 
                                                        : setShowDefaultIcons(["call", "status", "position", "star","pills","edit","delete"])
                                                    }}>
                                                    <DotsNine size={18} weight="light" className="c-icons" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 
            </div>
          : 
            <Accordion
                defaultActiveKey={"0"}
                key={index}
                className={`bg-base-header-color position-sticky top-0 z-index-6`}
            >
                <Card.Header className={`mainTopicTaskFilter new_accordion_toggle bg-transparent `}>
                    <Accordion.Button eventKey={index} onClick={() => handleAccordionToggle(index)} className="new_accordion_toggle_padding">
                    <div className="task-list-card list-view  taskcardliteversion overflow-visible">
                        <div className="task-list-wrapper">
                            <div className="d-flex w-100 align-items-start">
                                <div className="d-flex task-list-detail">
                                    <div className="task-left flex-grow-1 width100minus160">
                                        <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                                            {type == "email" ? <></> :
                                            <div 
                                                className={` card-view-none me-10px `} 
                                                onMouseOut={(() => setleadContactProfile(true))}
                                                onMouseLeave={(() => setleadContactProfile(false))} 
                                            >
                                                <a className={`h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle border m-auto`}>
                                                    <div
                                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle position-relative"
                                                        style={{
                                                            backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg')`,
                                                        }}
                                                    ></div>
                                                    <div className="stroke-transparent path-warning HW14 position-absolute absoluteiconbadge " 
                                                        onClick={((e) => {
                                                            e.stopPropagation();
                                                            setPrimaryContact(data.id);
                                                        })}>
                                                        {data.is_primary == 1 
                                                            ? <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                                                            : leadContactProfile 
                                                                ? <Crown  size={14}  weight="light" className="c-icons position-absolute" /> 
                                                                :<></>
                                                        }
                                                    </div>
                                                </a>
                                            </div>
                                            }
                                            <a href="#/" className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task">
                                                <div className="flex-wrap d-flex  w-100 align-items-center comman_action_icon">
                                                    <div className={`d-flex flex-column w100minus100per task-list-name text-truncate ms-10px`}>
                                                        <div className="d-flex text-truncate w-100 mb-1">
                                                            {type == "email" ? data.subject : data.full_name ? data.full_name : data.subject ? data.subject : data.description ? data.description : _l("l_no_description") }
                                                
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            {type == "email" ? 
                                                            <div class="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                                                <span class=" p-0 ">
                                                                    {data.date ?  _l("l_sent_date") + ": " + moment(data.date).format("DD/MM/YYYY hh:mm a") : ''}
                                                                </span>
                                                            </div>
                                                            : <div class="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                                            <span class=" p-0 ">
                                                                {"#"  + data.id}
                                                            </span>
                                                        </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center task-bottom-part-full ">
                                        <div className="task-right-static-options">
                                            <div className="d-flex align-items-center comman_action_icon">
                                                {type == "email" ? 
                                                <> 
                                                    <div className="position-relative d-flex assignee-list-wrappercard-view-less-padding " onClick={(e) => e.stopPropagation()}>
                                                        {["right"].map((placement) => (
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            delay={{ show: 1000 }}
                                                            key={placement}
                                                            placement={placement}
                                                            overlay={
                                                                <Popover>
                                                                    <Popover.Body className="pb-1 pt-1 px-0">
                                                                    {data.track_details && data.track_details.length > 0 ? 
                                                                        <ul className="overflowscroll">
                                                                            <li className="hr_1 pb-1 px-2">{_l("l_open_date")}</li>
                                                                            {data.track_details.map((item, i) => {
                                                                                return(
                                                                                <li className="hr_1 pb-1 px-2">{moment(item.date).format("DD/MM/YYYY hh:mm a")}</li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    : <span className="hr_1 pb-1 px-2">{_l('l_not_opened')}</span> }
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                                <div className="pe-10px">
                                                                    <div className="d-flex align-items-center">
                                                                        {
                                                                            data.opened == 1 ? 
                                                                                <span class="badge badge-done  h32wauto rounded-pill  fw-light color-white-80 ">{_l('l_opened')} : {data.view_count} </span> :
                                                                                <span class="badge badge-red rounded-pill h32wauto  fw-light color-white-80  ">{_l('l_not_opened')}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                        </OverlayTrigger>))}
                                                    </div>
                                                    <div 
                                                        href="#/" 
                                                        className='comman-image-box h30w30 rounded-circle multi-wrapper-border me-2 border-2'
                                                         
                                                         
                                                         >
                                                            <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url(${User_2})` }}></div>
                                                    </div>
                                                     
                                                </> 
                                                
                                                
                                                : type == "appoinment" ? 
                                                <div className="align-items-center d-flex leads">
                                                    {/* Pill start */}
                                                    { data.isnotified > -1 ? <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                                                        <div className="pe-10px">
                                                            <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto">
                                                                <span class=" p-0 ">
                                                                    {data.isnotified > -1 && type == "appoinment" ? data.isnotified == 1 ?  _l("l_is_notified") + " : " + _l('l_yes') :  _l("l_is_notified") + " : " +_l('l_no') 
                                                                    : ""}</span>
                                                            </div>
                                                        </div>
                                                    </div> :<></>}
                                                    {/* Pill end */}
                                                        
                                                    {/* appointme invitee strat */}<div className="d-flex align-items-center"
                                                         
                                                         
                                                         >
                                                        <div className="c-list-icon ">
                                                            <div className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle cursor-pointer">
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${data && data.invitee && data.invitee.profile_image_url ? data.invitee.profile_image_url : User_2}')` }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ps-2 w100minus40 c-list-detail c-font f-14 text-truncate color-white-80 max-width75">{data.invitee.full_name}</div>
                                                    </div>
                                                    {/* appointme invitee end */}

                                                    {/* separator Start */}
                                                    {showDefaultIcons.length > 0 || (data && data.assigned_list && data.assigned_list.length > 0) || data.email || data.isnotified > -1 ? <div class="px-0 with_separator2_10 me-10px"></div> : <></>}
                                                    {/* separator end */}
                                                
                                                    <UncontrolledDropdown className="dropdown-center dropdown with_separator_10 ps-0 before-dnone d-flex align-items-center card-view-less-padding"
                                                     
                                                     
                                                     
                                                     
                                                    ><DropdownToggle
                                                        tag={'a'}
                                                        key={data.id}
                                                        className="d-flex on-hover-active-toggle-img h32w32"
                                                        type="button"
                                                        id={`minified_comment_${data.id}`}
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                        data-bs-auto-close="outside"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    > 
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                                            <DotsThreeVertical
                                                            size={18}
                                                            weight="light"
                                                            className="c-icons"
                                                            />
                                                        </div>
                                                    </div>
                                                    </DropdownToggle>
                                                     
                                                    <DropdownItem toggle={false} className="p-0"> 
                                                        <UnControlMainFiltersDropDown
                                                            type = "leadAppointment"
                                                            data={[
                                                                  {
                                                                  actionName: _l("l_edit"),
                                                                  actionHandler: () => {handleEdit(data)},
                                                                  actionIcon: "PencilSimpleLineIcon",
                                                                  },
                                                                  {
                                                                    actionName: _l("l_inactive"),
                                                                    actionHandler: () => {handleDelete(data.id)},
                                                                    actionIcon: "ProhibitIcon",
                                                                  }
                                                                ]}
                                                        />
                                                    </DropdownItem>
                                                </UncontrolledDropdown>
                                                     
                                                </div>
                                                : 
                                                <div className="align-items-center d-flex leads">

                                                    {/* Pill start */}
                                                    {(data.position != null && data.position != "" && type !== "email") ? <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                                                        <div className="pe-10px">
                                                            <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto">
                                                                <span class=" p-0 ">
                                                                    {data.position != null && data.position != "" ? data.position 
                                                                    : ""}</span>
                                                            </div>
                                                        </div>
                                                    </div> :<></>}
                                                
                                                {/* comments icon start */}
                                                <UncontrolledDropdown className="dropdown-center dropdown with_separator_10 ps-0 before-dnone d-flex align-items-center card-view-less-padding"
                                                     
                                                     
                                                     
                                                     
                                                    >
                                                    <DropdownToggle
                                                        tag={'a'}
                                                        key={data.id}
                                                        className="comman_action_icon w-100 d-flex"
                                                        type="button"
                                                        id={`minified_comment_${data.id}`}
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                        data-bs-auto-close="outside"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            showLeadContactComments();
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center w-100">
                                                        <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                                            <ChatCircle size={18} className="c-icons" weight="light" />
                                                        </div>
                                                        <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                                            {data.comments_count ? data.comments_count : 0}
                                                        </span>
                                                        </div>
                                                    </DropdownToggle>
                                                     
                                                    <DropdownItem toggle={false} className="p-0">
                                                        <CommentMinified
                                                            id={data.id}
                                                            loading={loading}
                                                            comments={leadComments}
                                                            mentionList={[]}
                                                            handleAddComment={handleAddComment}
                                                            showComments={showComments}
                                                            unContolList={true}
                                                        />
                                                    </DropdownItem>
                                                </UncontrolledDropdown>
                                                {/* comments icon end */}
                                                {/* email icon start */}
                                                <div className="dropdown-center dropdown with_separator_10 ps-0 before-dnone d-flex align-items-center card-view-less-padding close-toast">
                                                <a href="#/" className="comman_action_icon w-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (data.email) {
                                                            setShowEmailTemp(true);
                                                        }else{
                                                            dispatch(showMessage("unsucess", _l("l_error"), _l('l_email_not_found_for_lead')));
                                                        }
                                                    }}
                                                     
                                                     
                                                     
                                                >
                                                    <div className="d-flex align-items-center w-100">
                                                    <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                                        <EnvelopeSimple size={18} weight="light" className="c-icons" />
                                                    </div>
                                                    <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                                        {data.sent_email_count ? data.sent_email_count : 0}
                                                    </span>
                                                    </div>
                                                </a>
                                                </div>
                                                {/* email icon end */}

                                                {/* Call icon start */}
                                                <div className="with_separator_10 card-view-less-padding ps-0 before-dnone ">
                                                <a href="#/" className="comman_action_icon w-100 d-flex"
                                                     
                                                     
                                                     >
                                                    <div className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img light-theme-img">
                                                        <Phone size={18} weight="light" className="c-icons" />
                                                    </div>
                                                </a>
                                                </div>
                                                
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Accordion.Button>
                </Card.Header>
                <Accordion.Collapse eventKey={index} in={openAccordion === index}>
                    <Card.Body className="mt-0">
                    <div className="py-1 pt-0  pb-1">
                        <div className="position-relative  w-100 p-0">
                        <ul className="p-10 px-0">
                        {type == "email" ? 
                            <li className=" align-items-center g-0 lead-items p-1 row">
                                <div className={`border c-font f-13  color-white-80 text-truncate p-10 `}>
                                    {data.message? 
                                    <div>
                                        <div className="d-flex flex-row" >
                                            <div dangerouslySetInnerHTML={{ __html: data.message }} />
                                        </div>
                                    </div>
                                    : <><span>-</span></>}
                                </div>
                            </li> : <>
                            <li className="align-items-center g-0 lead-items p-1 row">
                            <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
                                {_l('l_first_name')} 
                            </div>
                            <div class="col-1 text-center">:</div>
                            <div className="col-lg-9">
                            <div className={`border  c-font f-13  color-white-80 text-truncate p-10 `}>
                                {data.full_name ? data.full_name.split(' ')[0] : '-'}
                            </div>
                            </div>
                            </li>
                            <li className="align-items-center g-0 lead-items p-1 row">
                            <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
                                {_l('l_last_name')} 
                            </div>
                            <div class="col-1 text-center">:</div>
                            <div className="col-lg-9">
                            <div className={`border c-font f-13  color-white-80 text-truncate p-10 `}>
                                {data.full_name ? data.full_name.split(' ')[1] || '-' : '-'}
                            </div>
                            </div>
                            </li>
                            <li className="align-items-center g-0 lead-items p-1 row">
                            <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
                                {_l('l_position')} 
                            </div>
                            <div class="col-1 text-center">:</div>
                            <div className="col-lg-9">
                            <div className={`border c-font f-13  color-white-80 text-truncate p-10 `}>
                                {data.position ? data.position : '-'}
                            </div>
                            </div>
                            </li>
                            <li className="align-items-center g-0 lead-items p-1 row">
                            <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
                                {_l('l_email_address_label')} 
                            </div>
                            <div class="col-1 text-center">:</div>
                            <div className="col-lg-9">
                            <div className={`border c-font f-13  color-white-80 text-truncate p-10 `}>
                                {data.email ? data.email :'-'}
                            </div>
                            </div>
                            </li>
                            <li className="align-items-center g-0 lead-items p-1 row">
                            <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
                                {_l('l_phone_label')} 
                            </div>
                            <div class="col-1 text-center">:</div>
                            <div className="col-lg-9">
                            <div className={`border c-font f-13  color-white-80 text-truncate p-10 `}>
                                {data.phonenumber ? data.phonenumber.slice(0, 2) + ' ' + data.phonenumber.slice(2): '-'}
                            </div>
                            </div>
                            </li>
                            <li className=" g-0 lead-items p-1 row">
                            <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
                                {_l('l_description')} 
                            </div>
                            <div class="col-1 text-center">:</div>
                            <div className="col-lg-9">
                            <div className={`border c-font f-13  color-white-80 text-truncate p-10 `}>
                                {data.notes.length > 0 ? 
                                <div>
                                {data.notes.map((note, index) => (
                                    <div key={index} className="d-flex flex-row" >
                                        <div dangerouslySetInnerHTML={{ __html: note.description }} />
                                    </div>
                                ))}
                                </div>
                                : <><span>-</span></>}
                            </div>
                            </div>
                            </li>
                            </> }
                        </ul>
                        </div>
                    </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        }
        {showEmailTemp ?
            <AddEmailTemplatesModalNew 
                show={showEmailTemp}
                handleClose={modalhandleleadClose}
                lead = {data}
                isLeadContact ={true}
            />
            :<></>
        }
        </React.Fragment>
    )
}

export default LeadCommanCard
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import GeneralItemTable from "./GeneralItemTable";
import { GearSix } from 'phosphor-react';
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TypeOfSpaceAllotment from '../../Dropdowns/TypeOfSpaceAllotment';
import { TaskCommentModalToggle, _l, checklistItem, checklistItemFormat, createCSVFile, csvOnLoadHandler, generateButtonText, getClientIdByProject, isSameArrays } from '../../../hooks/utilities';
import ScheduleFooter from './ScheduleFooter';
import CustomerServices from '../../../services/customer-services';
import { getBenchmarkList } from '../../../actions/customer';
import { showConfirmation, showMessage, toastCloseAction } from '../../../actions/messages';
import { useDispatch, useSelector } from 'react-redux';
 
import { setFloorFilters, selectFloorForSchedule, updateRoomKPICounts, updateTypeOfSpaceList } from '../../../actions/benchmark-actions/benchmark-actions';
import moment from 'moment';
import CommanPlaceholder from '../../Placeholder/CommanPlaceholder';
import { CHECKLIST_EXPORT_CSV_HEADER, WEEK_DAYS } from '../../../constants/constants';

const AssignmentSchedule = ({ handleClose, handleStep, isCreateBenchmark, isEdit, readOnlyMode }) => {
  const [dropdownKey, setDropdownKey] = useState(-1);
  const { floorDetails, createScheduleDetail, scheduleDetail, filteredFloorIds, selectedFloorIds } = useSelector((state) => state.benchmarkReducer);
  const { benchmarkSchedule, benchmarkDetail } = useSelector((state) => state.customer);
  const projectId = useSelector((state) => state.customer.selectedProject);

  const dispatch = useDispatch();

  const [bulkActionsCheckboxes, setBulkActionsCheckboxes] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [loader, setLoader] = useState(0);
  const [defaultSelectedFloors, setDefaultSelectedFloors] = useState([]);
  const [showImportItemsModal, setShowImportItemsModal] = useState(false);
  const [csvFile, setCsvFile] = useState("");
  const [processedCSV, setProcessedCSV] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);

  const moveToTypeOfSpace = (items, spaceId) => {
    let updatedChecklist = checklists.slice();
    let space = floorDetails.type_of_space.find((x) => x.id == spaceId);
    if (space) {
      updatedChecklist = updatedChecklist.map((item) => {
        if (items.findIndex(x => x.description && item.description && x.description.trim().toLowerCase() == item.description.trim().toLowerCase()) !== -1) {
          item.room_id = spaceId;
          item.type_of_space_items = space.details.map((x) => x.id);
        }
        return item;
      });
      let emptyChecklistItem = updatedChecklist.find((x) => x.room_id == spaceId && !x.description || !x.description.trim().length);
      setChecklists(updatedChecklist.filter((x) => ((x) => x.room_id == spaceId && !x.description || !x.description.trim().length)).concat([emptyChecklistItem]));
    }
  };

  const getBasicDetails = (key) => {
    return benchmarkDetail && benchmarkDetail[`${key}`] ? benchmarkDetail[`${key}`] : ''
  }

  const hasValidTypeOfSpaces = (spaceId, spaceItems) => {
    let flag = true;
    const space = floorDetails.type_of_space ? floorDetails.type_of_space.find((x) => x.id == spaceId) : undefined;
    if (space && space.is_default == 1) {
      return flag;
    }
    else if (space && !space.checked) {
      return false;
    }
    if (spaceItems && spaceItems.length > 0) {
      return flag;
    }
    else if (!spaceItems || !spaceItems.length) {
      floorDetails.type_of_space.forEach((space) => {
        if (space.id == spaceId && space.is_default == 0) {
          flag = false;
        }
      })
    }
  }

  const allValidChecklists = (checklistArray) => {
    let flag = true;
    checklistArray.forEach((checklist) => {
      if (checklist.days && Object.values(checklist.days).findIndex((x) => x) === -1) {
        flag = false;
        setLoader(0);
      }
    });
    return flag;
  }

  const handleStep3 = (save_and_send = 0) => {
    let formattedChecklistArray = [];
    checklists.forEach((checklist) => {
      if (checklist.description && hasValidTypeOfSpaces(checklist.room_id, checklist.type_of_space_items)) {
        formattedChecklistArray.push(
          {
            ...checklistItemFormat,
            id: checklist.id || 0,
            slot: checklist.slot,
            slot_title: checklist.slot_title,
            room_id: checklist.room_id,
            checklist: [
              {
                name: checklist.description,
                photo: 0,
                selected: 1,
                addedFromTemplate: false,
                unique_id: checklist.id,
                is_default_checklist: false,
                tasks_per_month: 1
              }
            ],
            days: checklist.days,
            priority: checklist.priority,
            comments: checklist.item_comments,
            photo_required: checklist.photo_required,
            type_of_space_items: checklist.type_of_space_items
          }
        )
      }
    })

    if (!allValidChecklists(formattedChecklistArray)) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_checklist_day_frequency_missing")
        )
      );
      return false;
    }

    CustomerServices.addEditBenchmarkStep3(
      projectId,
      getBasicDetails('id'),
      scheduleDetail.start_date,
      Number(getBasicDetails('start_date_is_empty')) > 0 ? 1 : 0,
      getBasicDetails('provider_id'),
      formattedChecklistArray,
      save_and_send,
      getClientIdByProject(), save_and_send == 'accessories' ? 0 : 1, isEdit ? 1 : 0
    ).then((res) => {
      if (res.status) {
        dispatch(
          getBenchmarkList(projectId)
        );
        dispatch(setFloorFilters([]));
        if (save_and_send == 'accessories' && getBasicDetails('is_schedule') == 0) {
          handleStep(save_and_send)
        } else {
          handleClose(save_and_send);
        }
        //API Call for updating the value of number of tasks in task card
      } else {
        handleStep(save_and_send)
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            res.message ? res.message : _l("l_something_went_wrong")
          )
        );
      }
    }).finally(() => {
      setLoader(0);
      if (save_and_send != 'accessories') {
        TaskCommentModalToggle();
      }

    });
  };

  const handleLoder = () => {
    if (loader) {
      return <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        className={`ms-2 `}
        aria-hidden="true"
      />
    }
  }

  const getTotalSpaceItemQuantity = (spaceItems) => {
    let spaceItemQuantity = 0;
    if (!filteredFloorIds || !filteredFloorIds.length) {
      spaceItemQuantity = spaceItems.length;
      return spaceItemQuantity;
    }
    else {
      spaceItems.forEach((item) => {
        if (filteredFloorIds.findIndex(x => x == item) !== -1) {
          spaceItemQuantity++;
        }
      })
    }
    return spaceItemQuantity;
  }

  const getSelectedTypeOfSpaceItems = (space) => {
    let spaceCount = 0;
    let checklist = checklists.find((w) => w.room_id == space.id);
    if (checklist) {
      let floorIds = space.details.filter((x) => checklist.type_of_space_items.includes(x.id)).map(x => x.floor_id);
      spaceCount = getTotalSpaceItemQuantity(floorIds);
    }
    return spaceCount;
  }

  const spaceHasEmptyChecklists = (spaceId) => {
    return checklists.filter((x) => x.room_id == spaceId && x.description.trim() != "").length == 0;
  }

  const updateChecklistItemsBasedOnFloors = (floorIdArray) => {
    let spaceItemsIdsOfSelectedFloor = floorDetails.type_of_space.map((x) => x.details.filter(k => floorIdArray.includes(k.floor_id)).map((v) => v.id)).filter(p => p.length > 0);
    spaceItemsIdsOfSelectedFloor = [].concat(...spaceItemsIdsOfSelectedFloor); //Converting to 1D Array
    let currentChecklistItems = checklists.slice();

    if (floorIdArray.length) {
      currentChecklistItems.forEach((checklist) => {
        let typeOfSpaceOfChecklist = floorDetails.type_of_space.find((x) => x.id == checklist.room_id);
        if (typeOfSpaceOfChecklist) {
          let spaceItems = typeOfSpaceOfChecklist.details.filter((x) => spaceItemsIdsOfSelectedFloor.includes(x.id)).map((w) => w.id);
          checklist.type_of_space_items = spaceItems;
        }
      });
    }
    else {
      if (benchmarkSchedule && benchmarkSchedule.week_schedule_details) {
        currentChecklistItems.forEach((checklist) => {
          let checklistIndex = benchmarkSchedule.week_schedule_details.find((x) => x.id == checklist.id)
          if (checklistIndex !== -1) {
            checklist = { ...checklist, type_of_space_items: checklist.type_of_space_items };
          }
          else {
            let typeOfSpaceOfChecklist = floorDetails.type_of_space.find((x) => x.id == checklist.room_id);
            if (typeOfSpaceOfChecklist) {
              let spaceItems = typeOfSpaceOfChecklist.details.map((w) => w.id);
              checklist.type_of_space_items = spaceItems;
            }
          }
        });
      }
      else {
        currentChecklistItems.forEach((checklist) => {
          let typeOfSpaceOfChecklist = floorDetails.type_of_space.find((x) => x.id == checklist.room_id);
          if (typeOfSpaceOfChecklist) {
            let spaceItems = typeOfSpaceOfChecklist.details.map((w) => w.id);
            checklist.type_of_space_items = spaceItems;
          }
        });
      }
    }
    setChecklists(currentChecklistItems);
  }

  const updateChecklistsAndTypeOfSpaces = (e) => {
    dispatch(setFloorFilters(e.map((x) => x.value)));
    dispatch(selectFloorForSchedule(e.map((x) => x.value)));
    updateChecklistItemsBasedOnFloors(e.map((x) => x.value));
    setDefaultSelectedFloors(e);
  }

  const removeAllFloors = () => {
    dispatch(updateTypeOfSpaceList(floorDetails.type_of_space.filter((x) => x.is_default == 0).map((w) => w.id), false));
  }

  const floorSelectBoxOnChangeEvent = (e) => {
    if (e.length && e.length < defaultSelectedFloors.length) {
      const deselectedOptions = defaultSelectedFloors.filter(
        (option) => !e.includes(option)
      );
      dispatch(showConfirmation(_l("l_are_you_sure"), `${_l("l_remove_type_of_space_of_selected_floor")} "${deselectedOptions[0].label}"?`, _l("l_yes"), _l("l_no"), () => { }, () => { updateChecklistsAndTypeOfSpaces(e); dispatch(toastCloseAction()) }));
    }
    else if (!e.length) {
      dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_remove_all_type_of_spaces_from_schedule"), _l("l_yes"), _l("l_no"), () => { }, () => { updateChecklistsAndTypeOfSpaces(e); removeAllFloors(); dispatch(toastCloseAction()) }));
    }
    else {
      updateChecklistsAndTypeOfSpaces(e);
    }
  }

  const downloadCSV = () => {
    let nonEmptyChecklists = checklists.filter((x) => x.description != "");
    if (nonEmptyChecklists.length) {
      let csvToArray = [CHECKLIST_EXPORT_CSV_HEADER];
      nonEmptyChecklists.forEach((checklist) => {
        let space = floorDetails.type_of_space.find((x) => (x.id == checklist.room_id));
        if (!space) {
          space = floorDetails.type_of_space.find((x) => x.is_default == 1);
        }
        csvToArray.push([space.title, checklist.description, moment(checklist.slot).format("hh:mm A"), checklist.priority ? _l("l_yes") : _l("l_no"), checklist.photo_required > 0 ? _l("l_yes") : _l("l_no"), checklist.days.mon ? _l("l_yes") : _l("l_no"), checklist.days.tue ? _l("l_yes") : _l("l_no"), checklist.days.wed ? _l("l_yes") : _l("l_no"), checklist.days.thu ? _l("l_yes") : _l("l_no"), checklist.days.fri ? _l("l_yes") : _l("l_no"), checklist.days.sat ? _l("l_yes") : _l("l_no"), checklist.days.sun ? _l("l_yes") : _l("l_no"), checklist.days.monthly ? _l("l_yes") : _l("l_no"), checklist.days.quarterly ? _l("l_yes") : _l("l_no"), checklist.days.half_yearly ? _l("l_yes") : _l("l_no"), checklist.days.yearly ? _l("l_yes") : _l("l_no"),])
      });
      createCSVFile(csvToArray, `${benchmarkDetail.provider_name}_${benchmarkDetail.id}_${_l("l_template")}.csv`)
    }
    else {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_no_checklists_to_export")));
    }
  }

  const downloadSample = () => {
    let csvToArray = [CHECKLIST_EXPORT_CSV_HEADER];
    [0, 1].forEach(() => {
      csvToArray.push(["Meeting Room", _l("l_cleaning_placeholder"), "10:30 AM", _l("l_yes"), _l("l_yes"), _l("l_no"), _l("l_yes"), _l("l_yes"), _l("l_no"), _l("l_yes"), _l("l_yes"), _l("l_no"), _l("l_no"), _l("l_no"), _l("l_no"), _l("l_no"),])
    });
    createCSVFile(csvToArray, `${_l("l_template")}.csv`)
  }

  const handleCsvImport = () => {
    setShowImportItemsModal(false);
    setCsvFile("");
    let checkListTemplateArray = [];
    let floorArrayBySpace = [];

    if (processedCSV) {
      const rows = processedCSV.split('\n');

      const spreadsheetData = rows.map(row => {
        const columns = row.split(',');
        return columns.map(column => column.replace(/(^"|"$)/g, ''));
      });

      spreadsheetData.forEach((row, rowIndex) => {
        if (rowIndex > 0 && row[1] && row[1].trim().length) {
          let spaceTitle = row[0];
          let uncheckedSpaces = [];
          let space = floorDetails.type_of_space.find((x) => ((x.title && spaceTitle && x.title.trim().toLowerCase() == spaceTitle.trim().toLowerCase())));
          if (!space) {
            space = floorDetails.type_of_space.find((x) => x.is_default == 1);
          }

          if (space && space.id) {
            checkListTemplateArray.push({
              ...checklistItem,
              id: 0,
              room_id: space.id,
              description: row[1],
              slot: new Date(`01-01-2000 ${row[2]}`),
              priority: row[3] == _l("l_yes") ? 1 : 0,
              photo_required: row[4] == _l("l_yes") ? 1 : 0,
              days: {
                mon: row[5] == _l("l_yes") ? true : false,
                tue: row[6] == _l("l_yes") ? true : false,
                wed: row[7] == _l("l_yes") ? true : false,
                thu: row[8] == _l("l_yes") ? true : false,
                fri: row[9] == _l("l_yes") ? true : false,
                sat: row[10] == _l("l_yes") ? true : false,
                sun: row[11] == _l("l_yes") ? true : false,
                monthly: row[12] == _l("l_yes") ? true : false,
                quarterly: row[13] == _l("l_yes") ? true : false,
                half_yearly: row[14] == _l("l_yes") ? true : false,
                yearly: row[15] == _l("l_yes") ? true : false,
              },
              item_comments: [],
              type_of_space_items: space.details.map((x) => x.id)
            });

            floorArrayBySpace = floorArrayBySpace.concat(space.details.map(x => x.floor_id));

            if (!space.checked) {
              uncheckedSpaces.push(space.id);
            }
          }
          uncheckedSpaces = Array.from(new Set(uncheckedSpaces)); // Remove duplicate space ids if there are any
          dispatch(updateTypeOfSpaceList(uncheckedSpaces, true));
        }
      });

      checkListTemplateArray.forEach((checklist) => {
        if (checklist.days && Object.keys(checklist.days).length) {
          Object.keys(checklist.days).forEach((day) => {
            if (WEEK_DAYS.includes(day) && checklist.days[day]) {
              checklist.days = { ...checklist.days, monthly: false, quarterly: false, half_yearly: false, yearly: false };
            }
          })
        }
      });

      let allCurrentChecklists = checklists.concat(checkListTemplateArray).filter((w) => w.description != "");
      floorDetails.type_of_space.forEach((space) => {
        if ((space.checked || space.is_default == 1) && allCurrentChecklists.findIndex((x) => x.room_id == space.id && x.description.trim() == "") === -1) {
          allCurrentChecklists.push({
            ...checklistItem, room_id: space.id, id: crypto.randomUUID(), type_of_space_items: space.details ? space.details.map(x => x.id) : [], description: "", slot: ""
          });
        }
      });
      setChecklists(allCurrentChecklists);

      floorArrayBySpace = [...new Set(floorArrayBySpace)];
      if (floorArrayBySpace.length) {
        dispatch(setFloorFilters(floorArrayBySpace));
        dispatch(selectFloorForSchedule(floorArrayBySpace));
        setDefaultSelectedFloors(floorDetails.floor.filter((x) => floorArrayBySpace.includes(x.id)).map((w) => { return { label: w.title, value: w.id } }));
      }
    }
    else {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_failed_to_process_file")));
    }
  }

  const selectChecklistTemplate = () => {

  }

  useEffect(() => {
    let currentChecklistItems = checklists.slice();
    let floorArrayBySpace = [];
    if (benchmarkSchedule && benchmarkSchedule.week_schedule_details && floorDetails.type_of_space.length) {
      let updatedChecklistItems = [];
      let typeOfSpacesInChecklist = [];
      let selectedTypeOfSpaces = floorDetails.type_of_space.filter(x => x.checked).map((x) => x.id);

      if (!currentChecklistItems.length) {
        floorDetails.type_of_space.forEach((space) => {
          currentChecklistItems.push({
            ...checklistItem, room_id: space.id, id: crypto.randomUUID(), type_of_space_items: space.details ? space.details.map((x) => x.id) : [], description: "", slot: ""
          });
        });
      }

      if (benchmarkSchedule.week_schedule_details.length) {
        benchmarkSchedule.week_schedule_details.forEach((checklist) => {
          let spaceIndex = floorDetails.type_of_space.findIndex((x) => x.id == checklist.room_id);
          if (spaceIndex !== -1) {
            if (!typeOfSpacesInChecklist.includes(checklist.room_id)) {
              typeOfSpacesInChecklist.push(checklist.room_id);
            }

            if (checklist.type_of_space_items && checklist.type_of_space_items.length) {
              floorDetails.type_of_space[spaceIndex].details.forEach((spaceItem) => {
                if (checklist.type_of_space_items.includes(spaceItem.id)) {
                  floorArrayBySpace.push(spaceItem.floor_id);
                }
              });
            }

            if (currentChecklistItems.findIndex((x) => x.id == checklist.id && x.room_id == checklist.room_id && x.description != "") == -1) {
              updatedChecklistItems.push({ ...checklist, description: checklist.item_name, slot: moment(checklist.item_slot).isValid() || checklist.item_slot instanceof Date ? checklist.item_slot : new Date(`01-01-2000 ${checklist.item_slot}`), type_of_space_items: checklist.type_of_space_items && checklist.type_of_space_items.length ? checklist.type_of_space_items : floorDetails.type_of_space[spaceIndex].details.map((x) => x.id) });
            }
          }
        });

        if (!selectedTypeOfSpaces.length && typeOfSpacesInChecklist.length && !isSameArrays(selectedTypeOfSpaces.sort((a, b) => a - b), typeOfSpacesInChecklist.sort((a, b) => a - b))) {
          dispatch(updateTypeOfSpaceList(typeOfSpacesInChecklist, true));
        }
      }

      setChecklists(updatedChecklistItems.concat(currentChecklistItems));
    }
    else if (floorDetails.type_of_space.length && !benchmarkSchedule) {
      floorDetails.type_of_space.forEach((space) => {
        if ((space.checked || space.is_default == 1) && currentChecklistItems.findIndex((x) => x.room_id == space.id && x.description.trim() == "") === -1) {
          currentChecklistItems.push({
            ...checklistItem, room_id: space.id, id: crypto.randomUUID(), type_of_space_items: space.details ? space.details.map(x => x.id) : [], description: "", slot: ""
          });
          floorArrayBySpace = floorArrayBySpace.concat(space.details.map(x => x.floor_id));
        }
      });
      setChecklists(currentChecklistItems);
    }
    floorArrayBySpace = [...new Set(floorArrayBySpace)];
    if (floorArrayBySpace.length) {
      dispatch(setFloorFilters(floorArrayBySpace));
      dispatch(selectFloorForSchedule(floorArrayBySpace));
      // setDefaultSelectedFloors(floorDetails.floor.filter((x) => floorArrayBySpace.includes(x.id)).map((w) => { return { label: w.title, value: w.id } }));
    }
  }, [benchmarkSchedule, floorDetails.type_of_space])

  useEffect(() => {
    let kpiCounts = {};
    checklists.forEach((checklist) => {
      if (checklist.days && Object.keys(checklist.days).length) {
        if (!kpiCounts[checklist.room_id]) {
          kpiCounts[checklist.room_id] = 0;
        }
        Object.values(checklist.days).forEach((slot) => {
          if (slot) {
            kpiCounts[checklist.room_id] = (kpiCounts[checklist.room_id] || 0) + (checklist.type_of_space_items.length || 1) * 1;
          }
        });
      }
    });
    dispatch(updateRoomKPICounts(kpiCounts));
  }, [checklists]);

  return (
    <React.Fragment>
      <div className={`custom-accordian-main with-left-header-arrow`}>
        {
          floorDetails.type_of_space.length ?
            floorDetails.type_of_space.filter(x => x.is_default == 1 || (x.checked && !x.filtered)).sort((a, b) => b.is_default - a.is_default).map((space, index) => {
              if (readOnlyMode && spaceHasEmptyChecklists(space.id)) {
                return <React.Fragment></React.Fragment>
              }
              return (
                <Accordion defaultActiveKey={[index.toString()]}
                  key={index}
                  alwaysOpen className={bulkActionsCheckboxes.length && bulkActionsCheckboxes.findIndex(x => x.room_id == space.id) !== -1 ? "z-index-9" : ""}>
                  <Accordion.Item id={`acc_${index}`} key={space.id} className={`mainTopicTaskFilter for-accessible`} eventKey={index.toString()}>
                    <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts custom-accordian-Header-sticky border-bottom w-100 base-modal-bg">
                      <Accordion.Header className="text-truncate w-auto bg-transparent">
                        <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                          <div className="title-font c-font f-12 fw-semibold text-truncate ">{space.title}
                            {
                              space.is_default == 1 ? <></> : <span className="c-font f-12 fw-semibold title-fonts color-green ps-2">[{getSelectedTypeOfSpaceItems(space)} / {getTotalSpaceItemQuantity(space.details.map((x) => x.floor_id))}]</span>
                            }
                          </div>
                        </div>
                      </Accordion.Header>
                      <div className="schedule-icon-wrapper">
                        <div className="d-flex align-items-center">
                          {
                            space.is_default == 1
                              ?
                              <React.Fragment></React.Fragment>
                              :
                              <UncontrolledDropdown className="with_separator_10" isOpen={index == dropdownKey ? true : false}>
                                <DropdownToggle tag="a" className=" on-hover-active-toggle-img z-index-3 before-dnone border-0" onClick={() => { setDropdownKey(index); }} >
                                  <GearSix size={18} className="c-icons" weight="light" />
                                </DropdownToggle>
                                <DropdownItem toggle={false} className="p-0" >
                                  <TypeOfSpaceAllotment readOnlyMode={readOnlyMode} setDropdownKey={setDropdownKey} space={space} key={index} isChecklistCreation={true} checklists={checklists} setChecklists={setChecklists} />
                                </DropdownItem>
                              </UncontrolledDropdown>
                          }
                          {bulkActionsCheckboxes.length && bulkActionsCheckboxes.findIndex(x => x.room_id == space.id) !== -1 ?
                            <div className=""
                               
                               
                               
                            >
                              <Select
                                placeholder={`${_l("l_move_to_other_room")}`}
                                className="custom-select-menu with-filter-select min-h-28 me-0 w-180px"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                isOpen={true}
                                options={floorDetails.type_of_space.filter(x => x.id != space.id && (x.is_default == 1 || x.checked)).map((w) => {
                                  return { label: w.title, value: w.id }
                                })}
                                onChange={(e) => {
                                  moveToTypeOfSpace(bulkActionsCheckboxes, e.value);
                                  setBulkActionsCheckboxes([]);
                                }}
                              />
                               
                            </div>
                            :
                            <React.Fragment></React.Fragment>

                          }
                        </div>
                      </div>
                    </div>
                    <Accordion.Body className="mt-1 childTopicTaskFilter">
                      <GeneralItemTable readOnlyMode={readOnlyMode} key={index} checklists={readOnlyMode ? checklists.filter((x) => x.description != "" && x.room_id == space.id) : checklists} setChecklists={setChecklists} room_id={space.id} room_title={space.title} setBulkActionsCheckboxes={setBulkActionsCheckboxes} bulkActionsCheckboxes={bulkActionsCheckboxes} isReadOnly={readOnlyMode} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )
            })
            :
            <CommanPlaceholder imgType="no-checklist" mainSpacing="m-auto" placeholderText={_l("l_no_checklist")} />
        }
        {
          readOnlyMode && checklists.filter((x) => x.description.trim() != "").length == 0
            ?
            <CommanPlaceholder imgType="no-checklist" mainSpacing="m-auto" placeholderText={_l("l_no_checklist")} />
            :
            <React.Fragment></React.Fragment>
        }
      </div >
      {
        readOnlyMode
          ?
          <React.Fragment></React.Fragment>
          :
          <div className={`position-sticky-bottom bg-base-header-color  px-0 z-index-3 border-top w-100 mt-auto custom-footer`}>
            {/* Template and Import-Export feature... will be added here*/}
            <div className={`align-items-center d-flex w-100 border-top justify-content-between`}>
              {/* {
                floorDetails.floor && floorDetails.floor.length > 1
                  ?
                  <Select
                    placeholder={_l("l_floors")}
                    className="custom-select-menu drop-up min-h-28 max-h95 m-w40 bg-transparent me-2"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    isMulti={true}
                    value={defaultSelectedFloors}
                    options={floorDetails.floor ? floorDetails.floor.map((x) => { return { label: x.title, value: x.id } }) : []}
                    onChange={floorSelectBoxOnChangeEvent}
                  />
                  :
                  <React.Fragment></React.Fragment>
              } */}
              <div className='d-none'>
                <Select
                  placeholder={_l("l_template")}
                  className="custom-select-menu drop-up min-h-28 max-h95 m-w40 bg-transparent me-2"
                  classNamePrefix="react-select"
                  options={templateOptions}
                  onChange={selectChecklistTemplate}
                />
                <Button variant="secondary me-2" size="sm" className="position-relative"
                  onClick={() => {
                    setShowImportItemsModal(true);
                  }}>{_l('l_save_as_template')}
                </Button>
              </div>
              <Button variant="secondary me-2" size="sm" className="position-relative"
                onClick={() => {
                  setShowImportItemsModal(true);
                }}>{_l('l_import_items')}
              </Button>
              <Button variant="secondary me-2" size="sm" className="position-relative"
                onClick={downloadCSV}>{_l('l_export_items')}
              </Button>
              <ScheduleFooter className="w-100" currentStep={3} selectedTab={`create-schedule-type`} handleStep={(step) => {
                if (step == 'prev') {
                  handleStep(step == 'prev' ? 'create-schedule-type' : '')
                }
                setLoader(0)
              }} isEdit={isEdit} isCreateBenchmark={isCreateBenchmark} providerCategory={getBasicDetails("provider_id")} loader={loader} setLoader={setLoader} />
              <div className="p-15 px-0">
                {
                  isCreateBenchmark ? <Button variant="primary" className="ms-3" size="sm" disabled={loader == 3} onClick={() => {
                    setLoader(3)
                    handleStep3('accessories')
                  }}>{_l('l_next')}{loader == 3 ? handleLoder() : ""}</Button> : <div className={`d-flex justify-content-center ms-4 ${!readOnlyMode || !isEdit ? '' : 'd-none'}`}>
                    <div>
                      <Button
                        variant="secondary  me-3"
                        size="sm"
                        disabled={loader == 2}
                        className={`${(getBasicDetails("status_text") == "draft") ? "" : "d-none"}`} onClick={() => { setLoader(2); handleStep3(2); }}>  {loader == 2 ? _l("l_please_wait") : _l("l_save_as_draft")} {loader == 2 ? handleLoder() : ""}</Button>
                      <Button variant="primary" size="sm" disabled={loader == 1} onClick={() => { setLoader(1); handleStep3(1); }}> {loader == 1 ? _l("l_please_wait") : generateButtonText(getBasicDetails("status"))} {loader == 1 ? handleLoder() : ""}</Button>
                    </div >
                  </div >
                }
              </div>
            </div >
          </div >
      }
      {
        showImportItemsModal
          ?
          <></>
          :
          <React.Fragment></React.Fragment>
      }
    </React.Fragment>
  )
}

export default AssignmentSchedule
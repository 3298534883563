import React, { useEffect, useRef } from "react";
import Lightbox from "yet-another-react-lightbox";
import FollowersDropdown from "./Dropdowns/FollowersDropdown";
import ReactHtmlParser from "react-html-parser";
 
import { useState } from "react";
import {
  TaskCommentModalOpen,
  _l,
  TaskCommentModalToggle,
  TaskModalClose,
  CloseCommentModal,
  tabindexFix,
} from "../hooks/utilities";
import jquery from "jquery";
import ChecklistComponent from "./TaskModalComponents/ChecklistComponent";
import TaskShareLinkModal from "./Modals/TaskShareLinkModal";
import { MentionsInput, Mention } from "react-mentions";
import Offcanvas from "react-bootstrap/Offcanvas";
import CommanLoader from "./Loader/CommanLoader";
import RightSideComments from "./StandAlonePages/RightSideComments";
import {
  updateTaskStatus,
  changePriority,
  changeTaskProject,
  setTaskData,
  updateTaskPin,
  getTaskCount,
  setTaskAssignee,
  SetSelectedTask,
  getSelectedTask,
  IsToastOpenOrClose,
  getCustomerSpaces,
  getTopicTasks,
  updateUnreadCommentCount,
} from "../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import FileUploadComponent from "./TaskModalComponents/FileUploadComponent";
import LinkComponent from "./TaskModalComponents/LinkComponent";
import CustomerServices from "../services/customer-services";
import NotesComponent from "./TaskModalComponents/NotesComponent";
import * as bootstrap from "bootstrap";
import Toasts from "./Toasts/Toasts";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import CommonHeaderModal from "./TaskModalComponents/CommonHeaderModal";
import CommonHeaderBody from "./TaskModalComponents/CommonHeaderBody";
import {
  X,
  Info,
  ArrowRight,
  DotsThreeVertical,
  UserPlus,
  MagnifyingGlass,
  CornersIn,
  At,
  CornersOut,
  Paperclip,
  Hash,
  ShareNetwork,
} from "phosphor-react";
import CommonFooter from "./TaskModalComponents/CommonFooter";
import DocumentsActivity from "./Documents/DocumentsActivity";
import DocumentsDetail from "./Documents/DocumentsDetail";
import Document from "./Documents/tabs/Document";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
window.bootstrap = bootstrap;

const TaskDetail = ({
  taskId,
  projectId,
  handler,
  offCanvasshow,
  offCanvashandleClose = null,
  providerDefaultType = null,
  providerDefaultValue = null,
  providerDefaultDetails = null,
  attachmentCountUpdate = null,
  convertRequestTaskId = 0,
  taskDeleted,
  isCreateTask = false,
}) => {
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const appliedFilters = useSelector((state) => state.customer.taskFilters);
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [taskStatus, setTaskStatus] = useState(1);
  const [taskPriority, setTaskPriority] = useState(
    selectedTask ? (selectedTask.priority == "3" ? 3 : 2) : 0
  );
  const [commentList, setCommentList] = useState([]);
  const [taskTags, setTaskTags] = useState([]);
  const [commentContent, setCommentContent] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [commentId, setCommentId] = useState(0);
  const [myr_total, setMyr_total] = useState(0);
  const [taskProject, setTaskProject] = useState("");
  const [taskRecurringData, setTaskRecurringData] = useState([]);
  const [activeTab, setActiveTab] = useState(
    selectedTask &&
      (selectedTask.provider === "google_drive" || selectedTask.provider === "one_drive") &&
      typeof selectedTask.is_synced !== "undefined"
      ? "document"
      : "checklist"
  );
  const [mentionList, setMentionList] = useState([]);
  const [showData, setShowData] = useState(0);
  const [toastHeader, setToastHeader] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastClass, setToastClass] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [imageBuffer, setImageBuffer] = useState([]);
  const [showSearch, setshowSearch] = useState(false);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const [showMyrTotal, setShowMyrTotal] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [commentTab, setCommentTab] = useState(true);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [providerType, setProviderType] = useState("");
  const [providerValue, setProviderValue] = useState("");
  const [providerDetail, setProviderDetail] = useState("");
  const [openGallery, setOpenGallery] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [defaultComment, setdefaultComment] = useState([]);
  const [taskPinShow, setTaskPinShow] = useState(false);
  const [previewSrc, setPreviewSrc] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [tempCommentId, setTempCommentId] = useState(0);
  const [showFullscreen, setShowFullscreen] = useState(
    (selectedTask.provider == "google_drive" || selectedTask.provider === "one_drive") && selectedTask.is_synced
      ? true
      : false
  );
  const [ShowCommentModal, setShowCommentModal] = useState(false);
  const [selectedTagId, setTagId] = useState(0);
  const [searchLinkAttchment, setSearchLinkAttchment] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [toastClose, setToastClose] = useState(false);
  const [taskCreatorImage, setTaskCreatorImage] = useState(
    selectedTask
      ? selectedTask.creator_profile_image
      : localStorage.getItem("contact_image")
  );
  const [taskCreatorName, setTaskCreatorName] = useState(
    selectedTask ? selectedTask.creator_name : localStorage.getItem("full_name")
  );
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [unreadCommentCount, setUnreadCommentCount] = useState(0);
  const [commonHeaderData, setCommonHeaderData] = useState({
    creater_name: "",
    creater_profile: "",
    document_name: "",
    document_name_permission: "",
    document_id: "",
  });
  const [commonHeaderBodyData, setCommonHeaderBodyData] = useState({});

  const dispatch = useDispatch();

  const closeButtonRef = useRef();
  let totalRef = null;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const projects = JSON.parse(localStorage.getItem("spaces"));
  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");
  const userType = localStorage.getItem("user_type");
  const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const userRole = localStorage.getItem("contact_role");
  const client_id = localStorage.getItem("client_id");
  const contact_image = localStorage.getItem("contact_image");
  const selectedDate =
    localStorage.getItem("calendarData") &&
    localStorage.getItem("calendarData") != "undefined"
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : new Date();

  const mentionsList = useSelector((state) => state.customer.mentionList);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [visibleToCustomer, setVisibleToCustomer] = useState(false);
  const [visibleToProvider, setVisibleToProvider] = useState(false);
  const hideTaskModal = () => {
    if (
      selectedTask &&
      selectedTask.is_allowed_to_edit &&
      (!taskName || !taskName.trim()) &&
      unsavedChanges
    ) {
      setToastHeader(_l("l_are_you_sure"));
      setToastClass("toast-with-btn");
      setToastMessage(_l("l_do_you_want_to_save_the_task_as_draft"));
      setConfirmButtonText(_l("l_save"));
      setCancelButtonText(_l("l_delete"));
      setShowToast(true);
      return;
    }
    handler(taskId, false);
    dispatch(SetSelectedTask(""));
    offCanvashandleClose();
    // jquery(".task-list-accordian").addClass("list-view-toggle");
  };

  useEffect(() => {
    jquery(".checklist_textarea").each(function () {
      jquery(this).css("height", "20px");
      this.style.height = this.scrollHeight + "px";
    });
    setSearchLinkAttchment("");
  }, [activeTab, selectedTask]);

  useEffect(() => {
    setshowSearch(false);
    setSearchString("");
    setSearchLinkAttchment("");
    if (taskId && projectId) {
      try {
        document.getElementById(`task_${taskId}`).scrollIntoView();
      } catch (error) {
        console.log(error);
      }
    }
  }, [taskId, projectId]);

  useEffect(() => {
    if (mentionsList) {
      setMentionList(
        mentionsList.map((name) => {
          return {
            display: name.name,
            id: name.id,
            image: name.profile_image || "",
          };
        })
      );
    }
  }, [mentionsList]);

  useEffect(() => {
    setCommentContent("");
    setIsChecked(false);
    if (selectedTask) {
      const taskSpace = projects.filter(
        (project) => project.project_id == projectId
      );
      setTaskName(selectedTask.name ? selectedTask.name : "");
      setVisibleToCustomer(
        selectedTask && selectedTask.visible_to_client == 1 ? true : false
      );
      setVisibleToProvider(
        selectedTask && selectedTask.visible_to_provider == 1 ? true : false
      );
      setTaskStatus(parseInt(selectedTask.status));
      if (selectedTask.startdate != null) {
        setStartDate(new Date(selectedTask.startdate));
      }
      if (
        selectedTask.duedate != null &&
        selectedTask.duedate != "0000-00-00 00:00:00"
      ) {
        setDueDate(new Date(selectedTask.duedate));
      }
      setTaskPinShow(parseInt(selectedTask.is_pinned) > 0);
      setTaskPriority(selectedTask.priority == "3" ? 3 : 2);
      setTaskTags(selectedTask.tags);
      if (
        providerDefaultType == null &&
        providerDefaultValue == null &&
        providerDefaultDetails == null
      ) {
        setShowMyrTotal(selectedTask.myr_total > 0);
      }
      if (taskSpace && taskSpace.length) {
        setTaskProject(
          taskSpace[0].office_title || taskSpace[0].postal_address
        );
      }
      setTaskRecurringData({
        recurring_type: selectedTask.recurring_type,
        custom_recurring: selectedTask.custom_recurring,
        recurring: selectedTask.recurring,
        repeat_every: selectedTask.repeat_every,
      });
      if (selectedTask.task_creator_image) {
        setTaskCreatorImage(selectedTask.task_creator_image);
      }
      setTagId(0);
      if (selectedTask.tags && selectedTask.tags.length) {
        setTagId(selectedTask.tags[0].id);
      }
      setMyr_total(selectedTask.myr_total);
      setCommentCount(selectedTask.comment_count);
      setUnreadCommentCount(selectedTask.unread_comment_count);
      setCommonHeaderData({
        creater_name: selectedTask
          ? selectedTask.creator_name
          : localStorage.getItem("full_name"),
        creater_profile: selectedTask.task_creator_image
          ? selectedTask.task_creator_image
          : "",
        document_name: selectedTask.name ? selectedTask.name : "",
        document_name_permission: selectedTask
          ? parseInt(selectedTask.is_allowed_to_edit) != 1
          : false,
        document_id: taskId,
        task_redirect_btn_permission:
          selectedTask && selectedTask.request_task_id > 0,
        task_redirect_btn_text: _l("l_view_request_task"),
        document_type:
          selectedTask.provider === "google_drive" || selectedTask.provider === "one_drive"
            ? "document task"
            : _l("l_task"),
        show_full_screen: showFullscreen,
        follow_btn_text: _l("l_follow"),
        show_task_myr_total:
          providerDefaultType == null &&
          providerDefaultValue == null &&
          providerDefaultDetails == null &&
          selectedTask.myr_total > 0,
        myr_total: selectedTask.myr_total,
        myr_total_permission: selectedTask
          ? parseInt(selectedTask.is_allowed_to_edit) < 1
          : false,
      });
      setCommonHeaderBodyData({
        date_tooltip:
          selectedTask && selectedTask.schedule_id > 0
            ? _l("l_start_date")
            : _l("l_end_date"),
        date:
          selectedTask && selectedTask.schedule_id > 0
            ? selectedTask.startdate
              ? new Date(selectedTask.startdate)
              : ""
            : selectedTask.duedate
            ? new Date(selectedTask.duedate)
            : "",
        date_permission: selectedTask
          ? parseInt(selectedTask.is_allowed_to_edit) < 1
          : false,
        is_pinned: parseInt(selectedTask.is_pinned) > 0,
        status_class:
          selectedTask.status == 1
            ? "in-progress-white"
            : selectedTask.status == 4
            ? "in-progress-blue"
            : "done",
        status_permission:
          selectedTask && parseInt(selectedTask.is_allowed_to_edit) > 0,
        priority: selectedTask.priority == "3" ? 1 : 0,
        priority_permission: selectedTask
          ? parseInt(selectedTask.is_allowed_to_edit) < 1
          : false,
        common_permission:
          selectedTask && parseInt(selectedTask.is_allowed_to_edit) > 0,
        project_rel_id: selectedTask && selectedTask.rel_id,
        recurring_data: {
          recurring_type: selectedTask.recurring_type,
          custom_recurring: selectedTask.custom_recurring,
          recurring: selectedTask.recurring,
          repeat_every: selectedTask.repeat_every,
        },
        document_id: taskId,
        tag_id:
          selectedTask.tags && selectedTask.tags.length
            ? selectedTask.tags[0].id
            : 0,
        assignee_providers:
          selectedTask &&
          selectedTask.task_assignee &&
          selectedTask.task_assignee.providers,
        assignee_equipments:
          selectedTask &&
          selectedTask.task_assignee &&
          selectedTask.task_assignee.equipments,
        visible_to_client:
          selectedTask && selectedTask.visible_to_client == 1 ? true : false,
        visible_to_provider:
          selectedTask && selectedTask.visible_to_provider == 1 ? true : false,
      });
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
        setUnsavedChanges(true);
      });
    });

    return () => {
      imagePreviews.map((img) => {
        URL.revokeObjectURL(img.url);
      });
      jquery(document).unbind("ready");
    };
  }, [JSON.stringify(selectedTask)]);

  const handleCommonAction = (type, data) => {
    if (type == "nameChangeValue") {
      setTaskName(data);
    } else if (type == "nameUpdate") {
      updateTaskData(undefined, undefined, undefined, visibleToCustomer, visibleToProvider);
    } else if (type == "modalClose") {
      hideTaskModal();
      TaskModalClose();
      setShowCommentModal(false);
    } else if (type == "taskMoveBtn") {
      dispatch(
        getSelectedTask(
          localStorage.getItem("selectedOffice"),
          selectedTask && selectedTask.request_task_id,
          1
        )
      );

      handler(selectedTask && selectedTask.request_task_id, "show", 1, taskId);
    } else if (type == "shareNetwork") {
      setShow(true);
    } else if (type == "showFullscreen") {
      setShowFullscreen(data);
    } else if (type == "follow") {
      setShow(true);
    } else if (type == "setMyr_total") {
      setMyr_total(data);
    } else if (type == "myrTotalUpdate") {
      if (
        selectedTask.task_assignee &&
        selectedTask.task_assignee.providers &&
        selectedTask.task_assignee.providers.length
      ) {
        updateTaskData(undefined, undefined, myr_total);
      } else {
        assignToProvider(providerType, providerValue, providerDetail);
      }
    } else if (type == "totalRef") {
      totalRef = data;
    }
  };
  const handleCommonHeaderBodyAction = (type, data) => {
    if (type == "dateUpdate") {
      if (selectedTask && selectedTask.schedule_id > 0) {
        setStartDate(data);
        updateTaskData(data, undefined);
      } else {
        setDueDate(data);
        updateTaskData(undefined, data);
      }
    } else if (type == "pin") {
      handlePinTask();
    } else if (type == "status") {
      changeTaskStatus(data);
    } else if (type == "priority") {
      changeTaskPriority();
    } else if (type == "project") {
      handleChangeTaskProject(data.id, data.name);
    } else if (type == "recurring") {
      handleSetRecurringTask(data);
    } else if (type == "assignees") {
      handleProvider(data.type, data.value, data.details);
    } else if (type == "tag") {
      setTagId(0);
      setTaskTags([]);
      if (data && data.tab && data.is_remove == 0) {
        setTagId(data.tab.id);
        setTaskTags([data.tab]);
      }
    } else if (type == "handleTaskAssigneeData") {
      handleTaskAssigneeData();
    } else if (type == "visible_to_client") {
      updateTaskData(undefined, undefined, undefined, data ,visibleToProvider);
    }
    else if (type == "visible_to_provider") {
      updateTaskData(undefined, undefined, undefined, visibleToCustomer, data);
    }
    dispatch(
      getSelectedTask(
        localStorage.getItem("selectedOffice"),
        selectedTask && selectedTask.id ? selectedTask.id : 0,
        null
      )
    );
  };

  useEffect(() => {
    if (searchString != "") {
      setCommentList((commentList) =>
        defaultComment.filter(
          (comment) =>
            comment.content &&
            comment.content.toLowerCase().includes(searchString.toLowerCase())
        )
      );
      scrollToBottom();
    } else {
      setCommentList(defaultComment);
    }
  }, [searchString]);

  const addNewComment = () => {
    // var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // && !format.test(commentContent)
    if (imagePreviews.length || (commentContent && commentContent.trim())) {
      if (commentId) {
        CustomerServices.editLinkComment(
          taskId,
          projectId,
          commentContent.trim(),
          undefined,
          commentId,
          undefined,
          undefined,
          imageBuffer
        ).then((res) => {
          if (res.status) {
            commentList.map((comment) => {
              if (comment.id === commentId) {
                if (imageBuffer.length) {
                  let tempImage = comment.attachments.length
                    ? comment.attachments
                    : [];
                  if (res.data.files_list && res.data.files_list.length) {
                    res.data.files_list.map((img) => {
                      tempImage.push({
                        id: img.file_id,
                        file_name: img.image_url,
                      });
                    });
                  }
                  comment.attachments = tempImage;
                }
                comment.content = commentContent.trim();
              }
            });
            setCommentList(commentList);
            setCommentContent("");
            setCommentId(0);
            setImagePreviews([]);
            setImageBuffer([]);
          }
        });
      } else {
        const comment = commentContent.trim();
        imagePreviews.map((img) => {
          URL.revokeObjectURL(img.url);
        });
        setImagePreviews([]);
        setCommentContent("");
        CustomerServices.addLinkComment(
          taskId,
          projectId,
          comment,
          undefined,
          undefined,
          undefined,
          undefined,
          imageBuffer
        ).then((res) => {
          if (res.status) {
            setCommentList((commentList) => [
              ...commentList,
              {
                id: res.data.comment_id,
                contact_id,
                dateadded: new Date().toISOString(),
                contact_full_name: localStorage.getItem("full_name"),
                content: comment,
                time: res.data.time,
                addedfrom_details: {
                  profile_url: localStorage.getItem("contact_image"),
                },
                staffid: localStorage.getItem("staff_id"),
                attachments: res.data.image_url
                  ? res.data.image_url.map((img) => {
                      return { file_name: img };
                    })
                  : [],
                is_allowed_to_edit: 1,
              },
            ]);
            if (res.data.files_list && res.data.files_list.length) {
              attachmentCountUpdate(taskId, res.data.files_list.length);
            }
            setImageBuffer([]);
            setCommentCount(commentCount + 1);
          }
        });
      }
    } else {
      setShowToast(false);
      setToastClass("unsucess");
      setToastHeader(_l("l_error"));
      setToastMessage(_l("l_please_enter_valid_comment"));
      setShowToast(true);
    }
  };

  const changeTaskStatus = (statusId) => {
    dispatch(updateTaskStatus(taskId, projectId, statusId));
    setTaskStatus(statusId);
    dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
  };

  const changeTaskPriority = () => {
    dispatch(changePriority(taskId, projectId, taskPriority, selectedTask.provider)).then(() => {
      setTaskPriority(taskPriority == 3 ? 2 : 3);
    });
  };

  const handleAddTag = () => {
    if (tagValue.trim()) {
      CustomerServices.setTag(taskId, projectId, tagValue.trim()).then(
        (res) => {
          if (res.data && res.data.tag_id) {
            setTagValue("");
            setTaskTags([
              ...taskTags,
              { name: tagValue.trim(), id: res.data.tag_id },
            ]);
          }
        }
      );
    }
  };

  const deleteComment = (id) => {
    CustomerServices.removeComment(projectId, id).then((res) => {
      if (res.status) {
        setCommentList(commentList.filter((comment) => comment.id !== id));
        setCommentContent("");
      }

      //Acknowledgement for deletion if required
    });
  };

  const handleEditComment = (id) => {
    const targetComment = commentList.filter((comment) => comment.id === id);
    if (targetComment.length) {
      setCommentId(id);
      setCommentContent(targetComment[0].content);
    }
  };

  const handleChangeTaskProject = (id, name) => {
    setTaskProject(name);
    dispatch(changeTaskProject(id, taskId)).then(() => {
      if (localStorage.getItem("selectedOffice") != id) {
        hideTaskModal();
        dispatch(SetSelectedTask(""));
      }
    });
  };

  const updateTaskData = (
    startDateTemp = "",
    dueDateTemp = "",
    total = 0,
    visibility
  ) => {
    dispatch(
      setTaskData(
        projectId,
        taskId,
        taskName,
        startDateTemp || startDate,
        dueDateTemp || dueDate,
        total || myr_total,
        0,
        0,
        0,
        "",
        "",
        visibility
      )
    );

    if (startDateTemp || dueDateTemp)
      dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
  };

  const handleSetRecurringTask = (recurringData) => {
    setTaskRecurringData(recurringData);
    setCommonHeaderBodyData({
      ...commonHeaderBodyData,
      ["recurring_data"]: recurringData,
    });
    CustomerServices.setTaskRecurring(projectId, taskId, recurringData).then(
      () => {}
    );
  };

  const getTaskComments = (pending = 0) => {
    CustomerServices.getComments(taskId, projectId, undefined, pending, 1).then(
      (res) => {
        const comments = res.data;
        setCommentList([]);
        setdefaultComment([]);
        comments.forEach((item) => {
          setCommentList((commentList) => [...commentList, item]);
          setdefaultComment((defaultComment) => [...defaultComment, item]);
        });
        if (localStorage.getItem("accessToken")) {
          const staffOrContactId =
            localStorage.getItem("user_type") == "contact"
              ? localStorage.getItem("contact_id")
              : localStorage.getItem("staff_id");
          const client_id = localStorage.getItem("client_id");
          dispatch(getCustomerSpaces(client_id, staffOrContactId));
          dispatch(getTopicTasks(projectId));
          dispatch(updateUnreadCommentCount(taskId, 0));
        }
      }
    );
  };

  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(imageBuffer).filter(
      (img) => img.name !== name
    );
    setImageBuffer(updatedFileList);
    preparePreview(updatedFileList);
  };

  const convertTask = (id) => {
    CustomerServices.convertToTask(projectId, id).then(() => {
      offCanvashandleClose();
      dispatch(SetSelectedTask(""));
      dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
    });
  };

  const assignToProvider = (type, value, details) => {
    updateTaskData(undefined, undefined, myr_total);
    CustomerServices.setTaskAssignee(projectId, taskId, type, value).then(
      (res) => {
        if (res.status && res.data) {
          dispatch(setTaskAssignee(details, type));
        }
      }
    );
  };
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };
  const handleProvider = (type, value, details) => {
    if (myr_total > 0) {
      assignToProvider(type, value, details);
    } else {
      setShowMyrTotal(true);
      totalRef.focus();
      setProviderType(type);
      setProviderValue(value);
      setProviderDetail(details);
    }
  };

  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      previrewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });
    setImagePreviews(previrewArray);
  };

  const getMentionData = () => {
    setShowData(showData == 0 ? 1 : 0);
    setCommentContent(showData == 0 ? "@" : "");
    if (!showData) {
      jquery("#commentInput").focus();
    } else {
      setCommentContent("");
    }
  };

  const handleSearch = (e) => {
    const searchStringInput = e.target.value;
    setSearchString(searchStringInput);
  };

  const deleteTask = () => {
    setShowLoader(true);
    CustomerServices.deleteTask(taskId, projectId).then((res) => {
      if (res.status === 1) {
        dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
        setShowLoader(false);
        // setToastClose(true)
        offCanvashandleClose();
      } else {
        setShowLoader(false);
        setShowToast(false);
        setToastClass("unsucess");
        setToastHeader(_l("l_error"));
        setToastMessage(res.message);
        setShowToast(true);
      }
    });
  };

  const tostsDeleted = () => {
    setToastClass("success");
    setToastHeader(_l("l_success"));
    setToastMessage(_l("l_task_sucessfully_removed"));
    setShowToast(true);
  };
  const handlePinTask = () => {
    dispatch(updateTaskPin(taskId, projectId, taskPinShow, selectedTask.provider));
    setTaskPinShow(taskPinShow == 0 ? 1 : 0);
  };
  const scrollToBottom = () => {
    var msgListDiv = document.getElementById("comment-list");
    msgListDiv.scrollBottom = msgListDiv.scrollHeight;
  };

  const openToastClose = () => {
    setToastClass("unsucess");
    setToastHeader("");
    setConfirmButtonText("");
    setCancelButtonText("");
    setToastMessage(0);
    setTempCommentId(0);
    setShowToast(false);
  };

  if (commentList.length && offCanvasshow) {
    setTimeout(() => {
      var msgListDiv = document.getElementById("comment-list");
      try {
        if (msgListDiv.scrollHeight && msgListDiv.scrollHeight != null)
          msgListDiv.scrollTop = msgListDiv.scrollHeight
            ? msgListDiv.scrollHeight
            : 0;
      } catch (err) {
        console.log(err);
      }
    }, 100);
  }
  const handleWindowResize = () =>
    window.innerWidth <= 1500 ? setisMobileView(true) : setisMobileView(false);
  const [isMobileView, setisMobileView] = useState(
    window.innerWidth <= 1500 ? true : false
  );

  const removeTag = (id) => {
    CustomerServices.removeTag(taskId, projectId, id).then((res) => {
      if (res.data) {
        let tags = taskTags.filter((tag) => tag.id != id);
        setTaskTags(tags);
      }
    });
  };
  const handleCommonFooter = (type, data) => {
    if (type == "addComment") {
      addNewComment();
      setShowCommentModal(!ShowCommentModal);
      TaskCommentModalOpen();
    } else if (type == "commentValueChange") {
      setCommentContent(data);
    } else if (type == "removePreview") {
      removeImageSelection(data);
    } else if (type == "fileUpload") {
      if (data.target.files.length > 5) {
        setToastClass("unsucess");
        setToastHeader(_l("l_error"));
        setToastMessage(_l("l_maximum_5_files_are_allowed_at_a_time"));
        setShowToast(true);
        return;
      }
      setImageBuffer(data.target.files);
      preparePreview(data.target.files);
    } else if (type == "getComment") {
      setShowCommentModal(!ShowCommentModal);
      TaskCommentModalOpen();
      getTaskComments();
    }
  };
  useEffect(() => {
    if (ShowCommentModal) {
      TaskCommentModalOpen();
    } else {
      if (ShowCommentModal) {
      } else {
        TaskCommentModalToggle();
      }
    }
  }, [ShowCommentModal]);

  return (
    <React.Fragment>
      {showToast ? (
        <Toasts
          delay={4000}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            if (tempCommentId) {
              setTempCommentId(0);
              setShowToast(false);
            } else {
              deleteTask();
              setShowToast(false);
            }

            // setShowToast(false);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            if (tempCommentId) {
              deleteComment(tempCommentId);
              setTempCommentId(0);
              setShowToast(false);
            } else {
              setShowToast(false);
              setToastClass("unsucess");
              setToastHeader(_l("l_error"));
              setToastMessage(_l("l_please_enter_task_name_tosave_the_task"));
              setShowToast(true);
            }
          }}
        />
      ) : (
        <></>
      )}
      <Lightbox
        key={galleryIndex}
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={previewSrc}
      />
      <div className="custom-modal-style task-detail-modal position-relative ">
        <Offcanvas
          show={offCanvasshow}
          restoreFocus={false}
          onHide={() => {
            offCanvashandleClose();
          }}
          onShow={() => {
            tabindexFix();
          }}
          className={`taskDetailModal custom-offcanvas-modal radius_3 overflow-hidden custom-modal-style ${
            showFullscreen ? "modal-full" : ""
          }`}
          // className="taskDetailModal custom-offcanvas-modal custom-modal-style radius_3 overflow-hidden "
          placement="end"
          scroll={false}
          backdrop={false}
        >
          {/* ${selectedTask && selectedTask.is_requested == 1
              ? "with-overlay-blur"
              : ""
              } */}
          <div className={`custom`}></div>
          <Offcanvas.Body className="p-0">
            <div className="modal-body p-0 res-overflow-auto">
              <div className="d-flex justify-content-between flex-wrap h-100">
                <div className="task-detail-left-main h-100 res-width-100 d-flex flex-column">
                  <CommonHeaderModal
                    data={commonHeaderData}
                    type={"task"}
                    handleAction={handleCommonAction}
                    showTotal={showMyrTotal}
                    showFullscreen={showFullscreen}
                    setShowFullscreen={setShowFullscreen}
                    setShowCommentModal={setShowCommentModal}
                  />
                  {/* 
                  <div className="task-detail-header">
                    <div className="d-flex align-items-center">
                      <div className="task-header-left flex-grow-1">
                        <div className="d-flex align-items-center">
                          <div className="task-creator-icon">
                            <div
                              className="h25w25 comman-round-box with-bg rounded-circle bg-white-05"
                              aria-expanded="false"
                               
                            >
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('${taskCreatorImage}')`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="task-name-input w100minus40">
                            <input
                              type=""
                              className="form-control transparent py-0 c-font f-16 fw-semibold"
                              placeholder={`${_l("l_task_name")}`}
                               
                               
                             
                              data-bs-placement="left"
                              value={taskName}
                              onChange={(e) => {
                                setTaskName(e.target.value);
                              }}
                              onBlur={() => {
                                updateTaskData(
                                  undefined,
                                  undefined,
                                  undefined,
                                  visibleToCustomer
                                );
                              }}
                              disabled={
                                selectedTask
                                  ? parseInt(selectedTask.is_allowed_to_edit) !=
                                  1
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="task-header-right">
                        <div className="d-flex align-items-center justify-content-end">
                          {(userRole == 1 || userRole == 2) &&
                            selectedTask &&
                            selectedTask.is_requested == 1 ? (
                            <div className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light ">
                              <span className="text-warning">
                                {_l("l_request")}
                              </span>
                            </div>
                          ) : (
                            <>
                              <a href="#/"
                                className={`btn btn-primary btn-sm line-height-1 action_icon mx-2 ${selectedTask &&
                                  selectedTask.request_task_id > 0
                                  ? ""
                                  : "d-none"
                                  }`}
                                onClick={() => {
                                  if (
                                    selectedTask &&
                                    selectedTask.request_task_id > 0
                                  ) {
                                    dispatch(
                                      getSelectedTask(
                                        localStorage.getItem("selectedOffice"),
                                        selectedTask.request_task_id,
                                        1
                                      )
                                    );

                                    handler(
                                      selectedTask.request_task_id,
                                      "show",
                                      1,
                                      taskId
                                    );
                                  }
                                }}
                              >
                                {_l("l_view_request_task")}
                              </a>
                              <div className="badge badge-white rounded-pill badge  badge-white rounded-pill ">
                                <span className="color-white">
                                  {_l("l_task")}
                                </span>
                              </div>
                            </>
                          )}
                          <div className="d-flex align-items-center justify-content-between">
                            <a href="#/" className="action_icon d-none">
                              <MagnifyingGlassIcon className="HW18" />
                            </a>
                            <a href="#/"
                              className="action_icon ml-10 d-none"
                              onClick={() => {
                                setShow(true);
                              }}
                            >
                              <ShareNetworkIcon className="HW18" />
                            </a>
                            {showFullscreen ? (
                              <a href="#/"
                                className="action_icon ml-10"
                                onClick={() => setShowFullscreen(false)}
                                // id="modal_zoom_in"
                                 
                                 
                                 
                                 
                              >
                                <CornersInIcon className="HW18 zoom_in" />
                              </a>
                            ) : (
                              <a href="#/"
                                className="action_icon ml-10"
                                onClick={() => setShowFullscreen(true)}
                                // id="modal_zoom_out"
                                 
                                 
                                 
                                 
                              >
                                <CornersOutIcon className="HW18 zoom_out" />
                              </a>
                            )}
                            <a href="#/"
                              className="btn btn-white-10 btn-sm line-height-1 action_icon ml-10"
                              onClick={() => {
                                setShow(true);
                              }}
                            >
                              {_l("l_follow")}
                            </a>
                            <a href="#/" className="action_icon ml-10 d-none">
                              <VideoCameraIcon className="HW18" />
                            </a>
                          </div>
                          {/* <div className="badge badge-white color-green c-font f-14 fw-semibold p-2">
                        ${myr_total}
                      </div> */}
                  {/*  <input
                    className={`badge-white color-green c-font f-14 ms-2 fw-semibold p-1 radius_3 text-center title-fonts ${showMyrTotal ? "" : "d-none"
                      }`}
                    placeholder="$300"
                    type="text"
                     
                     
                     
                     
                    value={"€ " + myr_total}
                    id="myr_total"
                    onChange={(e) => {
                      if (parseFloat(e.target.value) !== NaN) {
                        let TempTotal = e.target.value;
                        setMyr_total(
                          TempTotal.replaceAll("€", "").trim()
                        );
                      }
                    }}
                    onBlur={() => {
                      if (myr_total !== NaN && myr_total > 0) {
                        if (
                          selectedTask.task_assignee &&
                          selectedTask.task_assignee.providers &&
                          selectedTask.task_assignee.providers.length
                        ) {
                          updateTaskData(
                            undefined,
                            undefined,
                            myr_total
                          );
                        } else {
                          assignToProvider(
                            providerType,
                            providerValue,
                            providerDetail
                          );
                        }
                      }
                    }}
                    ref={(reference) => {
                      totalRef = reference;
                    }}
                    disabled={
                      selectedTask
                        ? parseInt(selectedTask.is_allowed_to_edit) < 1
                        : false
                    }
                  />
                  <a href="#/"
                    type="button"
                    className="modal-close with_separator_10 pe-0 position-relaive modal-close-button z-index-5"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => {
                      hideTaskModal();
                      TaskModalClose();
                      setShowCommentModal(false);
                    }}
                    ref={closeButtonRef}
                  >
                    <XIcon className="HW16" />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
                  <CommonHeaderBody
                    data={commonHeaderBodyData}
                    type={"task"}
                    handleAction={handleCommonHeaderBodyAction}
                  />
                  {/* <div className="task-setting-section comman_action_icon p-0">
                    <div className="d-flex align-items-center flex-wrap">
                      {/* <a href="#/"
                        className="white-border-box custom-datepicker datepicker-input w-90"
                        data-bs-toggle="tooltip"
                         
                         
                         
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={calendarBlankWhite}
                            alt={"calendarWhite"}
                            className="img-width-18 "
                          />
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              updateTaskData(date);
                            }}
                            showTimeSelect
                            dateFormat="dd/MM/yyyy"
                            readOnly={
                              selectedTask
                                ? parseInt(selectedTask.is_allowed_to_edit) < 1
                                : false
                            }
                          />
                        </div>
                      </a> */}
                  {/*   <a href="#/"
                    className="white-border-box custom-datepicker datepicker-input "
                    data-bs-toggle="tooltip"
                 
                  >
                    <div className="d-flex align-items-center">
                      <CalendarBlankIcon className="HW16 " />
                      <DatePicker
                        selected={
                          selectedTask && selectedTask.schedule_id > 0
                            ? startDate
                            : dueDate
                        }
                        onChange={(date) => {
                          if (
                            selectedTask &&
                            selectedTask.schedule_id > 0
                          ) {
                            setStartDate(date);
                            updateTaskData(date, undefined);
                          } else {
                            setDueDate(date);
                            updateTaskData(undefined, date);
                          }
                        }}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy"
                        placeholderText={`${_l("l_duedate")}`}
                        className="c-font f-12"
                        readOnly={
                          selectedTask
                            ? parseInt(selectedTask.is_allowed_to_edit) < 1
                            : false
                        }
                      />
                    </div>
                  </a>
                  <div
                    className={`white-border-box color-white-60 dropend d-flex align-items-center h25w25 action_icon with_bg ${selectedTask &&
                      parseInt(selectedTask.is_allowed_to_edit) > 0
                      ? ""
                      : "for-disabled"
                      } `}
                    id="TaskListStatusDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                     
                     
                     
                     
                  >
                    <div className="d-flex">
                      {/* <span
                        type="button"
                        id="TaskListStatusDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {taskStatus == 1
                          ? "To Do"
                          : taskStatus == 4
                          ? "In Progress"
                          : "Done"}
                      </span> */}
                  {/*    <span
                    className={`c-dots c-10 ${taskStatus == 1
                      ? "in-progress-white"
                      : taskStatus == 4
                        ? "in-progress-blue"
                        : "done"
                      } rounded-circle `}
                  ></span>

                  {selectedTask &&
                    parseInt(selectedTask.is_allowed_to_edit) > 0 ? (
                    <TaskListStatusDropdown
                      key={2}
                      setTaskStatus={changeTaskStatus}
                    />
                  ) : (
                    <>
                      <TaskListStatusDropdown
                        key={2}
                        setTaskStatus={changeTaskStatus}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="white-border-box d-flex align-items-center h25w25 action_icon with_bg">
                <div
                  className="priority-checkbox text_wrap position-relative"
                  
                >
                  <input
                    onChange={(e) => {
                      changeTaskPriority(e);
                    }}
                    checked={taskPriority == 3 ? true : false}
                    type="checkbox"
                    className="absolute-input"
                    disabled={
                      selectedTask
                        ? parseInt(selectedTask.is_allowed_to_edit) < 1
                        : false
                    }
                  />
                  <div className="check_box">
                    <HighPriorityActiveIcon className="HW18 check_span_img active" />
                    <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                  </div>
                </div>
              </div>
              <div className="white-border-box d-flex align-items-center h25w25 action_icon with_bg">
                <a href="#/"
                  className=""
                 
                  onClick={handlePinTask}
                >
                  <div
                    className={`img-width-16  m-auto ${!taskPinShow ? "" : "d-none"
                      }`}
                  >
                    <PushPinIcon className="HW14" />
                  </div>
                  <div
                    className={`img-width-16  m-auto ${taskPinShow ? "" : "d-none"
                      }`}
                  >
                    <PushPinFillIcon className="HW14 green-l-black stroke-transparent" />
                  </div>
                </a>
              </div>
              <div
                className={`white-border-box color-white-60 dropdown filter-select-box h25w25 action_icon with_bg ${(selectedTask &&
                  selectedTask.is_allowed_to_edit == 1) ||
                  isCreateTask
                  ? ""
                  : "for-disabled"
                  }`}
              >
                <div
                  type="button"
                  id="ProjectListingDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  
                  className={`d-flex align-items-center justify-content-between `}
                >
                  <span
                    className="text-truncate p-0"
                    title={taskProject}
                  >
                    <div className="on-hover-active-toggle-img text-on-hover-active-green">
                      <MapPinIcon className="HW16  m-auto" />
                    </div>
                  </span>
                </div>
                {selectedTask &&
                  parseInt(selectedTask.is_allowed_to_edit) > 0 ? (
                  <ProjectListingDropdown
                    setProject={handleChangeTaskProject}
                    currentProject={selectedTask && selectedTask.rel_id}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="dropdown  d-flex">
                <div>
                  <div
                    type="button"
                    className={` d-flex align-items-center justify-content-between white-border-box h25w25 w-auto px-1 action_icon with_bg ${(selectedTask &&
                      selectedTask.is_allowed_to_edit == 1) ||
                      isCreateTask
                      ? ""
                      : "for-disabled"
                      }`}
                    data-bs-toggle="dropdown"
                    title="Recurring Task detail"
                    id="#RecurringTaskMonthDropdown"
                     
                     
                     
                     
                    data-bs-auto-close="outside"
                  >
                    <div className="on-hover-active-toggle-img text-on-hover-active-green">
                      <RepeatIcon className="HW16  m-auto" />

                      <span className="ps-0">
                        {taskRecurringData.repeat_every &&
                          taskRecurringData.recurring_type &&
                          taskRecurringData.custom_recurring != 0
                          ? `${taskRecurringData.repeat_every} ${taskRecurringData.recurring_type}`
                          : taskRecurringData.recurring != 0 &&
                            taskRecurringData.recurring_type
                            ? `${taskRecurringData.repeat_every} ${taskRecurringData.recurring_type}`
                            : ""}
                      </span>
                    </div>
                  </div>
                  {selectedTask &&
                    parseInt(selectedTask.is_allowed_to_edit) > 0 ? (
                    <RecurringTaskMonthDropdown
                      data={taskRecurringData}
                      setTaskRecurring={handleSetRecurringTask}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className={`white-border-box dropdown d-flex h25w25 action_icon with_bg ${(selectedTask &&
                  selectedTask.is_allowed_to_edit == 1) ||
                  isCreateTask
                  ? ""
                  : "for-disabled"
                  }`}
              >
                <div
                  type="button"
                  id="ProjectListingDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                   
                   
                   
                   
                  className="d-flex align-items-center justify-content-between w-100"
                  onClick={() => setOpenAssigneeDropdown(true)}
                >
                  <div className="on-hover-active-toggle-img text-on-hover-active-green">
                    <UserGearCircleIcon className=" m-auto HW16" />
                  </div>
                  {selectedTask &&
                    parseInt(selectedTask.is_allowed_to_edit) > 0 ? (
                    <AddAssigneeMainDropdown
                      projectId={projectId}
                      taskId={taskId}
                      openAssigneeDropdown={openAssigneeDropdown}
                      setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                      handleProvider={handleProvider}
                      providerDefaultType={providerDefaultType}
                      providerDefaultValue={providerDefaultValue}
                      providerDefaultDetails={providerDefaultDetails}
                      selectedTagId={selectedTagId}
                      isCreateTask={isCreateTask ? 1 : null}
                      hangleTag={(tab, is_remove = 0) => {
                        setTagId(0);
                        setTaskTags([]);
                        if (tab && is_remove == 0) {
                          setTagId(tab.id);
                          setTaskTags([tab]);
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className="multi_image_wrapper dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                 
                 
                 
                 
                onClick={handleTaskAssigneeData}
              >
                <div className="multi_inner_wrapper d-flex position-relative pe-2">
                  {selectedTask &&
                    selectedTask.task_assignee &&
                    selectedTask.task_assignee.providers ? (
                    <>
                      {selectedTask.task_assignee.providers.map(
                        (assignee, index) => {
                          if (index < 2) {
                            return (
                              <a href="#/"
                                type="button"
                                id="TaskAssigneeDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="comman-image-box h20w20 rounded-circle"
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${assignee.profile_image}')`,
                                  }}
                                ></div>
                              </a>
                            );
                          }
                        }
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {selectedTask &&
                    selectedTask.task_assignee &&
                    selectedTask.task_assignee.providers &&
                    selectedTask.task_assignee.providers.length > 2 ? (
                    <>
                      <a href="#/"
                        className="comman-image-box h20w20 with_overlay d-flex rounded-circle"
                        type="button"
                        id="TaskAssigneeDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="unread_count m-auto">{`+${selectedTask.task_assignee.providers.length -
                          2
                          }`}</div>
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                  {selectedTask &&
                    selectedTask.task_assignee &&
                    selectedTask.task_assignee.providers &&
                    selectedTask.task_assignee.providers.length ? (
                    <GeneralListing
                      list={selectedTask.task_assignee.providers.map(
                        (item, index) => {
                          return {
                            name: item.full_name || item.name,
                            image:
                              item.user_image || item.profile_image,
                            id: index,
                          };
                        }
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="multi_image_wrapper  d-flex">
                {selectedTask &&
                  selectedTask.task_assignee &&
                  selectedTask.task_assignee.equipments ? (
                  <>
                    {selectedTask.task_assignee.equipments.map(
                      (assignee, index) => {
                        if (index < 2) {
                          return (
                            <a href="#/"
                              data-bs-toggle="tooltip"
                              className="comman-image-box h25w25 radius_3"
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                                style={{
                                  backgroundImage: `url('${assignee.profile_image}')`,
                                }}
                              ></div>
                            </a>
                          );
                        }
                      }
                    )}
                  </>
                ) : (
                  <></>
                )}
                {selectedTask &&
                  selectedTask.task_assignee &&
                  selectedTask.task_assignee.equipments &&
                  selectedTask.task_assignee.equipments.length > 2 ? (
                  <a href="#/"
                    data-bs-toggle="tooltip"
                    className="comman-image-box h25w25 with_overlay d-flex rounded-circle"
                  >
                    <div className="unread_count m-auto">{`+${selectedTask.task_assignee.equipments.length - 2
                      }`}</div>
                  </a>
                ) : (
                  <></>
                )}
              </div>
              <a href="#/"
                className="white-border-box ml-10 rounded-pill add-tag me-0 h-auto d-none"
                data-bs-toggle="tooltip"
                title="add task"
              >
                <div className="d-flex align-items-center flex-wrap">
                  <div className="img-width-14 " onClick={handleAddTag}>
                    <PlusIcon />
                  </div>
                  <input
                    type="text"
                    className=" border-0 bg-transparent text_wrap"
                    placeholder={`${_l("l_add_tags")}`}
                    data-bs-toggle="tooltip"
                    value={tagValue}
                    onChange={(e) => setTagValue(e.target.value)}
                  />
                </div>
              </a>
              <Form.Check
                className={`d-flex justify-content-end flex-grow-1 pe-2 ${userType == "staff" && !virtualSpaces ? "" : "d-none"
                  }`}
                type="checkbox"
                id=""
                label="Visible to customer"
                checked={visibleToCustomer}
                onChange={(e) => {
                  setVisibleToCustomer(e.target.checked);
                  updateTaskData(
                    undefined,
                    undefined,
                    undefined,
                    e.target.checked
                  );
                }}
              />
            </div>

            <div className="added-tag-list ">
              <div className="d-flex align-items-center flex-wrap">
                {taskTags && taskTags.length ? (
                  taskTags.map((tag, index) => {
                    return (
                      <div
                        key={index}
                        className="pill-tag add-tag with_bg rounded-pill"
                        data-bs-toggle="tooltip"
                        title="abc"
                      >
                        <span className="color-white-80 c-font f-12 pe-1">
                          {tag.name}
                        </span>
                        <a href="#/"
                          className="opacity-50 d-none"
                          onClick={() => {
                            // if (selectedTask && parseInt(selectedTask.is_allowed_to_edit) == 1) {
                            //   removeTag(tag.id);
                            // }
                          }}
                        >
                          <X size={14}  weight="light" className="c-icons" />
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div> */}

                  <div className="tab-wrapper_main pb-3 res-overflow-auto">
                    <div className="d-flex align-items-center justify-content-between border-bottom">
                      <div className="comman-tab-wrapper pb-0">
                        <div className="d-flex align-items-center">
                          {(selectedTask.provider === "google_drive" || selectedTask.provider === "one_drive") &&
                          typeof selectedTask.is_synced !== "undefined" ? (
                            <>
                              <div
                                id="task_detail_head_4"
                                className={`tab-wrapper  for-accessible ${
                                  activeTab === "document" ? "active" : ""
                                } `}
                              >
                                <a href="#/"
                                  className="tab-name"
                                  onClick={() => {
                                    setActiveTab("document");
                                    // openToastClose();
                                  }}
                                >
                                  {_l("l_document")}
                                </a>
                              </div>

                              <div
                                id="task_detail_head_5"
                                className={`tab-wrapper  for-accessible ${
                                  activeTab === "basic_details" ? "active" : ""
                                } `}
                              >
                                <a href="#/"
                                  className="tab-name"
                                  onClick={() => {
                                    setActiveTab("basic_details");
                                    // openToastClose();
                                  }}
                                >
                                  {_l("l_basic_details")}
                                </a>
                              </div>

                              <div
                                id="task_detail_head_6"
                                className={`tab-wrapper  for-accessible ${
                                  activeTab === "activities" ? "active" : ""
                                } `}
                              >
                                <a href="#/"
                                  className="tab-name"
                                  onClick={() => {
                                    setActiveTab("activities");
                                    // openToastClose();
                                  }}
                                >
                                  {_l("l_activities")}
                                </a>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div
                            id="task_detail_head_1"
                            className={`tab-wrapper task-detail-tab ${
                              activeTab === "checklist" ? "active" : ""
                            } d-flex align-items-center`}
                          >
                            <a href="#/"
                              className="tab-name"
                              onClick={() => {
                                setActiveTab("checklist");
                                openToastClose();
                              }}
                            >
                              {_l("l_checklist")}
                            </a>
                          </div>
                          <div
                            id="task_detail_head_2"
                            className={`tab-wrapper task-detail-tab ${
                              activeTab === "files" ? "active" : ""
                            } d-flex align-items-center`}
                          >
                            <a href="#/"
                              className="tab-name"
                              onClick={() => {
                                setActiveTab("files");
                                openToastClose();
                              }}
                            >
                              {_l("l_files")}
                            </a>
                          </div>
                          <div
                            id="task_detail_head_3"
                            className={`tab-wrapper task-detail-tab ${
                              activeTab === "links" ? "active" : ""
                            } d-flex align-items-center`}
                          >
                            <a href="#/"
                              className="tab-name"
                              onClick={() => {
                                setActiveTab("links");
                                openToastClose();
                              }}
                            >
                              {_l("l_links")}
                            </a>
                          </div>
                          {/* <div
                            id="task_detail_head_4"
                            className={`tab-wrapper d-none task-detail-tab ${activeTab === "notes" ? "active" : ""
                              } d-flex align-items-center`}
                          >
                            <a href="#/"
                              className="tab-name"
                              onClick={() => {
                                // setActiveTab("notes");
                                // openToastClose();
                              }}
                            >
                              Notes
                            </a>
                          </div> */}

                          {selectedTask &&
                          selectedTask.is_one_time_benchmark ? (
                            <div
                              id="task_detail_head_5"
                              className={`tab-wrapper task-detail-tab ${
                                activeTab === "benchmark" ? "active" : ""
                              } d-flex align-items-center`}
                            >
                              <a href="#/"
                                className="tab-name"
                                onClick={() => {
                                  setActiveTab("benchmark");
                                }}
                              >
                                {_l("l_benchmark")}
                              </a>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {activeTab === "checklist" ? (
                        <div
                          className="switch"
                          
                        >
                          <div className="d-flex align-items-center">
                            <div className="c-font f-10 color-white-60 pe-2">
                              {/* <div className="result">
                              Above checkbox is
                              {isChecked ? "checked" : "un-checked"}.
                            </div> */}
                            </div>
                            <div className="label-text">
                              <div className="d-flex align-items-center">
                                <div className="form-check form-switch d-flex justify-content-end align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={isChecked}
                                    onChange={() => {
                                      setIsChecked(!isChecked);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {activeTab === "files" || activeTab === "links" ? (
                        <div className="d-flex commn-search radius_3 justify-content-between">
                          <div className="comment-input-box">
                            <input
                              type="text"
                              className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                              placeholder={`${_l("l_search")}`}
                              value={searchLinkAttchment}
                              onChange={(e) => {
                                setSearchLinkAttchment(e.target.value);
                              }}
                            />
                          </div>
                          <a href="#/" className="comman_action_icon">
                            <div className="d-flex align-items-center ">
                              <div className="action_icon h25w25 d-none">
                                <X
                                  size={14}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                              <div className="action_icon h25w25">
                                <MagnifyingGlass
                                  size={14}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {(selectedTask.provider === "google_drive" || selectedTask.provider === "one_drive")&&
                  typeof selectedTask.is_synced !== "undefined" ? (
                    <>
                      <div
                        id="task_detail_7"
                        className={`${
                          activeTab === "document" ? "" : "d-none"
                        } h-100`}
                      >
                        <Document data={selectedTask} />
                      </div>

                      <div
                        id="task_detail_8"
                        className={`${
                          activeTab === "basic_details" ? "" : "d-none"
                        } h-100`}
                      >
                        <div className="d-flex commn-search radius_3 justify-content-between">
                          <div className="comment-input-box">
                            <DocumentsDetail data={selectedTask} />
                          </div>
                        </div>
                      </div>

                      <div
                        id="task_detail_9"
                        className={`${
                          activeTab === "activities" ? "" : "d-none"
                        } h-100`}
                      >
                        <DocumentsActivity data={selectedTask} />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="tast-detail-tab-wrapper res-height-auto">
                    <div
                      id="task_detail_contain_1"
                      className={`${
                        activeTab === "checklist" ? "" : "d-none"
                      } h-100 overflow-hiiden-auto`}
                    >
                      {activeTab === "checklist" && (<ChecklistComponent
                        projectId={projectId}
                        task_id={taskId}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        isSystemTask={
                          selectedTask &&
                          selectedTask.addedfrom == 0 &&
                          selectedTask.schedule_id &&
                          parseInt(selectedTask.schedule_id) > 0
                            ? true
                            : null
                        }
                        toastClose={openToastClose}
                        closeAllTost={toastClose}
                        setRequestChecklist={() => {}}
                      />)}
                    </div>
                    <div
                      id="task_detail_contain_2"
                      className={`${
                        activeTab === "files" ? "" : "d-none"
                      } h-100`}
                    >
                      {activeTab === "files" && (<FileUploadComponent
                        projectId={projectId}
                        task_id={taskId}
                        attachmentCountUpdate={attachmentCountUpdate}
                        toastClose={openToastClose}
                        closeAllTost={toastClose}
                        searchAttchment={searchLinkAttchment}
                      />)}
                    </div>
                    <div
                      id="task_detail_contain_3"
                      className={`${
                        activeTab === "links" ? "" : "d-none"
                      } h-100`}
                    >
                      { activeTab === "links" ?  (<>
                        <LinkComponent
                            projectId={projectId}
                            task_id={taskId}
                            toastClose={openToastClose}
                            closeAllTost={toastClose}
                            searchLink={searchLinkAttchment}
                          />
                      </>)
                         : <></>
                      }
                    </div>
                    <div
                      id="task_detail_contain_4"
                      className={`for-disabled ${
                        activeTab === "notes" ? "" : "d-none"
                      } h-100`}
                    >
                      { activeTab === "notes" ?  (<>
                        <NotesComponent
                          projectId={projectId}
                          task_id={taskId}
                          toastClose={openToastClose}
                          closeAllTost={toastClose}
                        />
                      </>)
                         : <></>
                      }
                      
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-end align-items-end ps-3 w-100 m-auto">
                    {ShowCommentModal ? (
                      <></>
                    ) : (
                      <div className="chat-main-box with-bg flex-grow-1 me-0 w100minus40 overflow-hidden ">
                        <div className="chat-comment-box">
                          <div className="comment-input-box">
                            <div className="d-flex align-items-center position-relative">
                              <div
                                className="h25w25 comman-image-box rounded-circle"
                                data-bs-toggle="tooltip"
                                title={localStorage.getItem("full_name")}
                                aria-expanded="false"
                                 
                                 
                                 
                                 
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${contact_image}')`,
                                  }}
                                ></div>
                              </div>

                              <MentionsInput
                                value={commentContent}
                                className="mension-box flex-grow-1"
                                placeholder={`${_l("l_write_comment")}`}
                                onChange={(e) => {
                                  setCommentContent(e.target.value);
                                }}
                                onKeyUp={(e) => {
                                  if (e.keyCode === 13) {
                                    addNewComment();
                                  }
                                }}
                                id="commentInput"
                              >
                                <Mention
                                  markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                                  trigger="@"
                                  data={mentionList}
                                  renderSuggestion={(
                                    suggestion,
                                    search,
                                    highlightedDisplay
                                  ) => {
                                    return (
                                      <div className="user d-flex align-items-center">
                                        <div className="comman-image-box h25w25 rounded-circle mx-2">
                                          <div
                                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                            style={{
                                              backgroundImage: `url('${suggestion.image}')`,
                                            }}
                                          ></div>
                                        </div>

                                        {highlightedDisplay}
                                      </div>
                                    );
                                  }}
                                />
                              </MentionsInput>
                              <React.Fragment>
                                {imagePreviews.length ? (
                                  <div className="upload-image-preview ">
                                    <div className="d-flex align-items-center justify-content-start">
                                      {imagePreviews.map((preview, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="comman-image-box h25w25 radius_3 upload-image with-margin"
                                          >
                                            <div
                                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                              style={{
                                                backgroundImage: `url(
                                            '${preview.url}'
                                          )`,
                                              }}
                                            ></div>
                                            <a href="#/"
                                              className="upload-close"
                                              onClick={() => {
                                                removeImageSelection(
                                                  preview.name
                                                );
                                              }}
                                            >
                                              <X size={10}  weight="light" className="c-icons" />
                                            </a>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </React.Fragment>
                              <div className="d-flex align-items-center me-4 pe-1">
                                <div className="comman_action_icon pe-2 ps-0">
                                  <div className="d-flex align-items-center">
                                    <a href="#/"
                                      className="action_icon white-border-box opacity-60"
                                      aria-expanded="false"
                                       
                                       
                                       
                                       
                                    >
                                      <AtIcon className="HW18" />
                                    </a>
                                  </div>
                                </div>
                                <div className="comman_action_icon  pe-2 ps-1">
                                  <div className="d-flex align-items-center position-relative">
                                    <a href="#/"
                                      className="action_icon with_bg"
                                      aria-expanded="false"
                                       
                                       
                                       
                                       
                                    >
                                      <input
                                        type="file"
                                        className="absolute-input"
                                        multiple
                                        accept="image/*"
                                        onChange={(event) => {
                                          if (event.target.files.length > 5) {
                                            setToastClass("unsucess");
                                            setToastHeader(_l("l_error"));
                                            setToastMessage(
                                              _l(
                                                "l_maximum_5_files_are_allowed_at_a_time"
                                              )
                                            );
                                            setShowToast(true);
                                            return;
                                          }
                                          setImageBuffer(event.target.files);
                                          preparePreview(event.target.files);
                                          // CustomerServices.addLinkComment(
                                          //   taskId,
                                          //   projectId,
                                          //   "",
                                          //   undefined,
                                          //   undefined,
                                          //   0,
                                          //   0,
                                          //   event.target.files[0]
                                          // );
                                        }}
                                        onClick={(e) => {
                                          e.target.value = "";
                                        }}
                                      />
                                      <PaperclipIcon className="HW18" />
                                    </a>
                                  </div>
                                </div>
                                <div className="comman_action_icon  pe-2 ps-1 d-none">
                                  <div className="d-flex align-items-center">
                                    <a href="#/"
                                      className="action_icon with_bg"
                                      aria-expanded="false"
                                       
                                       
                                       
                                       
                                    >
                                      <HashIcon className="HW18" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <a href="#/"
                                className="send-arrow-btn with_position_right_zero comman_action_icon"
                                onClick={addNewComment}
                              >
                                <div className="action_icon with_bg">
                                  <ArrowRightIcon className="HW18 lt-black" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={`comman_action_icon paper-clip-file ps-3 h-100 `}
                    >
                      <a href="#/"
                        className="action_icon with_bg position-relative w55 h46"
                        onClick={() => {
                          setShowCommentModal(!ShowCommentModal);
                          TaskCommentModalOpen();
                          getTaskComments();
                        }}
                      >
                        {unreadCommentCount > 0 ? (
                          <span
                            className=" rounded-pill badge-custom d-flex"
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              height: "20px",
                              width: "20px",
                              padding: "0px",
                            }}
                          >
                            6
                          </span>
                        ) : (
                          <></>
                        )}

                        <div className="d-flex align-items-center w-100 h-100">
                          <ChatCircleIcon className="HW18 ms-2 me-0" />
                          <span className="c-font f-12 title-fonts fw-semibold">
                            {commentCount}{" "}
                          </span>
                        </div>
                      </a>
                    </div>
                  </div> */}

                  <CommonFooter
                    data={{
                      comment: commentContent,
                      preview: imagePreviews,
                      unreadCommentCount: unreadCommentCount,
                    }}
                    mentionList={mentionList}
                    count={commentCount}
                    type={"task"}
                    handleAction={handleCommonFooter}
                    isAllowToEdit={
                      selectedTask && selectedTask.is_allowed_to_edit == 1
                    }
                  />
                  <div className="d-flex justify-content-end">
                    {(userRole == 1 || userRole == 2) &&
                    selectedTask &&
                    selectedTask.is_requested == 1 ? (
                      <button
                        className="btn btn-primary btn-sm z-index-5"
                        onClick={() => {
                          convertTask(selectedTask.id);
                        }}
                      >
                        {_l("l_convert_to_task")}
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                   
                </div>
                {ShowCommentModal ? (
                  <RightSideComments
                    list={commentList}
                    handleDelete={deleteComment}
                    handleEditComment={handleEditComment}
                    convertedTaskId={0}
                    imagePreviews={imagePreviews}
                    removeImageSelection={removeImageSelection}
                    addNewComment={addNewComment}
                    mentionList={mentionList}
                    isAllowToEdit={
                      selectedTask && selectedTask.is_allowed_to_edit != 1
                    }
                    imageBuffer={imageBuffer}
                    showSearch={showSearch}
                    setImageBuffer={setImageBuffer}
                    preparePreview={preparePreview}
                    commentContent={commentContent}
                    setCommentContent={setCommentContent}
                    getMentionData={getMentionData}
                    setShowCommentModal={setShowCommentModal}
                    ShowCommentModal={ShowCommentModal}
                  />
                ) : (
                  <></>
                )}
                <div
                  className={`task-detail-right-main h-100 flex-column res-width-100 task-comment-modal ${
                    ShowCommentModal ? "show-comments" : ""
                  }`}
                >
                  <div className="right-modal-task-header border-bottom  p-15 d-none">
                    <div className="d-flex align-items-center">
                      <div className="right-task-header with_separator_10 flex-grow-1 ps-0">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="task-header-left-options">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="title-fonts fw-semibold">
                                {_l("l_comments")}
                              </div>
                              <div className="multi_image_wrapper  ps-0 d-none">
                                <div className="multi_inner_wrapper d-flex">
                                  <a href="#/"
                                    data-bs-toggle="tooltip"
                                    className="comman-image-box h25w25 rounded-circle"
                                  >
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('https://source.unsplash.com/random')`,
                                      }}
                                    ></div>
                                  </a>
                                  <a href="#/" className="comman-image-box h25w25 rounded-circle">
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('https://source.unsplash.com/random')`,
                                      }}
                                    ></div>
                                  </a>
                                  <a href="#/" className="comman-image-box h25w25 with_overlay d-flex rounded-circle">
                                    <div
                                      className="unread_count m-auto"
                                      type="button"
                                      id="TaskDetailFollowersDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                       
                                       
                                       
                                       
                                    >
                                      +5
                                    </div>

                                  </a>
                                </div>
                              </div>
                              <div className="dropend d-none">
                                <a href="#/"
                                  className="comman_action_icon with_separator_10 before-dnone"
                                  type="button"
                                  id="FolloWersDropdown"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside"
                                  aria-expanded="false"
                                >
                                  <div className="action_icon with_bg">
                                    <UserPlus
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </div>
                                </a>
                                <FollowersDropdown key={1} />
                              </div>
                            </div>
                          </div>
                          <div className="task-header-right-option">
                            <div className="d-flex align-items-center justify-content-between">
                              <a href="#/" className="action_icon d-none">
                                <MagnifyingGlass
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                              </a>
                              <a href="#/"
                                className="action_icon ml-10 d-none"
                                onClick={() => {
                                  setShow(true);
                                }}
                              >
                                <ShareNetwork
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                              </a>
                              {showFullscreen ? (
                                <a href="#/"
                                  className="action_icon ml-10 d-none"
                                  onClick={() => setShowFullscreen(false)}
                                  // id="modal_zoom_in"
                                   
                                   
                                   
                                   
                                >
                                  <CornersIn
                                    size={18}
                                    weight="light"
                                    className="c-icons zoom_in"
                                  />
                                </a>
                              ) : (
                                <a href="#/"
                                  className="action_icon ml-10 d-none"
                                  onClick={() => setShowFullscreen(true)}
                                  // id="modal_zoom_out"
                                   
                                   
                                   
                                   
                                >
                                  <CornersOut
                                    size={18}
                                    weight="light"
                                    className="c-icons zoom_out"
                                  />
                                </a>
                              )}
                              <a href="#/"
                                className="btn btn-white-10 btn-sm line-height-1 action_icon ml-10 d-none"
                                onClick={() => {
                                  setShow(true);
                                }}
                              >
                                {_l("l_follow")}
                              </a>
                              <a href="#/" className="action_icon ml-10 d-none">
                                <ShareNetwork
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="#/"
                        type="button"
                        className="modal-close with_separator_10 pe-0 position-relaive  z-index-5"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => {
                          CloseCommentModal();
                          setShowCommentModal(false);
                        }}
                        ref={closeButtonRef}
                      >
                        <X size={18} weight="light" className="c-icons" />
                      </a>
                    </div>
                  </div>
                  <div className="comman-tab-wrapper pb-0">
                    <div className="d-flex align-items-center w-100">
                      <div
                        className={`tab-wrapper ${
                          commentTab ? "active" : ""
                        } d-flex align-items-center justify-content-center flex-grow-1 d-none`}
                      >
                        <a href="#/"
                          className="tab-name"
                          onClick={() => {
                            setCommentTab(true);
                            getTaskComments(0);
                          }}
                        >
                          {_l("l_comments")}
                        </a>
                        <a href="#/" className="info-icon">
                          <Info size={16} weight="light" className="c-icons" />
                        </a>
                      </div>
                      {userRole == 1 || userRole == 2 ? (
                        <div
                          className={`tab-wrapper ${
                            !commentTab ? "active" : ""
                          }  d-flex align-items-center justify-content-center flex-grow-1 d-none`}
                        >
                          <a href="#/"
                            className="tab-name"
                            onClick={() => {
                              setCommentTab(false);
                              getTaskComments(1);
                            }}
                          >
                            {_l("l_internal_rating")}
                          </a>
                          <a href="#/" className="info-icon">
                            <Info
                              size={16}
                              weight="light"
                              className="c-icons opacity-50"
                            />
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="right-chat-wrapper position-relative d-flex flex-column">
                    {showSearch ? (
                      <div className="search-with-close">
                        <div className="d-flex align-items-center ">
                          <div className="comment-input-box  pb-0 radius_3  w-100">
                            <input
                              className=" border-0 c-font f-12 form-control p-2 radius_3"
                              placeholder={`${_l("l_search")}`}
                              value={searchString}
                              onChange={(e) => handleSearch(e)}
                            />
                          </div>
                          <a href="#/" className="bg-white-03 border-left d-flex h35w35 line-height-0">
                            <div
                              className="img-width-18 m-auto"
                              onClick={() => {
                                setshowSearch(false);
                                setSearchString("");
                              }}
                            >
                              <X size={16} weight="light" className="c-icons" />
                            </div>
                          </a>
                        </div>
                        <div className="pill-wrapper d-none">
                          <a href="#/"
                            className="comman_action_icon filter-list-view d-none"
                            id="filter-show-list"
                          >
                            <div className="action_icon with_bg_03 h35w35">
                              <div
                                className="img-width-18 m-auto"
                                onClick={() => {
                                  setshowSearch(false);
                                  setSearchString("");
                                }}
                              >
                                <X
                                  size={16}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                            </div>
                          </a>
                          <div
                            id="show-filter-list"
                            className="filter-list-toggle"
                          >
                            <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                              <div className="d-flex radius_3 commn-search w-100 justify-content-between">
                                <div className="comment-input-box">
                                  <input
                                    type="text"
                                    id="task-search-string"
                                    className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                                    placeholder={`${_l("l_search")}`}
                                    data-bs-toggle="tooltip"
                                  />
                                </div>
                                <a href="#/" className="comman_action_icon">
                                  <div className="d-flex align-items-center ">
                                    <div className="action_icon h25w25">
                                      <MagnifyingGlass
                                        size={14}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="chat-main-box flex-grow-1 comman_vertical_btn_h">
                      <div className="d-flex flex-column h-100">
                        <div className="c-font f-12 py-1 px-2 title-fonts color-white-60 border-bottom d-none">
                          {_l("l_comments")}
                        </div>
                        <div className="chat-wrapper" id="comment-list">
                          {commentList && commentList.length ? (
                            commentList.map((comment, index) => {
                              const commentContent = comment.content;
                              const dummyDiv = document.createElement("div");
                              dummyDiv.innerHTML = commentContent;
                              return (
                                <div
                                  key={index}
                                  className={`chat-box 
                                    ${
                                      (userType == "contact" &&
                                        contact_id === comment.contact_id) ||
                                      (userType == "staff" &&
                                        staff_id == comment.staffid)
                                        ? "right-side-msg"
                                        : ""
                                    }`}
                                >
                                  <div className="d-flex">
                                    <div
                                      className="h30w30 comman-image-box with-bg rounded-circle bg-white-05"
                                      data-bs-toggle="tooltip"
                                      // title={comment.contact_full_name}
                                      aria-expanded="false"
                                      
                                    >
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('${comment.addedfrom_details.profile_url}')`,
                                        }}
                                      ></div>
                                    </div>
                                     
                                    <div className="chat-msg">
                                      <div className="chat-text-box">
                                        <div className="image-upload-wrapper">
                                          {comment.attachments &&
                                          comment.attachments.length ? (
                                            <div className="d-flex align-items-center flex-wrap ">
                                              {comment.attachments.map(
                                                (image, index) => {
                                                  if (index < 3) {
                                                    return (
                                                      <a href="#/"
                                                        key={index}
                                                        className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 me-2 mb-2"
                                                        onClick={() => {
                                                          setOpenGallery(true);
                                                          setGalleryIndex(
                                                            index
                                                          );
                                                          setPreviewSrc(
                                                            comment.attachments.map(
                                                              (src) => {
                                                                return {
                                                                  src: src.file_name,
                                                                };
                                                              }
                                                            )
                                                          );
                                                        }}
                                                      >
                                                        <div
                                                          className="comman-bg-img h-100 w-100 bg-style-cover"
                                                          style={{
                                                            backgroundImage: `url('${image.file_name}')`,
                                                          }}
                                                        ></div>
                                                      </a>
                                                    );
                                                  } else if (index === 3) {
                                                    return (
                                                      <a href="#/"
                                                        className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3  mb-2 with_overlay"
                                                        onClick={() => {
                                                          setOpenGallery(true);
                                                          setGalleryIndex(
                                                            index
                                                          );
                                                          setPreviewSrc(
                                                            comment.attachments.map(
                                                              (src) => {
                                                                return {
                                                                  src: src.file_name,
                                                                };
                                                              }
                                                            )
                                                          );
                                                        }}
                                                      >
                                                        <div class="unread_count m-auto">
                                                          +
                                                          {comment.attachments
                                                            .length - 3}
                                                        </div>
                                                      </a>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        {/* {
                                        comment.attachments && comment.attachments.length ? <ImageGallery showPlayButton={false} showThumbnails={false} items={comment.attachments.map((image) => {
                                          return {
                                            original: image.file_name,
                                            thumbnail: image.file_name,
                                          }
                                        })} /> : <></>
                                      } */}
                                        <div className="chat-text">
                                          {/* {dummyDiv.innerText} */}
                                          {ReactHtmlParser(comment.content)}
                                          {comment.content.match(
                                            "data-mention-id"
                                          ) && (
                                            <div className="radius_3 mension-detail-box p-1  radius_3 c-font f-12 title-fonts">
                                              <div className="mension-detail-wrapper p-1">
                                                <div className="d-flex align-items-center">
                                                  <div className="h30w30 comman-round-box with-bg d-flex align-items-center">
                                                    <div
                                                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                      style={{
                                                        backgroundImage: `url('https://source.unsplash.com/random')`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                  <div className="mension-details color-white ps-2 text-truncate">
                                                    <div className="text-truncate">
                                                      John Smith
                                                    </div>
                                                    <div className="color-white-60">
                                                      Manager
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div
                                          className={`dropdown dropdown-center chat-action-option ${
                                            comment.is_allowed_to_edit == "0"
                                              ? "d-none"
                                              : ""
                                          }`}
                                        >
                                          <a href="#/"
                                            className="line-height-0 "
                                            type="button"
                                            id="commentOptionDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                             
                                             
                                             
                                             
                                          >
                                            <DotsThreeVertical
                                              size={18}
                                              weight="light"
                                              className="c-icons"
                                            />
                                          </a>
                                          <ActionDropdown
                                            key={index}
                                            actions={[
                                              {
                                                actionName: _l("l_edit"),
                                                actionHandler: () => {
                                                  handleEditComment(comment.id);
                                                },
                                                actionIcon:
                                                  "PencilSimpleLineIcon",
                                              },
                                              {
                                                actionName: _l("l_delete"),
                                                actionHandler: () => {
                                                  openToastClose();
                                                  setToastHeader(
                                                    _l("l_are_you_sure")
                                                  );
                                                  setToastClass(
                                                    "toast-with-btn"
                                                  );
                                                  setToastMessage(
                                                    _l(
                                                      "l_you_want_to_delete_this_comment"
                                                    )
                                                  );
                                                  setConfirmButtonText(
                                                    _l("l_delete")
                                                  );
                                                  dispatch(
                                                    IsToastOpenOrClose(true)
                                                  );
                                                  setCancelButtonText(
                                                    _l("l_cancel")
                                                  );
                                                  setShowToast(true);
                                                  setTempCommentId(comment.id);
                                                  setToastClose(!toastClose);
                                                },
                                                actionIcon: "TrashIcon",
                                              },
                                            ]}
                                          />
                                          {/* <DeleteDropdown
                                          handleEdit={() => {
                                            handleEditComment(comment.id);
                                          }}
                                          handleDelete={() => {
                                            deleteComment(comment.id);
                                          }}
                                          edit={true}
                                          pending={!commentTab}
                                          pendingAction={handlePendingComment}
                                          itemId={comment.id}
                                        /> */}
                                        </div>
                                      </div>
                                      <div className="chat-send-name c-font f-10 opacity-50">
                                        {/* <span>{comment.contact_full_name}</span> */}
                                        <span>{comment.time}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <>
                              <CommanPlaceholder imgType="no-comment" placeholderText = {_l("l_no_comments")} />
                            </>
                          )}
                        </div>
                        {commentTab ? (
                          <React.Fragment>
                            {imagePreviews.length ? (
                              <div className="upload-image-preview">
                                <div className="d-flex align-items-center justify-content-start">
                                  {imagePreviews.map((preview, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="comman-image-box h40w40 radius_3 upload-image with-margin"
                                      >
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                          style={{
                                            backgroundImage: `url(
                                      '${preview.url}'
                                    )`,
                                          }}
                                        ></div>
                                        <a href="#/"
                                          className="upload-close"
                                          onClick={() => {
                                            removeImageSelection(preview.name);
                                          }}
                                        >
                                          <X
                                            size={10}
                                            weight="light"
                                            className="c-icons"
                                          />
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="chat-comment-box">
                              <div className="comment-input-box border-bottom">
                                <div className="d-flex align-items-center position-relative">
                                  <div
                                    className="h30w30 comman-image-box rounded-circle"
                                    data-bs-toggle="tooltip"
                                    title={localStorage.getItem("full_name")}
                                    aria-expanded="false"
                                     
                                     
                                     
                                     
                                  >
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('${contact_image}')`,
                                      }}
                                    ></div>
                                  </div>

                                  <MentionsInput
                                    value={commentContent}
                                    className="mension-box"
                                    placeholder={`${_l(
                                      "l_write_comment_placeholder"
                                    )}`}
                                    onChange={(e) => {
                                      setCommentContent(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                                        e.preventDefault();
                                        addNewComment();
                                      }
                                    }}
                                    id="commentInput"
                                  >
                                    <Mention
                                      markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                                      trigger="@"
                                      data={mentionList}
                                      renderSuggestion={(
                                        suggestion,
                                        search,
                                        highlightedDisplay
                                      ) => {
                                        return (
                                          <div className="user d-flex align-items-center">
                                            <div className="comman-image-box h25w25 rounded-circle mx-2">
                                              <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                style={{
                                                  backgroundImage: `url('${suggestion.image}')`,
                                                }}
                                              ></div>
                                            </div>

                                            {highlightedDisplay}
                                          </div>
                                        );
                                      }}
                                    />
                                  </MentionsInput>

                                  <a href="#/"
                                    className="send-arrow-btn with_position_right_zero"
                                    onClick={addNewComment}
                                  >
                                    <ArrowRight
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className="comment-bottom-option">
                                <div className="d-flex align-items-center">
                                  <div className="comman_action_icon with_separator_10 ps-0">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                         
                                         
                                         
                                         
                                      >
                                        <At
                                          size={18}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="comman_action_icon with_separator_10">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                         
                                         
                                         
                                         
                                      >
                                        <input
                                          type="file"
                                          className="absolute-input"
                                          multiple
                                          accept="image/*"
                                          onChange={(event) => {
                                            if (event.target.files.length > 5) {
                                              setToastClass("unsucess");
                                              setToastHeader(_l("l_error"));
                                              setToastMessage(
                                                _l(
                                                  "l_maximum_5_files_are_allowed_at_a_time"
                                                )
                                              );
                                              setShowToast(true);
                                              return;
                                            }
                                            setImageBuffer(event.target.files);
                                            preparePreview(event.target.files);
                                            // CustomerServices.addLinkComment(
                                            //   taskId,
                                            //   projectId,
                                            //   "",
                                            //   undefined,
                                            //   undefined,
                                            //   0,
                                            //   0,
                                            //   event.target.files[0]
                                            // );
                                          }}
                                          onClick={(e) => {
                                            e.target.value = "";
                                          }}
                                        />
                                        <Paperclip
                                          size={18}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="comman_action_icon with_separator_10 d-none">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                         
                                         
                                         
                                         
                                      >
                                        <Hash
                                          size={18}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </a>
                                    </div>
                                  </div>

                                  <div className="comman_action_icon with_separator_10">
                                    <div className="d-flex align-items-center">
                                      <a href="#/"
                                        className="action_icon with_bg"
                                        aria-expanded="false"
                                         
                                         
                                         
                                         
                                        onChange={(e) => {
                                          setSearchString(e.target.value);
                                        }}
                                        onClick={() => {
                                          setshowSearch(!showSearch);
                                        }}
                                      >
                                        {showSearch ? (
                                          <div
                                            className="img-width-18 m-auto"
                                            onClick={() => setSearchString("")}
                                          >
                                            <X
                                              size={16}
                                              weight="light"
                                              className="c-icons"
                                            />
                                          </div>
                                        ) : (
                                          <MagnifyingGlass
                                            size={18}
                                            weight="light"
                                            className="c-icons"
                                          />
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" overflow-auto">
                              {
                                // showData == 1 ? (
                                //   <div className="mension-box-chet-box w-100 p-0">
                                //     {mentionList.map((e) => {
                                //       const hideMentionData = () => {
                                //         const tempString = "@"+e.display
                                //         setCommentContent(tempString);
                                //         getMentionData();
                                //       };
                                //       return (
                                //         <div className="mension-box ">
                                //           <div className="mension-box__suggestions">
                                //             <div className="mension-box__suggestions__list">
                                //               <li
                                //                 className="user d-flex align-items-center"
                                //                 onClick={hideMentionData}
                                //               >
                                //                 <div className="comman-image-box h25w25 rounded-circle mx-2">
                                //                   <div
                                //                     className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                //                     style={{
                                //                       backgroundImage: `url('${e.image}')`,
                                //                     }}
                                //                   ></div>
                                //                 </div>
                                //                 {e.display}
                                //               </li>
                                //             </div>
                                //           </div>
                                //         </div>
                                //         // <div>
                                //         //   <p
                                //         //     className="my-0 py-2"
                                //         //     onClick={hideMentionData}
                                //         //   >
                                //         //     {e.display}
                                //         //   </p>
                                //         // </div>
                                //       );
                                //     })}
                                //   </div>
                                // ) : (
                                //   <></>
                                // )
                              }
                            </div>
                          </React.Fragment>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {show ? (
        <TaskShareLinkModal
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      ) : (
        <></>
      )}
      
    </React.Fragment>
  );
};

export default TaskDetail;

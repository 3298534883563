import React from 'react';
 

function DeskIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15156_255278)">
<path d="M7 11.5H2C1.73478 11.5 1.48043 11.3946 1.29289 11.2071C1.10536 11.0196 1 10.7652 1 10.5V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7 13.5H4" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 4.5H11" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 6.5H11" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.5 2.5H9.5C9.22386 2.5 9 2.72386 9 3V13C9 13.2761 9.22386 13.5 9.5 13.5H14.5C14.7761 13.5 15 13.2761 15 13V3C15 2.72386 14.7761 2.5 14.5 2.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.5 11.5V13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 12C12.4142 12 12.75 11.6642 12.75 11.25C12.75 10.8358 12.4142 10.5 12 10.5C11.5858 10.5 11.25 10.8358 11.25 11.25C11.25 11.6642 11.5858 12 12 12Z"/>
</g>
<defs>
<clipPath id="clip0_15156_255278">
<rect width="16" height="16"/>
</clipPath>
</defs>
</svg>



		</React.Fragment>
	)
}

export default DeskIcon
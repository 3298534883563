import React, { useEffect, useState } from "react";
import 'intro.js/introjs.css';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { _l } from "../../hooks/utilities";
import { Buildings, UsersThree, UserCircleGear, Wrench , MapPin } from "phosphor-react";
import properties from "../../assets/images/properties.svg";
import Tenants from "../../assets/images/Tenants.svg";
import Operators from "../../assets/images/Operators.svg";
import Equipment from "../../assets/images/Equipment.svg";
 
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { UncontrolledDropdown, DropdownToggle} from "reactstrap";
let initialFormat = {
    Properties: {
        total_area: 0,
        Properties: 0
    },
    Operators: {
        operator_count: 0,
        Operators: []
    },
    Tenants: {
        employee_count: 0,
        Tenants: []
    },
    Equipment: {
        equipments_count: 0,
        Equipment: []

    }
}
const MapMainCardList = ({ dashBoardDetails }) => {
    const navigate = useNavigate();
    const [dashboardDetails, setDashboardDetails] = useState(initialFormat);
    useEffect(() => {
        if (dashBoardDetails) {
            setDashboardDetails({
                Properties: {
                    total_area: dashBoardDetails.property_count ? dashBoardDetails.property_count : 0,
                    Properties: dashBoardDetails.total_area ? dashBoardDetails.total_area : 0,
                },
                Operators: {
                    operator_count: dashBoardDetails.operator_count ? dashBoardDetails.operator_count : 0,
                    Operators: dashBoardDetails.operators ? dashBoardDetails.operators : [],
                },
                Tenants: {
                    employee_count: dashBoardDetails.employee_count ? dashBoardDetails.employee_count : 0,
                    Tenants: dashBoardDetails.employees ? dashBoardDetails.employees : [],
                },
                Equipment: {
                    equipments_count: dashBoardDetails.equipments_count ? dashBoardDetails.equipments_count : 0,
                    Equipment: dashBoardDetails.equipments ? dashBoardDetails.equipments : [],

                }
            })
        }
    }, [dashBoardDetails])



    return (
        <>
            {dashboardDetails && Object.keys(dashboardDetails).length > 0 && Object.keys(dashboardDetails).map((key) => {
let keyText = key == 'Properties' ? _l("l_properties") : key == 'Tenants' ? _l("l_tenants")  : key == 'Operators' ? _l("l_operator") : key == 'Equipment' ? _l("l_equipment") : ''
                return (
                    <div className="bg-white-03 radius_5 card-list p-20 with-height-calc d-flex flex-column flex-grow-1">
                        <div className="d-flex card-list-top justify-content-between align-items-center">
                            <div className="d-flex align-imtes-center justify-content-between">
                                <div className="comman_action_icon me-3">
                                    <div className="h40w40  with-bg d-flex bg-base-header-color p-5px box-shadow-2 on-hover-active-toggle-img radius_3">
                                        {
                                            key == 'Properties' ? <Buildings size={24} weight="light" className="c-icons" /> : key == 'Tenants' ? <UsersThree size={24} weight="light" className="c-icons color-blue" /> : key == 'Operators' ? <UserCircleGear size={24} weight="light" className="c-icons warning" /> : key == 'Equipment' ? <Wrench size={24} weight="light" className="c-icons color-green" /> : ''
                                        }

                                    </div>
                                </div>
                                <div className="card-list-heading text-truncate">
                                    <div className={`title-fonts c-font f-16  text-truncate ${key == 'Properties' ? "color-white-80" : key == 'Tenants' ? "color-blue" : key == 'Operators' ? "text-warning" : key == 'Equipment' ? "color-green" : ''}
                                    `} >{keyText}</div>
                                    <div className="c-font color-white-60 f-12 text-truncate">
                                        <span className="hover-span">{_l("l_today")}</span>
                                    </div>
                                </div>
                            </div>
                            { key == 'Properties' ?  (<a href="#/" className="comman_action_icon d-none"  onClick={() => {
                          navigate("/fullpagemap");
                        }}>  <div className="h40w40  bg-white-10 d-flex  p-5px box-shadow-2 on-hover-active-toggle-img radius_3">
                        <MapPin size={24} color="#00ff6a" weight="light" className="c-icons" />
                        </div>
                    </a>)
                     : <></> }
                                       
                                        
                               
                        </div>
                        <div className="d-flex justify-content-between card-list-bottom mt-auto">
                            <div className="mt-auto card-list-bottom-left w100minus100per flex-grow-1">
                                <div className="left-team-image-wrapper">
                                    <div className="title-fonts c-font f-12  text-truncate pb-2">{key == 'Properties' ? _l("l_sq_meter") : ''}</div>
                                    <div className="multi_image_wrapper">
                                        <div className="multi_inner_wrapper d-flex align-items-center">
                                            {
                                                key == 'Properties' ? dashboardDetails[key][key] : <></>
                                            }
                                            {
                                                key != 'Properties' && dashboardDetails[key] && dashboardDetails[key][key] && dashboardDetails[key][key].length ?
                                                    dashboardDetails[key][key].map((employee, index) => {
                                                        if (index < 2) {
                                                            return (
                                                                <a href="#/"
                                                                    data-bs-toggle="tooltip"
                                                                    
                                                                     
                                                                     
                                                                    data-className="tooltip-main"
                                                                    className="comman-image-box h25w25 rounded-circle"
                                                                >
                                                                    <div
                                                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                                        style={{ backgroundImage: `url('${employee.image}')` }}
                                                                    ></div>
                                                                     
                                                                </a>
                                                            )
                                                        }
                                                    })
                                                    : <></>
                                            }

                                            {

                                                key != 'Properties' && dashboardDetails[key] && dashboardDetails[key][key] && dashboardDetails[key][key].length > 2 ?
                                                    <>
                                                       
                                                        <UncontrolledDropdown className="comman-image-box h25w25 with_overlay d-flex rounded-circle dropup dropdown-center team-list-dropdown">
                                <DropdownToggle  data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                                                            <a href="#/"
                                                                className="unread_count m-auto title-fonts c-font f-12 fw-semibold text-nowrap line-height-0"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false"
                                                                 
                                                                 
                                                                 
                                                            >
                                                                {(parseInt(dashboardDetails[key][key].length) - 2) > 99 ? '99+' : `+${parseInt(dashboardDetails[key][key].length) - 2}`}
                                                            </a>
                                                             
                                                            <GeneralListing unControlList={true} 
                                                                handler={() => { }}
                                                                list={dashboardDetails[key] && dashboardDetails[key][key] && dashboardDetails[key][key].length && dashboardDetails[key][key].map((item, index) => {
                                                                    return {
                                                                        name: item.full_name || item.name,
                                                                        image:
                                                                            item.image || item.image,
                                                                        id: item.id,
                                                                    };
                                                                })}
                                                            />
                                                            </DropdownToggle>
                                  </UncontrolledDropdown>
                                                       
                                                    </>
                                                    : <></>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-auto text-end card-list-bottom-right">
                                <div className="title-fonts c-font f-30 fw-semibold text-truncate">{
                                    key == 'Properties' ? dashboardDetails[key].total_area ? dashboardDetails[key].total_area : 0 : key == 'Tenants' ? dashboardDetails[key].employee_count ? dashboardDetails[key].employee_count : 0 : key == 'Operators' ? dashboardDetails[key].operator_count ? dashboardDetails[key].operator_count : 0 : key == 'Equipment' ? dashboardDetails[key].equipments_count ? dashboardDetails[key].equipments_count : 0 : ''
                                }</div>
                                <div className="c-font color-white-60 f-14 text-truncate">{_l("l_total")} {keyText}</div>
                            </div>
                        </div>
                        <div className="bg-img-bottom-right" style={{
                            backgroundImage: `url('${key == 'Properties' ? properties : key == 'Tenants' ? Tenants : key == 'Operators' ? Operators : key == 'Equipment' ? Equipment : ''
                                }')`
                        }}></div>
                    </div>
                )
            })}

        </>
    );
};
export default MapMainCardList;
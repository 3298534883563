import React, { useEffect, useState } from "react";
import { HideDropdown, _l } from "../../hooks/utilities";
import { Trash, PushPin , NotePencil, CaretRight, VideoCamera, Info, Repeat, PhoneCall, ArrowBendUpLeft, EyeSlash} from "phosphor-react";
import AddTaskIcon from "../../assets/icons/AddTaskIcon";
import RequestTaskIcon from "../../assets/icons/RequestTaskIcon";
import { useDispatch} from "react-redux";
import ShareNetworkIcon from "../../assets/icons/ShareNetworkIcon";
import TaskShareLinkModal from "../Modals/TaskShareLinkModal";
import ActionDropdown from "./ActionDropdown";
import { showMessage } from "../../actions/messages";
import CopyIcon from "../../assets/icons/CopyIcon";
import AddAssigneeMainDropdown from "../TaskModalComponents/AddAssigneeMainDropdown";
 
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import jquery from "jquery";
import { openChatAiInput } from "../../actions/customer";
import AddAssigneeMainDropdownNew from "../TaskModalComponents/AddAssigneeMainDropdownNew";

const DeleteDropdown = ({
  edit,
  handlePin,
  handleDelete,
  handleEdit,
  isTaskPinned,
  deleteOnly,
  pending,
  pendingAction,
  itemId,
  newEditId,
  widthClass = "",
  forCreateTask = false,
  createTask,
  taskLoader = false,
  task = {},
  handelGoogleMeet,
  docType = "",
  pageName = '',
  withSubDropdown = false,
  displayCopyBtn = false,
  textToCopy = "",
  contacts = [],
  isViewTask = false,
  viewTask = false,
  setShowConvertDropdown,
  setConvertMessageId,
  initReply = false,
  isOwnMsg = false,
  allowToDelete = true,
  allowToCall = false,
  allowToShare = false,
  makeCall,
  setConvertTaskId,
  taskUserAssignees = [],
  callListPos = "",
  isfromChatPage = false,
  setIsfromChatPage = () =>{},
  setshowOptions = () =>{},
  unreadMessage = false,
  setUnreadMessage = () =>{},
}) => {
  const [selectedId, setSelectedId] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const [randomID, setRandomID] = useState(String(Math.random()));
  const handleCloseShareLink = () => setShowLink(false);
  const handleShowShareLink = () => setShowLink(true);
  const dispatch = useDispatch();
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [openAssigneeDropdownReq, setOpenAssigneeDropdownReq] = useState(false);
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const [selectedAssigneeNew, setSelectedAssigneeNew] = useState([]);
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState([]);




  useEffect(() => {
    jquery(document).mouseup(function (e) {
      var container = jquery(`${"#ChatDeLeteDropdown-"+newEditId}`);
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        jquery(`${"#ChatDeLeteDropdown-"+newEditId}`).removeClass("show");
      }
    });
    jquery(document).ready(function () {
      jquery(document).on("click", `${"#commentOptionDropdown-"+newEditId}`, function () {
        if((jquery`${"#ChatDeLeteDropdown-"+newEditId}`).hasClass("show")){
          jquery(`${"#ChatDeLeteDropdown-"+newEditId}`).removeClass("show");
        } else{
          jquery(`${"#ChatDeLeteDropdown-"+newEditId}`).addClass("show"); 
        }
      });
    });
  }, []);  

  useEffect(() => {
    jquery(document).mouseup(function (e) {
     var container = jquery('.chat-page');
     if (!container.is(e.target) && container.has(e.target).length === 0) {
       setshowOptions(0)
  
     }
   });
  })
  const ReplyMsgArea = (
   
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={() => {
        initReply(newEditId, pageName);
        HideDropdown();
        setIsfromChatPage(false)
        dispatch(openChatAiInput(true));
      }}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
            <ArrowBendUpLeft size={16} className="c-icons" weight="light" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l("l_replay")}
            </div>
          </div>
        </div>
      </DropdownItem>
   
  );

  const PinArea = isTaskPinned ? (
    
    
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={handlePin}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <PushPin size={20} className="c-icons" weight="light" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l("l_unpin")}
            </div>
          </div>
        </div>
      </DropdownItem>
  ) : (
   
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={handlePin}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <PushPin size={20} weight="fill" color="Push-Pin-fill" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l("l_pin")}
            </div>
          </div>
        </div>
      </DropdownItem>
    
  );

  const EditArea = (
   
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={() => { 
        handleEdit(newEditId); 
        setIsfromChatPage(false)
      }}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
            <NotePencil size={16} className="c-icons" weight="light" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l("l_edit")}
            </div>
          </div>
        </div>
      </DropdownItem>

  );

  const CopyArea = (
    <>
    
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={() => { 
        navigator.clipboard.writeText(textToCopy.replace(/<([a-z]+)(?![^>]*\/>)[^>]*>/g, 
        function() {
          return '';
         }).replace(/<.a>/gm, ""));
        dispatch(showMessage("sucess", _l("l_success"), _l("l_copied_to_clipboard")));
        setIsfromChatPage(false)
      }}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <CopyIcon size={16} className="c-icons" weight="light" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l("l_copy")}
            </div>
          </div>
        </div>
      </DropdownItem>
    </>
  );

  const TaskAssignArea = (
    <UncontrolledDropdown className={`hr_sepretr dropstart l_50px`} isOpen={openAssigneeDropdown} direction="end">
      <DropdownToggle className="dropdown-item p-2" type="button"
        id="CurrentProviderDropdown"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        data-popper-placement="left-start"
        data-assigneeMain={""}
        onClick={() => {
          setOpenAssigneeDropdown(!openAssigneeDropdown);
          setOpenAssigneeDropdownReq(false);
        }}>
      
        <div className="d-flex align-items-center text-truncate" >
          <div className="d-flex align-items-center w100minus10 text_wrap">
            <div className="c-list-icon comman_action_icon">
              <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                <AddTaskIcon className="HW18" /> 
              </div>
            </div>
            <div className="ps-2 w100minus30 text_wrap">
              <div className="c-list-detail c-font f-14 text-truncate color-white-60">{_l("l_create_task")}</div>
            </div>
          </div>
          <div className="c-list-icon">
            <CaretRight size={14} weight="light" className="c-icons" /> 
          </div>
        </div>
      </DropdownToggle>
      <DropdownItem toggle={false} className="p-0">
      <AddAssigneeMainDropdownNew
        openAssigneeDropdown={
          openAssigneeDropdown
        }
        setOpenAssigneeDropdown={setOpenAssigneeDropdown}
        pageName={pageName == "LiteVersion" ? "LiteVersion" : 'task'}
        selectedId={selectedAssigneeIds}
        setDropDownToggle={setDropDownToggle}
        dropDownToggle={dropDownToggle}
        isRequest={true}
        setSelectedAssigneeIds={setSelectedAssigneeIds}
        selectedAssignee={selectedAssigneeNew}
        setSelectedAssignee={setSelectedAssigneeNew}
        saveBtnShow={true}
        fromCreate={true}
        actionType={"task_from_chat"}
        actionStausChange={createTask}
        actionTypeId={newEditId}
      />
      </DropdownItem>
    </UncontrolledDropdown>
  )

  const RequestAssignArea = (
    <UncontrolledDropdown className={`hr_sepretr dropstart l_50px`} isOpen={openAssigneeDropdownReq} direction="end">
      <DropdownToggle className="dropdown-item p-2" type="button"
        id="CurrentProviderDropdown"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        data-popper-placement="left-start"
        data-assigneeMain={""}
        onClick={() => {
          setOpenAssigneeDropdownReq(!openAssigneeDropdownReq);
          setOpenAssigneeDropdown(false);
          
        }}>
      
        <div className="d-flex align-items-center text-truncate" >
          <div className="d-flex align-items-center w100minus10 text_wrap">
            <div className="c-list-icon comman_action_icon">
              <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <RequestTaskIcon className="HW18" /> 
              </div>
            </div>
            <div className="ps-2 w100minus30 text_wrap">
              <div className="c-list-detail c-font f-14 text-truncate color-white-60">{_l("l_create_request")}</div>
            </div>
          </div>
          <div className="c-list-icon">
            <CaretRight size={14} weight="light" className="c-icons" /> 
          </div>
        </div>
      </DropdownToggle>
      <DropdownItem toggle={false} className="p-0">
      <AddAssigneeMainDropdownNew
        openAssigneeDropdown={
          openAssigneeDropdownReq
        }
        setOpenAssigneeDropdown={setOpenAssigneeDropdownReq}
        pageName={pageName == "LiteVersion" ? "LiteVersion" : 'task'}
        selectedId={selectedId}
        setDropDownToggle={setDropDownToggle}
        dropDownToggle={dropDownToggle}
        isRequest={true}
        setSelectedAssigneeIds={setSelectedAssigneeIds}
        selectedAssignee={selectedAssigneeNew}
        setSelectedAssignee={setSelectedAssigneeNew}
        saveBtnShow={true}
        fromCreate={true}
        actionType={"request_from_chat"}
        actionStausChange={createTask}
        actionTypeId={newEditId}
      />
      </DropdownItem>
    </UncontrolledDropdown>
  )

  const ConvertArea = (
    
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={() => { 
        setConvertMessageId(newEditId); 
        setShowConvertDropdown(true);
        setIsfromChatPage(false)
        setConvertTaskId(newEditId)
      }}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <Repeat size={16} className="c-icons" weight="light" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l("l_convert")}
            </div>
          </div>
        </div>
      </DropdownItem>
  );

  const subShareMenu =(
   
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={handelGoogleMeet}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              
            <VideoCamera size={18} weight="light" className="c-icons" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l('l_google_meet')}
            </div>
          </div>
        </div>
      </DropdownItem>

  )

  const viewTaskArea = (
    
      <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={()=>viewTask(newEditId)}>
        <div className="d-flex align-items-center text-truncate">
          <div className="c-list-icon comman_action_icon">
            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
            <Info size={16} className="c-icons" weight="light" />
            </div>
          </div>
          <div className="ps-2 w100minus30 text_wrap">
            <div className="c-list-detail c-font f-14 text-truncate color-white-60">
            {_l("l_view_task")}
            </div>
          </div>
        </div>
      </DropdownItem>

  );


  const CallArea = (
    <li className="hr_sepretr l_50px">
      <UncontrolledDropdown direction={callListPos}>
        <DropdownToggle tag="div" className="" >
          <a href="#/" 
            className="dropdown-item"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false" 
            data-popper-placement="left-start"
          >
            <div className="d-flex align-items-center text-truncate">
              <div className="c-list-icon comman_action_icon">
                <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                  <PhoneCall size={16} className="c-icons" weight="light" />
                </div>
              </div>
              <div className="ps-2 w100minus30 text_wrap">
                <div className="c-list-detail c-font f-14 text-truncate color-white-60">
                  {_l("l_call")}
                </div>
              </div>
            </div>
          </a>
          <UncontrolledGeneralListing 
            list={taskUserAssignees.map((item, index) => {
              return {
                name: item.name,
                image: item.profile_image,
                id: index,
                isOnline: 0,
                userId: item.id,
                email: item.email,
                userType: item.user_type
              };
            })} 
            makeCall={makeCall}
            forCall={true}
          />
        </DropdownToggle>
      </UncontrolledDropdown>
    </li>
  );

  const ShareArea = (
    <li className={`hr_sepretr dropstart l_50px`}>
      <a href="#/" className="dropdown-item" type="button"
        id="CurrentProviderDropdown"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        data-popper-placement="left-start"
        data-assigneeMain={""}
      >
        <div className="d-flex align-items-center text-truncate" >
          <div className="d-flex align-items-center w-100 text_wrap">
            <div className="c-list-icon comman_action_icon">
              <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
              <ShareNetworkIcon size={14} className="c-icons with-fill" weight="light" />
              </div>
            </div>
            <div className="ps-2 w100minus40 text_wrap">
              <div className="c-list-detail c-font f-14 text-truncate color-white-60">{_l("l_share")}</div>
            </div>
            <div className="c-list-icon">
              <CaretRight size={14} weight="light" className="c-icons " />
            </div>
          </div>
        </div>
      </a>
      <ActionDropdown
       actions={[
         {
           actionName: _l("l_copy_link"),
           actionHandler: () => {
               navigator.clipboard.writeText(`${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${task ? task.hash : ""}`);
               dispatch(showMessage("sucess", _l("l_success"),_l("l_link_copied")));
               HideDropdown();
              setIsfromChatPage(false)
             
           },
           actionIcon: "CopyIcon",
         },
         {
           actionName: _l("l_send_to_email"),
           actionHandler: () => {
             setShowLink(true)
             HideDropdown();
           },
           actionIcon: "EnvelopeSimpleIcon",
         },
         {
           actionName: _l("l_open_link"),
           actionHandler: () => {
             window.open(`${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${task ? task.hash : ""}`);
             HideDropdown();
           },
           actionIcon: "LinkSimpleIcon",
         }
         
       ]}          
     />
      <TaskShareLinkModal
        show={showLink}
        handleClose={handleCloseShareLink}
        handleShow={handleShowShareLink}
        link={`${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${task.hash}`}
        data={{id: task.id, contacts: contacts}}
        docType={docType}
      />
    </li>
  );

  const unreadMessageHere = (
    
    <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={()=>setUnreadMessage(newEditId)}>
      <div className="d-flex align-items-center text-truncate">
        <div className="c-list-icon comman_action_icon">
          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
          <EyeSlash size={16} className="c-icons" weight="light" />
          </div>
        </div>
        <div className="ps-2 w100minus30 text_wrap">
          <div className="c-list-detail c-font f-14 text-truncate color-white-60">
          {_l("l_unread_from_here")}
          </div>
        </div>
      </div>
    </DropdownItem>

);

  return (
    <>
    <DropdownMenu container="body" className={`dropdown-menu chat-page ${widthClass != "" ? widthClass : "w-100 min-w-100px"} ${isOwnMsg ? "end-0":""} ${isfromChatPage ? "show" : ""}`} aria-labelledby="deLeteDropdown" id={isfromChatPage ? `${'ChatDeLeteDropdown-'+newEditId}` : ""}>
      {initReply ? ReplyMsgArea : <></>}
      {deleteOnly ? <></> : edit ? EditArea : <></>}
      {displayCopyBtn ? CopyArea : <></>}
      {pending ? (
        <>
          <li className="hr_sepretr">
            <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={handleDelete}>
              <div
                className="d-flex align-items-center text-truncate"
                onClick={() => {
                  pendingAction(itemId, 3);
                }}
              >
                <div className="c-list-icon comman_action_icon">
                  <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                    <Trash size={16} className="c-icons" weight="light" />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap">
                  <div className="c-list-detail c-font f-14 text-truncate color-white-60">
                  {_l("l_reject")} 
                  </div>
                </div>
              </div>
            </DropdownItem>
          </li>
          <li className="hr_sepretr">
            <DropdownItem className="dropdown-item hr_sepretr w-100 l_50px" onClick={handleDelete}>
              <div
                className="d-flex align-items-center text-truncate"
                onClick={() => {
                  pendingAction(itemId, 1);
                }}
              >
                <div className="c-list-icon comman_action_icon">
                  <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                    
                  <Trash size={16} className="c-icons" weight="light" />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap">
                  <div className="c-list-detail c-font f-14 text-truncate color-white-60">
                  {_l("l_approve")}
                  </div>
                </div>
              </div>
            </DropdownItem>
          </li>
        </>
      ) : (
        <>

          <li className={`hr_sepretr l_50px ${pageName === "task" ? (task && parseInt(task.is_allowed_to_delete) == 1) ? "" : "d-none" : allowToDelete ? "" : "d-none"}`}>
            <a href="#/" className="dropdown-item " onClick={handleDelete}>
              <div className="d-flex align-items-center text-truncate">
                <div className="c-list-icon comman_action_icon">
                  <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                    
                  <Trash size={16} className="c-icons" weight="light" />
                  </div>
                </div>
                <div className="ps-2 w100minus30 text_wrap">
                  <div className="c-list-detail c-font f-14 text-truncate color-white-60">
                  {_l("l_delete")}
                  </div>
                </div>
              </div>
            </a>
          </li>

        </>
      )}
      {isOwnMsg && forCreateTask ? TaskAssignArea : <></>}
      {isOwnMsg && forCreateTask ? RequestAssignArea : <></>}
      {isOwnMsg && forCreateTask ? ConvertArea : <></>}
      {withSubDropdown ? subShareMenu : <></>}
      {isViewTask ? viewTaskArea : <></>}
      {allowToShare ? ShareArea : <></>}
      {allowToCall ? CallArea : <></>}
      {unreadMessage ? unreadMessageHere : <></>}
    </DropdownMenu>
       
      </>
  );
};

export default DeleteDropdown;

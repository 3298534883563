import React, { useState } from "react";
import { _l } from "../../../hooks/utilities";
import { Minus, Plus } from "phosphor-react";
import { useSelector } from "react-redux";
import LeaseModal from "../../Modals/LeaseModal";
import { Button } from "react-bootstrap";

function SpaceSummary({ currentStep, addEditFloor, spaceDetail, setSpaceDetail, fullWidth , showContractbtn = false , subAddContract}) {

  const { floorDetails ,floorIds} = useSelector((state) => state.benchmarkReducer);

  const [showLeaseModal, setshowLeaseModal] = useState(false);


  return (
    <>
    <div className="col-xl-12 position-sticky top-0 bgOfficelisting lightthemebgwhite mb-2 z-index-2">
      {
        currentStep === "floor-listing"
        ?
          <div className="row mt-0 g-4">
            <div className="col-xl-12 c-input-box pb-3 position-relative m-0">
              <div className="form-control p-0 d-flex align-items-center">
                <div className="color-white-80 border-right px-3">{_l("l_title")}</div>
                <input
                  className="form-control border-0 bg-transparent c-box-shadow-box"
                  placeholder={_l("l_space_title_placeholder")}
                  type="text"
                  name=""
                  defaultValue={spaceDetail.title || spaceDetail.address}
                  onChange={(e) => {
                    setSpaceDetail({
                      ...spaceDetail,
                      title: e.target.value
                        // .replace(/[^\w\s]/gi, "")
                        .substring(0, 50),
                    });
                  }}
                />
              </div>
            </div> 
          </div>
        :
          <React.Fragment></React.Fragment>
      }
      <div className="p-20 radius_5 w-100 c-box-shadow-box">
        <div class="align-items-center d-flex justify-content-center">
          <div className={`${fullWidth ? "w-100" : "w-75"} align-items-center d-flex justify-content-between`}>
            <div className="w-33 text-center">
              {/* <div className="c-font f-18 fw-semibold title-fonts py-1">
                {summaryData.superfice} (m²)
              </div> */}
              <div className="m-w110px mx-auto py-1">
                <div className="d-flex align-items-center justify-content-between form-control bg-transparent">
                  <a
                    className={`c-font f-22 line-height-0 ${
                      spaceDetail.superfice > 1000 ? "" : "for-disabled"
                    }`}
                    onClick={() => {
                      if(spaceDetail.superfice > 1000)
                      {
                        setSpaceDetail({...spaceDetail, superfice: Number(spaceDetail.superfice) - 1000 });
                      }
                    }}
                  >
                    <Minus size={18} className="c-icons" weight="light" />
                  </a>
                  <input
                    type="number"
                    className="bg-transparent border-0 text-center line-height-1"
                    name=""
                    value={spaceDetail.superfice}
                    onChange={(e) => {
                      setSpaceDetail({...spaceDetail, superfice: e.target.value});
                    }}
                  />
                  <a
                    className="c-font f-22 line-height-0"
                    onClick={() => {
                      setSpaceDetail({...spaceDetail, superfice: Number(spaceDetail.superfice) + 1000 });
                    }}
                  >
                    <Plus size={18} className="c-icons" weight="light" />
                  </a>
                </div>
              </div>
              <div className="c-font color-white-60 f-12 py-1">
                {_l("l_superficy")}
              </div>
            </div>
            <div className="w-33 text-center">
              <div className="m-w110px mx-auto py-1">
                <div className="d-flex align-items-center justify-content-between form-control bg-transparent">
                  <a
                    className={`c-font f-22 line-height-0 ${
                      spaceDetail.number_of_desk > 10 ? "" : "for-disabled"
                    }`}
                    onClick={() => {
                      if(spaceDetail.number_of_desk > 10)
                      {
                        setSpaceDetail({...spaceDetail, number_of_desk: Number(spaceDetail.number_of_desk) - 10 });
                      }
                    }}
                  >
                    <Minus size={18} className="c-icons" weight="light" />
                  </a>
                  <input
                    type="number"
                    className="bg-transparent border-0 text-center line-height-1"
                    name=""
                    value={spaceDetail.number_of_desk}
                    onChange={(e) => {
                      setSpaceDetail({...spaceDetail, number_of_desk: e.target.value});
                    }}
                  />
                  <a
                    className="c-font f-22 line-height-0"
                    onClick={() => {
                      setSpaceDetail({...spaceDetail, number_of_desk: Number(spaceDetail.number_of_desk) + 10 });
                    }}
                  >
                    <Plus size={18} className="c-icons" weight="light" />
                  </a>
                </div>
              </div>
              <div className="c-font color-white-60 f-12 py-1">
                {_l("l_number_of_desk")}
              </div>
            </div>
            <div className="w-33 text-center">
              <div className="m-w110px mx-auto py-1">
                <div className="d-flex align-items-center justify-content-between form-control bg-transparent">
                  <a
                    className={`c-font f-22 line-height-0 ${
                      floorDetails.floor && floorDetails.floor.length > 1 ? "" : "for-disabled"
                    }`}
                    onClick={() => {
                      addEditFloor(-1);
                    }}
                  >
                    <Minus size={18} className="c-icons" weight="light" />
                  </a>
                  <input
                    type="number"
                    className="bg-transparent border-0 text-center line-height-1"
                    name=""
                    value={floorDetails.floor && floorDetails.floor.length ? floorDetails.floor.length : 0 }
                  />
                  <a
                    className="c-font f-22 line-height-0"
                    onClick={() => {
                      addEditFloor(1);
                    }}
                  >
                    <Plus size={18} className="c-icons" weight="light" />
                  </a>
                </div>
              </div>
              <div className="c-font color-white-60 f-12 py-1">
                {_l("l_floors")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
          {
            showContractbtn ?
              <Button variant="secondary btn-sm"
                className={`color-white-60  title-fonts text-nowrap ms-10px me-10px mb-10px`}
                onClick={subAddContract}
              >
                <span className="">{_l("l_sub_contract")}</span>
              </Button>
              : <React.Fragment></React.Fragment>
          }
      <Button 
      variant={"primary"} 
      className={`me-10px mb-10px d-flex align-items-center gap10 ${floorIds.length > 0 ? "" : "for-disabled"}`}
      size="sm"
      onClick={()=>{
        if (floorIds.length > 0){
          setshowLeaseModal(true)
        }
      }}>
        {/* <Plus size={16} weight="light" className="c-icons " /> */}
        <div> {_l("l_lease")}</div>
      </Button>
    </div>
    </div>
    {
    floorIds.length > 0 &&  showLeaseModal ?
      <LeaseModal
      show={showLeaseModal} 
      handleClose={()=>{
        setshowLeaseModal(false);
      }}
      spaceDetail={spaceDetail}
      selectedFloorIds={floorIds}
      isFrom={"floor-list"}
      setSpaceDetail={setSpaceDetail}
      isCreate={true}
       />
       : <></>
    }
    </>
  );
}

export default SpaceSummary;

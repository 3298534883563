import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l, showError } from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import AddRiskModal from "./AddRiskModal";
import { useSelector } from "react-redux";
import { DotsNine } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import Form from "react-bootstrap/Form";
import customerServices from "../../services/customer-services";
import { DropdownToggle, DropdownItem, UncontrolledDropdown } from "reactstrap";
import ProviderListActionModal from "../Providertables/ProviderListActionModal";
import { showMessage } from "../../actions/messages";

const CreateTodoModal = ({ show, handleClose, hideActionMenu = false }) => {

  const { providerlistTableData } = useSelector((state) => state.customer);
  const [loader, setLoader] = useState(false);
  const [showAddRiskModal, setShowAddRiskModal] = useState(false);
  const [todoTaskData, setTodoTaskData] = useState([
    {
      title: "",
    },
  ]);

  const addNewRow = () => {
    let emptyData = {
        title: "",
    };
    setTodoTaskData([...todoTaskData, emptyData]);
  };

  const handleSave = () => {
    setLoader(true);
    let data = todoTaskData.filter((d) => d.title.trim() !== "");
    if (data.length > 0) {  
      try {
        customerServices.createToDos(
            data,
            [
                {
                   "main_key": "provider_page",
                   "categories": [
                      {
                        "filter_key": localStorage.getItem("currentCompanyID"),
                         "main_sub_key": "provider",
                         "sub_category": []
                      }
                   ]
                }
             ],
       
        ).then((res)=>{
            if (res && res.status) {
                let ids = res.data && res.data.task_ids ? res.data.task_ids : [];
                if (ids && ids.length > 0) {
                    customerServices.getTasklistForChat(undefined,ids,1).then((res)=>{
                        if (res && res.status) {
                        }
                        let oldTask = providerlistTableData.task
                        let finalArr = oldTask.concat(res.data ? res.data : [])
                    })
                }
                setLoader(false);
                showMessage(res.message);
                handleClose();

            }else{

                setLoader(false);

            }
        })
      } catch (error) {
        console.log(error);
      } 
    } else {
      showError("l_one_or_more_row_has_all_emply_slots");
    }
  };

  const handleDelete = (index) =>{
    let emptyData = todoTaskData.filter((d,i)=> d.title == "")
    if (emptyData.length == todoTaskData.length) {
      
    } else {
        setTodoTaskData(todoTaskData.filter((d,i)=> i != index));
    }
  }

  useEffect(() => {
    if (todoTaskData.length == 0) {
        addNewRow()
    } else if (todoTaskData.filter((d) => d.title === "").length == 0) {
        addNewRow();
      }

  }, [JSON.stringify(todoTaskData)]);

  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_create_todo")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="with-overflow pt-0 minh60vh">
            <div className="form-wrapper-main border-bottom pb-2">
              <div className="comman_vertical_btn_h vertical_scroll120 comman-white-box-shadow light-theme-white-bg">
                <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
                  <table className="dataTable text-center tablewithborder">
                    <thead className="comman-white-box-shadow">
                      <tr>
                        <th style={{ width: "20%" }} className="text-start">
                          {_l("l_task_name")}
                        </th>
                          <th style={{ width: "3%" }} className="text-center">
                            {_l("l_action")}
                          </th>
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "middle" }}>
                      {todoTaskData && todoTaskData.length > 0 ? (
                        <>
                          {todoTaskData.map((data, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="p-2">
                                    
                                            <a
                                              href="#/"
                                              className="title-fonts text-truncate text-start  fw-semibold w-100"
                                              onBlur={()=>{
                                                if (todoTaskData.filter((d) => d.title === "").length == 0) {
                                                    addNewRow();
                                                  }
                                              }}
                                            >
                                              <Form.Group className="col-xl-12 c-input-box position-relative">
                                                <Form.Control
                                                  className="p-10"
                                                  placeholder={`${_l(
                                                    "l_task_name"
                                                  )}`}
                                                  type="text"
                                                  name="isk_zone"
                                                  value={data.title}
                                                  onChange={(e) => {
                                                    setTodoTaskData(
                                                      Object.values({
                                                        ...todoTaskData,
                                                        [index]: {
                                                          ...data,
                                                          title: e.target.value,
                                                        },
                                                      })
                                                    );
                                                  }}
                                                  onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        addNewRow();
                                                    }
                                                  }}
                                                ></Form.Control>
                                              </Form.Group>
                                            </a>
                                    
                                  </td>
                                  
                                    <td className="p-2">
                                      <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                        <UncontrolledDropdown
                                          setActiveFromChild={true}
                                        >
                                          <DropdownToggle className="border-0 p-0">
                                            <a
                                              href="#/"
                                              className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                                            >
                                              <DotsNine
                                                size={18}
                                                className="c-icons "
                                                weight="light"
                                              />
                                            </a>
                                          </DropdownToggle>
                                          <DropdownItem toggle={false} className="p-0">
                                          <ProviderListActionModal
                                            handleDelete={()=>{
                                              handleDelete(index)
                                            }}
                                            actionList={["delete"]}
                                           />
                                           </DropdownItem>
                                        </UncontrolledDropdown>
                                      </div>
                                    </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {" "}
                          <tr className="">
                            <td className="border-0" colSpan={7}>
                              <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                <div className="d-flex flex-column align-items-center">
                                  <CommanPlaceholder
                                    imgType="request"
                                    placeholderText={_l("l_no_data")}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                if (!loader) {
                  handleSave();
                }
              }}
              disabled={loader}
            >
              {loader ? _l("l_please_wait") : _l("l_save")}
              {loader ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {showAddRiskModal ? (
        <AddRiskModal
          show={showAddRiskModal}
          handleClose={() => {
            setShowAddRiskModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};


export default CreateTodoModal
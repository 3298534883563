import React, { useEffect, useRef, useState } from "react";
import { CaretRight, CaretLeft, X, ArrowRight } from "phosphor-react";
import LogoGreenDark from "../../assets/icons/LogoGreenDark";
import LogoGreenLight from "../../assets/icons/LogoGreenLight";
import moment, { locale } from "moment";
import jquery from "jquery";
import { _l, showError, showSuccess } from "../../hooks/utilities";
 
import {
  setActionForLeaseDocument,
  setImageViewerisOPen,
} from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import leaseServices from "../../services/lease-services";
import { addEditDocumentComment } from "../../actions/documents-actions/documents-actions";
// import FileViewer from 'react-file-viewer';


const ImageViewer = ({
  attachmentURL,
  attachmentDate,
  changeAttach,
  showPrevBtn,
  showNextBtn,
  docType,
  documents = [],
  data,
}) => {
  const { themeColor, leaseDocAction, leaseDocType, leaseDocuments, activeCommentID } = useSelector(
    (state) => state.customer
  );
  const documentData = useSelector((state) => state.documentsReducer);
  const [LightTheme, setLightTheme] = useState(
    localStorage.getItem("selectedTheme") == "light" ? true : false
  );

  const [comments, setComments] = useState([]);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [newComment, setNewComment] = useState({ content: "", x: 0, y: 0 });
  const [fileContent, setFileContent] = useState("");
  const [editFileContent, setEditFileContent] = useState("");
  const [selectedComment, setSelectedComment] = useState(0);
  const [documentID, setdocumentID] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);


  const editorRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setImageViewerisOPen(true));
  }, []);

  useEffect(() => {
    if (themeColor == "light") {
      jquery("html").addClass("light-theme");
      setLightTheme(true);
    } else {
      jquery("html").removeClass("light-theme");
      setLightTheme(false);
    }
  }, [themeColor]);

  const getFileType = (url) => {
    if (url) {
      const fileExtension = url.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        return "pdf";
      } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
        return "image";
      } else if (["txt"].includes(fileExtension)) {
        return "text";
      } else if (["doc", "docx"].includes(fileExtension)) {
        return "doc";
      } else {
        return "other";
      }
    }
  };

  const fileType = getFileType(attachmentURL);

  const handleAddCommentOnDocument = (e) => {
    setIsAddingComment(true);
    let element = document.getElementById("file-container")
    let rect = element.getBoundingClientRect();
    setNewComment({
      ...newComment,
      position : {
        x: e.clientX - rect.left,
        y: (((e.clientY - rect.top) + 40 ) + scrollPosition) },
        id : Math.floor(Math.random() * 99999),
        addedfrom_details : {
          full_name : localStorage.getItem("full_name"),
          id : localStorage.getItem("staff_id"),
          profile_url : localStorage.getItem("contact_image"),
        },
    });

    setComments([
      ...comments,
      {
        position : { 
          x: Math.round(e.clientX - rect.left),
          y: (((e.clientY - rect.top) + 40 ) + scrollPosition) },
        title: "",
        content: "",
        attachmentURL: attachmentURL,
        addedfrom_details : {
          full_name : localStorage.getItem("full_name"),
          id : localStorage.getItem("staff_id"),
          profile_url : localStorage.getItem("contact_image"),
        },
      },
    ]);
  };
  const handleCommentBlur = async() => {



    if (newComment && newComment.content.trim() !== "" ) {
      dispatch(
        addEditDocumentComment(
          "leaseDocument",
          documentID,
          newComment.content,
          false,
          0,
          [],
          ()=>{},
          ()=>{},
          undefined,
          undefined,
          { x : newComment.position.x, y : newComment.position.y},
          false,
          documentID,
          ()=>{},
          ""
        )
      );
      let tempComments = comments.filter((c) => c.content && c.content != "");
      setComments([...tempComments, newComment]);
      setNewComment({ content: "", position : {x: 0, y: 0} });
      setIsAddingComment(false);

    } else {
      
    }
  };

  const handleCommentChange = (e) => {
    setIsAddingComment(true);
    setNewComment({
      ...newComment,
      content: e.target.value,
      title: e.target.value,
      attachmentURL: attachmentURL,
    });
  };

  const handleUpdateTextFile = async (content) => {
    setEditFileContent(content);
    const file = new Blob([content], { type: "text/plain" });
  };

  const handleSaveFile = async () => {
    let docID = documents.find((item) => item.image_url == attachmentURL);

    if (docID && docID.id) {
      const file = new Blob(
        [editFileContent],
        { type: "text/plain" },
        { name: "asdasasd.txt" }
      );
      let url = URL.createObjectURL(file);
      let response = await leaseServices.editLeaseDocuments(
        data.projectId,
        data.lease_schedule_id,
        docID.id,
        file
      );
      if (response && response.status) {
        showSuccess("l_document_update");
      } else {
        showError("l_something_went_wrong");
      }
      dispatch(setActionForLeaseDocument());
    }
  };

  useEffect(() => {
    setComments([])
    // dispatch(setActionForLeaseDocument());
    if (fileType == "text") {
      jquery(".lease-action").addClass("f-black");
      jquery(".lease-action").removeClass("color-white-60");
      jquery(".footer-add-btn-bg").addClass("shadowWhite");

      // jquery(".lease-doc-action").removeClass("footer-add-btn-bg")
    } else {
      jquery(".lease-action").removeClass("f-black");
      jquery(".footer-add-btn-bg").removeClass("shadowWhite");
      jquery(".lease-action").addClass("color-white-60");
      // jquery(".lease-doc-action").addClass("footer-add-btn-bg")
    }
  }, [attachmentURL]);

  // useEffect(() => {
  // }, [leaseDocAction, leaseDocType]);

  useEffect(() => {
    if (attachmentURL && fileType == "text") {
      fetch(attachmentURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((text) => {
          setFileContent(text);
        })
        .catch((error) =>
          console.error("Error fetching the text file:", error)
        );
    } else {
      setFileContent("");
    }
  }, [attachmentURL]);

  useEffect(() => {

    let docs = leaseDocuments && leaseDocuments.length > 0 && leaseDocuments.map((doc, i)=>{
      if (doc && doc.sub_document && doc.sub_document.length > 0 ) {
        return {...doc , 
          image_url : doc.sub_document[doc.sub_document.length - 1].image_url, 
          previous_document_id : doc.sub_document[doc.sub_document.length - 1].previous_document_id,
          crrDocID : doc.sub_document[doc.sub_document.length - 1].id
         }
      }else{
        return doc
      }
    })
    let crrDoc = docs && docs.length > 0 &&  docs.find(doc => doc.image_url == attachmentURL)
    if (crrDoc) {
      setdocumentID(crrDoc.id)
    }
  }, [leaseDocuments, attachmentURL])
  
useEffect(() => {
    let formattedComments = []
    if (documentData && documentData.leaseDocument_comments && documentData.leaseDocument_comments.length > 0) {
      const index = documentData[`${"leaseDocument"}_comments`].findIndex(
        (x) => x.id == documentID
      );
      if (index !== -1) {
        formattedComments = documentData.leaseDocument_comments[index].comments;
        if (formattedComments && formattedComments.length > 0) {
          formattedComments =  formattedComments.filter(comment => comment.position)
  
          setComments(formattedComments)
        }else{
          setComments([])
        }
      }
      
    }else{
      setComments([])
    }


}, [documentData, attachmentURL])

// useEffect(() => {
//   console.log("activeCommentID",activeCommentID)
//   // setSelectedComment(activeCommentID)
// }, [activeCommentID])


useEffect(() => {
  const txtViewer = document.getElementById('txtviewer');

  const handleScroll = () => {
    const scrollTop = txtViewer.scrollTop;
    const scrollHeight = txtViewer.scrollHeight;
    const clientHeight = txtViewer.clientHeight;
    const scrolledPercentage = scrollTop 
    // console.log("scrollHeight",scrollHeight,"scrollTop",scrollTop,"clientHeight",clientHeight,"scrolledPercentage",scrolledPercentage)

    // Update state with the current scroll position and percentage
    setScrollPosition(scrolledPercentage);
  };

  if (txtViewer) {    
    // Add event listener for scroll
    txtViewer.addEventListener('scroll', handleScroll);
  
    // Cleanup event listener on component unmount
    return () => {
      txtViewer.removeEventListener('scroll', handleScroll);
    };
  }
});

useEffect(() => {
  if (leaseDocAction == "comment") {  
  } else {
    setComments(comments.length > 1 ? comments.filter(c => c.content && c.content != "") : comments );
    setIsAddingComment(false);
  }

},[leaseDocAction])



  return (
    <>
    <div className="d-flex flex-column h-100 position-relaive">
      <div
      id="file-container"
        className={`flex-grow-1 right-content-part flex-grow-1 h-100 position-relative c-list-icon w-100 light-theme-base-body-color radius_3 withbeforenone position-relative ${
          docType == "leaseDocument" ? "" : "h_16vh with-overlay"
        } ${leaseDocAction == "comment" ? "custom-cursor" : ""}`}
        onClick={(e) => {
          if (leaseDocAction == "comment" && !isAddingComment) {
            handleAddCommentOnDocument(e);
          }
        }}
      >
        
        {docType == "leaseDocument" || leaseDocAction ? (
          <></>
        ) : (
          <div
            className={`header-logo position-absolute top-0 start-0 z-index-2 w-100`}
          >
            <div className="d-flex align-items-center justify-content-between">
              {LightTheme ? (
                <LogoGreenLight className="header-logo-img p-2 max-w-130px h-auto " />
              ) : (
                <LogoGreenDark className="header-logo-img p-2 max-w-130px h-auto " />
              )}
            </div>
          </div>
        )}
        {/* <div className="comman-image-box-bg bg-style-contain h-100 w-100 radius_3" style={{ backgroundImage: `url('${attachmentURL}')` }}></div>
        <iframe src={attachmentURL} width="100%" height="100%" style={{ paddingTop: "50px" }} ></iframe> */}

        {fileType === "pdf" ? (
          <div className="position-relative h-100 d-flex justify-content-center">
            <iframe
              src={attachmentURL}
              width="100%"
              height="100%"
              // style={{ paddingTop: "50px" }}
            ></iframe>
             {/* <FileViewer
        fileType={"pdf"}
        filePath={attachmentURL}
        /> */}
          </div>
        ) : fileType === "text" ? (
          <div className={`with-white-bg Editorbox h-100 position-relative`}>
            <>
              <span
                style={{ whiteSpace: "pre-wrap" }}
                className="d-flex h-100 w-100 m-0  p-0 bg-white"
              >
                <p className="bg-white f-black m-0 overflow-auto p-0 p-3 h100minus220px h-100" id="txtviewer">
                  {fileContent}
                </p>
              </span>
            </>
          </div>
        ) : fileType === "doc" ? (
          <>
            {/* <iframe
            src={attachmentURL}
            width="100%"
            height="100%"
            style={{ paddingTop: "50px" }}
          ></iframe> */}
          </>
        ) : // <div
        //   className="comman-image-box-bg bg-style-contain h-100 w-100 radius_3" >
        //     {fileContent}
        // </div>
        fileType === "image" ? (
          <div
            className="comman-image-box-bg bg-style-contain h-100 w-100 radius_3 position-relative"
            style={{ backgroundImage: `url('${attachmentURL}')` }}
          ></div>
        ) : (
          <p>Unsupported file type</p>
        )}
        {showPrevBtn && !leaseDocType ? (
          <a
            href="#/"
            className="ms-2 bg-silder-btn h40w40 with_overlay d-flex  rounded-circle position-absolute top-50 start-0 translate-middle-y"
             
             
             
            onClick={() => changeAttach("prev")}
          >
            <CaretLeft size={18} weight="light" className="c-icons " />
             
          </a>
        ) : (
          <></>
        )}
        {showNextBtn && !leaseDocType ? (
          <a
            href="#/"
            className="me-2 bg-silder-btn h40w40 with_overlay d-flex  rounded-circle position-absolute top-50 end-0 translate-middle-y"
             
             
             
            onClick={() => changeAttach("next")}
          >
            <CaretRight size={18} weight="light" className="c-icons " />
             
          </a>
        ) : (
          <></>
        )}
        {/* <div className="file-uploader-name d-flex pb-3"></div> */}
        <div className="position-absolute top-100 start-50 translate-middle pb-5 d-none">
          <a
            href="#/"
            className="rounded-pill badge badge-done text-nowrap c-green-border px-2 py-1 mb-5"
          >
            <span className="title-font c-font f-14">
              {attachmentDate && attachmentDate != ""
                ? moment(attachmentDate).format("DD/MM/YYYY hh:MM a")
                : ""}
            </span>
          </a>
        </div>
        {/* {!leaseDocType && <CommanFooterPlus
          MainClass="with-position"
          bottomMenuClass="footer-right-10"
          id="ImageViewerFooterHandle"
          toggleId="ImageViewerFooterShow"
          docType={docType}
        />} */}
         
      </div>
      <div className="">
          {comments.map((comment, index) => {
              return (
                <div
                  key={index}
                  className="comment-point comment-point position-absolute z-1"
                  style={{ left: comment.position.x, top: (comment.position.y - scrollPosition)}}
                  // title={comment.text}
                  onClick={(event)=>{
                    event.preventDefault();
                  }}
                  onMouseLeave={()=>{
                    setSelectedComment(0)
                  }}
                >
                  <OverlayTrigger
                    // trigger={["click"]}
                    show={comment.id == selectedComment}
                    delay={{ show: 1500   }}
                    placement={"bottom"}
                    onClick={(event)=>{
                      setSelectedComment(comment.id == selectedComment ? 0 : comment.id)
                      event.preventDefault();
                    }}
                    onExit={()=>{setSelectedComment(0)}}
                    overlay={
                          <Popover
                            id={`popover-positioned-${"bottom"}`}
                            className=""
                            >
                            <Popover.Body className="p-body">
                              <span>{comment.content}</span>
                            </Popover.Body>
                        </Popover>
                    }
                  >
                    <div className="d-flex align-items-center gap10 m-1">
                      <a
                        href="#/"
                        className={`h32w32 comman-round-box with-bg d-flex align-items-center bgOfficelisting rounded-circle ${comment.id == activeCommentID ? "greenborder3px" : ""}`}
                        onClick={(event)=>{
                          setSelectedComment(comment.id == selectedComment ? 0 : comment.id);
                        }}
                      >
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex on-hover-active-toggle-img"
                          style={{
                            backgroundImage: `url(${comment.addedfrom_details.profile_url})`,
                          }}
                        ></div>
                      </a>
                      <div className="d-flex align-items-center">
                        {isAddingComment && index == (comments.length - 1) ? (
                          <>
                            <input
                              type="text"
                              placeholder="write comment"
                              className="form-control comment-input customCommentBox pe28px"
                              style={{ left: newComment.x, top: newComment.y }}
                              value={newComment.content}
                              onChange={(e) => {
                                handleCommentChange(e, index);
                              }}
                              // onBlur={handleCommentBlur}
                            />
                            <a
                              href="#/"
                              className="bg-light-subtle border d-flex h25w25 me-1 position-absolute right-5px send-arrow-btn ms-1"
                              onClick={() => {
                                handleCommentBlur();
                              }}
                            >
                              <ArrowRight
                                size={16}
                                weight="light"
                                className="c-icons black-l-white"
                              />
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              );
          })}
        </div>
    </div>
    </>
  );
};

export default ImageViewer;

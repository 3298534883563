import axios from "axios";
import { addLoaderItem, removeLoaderItem } from "../../actions/general-actions/general-actions";
import store from "../../store";
import authHeader from "../auth-header";
import { loadDataToIDB } from "../../hooks/utilities";
const version = process.env.REACT_APP_VERSION;

const EXTERNAL_PAGES = ["/create-benchmark"];
const EXTERNAL_QUERY_PARAMS = ["external-benchmark"];

const apiService = async (
  url,
  method,
  header,
  data,
  changeStaffid = 0,
  exists = false,
  guestId = 0,
  user_role = ""
) => {
  const validUserTypes = ["contact", "operator", "staff"];
  let userType = localStorage.getItem("user_type");
  let language = localStorage.getItem("language");
  const path = window.location.pathname;

  if(EXTERNAL_PAGES.includes(path) || EXTERNAL_QUERY_PARAMS.find((w) => window.location.search.includes(w)))
  {
    data.append("is_external", 1);
  }

  if(guestId > 0)
  {
    userType = "";
  }
  else if(validUserTypes.includes(userType))
  {
    if(url.includes("get_login_details"))
    {
      userType = "staff";
    }
  }
  else {
    userType = "contact";
  }
  if(exists && user_role == "staff"){
    userType = "staff";
  }else if(exists && user_role != ""){
    userType = user_role;
  }
  let headers = {};
  const jwtToken = authHeader();
  if (header) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: jwtToken,
      "user-type": userType,
      'language': language,
      "version":version
    };
  }
  const virtualspace = localStorage.getItem("selectedVirtualSpace") ? 1 : 0 ;
  // let permissionDenied = ["mod=company_profile&act=get_employee_data"];
  let permissionDenied = ['mod=document&act=document_assignee', "mod=space&act=add_edit_type_of_space", 'mod=space&act=get_type_of_space_per_space', 'mod=general&act=send_email'];
  let customUrl = url.split("?");
  if (data) {
    const staff_id = localStorage.getItem("staff_id");
    const contact_role = localStorage.getItem("contact_role");
    if (contact_role != null) { 
      data.append("contact_role",contact_role);
    }
    if (changeStaffid == 1 && staff_id) {
      data.append("staffid", staff_id);
    }else if(staff_id){
      data.append("staff_id", staff_id);
    }
  }
  if (!exists) {
    const client_id = localStorage.getItem("client_id");

    const contact_id = localStorage.getItem("contact_id");
    if (data) {
      let space_setup_pending = localStorage.getItem("space_setup_pending") ? localStorage.getItem("space_setup_pending") : 0
      if (permissionDenied.indexOf(customUrl[1]) == -1 || customUrl[1] == "mod=general&act=send_email") {
        if (contact_id != null) { 
          data.append("contact_id", contact_id);
        }
      }
      if((userType == "staff" || userType == "operator") && url.includes("chat_group_create") && space_setup_pending != 1 ){
        data.append("client_id", localStorage.getItem("virtualSpaceClientId"));
      }

      if(customUrl[1]  == "mod=dashboard&act=get_credit_note_info" && userType == "operator"){
        data.append("client_id", localStorage.getItem("dashboard_client_id"));
      }else if (client_id != null && client_id > 0) { 
        data.append("client_id", client_id);
      }
    }
  }
  if (guestId == 0 && permissionDenied.indexOf(customUrl[1]) == -1) {
    if (userType == "operator" || userType == "staff") {
      const staff_id = localStorage.getItem("staff_id");
     
      data.append("is_virtualspace", virtualspace);
      data.append("rel_id", staff_id);
      data.append("rel_type", "staff");
    } else {
      const contact_id = localStorage.getItem("contact_id");
      data.append("is_virtualspace", virtualspace);
      data.append("rel_id", contact_id);
      data.append("rel_type", "contact");
    }
  }

  const urlId = "";

  try {
    store.dispatch(addLoaderItem({ url, id: urlId }));

    const response = await axios({
      url,
      method,
      headers,
      data,
    });

    store.dispatch(removeLoaderItem({ url, id: urlId }));

    if (response.status == 200 && response.data && response.data.status) {
      if (response &&  response.data && response.data.extra && response.data.extra.is_json_update && response.data.extra.is_json_update == 1 && (localStorage.getItem('json_updating')  ? JSON.parse(localStorage.getItem('json_updating')) == false : true)) {
        localStorage.setItem('json_updating', true)
        loadDataToIDB(localStorage.getItem("staff_id") , false)
      }
      return response.data;
    } else if (response.data && response.data.message) {
      // swal("Oops!", response.data.message, "error");
      return response.data;
    } else {
      console.error(response);
      return [];
    }
  }
  catch (e) {
    console.error("API CALL ERROR", e);
  }
};

export default apiService;

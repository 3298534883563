import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { _l } from "../../hooks/utilities";
import { getContactPermissions } from "../../actions/customer";
import { List } from "phosphor-react";

const AddRole = ({ show, handleClose, role, tableDataSource = [], setTableDataSource = () => {}, rolesData, setRolesData,handleDataRole = () => {} }) => {
  const [roleFrenchName, setRoleFrenchName] = useState("");
  const [roleEnglishName, setRoleEnglishName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [allPermissionList, setAllPermissionList] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");

  let contactPermissions = useSelector((state) => state.customer.contactPermissions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contactPermissions || !contactPermissions.length) {
      dispatch(getContactPermissions());
    }
    if (role && role.permissions && role.permissions.length) {
      setPermissions(role.permissions.map((permission) => {
        return {
          id: permission[0],
          checked: permission[1]
        }
      }));
    }
    else if (permissions && permissions.length) {
      setPermissions(permissions.map((permission) => {
        return {
          id: permission.id,
          checked: false
        }
      }));
    }

    if (role && (role.role||role.name)) {
      setRoleEnglishName(role.role||role.name);
      setRoleFrenchName(role.role||role.name);
    }
  }, [role]);

  useEffect(() => {
    if (contactPermissions) {
      setAllPermissionList(contactPermissions);
      if (!role || !role.permissions || !role.permissions.length) {
        setPermissions(contactPermissions.map((permission) => {
          return {
            id: permission.id,
            checked: false
          }
        }));
      }
    }
  }, [contactPermissions]);

  const prepareToast = (classType, header, message) => {
    setShowToast(true);
    setToastClass(classType);
    setToastHeader(header);
    setToastMessage(message);
  };

  const handleSaveRole = () => {
    if (!roleFrenchName || !roleFrenchName.trim().length) {
      prepareToast("unsucess", _l("l_error"), _l("l_please_add_french_role_name"));
    }
    else {
      let formattedPermissions = [];
      permissions.forEach((permission) => {
        formattedPermissions.push(Object.values(permission));
      });
      CustomerServices.addNewRole(roleFrenchName, roleFrenchName, formattedPermissions, role && role.id ? role.id : 0).then((res) => {
        if (role && role.id) {
          let pos = tableDataSource.findIndex((x) => x.id == role.id);
          let updatedRole = role;
          updatedRole.permissions = formattedPermissions;
          updatedRole.role = roleFrenchName;
          handleDataRole(updatedRole)
          setTableDataSource(Object.values({ ...tableDataSource, [pos]: updatedRole }));
          pos = rolesData && rolesData.findIndex((x) => x.id == role.id);
          if (setRolesData) { 
            setRolesData(Object.values({ ...rolesData, [pos]: updatedRole }));
          }
        }
        else {
          let newRole = {
            index: tableDataSource.length + 1,
            id: parseFloat(res.data.role_id),
            role: roleFrenchName,
            members: [],
            is_default: 0,
            permissions: formattedPermissions,
          }
          setTableDataSource(tableDataSource.concat(newRole));
          setRolesData(rolesData.concat(newRole));
        }
        handleClose();
      });
    }
  };

  return (
    <>
      {showToast ? <Toasts delay={2500} handler={() => { setShowToast(false) }} header={toastHeader} message={toastMessage} toastClass={toastClass} /> : <></>}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style with-min-height overflow-hidden"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_role")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main pt-0">
            <div className=" c-input-box position-relative custom-modal-section-sticky pt-3">
              <label className="input-label no-asterisk ">
              {_l("l_role_description")}
              </label>
                <div className="row">
                  <div className="col-xl-6 c-input-box pb-3 position-relative d-none">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_role_english")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 border-0"
                        type="text"
                        placeholder={`${_l("l_role_placeholder")}`}
                        aria-label="default input example"
                        value={roleEnglishName}
                        onChange={(e) => { setRoleEnglishName(e.target.value) }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-12 c-input-box pb-3 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l("l_role")}
                    </Form.Label>
                    <Form.Group className="comment-input-box">
                      <Form.Control
                        className="form-control height-45 border-0 light-theme-border-1"
                        type="text"
                        placeholder={`${_l("l_role_title_placeholder")}`}
                        aria-label="default input example"
                        value={roleFrenchName}
                        onChange={(e) => { setRoleFrenchName(e.target.value) }}
                      />
                    </Form.Group>
                  </div>
                </div>
            </div>
            <div className="comment-input-box d-none">
              <input
                className="form-control border-0 pe-2"
                type="text"
                placeholder={`${_l("l_search")}`}
                aria-label="default input example"
              />
            </div>
            <div className="topic-list-wrapper">
              <div className="row">
                <div className="role-box-wrapper pt-2 row">
                  {
                    allPermissionList && allPermissionList.length
                      ?
                      allPermissionList.map((permission, index) => {
                        return (
                          <div className="col-xl-6">
                            <div key={index} className="topic-main-box ">
                              <div className="hr_sepretr left-25">
                                <div className=" py-3  d-flex align-items-center justify-content-between">
                                  <div className="topic-left-title">
                                  <List size={18} className="c-icons me-2" weight="light" />
                                    <span>{_l(`l_${permission.short_name}`)}</span>
                                  </div>
                                  <div className="topic-switch">
                                    <div className="form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={permissions.find(x => x.id === permission.id) && permissions.find(x => x.id === permission.id).checked}
                                        onChange={(e) => {
                                          const pos = permissions.findIndex(x => x.id === permission.id);
                                          setPermissions(Object.values({
                                            ...permissions, [pos]: {
                                              id: permission.id,
                                              checked: e.target.checked
                                            }
                                          }))
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      :
                      <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={handleSaveRole}>{_l('l_save')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddRole;

import React from "react";
import LoaderVideo from "../../assets/images/myr.ai-loader.gif";
import { _l } from "../../hooks/utilities";



const FullScreenLoader = ({}) => {
  return (
    <>
      <div className="main-loader-wrapper full-screen">
          <div className="vw-100 vh-100 d-flex">
            <div className="loader-video">
            <img src={LoaderVideo} alt={LoaderVideo} />
              <div className="loader-text c-font f-18">{_l("l_please_wait")}</div>
            </div>
          </div>
      </div>
    </>
  );
};

export default FullScreenLoader;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import $ from "jquery";
import ChatServices from "../../services/chat-services";
import LeftChannelList from "./LeftChannelList";
import RightChatBoard from "./RightChatBoard";
import { _l } from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import ChatGroupCreationModal from "../Modals/ChatGroupCreationModal";
import CustomerServices from "../../services/customer-services";

import {
  fetchMessages,
  groupOut,
  groupIn,
  updateUserOnline,
  updateUserOffline,
  updateNewMessage,
  updateUnreadMsgCount,
  fetchUnreadMsgCount,
  updateReadAllGrpMsgBy,
  updateGeneralMsgCount,
} from "../../actions/chat";
import { setGeneralUnreadCount,  setSpaceNotificationCount, setVirtualSpaceNotificationCount } from "../../actions/customer";

import {
  ATTACH_BASE_URL,
  USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";

const ChatPage = (
  {
    pageName,
    chatAIgroupdeatils,
    loder,
    setLoder,
    setshowDate,
    selectStartDate,
    msgloader
  }) => {
    const {
      groupList,
      messageList,
      otherUserStatus,
      userOnline,
      userOffline,
      newMessage,
      readAllGrpMsgBy,
      viewGroupList,
      openGeneralChatGroup,
    } = useSelector((state) => state.chat);
  const { selectedUserForChat,generalUnreadCount, globalView} = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [assignedAM, setAssignedAM] = useState({});
  const [groupDetails, setGroupDetails] = useState({});
  const [show, setShow] = useState(false);
  const [viewGroupDetails, setViewGroupDetails] = useState(false);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [loader, setLoader] = useState(false);
  const [groupMemberIds, setGroupMemberIds] = useState([]);
  const [isVirtualSpace, setIsVirtualSpace] = useState(localStorage.getItem("selectedVirtualSpace") ? true : false);
  const [searchStringLeftChannel, setSearchStringLeftChannel] = useState("");
  const [searchStringRightBoard, setSearchStringRightBoard] = useState("");
  const [mentionList, setMentionList] = useState([]);
  const [filteredGroupList, setFilteredGroupList] = useState([]);
  const [shareContacts, setShareContacts] = useState([]);
  const [id, setId] = useState('');

  var chatUserId = localStorage.getItem("chatUserId");
  var orgChatUserId = localStorage.getItem("orgChatUserId");
  var projectId = localStorage.getItem("selectedOffice") ? localStorage.getItem("selectedOffice") : JSON.parse(localStorage.getItem("selectedVirtualSpace")) && JSON.parse(localStorage.getItem("selectedVirtualSpace")).id;
  if (localStorage.getItem("isLiteVersion") == "true") {
    projectId = 0
  }
  var activeGroupId = localStorage.getItem("activeGroupId");
  const isLiteVersionChat = localStorage.getItem("generalChatForLiteVersion");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearLeftchannelsearch =()=>{
    setSearchStringLeftChannel("")
  }
  const clearRightBoardsearch =()=>{
    setSearchStringRightBoard("")
  }

  // ------------------------ Helper functions start ------------------------

  function getImageDimensions(image) {
    return new Promise((resolve, reject) => {
      image.onload = function (e) {
        const width = this.width;
        const height = this.height;
        resolve({ height, width });
      };
    });
  }

  function compressImage(image, scale, initalWidth, initalHeight) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");

      canvas.width = scale * initalWidth;
      canvas.height = scale * initalHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpg");
    });
  }

  async function readAndPreview(file) {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    var newName = timestamp + ".png";
    file.name = newName;
    var file = new File([file], file.name, { type: file.type });
    return file
  }

  async function previewImage(inputId, previewId = "", previewAttr = "src") {
    const imageInput = document.getElementById(inputId);
    const uploadedImage = imageInput.files[0];
    if (!uploadedImage) {
      return;
    }
    var name = uploadedImage.name;
    if (!/\.(jpe?g|png|jpg)$/.test(name)) {
      return dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_valid_file")));
    }
    const inputPreview = document.getElementById("input-preview");
    inputPreview.src = URL.createObjectURL(uploadedImage);

    const { height, width } = await getImageDimensions(inputPreview);

    const MAX_WIDTH = 500;
    const MAX_HEIGHT = 500;

    const widthRatioBlob = await compressImage(
      inputPreview,
      MAX_WIDTH / width,
      width,
      height
    );
    const heightRatioBlob = await compressImage(
      inputPreview,
      MAX_HEIGHT / height,
      width,
      height
    );
    const compressedBlob =
      widthRatioBlob.size > heightRatioBlob.size
        ? heightRatioBlob
        : widthRatioBlob;

    const outputPreview = document.getElementById("output-preview");
    outputPreview.src = URL.createObjectURL(compressedBlob);
    if(previewId != ""){
      const previewImage = document.getElementById(previewId);
      if(previewAttr == "style"){
        previewImage.style.backgroundImage = "url('"+URL.createObjectURL(compressedBlob)+"')";
      }else{
        previewImage.src = URL.createObjectURL(compressedBlob);
      }
    }

    const optimalBlob = compressedBlob.size < uploadedImage.size ? compressedBlob : uploadedImage;
    compressedBlob.name = name;
    URL.revokeObjectURL(inputPreview);
    URL.revokeObjectURL(outputPreview);
    return readAndPreview(compressedBlob);
  }

  // ------------------------ Helper functions end ------------------------


  const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  useEffect(() => {
    if (chatUserId != "" && chatUserId != null) {
      activeGroupId = localStorage.getItem("activeGroupId");
      if (activeGroupId != "" && activeGroupId != undefined) {
        var socketData = {
          groupId: activeGroupId,
          userId: chatUserId,
        };
        if (globalView == "AichatView") {
          dispatch(groupIn(socketData));
        }else{
          dispatch(groupOut(socketData));
        }
      }
      activeGroupId = "";
      $('a[id^="group_card_unread_count_display_"]').removeClass("active");
      if (localStorage.getItem('user_type') == "staff" && !localStorage.getItem("selectedVirtualSpace") && (localStorage.getItem("isLiteVersion") == "false")) {
        
      } else {
        chatUserId = orgChatUserId;
        localStorage.setItem("chatUserId", orgChatUserId);
      }
      if (projectId > 0) {
        dispatch(fetchUnreadMsgCount(chatUserId,localStorage.getItem("isLiteVersion") == "true" || projectId == null ? 0 : projectId));
      }
    }
    setIsVirtualSpace(localStorage.getItem("selectedVirtualSpace") ? true : false);
    CustomerServices.getContactsByClient().then((res) => {
      if(res.status){
        setShareContacts(res.data);
      }
    });
  }, [projectId]);


  if (!$.isEmptyObject(newMessage)) {
    var msgGroupId = newMessage.group;
    var lastMessage = newMessage.lastMessage;
    if (newMessage.user != chatUserId) {
      lastMessage =
        capitalize(newMessage.senderName.split(" ")[0]) + ": " + lastMessage;
    }
    $("#group_last_msg_" + msgGroupId).html(lastMessage);
    $("#group_last_msg_date_" + msgGroupId).text(
      moment(newMessage.createdAt).format("MMM D, YYYY")
    );
    dispatch(updateNewMessage());
    var groupHTML = $("#chat_group_div_" + msgGroupId);
    $("#chat_group_div_" + msgGroupId).remove();
    $("#group_list").prepend(groupHTML);
    activeGroupId = localStorage.getItem("activeGroupId");
    if (msgGroupId != activeGroupId) {
      $("#group_card_unread_count_display_" + msgGroupId).addClass(
        "with-unread-count"
      );
      $("#group_card_unread_count_" + msgGroupId).text(
        parseInt($("#group_card_unread_count_" + msgGroupId).text()) + 1
      );
      dispatch(updateUnreadMsgCount(1));
      if(newMessage.groupType == 4){
        dispatch(updateGeneralMsgCount(1));
      }
    }
  }

  if (!$.isEmptyObject(userOnline)) {
    var otherUserId = userOnline.userId;
    $("#other_user_card_status_" + otherUserId).removeClass("bg-warning");
    $("#other_user_card_status_" + otherUserId).addClass("done");
    if(!$.isEmptyObject(groupDetails) && groupDetails.members && groupDetails.members.length > 0){
      var groupDetailsData = groupDetails;
      groupDetails.members.map((item, i) => {
        if(item._id == userOnline.userId){
          groupDetailsData.members[i].isOnline = "1";
        }
      });
      setGroupDetails(groupDetailsData);
    }
    var currentOtherUserId = localStorage.getItem("otherUserId");
    if (otherUserId == currentOtherUserId) {
      $("#other_user_status").removeClass("bg-warning");
      $("#other_user_status").addClass("done");
      $("#other_user_status_name").text("Online");
    }
    dispatch(updateUserOnline());
  }

  if (!$.isEmptyObject(userOffline)) {
    var otherUserId = userOffline.userId;
    $("#other_user_card_status_" + otherUserId).removeClass("done");
    $("#other_user_card_status_" + otherUserId).addClass("bg-warning");
    if(!$.isEmptyObject(groupDetails) && groupDetails.members && groupDetails.members.length > 0){
      var groupDetailsData = groupDetails;
      groupDetails.members.map((item, i) => {
        if(item._id == userOffline.userId){
          groupDetailsData.members[i].isOnline = "0";
        }
      });
      setGroupDetails(groupDetailsData);
    }
    var currentOtherUserId = localStorage.getItem("otherUserId");
    if (otherUserId == currentOtherUserId) {
      $("#other_user_status").removeClass("done");
      $("#other_user_status").addClass("bg-warning");
      $("#other_user_status_name").text("Offline");
    }
    dispatch(updateUserOffline());
  }
  const changeGroupId = (groupId, otherUserId, groupType) => {
    setId(otherUserId);
    activeGroupId = localStorage.getItem("activeGroupId");
    if (chatUserId != "" && activeGroupId != "" && activeGroupId != undefined) {
      var socketData = {
        groupId: activeGroupId,
        userId: chatUserId,
      };
      dispatch(groupOut(socketData));
    }
    localStorage.setItem("activeGroupId", groupId);
    localStorage.setItem("otherUserId", otherUserId);
    localStorage.setItem("isViewGroup", 0);
    $('#other_user_online_status_div, #group_img_div, #send_message_div').show();
    var otherUserImg = $("#group_img_" + groupId).css("background-image");
    localStorage.setItem("otherUserImg", otherUserImg);
    localStorage.setItem("chatGroupType", groupType);
    if (chatUserId != "" && groupId != "" && groupId != undefined) {
      dispatch(fetchMessages(chatUserId, groupId, otherUserId, setLoader,1));
      var socketData = {
        groupId: groupId,
        userId: chatUserId,
      };
      dispatch(groupIn(socketData));
    }
    $("#group_card_unread_count_display_" + groupId).removeClass(
      "with-unread-count"
    );
    $('a[id^="group_card_unread_count_display_"]').removeClass("active");
    $("#group_card_unread_count_display_" + groupId).addClass("active");
    var unreadMsgCount = parseInt($("#group_card_unread_count_" + groupId).text());
    dispatch(updateUnreadMsgCount(-unreadMsgCount));
    if(groupType == 4){
      dispatch(updateGeneralMsgCount(-unreadMsgCount));
    }
    dispatch(setGeneralUnreadCount(parseInt(generalUnreadCount) - parseInt(unreadMsgCount)));
    dispatch(setSpaceNotificationCount(projectId, unreadMsgCount, -1, "chat"));
    dispatch(setVirtualSpaceNotificationCount(projectId, unreadMsgCount, -1, "chat"));
    $("#group_card_unread_count_" + groupId).text(0);
    ChatServices.getGroupDetails(chatUserId, groupId).then((res) => {
      if (res.success) {
        setGroupDetails(res.result);
        localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
      }else{
        dispatch(showMessage("unsucess", _l("l_error"),res.message));
      }
    });
  };

  if (!$.isEmptyObject(readAllGrpMsgBy)) {
    var otherUserId = localStorage.getItem("otherUserId");
    var activeGroupId = localStorage.getItem("activeGroupId");
    if (
      readAllGrpMsgBy.user == otherUserId &&
      readAllGrpMsgBy.group == activeGroupId
    ) {
      $(".delivered-status-icon").removeClass("delivered-single delivered");
      $(".delivered-status-icon").addClass("message-read");
    }
    dispatch(updateReadAllGrpMsgBy());
  }

  const openViewGroup = (groupId, userId1, userId2) => {
    activeGroupId = localStorage.getItem("activeGroupId");
    if (chatUserId != "" && activeGroupId != "" && activeGroupId != undefined) {
      var socketData = {
        groupId: activeGroupId,
        userId: chatUserId,
      };
      dispatch(groupOut(socketData));
    }
    localStorage.setItem("activeGroupId", groupId);
    localStorage.setItem("isViewGroup", 1);
    localStorage.setItem("viewGroupMember1", userId1);
    $('#other_user_online_status_div, #group_img_div, #send_message_div').hide();
    $('a[id^="group_card_unread_count_display_"]').removeClass("active");
    $("#group_card_unread_count_display_" + groupId).addClass("active");
    dispatch(fetchMessages(userId1, groupId, userId2, setLoader,1));
  }


  useEffect(() => {
    if(groupDetails.members){
      setGroupMemberIds(groupDetails.members.map((val) => val._id));
      setIsGroupAdmin(groupDetails.type == 2 && groupDetails.admin == chatUserId ? true : false);
      setMentionList(
        groupDetails.members.filter((item) => item._id != chatUserId).map((item) => {
          return {
            id: item._id,
            display: item.fname+" "+item.lname,
            image: item.displayPicture != "" ? ATTACH_BASE_URL+item.displayPicture : USR_IMG_PLACEHOLDER
          };
        })
      );
    }
  }, [groupDetails]);

  useEffect(() => {
    groupList && groupList.sort(function (a, b) {
      if (a.type == 4) return -1;
      return 0;
    })
    setFilteredGroupList(groupList);
  }, [groupList]);
  useEffect(() => {
    if (selectedUserForChat && Object.keys(selectedUserForChat).length > 0 && selectedUserForChat.groupId && selectedUserForChat.chatUserId) {
      setTimeout(() => {
        $("#group_card_unread_count_display_" + selectedUserForChat.groupId).addClass("active");
        changeGroupId(selectedUserForChat.groupId, selectedUserForChat.chatUserId, 1);
      }, 500); 
    }
  },[selectedUserForChat])

  
  return (
    <div className={`${pageName != "ChatAi" ? "h-100":"comman_vertical_btn_h scroll_60 border-0"} ${openGeneralChatGroup || (selectedUserForChat && Object.keys(selectedUserForChat).length > 0 && isLiteVersionChat == "true") ? " overflow-hiiden-auto": ""} comman-contnet-wrapper chat-main`}>
      <div className="d-flex h-100">
        {pageName != "ChatAi" ? <LeftChannelList 
          groupList={filteredGroupList} 
          changeGroupId={changeGroupId} 
          assignedAM={assignedAM} 
          viewGroupList={viewGroupList} 
          openViewGroup={openViewGroup} 
          isVirtualSpace={isVirtualSpace} 
          groupDetails={groupDetails}
          setShow={setShow}
          setViewGroupDetails={setViewGroupDetails}
          searchStringLeftChannel={searchStringLeftChannel}
          setSearchStringLeftChannel={setSearchStringLeftChannel}
          clearRightBoardsearch={clearRightBoardsearch}
        /> : <></>}
        {activeGroupId != "" && activeGroupId != undefined && activeGroupId != 0 ? (
          <RightChatBoard
            messageList={messageList}
            otherUserStatus={otherUserStatus}
            isVirtualSpace={isVirtualSpace}
            groupDetails={groupDetails}
            setShow={setShow}
            setViewGroupDetails={setViewGroupDetails}
            isGroupAdmin={isGroupAdmin}
            searchStringRightBoard={searchStringRightBoard}
            setSearchStringRightBoard={setSearchStringRightBoard}
            clearLeftchannelsearch={clearLeftchannelsearch}
            mentionList={mentionList}
            shareContacts={shareContacts}
            chatUserId={id}
            loader={loder}
            pageName={pageName}
            chatAIgroupdeatils={chatAIgroupdeatils}
            setLoder={setLoder}
            setshowDate={setshowDate}
            selectStartDate={selectStartDate}
            msgloader={msgloader}
          />
        ) : (
          <></>
        )}
      </div>
      <ChatGroupCreationModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        groupList={groupList}
        groupDetails={groupDetails}
        viewGroupDetails={viewGroupDetails}
        isGroupAdmin={isGroupAdmin}
        groupMemberIds={groupMemberIds}
        setGroupMemberIds={setGroupMemberIds}
        previewImage={previewImage}
      />
      <img id="input-preview" style={{ display: "none" }} alt="" />
      <img id="output-preview" style={{ display: "none" }} alt="" />
    </div>
  );
};

export default ChatPage;

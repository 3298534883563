
import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import { _l } from "../../../hooks/utilities";
import MapPinLineIcon from "../../../assets/icons/MapPinLineIcon";
import ArVrIcon from "../../../assets/icons/ArVrIcon";
import MapTrifoldIcon from "../../../assets/icons/MapTrifoldIcon"
 
import { CaretRight} from "phosphor-react";
import $ from "jquery";
import CommanSmallLoader from "../../Loader/CommanSmallLoader";
import { isLoadingData } from "../../../hooks/loader_helper";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";

const SpaceDocumentCanvas = ({ data }) => {
  const [openGallery, setOpenGallery] = useState(false);
  const [extention, setExtention] = useState('');
  const [documentDetail, setDocumentDetail] = useState({});
  const [documentAttachments, setDocumentAttachments] = useState({});
  const [selectedTab, setSelectedTab] = useState("Map-View");
  const [defaultAttachments, setDefaultAttachments] = useState([]);
  useEffect(() => {
    if(data && data.attachments && data.attachments.length > 0){
      setDocumentDetail(data.attachments[0]);
      setDocumentAttachments(data.attachments);
      const arr = data.attachments.filter((df) => df.file_name.split(".").slice(-1)[0] == "jpg" || df.file_name.split(".").slice(-1)[0] == "png" || df.file_name.split(".").slice(-1)[0] == "jpeg")
      setDefaultAttachments(arr);
    }else{
      setDocumentDetail({});
      setDocumentAttachments([]);
    }
  }, [data])

  useEffect(() => {
    if (documentDetail && documentDetail.file_url) {
      const name = documentDetail.file_url.split("/").slice(-1)[0]
      setExtention(name.split(".").slice(-1)[0]);
    }
    if (documentDetail && documentDetail.length == 0) {
      setExtention("default");
    }
  }, [documentDetail])
  const setAttchmentOnTop = (id) => {
    const targetAttchement = defaultAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = defaultAttachments.filter(
      (attachment) => attachment.id != id
    );
    setDefaultAttachments(targetAttchement.concat(otherAttachments));
  };
  return (
    <div className="comman-contnet-wrapper h_calc77 border-0">
      { defaultAttachments && defaultAttachments.length > 0 ?
        <Lightbox
          open={openGallery}
          close={() => setOpenGallery(false)}
          slides={defaultAttachments.map((src) => { return { src: src.file_url }; })}
        />
        : ""
      }
      {isLoadingData("space_document") ?
      <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian">
      <CommanSmallLoader />
      </div> : <></> }
      <div className="d-flex flex-wrap  h-100">
        { documentAttachments && documentAttachments.length > 0 ?
        <div className="left-content-part w-240 p-0 res-width-100 h-100  d-flex flex-column lightthemebg borderright">
          <div className="comman-content-scroll-wrapper overflow-hiiden-web">
            <div className="d-flex align-items-center p-2 border-bottom justify-content-between">
              <div className="title-fonts fw-semibold">{data && data.floor_name}</div>
            </div>
            <div className="comman-content-scroll comman-verticle-tab">
            { documentAttachments.map((thumb, index) => {
              return <li className="comman-list p-0 w-100">
                <a href="#/" key={index} className={`w-100 position-relative tab_link ${documentDetail.id == thumb.id ? "active" : ""} `}
                onClick={() => setDocumentDetail(thumb)}>
                  <div className="d-flex align-items-center text-truncate">
                    <div className="comman-list-left text-truncate flex-grow-1">
                      <div className="upload-image-preview">
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="comman-image-box h25w25 radius_3 upload-image">
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                              style={{
                                backgroundImage: `url('${thumb.file_url}')`,
                              }}
                            ></div>
                          </div>
                          <div class="image-text w100minus30 ps-3 text-truncate">
                            <div class="text-truncate c-font f-13"
                            
                               
                            >{thumb.file_name}</div>
                            <div class="text-truncate c-font f-11 color-white-60 "></div>
                             
                          </div>
                        </div>
                      </div>
                    </div>  
                    <div className="comman-list-right">
                      <CaretRight />
                    </div>
                  </div>
                </a>
              </li>
            })} 
            </div>
          </div>
        </div>
        : <></>
        }  
        <div className="right-content-part width-calc-240 h-100 ps-2">
          <div className="comman-main-box h-100 p-2 d-flex flex-column m-0 pe-0">
            <div className="myspace-fixed-box d-none">
              <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "Map-View" ? "active" : ""}`} onClick={() => { setSelectedTab("Map-View"); }}>

                <MapPinLineIcon className="HW20 path-fill-transparent" />
              </a>
              <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "AR-View" ? "active" : ""}`} onClick={() => {
                setSelectedTab("AR-View");
              }}>

                <ArVrIcon className="HW20 path-fill-transparent" />
              </a>
              <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "ThreeDScan-View" ? "active" : ""}`} onClick={() => { setSelectedTab("ThreeDScan-View"); }}>

                <MapTrifoldIcon className="HW20" />
              </a>
            </div>

            { documentDetail && !$.isEmptyObject(documentDetail) ?
              extention == "glb" ?
              (<model-viewer
                id="glb"
                alt="Space"
                src={documentDetail.file_url}
                camera-controls
                shadow-intensity="1"
                disable-tap
                ar
                environment-image=""
                poster=""
                crossorigin="anonymous"
                touch-action="none"
              ></model-viewer>)
            
              : extention == "pdf" ?
              <iframe src={documentDetail.file_url} className="h-100 w-100" />
              
              : extention == "jpg" || extention == "png" || extention == "jpeg" ?
                  <div className="h-100 w-100 bg-style-contain"
                    onClick={() => { 
                      setAttchmentOnTop(documentDetail.id)
                      setOpenGallery(true); }}
                    type="button"
                    style={{
                      backgroundImage: `url('${documentDetail.file_url}')`,
                    }}
                  >
                  </div>
                : <></>
              : 
              <CommanPlaceholder imgType="no-documents" placeholderText = {_l("l_no_documents")} />
            }
            </div>
          </div>
        </div>
      </div>
  );
};
export default SpaceDocumentCanvas;

import React, { useEffect, useState } from "react";
import { _l, getFileType } from "../../hooks/utilities";
import { CaretDoubleDown, DotsNine } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
 
import { Spinner } from "react-bootstrap";
import ProviderListActionModal from "./ProviderListActionModal";
import DocumentModal from "../Modals/DocumentModal";
import jquery from "jquery";
import DocumentPreview from "../Documents/DocumentPreview";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import documentsServices from "../../services/documents-services";
import { getProviderListTableData } from "../../actions/customer";
import moment from "moment";

const DocumentTable = (data) => {
  const {  providerlistTableData } = useSelector((state) => state.customer);
  const [documentData, setdocumentData] = useState(
    data.data && data.data.length > 0 ? data.data : []
  );
  const [documentMiniModalData, setDocumentMiniModalData] = useState({});
  const [documentPage, setDocumentPage] = useState(1);
  const [openGallery, setOpenGallery] = useState(false);
  const [lightboxInputArray, setLightboxInputArray] = useState([]);
  const [loder, setLoder] = useState({
    documentLoader: false,
  });
  const loginUserRole = localStorage.getItem("contact_role")
    ? localStorage.getItem("contact_role")
    : 0;
  const userType = localStorage.getItem("user_type");

  const dispatch = useDispatch()

  const scroll = (status) => {
    let page;
    let taskStaus = status;
    if (status == 1) {
      page = documentPage + 1;
      setDocumentPage(page);
    }
    taskStaus = [status];
    if (status == 1) {
      setLoder({ ...loder, ["documentLoader"]: false });
    }
  };

  const documentModalopen = (item) => {
    switch (item.type) {
      case 'l_estimate':
      case 'l_subscription':
      case 'l_invoice':
        setDocumentMiniModalData({
          id: item.id ? item.id : 0,
          hash: item.hash ? item.hash : 0,
          type: item.type.slice(2),
          is_app_invoice : item.is_app_invoice ? item.is_app_invoice : 0
        });
        break;
      case 'company_document':
        setLightboxInputArray([{
          src : item.url,
          type: getFileType(item.url),
        }]);
        setOpenGallery(true);
        break;
      case 'topic_document':
        setDocumentMiniModalData({
          id: item.id ? item.id : 11,
          type: "documents",
          file_name : item.name ? item.name : '',
          image_url : item.url
        });
        break;
      default:
        break;
    }
  }
  const handleActionItems = (action) => { 
    let item = []
    switch (action.type) {
      case 'l_subscription':
        item = ["download"];
        if (userType === "contact" && action.status == "active" && [1, 2].includes(Number(loginUserRole))) {
          if (action.payment_mode == "stripe") {
            item = ["l_cancel_immediately", "l_cancel__at_period_end"].concat(item);
          }else{
            item = ["l_cancel"].concat(item);
          }
        }
        break;
      case 'l_invoice':
      case 'l_estimate':
        item = ["download"];
        break;
      case 'company_document':
        item = ["download"];
        break;
      case 'topic_document':
        item = ["download"];
        break;
      default:
        break;
    } 
    return item;
  }

  const handlerCancelItems = (type, data) => {
    switch (type) {
      case 'l_cancel_immediately':
        cancelSubscription(data, "immediately");
        break;
      case 'l_cancel__at_period_end':
        cancelSubscription(data, "at_period_end");
        break;
      case 'l_cancel':
        cancelSubscription(data);
        break;
      default:
        break;
    }
  }

  const cancelSubscription = (subscription, type) => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_cancel_subscription"), _l("l_yes"), _l("l_no"), () => {}, async() => {
      try {
        // setIsFetchingData(true);
        dispatch(toastCloseAction());
        const response = await documentsServices.cancelCustomerSubscription(subscription.id, type);
        if(response.status)
        {
          if(type !== "at_period_end")
          {
            let documentUpdates = {
              ...providerlistTableData,
              ["document"]: providerlistTableData["document"].map((w) => {
                if (w.id == subscription.id) {
                  w.status = "canceled";
                }
                return w;
              }),
            };
            dispatch(getProviderListTableData(documentUpdates))
            showMessage("l_subscription_cancelled");
          }
          else {
            showMessage("l_subscription_cancellation_scheduled");
          }
        }
      }
      catch(e)
      {
        console.error(e);
      }
      finally {
        // setIsFetchingData(false);
      }
    }))
  }
  
  useEffect(() => {
    setdocumentData(providerlistTableData.document)
  }, [JSON.stringify(providerlistTableData)])

  return (
    <>
    <table className="dataTable text-center tablewithborder">
      <thead className="comman-white-box-shadow">
        <tr>
          <th style={{ width: "20%" }} className="text-start">
            {_l("l_name")}
          </th>
          <th style={{ width: "3%" }} className="text-start">
            {_l("l_type")}
          </th>
          <th style={{ width: "3%" }} className="text-start">
            {_l("l_format")}
          </th>
          <th style={{ width: "8%" }} className="text-start">
            {_l("l_assignee")}
          </th>
          <th style={{ width: "3%" }} className="text-center">
            {_l("l_visibility")}
          </th>
          <th style={{ width: "4%" }} className="text-center">
            {_l("l_date")}
          </th>
          <th style={{ width: "3%" }} className="text-center">
            {_l("l_action")}
          </th>
        </tr>
      </thead>
      <tbody style={{ verticalAlign: "middle" }}>
        {documentData && documentData.length > 0 ? (
          <>
            {documentData.slice(
                  0,
                  documentPage > 1 ? (documentPage - 1) * 10 + 10 : documentPage * 10
                ).map((document) => {
                  const actionItems = handleActionItems(document);
              return (
                <>
                  <tr>
                    <td>
                      <div className="name-with-progress max-w300" 
                      onClick={() => {
                        documentModalopen(document)
                      }}>
                        <div className="d-flex align-items-center">
                          <a
                            href="#/"
                            className="h35w35 comman-round-box with-bg rounded-circle bg-white-05"
                          >
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                              style={{
                                // backgroundImage: `url(${document.url})`
                                backgroundImage: `url('https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                              }}
                            ></div>
                          </a>
                          <div className="ps-2 w100minus70 text-start flex-grow-1">
                            <div className="d-flex flex-column">
                              <a
                                href="#/"
                                className="title-fonts text-truncate text-start max-width-200 fw-semibold"
                              >
                                {document.name}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="title-fonts text-truncate text-start max-w150px">
                      {_l(document.display_type)}
                    </td>
                    <td className="title-fonts text-truncate text-start">
                      {document.format}
                    </td>
                    <td className="title-fonts text-start max-w150px text-truncate">{document.assignee}</td>
                    <td className="title-fonts text-center">{document.visibility}</td>
                    <td className="title-fonts text-center text-truncate">{moment(document.date).format("DD/MM/yyyy")}</td>
                    <td>
                      <div className="align-items-center comman_action_icon d-flex justify-content-center">
                        <UncontrolledDropdown setActiveFromChild={true}>
                          <DropdownToggle className="border-0 p-0">
                            <a
                              href="#/"
                              className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                            >
                              <DotsNine size={18} className="c-icons " weight="light" />
                            </a>
                          </DropdownToggle> 
                          <DropdownItem toggle={false} className="p-0">
                          <ProviderListActionModal 
                            actionList={actionItems} 
                            handleDownload={()=>{
                              window.open(document.url)
                            }}
                            handleCancel ={handlerCancelItems}
                            data={document}
                            />
                           </DropdownItem>
                        </UncontrolledDropdown>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
               <tr>
                {(documentData.length >= (documentPage - 1) * 10 + 10 &&
                  documentData.length != (documentPage - 1) * 10 + 10) ||
                loder.documentLoader ? (
                  <>
                    <td className="border-0" colSpan={12}>
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setLoder({ ...loder, ["documentLoader"]: true });
                          scroll(1);
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {documentPage > 1
                              ? documentData.length - ((documentPage - 1) * 10 + 10)
                              : documentData.length - 10}
                            )
                          </span>
                          <CaretDoubleDown
                            size={12}
                            className="c-icons mx-2"
                            weight="light"
                          />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.documentLoader ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
          </>
        ) : (
          <>
            {" "}
            <tr className="">
              <td className="border-0" colSpan={7}>
                <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                  <div className="d-flex flex-column align-items-center">
                    <CommanPlaceholder
                      imgType="request"
                      placeholderText={_l("l_no_data")}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
    {documentMiniModalData.id > 0   ? 
      <DocumentModal
        handleClose={() => {
          setDocumentMiniModalData({});
        }}
        fromTopicSetting={false}
        documentData={documentMiniModalData}
        show={
          documentMiniModalData.id > 0 ||
          (documentMiniModalData.id && documentMiniModalData.id.length)
        }
        type={documentMiniModalData.type}
      />
    : <></>}
    {openGallery && lightboxInputArray.length ? 
        <DocumentPreview
          open={openGallery}
          setOpen={setOpenGallery}
          slides={lightboxInputArray}
        />
      : 
      <></>
    }
  </>
  );
};

export default DocumentTable;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import BenchmarkServices from "../services/benchmark-services";
import { setProject } from "../actions/customer";
import { _l } from "../hooks/utilities";
import OnboardingHeader from "../components/SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../components/SpaceOnBoarding/OnboardingFooter";

const ViewDPGFProposal = () => {
  const [spaceList, setSpaceList] = useState([]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dpgfBenchmarkId = searchParams.get("dpgf-proposal-id");

  const dispatch = useDispatch();

  const getButtonText = (space) => {
    let buttonText = "";
    switch(space.group_proposal_status_id)
    {
      case "2":
      case "3":
        buttonText = "l_view";
        break;
      case "4":
      if(space.group_proposal_amount === "0.00")
      {
        buttonText = "l_add_amount";
      }
      else {
        buttonText = "l_update_amount";
      }
      break;
      default:
        buttonText = "";
    }
    return buttonText;
  };

  const SpaceCard = ({ space }) => {
    return (
      <div class="task-list-card list-view radius_3 no-hover">
        <div class="d-flex justify-content-between align-items-start  w-100">
          <div class="w-100">
            <div class="d-flex align-items-center justify-content-between res-flex-wrap-wrap ">
              <div class="left-premium-title text-truncate w100minus100per flex-grow-1">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center ">
                    <div class="title-fonts text-truncate task-list-name ps-0">
                      {space.office_title}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center c-font f-10 title-fonts pt-1">
                    <div class="d-flex align-items-center">
                      <div
                        class="color-white-60 text-truncate with_separator_10 ps-0 m-w150"
                         
                         
                         
                         
                         
                      >
                        {space.postal_address}
                      </div>
                      <div class="color-white-60  text-truncate with_separator_10">
                        {_l("l_superficy")} :{" "}
                        <span class="color-white text-truncate">
                          {space.superficies}
                        </span>
                      </div>
                      <div class="color-white-60 text-truncate with_separator_10">
                        {_l("l_desks")} :{" "}
                        <span class="color-white text-truncate">
                          {space.number_of_desk}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-premium-title">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center comman_action_icon">
                    <div
                      className={`badge h32wauto c-font f-12 rounded-pill c-text me-10px text-truncate  done`}
                    >
                      {_l(space.group_proposal_status)}
                    </div>
                    <a
                      href="#/"
                      class="btn btn-white-05 btn-sm-2 me-10px"
                      onClick={() => navigate(`/connect-provider?space=${space.project_id}&provider-type=proposal&id=${space.group_schedule_id}&dpgf-proposal-id=${dpgfBenchmarkId}`)}
                    >
                      {_l(getButtonText(space))}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let selectedSpace = localStorage.getItem("selectedOffice");

    if (!dpgfBenchmarkId || dpgfBenchmarkId <= 0) {
      navigate("/");
    } else {
      const getDPGFProposalInfo = async (id) => {
        try {
          const response = await BenchmarkServices.getBenchmarkGroupInfo(id);
          if (response && response.status && response.data.group_id > 0) {
            
            if (
              response.data.schedules &&
              response.data.schedules.length
              ) {
              let projects = response.data.schedules.filter((w) => w.proposal_id > 0).map((space) => {
                space.checked = true;
                space.is_disabled = space.schedule_id > 0;
                space.group_proposal_status = space.proposal_status_text;
                space.group_proposal_status_id = space.proposal_status;
                space.group_proposal_id = space.proposal_id;
                space.group_schedule_id = space.schedule_id;
                space.group_proposal_amount = space.proposal_amount;
                return {...space, ...space.project_details};
              });
              setSpaceList(projects);
            }
          } else {
            navigate(-1);
          }
        } catch (e) {
          console.error(e);
        }
      };

      localStorage.setItem("selectedOffice", 0);
      dispatch(setProject(0));
      dispatch(
        setOnboardingStepData({
          step: "space-onboarding",
        })
      );

      getDPGFProposalInfo(dpgfBenchmarkId);
    }

    return () => {
      localStorage.setItem("selectedOffice", selectedSpace);
      dispatch(setProject(selectedSpace));
    };
  }, []);

  return (
    <div
      className={`onboarding-wrapper-main d-flex m-auto h-100 flex-column onboarding-with-bg`}
    >
      <OnboardingHeader
        title={"l_dpgf_proposal_title"}
        // description={"l_dpgf_proposal_description"}
      />
      {spaceList.map((space, i) => {
        return <SpaceCard space={space} key={i} />;
      })}
      <OnboardingFooter />
    </div>
  );
};

export default ViewDPGFProposal;

import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Toasts from "../Toasts/Toasts";
import CustomerServices from "../../services/customer-services";
import { _l, getDatePickerLocale } from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { getDefaultTopics, updatedCompanyEmployeeList } from "../../actions/customer";
import AddEmployeeDocuments from "./AddEmployeeDocuments";
import PhoneInput from "react-phone-input-2";

const AddEmployee = ({
  projectId,
  show,
  handleClose,
  updateDataSource,
  selectedRecord, topicType
}) => {
  const defaultOptionState = [];
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState({
    value: "+33",
    label: "+33",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const companyEmployeeList = useSelector((state) => state.customer.getCompanyEmployee);
  const spaces = useSelector((state) => state.customer.spaces);
  const [employeePosition, setEmployeePosition] = useState('');
  const [employeeType, setEmployeeType] = useState(defaultOptionState);
  const [employeeRole, setEmployeeRole] = useState("");
  const [location, setLocation] = useState("");
  const [selectedSpace, setSelectedSpace] = useState([]);
  const [spaceListing, setSpaceListing] = useState([]);
  const [profileTabAccess, setProfileTabAccess] = useState(false);
  const [deskBookingAccess, setDeskBookingAccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [loder, setLoder] = useState(false);
  const [employeeRoleList, setEmployeeRoleList] = useState([]);
  const [imagePreviews, setImagePreviews] = useState({
    l_profile_image_upload: {previewArray: [{url: ""}]},
    l_id_card_upload: {previewArray: [{url: ""}]},
    l_company_id_upload: {previewArray: [{url: ""}]},
    l_payment_upload: {previewArray: [{url: ""}]},
    l_insurance_upload: {previewArray: [{url: ""}]},
  });
  const userType = localStorage.getItem("user_type");
  const contact_role = localStorage.getItem("contact_role");
  const admin = userType == "staff" ? localStorage.getItem("admin") : contact_role == 1;
  const [numberFormate, setNumberFormate] = useState('');
  const [numberlength, setNumberLength]= useState({max : 10, min : 9});
  const [countryCodeReact, setCountryCodeReact] = useState();

  useEffect(() => {
    CustomerServices.getCountryCodes().then((res) => {
      if (res.status == '1') {
        setCountryCodes(
          res.data.map((code) => {
            return {
              value: code.code_value,
              label: code.code_value,

            };
          })
        );
      }
    });
    CustomerServices.getRoles().then((res) => {
      if (res.status == 1) {
        setEmployeeRoleList(
          res.data.map((role) => {
            return {
              value: role.roleid,
              label: role.name,

            };
          })
        );
      } else {
        dispatch(showMessage('unsucess', _l('l_error'), res.message ? res.message : ''));
        return false;
      }

    })
  }, [])

  useEffect(() => {
    if (spaces && spaces.length) {
      setSpaceListing(spaces.map((space) => {return{ value:space.project_id, label:space.office_title}}))
    }
  },[spaces])

  useEffect(() => {
    if (selectedRecord && selectedRecord.id) {
      setFirstName(selectedRecord.first_name);
      setLastName(selectedRecord.last_name);
      let contryCodeObject = { value: selectedRecord.countrycode && selectedRecord.countrycode != '' ? selectedRecord.countrycode : '+33', label: selectedRecord.countrycode && selectedRecord.countrycode != '' ? selectedRecord.countrycode : '+33' };
      setCountryCode(contryCodeObject);
      setMobileNumber(selectedRecord.phonenumber.replace(countryCode.value, ""));
      setEmail(selectedRecord.email);
      setEmployeeRole(selectedRecord.role_detail && selectedRecord.role_detail.role_id != '' ? {
        value: selectedRecord.role_detail.role_id,
        label: selectedRecord.role_detail.role_name,
      } : '');
      if (selectedRecord.assigned_project_ids && selectedRecord.assigned_project_ids.length > 0) {
        let spaceArr = []
        for (let i = 0; i < selectedRecord.assigned_project_ids.length; i++) {
          for (let j = 0; j < spaceListing.length; j++) {
            if (selectedRecord.assigned_project_ids[i] == spaceListing[j].value) {
              spaceArr.push(spaceListing[j])
            }
          }
        }
        setSelectedSpace(spaceArr);
      }
      setEmployeePosition(
        selectedRecord.title ? selectedRecord.title : ''
      );
      setImagePreviews({
        l_profile_image_upload: {previewArray: [{url: selectedRecord.profile_image, name: selectedRecord.profile_image ? selectedRecord.profile_image.split("/").pop() : "" }]},
        l_id_card_upload: {previewArray: [{url: selectedRecord.id_card, name: selectedRecord.id_card ? selectedRecord.id_card.split("/").pop() : "" }]},
        l_company_id_upload: {previewArray: [{url: selectedRecord.company_id, name: selectedRecord.company_id ? selectedRecord.company_id.split("/").pop() : "" }]},
        l_payment_upload: {previewArray: [{url: selectedRecord.payment, name: selectedRecord.payment ? selectedRecord.payment.split("/").pop() : "" }]},
        l_insurance_upload: {previewArray: [{url: selectedRecord.insurance, name: selectedRecord.insurance ? selectedRecord.insurance.split("/").pop() : "" }]}
      })
    }
    else {
      setSelectedSpace(spaceListing.filter((x) => x.value == projectId));
    }
  }, [JSON.stringify(selectedRecord), countryCodes]);

  const autoCompleteRef = useRef();
  const addressRef = useRef();
  const addressOptions = {
    componentRestrictions: { country: 'fr' },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };
  useEffect(() => {
    if (
      location &&
      location.trim().length &&
      addressRef.current
    ) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          addressRef.current,
          addressOptions
        ).addListener("place_changed", () => {
          setLocation(addressRef.current.value);
        });
      } catch (e) {
        console.log(e);
      }
    }


  }, [location]);

  const employeeRoleListing = admin ? [
    {
      value: 1,
      label: _l('l_admin'),
    },
    {
      value: 2,
      label: _l('l_manager'),
    },
    {
      value: 3,
      label: _l('l_employee'),
    },
  ] : [
    {
      value: 2,
      label: _l('l_manager'),
    },
    {
      value: 3,
      label: _l('l_employee'),
    }
  ];

  const validateInputFields = () => {
    const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!email || !email.trim().length || !pattern.test(email)) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_valid_email_address')));
      return false;
    } else if (employeeRole == "") {
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_select_employee_role')));
      return false;
    } else if (employeeRole.value != 1 && selectedSpace.length == 0) {
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_select_space')));
      return false;
    }
    else if ((mobileNumber.slice(countryCodeReact.length - 1).trim() != '' &&
    (numberlength.max != mobileNumber.length && numberlength.min != mobileNumber.length)) && (topicType == 'task_topic' || topicType == "CreateScheduleNew")) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_valid_mobile_number')));
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (validateInputFields()) {
      setLoder(true)
      let tabs = [];
      if (profileTabAccess) {
        tabs.push("userprofile");
      }
      if (deskBookingAccess) {
        tabs.push("desk");
      }
      let phoneNumbers = mobileNumber.slice(countryCodeReact.length - 1) 
      if (topicType == 'myr_staff_task' || topicType == 'task_topic') {
        CustomerServices.addMyrStaff(
          firstName,
          lastName,
          email,
          password,
          mobileNumber != '' ? `${countryCode.label} ${mobileNumber}` : '',
          employeeRole.value,
          '',
          '',
          employeePosition,
          '',
          '',
          0,
          0
        ).then((res) => {
          setLoder(false)
          if (res.status && res.data) {
            let newEmployee = {
              id: res.data.filter_key,
              index: 0,
              full_name: `${firstName} ${lastName}`,
              team_name: "",
              role: employeeRole.label,
              role_id: employeeRole.value,
              projects: [],
              image: res.data.image ? res.data.image : '',
              email
            };
            updateDataSource(newEmployee);
            handleClose();
            dispatch(getDefaultTopics(projectId, "", "", ""));
            dispatch(showMessage('sucess', _l('l_success'), res.message ? res.message : _l('l_something_went_wrong')));
          }
        });
      } else {
        if (selectedRecord && selectedRecord.id) {
          CustomerServices.editCompanyEmployees(
            firstName,
            lastName,
            email,
            mobileNumber != '' ? `${countryCode.label} ${mobileNumber}` : '',
            employeeRole.value ? employeeRole.value : '',
            0,
            0,
            employeePosition,
            '',
            '',
            0,
            0,
            projectId,
            selectedRecord.id, selectedRecord.staff_id, password,
            employeeRole.label == "Admin" ? spaces : selectedSpace
          ).then((res) => {
            setLoder(false)
            if (res.status) {
              let modifiedData = {
                id: selectedRecord.id,
                full_name: `${firstName} ${lastName}`,
                role: employeeRole.label,
                role_id: employeeRole.value,
                profile_image: imagePreviews.l_profile_image_upload.previewArray[0].url,
                id_card: imagePreviews.l_id_card_upload.previewArray[0].url,
                company_id: imagePreviews.l_company_id_upload.previewArray[0].url,
                payment: imagePreviews.l_payment_upload.previewArray[0].url,
                insurance: imagePreviews.l_insurance_upload.previewArray[0].url,
                projects: employeeRole.label == "Admin" ? spaces : selectedSpace,
                email
              };
              updateDataSource(modifiedData, true);
              handleClose();
              dispatch(getDefaultTopics(projectId, "", "", ""));
              dispatch(showMessage('sucess', _l('l_success'), res.message ? _l(res.message) : _l('l_something_went_wrong')));
            }else{
              dispatch(showMessage("unsucess", _l("l_error"), res.message));
            }
          });
        } else {
          const is_pilot_onboarding = 1;
          CustomerServices.addCompanyEmployee(
            firstName,
            lastName,
            email,
            password,
            topicType === "CreateScheduleNew" ? phoneNumbers != '' ? `${countryCodeReact} ${phoneNumbers}` : ''  :  mobileNumber != '' ? `${countryCode.label} ${mobileNumber}` : '',
            employeeRole.value,
            '',
            '',
            employeePosition,
            '',
            '',
            0,
            0,
            updateDataSource ? 0 : is_pilot_onboarding,
            imagePreviews,
            employeeRole.label == "Admin" ? spaces : selectedSpace
          ).then((res) => {
            setLoder(false)
            if (res.status) {
              let newEmployee = {
                id: res.data.employee_id,
                index: 0,
                full_name: `${firstName} ${lastName}`,
                team_name: "",
                role: employeeRole.label,
                role_id: employeeRole.value,
                projects: employeeRole.label == "Admin" ? spaces : selectedSpace,
                profile_image: imagePreviews.l_profile_image_upload.previewArray[0].url || res.data.profile_image,
                id_card: imagePreviews.l_id_card_upload.previewArray[0].url,
                company_id: imagePreviews.l_company_id_upload.previewArray[0].url,
                payment: imagePreviews.l_payment_upload.previewArray[0].url,
                insurance: imagePreviews.l_insurance_upload.previewArray[0].url,
                staff_id:res.data.staff_id,
                email
              };
              if (updateDataSource) {
                updateDataSource(newEmployee);
              } else {
                dispatch(updatedCompanyEmployeeList([...companyEmployeeList, {
                  id: res.data.employee_id,
                  index: 0,
                  full_name: `${firstName} ${lastName}`,
                  role_detail: { role_id: employeeRole.value, role_name: employeeRole.label },
                  projects: employeeRole.label == "Admin" ? spaces : selectedSpace,
                  staff_id:res.res.data.staff_id,
                }]))
              }
              handleClose();
              dispatch(getDefaultTopics(projectId, "", "", ""));
              dispatch(showMessage('sucess', _l('l_success'), res.message ? _l(res.message) : _l('l_something_went_wrong')));
            }else{
              dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    if (numberFormate.dialCode) {
      setCountryCodeReact(`+${numberFormate.dialCode}`)
        const dialcodeL = Number(numberFormate.dialCode.length);
        let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
        if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
            if (["49", "44"].includes(numberFormate.dialCode)) {
                setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
            }else{
                setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
            }
        }else{
            setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
        }
    }
  }, [numberFormate])

  return (
    <>
      {showToast ? (
        <Toasts
          delay={2500}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonHandler={() => {
            setShowToast(false);
          }}
          cancelButtonText={_l("l_cancel")}
          confirmButtonText={_l("l_delete")}
        />
      ) : (
        <></>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedRecord != "" ? _l('l_edit') : _l('l_add')} {_l('l_user')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
          <div className="c-font f-13 fw-semibold mb-2 title-fonts">{_l('l_basic_info')}</div>
            <div className="row ">
              <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                  <Form.Label className="input-label ">
                    {_l("l_email")}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_email_placeholder")}`}
                    value={email}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />

                </Form.Group>
                  <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                  <Form.Label className="input-label">
                    {_l('l_assign_role')}
                  </Form.Label>
                  <div className="d-flex flex-grow-1 align-items-center">
                    <Select
                      className="custom-select-menu w-100"
                      classNamePrefix="react-select"
                      placeholder={_l('l_select_role')}
                      options={topicType == 'myr_staff_task' || topicType == 'task_topic' ? employeeRoleList : employeeRoleListing}
                      closeMenuOnSelect={true}
                      value={employeeRole}
                      onChange={(value) => {
                        setEmployeeRole(value);
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group className={`c-input-box pb-4 position-relative ${topicType == 'myr_staff_task' || topicType == 'task_topic' ? "col-xl-12" : "col-xl-6"} `}>
                  <Form.Label className="input-label">
                    {_l('l_space_selection')}
                  </Form.Label>
                  <div className="d-flex flex-grow-1 align-items-center">
                    <Select
                      className="custom-select-menu w-100 drop-up"
                      classNamePrefix="react-select"
                      placeholder={ parseInt(employeeRole.value) == 1 ? _l("all_project_access") : _l('l_select_space_selection')}
                      options={spaceListing}
                      onChange={(e) => {
                        setSelectedSpace(e)
                      }}
                      defaultValue={spaceListing && spaceListing.filter(obj => selectedSpace.includes(s => s.value == obj.value))}
                      isMulti
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      isSearchable={true}
                      value={parseInt(employeeRole.value) == 1 ? [] : selectedSpace}
                      isDisabled={parseInt(employeeRole.value) == 1}
                    />
                  </div>
                </Form.Group>
                
            </div>
            <div className="border-bottom mb-3"></div>
                <div className="c-font f-13 fw-semibold mb-2 title-fonts">{_l('l_additional_information')}</div>
                <div className="row">
                <Form.Group className="col-xl-6 c-input-box pb-4 position-relative pe-3">
                    <Form.Label className="input-label no-asterisk">
                      {_l("l_first_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_first_name_placeholder")}`}
                      type="text"
                      name=""
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />

                  </Form.Group>
                  <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                    <Form.Label className="input-label no-asterisk">
                      {_l("l_last_name")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_last_name_placeholder")}`}
                      type="text"
                      name=""
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />

                  </Form.Group>
                    
                   <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                    <Form.Label className="input-label no-asterisk">
                      {_l('l_password')}
                    </Form.Label>
                    <Form.Control
                      placeholder="****"
                      type="password"
                      autoComplete="new-password"
                      name=""
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />

                  </Form.Group>
                  <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                    <Form.Label className="input-label no-asterisk">
                      {_l('l_confirm_password')}
                    </Form.Label>
                    <Form.Control
                      placeholder="****"
                      type="password"
                      autoComplete="new-password"
                      name=""
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />

                  </Form.Group>
                  
                  <Form.Group className="col-xl-6 c-input-box position-relative select-half-separator">
                    <Form.Label className="input-label no-asterisk no-asterisk">
                      {_l('l_mobile_number')}
                    </Form.Label>
                      {topicType == "CreateScheduleNew" ? 
                      <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                        <PhoneInput
                          autoFormat={true}
                          enableLongNumbers = {true}
                          countryCodeEditable={false}
                          disableCountryGuess={true}
                          searchStyle={{"border-color" : "red"}}
                          disableSearchIcon={true}
                          enableSearch={true}
                          localization={""}
                          country='fr'
                          preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                          name="mobile_number"
                          value={mobileNumber}
                          onChange={(phone, formattedValue) => {
                              setNumberFormate(formattedValue);
                              setMobileNumber(phone);
                          }}
                          isValid={(value) => {
                            if (value.length > numberlength.max) {
                              setMobileNumber(value.slice(0,numberlength.max));
                            } else {
                              return true;
                            }
                          }}
                        />
                        </div>
                      : <>
                    <div className="row">
                      <div
                        className="c_input_border drop_image col-3 col-md-3  col-xl-3 position-relative"
                        action="/action_page.php"
                      >
                        <Select
                          className="custom-select-menu scroll-height-30vh"
                          classNamePrefix="react-select"
                          placeholder={`${_l("l_region")}`}
                          options={countryCodes}
                          closeMenuOnSelect={true}
                          value={countryCode}
                          onChange={(value) => {
                            setCountryCode(value);
                          }}
                        />
                      </div>
                      <div className="col-9 col-md-9 col-xl-9 ps-md-0">
                        <Form.Control
                          placeholder={_l('l_mobile_number_placeholder')}
                          type="text"
                          name="phonenumber"
                          value={mobileNumber}
                          onChange={(e) => {
                            setMobileNumber(e.target.value);
                          }}
                        />

                      </div>
                    </div>
                      </>
                    }  
                  </Form.Group>
                  
              
                  
                  
              {
                topicType == 'myr_staff_task' || topicType == 'task_topic' ? <></> : <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l('l_position')}
                  </Form.Label>
                  <div className="d-flex flex-grow-1 align-items-center">
                    <Form.Control
                      placeholder={_l('l_emoployee_position')}
                      type="text"
                      autoComplete="new-password"
                      name=""
                      value={employeePosition}
                      onChange={(e) => {
                        setEmployeePosition(e.target.value);
                      }}
                    />
                  </div>
                </Form.Group>
              }


              


              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative d-none">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l('l_employee_type')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Form.Control
                    placeholder={_l('l_password_placeholder')}
                    type="text"
                    autoComplete="new-password"
                    name=""
                    value={employeeType}
                    onChange={(e) => {
                      setEmployeeType(e.target.value);
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative d-none">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l('l_location')}
                </Form.Label>
                <Form.Control
                  placeholder={_l('l_location_placeholder')}
                  type="text"
                  name=""
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  ref={addressRef}
                  value={location}
                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative d-none">
                <Form.Label className="input-label no-asterisk">
                  {_l('l_join_date')}
                </Form.Label>
                <div
                  className="custom-datepicker"
                  data-bs-toggle="tooltip"
                  title="Aug, 8 2022"
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                    <CalendarBlank size={18} className="c-icons" weight="light" />
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      disabledKeyboardNavigation
                      placeholderText={_l('l_select_start_date')}
                      locale={getDatePickerLocale()}
                      dateFormat="dd MMMM yyyy"
                      selectsStart
                      defaultMenuIsOpen
                      calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-4 d-none">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l('l_select_criteria')}
                </Form.Label>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="form-check with-span">
                      <div className="d-flex align-items-center">
                        <Form.Label className="form-check-label text_wrap w-auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={profileTabAccess}
                            onChange={(e) =>
                              setProfileTabAccess(e.target.checked)
                            }
                          />
                          <span>{_l('l_user_profile')}</span>
                        </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="form-check with-span">
                      <div className="d-flex align-items-center">
                        <Form.Label className="form-check-label text_wrap w-auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={deskBookingAccess}
                            onChange={(e) =>
                              setDeskBookingAccess(e.target.checked)
                            }
                          />
                          <span> {_l('l_deskbooking')}</span>
                        </Form.Label>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
              {
                topicType == 'myr_staff_task' || topicType == 'task_topic' ? <React.Fragment></React.Fragment> : <AddEmployeeDocuments imagePreviews={imagePreviews} setImagePreviews={setImagePreviews} />
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={
            () => {
              if (!loder) {

                handleSave()
              }
            }
          }>
            {loder
              ? _l('l_please_wait')
              : _l('l_save')}
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
              : <></>
            }

          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEmployee;

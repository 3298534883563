import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { _l } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";

const LeaseEnterprisCompanyModal = ({ show, handleClose, spaceBasicDetails = {},setResidentTopics, residentTopics }) => {
    const initialInput = {
        contact_name: "",
        phone_number: "",
        country_code: "+33",
        company_name: "",
        email: "",
    }
  
    const [numberFormate, setNumberFormate] = useState('');
    const [numberlength, setNumberLength]= useState({max : 14, min : 9});
    const [leaseInput, setLeaseInput] = useState(initialInput);
    const [loader, setLoader] = useState(false)

    const dispatch = useDispatch()
    
      useEffect(() => {
        if (numberFormate.dialCode) {
            setLeaseInput({...leaseInput, country_code : `+${numberFormate.dialCode}`})
            const dialcodeL = Number(numberFormate.dialCode.length);
            let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
            if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
                if (["49", "44"].includes(numberFormate.dialCode)) {
                    setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
                }else{
                    setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
                }
            }else{
                setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
            }
        }
      }, [numberFormate])
    
      const fieldValidations = () => {
        const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        let flag = true;
        if (!leaseInput.contact_name || !leaseInput.contact_name.length) {
          flag = false;
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_contact_name")
            )
          );
        } else if (leaseInput.email.trim() == "") {
          dispatch(
            showMessage("unsucess", _l("l_error"), _l("l_please_enter_email"))
          );
          flag = false;
        } else if (!pattern.test(leaseInput.email.trim())) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_valid_email")
            )
          );
          flag = false;
        } else if (
          !leaseInput.phone_number ||
          !leaseInput.phone_number.length ||
          (numberlength.max != leaseInput.phone_number.length &&
            numberlength.min != leaseInput.phone_number.length)
        ) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_valid_mobile_number")
            )
          );
          flag = false;
        } else if (
          !leaseInput.company_name.trim() ||
          !leaseInput.company_name.length
        ) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              _l("l_please_enter_company_name")
            )
          );
          flag = false;
        }
        return flag;
      };

      const createEnterPrisCompany = () => {
        if (!fieldValidations()) {
            return false
        }
        try {
            setLoader(true);
            customerServices.registrationNewVersion(
                leaseInput.contact_name, 
                `${leaseInput.country_code.slice(0)} ${leaseInput.phone_number.slice(leaseInput.country_code.length - 1)}`, 
                leaseInput.email, 
                leaseInput.company_name,
                spaceBasicDetails && spaceBasicDetails.region_id,
                spaceBasicDetails && spaceBasicDetails.address
            ).then((res) => {
                if (res.status) {
                    const firstSpaceIndex = leaseInput.contact_name.split(' ');
                    let first_name = firstSpaceIndex.slice(0,1)[0] ? firstSpaceIndex.slice(0,1)[0] : ""
                    let last_name = firstSpaceIndex.slice(1)[0] ? firstSpaceIndex.slice(1)[0] : '';
                    let updateResidnt = [
                        {
                            company_name : leaseInput.company_name,
                            filter_key : res.data && res.data.client_id ? res.data.client_id : 0,
                            is_active : 0,
                            main_sub_key : "resident",
                            user_details : 
                                {
                                contact_role: "1",
                                contactid: res.data && res.data.contact_id ? res.data.contact_id : 0,
                                email: leaseInput.email,
                                firstname: first_name,
                                image: "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg",
                                lastname: last_name,
                                profile_image: null,
                                staffid: res.data && res.data.staff_id ? res.data.staff_id : 0,
                                userid: res.data && res.data.client_id ? res.data.client_id : 0}
                            ,
                        }
                    ]
                    setResidentTopics(updateResidnt.concat(residentTopics));
                    setLoader(false);
                    handleClose();
                    dispatch(
                        showMessage(
                          "sucess",
                          _l("l_sucess"),
                          _l(res.message)
                        )
                      );
                }else{
                  setLoader(false);
                  showMessage(
                    "unsucess",
                    _l("l_error"),
                    _l(res.message)
                  )
                }
            })
            
        } catch (error) {
            console.log("error", error);
        }
      }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_add") + ' ' + _l("l_enterprise_company")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="form-wrapper-main">
            <div className="row m-0 ">
              <Form.Group className="col-xl-12 c-input-box position-relative  mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_contact_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_contact_name")}
                  aria-label="contactName"
                  aria-describedby="basic-addon1"
                  value={leaseInput.contact_name}
                  onChange={(e) => {
                      setLeaseInput({ ...leaseInput, contact_name: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 select-half-separator">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_phone")}
                </Form.Label>
                <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                  <PhoneInput
                    inputClass="title-fonts f-12"
                    autoFormat={true}
                    containerClass="f-32"
                    disableCountryGuess={true}
                    countryCodeEditable={false}
                    disableSearchIcon={true}
                    enableSearch={true}
                    enableAreaCodes={true}
                    enableLongNumbers={true}
                    localization={""}
                    country="fr"
                    preferredCountries={[
                      "fr",
                      "de",
                      "be",
                      "es",
                      "ch",
                      "gb",
                      "pl",
                      "lu",
                    ]}
                    value={leaseInput.phone_number}
                    placeholder="780 1250 456"
                    isValid={(value, country)=>{
                        if (value.length > numberlength.max) {
                          setLeaseInput({ ...leaseInput, phone_number: value.slice(0,numberlength.max) });
                        } else {
                        return true;
                        }
                    }}
                    onChange={(phone, formattedValue) => {
                      setLeaseInput({ ...leaseInput, phone_number: phone });
                      setNumberFormate(formattedValue);
                    }}
                    inputProps={{
                        autoFocus: true
                      }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_email_address_label")}
                </Form.Label>
                <Form.Control
                    placeholder={_l("l_email_address_label")}
                    aria-label="email"
                    aria-describedby="basic-addon1"
                    type="email"
                    value={leaseInput.email}
                    onChange={(e) => {
                      setLeaseInput({ ...leaseInput, email: e.target.value });
                    }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative  mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_company_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_company_name")}
                  aria-label="contactName"
                  aria-describedby="basic-addon1"
                  value={leaseInput.company_name}
                  onChange={(e) => {
                      setLeaseInput({ ...leaseInput, company_name: e.target.value });
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleClose}
          >
            {_l("l_cancel")}
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={loader}
            onClick={() => {
                createEnterPrisCompany();
            }}
          >
            {loader ? 
            <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={` `}
                    aria-hidden="true"
                />

                <span className="ms-2">{_l("l_please_wait")}</span>
            </> :_l("l_confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default LeaseEnterprisCompanyModal;

import React from "react";
import DatePicker from "react-datepicker";
import { _l } from "../../../hooks/utilities";
import Select from "react-select";
 
import useScheduleSteps from "../../../hooks/useScheduleSteps";
import { Form } from "react-bootstrap";
export default function ScheduleAssignmentOperator({
  employeeData,
  detail,
  handleOperatorDetail,
  selectOperatorSlot,
  index,
  selectAllOperatorSlots,
}) {

  const userType = localStorage.getItem("user_type");

  const { operatorAvailability, calculationsCounts } =
    useScheduleSteps();

  const handleBulkDaySelectEvent = (check) => {
    let days = { ...detail.days };
    Object.keys(days).forEach((day) => {
      if (calculationsCounts.headerCounts[day] > 0 && check) {
        days[day] = true;
      }
      else {
        days[day] = false;
      }
    });
    selectAllOperatorSlots(days);
  };

  return (
    <>
      <tr>
        <td className="w-100  me-2 p-0 px-2">
          <div className="d-flex alingn-items-center w-100">
            <div className="benchmark_div_height w-70px">
              <a href="#/"
                className="white-border-box custom-datepicker border-0 form-control h-35 my-3 mx-0"
                data-bs-toggle="tooltip"
              >
                <div className="d-flex align-items-center c-font f-10 custom-timepicker">
                  {/* <Clock size={18} className="c-icons" weight="light" /> */}
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    className="m-0 text-center"
                    selected={
                      detail.available_from instanceof Date &&
                      !isNaN(detail.available_from)
                        ? detail.available_from
                        : ""
                    }
                    showTimeSelect
                    onChange={(date) => {
                      selectOperatorSlot(date);
                    }}
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    placeholderText={`${_l("l_time_placeholder")}`}
                    dateFormat="h:mm aa"
                    calendarStartDay={1}
                  />
                </div>
              </a>
            </div>
            <div className="my-3 mx-2 flex-grow-1">
              <Select
                className="custom-select-menu with-filter-select me-0"
                classNamePrefix="react-select"
                // Filter all the operator which are already associated with any other slots
                options={employeeData.filter(
                  (w) =>
                    operatorAvailability.findIndex(
                      (x) => x.assignee_id == w.value
                    ) == -1
                )}
                value={employeeData.filter(
                  (employee) => employee.value == detail.assignee_id
                )}
                onChange={(value) => {
                  handleOperatorDetail(value);
                }}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="my-3 d-flex alingn-items-center">
              {/* <Form.Control
                placeholder={_l("l_task_name")}
                className="h-35"
                type="text"
                value={operatorAvailability[index].task_title}
                onChange={(e) => {
                  setOperatorAvailability(
                    Object.values({
                      ...operatorAvailability,
                      [index]: {
                        ...operatorAvailability[index],
                        task_title: e.target.value,
                      },
                    })
                  );
                }}
              /> */}
              {
                userType === "operator"
                ?
                <Form.Check
                  className="ps-2 m-auto"
                  type="checkbox"
                  onChange={(e) => {
                    handleBulkDaySelectEvent(e.target.checked);
                  }}
                />
                :
                <></>
              }
            </div>
          </div>
        </td>
      </tr>
      {/*   */}
    </>
  );
}

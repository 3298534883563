import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react'
import { mapStyle } from "../assets/map/mapStyle";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientTabData } from '../actions/customer';
import { login } from '../actions/auth';
import customerServices from '../services/customer-services';


const Mapcard = ({
    data
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const spaceState = useSelector((state) => state.customer.spaces);


    const [coOrdinates, setCoOrdinates] = useState({
        lat: 48.83888,
        lng: 2.38062,
    });

    const containerStyle = {
        width: "100%",
        height: "100%",
        position: "static",
    };

    useEffect(() => {
        setCoOrdinates({
            lat: data.extraData.latitude,
            lng: data.extraData.longitude
        })

    }, [JSON.stringify(data)])
      
  return (
    <div className='d-flex chat-box with-padding width-custom '>
        <div className="h30w30 comman-image-box with-bg rounded-circle bg-white-05 mt-auto">
          <div
            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
            style={{
              backgroundImage: `url('${"https://myrpp.myr.ai/orb/uploads/chat_documents/small_myr-ai-logo-object.png"}')`,
            }}
          ></div>
        </div>
        <div className="d-flex w-100">
            <div className=" w100minus30 chat-msg">
                <div className='chat-text-box with-media with-task-card  p-2 pb-0 w-100  opacity-100 '>
                    <div className='task-list-card list-view aichatlist d-inline '>
                        <div className='task-list-wrapper'>
                            <div className='d-flex w-100 align-items-start'>
                                <div className='d-flex task-list-detail'>
                                    <div className='task-left flex-grow-1 width100minus160'>
                                        <div className='d-flex align-items-center w-100 task-left-part-toggle'>
                                            <div className='task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task' 
                                            onClick={()=>{
                                                const url = data.extraData.link

                                                // Regular expression to extract space= value
                                                const regex = /space=(\d+)/;
                                            
                                                // Using the regular expression to match and extract the value
                                                const match = url.match(regex);
                                            
                                                // Checking if there is a match project_id
                                                if (match) {
                                                    customerServices.getSpacemembarshipPackageID().then((res)=>{
                                                        if (res && res.status) {
                                                            localStorage.setItem("membership_package_id",res && res.data && res.data.package_id ? res.data.package_id : 0)
                                                        }
                                                    })
                                                  const spaceValue = match[1];
                                                  let space = spaceState.filter((space) => space.project_id == spaceValue)
                                                  if (space  && space.length > 0) {
                                                    customerServices.getCustomerSpaces(localStorage.getItem("client_id"), localStorage.getItem("contact_id")).then((res)=>{
                                                        if (res && res.status) {   
                                                            dispatch(clientTabData(space[0]));
                                                            localStorage.removeItem("is_registered")
                                                        }
                                                    })
                                                  }
                                                } 
                                                // navigate(data.extraData.link)
                                            }}>
                                                <div className=' d-flex  w-100 align-items-center'>
                                                    <div className='task-creator-img'>
                                                        <div className='comman-image-box h32w32 rounded-circle'>
                                                              <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle mp'
                                                                  style={{ backgroundImage: `url('${"https://myrpp.myr.ai/orb/assets/images/default-comapny-placeholder.svg"}')` }}>
                                                              </div>
                                                        </div>
                                                    </div>
                                                    <a href="#/" className='task-list-name text-start text-truncate py-0 c-font f-14imp'>
                                                        {data.extraData.postal_address}
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="border d-block my-2 w-100"></div>
                                            <div className='  mb-2 px-1 h-100 w-100 '>
                                               
                                                    <div className='align-items-center h100w100 w-100  d-flex radius_3 position-relative'>
                                                        
                                                              <GoogleMap
                                                                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                                                                  mapContainerStyle={containerStyle}
                                                                  center={coOrdinates}
                                                                  zoom={10}
                                                                  options={{
                                                                    streetViewControl: false,
                                                                    scrollwheel: true,
                                                                    styles: mapStyle,
                                                                    minZoom : 5,
                                                                    fullscreenControl:false,
                                                                  }}
                                                              >
                                                                  <Marker position={coOrdinates} />
                                                              </GoogleMap>

                                                       
                                                    </div>
                                               
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mapcard
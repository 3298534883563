import React, { useState } from "react";
import { X, UploadSimple, Plus, Check, FilePdf } from "phosphor-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, validateEmail } from "../../hooks/utilities";
import Select from "react-select";

const AddNewDocument = ({ projectId, show, handleClose, handleShow }) => {
  const [assignees, setAssignees] = useState([]);
  const [categories, setCategories] = useState([]);
  const [addDetails, setAddetails] = useState({
    assignee: '',
    category: ''
  });
  const [addCategory, setAddCategory] = useState(true);
  const [addAssignees, setAddAssignees] = useState(true);
  const addAssigneesOrCategory = (type) => {
    if (type == 'assignee') {
      if (validateEmail(addDetails.assignee) && addDetails.assignee != '') {
        setAssignees([{ value: addDetails.assignee, label: addDetails.assignee }, ...assignees])
        setAddetails({ ...addDetails, ['assignee']: '' })
      }

    } else if (type == 'category') {
      if (addDetails.category != '') {
        setCategories([{ value: addDetails.category, label: addDetails.category }, ...categories])
        setAddetails({ ...addDetails, ['category']: '' })
      }
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title> {_l("l_add_new_document")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_document_name")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_document_name_placeholder")}`}
                  type="text"
                  name=""
                />
              </Form.Group>
              <div className="col-12">
                <div className="row c-input-box  position-relative align-items-center">
                  {
                    addCategory ? (
                      <Form.Group className="col-12 c-input-box pb-3 position-relative comment-input-box">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_category")}
                        </Form.Label>
                        <div className="position-relative">
                          <Select className="custom-select-menu w-100 form-control ps-0 py-0 wirh-border-none pe-0"
                            classNamePrefix="react-select"
                            options={categories}
                            closeMenuOnSelect={true}
                            placeholder={`${_l("l_please_select")}`}
                          />
                          <a href="#/" className="send-arrow-btn" onClick={() => setAddCategory(false)}>
                            <div
                              alt={"White arrow"}
                              className="img-width-18 m-auto"
                            >
                              <Plus size={16} weight="light" className="c-icons" />
                            </div>
                          </a>
                        </div>
                      </Form.Group>) : (
                      <Form.Group className="col-12 c-input-box pb-3 position-relative comment-input-box">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_add_category")}
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            placeholder={_l("l_add_category_placeholder")}
                            type="text"
                            name=""
                            value={addDetails.category}
                            onChange={(e) => {
                              setAddetails({ ...addDetails, ['category']: e.target.value })
                            }}
                          />
                          <a href="#/" className="send-arrow-btn" onClick={() => setAddCategory(true)}>
                            <div
                              alt={"White arrow"}
                              className="img-width-18 m-auto" onClick={() => {
                                addAssigneesOrCategory('category')
                              }}
                            >
                              <Check size={16} weight="light" className="c-icons" />
                            </div>
                          </a>
                        </div>
                      </Form.Group>)
                  }
                </div>
              </div>

              {addAssignees ? (
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative comment-input-box">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_assignee")}
                  </Form.Label>
                  <div className="position-relative">
                    <Select className="custom-select-menu w-100 form-control ps-0 py-0 wirh-border-none pe-0"
                      classNamePrefix="react-select"
                      options={assignees}
                      closeMenuOnSelect={true}
                      placeholder={`${_l("l_please_select")}`}
                    />
                    <a href="#/" className="send-arrow-btn" onClick={() => setAddAssignees(false)}>
                      <div
                        alt={"White arrow"}
                        className="img-width-18 m-auto"
                      >
                        <Plus size={16} weight="light" className="c-icons" />
                      </div>
                    </a>
                  </div>
                </Form.Group>
              ) : (
                <Form.Group className="col-12 c-input-box pb-3 position-relative comment-input-box">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_assignee_email")}
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      placeholder={`${_l("l_assignee_email_placeholder")}`}
                      type="text"
                      name=""
                      value={addDetails.assignee}
                      onChange={(e) => {
                        setAddetails({ ...addDetails, ['assignee']: e.target.value })
                      }}
                    />
                    <a href="#/" className="send-arrow-btn" onClick={() => setAddAssignees(true)}>
                      <div
                        alt={"White arrow"}
                        className="img-width-18 m-auto" onClick={() => {
                          addAssigneesOrCategory('assignee')
                        }}
                      >
                        <Check size={16} weight="light" className="c-icons" />
                      </div>
                    </a>
                  </div>
                </Form.Group>
              )
              }
              <Form.Group className="col-12 c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_select_box")}
                </Form.Label>
                <div className="form-control file-upload position-relative with-bg">
                  <div className="d-flex align-items-center justify-content-center">
                    <Form.Control className="  hidden-input" type="file" />
                    <UploadSimple size={18} className="c-icons m-0" weight="light" />
                    <span className="color-white-60 ps-2">
                      {_l("l_browse_file")}
                    </span>
                  </div>
                </div>
                <div className="upload-image-preview mt-4">
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="comman-image-box h40w40 radius_3 upload-image">
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                        style={{
                          backgroundImage: `url('https://source.unsplash.com/random')`,
                        }}
                      ><FilePdf size={24} color="#00ff6a" weight="light" className="c-icons d-none" /></div>
                      <a href="#/" className="upload-close">
                        <X size={14} className="c-icons" weight="light" />
                      </a>
                    </div>
                    <div class="image-text w100minus50 ps-3 text-truncate">
                      <div class="text-truncate c-font f-13"> idet logo.png </div>
                      <div class="text-truncate c-font f-11 color-white-60 ">size : 50.00 KB</div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm">{_l('l_save')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewDocument;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import Toasts from "../Toasts/Toasts";
import { _l } from "../../hooks/utilities";
import AdminServices from "../../services/admin-services";
import { showMessage, showConfirmation } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { getDefaultTopics, updateTeams } from "../../actions/customer";
const CreateTeam = ({
  show,
  handleClose,
  dataSource,
  setDataSource,
  team,
  teamsData,
  setTeamsData,
  handleAddedTeam, topicType = '',projectId = 0
}) => {
  const [employeeData, setEmployeeData] = useState([]);
  const { userTeams } = useSelector((state) => state.customer);
  const [manager, setManager] = useState(
    team ? { label: team.manager_name, value: team.manager_id , manager_profile_image: team.manager_profile_image} : []
  );
  const dispatch = useDispatch();
  const [teamMembers, setTeamMembers] = useState(
    team
      ? team.members && team.members.map((x) => {
        return {
          value: x.member_id,
          label: `${x.firstname} ${x.lastname}`,
        };
      })
      : []
  );
  const [teamName, setTeamName] = useState(
    team && team.teamTitle ? team.teamTitle : ""
  );
  const [nameValidationShow, setNameValidationShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [loder, setLoder] = useState(false);
  const admin = localStorage.getItem("admin");
  const userImage = localStorage.getItem("contact_image");
  const currentUserName = localStorage.getItem("full_name");
  const staff_id = localStorage.getItem("staff_id");

  useEffect(() => {
    if (admin) {
      AdminServices.getMyRStaffList().then((res) => {
        if (res.data && res.status) {
          const formattedResponse = res.data.map((staff) => {
            return {
              value: staff.staffid,
              label: staff.fullname,
              role: 1,
              manager_profile_image: staff.profile_image_url,
            };
          });
          setEmployeeData(formattedResponse);
        }
      });
    } else {
      CustomerServices.getCompanyEmployee().then((res) => {
        if (res.status && res.data) {
          const formattedResponse = res.data.map((employee) => {
            return {
              value: employee.staff_id,
              label: employee.full_name,
              role: employee.role_detail.role_id,
              manager_profile_image: employee.profile_image,
            };
          });
          setEmployeeData(formattedResponse);
        }
      });
    }
  }, []);

  const prepareToast = (className, messageBody, header) => {
    setShowToast(true);
    setToastHeader(header);
    setToastClass(className);
    setToastMessage(messageBody);
  };

  const handleCreateTeam = () => {
    if (!teamName || !teamName.trim().length) {
      prepareToast("unsucess", _l("l_please_enter_team_name"), _l("l_error"));
      return false;
    } else if (
      !admin &&
      (!manager.value ||
        parseFloat(manager.value) === NaN ||
        parseFloat(manager.value) < 0)
    ) {
      prepareToast(
        "unsucess",
        _l("l_please_select_team_manager"),
        _l("l_error")
      );
      return false;
    } else if (!teamMembers.length) {
      prepareToast(
        "unsucess",
        _l("l_please_add_members_to_team"),
        _l("l_error")
      );
      return false;
    } else {
      const members = teamMembers.map((x) => x.value);
      const managerId = parseFloat(manager.value);
      if (admin && topicType == '') {
        const teamId = team && team.id > 0 ? team.id : 0;
        setLoder(true);
        AdminServices.createVirtualSpace(members, teamName, teamId).then(
          (res) => {
            if (res.status && res.data) {
              setLoder(false);
              let memberDetails = employeeData.filter((x) => {
                if (members.findIndex((w) => w == x.value) !== -1) {
                  return x;
                }
              });
              memberDetails = memberDetails.map((x) => {
                return {
                  staffid: x.value,
                  profile_image_url: x.profile_image,
                  full_name: x.label,
                };
              });
              const creator = {
                staff_id: staff_id,
                full_name: currentUserName,
                profile_image_url: userImage,
              };
              const newTeam = {
                new: teamId == 0,
                creator_details: creator,
                id: res.data.project_id || teamId,
                members: memberDetails,
                name: teamName,
                unread_notification_count: "0",
                handler: "",
              };
              handleAddedTeam(newTeam);
              dispatch(getDefaultTopics(projectId, "", "", ""));
              prepareToast("success", _l("l_team_create"), _l("l_success"));
              handleClose();
            }
          }
        );
      } else {
        setLoder(true)

        CustomerServices.createTeam(
          members,
          managerId,
          teamName,
          team && team.id ? team.id : 0,
          topicType == 'user_journey' ? 1 : 0
        ).then((res) => {
          setLoder(false)

          if (res.status) {
            if (team && team.id) {
              let updatedTeam = {
                id: team.id,
                index: team.index,
                name: teamName,
                manager_name: manager.label,
                manager_id: manager.value,
                manager_profile_image:manager.manager_profile_image,
                team: teamMembers.map((x) => {
                  return {
                    firstname: x.label,
                    lastname: "",
                    profile_image: employeeData.find(
                      (profile) =>
                        profile.value == x.value
                    ).manager_profile_image,
                    member_id: x.value,
                  };
                }),
              };
              let pos = userTeams.findIndex((x) => x.id == parseFloat(team.id));
              let Arr = userTeams
              Arr[pos] = updatedTeam
              dispatch(updateTeams({data:Arr}));
              handleAddedTeam({...updatedTeam, teamTitle:teamName}, res.data.team_id);
              prepareToast("success", _l("l_team_update"), _l("l_success"));
              dispatch(getDefaultTopics(projectId, "", "", ""));
              dispatch(showMessage('sucess', _l('l_success'), res.message ? _l(res.message) : _l('l_team_update')));
            } else {
              let newTeam = {
                id: res.data.team_id,
                index: dataSource.length,
                name: teamName,
                manager_name: manager.label,
                manager_id: manager.value,
                manager_profile_image: manager.manager_profile_image,
                team: teamMembers.map((x) => {
                  return {
                    firstname: x.label,
                    lastname: "",
                    profile_image: employeeData.find(
                      (profile) =>
                        profile.value == x.value
                    ).manager_profile_image,
                    member_id: x.value,
                  };
                }),
              };
              setTeamsData(userTeams.concat(newTeam));
              dispatch(updateTeams({ data: userTeams.concat(newTeam) }));
              setDataSource(dataSource.concat(newTeam));
              handleAddedTeam({...newTeam,teamTitle:teamName});
              dispatch(getDefaultTopics(projectId, "", "", ""));
              dispatch(showMessage('sucess', _l('l_success'), res.message ? _l(res.message) : _l('l_team_create')));
            }
            handleClose();
          }
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        backdrop={false}
      >
        {showToast ? (
          <Toasts
            delay={2500}
            handler={() => {
              setShowToast(false);
            }}
            header={toastHeader}
            message={toastMessage}
            toastClass={toastClass}
          />
        ) : (
          <></>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            {team ? _l("l_edit_team") : _l("l_create_team")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_team_name")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_team_name")}`}
                  type="text"
                  value={teamName}
                  onChange={(e) => {
                    setTeamName(e.target.value);
                  }}
                  onFocus={() => setNameValidationShow(true)}
                  name=""
                />
                {nameValidationShow ? (
                  <Form.Control.Feedback
                    type={teamName.trim().length ? "" : "invalid"}
                  >
                  </Form.Control.Feedback>
                ) : (
                  <></>
                )}
              </Form.Group>
              <Form.Group
                className={`col-12 c-input-box pb-4 position-relative ${admin ? topicType != '' ? '' : "d-none" : ""
                  } `}
              >
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_manager_name")}
                </Form.Label>
                <Select
                  defaultValue={manager}
                  className="custom-select-menu"
                  classNamePrefix="react-select"
                  options={employeeData}
                  placeholder={`${_l("l_select_manager")}`}
                  onChange={(value) => setManager(value)}
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_employee")}
                </Form.Label>
                <Select
                  defaultValue={teamMembers}
                  closeMenuOnSelect={false}
                  className="custom-select-menu max-h95 multi-select-with-scroll"
                  classNamePrefix="react-select"
                  options={employeeData.filter((x) => x.value != manager.value)}
                  isMulti
                  placeholder={`${_l("l_select_employee")}`}
                  onChange={(value) => {
                    setTeamMembers(value);
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm" disabled={ loder ? true : false} onClick={() => {
            if (topicType == 'user_journey' || topicType == 'my_people') {
              if (team && team.id > 0) {
                dispatch(
                  showConfirmation(
                    _l("l_are_you_sure"),
                    _l("l_you_want_to_update_the_team"),
                    _l("l_yes"),
                    _l("l_no"),
                    undefined,
                    () => { handleCreateTeam(); }
                  )
                );
              } else {
                handleCreateTeam()
              }

            } else {
              handleCreateTeam()
            }
          }}>
            {loder
              ? _l('l_please_wait')
              : _l('l_save')}
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
              : <></>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateTeam;

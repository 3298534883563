import React from 'react'

const TaskListSkeleton = () => {
  return (
    <div className='task-list-card list-view   taskcardliteversion'>
        <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center flex-grow-1'>
                <div className='h20w20 me-10px skeleton-animation radius_3'></div>
                <div className='h32w32 rounded-5 skeleton-animation'></div>
                <div className='ms-10px flex-grow-1'>
                    <div className='mb-1 skeleton-animation radius_3' style={{width:'40%', height:'15px'}}></div>
                    <div className='skeleton-animation radius_3' style={{width:'25%', height:'10px'}}></div>
                </div>
            </div>
            <div className='d-flex align-items-cente gap10'>
                <div className='h32w32 skeleton-animation radius_3'></div>
                <div className='h32w32 skeleton-animation radius_3'></div>
                <div className='h32w32 skeleton-animation radius_3'></div>
            </div>
        </div>
    </div>
  )
}

export default TaskListSkeleton
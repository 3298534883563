import React, { useEffect, useState } from "react";
import Select from "react-select";
import {CalendarBlank,Plus,Minus } from "phosphor-react";
import { _l } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import PlacesAutocomplete from "react-places-autocomplete";
import Toasts from "../Toasts/Toasts";
import {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const Schedule3DScan = ({ projectId, show, handleClose, handleShow }) => {
  const [regionList, setRegionList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [address, setAddress] = useState("");
  const [input3dplan, setinput3dplan] = useState([
    {
      name3dplan: "",
      country_code_3dplan: "",
      phonenumber_3dplan: "",
      surface_3dplan: "",
      access_description: "",
      access_code: "",
      wifi_code: "",
      address_3dplan: "",
      date:
        startDate && startDate != ""
          ? moment(startDate).format("YYYY-MM-DD HH:MM:SS")
          : "",
      indoor_scan: false,
      outdoor_scan: false,
    },
  ]);
  const [toastClass, setToastClass] = useState("unsucess");
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [validationFalse, setValidationFalse] = useState(false);

  useEffect(() => {
    CustomerServices.getRegionList().then((res) => {
      if (res.status && res.data) {
        let allregions = [];
        res.data.forEach((region) => {
          allregions.push({
            value: "+" + region.id,
            label: "+" + region.id,
            name: "country_code_3dplan",
          });
        });
        setRegionList(allregions);
      }
    });
  }, []);

  useEffect(() => {
    if (address != "") {
      let state = [
        ...input3dplan,
        (input3dplan[0]["address_3dplan"] = address),
      ];
      state.pop();
      setinput3dplan(state);
    } else {
      let state = [...input3dplan, (input3dplan[0]["address_3dplan"] = "")];
      state.pop();
      setinput3dplan(state);
    }
  }, [address]);

  useEffect(() => {
    if (startDate != "") {
      let state = [
        ...input3dplan,
        (input3dplan[0]["date"] = moment(startDate).format(
          "YYYY-MM-DD HH:MM:SS"
        )),
      ];
      state.pop();
      setinput3dplan(state);
    }
  }, [startDate]);

  const handleInputChange = (e, flag = "") => {
    let newState;
    if (flag && flag == "init") {
      newState = [
        ...input3dplan,
        (input3dplan[0]["date"] = moment(e).format("YYYY-MM-DD")),
      ];
    } else {
      if (
        e.target &&
        e.target.name &&
        (e.target.name == "indoor_scan" || e.target.name == "outdoor_scan")
      ) {
        newState = [
          ...input3dplan,
          (input3dplan[0][e.target.name] = e.target.checked),
        ];
      } else {
        let name, value;
        if (e.target) {
          name = e.target.name;
          value = e.target.value;
        } else {
          name = e.name;
          value = e.value;
        }
        newState = [...input3dplan, (input3dplan[0][name] = value)];
      }
    }
    newState.pop();
    setinput3dplan(newState);
  };

  const validateform = () => {

    var format = /^[a-z0-9\s]+$/i
    if (input3dplan && input3dplan[0] && input3dplan[0].name3dplan == "" || !format.test(input3dplan[0].name3dplan)) {
      setHeader(_l("l_error"));
      setMessage(_l("l_please_enter_the_fullname"));
      setValidationFalse(true);
      return false;
    } else if (
      input3dplan &&
      input3dplan[0] &&
      input3dplan[0].country_code_3dplan == ""
    ) {
      setHeader(_l("l_error"));
      setMessage(_l('l_please_select_country_code'));
      setValidationFalse(true);
      return false;
    } else if (
      input3dplan &&
      input3dplan[0] &&
      input3dplan[0].phonenumber_3dplan == ""
    ) {
      setHeader(_l("l_error"));
      setMessage(_l('l_phone_number_cannot_be_empty'));
      setValidationFalse(true);
      return false;
    } else if (input3dplan && input3dplan[0] && input3dplan[0].date == "") {
      setHeader(_l("l_error"));
      setMessage(_l('l_please_select_date'));
      setValidationFalse(true);
      return false;
    } else if (
      input3dplan &&
      input3dplan[0] &&
      (input3dplan[0].surface_3dplan == "" ||
        parseInt(input3dplan[0].surface_3dplan) <= 0)
    ) {
      setHeader(_l("l_error"));
      setMessage(_l('l_please_enter_valid_superficy'));
      setValidationFalse(true);
      return false;
    } else if (
      input3dplan &&
      input3dplan[0] &&
      (input3dplan[0].address_3dplan == "" ||
        input3dplan[0].address_3dplan == "undefined")
    ) {
      setHeader(_l("l_error"));
      setMessage(_l('l_address_can_not_be_empty'));
      setValidationFalse(true);
      return false;
    } else {
      CustomerServices.addtask3dplan(
        input3dplan && input3dplan[0] ? input3dplan[0] : ""
      ).then((res) => {
        if (res.status) {
          setHeader(_l("l_success"));
          setToastClass("sucess");
          setMessage(res.message ? res.message : "");
          setValidationFalse(true);
          setinput3dplan([
            {
              name3dplan: "",
              country_code_3dplan: "",
              phonenumber_3dplan: "",
              surface_3dplan: "",
              access_description: "",
              access_code: "",
              wifi_code: "",
              address_3dplan: "",
              date: "",
              indoor_scan: false,
              outdoor_scan: false,
            },
          ]);
          setStartDate("");
          setAddress("");
          handleClose();
        }
      });
    }
  };

  const handleSelect = async (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {})
      .catch((error) =>{});
  };
  const autosearchPlaces = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }) => {
    return (
      <div>
        <Form.Control
          placeholder={`${_l("l_address_placeholder")}`}
          type="text"
          name="address_3dplan"
          {...getInputProps({
            placeholder: "Avenue de France, Paris, France",
          })}
        />
        <div className="custom-map-suggestion">
          {loading && <div>{_l("l_loading")}</div>}
          {suggestions.map((suggestion, index) => {
            return (
              <div {...getSuggestionItemProps(suggestion)} key={index}>
                <a>{suggestion.description}</a>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="lg"
          onHide={() => {
            handleClose();
            setinput3dplan([
              {
                name3dplan: "",
                country_code_3dplan: "",
                phonenumber_3dplan: "",
                surface_3dplan: "",
                access_description: "",
                access_code: "",
                wifi_code: "",
                address_3dplan: "",
                date: "",
                indoor_scan: false,
                outdoor_scan: false,
              },
            ]);
            setStartDate("");
            setAddress("");
            setHeader("");
            setToastClass("unsucess");
            setMessage("");
            setValidationFalse(false);
          }}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_schedule_a_3d_scan")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main">
              <div className="row">
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_full_name_label")}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_full_name_placeholder")}`}
                    type="text"
                    name="name3dplan"
                    onChange={handleInputChange}
                    defaultValue={
                      input3dplan && input3dplan[0]
                        ? input3dplan[0].name3dplan
                        : ""
                    }
                  />
                  
                </Form.Group>
                <Form.Group className="col-xl-6 c-input-box position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_phone_number")} 
                  </Form.Label>
                  <div className="row">
                    <div className="c_input_border drop_image col-3 col-md-3  col-xl-3 position-relative">
                      <Select
                        className="custom-select-menu"
                        classNamePrefix="react-select"
                        options={regionList}
                        name="country_code_3dplan"
                        placeholder={`${_l("l_region")}`}
                        onChange={handleInputChange}
                        defaultValue={
                          input3dplan && input3dplan[0]
                            ? input3dplan[0].country_code_3dplan
                            : ""
                        }
                      />
                    </div>
                    <div className="col-9 col-md-9 col-xl-9 ps-md-0">
                      <Form.Control
                        placeholder={`${_l("l_phone_number_placeholder")}`}
                        type="Number"
                        name="phonenumber_3dplan"
                        onChange={handleInputChange}
                        defaultValue={
                          input3dplan && input3dplan[0]
                            ? input3dplan[0].phonenumber_3dplan
                            : ""
                        }
                      />
                     
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_3d_scan_date")} 
                  </Form.Label>
                  <div
                    className="custom-datepicker"
                    data-bs-toggle="tooltip"
                    title="Aug, 8 2022"
                  >
                    <div className="d-flex align-items-center form-control  color-white-60 dropdown react-datepicker-wrapper-w-100">
                      <CalendarBlank size={18} className="c-icons" weight="light" />
                      <DatePicker
                        className=""
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          handleInputChange(date, "init");
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={`${_l("l_date_placeholder_text")}`}
                        calendarStartDay={1}
                      ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                      <span className="color-white-60 d-none">Aug, 8 2022</span>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_superficy")} 
                  </Form.Label>
                    <div className="d-flex align-items-center justify-content-between form-control">
                      <a href="#/"  className="c-font f-22 line-height-0">
                      <Minus size={18} className="c-icons" weight="light" />
                      </a>
                        <Form.Control
                            className="bg-transparent border-0 p-0 text-center"
                            placeholder="10"
                            type="number"
                            name="surface_3dplan"
                            onChange={handleInputChange}
                            defaultValue={
                              input3dplan && input3dplan[0]
                                ? input3dplan[0].surface_3dplan
                                : ""
                            }
                            min="1"
                          />
                        <a href="#/"  className="c-font f-22 line-height-0">
                        <Plus size={18} className="c-icons" weight="light" />
                        </a>
                    </div>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_address")} 
                  </Form.Label>

                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onClick={handleSelect}
                  >
                    {autosearchPlaces}
                  </PlacesAutocomplete>
                </Form.Group>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="form-check with-span">
                      <div className="d-flex align-items-center">
                        <Form.Label className="form-check-label text_wrap w-100">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="indoor_scan"
                            onChange={handleInputChange}
                            value={
                              input3dplan && input3dplan[0]
                                ? input3dplan[0].indoor_scan
                                : ""
                            }
                          />
                          <span>{_l("l_indoor_scan")} </span>
                        </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="form-check with-span">
                      <div className="d-flex align-items-center">
                        <Form.Label className="form-check-label text_wrap w-100">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="outdoor_scan"
                            onChange={handleInputChange}
                          />
                          <span>{_l("l_outdoor_scan")} </span>
                        </Form.Label>
                      </div>
                    </div>
                  </div>
                </div>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_wifi_code")}{" "}
                  </Form.Label>
                  <Form.Control
                    placeholder="123456"
                    type="number"
                    name="wifi_code"
                    onChange={handleInputChange}
                    defaultValue={
                      input3dplan && input3dplan[0]
                        ? input3dplan[0].wifi_code
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_access_code")}
                  </Form.Label>
                  <Form.Control
                    placeholder="11234560"
                    type="number"
                    name="access_code"
                    onChange={handleInputChange}
                    defaultValue={
                      input3dplan && input3dplan[0]
                        ? input3dplan[0].access_code
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l("l_access_description")}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={`${_l("l_type_description_here")}`}
                    rows={3}
                    type="text"
                    name="access_description"
                    onChange={handleInputChange}
                    defaultValue={
                      input3dplan && input3dplan[0]
                        ? input3dplan[0].access_description
                        : ""
                    }
                  ></Form.Control>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button
              variant="secondary" size="sm"
              onClick={() => {
                handleClose();
                setinput3dplan([
                  {
                    name3dplan: "",
                    country_code_3dplan: "",
                    phonenumber_3dplan: "",
                    surface_3dplan: "",
                    access_description: "",
                    access_code: "",
                    wifi_code: "",
                    address_3dplan: "",
                    date: "",
                    indoor_scan: false,
                    outdoor_scan: false,
                  },
                ]);
                setStartDate("");
                setAddress("");
                setHeader("");
                setToastClass("unsucess");
                setMessage("");
                setValidationFalse(false);
              }}
            >
             {_l('l_close')}
            </Button>
            <Button variant="primary" size="sm" onClick={validateform}>
            {_l('l_save')}{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {validationFalse ? (
        <Toasts
          message={message}
          header={header}
          toastClass={toastClass}
          delay={3000}
          handler={() => {
            setValidationFalse(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Schedule3DScan;

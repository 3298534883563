import React, { useState, useEffect } from "react";
import User_2 from "../../assets/images/users/user_2.jpg";
import jquery from "jquery";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import PencilSimpleLineIcon from "../../assets/icons/PencilSimpleLineIcon";
import Offcanvas from "react-bootstrap/Offcanvas";
import Lightbox from "yet-another-react-lightbox";
import {
    TaskCommentModalOpen,
    _l,
    TaskCommentModalToggle,
    TaskModalClose,
    CloseCommentModal,
    tabindexFix,
} from "../../hooks/utilities";
import TrashIcon from "../../assets/icons/TrashIcon";
import { showConfirmation, toastCloseAction, showMessage } from "../../actions/messages";
import ReactHtmlParser from "react-html-parser";
import { useDispatch } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { MagnifyingGlass,UploadSimple,VideoCamera,PushPin,ArrowRight,Paperclip,At,DotsThreeVertical,Hash,X,Info } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";

const RightSideComments = ({ id, category, showModal, setShowModal, list, handleDelete, handleEditComment, convertedTaskId, imagePreviews, removeImageSelection, addNewComment, mentionList, isAllowToEdit, preparePreview, setImageBuffer, imageBuffer, commentContent, setCommentContent, getMentionData, setShowCommentModal, ShowCommentModal }) => {
    const [show, setShow] = useState(true);
    const [showFullscreen, setShowFullscreen] = useState(false);
    const userType = localStorage.getItem("user_type");
    const staff_id = localStorage.getItem("staff_id");
    const contact_id = localStorage.getItem("contact_id");
    const [openGallery, setOpenGallery] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [previewSrc, setPreviewSrc] = useState([]);
    const contact_image = localStorage.getItem("contact_image");

    const [searchString, setSearchString] = useState("");
    const [showSearch, setshowSearch] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [defaultComment, setdefaultComment] = useState([]);
    const handleClose = () => {
        setShow(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        jquery(".office-list-icon, #openCommentModal,.office-box").on("click", function () {
            if (ShowCommentModal) {
                setShowCommentModal(false)
                CloseCommentModal()
            }

        });


    }, [])

    useEffect(() => {
        setSearchString('')
        setdefaultComment([])
        setCommentList([]);
        if (list && list.length) {

            setCommentList(list);
            setdefaultComment(list)
        }
    }, [list]);
    useEffect(() => {
        if (searchString != "") {
            setCommentList(() =>
                defaultComment.filter(
                    (comment) =>
                        comment.content &&
                        comment.content.toLowerCase().includes(searchString.toLowerCase())
                )
            );
            scrollToBottom();
        } else {
            setCommentList(defaultComment);
        }
    }, [searchString]);


    const deleteConfirmation = (id) => {
        dispatch(
            showConfirmation(
                _l("l_are_you_sure"),
                _l("l_you_want_to_delete_this_commment"),
                _l("l_delete"),
                _l("l_cancel"),
                undefined,
                () => {
                    handleDelete(id);
                }
            )
        );
    };
    const setAttchmentOnTop = (id) => {
        const targetAttchement = previewSrc.filter(
            (attachment) => attachment.id === id
        );
        const otherAttachments = previewSrc.filter(
            (attachment) => attachment.id != id
        );
        setPreviewSrc(targetAttchement.concat(otherAttachments));
    };
    const handleSearch = (e) => {
        const searchStringInput = e.target.value;
        setSearchString(searchStringInput);
    };
    const scrollToBottom = () => {
        var msgListDiv = document.getElementById("comment-list");
        msgListDiv.scrollBottom = msgListDiv.scrollHeight;
    };

    return (
        <>
            <Lightbox
                key={galleryIndex}
                open={openGallery}
                close={() => setOpenGallery(false)}
                slides={previewSrc.map((img) => {
                    return { src: img.src };
                })}
            />
            <Offcanvas
                className={`taskDetailModal custom-offcanvas-modal custom-modal-style stand-alone-comments`}
                placement="end"
                scroll={false}
                backdrop={false}
                show={show}
                onHide={() => { handleClose() }}
            >

                <Offcanvas.Body className="p-0">
                    <div className="task-detail-right-main h-100 d-flex flex-column res-width-100 task-detail-right-main">
                        <div className="border-bottom p-15">
                            <div className="d-flex align-items-center">
                                <div className="right-task-header with_separator_10 flex-grow-1 ps-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="task-header-left-options">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="title-fonts fw-semibold">{_l('l_comments')}</div>
                                                <div className="dropend d-none">
                                                    <a href="#/" className="comman_action_icon with_separator_10 before-dnone">
                                                        <div className="action_icon with_bg">
                                                            <PushPin size={18}  weight="light" className="c-icons" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-header-right-option">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <a href="#/" className="action_icon d-none">
                                                    <MagnifyingGlass size={18}  weight="light" className="c-icons" />
                                                </a>
                                                <a href="#/" className="action_icon ml-10 on-hover-active-toggle-img">
                                                    <UploadSimple size={18}  weight="light" className="c-icons" />
                                                </a>
                                                <a href="#/" className="btn btn-primary btn-sm line-height-1 action_icon ml-10 d-none">
                                                {_l('l_share')}
                                                </a>
                                                <a href="#/" className="action_icon ml-10 on-hover-active-toggle-img">
                                                    <VideoCamera size={18}  weight="light" className="c-icons" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="#/" type="button" className="modal-close with_separator_10 pe-0 position-relaive  z-index-5" onClick={() => {
                                    //  handler(taskId, false);
                                    // hideTaskModal();
                                    // dispatch(SetSelectedTask(""));
                                    CloseCommentModal();
                                    setShowCommentModal(false);
                                    // LeftPanelBaseOnScreenSize();
                                }}
                                >
                                    <X size={18}  weight="light" className="c-icons" />
                                </a>
                            </div>
                        </div>
                        <div className="comman-tab-wrapper pb-0">
                            <div className="d-flex align-items-center w-100">
                                <div className="tab-wrapper d-flex align-items-center justify-content-center flex-grow-1 d-none">
                                    <a href="#/" className="tab-name">{_l('l_comments')}</a>
                                    <a href="#/" className="info-icon">
                                        <Info size={18}  weight="light" className="c-icons opacity-50" />
                                    </a>
                                </div>
                                <div className="active d-flex align-items-center justify-content-center flex-grow-1 d-none">
                                    <a href="#/" className="tab-name">Internal rating</a>
                                    <a href="#/" className="info-icon">
                                        <Info size={18}  weight="light" className="c-icons opacity-50" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="right-chat-wrapper position-relative d-flex flex-column">
                            <div className="search-with-close d-none">
                                <div className="d-flex align-items-center ">
                                    <div className="comment-input-box bg-base-header-color pb-0 radius_3  w-100">
                                        <input className="bg-base-header-color border-0 c-font f-12 form-control p-2 radius_3" placeholder={`${_l("l_type_to_search")}`} value="" />
                                    </div>
                                    <a href="#/" className="bg-base-header-color border-left d-flex h35w35 line-height-0">
                                        <X size={18}  weight="light" className="c-icons" />
                                    </a>
                                </div>
                            </div>
                            {showSearch ? (
                                <div className="search-with-close">
                                    <div className="d-flex align-items-center ">
                                        <div className="comment-input-box bg-base-header-color pb-0 radius_3  w-100">
                                            <input
                                                className="bg-base-header-color border-0 c-font f-12 form-control p-2 radius_3"
                                                placeholder={`${_l("l_search")}`}
                                                value={searchString}
                                                onChange={(e) => handleSearch(e)}
                                                disabled={convertedTaskId > 0 ? true : false}
                                            />
                                        </div>
                                        <a href="#/" className="bg-base-header-color border-left d-flex h35w35 line-height-0">
                                            <div
                                                className="img-width-18 m-auto"
                                                onClick={() => {
                                                    if (convertedTaskId == 0) {
                                                        setshowSearch(false);
                                                        setSearchString("");
                                                    }
                                                }}
                                            >
                                                <X size={16}  weight="light" className="c-icons" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="pill-wrapper d-none">
                                        <a href="#/"
                                            className="comman_action_icon filter-list-view d-none"
                                            id="filter-show-list"
                                        >
                                            <div className="action_icon with_bg_03 h35w35">
                                                <div
                                                    className="img-width-18 m-auto"
                                                    onClick={() => {
                                                        setshowSearch(false);
                                                        setSearchString("");
                                                    }}
                                                >
                                                    <X size={16}  weight="light" className="c-icons" />
                                                </div>
                                            </div>
                                        </a>
                                        <div
                                            id="show-filter-list"
                                            className="filter-list-toggle"
                                        >
                                            <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                                                <div className="d-flex commn-search radius_3 w-100 justify-content-between">
                                                    <div className="comment-input-box">
                                                        <input
                                                            type="text"
                                                            id="task-search-string"
                                                            className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                                                            placeholder={`${_l("l_search")}`}
                                                            data-bs-toggle="tooltip"
                                                        />
                                                    </div>
                                                    <a href="#/" className="comman_action_icon">
                                                        <div className="d-flex align-items-center ">
                                                            <div className="action_icon h25w25">
                                                                <MagnifyingGlass size={14}  weight="light" className="c-icons" />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="chat-main-box flex-grow-1 comman_vertical_btn_h">
                                <div className="d-flex flex-column h-100">
                                    <div className="c-font f-12 py-1 px-2 title-fonts color-white-60 border-bottom d-none">{_l('l_comments')}</div>
                                    <div className="chat-wrapper" id="comment-list">
                                        {commentList && commentList.length ? (
                                            commentList.map((comment, index) => {
                                                const commentContent = comment.content;
                                                const dummyDiv = document.createElement("div");
                                                dummyDiv.innerHTML = commentContent;
                                                return (
                                                    <div className={`chat-box ${(userType == "contact" &&
                                                        contact_id === comment.contact_id) ||
                                                        (userType == "staff" &&
                                                            staff_id == comment.staffid)
                                                        ? ""
                                                        : ""
                                                        }`} key={index}>
                                                        <div className="d-flex">
                                                            <div className="h30w30 comman-image-box with-bg rounded-circle bg-white-05" data-bs-toggle="tooltip"
                                                                // title={comment.contact_full_name}
                                                                aria-expanded="false"
                                                                 
                                                                 
                                                                 >
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                                                                    backgroundImage: `url('${comment.addedfrom_details.profile_url}')`,
                                                                }}></div>
                                                            </div>
                                                            <div className="chat-msg">
                                                                <div className="chat-text-box">
                                                                    <div className="image-upload-wrapper">
                                                                        {comment.attachments &&
                                                                            comment.attachments.length ? (
                                                                            <div className="d-flex align-items-center flex-wrap ">
                                                                                {comment.attachments.map(
                                                                                    (image, index) => {
                                                                                        if (index < 3) {
                                                                                            return (
                                                                                                <a href="#/"
                                                                                                    key={index}
                                                                                                    className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-auto mb-2"
                                                                                                    onClick={() => {
                                                                                                        setOpenGallery(true);
                                                                                                        setGalleryIndex(
                                                                                                            index
                                                                                                        );


                                                                                                        setPreviewSrc(
                                                                                                            comment.attachments.map(
                                                                                                                (
                                                                                                                    src,
                                                                                                                    innerIndex
                                                                                                                ) => {
                                                                                                                    return {
                                                                                                                        id: src.id,
                                                                                                                        src: src.file_name,
                                                                                                                    };
                                                                                                                }
                                                                                                            )
                                                                                                        );
                                                                                                        // setAttchmentOnTop(image.id)

                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="comman-bg-img h-100 w-100 bg-style-cover"
                                                                                                        style={{
                                                                                                            backgroundImage: `url('${image.file_name}')`,
                                                                                                        }}
                                                                                                    ></div>
                                                                                                </a>
                                                                                            );
                                                                                        } else if (index === 3) {
                                                                                            return (
                                                                                                <a href="#/"
                                                                                                    className="h70w70 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-auto mb-2 with_overlay"
                                                                                                    onClick={() => {
                                                                                                        setOpenGallery(true);
                                                                                                        setGalleryIndex(
                                                                                                            index
                                                                                                        );
                                                                                                        setPreviewSrc(
                                                                                                            comment.attachments.map(
                                                                                                                (src) => {
                                                                                                                    return {
                                                                                                                        src: src.file_name,
                                                                                                                    };
                                                                                                                }
                                                                                                            )
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <div class="unread_count m-auto">
                                                                                                        +
                                                                                                        {comment.attachments
                                                                                                            .length - 3}
                                                                                                    </div>
                                                                                                </a>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </div>
                                                                    <div className="chat-text"> {comment.content != "null" &&
                                                                        comment.content != "undefined"
                                                                        ? ReactHtmlParser(comment.content)
                                                                        : ""}</div>
                                                                    <div className={`dropdown chat-action-option ${comment.is_allowed_to_edit == "0" ||
                                                                        convertedTaskId > 0
                                                                        ? "d-none"
                                                                        : ""
                                                                        }`}>
                                                                        <a href="#/"
                                                                            className="line-height-0 "
                                                                            type="button"
                                                                            id="commentOptionDropdown"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                             
                                                                             
                                                                             
                                                                             
                                                                        >
                                                                            <DotsThreeVertical size={18}  weight="light" className="c-icons" />
                                                                        </a>
                                                                        <ActionDropdown
                                                                            actions={[
                                                                                {
                                                                                    actionName: _l("l_edit"),
                                                                                    actionHandler: () => {
                                                                                        handleEditComment(
                                                                                            comment.id
                                                                                        );
                                                                                    },
                                                                                    actionIcon: "PencilSimpleLineIcon",
                                                                                },
                                                                                {
                                                                                    actionName: _l("l_delete"),
                                                                                    actionHandler: () => {
                                                                                        deleteConfirmation(comment.id)
                                                                                    },
                                                                                    actionIcon: "TrashIcon",
                                                                                },


                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="chat-send-name c-font f-10 opacity-50"><span>{comment.time}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })) : (
                                            <>
                                                <CommanPlaceholder imgType="no-comment" placeholderText = {_l("l_no_comments")} />
                                            </>)
                                        }
                                    </div>
                                    {imagePreviews.length ? (
                                        <div className="upload-image-preview p-2">
                                            <div className="d-flex align-items-center justify-content-start">
                                                {imagePreviews.map((preview, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="comman-image-box h40w40 radius_3 upload-image with-margin"
                                                        >
                                                            <div
                                                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                                                style={{
                                                                    backgroundImage: `url(
                                      '${preview.url}'
                                    )`,
                                                                }}
                                                            ></div>
                                                            <a href="#/"
                                                                className="upload-close"
                                                                onClick={() => {
                                                                    removeImageSelection(preview.name);
                                                                }}
                                                            >
                                                                <X size={10}  weight="light" className="c-icons" />
                                                            </a>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="chat-comment-box">
                                        <div className="comment-input-box border-bottom">
                                            <div className="d-flex align-items-center position-relative ">
                                                <div className="h30w30 comman-image-box rounded-circle" >
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${contact_image})` }}></div>
                                                </div>
                                                <div className="mension-box mension-box--multiLine" style={{ position: 'relative', overflowY: 'visible' }}>
                                                    {/* <textarea placeholder="Write comment" className="border-0 bg-transparent"></textarea> */}
                                                    <MentionsInput
                                                        value={commentContent}
                                                        className="mension-box"
                                                        placeholder={`${_l(
                                                            "l_write_comment_placeholder"
                                                        )}`}
                                                        alwaysOpen={true}
                                                        onChange={(e) =>
                                                            setCommentContent(e.target.value)
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                                                                e.preventDefault();
                                                                addNewComment();
                                                            }
                                                        }}
                                                        disabled={
                                                            convertedTaskId > 0 ||
                                                                (isAllowToEdit)
                                                                ? true
                                                                : false
                                                        }
                                                        id="comment_input"
                                                    >
                                                        <Mention
                                                            markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                                                            trigger="@"
                                                            data={mentionList}
                                                            renderSuggestion={(
                                                                suggestion,
                                                                search,
                                                                highlightedDisplay
                                                            ) => {
                                                                return (
                                                                    <div className="user d-flex align-items-center">
                                                                        <div className="comman-image-box h25w25 rounded-circle mx-2">
                                                                            <div
                                                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                                                style={{
                                                                                    backgroundImage: `url('${suggestion.image}')`,
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                        {highlightedDisplay}
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MentionsInput>
                                                </div>
                                                <a href="#/" className="send-arrow-btn with_position_right_zero" onClick={() => {
                                                    if (
                                                        convertedTaskId == 0 ||
                                                        (isAllowToEdit)
                                                    ) {
                                                        addNewComment();
                                                    }
                                                }}>
                                                <ArrowRight size={18}  weight="light" className="c-icons" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="comment-bottom-option">
                                            <div className="d-flex align-items-center">
                                                <div className="comman_action_icon with_separator_10 ps-0">
                                                    <div className="d-flex align-items-center">
                                                        <a href="#/" className="action_icon with_bg" onClick={() => {
                                                            if (convertedTaskId == 0) {
                                                                getMentionData();
                                                            }
                                                        }}>
                                                            <At size={18}  weight="light" className="c-icons" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="comman_action_icon with_separator_10">
                                                    <div className="d-flex align-items-center">
                                                        <a href="#/" className="action_icon with_bg" >
                                                            <input type="file" multiple="multiple" className="absolute-input" accept="image/*" onChange={(event) => {
                                                                if (event.target.files.length > 5) {
                                                                    dispatch(
                                                                        showMessage("unsucess", _l("l_error"), _l("l_maximum_5_files_are_allowed_at_a_time"))
                                                                    );
                                                                    return;
                                                                }
                                                                setImageBuffer([
                                                                    ...imageBuffer,
                                                                    ...event.target.files,
                                                                ]);
                                                                preparePreview(event.target.files);
                                                                // CustomerServices.addLinkComment(
                                                                //   taskId,
                                                                //   projectId,
                                                                //   "",
                                                                //   undefined,
                                                                //   undefined,
                                                                //   0,
                                                                //   0,
                                                                //   event.target.files[0]
                                                                // );
                                                            }}
                                                                onClick={(e) => {
                                                                    e.target.value = "";
                                                                }}
                                                                disabled={
                                                                    convertedTaskId > 0 ||
                                                                        (isAllowToEdit)
                                                                        ? true
                                                                        : false
                                                                } />
                                                            <Paperclip size={18}  weight="light" className="c-icons" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="comman_action_icon with_separator_10 d-none">
                                                    <div className="d-flex align-items-center">
                                                        <a href="#/" className="action_icon with_bg" >
                                                            <Hash size={18}  weight="light" className="c-icons" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="comman_action_icon with_separator_10">
                                                    <div className="d-flex align-items-center">
                                                        <a href="#/" className="action_icon with_bg"  
                                                             
                                                             
                                                             
                                                            onClick={() => {
                                                                if (showSearch) {
                                                                    setSearchString('')
                                                                }
                                                                setshowSearch(!showSearch);
                                                            }}>
                                                            {showSearch ? (
                                                                <X size={18}  weight="light" className="c-icons" />
                                                            ) : (
                                                                <MagnifyingGlass size={18}  weight="light" className="c-icons" />
                                                            )}

                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" overflow-auto"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default RightSideComments;

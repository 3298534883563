import React, { useEffect, useState } from 'react'
import { ChatCircle, DesktopTower, VideoCamera, House, Umbrella } from "phosphor-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { leftPanelFilters } from '../../actions/customer';
import { _l, getDatePickerLocale } from '../../hooks/utilities';
import { useNavigate } from "react-router-dom";
import User_2 from "../../assets/images/users/user_2.jpg";
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import UncontrolledGeneralListing from '../TaskModalComponents/UncontrolledGeneralListing';
import Calendar from '../Calendar';


const LockScreenHeader = ({
    setShowLoader,
    taskCount,
    filterTask,
    displayAttendance,
    setDisplayAttendance,
    get_attendance,
    setSelectedTab,
    pageName,
    taskPinned,
    setTaskPinned
}) => {
    const spaces = localStorage.getItem("spaces") != null && localStorage.getItem("spaces") != undefined ? JSON.parse(localStorage.getItem("spaces")) : '';
    const savedProject = localStorage.getItem("selectedOffice");
    const [projectId, setProjectId] = useState(savedProject && (savedProject != null ? savedProject : (spaces && spaces.length ? spaces[0].project_id : 0)));
    const dispatch = useDispatch();
    const contact_id = localStorage.getItem("contact_id");
    const { isBackdrop } = useSelector((state) => state.customer);
    const [date, setDate] = useState(localStorage.getItem('__selectedDate') != undefined ? new Date(localStorage.getItem('__selectedDate')) : new Date());
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const lockTaskCounter = useSelector((state) => state.customer.getLockTaskCount);
    const [filter, setFilter] = useState([]);
    const navigate = useNavigate();
    const { defaultTopics,} = useSelector((state) => state.customer);
    const [selectedAssignee, setSelectedAssignee] = useState([]);
    const [taskTitle, setTaskTitle] = useState("");
    const [checklistItems, setChecklistItems] = useState([]);
    const [checkListTexts, setCheckListTexts] = useState([]);
    const [disableOnClickEvent, setDisableOnClickEvent] = useState(false);
    const [addChecklistItemEvent, setAddChecklistItemEvent] = useState("");

    useEffect(() => {
        let task_assignee = [];
        let task_assignee_ids = [];
      let task_assignee_pills = [];
        if (selectedAssignee.length > 0) {
            selectedAssignee.map((ta) => {
              ta.categories && ta.categories.map((taskAssign) => {
                task_assignee_ids.push({
                  name: ta.main_key,
                  id: taskAssign.filter_key,
                })
              if (
                ta.main_key == "myr_staff_task" ||
                ta.main_key == "my_people" ||
                ta.main_key == "task_topic"
              ) {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          task_assignee.push({
                            name: provider.title,
                            profile_image: provider.image,
                            id: provider.filter_key,
                          });
                        }
                      });
                    }
                  });
              } else {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          if (topic.main_key != "equipments") {
                            task_assignee_pills.push({
                              name: provider.title,
                              id: provider.filter_key,
                              main_key:topic.main_key
                            });
                          }
  
                          let subCategory = []
                          provider.sub_category && provider.sub_category.forEach((sc) => {
                            taskAssign.sub_category && taskAssign.sub_category.forEach((dd) => {
                              if (sc.id == dd.id) {
                                  let title = taskTitle
                                  let tempTitle = title.split('-')
                                  if (tempTitle[1]) {
                                    title = taskAssign.sub_category[taskAssign.sub_category.length - 1].name + " - " + tempTitle[1]
                                  }else{
                                    if(!taskTitle.toLocaleLowerCase().includes(dd.name.toLocaleLowerCase())){
                                        title =  taskAssign.sub_category[taskAssign.sub_category.length - 1].name + `${taskTitle ? " - " : "" }` + taskTitle
                                      }
                                  }
                                  setTaskTitle(title)
                                subCategory.push(dd.id);
                                task_assignee_pills.push({
                                  name: sc.name,
                                  id: sc.id,
                                  main_key:topic.main_key
                                });
                              }
                            })
                          });
                          if (subCategory.length == 0) {
                            task_assignee_ids = task_assignee_ids.filter((tas) => tas.name != "equipments")
                          }
                          task_assignee_ids.push({
                            id: provider.filter_key,
                            name: provider.title,
                            sub_category: subCategory
                          })
                        }
                      });
                    }
                  });
              }
            });
          })
        }
      let ids = [];
      task_assignee_ids.map((data)=>{
       ids.push(data.id)
      })

  }, [JSON.stringify(selectedAssignee)]);

    useEffect(() => {
      if (disableOnClickEvent == "" && addChecklistItemEvent != "") {
        handleAddChecklistItem(addChecklistItemEvent);
      }
    }, [disableOnClickEvent]);

    const is_active_class = (key) => {
        let activeClassTask = false;
        filter.forEach(element => {
            if (element == key) {
                activeClassTask = true;
            }
        });
        return activeClassTask;
    }

    const filterTaskList = (type) => {
        const taskPosition = filter.findIndex(x => x == type)
        if (taskPosition == -1) {
            setFilter([...filter, type])
        }
        else {
            setFilter(filter.filter((t) => t != type))
        }
    }

    const handleAddChecklistItem = (e) => {
        let checklistArr = [];
        if (e.type == "paste") {
            checklistArr = e.clipboardData.getData("Text").split("\n");
        }
        checklistArr = e.target.value.split("\n");
        let newArr = [];
        for (let i = 0; i < checklistArr.length; i++) {
            if (checklistArr[i].trim().length) {
                newArr.push({ description: checklistArr[i], finished: 0 });
            }
        }
       
        let checklistText = checkListTexts;
        let newList = [];
        newList.push({
            addedfrom: contact_id,
            description: (e.target.value).trim(),
            finished: "0",
            id: checklistItems.length + 1,
            is_added_from_contact: "1",
            list_order: "0",
            req_photo: "0",
            comments_count: 0,
            links_count: 0,
            attachments_count: 0,
            is_allowed_to_edit: 1,
            status: 0,
            comments:[],
            assignee_id:0
        });
        let arr = checklistItems.concat(newList)
        setChecklistItems(checklistItems.concat(newList))

        arr.forEach((item) => {
            checklistText[item.id] = (item.description).trim();
          });
        setCheckListTexts(checklistText);

    };

    return (
        <>
            <div className='d-none'>
                <div className="lock-screen-main-header">
                    <div className="d-flex lock-screen-slider p-10">
                        <div className="progress-slider w-100">
                            <div className="d-flex align-items-center">
                                <div>{_l("l_space_score")}</div>
                                <div className="progress mx-3">
                                    <div className="progress-bar" style={{ width: `${(taskCount ? taskCount.task_progress : 0)}%` }}></div>
                                </div>
                                <div className="c-font f-12 ms-2 title-fonts">{taskCount ? taskCount.task_progress : 0}%</div>
                            </div>
                        </div>
                    </div>
                    <div className='calendar-modal-color p-10 pb-3'>
                        <div className="d-flex  justify-content-between ">
                            <div className="lock-left-header text-center custom-datepicker cursor_hide abs-input position-relative">
                                <div className="c-font date f-24 fw-bold lh-1 pb-1 title-fonts">{("0" + date.getDate()).slice(-2)}</div>
                                <div className="c-font day f-12  text-uppercase">{days[date.getDay()]}</div>
                                <div className="c-font f-10 month ">{monthNames[date.getMonth()] + '. ' + date.getUTCFullYear()}</div>
                                <div className='react-datepicker-full-height'>
                                    <DatePicker
                                        selected={date}
                                        locale={getDatePickerLocale()}
                                        onChange={(date) => {
                                            setDate(date);
                                        }}
                                        calendarStartDay={1}
                                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                </div>
                            </div>
                            <div className="lock-mid-header">
                                <div className="d-flex h-100">
                                    <div className="schedule-right-header flex-grow-1">
                                        <div className="h-100 kpi-main-wrapper p-0 shortcut-wrapper">
                                            <div className="d-flex h-100">
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('request');
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('request') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-orange">{_l("l_request")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics fw-bold">{taskCount && taskCount.request_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('1');
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('1') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-white-80">{_l("l_todo")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics fw-bold">{taskCount && taskCount.todo_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('4');
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('4') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-blue">{_l("l_inprogress")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics fw-bold">{taskCount && taskCount.inprogress_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#/"
                                                    onClick={() => {
                                                        filterTaskList('5');
                                                    }}
                                                    className={`align-items-center bg-white-05 justify-content-center kpi-box ${is_active_class('5') ? 'active' : ''}`}>
                                                    <div className="kpi-upper-box">
                                                        <div className="d-flex align-items-center">
                                                            <div className="c-font f-10 kpi-upper-box-text">
                                                                <span className="color-green">{_l("l_done")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="w-50 text-center">
                                                            <div className="c-font f-12 kpi-statatics fw-bold">{taskCount && taskCount.done_count} </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <a href="#/" className={`lock-right-header text-center ${is_active_class('unread') ? 'c-green-border-50' : ''}`}
                                onClick={() => {
                                    dispatch(
                                        leftPanelFilters({
                                            main_key: "unread_filter",
                                            filter_key: 'unread',
                                            title: "unread_filter"
                                        })
                                    )
                                }}
                            >
                                <div className="align-item-center bg-white-05 d-flex flex-column h60w60 h-100 justify-content-center m-auto position-relative">
                                    <div><ChatCircle size={18} weight="light" className="c-icons" /></div>
                                    <div className='c-font f-10 mt-1'>{_l("l_unread")}</div>
                                    <span class={`rounded-pill badge-custom d-flex ${taskCount.unread_comment_count && taskCount.unread_comment_count > 0 ? "" : "d-none"} `}>{taskCount.unread_comment_count}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='daily-schedule-main my-0'>
                <div className={`lock-screen-main-header position-sticky top-0 z-index-7 ${isBackdrop ? "comman-backdrop-search" : ""}`}>
                    <div className="d-flex lock-screen-slider p-10 radius_3 mb-2 d-none">
                        <div className="progress-slider flex-grow-1 pe-3 mt-auto">
                            <div className='c-font f-12'>{_l("l_space_score")}</div>
                            <div className="d-flex align-items-center">
                                <div className="progress">
                                    <div className="progress-bar" style={{ width: `${(lockTaskCounter ? lockTaskCounter.data.task_progress : 0)}%` }}></div>
                                </div>
                                <div className="c-font f-12 ms-2 title-fonts">{lockTaskCounter ? lockTaskCounter.data.task_progress : 0}%</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className='align-item-center bg-white-05 d-flex flex-grow-1 justify-content-center p-10 py-1 radius_3 text-nowrap on-hover-bg-white-05'>
                                <div className=" d-flex align-items-center with_separator_10 ps-0">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <DesktopTower size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>On Office</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex align-items-center with_separator_10">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <VideoCamera size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>Remote</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex align-items-center with_separator_10 d-none">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <House size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>{_l("l_home")}</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex align-items-center with_separator_10 pe-0">
                                    <a href="#/" className='pe-3' onClick={() => { navigate("/MyPeople"); }}>
                                        <Umbrella size={18} weight="light" className="c-icons" />
                                        <span className='px-2 title-fonts text-truncate d-none'>Leave</span>
                                    </a>
                                    <div className="position-relative  assignee-list-wrapper card-view-less-padding d-flex justify-content-center">
                                        <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start">
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${User_2})` }}></div>
                                            </a>
                                            <a href="#/" className="comman-image-box  h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                <div className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2" >
                                                            <div className="unread_count m-auto"
                                                                type="button"
                                                                id="GeneralListing"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-auto-close="outside"
                                                                aria-expanded="false">
                                                                +2
                                                            </div>
                                                            <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                                                                return {
                                                                    name: "User name",
                                                                    image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#/" className={` bg-white-05 p-10 radius_3 min-width-125 py-1 align-item-center d-flex d-none ${displayAttendance == true && (get_attendance !== undefined && Object.keys(get_attendance) && Object.keys(get_attendance).length > 0) ? "operator_user" : ""}`} onClick={() => {
                                setDisplayAttendance(!displayAttendance)
                                setSelectedTab("start-schedule")
                            }} >
                                <DesktopTower size={18} weight="light" className="c-icons m-0 my-auto" />
                                <span className='ps-2 title-fonts my-auto'>{displayAttendance == true ? "My status" : "My task"}</span>
                            </a>
                        </div>
                    </div>
                    <Calendar
                        projectId={projectId}
                        clearStatusFilter={() => { }}
                        setTaskModalClose={() => { }}
                        pageName={pageName}
                    />
                </div>
            </div>
        </>
    )
}

export default LockScreenHeader
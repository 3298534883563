import {  useSelector } from "react-redux";
import React from "react";
import moment from "moment";
import { Checks,SpeakerSlash } from "phosphor-react";
 
import { _l } from "../../hooks/utilities";
import ReactHtmlParser from "react-html-parser";

import {
  ATTACH_BASE_URL,
  MULTI_USR_PLACEHOLDER
} from "../../actions/chat-action-type";

const ChannelCard = ({ group, changeGroupId ,setSearchStringLeftChannel,clearRightBoardsearch}) => {
  const { activeChannelId } = useSelector((state) => state.chat);
  var chatUserId = localStorage.getItem("chatUserId");
  const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  const getInitials = (str) => {
    var parts = str.split(" ");
    var initials = "";
    parts.forEach(function (part, i)  {
      if (part.length > 0 && part !== "" && i<2) {
        initials += capitalize(part[0]);
      }
    });
    return initials;
  };

  // Modify data
  var userImg = group.displayPicture;
  if (userImg == "") {
    if(group.type == 2 || group.type == 4){
      userImg = MULTI_USR_PLACEHOLDER;
    }
  } else {
    userImg = ATTACH_BASE_URL + userImg;
  }
  var userName = group.groupName;
  var userRole = "";
  var displayCount = "";
  var msgDate = "";
  var lastMsg = "";
  var onlineClass = "";
  var groupId = (group.newGroup == 1) ? group._id : group.groupId;

  if(group.newGroup != 1){
    userRole = getInitials(group.myrUserType2);
    if (group.unreadCount > 0) {
      displayCount = "with-unread-count";
    }
    msgDate = group.lastMessageDate;
    if (msgDate != "") {
      msgDate = moment(group.lastMessageDate).format("MMM D, YYYY");
    }
    lastMsg = group.lastMessage;
    if (lastMsg == "") {
      msgDate = "";
    } else {
      var lastMsgUserName =
        capitalize(group.lastMsgUserName.split(" ")[0]) + ": ";
      if (group.lastMsgUserId == chatUserId) {
        lastMsgUserName = "";
      }
      lastMsg = lastMsgUserName + lastMsg;
    }
    onlineClass = group.otherUserStatus == 1 ? "done" : "bg-warning";
  }

  return (
    <li
      className="comman-list p-0 "
      id={`chat_group_div_${groupId}`}
      onClick={() => {
        changeGroupId(groupId, group.otherUserId, group.type);
        setSearchStringLeftChannel("")
        clearRightBoardsearch()
      }}
    >
      <a href="#/"
        id={`group_card_unread_count_display_${groupId}`}
        className={`w-100 position-relative tab_link  left-chat ${displayCount}`}
      >
        <div className="d-flex align-items-center">
          <div className="c-list-icon position-relative">
            <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px"
             
             
             >
              {userImg == "" ? 
              <span className="color-white text-center w-100 c-font f-13 ">
                {getInitials(userName)}
              </span>
              :
              <div
                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                id={`group_img_${groupId}`}
                style={{
                  backgroundImage: `url('${userImg}')`,
                }}
              ></div>
}
              {group.type != 2 && group.type != 4 ?
                <div className="badge-custom bg-transparent border-0 user-type">
                    <span
                      id={`other_user_card_status_${group.otherUserId}`}
                      className={`c-dots c-7 ${onlineClass} rounded-circle me-1`}
                       
                       
                       
                    ></span>
                </div>
                : <></>
                }
            </div>
          </div>
          <div className="c-list-detail c-font f-14 ps-2 w100minus35">
            <div className="top-part">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="chat-u-name text-truncate"
                  
                  id={`group_name_${groupId}`}
                >
                  {userName}
                </div>
                <div className="right-toggling">
                  
     
                  <div
                    id={`group_card_unread_count_${groupId}`}
                    className="unread-count rounded-pill badge-custom with-position-relative with-ms-5px with-d-flex"
                  >
                    {group.unreadCount}
                  </div>
                  <div className="read-msg with-ms-5px">
                    <Checks size={16}  weight="light" className="c-icons" />
                  </div>
                  <div className="delivered-msg with-ms-5px">
                    <Checks size={16}  weight="light" className="c-icons" />
                  </div>
                  <div className="mute-msg with-ms-5px">
                    <SpeakerSlash size={16}  weight="light" className="c-icons" />
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-part color-white-60">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="lst-msg text-truncate w-50 "
                  id={`group_last_msg_${groupId}`}
                >
                  {ReactHtmlParser(lastMsg)}
                </div>
                <div className="msg-time" id={`group_last_msg_date_${groupId}`}>
                  {msgDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
       
    </li>
  );
};

export default ChannelCard;

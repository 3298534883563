import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from "react-redux";
import CustomerServices from "../services/customer-services";
import TaskRequestModal from "./Modals/TaskRequestModal";
import CalendarDropdown from "./Dropdowns/CalendarDropdown";
import CalendarRequestTaskDropdown from "./Dropdowns/CalendarRequestTaskDropdown";
import CaretDownFillIcon from "../assets/icons/CaretDownFillIcon";
import { _l, hideRightPanelNew, showLeftPanelNew } from "../hooks/utilities";
import moment from "moment";
import jquery, { isNumeric } from "jquery";
import Accordion from "react-bootstrap/Accordion";
import {
  getSelectedTask,
  getSelectedTaskCalendar,
  SetSelectedTask,
  getCalendarTaskList,
} from "../actions/customer";
import { showMessage } from "../actions/messages";
import GoogleCalendar from "../services/google-calendar";
import { useNavigate, useSearchParams } from "react-router-dom";
import outlookCalendar from "../services/outlook-calendar";
import { CaretUp } from "phosphor-react";
import { checkIsAssigned, get_refresh_request_data } from "../actions/outlook_auth";
import { googlecalendar_assignees } from "../actions/googlecalendar_auth";
import frLocale from '@fullcalendar/core/locales/fr';
import esLocale from '@fullcalendar/core/locales/es';
import { SET_DATE_CLICK, GET_3RD_PARTY_CONTACTS, GET_GOOGLE_CONTACTS, SET_CONTACT_DATA, SET_NEW_MEETING } from "../actions/action-type";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import UncontrolledGeneralListing from "./TaskModalComponents/UncontrolledGeneralListing";
 
const fullCalendar = ({pageName}) => {
  const {
    selectedProject,
    taskFilters,
    activeTaskStatusFilters,
    calendarsAssigneeList,
    taskList,
    globalSearchString
  } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const scheduleId = searchParams.get("id");

  const currentDate = new Date();
  const refreshRequestData = useSelector(
    (state) => state.discord_auth.requestRefresh
  );
  const [convertTaskId, setConvertTaskId] = useState(0);
  const [events, setEvents] = useState();
  const [showTaskInfo, setShowTaskInfo] = useState(false);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [showCalendarTaskInfo, setShowCalendarTaskInfo] = useState(false);
  const [showRequestTaskInfo, setShowRequestTaskInfo] = useState(false);
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  const [filterCategories, setFilterCategories] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [prevDate, setPrevDate] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [exitwindow, setExitWindow] = useState(0);
  const [heightWidth, setHeightWidth] = useState({});
  const [providers, setProviders] = useState("");
  const [selectCalIds, setSelectCalIds] = useState([]);
  const [task_type, setTask_type] = useState('');
  let IdName = showTaskInfo
    ? "CalendarDropdownClose"
    : showRequestTaskInfo
    ? "CalendarRequestTaskDropdownClose"
    : "";
  const [stateModal, setStateModal] = useState(false);
  const FullCalendarWidth = jquery("#FullCalendar").width();
  const FullCalendarHeight = jquery("#FullCalendar").height();
  const rel_id = localStorage.getItem("staff_id");
  const rel_type = localStorage.getItem("user_type");
  const language = localStorage.getItem("language");
  const [calendarId, setCalendarId] = useState("");
  const calendar_type = localStorage.getItem("calendar_type");

  const [calendarEventInfo, setEventInfo] = useState([]);
  const [dayClick, setDayClick] = useState("");
  const [tempEvent, setTempEvent] = useState([]);
  const [tempTaskId, setTempTaskId] = useState('');
  const [tempCalendarId,setTempCalendarId] = useState('');
  const [shareContacts, setShareContacts] = useState([]);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const [title, setTitle] = useState("");
  const [commonModalData, setCommonModalData] = useState();
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  // const [loder, setLoder] = useState(false);
  const outlook_calendar = useSelector(
    (state) => state.discord_auth.outlook_calendar_connectivity
  );
  const newDayClick = useSelector(
    (state) => state.discord_auth.newDayClick
  );
  const setContacts = useSelector(
    (state) => state.discord_auth.set_contact_data
  );
  const set_taskData = useSelector(
    (state) => state.customer.selectedTask
  );
  const [isOutlookCalendarActive, setIsOutlookCalendarActive] = useState(outlook_calendar != undefined && outlook_calendar.status == 1 ? true : false);
  const [isGoogleCalendarActive, setGoogleCalendarActive] = useState(0);
  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  const Refresh_events = useSelector(
    (state) => state.google_calendar.refresh_events
  );
  const [calendarSearchString, setCalendarSearchString] = useState("")
  let tempDate = "";
  const provider = googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ? "google_calendar" : isOutlookCalendarActive === true ? "outlook_calendar" : "";

  useEffect(() => {
    if(outlook_calendar != undefined)
    {
      if(outlook_calendar.status == 1)
      {
        setIsOutlookCalendarActive(true);
      } else {
      }
    }
  }, [outlook_calendar]);

  useEffect(() => {
    if (globalSearchString !== "" || globalSearchString !== undefined) {
      setCalendarSearchString(globalSearchString);    
    }
  }, [globalSearchString])
  
  useEffect(() => {
   
    if (googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ) {
        setGoogleCalendarActive(1);
    } else if (googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type == 0 ) {
      setEvents([]);
    }

  }, [googlecalendar_connectivity]);

  useEffect(() => {
    CustomerServices.getContactsByClient().then((res) => {
        if(res.status){
          setShareContacts(res.data);
        }
      });
    }, []);
  const getMeetingLink = (TaskId, calendar_id) => {
 
      outlookCalendar.getMeetingLink(TaskId, selectedProject, calendar_id).then((res) => {
        if (res.status == 1) {
          setMeetingUrl(res.data.meetingUrl);
          setProviders(res.data.providers);
          let data = res.data.providers.length > 0 ? res.data.providers : [];
          dispatch({
            type: GET_3RD_PARTY_CONTACTS,
            payload: { data },
          });
        } else {
          setMeetingUrl("");
          setProviders([]);
        }
      });
    if (isGoogleCalendarActive === 1) {
      if(!calendar_id){
        dispatch(googlecalendar_assignees(TaskId));
      }
      GoogleCalendar.getMeetingLink(TaskId, calendar_id).then((res) => {
        if (res.status == 1) {
       
          if(res.data && res.data.contactsGoogleCalendar) {
            // setProviders(res.data.contactsGoogleCalendar);
            let contacts = res.data.contactsGoogleCalendar;
            dispatch({
              type : GET_GOOGLE_CONTACTS,
              payload : contacts
            })
          }
          if (res.data.meetingUrl == null) {
            setMeetingUrl("");
            setProviders([]);
          } else {
            setMeetingUrl(res.data.meetingUrl);
            setProviders(res.data.providers);
          }

          // setProviders(res.data.providers);
          // window.dispatchEvent(new Event('update_eeting_url'));
        } else {
          setMeetingUrl("");
        }
      });
    }
  };

  useEffect(() => {
    setTask_type(selectedTask && selectedTask.is_requested  && selectedTask.is_requested == 1 ? 'request-task' : 'task');
    setCommonModalData(selectedTask);
  }, [JSON.stringify(selectedTask)])
  useEffect(() => {
    jquery(document).mouseup(function (e) {
      if (IdName != "") {
        var container = jquery("#" + IdName);
        let modalS = JSON.parse(
          localStorage.getItem("meetingProviderModalClose")
        );
        window.addEventListener("meetingProviderModalClose", () => {
          modalS = JSON.parse(
            localStorage.getItem("meetingProviderModalClose")
          );
        });

        // if the target of the click isn't the container nor a descendant of the container
        if (
          !container.is(e.target) &&
          container.has(e.target).length === 0 &&
          modalS == false
        ) {
          localStorage.setItem("calendarDropdown", "");
          if (showRequestTaskInfo) {
            setShowRequestTaskInfo(false);
          }
          if (showTaskInfo) {
            setShowTaskInfo(false)
            dispatch(SetSelectedTask(""));
          }

          // if (!showTaskModal) {
          //   dispatch(SetSelectedTask(""));
          // }
        }
      }
    });
  }, [showTaskInfo, showRequestTaskInfo]);

  useEffect(() => {
    if (Object.keys(heightWidth).length) {
      IdName = showTaskInfo
        ? "CalendarDropdownClose"
        : showRequestTaskInfo
        ? "CalendarRequestTaskDropdownClose"
        : "CalendarDropdownClose";
      if (IdName != "") {
        let tempWidth = heightWidth.width;
        let tempHeight = heightWidth.height;
        if (
          heightWidth.width + jquery("#" + IdName).width() >
          FullCalendarWidth
        ) {
          tempWidth = heightWidth.width - jquery("#" + IdName).width();
        }
        if (
          heightWidth.height + jquery("#" + IdName).height() >
          FullCalendarHeight
        ) {
          tempHeight = heightWidth.height - jquery("#" + IdName).height();
        }

        jquery("#" + IdName).css({
          position: "absolute",
          left: tempWidth,
          top: Math.abs(tempHeight),
          // zIndex: 99900
        });
      }
    }
  }, [heightWidth]);
  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setShowTaskModal(false);
    setTaskId(taskId);
    setConvertTaskId(convertRequestTaskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {
      setShowTaskModal(show);
      if(show == true)
      {
        // jquery(
        //   "#commonLoader_full_calendar"
        // ).removeClass("d-none");
        // jquery("#full_calendar_loader").addClass(
        //   "d-none"
        // );
      }
    }
  };

  useEffect((e) => {
    jquery(document).on("dblclick", ".fc-daygrid-day-frame ", function () {
      // jquery('.fc-daygrid-day-frame').removeClass('show');
      jquery(this).addClass('show');
    });
    jquery(document).on("click", ".fc-daygrid-day-frame ", function () {
      jquery('.fc-daygrid-day-frame').removeClass('show');
      // jquery(this).addClass('show');
    });
      jquery(document).mouseup(function (e) {
        var container = jquery(".fc-daygrid-day");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          jquery(".fc-daygrid-day-frame").removeClass("show");
        }
      });
      jquery(document).mouseup(function (e) {
        var container = jquery(".custom-full-calendar");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
         
            setShowRequestTaskInfo(false);
            setShowTaskInfo(false)
        }
      });
  },);

  
  // const handleSelectedTask = (
  //   taskId = 0,
  //   show = "",
  //   isRequestedTask = null,
  //   convertRequestTaskId = 0
  // ) => {
  //   setShowReqestedTaskModal(false);
  //   setShowTaskModal(false);
  //   setTaskId(taskId);
  //   setConvertTaskId(convertRequestTaskId);
  //   localStorage.setItem("SelectedTask", taskId);
  //   localStorage.setItem(
  //     "TaskType",
  //     isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
  //   );
  //   if (taskId) {
  //     setShowTaskModal(show);
  //   }
  //   if (isRequestedTask) {
  //     setShowReqestedTaskModal(show);
  //   } 
  // };
  useEffect(() => {
    if (showTaskInfo == false) {
      jquery('td[data-date="' + prevDate + '"]').removeAttr("style");
    }
  }, [showTaskInfo]);
  useEffect(() => {
    window.addEventListener("task_updated", () => {
      let flag = localStorage.getItem('task_updated');
      if(flag == 2)
      {
        dispatch(showMessage("sucess", _l("l_success"), _l("l_task_created")));
      } else {
        dispatch(showMessage("sucess", _l("l_success"), _l("l_task_created")));
      }
          getCalendarData();
          setTaskId(null);
          localStorage.removeItem("task_updated");
    });
  }, []);
  window.addEventListener("outlook_calendar", () => {
    setIsOutlookCalendarActive(true);
  });
  // useEffect(() => {
  //   if (selectedProject) {
  //     CustomerServices.getDefaultTaskAssignee(selectedProject).then(
  //       (assigneeEndpointResponse) => {
  //         if (
  //           assigneeEndpointResponse.status &&
  //           assigneeEndpointResponse.data
  //         ) {
  //           let generalizeCategories = [];
  //           let checkboxes = [];
  //           for (let key in assigneeEndpointResponse.data) {
  //             switch (key) {
  //               case "myr_network":
  //                 let myr_network = [];
  //                 assigneeEndpointResponse.data[key].providers.forEach(
  //                   (provider) => {
  //                     myr_network.push({
  //                       name: provider.name,
  //                       id: provider.provider_id,
  //                       profile_image: provider.profile_image || "",
  //                       checked: false,
  //                     });
  //                     checkboxes[`myr_network_${provider.provider_id}`] = false;
  //                   }
  //                 );
  //                 generalizeCategories.push({
  //                   key: "myr_network",
  //                   title: "My Network",
  //                   value: myr_network,
  //                 });
  //                 break;
  //               case "my_peoples":
  //                 let my_peoples = [];
  //                 assigneeEndpointResponse.data[key].employees.forEach(
  //                   (employee) => {
  //                     my_peoples.push({
  //                       name: employee.name,
  //                       id: employee.contact_id,
  //                       profile_image: employee.profile_image || "",
  //                       checked: false,
  //                     });
  //                     checkboxes[`my_peoples_${employee.contact_id}`] = false;
  //                   }
  //                 );
  //                 generalizeCategories.push({
  //                   key: "my_peoples",
  //                   title: "My People",
  //                   value: my_peoples,
  //                 });
  //                 break;
  //               case "equipments":
  //                 let equipments = [];
  //                 assigneeEndpointResponse.data[key].equipments.forEach(
  //                   (equipment) => {
  //                     equipments.push({
  //                       name: equipment.equipment_name,
  //                       id: equipment.equipment_id,
  //                       profile_image: equipment.profile_image || "",
  //                       checked: false,
  //                     });
  //                     checkboxes[
  //                       `equipments_${equipment.equipment_id}`
  //                     ] = false;
  //                   }
  //                 );
  //                 generalizeCategories.push({
  //                   key: "equipments",
  //                   title: "Equipments",
  //                   value: equipments,
  //                 });
  //                 break;
  //             }
  //           }
  //           setAppliedFilters(checkboxes);
  //           setFilterCategories(generalizeCategories);
  //         }
  //       }
  //     );
  //   }
  // }, [selectedProject]);

  // window.addEventListener("setcalendars_assignee", () => {

  //   // setCalendars([...JSON.parse(localStorage.getItem("google_calendar_list"))]);
  // });
  useEffect(() => {
    if(refreshRequestData != undefined && refreshRequestData.data.status == 1)
    {
      setEvents(refreshRequestData != undefined && refreshRequestData.data.status == 1? refreshRequestData.data.data.events : []);
      if(refreshRequestData.data.data.flag != '' && refreshRequestData.data.data.flag != undefined && refreshRequestData.data.data.flag != null)
      {
        setShowTaskInfo(true);
        dispatch(showMessage("sucess", _l("l_success"), refreshRequestData.data.data.flag));
      }
      else {
      dispatch(showMessage("sucess", _l("l_success"), _l("l_task_created")));
      }
    }
  }, [refreshRequestData])

  useEffect(() => {
    if (selectedProject && calendarLoaded) {
      getCalendarData();
    }
  }, [
    startDate,
    endDate,
    selectedProject,
    taskFilters,
    JSON.stringify(activeTaskStatusFilters),
    isOutlookCalendarActive,
    isGoogleCalendarActive,
  ]);

  
  let selectedCalendarIds = []
  const getCalendarData = () => {
    let network = [];
    let people = [];
    let equipments = [];
    for (let key in appliedFilters) {
      if (jquery(`#${key}`).prop("checked")) {
        if (key.includes("network")) {
          network.push(key.split("myr_network_")[1]);
        } else if (key.includes("people")) {
          people.push(key.split("my_peoples_")[1]);
        } else {
          equipments.push(key.split("equipments_")[1]);
        }
      }
    }
    CustomerServices.getFullCalendar( 
      selectedProject,
      startDate,
      endDate,
      people,
      network,
      equipments,
      taskFilters,
      activeTaskStatusFilters,
      localStorage.getItem('selectedVirtualSpace') ? true : false,
      scheduleId
    ).then((res) => {
      
      setEvents([]);
      if(taskFilters != [])
        {
          taskFilters.map((result, index) => {
            if(jquery.isNumeric(result.filter_key) == false )
            {
              if((result.filter_key !== null && result.filter_key !== '') 
              || (result.filter_key !== null && result.filter_key !== ''))
              {
                // let selectedIndex = document.getElementById(result.filter_key);
                // if(selectedIndex != null && selectedIndex != undefined)
                // {
                //   selectedIndex.classList.add('d-none');
                  selectedCalendarIds.push(result.filter_key);
                // }
              }
            }else if(isOutlookCalendarActive == true)
            {
              selectedCalendarIds.push(result.filter_key);
            }
          })
        }
      if (res.status && res.data && res.data.length) {
        dispatch(getCalendarTaskList(res.data));
        // let allEvents = [];
        // res.data.forEach((event) => {
        //   let event_date = "";

        //   if (event.startdate != null) {
        //     event_date = moment(event.startdate).format("YYYY-MM-DD hh:mm:ss");
        //   } else if (event.duedate != null) {
        //     event_date = moment(event.duedate).format("YYYY-MM-DD hh:mm:ss");
        //   } else {
        //     event_date = moment(event.dateadded).format("YYYY-MM-DD hh:mm:ss");
        //   }

        //   allEvents.push({
        //     title:
        //       (event.name && event.name != null) ||
        //       (event.name && event.name != "")
        //         ? event.name
        //         : "",
        //     id: event.id,
        //     calendar_id: event.calendar_id,
        //     selected_cal_ids : selectedCalendarIds ,
        //     color: event.color,
        //     date: event_date,
        //     is_requested: event.is_requested,
        //     provider: event.provider,
        //     allDay: false,
        //     assignee_list: event.assignee_list,
        //     schedule_id: event.schedule_id,
        //     hash:event.hash
        //   });
        // });
        // setEvents(allEvents);
        
      }
    });
  };
  useEffect(() => {
    if (taskList && taskList.length > 0) {
      let allEvents = [];
      taskList.forEach((event) => {
        let event_date = "";

        if (event.startdate != null) {
          event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
        } else if (event.duedate != null) {
          event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
        } else {
          event_date = moment(event.dateadded).format("YYYY-MM-DD HH:mm:ss");
        }

        allEvents.push({
          title: event.name,
          id: event.id,
          calendar_id: event.calendar_id,
          selected_cal_ids: selectedCalendarIds,
          color: event.is_requested == 1 ?"#fca120" : event.status == 1 ? "#b3b3b3" :  event.status == 4 ? "#03A9F4" :  event.status == 5 ? "#00ff6a" : "",
          date: event_date,
          is_requested: event.is_requested,
          provider: event.provider,
          allDay: false,
          assignee_list: event.assignee_list_DEV,
          schedule_assignees: event.schedule_assignees,
          schedule_id: event.schedule_id,
          hash: event.hash
        });
      });
      setEvents(calendarSearchString ? allEvents.filter((data)=>data.title.toLocaleLowerCase().includes( calendarSearchString.toLocaleLowerCase())) : allEvents);
    }
  },[taskList ,calendarSearchString])
  useEffect(() => {
    window.addEventListener("realTimeTask", () => {
      if (JSON.parse(localStorage.getItem("realTimeTask")) != null) {
        let arrEvent = events != undefined ? events : [];
        if (arrEvent.indexOf(tempEvent) != -1) {
          arrEvent.splice(arrEvent.indexOf(tempEvent), 1);
        }
        let myEvent = JSON.parse(localStorage.getItem("realTimeTask"));
        setTitle(myEvent);
        arrEvent.push(myEvent);
        setEvents(arrEvent);
        setTempEvent(myEvent);
        window.removeEventListener('realTimeTask');
        localStorage.removeItem("realTimeTask");
      }
    });
  }, [JSON.parse(localStorage.getItem("realTimeTask"))]);


  // useEffect(() => {
  //   if(taskFilters.length > 0 || activeTaskStatusFilters.length > 0){
  //     showLeftPanel();
  //   }
  // }, [selectedProject]);

  useEffect(() => {
    if(showTaskInfo || showRequestTaskInfo ){
      setShowTaskModal(false);
      hideRightPanelNew();
      jquery("body").removeClass("task-modal-offcanvas-open");
    }
  }, [showTaskInfo,showRequestTaskInfo,showTaskModal]);
  useEffect(() => {
    if(!scheduleId)
    {
      showLeftPanelNew();
    }
  }, []);

  // window.addEventListener("assign_contact", () => {
  //   setProviders(JSON.parse(localStorage.getItem("assign_contact")));
  // });
useEffect(() => {

  if(setContacts != undefined && setContacts.data.status == 1)
  {
    setProviders(setContacts.data.data);
  } else {
    setProviders([]);
  }
}, [setContacts])


useEffect(() => {

  setEvents([]);

  if(Refresh_events ){
    setEvents(Refresh_events);
  }

  }, [Refresh_events]);

  const renderEventContent = (eventInfo) => { 
    let task_assignee =[];
    let taskData =eventInfo.event.extendedProps
    if (taskData.assignee_list && taskData.assignee_list.length > 0) {
      taskData.assignee_list.forEach((assignee) => {
        if (taskData.schedule_assignees.length > 0) {
          task_assignee = [];
          task_assignee = taskData.schedule_assignees ? taskData.schedule_assignees : []
        } else if (assignee && assignee.categories && ["myr_staff_task", "my_people","task_topic"].includes(assignee.main_key)){
      assignee && assignee.categories.map((taskAssign)=>{
        defaultTopics &&
        defaultTopics.forEach((topic) => {
          if (["myr_staff_task", "my_people","task_topic"].includes(topic.main_key)  ) {
          if (topic.main_key == assignee.main_key) {
            topic.categories.forEach((provider) => {
              if (taskAssign.filter_key == provider.filter_key) {
                // task_assignee.push({
                //   name: provider.title,
                //   profile_image: provider.image,
                //   id: provider.filter_key,
                // });
              }
            });
          }
        }
        });
      });
    }
  }) 
}
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div class="fc-event-main w100minus100per flex-grow-1 text-truncate"
        onClick={() => {
          setShowTaskInfo(true);
          dispatch(
            getSelectedTask(
              localStorage.getItem("selectedOffice"),
              eventInfo.event.id
            )
          );
          let width = calendarEventInfo.jsEvent.pageX;
          let height = calendarEventInfo.jsEvent.pageY;
          setHeightWidth({ width: width, height: height });
        }}
        >
          <div class="fc-event-main-frame">
              <div class="fc-event-time">
              {moment(eventInfo.event.start).format(" h:mm a") }
              </div>
              <div class="fc-event-title-container w100minus100per flex-grow-1">
                  <div class="fc-event-title fc-sticky text-truncate">
                  {eventInfo.event.title}
                  </div>
              </div>
          </div>
        </div>
        {task_assignee && task_assignee.length > 0 ? 
        <div className={`multi_image_wrapper dropdown-center dropdown`}>
          <div className="multi_inner_wrapper d-flex position-relative pe-2">
            <a href="#/" className="comman-image-box h15w15 rounded-circle multi-wrapper-border me-1 border-2">
              <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" 
              style={{ backgroundImage: `url('${ task_assignee.length> 0 && task_assignee[0].profile_image !== undefined ? task_assignee[0].profile_image :""}')` }} >

              </div>
            </a>
            {task_assignee && task_assignee.length > 1  ?
                <>
                  <a href="#/" className={`comman-image-box h15w15 with_overlay d-flex rounded-circle border-2 `}>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="dropdown dropdown-center team-list-dropdown" >
                        <div
                              className="d-flex rounded-circle"
                              type="button"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                            >
                              <div className="unread_count m-auto">
                                +{task_assignee ? task_assignee.length - 1 : " "}
                              </div>
                        </div>
                            <UncontrolledGeneralListing list={task_assignee ? task_assignee.map((data,index) => {
                            return {
                                name: data.name,
                                image: data.profile_image ? data.profile_image  : '',
                                id: index,
                            };
                          }) : <></> } />
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </a>
                </>
             :<></>
          }

          </div>
           
        </div> :<></> }
      </div>
    )
  };
  return (
    
    <>
     
      <div className={`comman-contnet-wrapper h-100 border-0`} id="FullCalendar">
        <div className="d-flex h-100">
          {showFilterSection ? (
            <div className="left-content-part w-300 h-100 res-d-none-imp p-0">
              <div className="left-title-header title-fonts fw-semibold border-bottom task-list-card w-100 p-15 mb-0">
                {_l("l_calendar_filter")}
              </div>
              <div className="comman_vertical_btn_h p-2 pb-0">
                <div className="comman-content-scroll-wrapper  ">
                  <div className="comman_vertical_btn_h scroll_60 overflow-hiiden-auto">
                    <div className="custom-accordian-main h-100">
                      <Accordion
                        className="h-100"
                        defaultActiveKey={["0"]}
                        alwaysOpen
                        // id={`FilterFor${category.title
                        //   .split(" ")
                        //   .join("")}`}
                        //   className="accordian-main-header"
                      >
                        {filterCategories.map((category, index) => {
                          return (
                            <Accordion.Item
                              eventKey={index}
                              key={index}
                              className=""
                            >
                              <Accordion.Header
                                className="custom-accordian-Header-sticky"
                                // id={`FilterFor${category.title
                                //   .split(" ")
                                //   .join("")}Header`}
                              >
                                {/* <div
                                    className="parent-left-header accordion-button  with-arrow-left collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#FilterFor${category.title
                                      .split(" ")
                                      .join("")}Btn`}
                                    aria-expanded="true"
                                    aria-controls={`DraftAccordianBtn${index}`}
                                  > */}
                                <div className="c-font f-12 title-fonts fw-semibold">
                                  <CaretUp
                                    size={14}
                                    weight="light"
                                    className="c-icons m-auto caretarrow"
                                  />
                                  <span>{category.title}</span>{" "}
                                  <span>[{category.value.length}]</span>
                                </div>
                                {/* </div> */}
                                <div className="parent-right-header"></div>
                              </Accordion.Header>
                              {/* <div
                                  id={`FilterFor${category.title
                                    .split(" ")
                                    .join("")}Btn`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`FilterFor${category.title
                                    .split(" ")
                                    .join("")}Header`}
                                  data-bs-parent={`#FilterFor${category.title
                                    .split(" ")
                                    .join("")}`}
                                > */}
                              <Accordion.Body className="mt-0 ps-3 pe-2">
                                <ul className="accordian-sub-listing comma-sub-list">
                                  {category.value.map((item, index2) => {
                                    return (
                                      <li
                                        key={index2}
                                        className="comman-list p-0 pt-2 pb-2"
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="comman-list-left w100minus50">
                                            <div className="d-flex align-items-center">
                                              <div className="c-list-icon">
                                                <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                                  <div
                                                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                    style={{
                                                      backgroundImage:
                                                        item.profile_image ||
                                                        `url('https://source.unsplash.com/random')`,
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="c-list-detail c-font f-14 ps-2 text-truncate c-font f-12 color-white-80">
                                                {item.name}{" "}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="comman-list-right">
                                            <label>
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id={`${category.key}_${item.id}`}
                                                  checked={
                                                    appliedFilters[
                                                      `${category.key}_${item.id}`
                                                    ]
                                                  }
                                                  onChange={() => {
                                                    setAppliedFilters({
                                                      ...appliedFilters,
                                                      [`${category.key}_${item.id}`]:
                                                        !appliedFilters[
                                                          `${category.key}_${item.id}`
                                                        ],
                                                    });
                                                    getCalendarData();
                                                  }}
                                                />
                                              </div>
                                            </label>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Accordion.Body>
                              {/* </div> */}
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    </div>
                  </div>
                  <div className="comman-content-bottom-btn">
                    <a href="#/" className="white-border-box color-white-60 dropdown-center dropdown w-100">
                      <div
                        type="button"
                        id="AddCalendarDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span>{_l("l_add_calendar")}</span>
                        <CaretDownFillIcon className="HW14 opacity-50" />
                       
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="right-content-part width-calc-300">
            <div className="custom-full-calendar">
              {showTaskInfo ? (
                <CalendarDropdown
                  projectId={selectedProject}
                  taskId={taskId}
                  providers={providers}
                  dayClick={dayClick}
                  meetingUrl={meetingUrl}
                  docType={"task"}
                  pageName={pageName}
                  data={{ contacts: shareContacts}}
                  isOutlookCalActive = {isOutlookCalendarActive}
                  handler={handleSelectedTask}
                  selectCalIds = {selectCalIds}
                  setShowTaskInfo={setShowTaskInfo}
                  taskNameOrStatusUpdate={(
                    task_id,
                    task_name_status,
                    flag = ""
                  ) => {
                    let eventUpdate = events.map((event) => {
                      if (task_id == event.id) {
                        if (flag != "") {
                          if (task_name_status == 1) {
                            event["color"] = "#b3b3b3";
                          } else if (task_name_status == 4) {
                            event["color"] = "#03A9F4";
                          } else if (task_name_status == 5) {
                            event["color"] = "#00ff6a";
                          }
                        } else {
                          event["title"] = task_name_status;
                        }
                        return event;
                      }
                      return event;
                    });
                    setEvents(eventUpdate);
                  }}
                />
              ) : (
                <></>
              )}
              {showRequestTaskInfo ? (
                <CalendarRequestTaskDropdown
                  projectId={selectedProject}
                  taskId={taskId}
                  handler={handleSelectedTask}
                  setShowRequestTaskInfo={setShowRequestTaskInfo}
                />
              ) : (
                <></>
              )}
               
              <div >
                <FullCalendar
                // plugins={[ dayGridPlugin, interactionPlugin, listPlugin ]}
                // ref={calendarRef}
                initialView={scheduleId > 0 ? "dayGridMonth" : "dayGridWeek"}
                dayMaxEventRows={true}
                firstDay= '1'
                eventDisplay= 'block'
                // eventDisplay= 'background'
                eventContent={renderEventContent}
                customButtons={{
                  myCustomButton: {
                    text: "",
                    click: function () {
                      setShowFilterSection(!showFilterSection);
                    },
                  },
                }}
                eventRender={() => {
                  
                }}
                
                views={{
                  dayGrid: {
                    // dayMaxEventRows: 3,
                  },
                  timeGridWeek:{
                    // eventMaxStack:1,
                    slotEventOverlap:false,
                  },
                  timeGridDay:{
                    
                  },
                }}
                headerToolbar={{
                  // left: "myCustomButton,prev,next,today",
                  left: "prev,next,today",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,dayGridDay",
                  // right: "dayGridMonth,dayGridWeek,dayGridDay",
                }}
                datesSet={(rangeInfo) => {
                  setStartDate(rangeInfo.start);
                  setEndDate(rangeInfo.end);
                  setCalendarLoaded(true);
                }}
                // events={calendarSearchString ? (events.filter((data)=>data.title.toLocaleLowerCase().includes(calendarSearchString.toLocaleLowerCase()))) : events }
                events={events}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                locales={ language === "english" ? esLocale : frLocale }
                locale={ language === "english" ? "en" : "fr" }     
                dateClick={(obj) => {
                  setTaskId(0);
                  setShowTaskInfo(true);
                  tempDate = obj.date;
                  dispatch({
                    type: SET_DATE_CLICK,
                    payload: { tempDate },
                  });
                  setDayClick(obj.date);
                  setMeetingUrl("");
                  var d = obj.date.getDate();
                  var m = obj.date.getMonth() + 1; //Month from 0 to 11
                  var y = obj.date.getFullYear();
                  var dyClick =
                    "" +
                    y +
                    "-" +
                    (m <= 9 ? "0" + m : m) +
                    "-" +
                    (d <= 9 ? "0" + d : d);
                  setPrevDate(dyClick);
                  jquery('td[data-date="' + prevDate + '"]').removeAttr(
                    "style"
                  );
                  // jquery('td[data-date="' + dyClick + '"]').attr(
                  //   "style",
                  //   "background-color:#1c3545;border:1px solid var(--color-green) !important"
                  // );
                  // dispatch(
                  //   getSelectedTask(
                  //     selectedProject,
                  //     0
                  //   )
                  // );
                  // dispatch(
                  //   dayClickModal(
                  //     selectedProject,
                  //     0,"",null,null,null
                  //   )
                  // );

                  localStorage.setItem("dayClick", JSON.stringify(obj.date));
                  window.dispatchEvent(new Event("dayClick"));
                }}
                contentHeight={"auto"}
                contentWidth={"auto"}
                eventClick={(eventInfo) => {
                  // if (eventInfo.event._def.publicId) {
                  //   dispatch(
                  //     getSelectedTask(
                  //       selectedProject,
                  //       eventInfo.event._def.publicId
                  //     )
                  //   );
                  //   setTaskId(eventInfo.event._def.publicId);
                  //   setShowTaskModal(true);
                  // }
                  setEventInfo(eventInfo);
                  if (eventInfo.event._def.publicId) {
                    let data= {
                      link: "",
                      newTaskFlag: 0,
                      newRequestFlag: 0,
                      task_id: 0,
                    };
                    dispatch({
                      type: SET_NEW_MEETING,
                      payload: { data },
                    });
                    setDayClick("");
                    setShowTaskInfo(false);
                    setMeetingUrl("");
                    setShowRequestTaskInfo(false);
                    setShowCalendarTaskInfo(false);
                    dispatch(checkIsAssigned(eventInfo.event._def.publicId));
                    setSelectCalIds(eventInfo.event._def.extendedProps.selected_cal_ids ? eventInfo.event._def.extendedProps.selected_cal_ids : []);
                    localStorage.setItem("assign_contact", JSON.stringify([]));
                    data = {
                      status: 0,
                      data: []
                    }
                    dispatch({
                      type: SET_CONTACT_DATA,
                      payload: { data },
                    });
                    setProviders([]);
                    setTempTaskId('');
                    setTempCalendarId('');
                    // setTimeout(() => {
                    if (eventInfo.event._def.extendedProps.is_requested == 1) {
                      // setShowTaskInfo(true);
                      localStorage.setItem(
                        "calendarDropdown",
                        "CalendarRequestTaskDropdownClose"
                      );
                      let width = eventInfo.jsEvent.pageX;
                      let height = eventInfo.jsEvent.pageY;
                      setHeightWidth({ width: width, height: height });
                      dispatch(
                        getSelectedTask(
                          selectedProject,
                          eventInfo.event._def.publicId,
                          eventInfo.event._def.extendedProps.is_requested == 1
                            ? 1
                            : null,
                        )
                      );
                    } else if (
                      eventInfo.event._def.extendedProps.is_requested == 0
                    ) {
                      // setShowTaskInfo(true);
                      localStorage.setItem(
                        "calendarDropdown",
                        "CalendarDropdownClose"
                      );
                      let width = eventInfo.jsEvent.pageX;
                      let height = eventInfo.jsEvent.pageY;
                      setHeightWidth({ width: width, height: height });
                     
                      dispatch(
                        getSelectedTask(
                          selectedProject,
                          eventInfo.event._def.publicId,
                          eventInfo.event._def.extendedProps.is_requested == 1 ? 1 : null,
                          1,
                          0
                        )
                      );

                      getMeetingLink(eventInfo.event._def.publicId, '');
                    } else if (
                      eventInfo.event._def.extendedProps.calendar_id != ""
                    ) {
                      if (isOutlookCalendarActive === true) {
                        
                        dispatch(get_refresh_request_data(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), [], selectedProject,  'outlook_calendar', 0));
                        localStorage.setItem(
                          "outlookCalendarDropdown",
                          "OutlookCalendarDropdownClose"
                        );
                        if (
                          jquery.isNumeric(eventInfo.event._def.publicId) ==
                          true
                        ) {
                          // setShowTaskInfo(true);
                          localStorage.setItem(
                            "calendarDropdown",
                            "CalendarDropdownClose"
                          );
                          let width = eventInfo.jsEvent.pageX;
                          let height = eventInfo.jsEvent.pageY;
                          setHeightWidth({ width: width, height: height });
                          dispatch(
                            getSelectedTask(
                              selectedProject,
                              eventInfo.event._def.publicId,
                              eventInfo.event._def.extendedProps.is_requested ==
                                1
                                ? 1
                                : null,
                              1
                            )
                          );

                          getMeetingLink(eventInfo.event._def.publicId, '');
                        } else {
                          // setShowTaskInfo(true);
                          setTempTaskId(eventInfo.event._def.publicId);
                          setTempCalendarId(eventInfo.event._def.extendedProps.calendar_id);
                          getMeetingLink(eventInfo.event._def.publicId, eventInfo.event._def.extendedProps.calendar_id);
                          localStorage.setItem(
                            "outlookCalendarDropdown",
                            "OutlookCalendarDropdownClose"
                          );
                          let width = eventInfo.jsEvent.pageX;
                          let height = eventInfo.jsEvent.pageY;
                          setHeightWidth({ width: width, height: height });
                          dispatch(
                            getSelectedTaskCalendar(
                              eventInfo.event._def.extendedProps.calendar_id,
                              eventInfo.event._def.publicId,
                              isOutlookCalendarActive,
                              selectedProject
                            )
                          );
                        }
                      } 
                      if (isGoogleCalendarActive === 1 && googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2) {
                        
                      dispatch(get_refresh_request_data(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), [], selectedProject,  'google_calendar', 0));
                        
                        if (isNumeric(eventInfo.event._def.publicId) == true) {
                          // setShowTaskInfo(true);
                          localStorage.setItem(
                            "calendarDropdown",
                            "CalendarDropdownClose"
                          );
                          
                          let width = eventInfo.jsEvent.pageX;
                          let height = eventInfo.jsEvent.pageY;
                          setHeightWidth({ width: width, height: height });
                          dispatch(
                            getSelectedTask(
                              selectedProject,
                              eventInfo.event._def.publicId,
                              eventInfo.event._def.extendedProps.is_requested ==
                                1
                                ? 1
                                : null,
                              1,
                              0,
                              "google_calendar",
                            )
                          );
                          getMeetingLink(eventInfo.event._def.publicId, '');
                        } else {
                          // setShowTaskInfo(true);
                          let width = eventInfo.jsEvent.pageX;
                          let height = eventInfo.jsEvent.pageY;
                          setHeightWidth({ width: width, height: height });
                          localStorage.setItem(
                            "googleCalendarDropdown",
                            "GoogleCalendarDropdownClose"
                          );
                         
                          dispatch(
                            getSelectedTaskCalendar(
                              eventInfo.event._def.extendedProps.calendar_id,
                              eventInfo.event._def.publicId,
                              isGoogleCalendarActive,
                              selectedProject
                            )
                          );
                          getMeetingLink(eventInfo.event._def.publicId, eventInfo.event._def.extendedProps.calendar_id);
                        }
                        // window.dispatchEvent(new Event("join_meeting"));
                        // editGeneral(eventInfo.event._def);
                      }
                    }
                  }
                }}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {
              showTaskModal ? <CommanOffCanvas
                show={showTaskModal}
                handleClose={() => {
                  setShowTaskModal(false);
                  // setIsRequestTask()
                  setCommonModalData();
                  setTaskId(0);
                  setTask_type('')
                }}
                pinHandler={(id, type, flag) => {
                  // handlePinUnPin(id, type, flag);
                }}
                data={commonModalData}
                pageName={pageName}
                docType={task_type}
                setSelectedId={setTaskId}
                selectedTaskId={taskId}
                IsKanbanView={false}
                meetingUrl={meetingUrl}
                canvasfullHeightwithHeaderFooter={true}
                handleParentComponentAction={(type, taskData) => {
                  if (task_type == 'task') {
                    if (type == 'attachmentCountUpdate') {
                      // updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
                      return;
                    } else if (type == 'handleSelectedTask') {
                      handleSelectedTask(taskData.task_id, taskData.flag)
                      return;
                    } else if (type == 'convertedTaskAction') {
                      handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                      return;
                    }
                  } else if (task_type == 'request-task') {

                    if (type == 'convertedTaskAction') {
                      handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                      return;
                    }
                  }

                }}
              /> : <></>
            }
      {/* {showTaskModal ? (
        <TaskDetail
          projectId={selectedProject}
          taskId={taskId}
          offCanvasshow={showTaskModal}
          offCanvashandleShow={setShowTaskModal}
          offCanvashandleClose={() => {
            setTaskId(0);
            setShowTaskModal(false);
          }}
          convertRequestTaskId={convertTaskId}
          handler={handleSelectedTask}
        />
      ) : (
        <></>
      )} */}

      {showReqestedTaskModal ? (
        <TaskRequestModal
          projectId={selectedProject}
          show={showReqestedTaskModal}
          handleClose={() => {
            setShowReqestedTaskModal(false);
            setTaskId(0);
          }}
          handleShow={() => {
            setShowReqestedTaskModal(true);
          }}
          taskId={taskId}
          handler={handleSelectedTask}
          convertRequestTaskId={convertTaskId}
          setConvertTask={() => {
            // getProjectTasks();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default fullCalendar;

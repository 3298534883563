import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomerServices from "../../services/customer-services";
import userpaymnetServices from "../../services/userpaymnet-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import { isIBANModalOpen } from "../../actions/customer";
import { _l, showError, showSuccess, showSuccessMessage, validateIBAN } from "../../hooks/utilities";
import PaymentSetupModal from "../Modals/PaymentSetupModal";
import { Form } from "react-bootstrap";
import CommanLoader from "../Loader/CommanLoader";
import Select from "react-select";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { PencilSimpleLine, Plus } from "phosphor-react";
import IBanEditModal from "../Modals/IBanEditModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CardIcon from "../../assets/images/CardIcon.svg";

export function PremiumBillingTable({ BillingsData = [], handlerIBAN, IBANStatus = 1, insideModal = false, hideInbuiltFooter }) {
  let spaces = JSON.parse(localStorage.getItem("spaces"));
  const userType = localStorage.getItem("user_type");

  spaces = spaces.filter((x) => x.status == "1" || x.status == "2");
  const [selectedTab, setSelectedTab] = useState("single");
  const [singleIBAN, setSingleIBAN] = useState("");
  const [singleSpaceTitle, setSingleSpaceTitle] = useState(
    localStorage.getItem("company_name")
  );
  const [singleSpaceBillingAddress, setSingleSpaceBillingAddress] = useState(
    localStorage.getItem("address") || ""
  );
  const [individualIBAN, setIndividualIBAN] = useState([]);
  const [multipleSpaceTitles, setMultipleSpaceTitles] = useState(
    spaces.map((x) => x.office_title)
  );
  const [premiumestimateSelected, setPremiumestimateSelected] = useState(
    spaces.map(() => 0)
  );
  const [multipleSpaceBillingAddress, setMultipleSpaceBillingAddress] =
    useState([]);
  const [multipleSuggestedAddresses, setMultipleSuggestedAddresses] = useState(
    []
  );
  const [categoryListing, setCategoryListing] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [operatorBillingData, setOperatorBillingData] = useState([
  ]);
  const [showPaymentSetupSteps, setShowPaymentSetupSteps] = useState(false);
  const [loadingWalletList, setLoadingWalletList] = useState(false);
  const [saveOperationInProgress, setSaveOperationInProgress] = useState(false);

  const [IBanModal, setIBanModal] = useState(false);
  const [IBanModalForAdd, setIBanModalForAdd] = useState(false);
  const [IBANListing, setIBANListing] = useState([]);
  const [IBanDetails, setIBanDetails] = useState({
    account_iban:"",
    company_name:localStorage.getItem("company_name") ,
    city: "",
    postal_code:"",
    company_address: localStorage.getItem("company_address")
  });
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();


  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const inputRefs = useMemo(() => spaces.map(() => React.createRef()), []);
  const autoCompleteRefs = useMemo(
    () => spaces.map(() => React.createRef()),
    []
  );

  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };
  let spaceArr = spaces.map((space) => {
    return {
      label: space.office_title || space.postal_address,
      value: space.project_id,
    };
  })
    
  const handleChange = (name, value, key, wallet_id) => {
    
    switch(name)
    {
      // selected_region
      case "selected_category":
        updateProviderWallet(wallet_id, operatorBillingData[key].selected_space.map((category => category.value)), value.map((category) => category.value), operatorBillingData[key].selected_region.map((region => region.value)), operatorBillingData[key].title);
        break;
      case "selected_space":
        updateProviderWallet(wallet_id, value.map((category) => category.value), operatorBillingData[key].selected_category.map((category => category.value)), operatorBillingData[key].selected_region.map((region => region.value)), operatorBillingData[key].title);
        break;
      case "wallet_label":
        updateProviderWallet(wallet_id, operatorBillingData[key].selected_space.map((category => category.value)), operatorBillingData[key].selected_category.map((category => category.value)), operatorBillingData[key].selected_region.map((region => region.value)), value);
        break;
      case "regions":
        updateProviderWallet(wallet_id, operatorBillingData[key].selected_space.map((category => category.value)), operatorBillingData[key].selected_category.map((category => category.value)), value.map((region) => region.value), operatorBillingData[key].title);
        break;
      default:
        break;
    }

    let Arr = operatorBillingData
    Arr[key] = {
      ...Arr[key],
      [name]: value
    }
    setOperatorBillingData(Arr.slice());
  }

  const individualIbanText = (e, project_id) => {
    const reg = new RegExp("^[A-Za-z0-9 ]+$");
    const inputValue = e.target.value;
    if (reg.test(inputValue) || inputValue == "") {
      setIndividualIBAN(
        individualIBAN.map((obj) => {
          if (obj.project_id == project_id) {
            obj["account_iban"] = inputValue;
            return obj;
          }
          return obj;
        })
      );
    }
  };

  const validationindividualIban = () => {
    for (let index = 0; index < individualIBAN.length; index++) {
      if (
        individualIBAN[index]["account_iban"] == "" ||
        individualIBAN[index]["account_iban"] == null
      ) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_enter_valid_IBAN_for_all_spaces")
          )
        );
        return false;
      } else if (
        individualIBAN[index]["account_iban"].length < 13 ||
        individualIBAN[index]["account_iban"].length > 34
      ) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_enter_IBAN_number_between_13_to_34")
          )
        );
        return false;
      } else if (
        !multipleSpaceTitles.length ||
        multipleSpaceTitles[index] == null ||
        !multipleSpaceTitles[index].length
      ) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_all_office_title_are_mandatory")
          )
        );
        return false;
      } else if (
        !multipleSpaceBillingAddress[index] ||
        multipleSpaceBillingAddress[index] == null ||
        !multipleSpaceBillingAddress[index].length
      ) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_all_office_billing_addresses_are_mandatory")
          )
        );
        return false;
      }
    }
    let premiumEstimateFlag = false;
    for (let index = 0; index < premiumestimateSelected.length; index++) {
      if (premiumestimateSelected[index]) {
        premiumEstimateFlag = true;
        return true;
      }
    }
    if (!premiumEstimateFlag) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_check_any_user_iban_for_premium")
        )
      );
      return false;
    }
    return true;
  };

  const updateStaffIban = async () => {
    if (selectedTab == "single") {
      if (validateIBAN(singleIBAN)) {
        try {
          const response = await CustomerServices.setupStaffIBANData(singleIBAN);
          if (response.status) {
            dispatch(
              showMessage(
                "sucess",
                _l("l_success"),
                _l("l_iban_account_updated")
              )
            );
            handlerIBAN(true);
          }
          else {
            showError("l_something_went_wrong");
          }
        }
        catch (e) {
          console.error(e);
          handlerIBAN(false);
        }
        finally {
        }
      }
      else {
        showError("l_please_enter_valid_iban_number");
        return;
      }
    }
    else {
      let ibanList = [];
      const invalidIban = individualIBAN.find((w) => w.account_iban != "" && !validateIBAN(w.account_iban));
      if (invalidIban) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            `${_l("l_please_enter_valid_iban_number")}: ${invalidIban.postal_address}`
          )
        );
        return;
      }
      individualIBAN.forEach((iban) => {
        ibanList.push({
          myr_account_iban: iban.account_iban,
          project_id: iban.project_id,
        });
      });
      if (ibanList.length) {
        try {
          const response = await CustomerServices.setupStaffIBANData("", ibanList);
          if (response.status) {
            dispatch(
              showMessage(
                "sucess",
                _l("l_success"),
                _l("l_iban_account_updated")
              )
            );
            handlerIBAN(true);
          }
          else {
            showError("l_something_wentwrong");
          }
        }
        catch (e) {
          console.log(e)
          handlerIBAN(false);
        }
        finally {
        }
      }
    }
  };

  const handleSave = () => {
    if (userType === "staff") {
      updateStaffIban();
    }
    else if (selectedTab == "single") {
      if (singleIBAN == "") {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_enter_valid_iban_number")
          )
        );
        return false;
      } else if (singleIBAN.length < 13 || singleIBAN.length > 34) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_enter_IBAN_number_between_13_to_34")
          )
        );
        return false;
      } else if (!singleSpaceTitle.trim().length) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_enter_space_title")
          )
        );
        return false;
      } else if (!singleSpaceBillingAddress.length) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_enter_valid_billing_address")
          )
        );
        return false;
      } else {
        setSaveOperationInProgress(true);
        CustomerServices.setIBANSetting("single", [
          singleIBAN,
          singleSpaceTitle,
          singleSpaceBillingAddress,
        ]).then((res) => {
          if (res.status == 1) {
            dispatch(
              showMessage(
                "sucess",
                _l("l_success"),
                res.message ? res.message : ""
              )
            );
            handlerIBAN();
            localStorage.setItem("iban_modal_open", 0);
            dispatch(isIBANModalOpen());
          } else {
            dispatch(
              showMessage(
                "unsucess",
                _l("l_error"),
                res.message ? res.message : _l("l_something_went_wrong")
              )
            );
          }
        }).finally(() => {
          setSaveOperationInProgress(false);
        });
      }
    } else {
      if (validationindividualIban()) {
        individualIBAN.forEach((office, index) => {
          office.billing_address = multipleSpaceBillingAddress[index];
          office.title = multipleSpaceTitles[index];
          office.user_iban_for_premium = premiumestimateSelected[index];
        });
        setSaveOperationInProgress(true);
        CustomerServices.setIBANSetting(selectedTab, individualIBAN).then(
          (res) => {
            if (res.status === 1) {
              dispatch(
                showMessage(
                  "sucess",
                  _l("l_success"),
                  res.message ? res.message : ""
                )
              );
              handlerIBAN();
              localStorage.setItem("iban_modal_open", 0);
              dispatch(isIBANModalOpen());
            } else {
              dispatch(
                showMessage(
                  "unsucess",
                  _l("l_error"),
                  res.message ? res.message : _l("l_something_went_wrong")
                )
              );
            }
          }
        ).finally(() => {
          setSaveOperationInProgress(false);
        });
      }
    }
  };

  const setAutoSuggetAdress = (index) => {
    try {
      autoCompleteRefs[index].current =
        new window.google.maps.places.Autocomplete(
          inputRefs[index].current,
          options
        ).addListener("place_changed", () => {
          setMultipleSpaceBillingAddress(
            Object.values({
              ...multipleSpaceBillingAddress,
              [index]: inputRefs[index].current.value,
            })
          );
          setMultipleSuggestedAddresses(
            Object.values({
              ...multipleSuggestedAddresses,
              [index]: inputRefs[index].current.value,
            })
          );
        });
    } catch (e) {
    }
  };

  const getOperatorCompanyAccountList = async() => {
    if(hideInbuiltFooter && userType === "operator")
    {
      try {
        const response = await CustomerServices.getProviderCompanyAccounts();
        setLoadingWalletList(true);
        const categoryResponse = await CustomerServices.getProviderCategoriesForWallet();
        const regionsResponse = await CustomerServices.getRegionList()

        if(response.status && categoryResponse.status && regionsResponse.status)
        {
          const categories = categoryResponse.data.filter((category, index, self) => self.findIndex((w) => w.name == category.name) === index).map((w) => {
            return {value: w.provider_id , label: w.name}
          });
          const regions = regionsResponse.data.map((data) => {
            return {
              label: data.name,
              value: data.id,
            };
          });

          categories.sort((a, b) => a.label.localeCompare(b.label));

          setRegionList(regions);

          setOperatorBillingData(response.data.map((w) => {
            return {id: w.id, title: w.title, is_active: w.is_active, acc_iban: w.account_iban, company_name: w.company_name, billing_address: w.company_address, selected_category: categories.filter((category) => w.stripe_category_id.includes(category.value)), selected_space: spaceArr.filter((space) => w.stripe_project_id.includes(space.value)), selected_region: regions.filter((region) => w.stripe_region_id.includes(region.value))}
          }));
          setCategoryListing(categories);
        }
        else {
          showError(response.message);
        }
      }
      catch(e)
      {
        console.error(e);
      }
      finally {
        setLoadingWalletList(false);
      }
    }
  };

  const updateProviderWallet = async(id, projects, categories, regions, wallet_label) => {
    try {
      const response = await CustomerServices.updateProviderCategoryWallet(id, projects, categories, regions, wallet_label);
      if(!response.status)
      {
        showError(response.message);
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      console.error(e);
    }
  };

  const updateWalletStatus = async(e, key) => {
    try {
      await CustomerServices.activeInactivateWallet(operatorBillingData[key].id);
      setOperatorBillingData(Object.values({...operatorBillingData, [key]: {...operatorBillingData[key], is_active: e.target.checked ? 1 : 0}}));
    }
    catch(e)
    {
      setOperatorBillingData(Object.values({...operatorBillingData, [key]: {...operatorBillingData[key], is_active: e.target.checked ? 0 : 1}}));
      console.error(e);
    }
  }

  const addNewIBAN = () =>{
    try {
      userpaymnetServices.setNewIBANForWallet(
        IBanDetails.account_iban,
        IBanDetails.company_address,
        IBanDetails.city,
        IBanDetails.postal_code,
      ).then((res)=>{
        if (res && res.status) {
          window.open(res.data.site, "_blank");
          showSuccess("l_iban_added");
        }
        else {
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      })
    } catch (error) {
      
    } finally {
      setIBanModal(false);
      setLoader(false);
          setIBanModalForAdd(false);
          setIBanDetails({
              account_iban:"",
              company_name:"",
              city: "",
              postal_code:"",
              company_address:""
          });
    }

  } 

  const editIBANDetails = ()=>{
    try {
      
    } catch (error) {
      
    } finally {
      setIBanModal(false);
      setLoader(false);
          setIBanModalForAdd(false);
          setIBanDetails({
              account_iban:"",
              company_name:"",
              city: "",
              postal_code:"",
              company_address:""
          });
    }
  }

  useEffect(() => {
    getOperatorCompanyAccountList();
  }, [])
  
  useEffect(() => {
    setSingleIBAN([]);
    if (BillingsData) {
      let spaces = JSON.parse(localStorage.getItem("spaces"));
      let newSpaces = [];
      if (spaces) {
        Object.keys(spaces).forEach((i) => {
          if (spaces[i].status == 1 || spaces[i].status == 2) {
            newSpaces.push({
              account_iban: "",
              project_id: spaces[i]["project_id"],
              postal_address: spaces[i]["postal_address"],
              user_iban_for_premium: 0,
            });
          }
        });
      }
      if (userType === "staff") {
        // if(BillingsData && BillingsData.single_iban_for_all_spaces == 1)
        {
          setSingleIBAN(BillingsData.unique_iban);
          setSingleSpaceBillingAddress(BillingsData.billing_address);
          setSingleSpaceTitle(BillingsData.title);
        }
        // else
        {
          newSpaces.forEach((space) => {
            let spaceForIban = BillingsData.project_data.find((w) => w.project_id == space.project_id);
            if (spaceForIban) {
              space.account_iban = spaceForIban.myr_account_iban;
            }
          });
        }
        setMultipleSpaceBillingAddress(Array.from({ length: newSpaces.length }).fill(BillingsData.billing_address));
      }
      else {
        if (BillingsData && BillingsData.single_iban_for_all_spaces == 1) {
          setSingleIBAN(BillingsData.account_iban);
          if (BillingsData.billing_address)
            setSingleSpaceBillingAddress(BillingsData.billing_address);
          if (BillingsData.title) setSingleSpaceTitle(BillingsData.title);
          setSelectedTab("single");
        } else {
          if (BillingsData.iban_accounts) {
            newSpaces = BillingsData.iban_accounts;
            setSelectedTab(BillingsData.single_iban_for_all_spaces == 0 ? "individual" : 'single');
            setMultipleSpaceBillingAddress(
              BillingsData.iban_accounts.map((x) => x.billing_address)
            );
            setMultipleSpaceTitles(
              BillingsData.iban_accounts.map((x) => x.title)
            );
            setPremiumestimateSelected(
              BillingsData.iban_accounts.map((x) =>
                x.user_iban_for_premium ? x.user_iban_for_premium : 0
              )
            );
          } else {
            setSelectedTab(IBANStatus == 2 || IBANStatus == 0 ? 'individual_with_software' : 'single');
            // setSelectedTab(IBANStatus == 2 ? 'individual_with_software' : IBANStatus == 0 ? "individual" : 'single');
          }
        }
      }
      setIndividualIBAN(newSpaces);
    }
  }, [BillingsData]);

  useEffect(() => {
    if (
      singleSpaceBillingAddress &&
      singleSpaceBillingAddress.trim().length &&
      inputRef.current
    ) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options
        ).addListener("place_changed", () => {
          setSingleSpaceBillingAddress(inputRef.current.value);
        });
      } catch (e) {
      }
    }
  }, [singleSpaceBillingAddress]);

  useEffect(() => {
    userpaymnetServices.getIBANlisting().then((response)=>{
      if (response && response.status) {
        setIBANListing(response.data)
      } else {
        showError("l_data_not_found")
      }
    })
  }, [])
  

  return (
    <>
      <div className=" overflow-hiiden-auto h_50 d-flex flex-column pe10per">
        {/* <div className={`${insideModal ? "pb-3 border-bottom" : "custom-modal-section-sticky p-10"} d-none`}>
          {
            userType == 'operator' && !insideModal ?
              <div className="row align-items-center">
                <div className="col-xl-5">
                  <Form.Label className="input-label no-asterisk color-white title-fonts m-0">
                    {_l("l_iban_for_all_spaces")}
                  </Form.Label>
                </div>
                <div className="col-xl-7 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      // let arr = operatorBillingData
                      // arr.push({id: arr.length + 1,acc_iban:"",company_name:"",billing_address:"",selected_category:"",selected_space:""})
                      // setOperatorBillingData(arr.slice())
                      setShowPaymentSetupSteps(true);
                    }}
                  >
                    {_l("l_add_new")}
                  </button>
                </div>
              </div>
              :
              <div className="row align-items-center">
                <div className="col-xl-5">
                  <Form.Label className="input-label no-asterisk color-white title-fonts m-0">
                    {selectedTab == "single" ? _l('l_single') : _l('l_indevidual')}{" "}
                    {_l("l_iban_for_all_spaces")}
                  </Form.Label>
                </div>
                <div className="col-xl-7">
                  <Form className="d-flex justify-content-end">
                    <Form.Check
                      type="radio"
                      label={_l("l_single")}
                      id="disabled-default"
                      className="me-3"
                      checked={selectedTab == "single"}
                      onClick={() => {
                        setSelectedTab("single");
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label={_l("l_individual")}
                      id="disabled-default"
                      className="me-3"
                      checked={selectedTab == "individual"}
                      onClick={() => {
                        setSelectedTab("individual");
                      }}
                    />
                    {
                      userType === "staff"
                        ?
                        <React.Fragment></React.Fragment>
                        :
                        <Form.Check
                          type="radio"
                          label={_l("l_individual_with_software")}
                          id="disabled-default"
                          className="me-3"
                          checked={selectedTab == "individual_with_software"}
                          onClick={() => {
                            setSelectedTab("individual_with_software");
                          }}
                        />
                    }
                  </Form>
                </div>
              </div>
          }
          {/*<hr />* /}
        </div> */}
        
        {
          userType == 'operator' && !insideModal && !loadingWalletList ?
          operatorBillingData.length > 0 ? operatorBillingData.map((bill,key) => {
            return (<div className={`p-2 flex-grow-1`}>
              <Form.Group controlId="formBasicEmail">
                <div className={`p-15 task-list-card w-100 no-hover mb-2 `}>
                  {/* <div className="border-bottom d-flex justify-content-between  pb-2 mb-1">
                  <Form.Check label="Use In premium" type="checkbox" />
                </div> */}
                  <div className="row">
                    <div className="col-xl-12 pb-3">
                      <div className="form-check-label p-0 title-font c-font f-12 color-white-60">
                        {bill.is_active > 0
                          ? `${_l("l_deactivate")}`
                          : _l("l_activate")}
                      </div>
                      <div className="form-check form-check-inline m-0 ps-2">
                          <Form.Check
                            className=" with-no-label-space"
                            type="switch"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            defaultChecked={bill.is_active > 0}
                            onChange={(e) => {updateWalletStatus(e, key)}}
                          />
                      </div>
                    </div>
                    <div className={`${bill.is_active == 0 ? "for-disabled" : ""} row`}>
                      <div className="col-xl-6 pb-3">
                        <Form.Label className="input-label no-asterisk ">
                          {" "}
                          {_l("l_wallet")}
                        </Form.Label>
                        <Form.Control
                          className=" pe-2"
                          type="text"
                          placeholder={`${_l("l_wallet")}`}
                          aria-label="default input example"
                          defaultValue={bill.title}
                          onBlur={(e) => {
                            const reg = new RegExp("^[A-Za-z0-9 ]+$");
                            const inputValue = e.target.value;
                            if (reg.test(inputValue) || inputValue == "")
                            {
                              handleChange("wallet_label", e.target.value, key, bill.id);
                            }
                          }}
                        />
                      </div>
                      <div className="col-xl-6 pb-3">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_wallet_regions")}
                        </Form.Label>
                        <Select
                          placeholder={_l("l_select_space_selection")}
                          className="custom-select-menu"
                          classNamePrefix="react-select"
                          options={regionList}
                          defaultValue={bill.selected_region}
                          onChange={(value) => {
                            // setSingleSpaceBillingAddress(e.target.value);
                            handleChange("regions", value, key, bill.id);
                          }}
                          isMulti
                        />
                      </div>
                      <div className="col-xl-6 pb-3">
                        <Form.Label className="input-label no-asterisk ">
                          {" "}
                          {_l("l_account_iban")}
                        </Form.Label>
                        <Form.Control
                          className=" pe-2"
                          type="text"
                          disabled
                          placeholder={`${_l("l_account_iban_placeholder")}`}
                          aria-label="default input example"
                          value={bill.acc_iban}
                          onChange={(e) => {
                            const reg = new RegExp("^[A-Za-z0-9 ]+$");
                            const inputValue = e.target.value;
                            if (reg.test(inputValue) || inputValue == "")
                            handleChange("acc_iban", e.target.value, key, bill.id);
                          }}
                        />
                      </div>
                      <div className="col-xl-6 ">
                        <Form.Label className="input-label no-asterisk ">
                          {" "}
                          {_l("l_company_name")}
                        </Form.Label>
                        <Form.Control
                          className=" pe-2"
                          type="text"
                          disabled
                          placeholder={_l("l_company_name_placeholder")}
                          aria-label="default input example"
                          value={bill.company_name}
                          readOnly={userType === "staff"}
                          onChange={(e) => {
                            const reg = new RegExp("^[A-Za-z0-9d, ]+$");
                            const inputValue = e.target.value;
                            if (reg.test(inputValue) || inputValue == "")
                              // setSingleSpaceTitle(inputValue);
                              handleChange("company_name", inputValue, key, bill.id);
                          }}
                        />
                      </div>
                      <div className="col-12 mb-2">
                        <Form.Label className="input-label no-asterisk">
                          {" "}
                          {_l("l_billing_address")}
                        </Form.Label>
                        <Form.Control
                          className=" pe-2"
                          type="text"
                          ref={inputRef}
                          disabled
                          placeholder={_l("l_billing_address_placeholder")}
                          aria-label="default input example"
                          value={bill.billing_address}
                          readOnly={userType === "staff"}
                          onChange={(e) => {
                            // setSingleSpaceBillingAddress(e.target.value);
                            handleChange("billing_address", e.target.value, key, bill.id);
                          }}
                        />
                      </div>
                      <div className="col-xl-6">
                        <Form.Label className="input-label no-asterisk ">
                          {" "}
                          {_l("l_select_category")}
                        </Form.Label>
                        <Select
                          placeholder={_l("l_select_category")}
                          className="custom-select-menu"
                          classNamePrefix="react-select"
                          options={categoryListing}
                          defaultValue={bill.selected_category}
                          onChange={(option) => { handleChange("selected_category", option, key, bill.id) }}
                          isMulti
                        />
                      </div>
                      <div className="col-xl-6">
                        <Form.Label className="input-label no-asterisk ">
                          {" "}
                          {_l("l_select_space_selection")}
                        </Form.Label>
                        <Select
                          placeholder={_l("l_select_space_selection")}
                          className="custom-select-menu"
                          classNamePrefix="react-select"
                          options={spaceArr}
                          defaultValue={bill.selected_space}
                          onChange={(option) => { handleChange("selected_space", option, key, bill.id) }}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>)
          })
          :
          <><CommanPlaceholder imgType="no-IBAN" placeholderText = {_l("l_no_data")} /></>
            :
            <div className={` ${insideModal ? "mt-2" : "pt-15"} flex-grow-1`}>
              {selectedTab == "single" ? (
                <Form.Group controlId="formBasicEmail">
                  {/* <div className={`${insideModal ? "p-15" : "p-15"} task-list-card w-100 no-hover mb-2 d-none`}>
                    {/* <div className="border-bottom d-flex justify-content-between  pb-2 mb-1">
                  <Form.Check label="Use In premium" type="checkbox" />
                </div> * /}
                <div className="row">
                  <div className="col-xl-6 pb-3">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                      <Form.Label className="input-label no-asterisk ">
                        {" "}
                        {_l("l_account_iban")}
                      </Form.Label>
                      <div className="comman_action_icon">
                   
                  </div>
                    </div>
                    <Form.Control
                      className=" pe-2"
                      type="text"
                      placeholder={`${_l("l_account_iban_placeholder")}`}
                      aria-label="default input example"
                      value={singleIBAN}
                      onChange={(e) => {
                        const reg = new RegExp("^[A-Za-z0-9 ]+$");
                        const inputValue = e.target.value;
                        if (reg.test(inputValue) || inputValue == "")
                          setSingleIBAN(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-6 ">
                    <Form.Label className="input-label no-asterisk ">
                      {" "}
                      {_l("l_company_name")}
                    </Form.Label>
                    <Form.Control
                      className=" pe-2"
                      type="text"
                      placeholder={_l("l_company_name_placeholder")}
                      aria-label="default input example"
                      value={singleSpaceTitle}
                      readOnly={userType === "staff"}
                      onChange={(e) => {
                        const reg = new RegExp("^[A-Za-z0-9d, ]+$");
                        const inputValue = e.target.value;
                        if (reg.test(inputValue) || inputValue == "")
                          setSingleSpaceTitle(inputValue);
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <Form.Label className="input-label no-asterisk ">
                      {" "}
                      {_l("l_billing_address")}
                    </Form.Label>
                    <Form.Control
                      className=" pe-2"
                      type="text"
                      ref={inputRef}
                      placeholder={_l("l_billing_address_placeholder")}
                      aria-label="default input example"
                      value={singleSpaceBillingAddress}
                      readOnly={userType === "staff"}
                      onChange={(e) => {
                        setSingleSpaceBillingAddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div> */}
              {/* ---------------------------- New payment card start ---------------------------- */}
              <div className="row">
                      
                        {IBANListing && IBANListing.length > 0 && IBANListing.map((iban)=>{
                          return(<>
                          <div className="col-lg-6 mb-3">
                          <div className="border no-hover p-15 task-list-card w-100 ">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="align-items-center bg-white-03 d-flex p-10 radius_5 lighthtemebg">
                              <img src={CardIcon} className="h40w40 imginvert" />
                              <span className="c-font f-16 fw-semibold title-fonts ps-3">IBAN</span>
                            </div>
                            <div className="comman_action_icon">
                            <a href="#/" onClick={() => {
                              setIBanDetails(iban)
                              setIBanModal(true);
                              setIBanModalForAdd(false);
                              }} className="action_icon with_bg h32w32">
                              <PencilSimpleLine size={14} className="c-icons m-auto" weight="light"/>
                            </a>
                            </div>
                          </div>
                            <div className="d-flex flex-column pt-20px">
                            <div className="c-font f-14 color-white fw-semibold title-fonts mb-2">{iban.company_name}</div>
                            <div className="c-font f-14 color-white-80 mb-2 title-fonts">{iban.account_iban}</div>
                            <div className="c-font f-14 color-white-80">{iban.company_address}</div>
                            <div class="border-bottom-10 mb-10 mt-10"></div>
                            {["bottom-start"].map((placement) => (
                                  <OverlayTrigger
                                    trigger="hover"
                                    key={placement}
                                    placement={placement}
                                    delay={1000}
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-${placement}`}
                                        className="overflowscroll"
                                      >
                                        {/* <Popover.Header as="h3">{`Popover ${placement}`}</Popover.Header> */}
                                        <Popover.Body className="p-0 w-250">
                                          <div className="popover-detail-wrapper padding_10">
                                            {
                                              iban.active_subscription && iban.active_subscription.length > 0 &&  iban.active_subscription.map((subscrption)=>{
                                                return(<>
                                            <div className="time-text py-1 border-bottom c-font f-12 color-white d-flex align-items-center ">
                                              <div className="me-2">#{subscrption.id} : </div>
                                             <div className="">{subscrption.name}</div>
                                              </div>
                                                </>)
                                              })
                                            }

                                          </div>

                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a href="#/" className={`${iban.active_subscription && iban.active_subscription.length > 0 ? "color-green " : "text-warning"} fw-semibold"`}>{iban.active_subscription && iban.active_subscription.length > 0 ? "Active subscription" : "inactive subscription"} </a>
                                  </OverlayTrigger>
                                ))}</div>
                        </div>
                        </div>                        
                          </>)
                        })}
                        {/* <div className="border no-hover p-15 task-list-card w-100">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="align-items-center bg-white-03 d-flex p-10 radius_5 ">
                              <img src={CardIcon} className="h40w40" />
                              <span className="c-font f-16 fw-semibold title-fonts ps-3">IBAN</span>
                            </div>
                            <div className="comman_action_icon">
                            <a href="#/" onClick={() => setIBanModal(true)} className="action_icon with_bg h32w32">
                              <PencilSimpleLine size={14} className="c-icons m-auto" weight="light"/>
                            </a>
                            </div>
                          </div>
                            <div className="d-flex flex-column pt-20px">
                            <div className="c-font f-14 color-white fw-semibold title-fonts mb-2">{singleSpaceTitle}</div>
                            <div className="c-font f-14 color-white-80 mb-2 title-fonts">{singleIBAN}</div>
                            <div className="c-font f-14 color-white-80">{singleSpaceBillingAddress}</div>
                            <div class="border-bottom-10 mb-10 mt-10"></div>
                            <div className="color-green fw-semibold">Active subscription </div>
                            </div>
                        </div> */}
                      
                      <div className="col-lg-6 mb-3">
                        <a href="#/" onClick={() => {
                            setIBanModal(true)
                            setIBanModalForAdd(true);
                        }} className="border-dashed h-100 no-hover p-15 task-list-card w-100 ">
                          <div className="align-items-center comman_action_icon d-flex flex-column h-100 justify-content-center">
                            <div className="action_icon h32w32 with_bg">
                               <Plus size={14} className="c-icons m-auto" weight="light"/>
                            </div>
                            <div className="c-font f-18 fw-semibold mt-10px title-fonts">Add payment method</div>
                          </div>
                        </a>
                      </div>
                </div>
                {/* ---------------------------- New payment card end  ---------------------------- */}
            </Form.Group>
          ) : individualIBAN && individualIBAN.length ? (
            individualIBAN.map((space, index) => {
              return (
                <Form.Group className="" controlId="formBasicEmail" key={index}>
                  <div className={`${insideModal ? "p-15" : "p-15"} task-list-card w-100 no-hover mb-2`}>
                    <div className="border-bottom d-flex justify-content-between  pb-2 mb-1">
                      <div className="text-truncate">
                        {space["postal_address"] || space["office_title"]}
                      </div>
                      {
                        userType === "staff"
                        ?
                        <></>
                        :
                        <Form.Check
                          label="Use In premium"
                          id={`premuim_${index}`}
                          type="radio"
                          checked={premiumestimateSelected[index]}
                          onClick={() => {
                            let tempArr = premiumestimateSelected.map(
                              (selectedFlag, tempindex) => {
                                if (tempindex == index) {
                                  selectedFlag = selectedFlag ? 0 : 1;
                                  return selectedFlag;
                                }
                                selectedFlag = 0;
                                return selectedFlag;
                              }
                            );
                            setPremiumestimateSelected(tempArr);
                          }}
                        />
                      }
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl-6 pb-3">
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_account_iban")}
                        </Form.Label>
                        <Form.Control
                          className=" pe-2"
                          type="text"
                          placeholder={_l("l_account_iban_placeholder")}
                          aria-label="default input example"
                          value={individualIBAN[index]["account_iban"]}
                          onChange={(e) => {
                            individualIbanText(e, space["project_id"]);
                          }}
                        />
                      </div>
                      <div className="col-xl-6">
                        <Form.Label className="input-label no-asterisk ">
                          {" "}
                          {_l("l_space_title")}
                        </Form.Label>
                        <Form.Control
                          className="  pe-2"
                          type="text"
                          placeholder={_l("l_space_title_placeholder")}
                          aria-label="default input example"
                          value={multipleSpaceTitles[index]}
                          onChange={(e) => {
                            const reg = new RegExp("^[A-Za-z0-9-, ]+$");
                            const inputValue = e.target.value;
                            if (reg.test(inputValue) || inputValue == "")
                              setMultipleSpaceTitles(
                                Object.values({
                                  ...multipleSpaceTitles,
                                  [index]: inputValue,
                                })
                              );
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <Form.Label className="input-label no-asterisk ">
                          {" "}
                          {_l("l_billing_address")}
                        </Form.Label>
                        <Form.Control
                          className=" pe-2"
                          type="text"
                          ref={inputRefs[index]}
                          placeholder={_l("l_billing_address_placeholder")}
                          readOnly={userType === "staff"}
                          aria-label="default input example"
                          value={multipleSpaceBillingAddress[index]}
                          onChange={(e) => {
                            setAutoSuggetAdress(index);
                            setMultipleSpaceBillingAddress(
                              Object.values({
                                ...multipleSpaceBillingAddress,
                                [index]: e.target.value,
                              })
                            );
                          }}
                        />
                      </div>
                    </div>

                    {/* <hr /> */}
                  </div>
                </Form.Group>
              );
            })
          ) : (
            <></>
          )}
        </div>
        }
      </div>
      {
        hideInbuiltFooter
        ?
        <React.Fragment></React.Fragment>
        :
        <div className={` ${insideModal ? "border-0 d-flex justify-content-end":"modal-footer border-0 justify-content-end mt-auto position-sticky bottom-0 rounded-0 p-2"}`}>
        
            <button
              type="button"
              id="closeButton"
              className="btn btn-secondary me-2 d-none"
            // data-bs-dismiss="modal" onClick={handleClose}
            >
              {_l("l_close")}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleSave}
              disabled={saveOperationInProgress}
            >
              {
                saveOperationInProgress ?
                _l("l_please_wait")
                :
                _l("l_update")
              }
            </button>
          
        </div>
      }
      {showPaymentSetupSteps ? (
            <PaymentSetupModal
              handleClose={() => {setShowPaymentSetupSteps(false); getOperatorCompanyAccountList();}}
              show={showPaymentSetupSteps}
              autoSubScribe={false}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}

      {
        IBanModal ? 
        <IBanEditModal
          show={IBanModal}
          handleClose={() => {
            setIBanModal(false);
            setIBanModalForAdd(false);
            setLoader(false)
            setIBanDetails({
              account_iban:"",
              company_name:localStorage.getItem("company_name") ,
              city: "",
              postal_code:"",
              company_address: localStorage.getItem("company_address"),
            });
          }}
          isForAdd={IBanModalForAdd}
          ibanDetails={IBanDetails}
          setIBanDetails={setIBanDetails}
          handleSave={() => {
            setLoader(true);
            if (IBanModalForAdd) {
              addNewIBAN();
            } else {
              editIBANDetails();
            }
          }}
          loader={loader}
        />
        : <></>
      }
    </>
  );
}

export default PremiumBillingTable;

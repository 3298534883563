import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import { DotsNine } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { getListOfRiskData } from "../../actions/customer";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import ProviderListActionModal from "./ProviderListActionModal";
import Spinner from "react-bootstrap/Spinner";
import { CaretDoubleDown } from "phosphor-react";
import RiskDetailModal from "../Modals/RiskDetailModal";
import moment from "moment";


const RiskTable = (
  {
    data, 
    hideActionMenu = false
  }) => {
  const {riskList} =  useSelector((state)=> state.customer)

  const dispatch = useDispatch();

  const [riskData, setRiskData] = useState(
    data && data.length > 0 ? data : []
  );
  const [riskPage, setRiskPage] = useState(1);
  const [loder, setLoder] = useState({
    riskLoader: false,
  });
  const [showRiskDetailModal, setShowRiskDetailModal] = useState(false);
  const [riskDetailData, setRiskDetailData] = useState();
  const [riskDetailDataTitle, setRiskDetailDataTitle] = useState();
  const [selectedRisk, setSelectedRisk] = useState()


  const scroll = (status) => {
    let page;
    let taskStaus = status;
    if (status == 1) {
      page = riskPage + 1;
      setRiskPage(page);
    }
    taskStaus = [status];
    if (status == 1) {
      setLoder({ ...loder, ["riskLoader"]: false });
    }
  };
  
  const handleShowDetails = (data) =>{
    setSelectedRisk(data) 
    setRiskDetailData(JSON.parse(data.risk_detail));
    setRiskDetailDataTitle(data.title);
    setShowRiskDetailModal(true)
  }


  useEffect(() => {
    setRiskData(riskList && riskList.length > 0 ? riskList : data)
  }, [JSON.stringify(riskList)])

  return (
    <>
    <table className="dataTable text-center tablewithborder">
      <thead className="comman-white-box-shadow">
        <tr>
          <th style={{ width: "20%" }} className="text-start">
            {_l("l_risk_zone")}
          </th>
          <th style={{ width: "5%" }} className="text-start">
            {_l("l_last_updated_date")}
          </th>
          <th style={{ width: "3%" }} className="text-center">
            {_l("l_score")}
          </th>
         {hideActionMenu ? <></> : <th style={{ width: "3%" }} className="text-center">
            {_l("l_action")}
          </th>}
        </tr>
      </thead>
      <tbody style={{ verticalAlign: "middle" }}>
        {riskData && riskData.length > 0 ? (
          <>
             {riskData.sort((a, b) => b.priority - a.priority)
                .slice(
                  0,
                  riskPage > 1 ? (riskPage - 1) * 10 + 10 : riskPage * 10
                ).map((data, i) => {
             return ( 
                <>
                  <tr>
                    <td className="pe-0">
                      <a className="name-with-progress d-block"
                      onClick={()=>{
                        handleShowDetails(data)
                      }}>
                        <div className="d-flex align-items-center">
                          <div
                            href="#/"
                            className="h35w35 comman-round-box with-bg rounded-circle bg-white-05"
                          >
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                              style={{
                                backgroundImage: `url('https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                              }}
                            ></div>
                          </div>
                          <div className="ps-2 w100minus70 text-start flex-grow-1">
                            <div className="d-flex flex-column">
                              <a
                                href="#/"
                                className="title-fonts text-truncate text-start max-width-200 fw-semibold"
                              >
                                {data.title}
                              </a>
                            </div>
                          </div>
                        </div>
                      </a>
                    </td>
                    <td className="title-fonts text-truncate text-start max-width75">
                     {moment(data.created_at).format("DD/MM/yyyy hh:mm a")}
                    </td>
                    <td className="title-fonts text-center">{Number(data.score) > 0 ? Number(data.score).toFixed(2) : 0}</td>
                    {hideActionMenu ? <></>:
                    <td>
                       <div className="align-items-center comman_action_icon d-flex justify-content-center">
                         <UncontrolledDropdown setActiveFromChild={true}>
                           <DropdownToggle className="border-0 p-0">
                             <a
                               href="#/"
                               className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                             >
                               <DotsNine size={18} className="c-icons " weight="light" />
                             </a>
                           </DropdownToggle>
                           <DropdownItem toggle={false} className="p-0">
                           <ProviderListActionModal actionList={["delete", "edit"]} data={data}/>
                           </DropdownItem>
                         </UncontrolledDropdown>
                       </div>
                    </td>}
                  </tr>
              
                </>
             );
           })}
               <tr>
                {(riskData.length >= (riskPage - 1) * 10 + 10 &&
                  riskData.length != (riskPage - 1) * 10 + 10) ||
                loder.riskLoader ? (
                  <>
                    <td className="border-0" colSpan={12}>
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setLoder({ ...loder, ["riskLoader"]: true });
                          scroll(1);
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {riskPage > 1
                              ? riskData.length - ((riskPage - 1) * 10 + 10)
                              : riskData.length - 10}
                            )
                          </span>
                          <CaretDoubleDown
                            size={12}
                            className="c-icons mx-2"
                            weight="light"
                          />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.riskLoader ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
          </>
        ) : (
          <>
            {" "}
            <tr className="">
              <td className="border-0" colSpan={7}>
                <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                  <div className="d-flex flex-column align-items-center">
                    <CommanPlaceholder
                      imgType="request"
                      placeholderText={_l("l_no_data")}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
    {showRiskDetailModal ? 
    <>
    <RiskDetailModal
    show={showRiskDetailModal}
    handleClose={()=>{
      setShowRiskDetailModal(false);
    }}
    editData={riskDetailData}
    data={selectedRisk}
    riskDetailDataTitle={riskDetailDataTitle}
    />

    </> 
    :<></>}
    </>
  );
};

export default RiskTable;

import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { _l } from "../../../hooks/utilities";
import DatePicker from "react-datepicker";
import { CalendarBlank } from "phosphor-react";
import moment from "moment";

const CustomFields = ({
  customField,
  customFieldValues,
  setCustomFieldValues,
}) => {
  switch (customField.type) {
    case "textarea":
      return (
        <Form.Group className={`c-input-box pb-4 position-relative ${[168, 169].includes(Number(customField.id)) ? "col-xl-6" : "col-xl-12"}`}>
          <Form.Label className="input-label no-asterisk">
            {customField.name}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder={customField.name}
            type="text"
            name=""
            defaultValue={customField.default_value}
            value={customField.benchmark_value}
            onChange={(e) => {
              setCustomFieldValues(
                customFieldValues.map((field) => {
                  if (field.id == customField.id) {
                    field.benchmark_value = e.target.value;
                  }
                  return field;
                })
              );
            }}
          ></Form.Control>
        </Form.Group>
      );
    case "select":
      const singleSelectMenuOptions = customField.options
        .split(",")
        .map((option) => {
          return { label: option, value: option };
        });

      const singleSelectValue = singleSelectMenuOptions.find(
        (w) => w.value == customField.benchmark_value
      );

      return (
        <Form.Group className="col-xl-12 c-input-box position-relative pb-3 z-index-12">
          <Form.Label className="input-label">{customField.name}</Form.Label>
          <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
            <Select
              className="custom-select-menu bg-white-03-solid onboarding-map-input scroll-height-30vh"
              classNamePrefix="react-select"
              placeholder={_l("l_select")}
              options={singleSelectMenuOptions}
              value={singleSelectValue}
              defaultValue={singleSelectMenuOptions.find(
                (w) => w.value == customField.default_value
              )}
              onChange={(e) => {
                setCustomFieldValues(
                  customFieldValues.map((field) => {
                    if (field.id == customField.id) {
                      field.benchmark_value = e.value;
                    }
                    return field;
                  })
                );
              }}
            />
          </div>
        </Form.Group>
      );
    case "multiselect":
      const multipleSelectMenuOptions = customField.options
        .split(",")
        .map((option) => {
          return { label: option, value: option };
        });

      let formattedMultiSelectValue = customField.benchmark_value ? customField.benchmark_value.split(",") : [];

      const multiSelectValue = multipleSelectMenuOptions.filter(
        (w) =>
          formattedMultiSelectValue.find(
            (z) => z == w.value
          )
      );

      return (
        <Form.Group className="col-xl-12 c-input-box position-relative pb-3 z-index-12">
          <Form.Label className="input-label">{customField.name}</Form.Label>
          <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
            <Select
              className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
              classNamePrefix="react-select"
              placeholder={_l("l_select")}
              options={multipleSelectMenuOptions}
              defaultValue={multipleSelectMenuOptions.find(
                (w) => w.value == customField.default_value
              )}
              value={multiSelectValue}
              isMulti
              onChange={(e) => {
                setCustomFieldValues(
                  customFieldValues.map((field) => {
                    if (field.id == customField.id) {
                      field.benchmark_value = e.map((w) => w.value).join(",");
                    }
                    return field;
                  })
                );
              }}
            />
          </div>
        </Form.Group>
      );
    case "link":
      return (
        <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
          <Form.Label className="input-label no-asterisk">
            {customField.name}
          </Form.Label>
          <Form.Control
            placeholder={customField.name}
            type="text"
            name={customField.name}
            onChange={(e) => {
              setCustomFieldValues(
                customFieldValues.map((field) => {
                  if (field.id == customField.id) {
                    field.benchmark_value = e.target.value;
                  }
                  return field;
                })
              );
            }}
            value={customField.benchmark_value}
          />
        </Form.Group>
      );
    case "date_picker":
      return (
        <>
          {" "}
          <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
            <Form.Label className="input-label no-asterisk">
              {customField.name}
            </Form.Label>
            <div className="custom-datepicker">
              <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                <div className="action_icon h32w32 with_bg">
                  <CalendarBlank
                    size={18}
                    weight="light"
                    className="c-icons opacity-60"
                  />
                </div>
                <DatePicker
                  className="ps-2 w-100"
                  disabledKeyboardNavigation
                  dateFormat="dd/MM/yyyy"
                  selectsStart
                  defaultMenuIsOpen
                  placeholderText={_l("l_please_select_date")}
                  calendarStartDay={1}
                  portalId="custom_datepicker"
                  calendarClassName="custom-datepicker"
                  onChange={(date) => {
                    setCustomFieldValues(
                      customFieldValues.map((field) => {
                        if (field.id == customField.id) {
                          field.benchmark_value =
                            moment(date).format("YYYY-MM-DD");
                        }
                        return field;
                      })
                    );
                  }}
                  selected={
                    customField.benchmark_value
                      ? new Date(customField.benchmark_value)
                      : ""
                  }
                >
                  <div className="datepicker-label">{_l("l_select_date_and_time")}</div>
                </DatePicker>
              </div>
            </div>
          </Form.Group>{" "}
        </>
      );
    default:
      return <div>CustomFields</div>;
  }
};

export default CustomFields;

import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, Button, DropdownItem, DropdownToggle, FormControl, Spinner } from 'react-bootstrap'
import { _l, fetchMyTaskFromIDB, hideLeftPanelNew, setHomePageTasksIDB } from '../hooks/utilities'
import { ArrowRight, CalendarBlank, CaretDoubleDown, Check, DotsNine, Envelope, File, GearSix, Hash, Phone, PhoneCall, UserCircleGear } from 'phosphor-react'
import { MentionsInput, Mention } from "react-mentions";
import Form from "react-bootstrap/Form";
import { commanoffcanvasisOpen, getDashboardTasksData, getDefaultTopics, getTaskList, setLeftPanelTab, setTabForSetupAccountAi, setTasklistForHomePage } from '../actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import customerServices from '../services/customer-services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TaskCard from '../components/TaskCard';
import Select from "react-select";
import SettingToolIcon from '../assets/icons/SettingToolIcon';
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import ActionDropdown from '../components/Dropdowns/ActionDropdown';
import ShareNetworkIcon from '../assets/icons/ShareNetworkIcon';
import { readCollectionFromDB } from '../hooks/indexed_db_helpers';
import { startCall } from '../actions/vapi';
import outlookCalendar from '../services/outlook-calendar';
import CommanOffCanvas from '../components/Offcanvas/CommanOffCanvas';
import jquery, { get } from "jquery";
import CommanPlaceholder from '../components/Placeholder/CommanPlaceholder';
import TaskListSkeleton from '../components/Skeleton/TaskListSkeleton';

const NewThemeDashboard = () => {

  const {
    defaultTopics,
    taskList,
    selectedTask,
    commanoffcanvasIsOpen,
    homepageTaskList

  } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailSyncStatus, setEmailSyncStatus] = useState(0)
  const [settingOptions, setSettingOptions] = useState({ label: "l_setup_my_account", icon: "PhoneIcon" })
  const IconComponent = require("../assets/icons/" + settingOptions.icon).default;
  const profileUrl = localStorage.getItem("contact_image") ? localStorage.getItem("contact_image") : ""
  const spaces = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [];
  let contact_role = localStorage.getItem("contact_role")
  const spaceState = useSelector((state) => state.customer.spaces);
  const messagesStates = useSelector((state) => state.messages);



  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [taskPinned, setTaskPinned] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [taskId, setTaskId] = useState()
  const [callUserList, setCallUserList] = useState([]);
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] = useState(0);
  const [showDefaultIcons, setShowDefaultIcons] = useState([]);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [convertTaskId, setConvertTaskId] = useState(0);
  const [commonModalData, setCommonModalData] = useState();
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [task_type, setTask_type] = useState('');
  const [imageCount, setImageCount] = useState(0);
  const [IsAttachmentCountUpdate, setIsAttachmentCountUpdate] = useState(false);
  const [taskPage, setTaskPage] = useState(1);
  const [topics, setTopics] = useState([]);
  const [taskListLocal, setTaskListLocal] = useState([])
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [isSkeltonShow, setIsSkeltonShow] = useState(true);


  const handleClose = () => setShowReqestedTaskModal(false);
  const handleShow = () => setShowReqestedTaskModal(true);

  const handleChangeTab = (tab, navigation) => {
    dispatch(commanoffcanvasisOpen(false));
    dispatch(setLeftPanelTab(tab));
    if (navigation) {
      navigate(navigation);
    }
  }

  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    getMeetingLink(taskId);
    setShowTaskModal(false);
    setSelectedTaskId(taskId);
    setConvertTaskId(convertRequestTaskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {

      setShowTaskModal(show);
      hideLeftPanelNew();
    }
  };

  const getMeetingLink = (TaskId, calendar_id = "") => {

    outlookCalendar.getMeetingLink(TaskId, 0, calendar_id).then((res) => {
      if (res.status == 1) {
        setMeetingUrl(res.data.meetingUrl);
      } else {
        setMeetingUrl("");
      }
    });
  };

  const handleToggleIcons = (isTaskPinned, taskAssignee, taskAssigneePills, taskPriority, comments_count, rating, setTaskCardIconToggle, taskCardIconToggle) => {
    if (taskCardIconToggle) {
      let tempDefaultIcons = []

      if (isTaskPinned) {
        tempDefaultIcons.push("pin")
      }
      if (taskAssignee.length > 0) {
        tempDefaultIcons.push("peopels")
      }
      if (taskAssigneePills.length > 0) {
        tempDefaultIcons.push("pills")
      }
      if (taskPriority == 3) {
        tempDefaultIcons.push("priority")
      }
      if (comments_count > 0) {
        tempDefaultIcons.push("comment")
      }
      if (rating && rating > 0) {
        tempDefaultIcons.push("star")
      }

      setShowDefaultIcons(tempDefaultIcons)
      setTaskCardIconToggle(false)
    } else {
      let tempDefaultIcons = ["calendar", "assigneTo", "pin", "peopels", "pills", "priority", "cube", "map", "status", "comment", "star", , "delete", "share", "call", "template"]
      setShowDefaultIcons(tempDefaultIcons)
      setTaskCardIconToggle(true)
    }

  }

  const updateAttchmentChecklistCount = (taskid, imageCount = 0) => {
    setImageCount(imageCount ? imageCount : 0);
    if (selectedTask) {
      setIsAttachmentCountUpdate(true);
    }
  };

  const getData = async () => {
    const data = await readCollectionFromDB("topics", "project_id");
    setTopics(data);
  }
  

  const taskListLocalData = useMemo(() => homepageTaskList ? homepageTaskList.slice() : [], 
  
  [homepageTaskList])

  useEffect(() => {
    const checkEmailSync = async () => {
      try {
        const response = await customerServices.getMailSettings();
        setEmailSyncStatus(response.status)
      } catch (e) {
        console.error(e);
      }
    };
    checkEmailSync()
    // dispatch(getTaskList("", "", 0, moment(new Date()).format("YYYY-MM-DD")))
    setTimeout(() => {
      setIsSkeltonShow(false);
    }, 500);
    try {
      customerServices.getDashboardTasks().then((res) => {
        if (res.status) {
          dispatch(getDashboardTasksData(res));
          if (res && res.status && res.data) {
            setHomePageTasksIDB(res.data);
            dispatch(setTasklistForHomePage(res.data));
            setIsDataAvailable(false);
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => {
    if (selectedTask) {

      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      setSelectedTaskId(selectedTask.task_id || selectedTask.id)
      // setSelectedTaskId( selectedTask.id)
      if (selectedTask.is_requested == 1) {
        handleShow();
      }
      if (selectedTask.task_id || selectedTask.id) {
        setShowTaskModal(true);
      }
    } else {
      setShowTaskModal(false)
      setSelectedTaskId(0)
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
        // setUnsavedChanges(true);
      });
    });

    return () => {
      jquery(document).unbind("ready");
    };
  }, [selectedTask]);

  useEffect(() => {
    getData()
    dispatch(getDefaultTopics(0, "", "", "homepage"));
  }, [JSON.stringify(spaces), JSON.stringify(spaceState)])

  useEffect(() => {
    let taskId = localStorage.getItem("externalTaskHash");
    let externalTaskDetails = localStorage.getItem("externalTaskData") ? JSON.parse(localStorage.getItem("externalTaskData")) : {}
    if (taskId && externalTaskDetails && messagesStates.idbDataLoader == false) {
      setShowTaskModal(true); 
      setCommonModalData(externalTaskDetails)
    }
  }, [messagesStates])
  
  useEffect(() => {
    fetchMyTaskFromIDB(dispatch);
  }, [])
  
  useEffect(() => {
    setTaskListLocal(homepageTaskList);

    let ids = homepageTaskList.map(task => task.id);
    let tasks = taskList.filter(t => ids.includes(t.id));
    if (tasks.length > 0 && tasks.length == homepageTaskList.length) {
      dispatch(setTasklistForHomePage(tasks));
      setHomePageTasksIDB(tasks);
    }
  }, [JSON.stringify(taskList)])
  
  // useEffect(() => {
  //   let ids = homepageTaskList.map((item) => item.id)
  //   console.log("taskList", ids)  
  // }, [homepageTaskList])
  


  return (
    <>
      <div className='schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column'>
        <div className='comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian   '>
          <div className={`d-flex flex-column flex-grow-1 backdroplighttheme w-800px canimation ${commanoffcanvasIsOpen ? '' : 'daily-schedule-main'}`}>
            {/* cardAnimation this class need to add on above div for transform */}
            <div className='position-sticky top-0 start-0 z-index-8 bgpatternCommanImg'>
              <div className='d-flex justify-content-between '>
                <div className='c-font f-24 fw-semibold'>Hello, {localStorage.getItem("full_name")}</div>
                <div className='d-flex align-items-center gap-2 comman_action_icon'>
                  {contact_role != 3 ? <div
                    className="comman_action_icon card-view-less-padding p-0  dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-popper-placement="left-start"
                  >
                    <a
                      href="#/"
                      className={` action_icon h32wauto btn btn-white-05-solid  gap10 text-nowrap`}
                       
                       
                       
                       

                    >
                      {
                        settingOptions.label == "l_setup_my_account"
                          ? <></>
                          : <IconComponent size={14} className="c-icons" weight="light" />
                      }
                      <di1v className='text-uppercase text-on-hover-active-green'>{_l(settingOptions.label)}</di1v>
                    </a>
                    <ActionDropdown textUppercase="text-uppercase" widthClass="w-auto"
                      actions={[
                        {
                          actionName: _l("l_setup_my_phone"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_phone", icon: "PhoneIcon" })
                            // dispatch(setTabForSetupAccountAi("my_phone"))
                           },
                          actionIcon: "PhoneIcon",
                        },
                        {
                          actionName: _l("l_setup_my_email"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_email", icon: "EnvelopeSimpleIcon" })
                            // dispatch(setTabForSetupAccountAi("my_email"))
                           },
                          actionIcon: "EnvelopeSimpleIcon",
                        },
                        {
                          actionName: _l("l_setup_my_calendar"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_calendar", icon: "CalendarBlankIcon" })
                            // dispatch(setTabForSetupAccountAi("my_calendar"))
                           },
                          actionIcon: "CalendarBlankIcon",
                        },
                        {
                          actionName: _l("l_setup_my_drive"),
                          actionHandler: () => { 
                            setSettingOptions({ label: "l_setup_my_drive", icon: "FIleXicon" })
                            // dispatch(setTabForSetupAccountAi("my_drive"))
                           },
                          actionIcon: "FIleXicon",
                        },
                      ]}
                    />
                  </div> : <></>}
                  {contact_role == 3 ?
                    <Button variant="primary" className='h32wauto lh-1'
                      onClick={() => {
                        getData()
                        let assistantID = spaces.find(item => item.vapi_assistant_id != null)
                        let projectID = assistantID.project_id
                        assistantID = assistantID ? assistantID.vapi_assistant_id : ""
                        // const data = await readCollectionFromDB("topics", "project_id");
                        // console.log(readCollectionFromDB("topics", "project_id"))
                        console.log("currentProjectTopics", topics)
                        if (topics.length) {
                          const currentProjectTopics = topics.find((projectTopics) => projectTopics.project_id == projectID);
                          if (currentProjectTopics && currentProjectTopics.topics) {
                            let Assistant = currentProjectTopics.topics.find((topics) => topics.main_key == "my_contact").categories.filter((category) => category.contact_role == 4)[0]
                            localStorage.setItem("selectedTopicForCall", JSON.stringify(Assistant))
                            localStorage.setItem("assistantCallerID", assistantID);
                            localStorage.setItem('selectedOffice', projectID);
                            startCall();
                          }
                        }
                      }}
                       
                       
                       >
                      <PhoneCall size="18" className="c-icons" />
                      <span className="ms-10px ps-0">{_l("l_assistant_call")}</span>
                    </Button> : <></>}

                </div>
              </div>
              <div className='audiocallbg p12px my-4'>
                <div className='align-items-center btnbluebg chat-comment-box comment-input-box d-flex flex-grow-1 p-10  position-sticky top-0 start-0 z-2 lightthemebgtranspaent light-theme-border'>
                  <div className='h32w32 comman-image-box with-bg rounded-circle'>
                    <div className='comman-bg-img h-100 w-100 bg-style-cover rounded-circle' style={{ backgroundImage: `url(${profileUrl})` }}></div>
                  </div>
                  <Form.Control
                    className='bg-transparent border-0 form-control p-0 ps-3'
                    placeholder={`${_l("l_write_message_placeholder")}`}
                    type="text"
                    name="" />
                  <div className={`d-flex align-items-center comman_action_icon`} >

                    <a href="#/" className={`action_icon with_bg  h32w32 me-10px`}
                       
                       
                       
                       >
                      <ArrowRight size={16} weight="light" className="c-icons" />
                    </a>

                    <a href="#/" className={`action_icon with_bg  h32w32`}
                       
                       
                       
                       >
                      <PhoneCall size={16} weight="light" className="c-icons" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-list-box accordion-body dashboardlist'>
              <div className='mb-15px'>
                <div className='bgpatternCommanImg c-font color-white-60 f-12  pb-1 position-sticky top160px z-1'>{_l("l_today")}</div>
                <div id="list_view" className={`schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column ${showTaskModal || showReqestedTaskModal ? "list-view-toggle w-auto" : ""
               // ${showTaskList ? "" : "comman-table-thead-bg"} 
           }`}>
                {isSkeltonShow || ( isDataAvailable && taskListLocalData.length == 0) ? 
                <>
                  <TaskListSkeleton />
                  <TaskListSkeleton />
                  <TaskListSkeleton />
                </> :
                 taskListLocalData && taskListLocalData.length > 0 ?
                  taskListLocalData.sort((a, b) => {
                    if (a.id > b.id){
                      return -1;
                    }
                  }).slice(0, taskPage * 10).map((task, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          key={index}
                          className=""

                        >
                          <TaskCard
                            key={task.id}
                            task={task}
                            active={selectedTaskId == task.id}
                            project_id={task.space_project_id}
                            handler={handleSelectedTask}
                            pageName='LiteVersion'
                            taskPinned={taskPinned}
                            setTaskPinned={setTaskPinned}
                            contacts={contacts}
                            setTaskId={setTaskId}
                            setCallUserList={setCallUserList}
                            setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                            selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                            showDefaultIcons={showDefaultIcons}
                            setShowDefaultIcons={setShowDefaultIcons}
                            handleToggleIcons={handleToggleIcons}
                            isFrom="homePage"
                          // getTaskDetails = {handleTask}
                          />
                        </div>
                      </React.Fragment>
                    )
                  }) :
                  <CommanPlaceholder placeholderText={_l("l_no_task_found")} />
                }
                {isSkeltonShow || ( isDataAvailable && taskListLocalData.length == 0) ? 
                  <>
                  </> 
                  :
                  (taskListLocalData.length >= (taskPage - 1) * 10 + 10 && 
                  taskListLocalData.length != (taskPage - 1) * 10 + 10) ? (
                  <a href="#/"
                    className="btn btn-white-03 w-100 view-more-btn"
                    onClick={() => {
                      setTaskPage(taskPage + 1)
                    }}
                  >
                    <div className="d-flex align-item-center justify-content-center">
                      <span className=" title-fonts c-font f-12 ms-2">
                        {_l("l_view_more_btn")} (
                        {taskPage > 1
                          ? taskListLocalData.length - ((taskPage - 1) * 10 + 10)
                          : taskListLocalData.length - 10}
                        )
                      </span>
                      <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                      {/* <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 ${loder.todoLoder ? "opacity-1" : "opacity-0"
                        }`}
                      aria-hidden="true"
                    /> */}
                    </div>
                  </a>
                ) : (
                  <></>
                )}
           </div>
                {/* {
              taskList
              .map((task, index) => {
                return (
                  <React.Fragment key={index}>
                    <div>
                      <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                        <div className='task-list-wrapper '>
                          <div className='d-flex w-100 align-items-start'>
                            <div className='d-flex task-list-detail'>
                              <div className='task-left flex-grow-1 width100minus160 '>
                                <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                                  <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                    <div className=' h32w32 '>
                                      <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url(${task.creator_profile_image})` }}></div>
                                    </div>
                                    <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                      <div className='fw-semibold mb-1 text-truncate w-100'>{task.name}</div>
                                      <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")} <span class=" p-0 ">{moment(task.dateadded).format(("YYYY-MM-DD HH:mm a"))}</span></div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className='d-flex align-items-center task-bottom-part-full '>
                                <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                                  <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                                    <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                                    <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                                  </a>
                                  <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                                    <span
                                       className={`c-font f-12 text-uppercase ps-0 
                                        ${ task.is_requested == 1
                                            ? "color-orange"
                                            : task.status == 1
                                              ? "color-white-60"
                                              : task.status == 4
                                                ? "in-progress-blue"
                                                : "color-green"
                                        }`}
                                      >
                                        { task.is_requested == 1
                                            ? _l("l_request")
                                            : task.status == 1
                                              ? _l("l_todo_kpi_name")
                                              : task.status == 4
                                                ? _l("l_ongoing_kpi")
                                                : _l("l_completed")
                                        }
                                      </span>
                                    <span class={`c-dots  c-10 rounded-circle ms-10px
                                      ${ task.is_requested == 1
                                        ? "bg-warning"
                                        : task.status == 1
                                          ? "in-progress-white"
                                          : task.status == 4
                                            ? "in-progress-blue"
                                            : "done"
                                      }`}></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </React.Fragment>
                );
              })
            } */}

                {/* <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-green ps-0'>{_l("l_completed")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px done"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-orange ps-0'>{_l("l_request")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px bg-warning"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px ps-0'>{_l("l_todo_kpi_name")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px in-progress-white"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div> */}
              </div>
              {/* <div className='mb-15px'>
              <div className='bgpatternCommanImg c-font color-white-60 f-12  pb-1 position-sticky top160px z-1'>{_l("l_tomorrow")}</div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-blue ps-0'>{_l("l_ongoing_kpi")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px in-progress-blue"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-green ps-0'>{_l("l_completed")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px done"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-blue ps-0'>{_l("l_ongoing_kpi")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px in-progress-blue"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-green ps-0'>{_l("l_completed")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px done"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-blue ps-0'>{_l("l_ongoing_kpi")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px in-progress-blue"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-green ps-0'>{_l("l_completed")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px done"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-blue ps-0'>{_l("l_ongoing_kpi")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px in-progress-blue"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                  <div className='task-list-wrapper '>
                    <div className='d-flex w-100 align-items-start'>
                      <div className='d-flex task-list-detail'>
                        <div className='task-left flex-grow-1 width100minus160 '>
                          <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                              <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                <div className=' h32w32 '>
                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                                </div>
                                <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                  <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                  <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                                </div>
                              </div>
                        
                          </div>
                        </div>
                        <div className='d-flex align-items-center task-bottom-part-full '>
                          <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                              <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                            </a>
                            <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                              <span className='c-font f-12 text-uppercase color-green ps-0'>{_l("l_completed")}</span>
                              <span class="c-dots  c-10 rounded-circle ms-10px done"></span>
                            </a>
                            <div className={`comman_action_icon`}>
                              <a className={`action_icon h32w32 with_bg with-border-active`}>
                                <DotsNine size={18}  weight="light" className="c-icons" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              </div> */}
              {/* <a href="#/" className="btn btn-white-03 w-100 view-more-btn">
              <div className="d-flex align-item-center justify-content-center">
                <span className="title-fonts c-font f-12 ms-2">
                  {_l("l_view_more_btn")}
                </span>
                <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2`}
                  aria-hidden="true"
                />
              </div>
            </a> */}

            </div>
          </div>
        </div>
      </div>


      {/* Dashboard AI Chat start */}
      {/* <div className='schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column'>
      <div className='comman-main-box custom-accordian-main flex-grow-1 h-100 overflow-auto res-padding-right0 task-list-accordian pb30'>
              <div className='backdroplighttheme canimation d-flex daily-schedule-main flex-column flex-grow-1 h-100 w-800px'>
                <div className='align-items-center row flex-column'>
                  <div className='col-lg-6 text-center'>
                    <div className='align-items-center bgOfficelisting comman-round-box d-flex h50w50 justify-content-center m-auto mb-4'>
                      <div className='comman-bg-img h-100 w-100 bg-style-cover' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1711881027254-e9ff3234d9d8?q=80&w=1947&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}></div>
                    </div>
                    <div className="c-font f-24 fw-semibold title-fonts">Hello , John</div>
                    <div className="c-font color-white-60 f-22 pb-10px pt-10px">Can I help you with anything?</div>
                    <div className="c-font f-14 opacity-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non convallis augue.</div>
                  </div>
                </div>
                <div className='mt-auto'>
                  <div className='d-flex g-0 justify-content-between'>
                    <div className='select-input-cox space-select-box bgleftpanelnew'>
                      <input className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                        name="chatoption"
                        checked
                       
                        type="radio"
                      />
                      <a href="#/" className="check-image">
                        <div className="check-image-box">
                          <Check size={14} className="c-icons color-white-03-solid" />
                        </div>
                      </a>
                      <div>
                        <div className='bg-white-03 comman_action_icon d-flex flex-column h-135 m-auto p-20 radius_3'>
                          <div className='action_icon h40w40 rounded-circle white-border-box with_bg'>
                            <Phone size={24}  weight="light" className="c-icons" />
                          </div>
                          <div class="c-font f-18 fw-semibold pt-15px">Setup My Phone</div>
                          <div class="color-white-60 mt-1">Most visit place</div>
                        </div>
                      </div>
                    </div>
                    <div className='select-input-cox space-select-box bgleftpanelnew'>
                      <input className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                        name="chatoption"
                        checked
                       
                        type="radio"
                      />
                      <a href="#/" className="check-image">
                        <div className="check-image-box">
                          <Check size={14} className="c-icons color-white-03-solid" />
                        </div>
                      </a>
                      <div>
                        <div className='bg-white-03 comman_action_icon d-flex flex-column h-135 m-auto p-20 radius_3'>
                          <div className='action_icon h40w40 rounded-circle white-border-box with_bg'>
                            <Envelope size={24}  weight="light" className="c-icons" />
                          </div>
                          <div class="c-font f-18 fw-semibold pt-15px">Setup my email</div>
                          <div class="color-white-60 mt-1">Most visit place</div>
                        </div>
                      </div>
                    </div>
                    <div className='select-input-cox space-select-box bgleftpanelnew'>
                      <input className="bg-transparent bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100"
                        name="chatoption"
                        checked
                       
                        type="radio"
                      />
                      <a href="#/" className="check-image">
                        <div className="check-image-box">
                          <Check size={14} className="c-icons color-white-03-solid" />
                        </div>
                      </a>
                      <div>
                        <div className='bg-white-03 comman_action_icon d-flex flex-column h-135 m-auto p-20 radius_3'>
                          <div className='action_icon h40w40 rounded-circle white-border-box with_bg'>
                            <CalendarBlank size={24}  weight="light" className="c-icons" />
                          </div>
                          <div class="c-font f-18 fw-semibold pt-15px">Setup My Calendar</div>
                          <div class="color-white-60 mt-1">Most visit place</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='comman_action_icon d-flex gap10 mt-4 position-sticky start-0'>
                   
                    
                      <div className='chat-main-box d-flex flex-column m-auto h64px w-100'>
                        <div className='border comment-input-box p15-10 bg-white-03-solid h-100'>
                        <div className="d-flex align-items-center position-relative">
                          <div className="h32w32 comman-image-box rounded-circle">
                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1711881027254-e9ff3234d9d8?q=80&w=1947&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}></div>
                          </div>
                          <div className="mension-box">
                            <textarea
                              placeholder={_l('l_write_comment_placeholder')}
                              className="d-flex align-items-center p-0 border-0 bg-transparent px-2"
                              rows="1"
                            ></textarea>
                          </div>
                          <a href="#/" className="send-arrow-btn with_position_right_zero">
                              <ArrowRight size={18}  weight="light" className="c-icons" />
                          </a>
                        </div>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
      </div>
    </div> */}
      {/* Dashboard AI Chat end */}
      {showTaskModal ? <CommanOffCanvas
        show={showTaskModal}
        handleClose={() => {
          setShowTaskModal(false);
          // setIsRequestTask()
          handleClose();
          setCommonModalData();
          setSelectedTaskId(0);
          setTask_type('')
        }}
        pinHandler={(id, type, flag) => {
          // handlePinUnPin(id, type, flag);
        }}
        data={commonModalData}
        docType={task_type}
        setSelectedId={setSelectedTaskId}
        selectedTaskId={selectedTaskId}
        IsKanbanView={false}
        meetingUrl={meetingUrl}
        handleParentComponentAction={(type, taskData) => {
          if (task_type == 'task') {
            if (type == 'attachmentCountUpdate') {
              updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
              return;
            } else if (type == 'handleSelectedTask') {
              handleSelectedTask(taskData.task_id, taskData.flag)
              return;
            } else if (type == 'convertedTaskAction') {
              handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
              return;
            }
          } else if (task_type == 'request-task') {

            if (type == 'convertedTaskAction') {
              handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
              return;
            }
          }

        }}
        pageName={"Liteversion"}
        contacts={contacts}
        callUserList={callUserList}
        isFullScreen={localStorage.getItem("externalTaskHash") ? true : false}
      /> : <></>
      }
    </>
  )
}

export default NewThemeDashboard
import React from 'react';
 

function PushPinFillIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="17" height="16" viewBox="0 0 17 16"xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M14.8906 6.70667L11.2344 10.3629C11.5156 11.1567 11.6344 12.4817 10.4094 14.1129C10.3226 14.2274 10.2126 14.3222 10.0865 14.3911C9.96051 14.4601 9.82133 14.5016 9.67813 14.5129H9.60938C9.34448 14.5118 9.09071 14.4062 8.90313 14.2192L5.89063 11.2067L3.74688 13.3567C3.65131 13.4491 3.52358 13.5007 3.39063 13.5007C3.25769 13.5007 3.12995 13.4491 3.03438 13.3567C2.94049 13.2619 2.88782 13.1338 2.88782 13.0004C2.88782 12.867 2.94049 12.739 3.03438 12.6442L5.18438 10.5004L2.15938 7.47542C2.05904 7.37655 1.98086 7.25747 1.93003 7.12609C1.87921 6.99471 1.85689 6.85402 1.86458 6.71335C1.87226 6.57269 1.90976 6.43527 1.9746 6.3102C2.03943 6.18513 2.13011 6.07528 2.24063 5.98792C3.82813 4.70667 5.34688 4.96292 6.01563 5.16917L9.68438 1.50042C9.87265 1.31492 10.1263 1.21094 10.3906 1.21094C10.6549 1.21094 10.9086 1.31492 11.0969 1.50042L14.8906 5.29417C14.984 5.38658 15.0581 5.49658 15.1087 5.6178C15.1592 5.73902 15.1853 5.86907 15.1853 6.00042C15.1853 6.13178 15.1592 6.26182 15.1087 6.38305C15.0581 6.50427 14.984 6.61427 14.8906 6.70667V6.70667Z" />
</svg>



		</React.Fragment>
	)
}

export default PushPinFillIcon
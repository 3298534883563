import { Col } from "react-bootstrap";
import { CloudArrowUp, X } from "phosphor-react";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { _l, getFileType, getPreviewFromURL } from "../../../hooks/utilities";
 
import DocumentPreview from "../../Documents/DocumentPreview";

const UploadDocumentBenchmark = ({
    imagePreviews,
    setImagePreviews
}) => {

  const [openGallery, setOpenGallery] = useState(false);
  const [lightboxInputArray, setLightboxInputArray] = useState([]);

  const getUploadKeyName = {
    l_documets: "Documents",
  };

  const removeImageSelection = (img,i) =>{
      setImagePreviews({
        ...imagePreviews,
        ["l_documets"]: {
          key: getUploadKeyName["l_documets"],
          files: Array.from(imagePreviews["l_documets"].files).filter((w,index)=> index !== i || img.name !== w.name),
          previewArray: imagePreviews["l_documets"].previewArray.filter((w,index)=> index !== i || img.name !== w.name)
        },
      });
  }

  return (
    <>
      <Col xs={12} className="mb-20">
        <Form.Label className="input-label form-label">
          {_l("l_document")}
        </Form.Label>
        <div className="d-flex flex-column">
          {
            <div className="form-control  file-upload with-bg position-relative p-0 h-100 text-center">
              <div className="d-flex align-items-center justify-content-center p-15 flex-column">
                <input
                  className="z-index-3 hidden-input m-auto"
                  type="file"
                  multiple
                  accept="image/*,video/*,.pdf"
                  onInput={(e) => {
                    setImagePreviews({
                      ...imagePreviews,
                      ["l_documets"]: {
                        key: getUploadKeyName["l_documets"],
                        files: e.target.files,
                        previewArray: Array.from(e.target.files).map((w) => {
                          return {
                            ...w,
                            name: w.name,
                            url: URL.createObjectURL(w),
                          };
                        }),
                      },
                    });
                  }}
                />
                <div className="m-auto">
                  <CloudArrowUp
                    size={30}
                    className="c-icons opacity-25 "
                    weight="light"
                  />
                  <div className="color-white-60 mt-10">
                    {_l("l_click_to_upload")}
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="d-flex g-3 row flex-wrap pt-20px">
            {imagePreviews["l_documets"].previewArray &&
            imagePreviews["l_documets"].previewArray.filter((w) => w.url)
              .length ? (
              imagePreviews["l_documets"].previewArray.map((x, index) => {
                let preview = {};
                preview.url = getPreviewFromURL(x.name || x.url);
                return (
                  <div
                    className="upload-image-preview cursor-pointer col-auto"
                    key={index}
                  >
                    <div
                      className="comman-image-box h50w50 radius_3 upload-image with-border active position-relative"
                       
                       
                       
                    >
                      {typeof preview.url === "string" ? (
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                          onClick={() => {
                            setOpenGallery(true);
                            let selectAttachment = imagePreviews["l_documets"].previewArray
                            .filter(
                                (file, innerIndex) => innerIndex == index
                              );
                              let otherAttachment = imagePreviews["l_documets"].previewArray
                              .filter(
                                (file, innerIndex) => innerIndex != index
                              );
                              setLightboxInputArray(
                                selectAttachment.concat(otherAttachment).map((w) => {
                                  return {
                                    src: w.url,
                                    type:
                                      w.type ||
                                      getFileType(w.url) ||
                                      getFileType(w.name),
                                  };
                                })
                              );
                          }}
                          style={{
                            backgroundImage: `url(${x.url})`,
                          }}
                        ></div>
                      ) : (
                        <div
                          className="h-100 w-100 d-flex cursor-pointer"
                          onClick={() => {
                            setOpenGallery(true);
                            setLightboxInputArray(
                              imagePreviews["l_documets"].previewArray.map(
                                (w) => {
                                  return {
                                    src: w.url,
                                    type:
                                      w.type ||
                                      getFileType(w.url) ||
                                      getFileType(w.name),
                                  };
                                }
                              )
                            );
                          }}
                          style={{
                            alignContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <preview.url
                            size={72}
                            weight="light"
                            className="c-icons"
                          />
                        </div>
                      )}
                      <a href="#/" className="upload-close"
                      onClick={()=>{
                        removeImageSelection(x,index)
                      }}>
                        <X size={14} weight="light" className="c-icons" />
                      </a>
                    </div>
                     
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </Col>
      {openGallery && lightboxInputArray.length ? (
        <DocumentPreview
          open={openGallery}
          setOpen={setOpenGallery}
          slides={lightboxInputArray}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UploadDocumentBenchmark;

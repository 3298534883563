import React, { useState, useEffect } from "react";
import SetupScreen from "./CommonComponents/SetupScreen";
import { GearSix, Minus, Plus } from "phosphor-react";
import ListWithAction from "./CommonComponents/ListWithAction";
import { useDispatch, useSelector } from "react-redux";
import CustomerServices from "../../../services/customer-services";
import { setFloorPlanning } from "../../../actions/benchmark-actions/benchmark-actions";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import { _l } from "../../../hooks/utilities";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import DropdownWithCheckbox from "./CommonComponents/DropdownWithCheckbox";

const EquipmentAssignment = ({
  showSetupScreen,
  equipmentData,
  setShowSetupScreen,
  spaceData,
  assignEquipmentToSpace
}) => {

  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];
  let currentSpaceIndex = spaces.findIndex((w) => w.project_id == spaceData.project_id);

  const { type_of_space, floor } = useSelector(
    (state) => state.benchmarkReducer.floorDetails
  );
  const dispatch = useDispatch();

  const contact_role = localStorage.getItem("contact_role");

  const [setupScreenDetails, setSetupScreenDetails] = useState({});
  const [spaceDetails, setSpaceDetails] = useState({
    floors: spaces[currentSpaceIndex].floor_count,
    superficies: spaces[currentSpaceIndex].superficies,
    number_of_desk: spaces[currentSpaceIndex].number_of_desk,
    project_id: spaces[currentSpaceIndex].project_id,
    ...spaces[currentSpaceIndex],
  });
  const [typeOfSpaceListing, setTypeOfSpaceListing] = useState([]);
  const [selectTypeOfSpace, setSelectTypeOfSpace] = useState({});
  const [equipmentItemsList, setEquipmentItemsList] = useState([]);
  const [hideEquipmentAssignmentDropdown, setHideEquipmentAssignmentDropdown] = useState(false);

  const handleCloseSetupScreen = () => {
    setShowSetupScreen(false);
  };

  const selectFloorForSetup = (row) => {
    setSetupScreenDetails({ ...row, label: row.title, value: row.id });
    typeOfSpaceForCurrentFloor(row.id);
    setShowSetupScreen(true);
  };

  const addEditFloor = async (direction) => {
    let currentFloorList = floor.slice();
    if (direction == -1) {
      if (floor.length > 1 && contact_role == 1) {
        let lastFloor = currentFloorList.pop();
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            `${_l("l_delete_floor_confirmation")} ${lastFloor.title} ?`,
            _l("l_delete"),
            _l("l_no"),
            () => {},
            async () => {
              if (lastFloor.id > 0) {
                try {
                  const response = await CustomerServices.deleteFloorItems(
                    spaceDetails.project_id,
                    "floor",
                    [lastFloor.id],
                    "floor_id"
                  );
                  if (response.status == 1) {
                    dispatch(
                      setFloorPlanning(
                        response.data.type_of_space,
                        response.data.floor
                      )
                    );
                    setSpaceDetails({
                      ...spaceDetails,
                      floors: response.data.floor.length,
                    });
                  }
                } catch (e) {
                  console.error(e);
                } finally {
                  dispatch(toastCloseAction());
                }
              }
            }
          )
        );
      }
    } else {
      try {
        const response = await CustomerServices.addFloorItem(
          spaceDetails.project_id,
          1,
          "",
          "floor",
          0,
          currentFloorList.length
        );
        if (response.status) {
          dispatch(
            setFloorPlanning(response.data.type_of_space, response.data.floor)
          );
          setSpaceDetails({
            ...spaceDetails,
            floors: response.data.floor.length,
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const updateValueToLocalSpaceData = (key, value) => 
  {
    spaces[currentSpaceIndex][key] = value;
    localStorage.setItem("spaces", JSON.stringify(spaces));
  }

  const updateSpaceDetail = async (key, value) => {
    if (value < 0) {
      return;
    }
    try {
      await CustomerServices.updateSpaceData(
        spaceDetails.project_id,
        key,
        value
      );
      setSpaceDetails({...spaceDetails, [key]: value});
      updateValueToLocalSpaceData(key, value);
    } catch (e) {
      console.error(e);
    }
  };

  const typeOfSpaceForCurrentFloor = (id) => {
    let spaces = [];
    type_of_space.forEach((space) => {
      space.details.forEach((item) => {
        if (item.floor_id == id && !spaces.find((w) => w.id == space.id)) {
          spaces.push(space);
        }
      });
    });
    setTypeOfSpaceListing(
      spaces.map((w) => {
        return { ...w, label: w.title, value: w.id, count: equipmentData && equipmentData.equipmentItems ? equipmentData.equipmentItems.filter((x) => w.details.find((z) => z.id == x.type_of_space_item_id && z.floor_id == id)).length : 0};
      })
    );
    if (spaces.length && !selectTypeOfSpace.id) {
      setSelectTypeOfSpace(spaces[0]);
    }
  };

  const getEquipmentItems = (id) => {
    let equipmentItems = [];
    equipmentData.equipmentItems.forEach((equipment) => {
        equipmentItems.push({...equipment, disabled: equipment.type_of_space_item_id > 0 && equipment.type_of_space_item_id != id, checked: equipment.type_of_space_item_id > 0, title: equipment.quantity_name, selected_space_item_id: id});
    });
    setEquipmentItemsList(equipmentItems);
    setHideEquipmentAssignmentDropdown(false);
  };

  const inputJSXForHeader = [
    {
      label: "l_number_of_desk",
      jsxElement: (
        <React.Fragment>
          <a href="#/"
            className={`c-font f-22 line-height-0 h32w32 with_bg action_icon`}
            onClick={() => {
              updateSpaceDetail(
                "number_of_desk",
                Number(spaceDetails.number_of_desk) - 1
              );
            }}
          >
            <Minus size={18} className="c-icons" weight="light" />
          </a>
          <input
            type="number"
            className="bg-transparent border-0 text-center line-height-1"
            name=""
            value={spaceDetails.number_of_desk}
            disabled
          />
          <a href="#/"
            className="c-font f-22 line-height-0 h32w32 with_bg action_icon"
            onClick={() => {
              updateSpaceDetail(
                "number_of_desk",
                Number(spaceDetails.number_of_desk) + 1
              );
            }}
          >
            <Plus size={18} className="c-icons" weight="light" />
          </a>
        </React.Fragment>
      ),
      noBorder: false,
    },
    {
      label: "l_superficy",
      jsxElement: (
        <React.Fragment>
          <a href="#/"
            className={`c-font f-22 line-height-0`}
            onClick={() => {
              updateSpaceDetail("superficies", Number(spaceDetails.superficies) - 1000);
            }}
          >
            <Minus size={16} className="c-icons" weight="light" />
          </a>
          <input
            type="number"
            className="bg-transparent border-0 text-center line-height-1"
            name=""
            value={spaceDetails.superficies}
            onChange={(e) => {setSpaceDetails({...spaceDetails, superficies: e.target.value})}}
            onBlur={(e) => {updateSpaceDetail("superficies", e.target.value)}}
          />
          <a href="#/"
            className="c-font f-22 line-height-0"
            onClick={() => {
              updateSpaceDetail("superficies", Number(spaceDetails.superficies) + 1000);
            }}
          >
            <Plus size={18} className="c-icons" weight="light" />
          </a>
        </React.Fragment>
      ),
      noBorder: false,
    },
    {
      label: "l_floors",
      jsxElement: (
        <React.Fragment>
          <a href="#/"
            className={`c-font f-22 line-height-0`}
            onClick={() => {
              addEditFloor(-1);
            }}
          >
            <Minus size={16} className="c-icons" weight="light" />
          </a>
          <input
            type="number"
            className="bg-transparent border-0 text-center line-height-1"
            name=""
            disabled
            value={spaceDetails.floors}
          />
          <a href="#/"
            className="c-font f-22 line-height-0"
            onClick={() => {
              addEditFloor(1);
            }}
          >
            <Plus size={18} className="c-icons" weight="light" />
          </a>
        </React.Fragment>
      ),
      noBorder: false,
    },
  ];

  const placeEquipmentToSpace = async(params) => {
    try {
      const flag = await assignEquipmentToSpace(params);
      if(flag)
      {
       if(params.selected_space_item_id > 0)
       {
        setTypeOfSpaceListing(typeOfSpaceListing.map((w) => {
          if(w.details.find((z) => z.id == params.selected_space_item_id && z.floor_id == setupScreenDetails.id))
          {
            w.count = params.checked ? (w.count + 1) : (w.count - 1);
          }
          return w;
        }));
       }
       else {
        setTypeOfSpaceListing(typeOfSpaceListing.map((w) => {
          if(w.is_default == 0)
          {
            w.count = params.checked ? (w.count + 1) : (w.count - 1);
          }
          return w;
        }));
       }
      }
    }
    catch(e)
    {
      console.error(e);
    }
  };

  const TypeOfSpaceJSX = (
    <React.Fragment>
      {selectTypeOfSpace.details &&
        selectTypeOfSpace.details.filter((w) => w.floor_id == setupScreenDetails.id).map((item, key) => {

          const badgeCount = equipmentData.equipmentItems.filter((w) => w.type_of_space_item_id == item.id).length;

          return (
            <div key={key} className="task-list-card w-100 p-0">
              <div className="w-100 p-2">
                <div class="align-items-center d-flex justify-content-between py-1">
                  <div class="c-font f-14 text-truncate w100minus100per">
                    {item.title}
                  </div>
                  <div className="space-right align-items-center d-flex">
                    {
                      badgeCount
                      ?
                      <div className="with_separator_10">
                        <span class="rounded-circle green-text badge with-bg d-flex h20w20 p-0"><span class="m-auto p-0">{badgeCount}</span></span>
                      </div>
                      :
                      <React.Fragment></React.Fragment>
                    }
                    <div className={`align-items-center d-flex space-right-action-icon ${badgeCount ? "with_separator_10 pe-0" : ""}`}>
                      <UncontrolledDropdown
                        className=""
                      >
                        <DropdownToggle
                          tag="a"
                          className=" on-hover-active-toggle-img z-index-3 before-dnone border-0"
                          onClick={() => {getEquipmentItems(item.id)}}
                        >
                          <GearSix
                            size={18}
                            className="c-icons"
                            weight="light"
                          />
                        </DropdownToggle>
                        {
                          hideEquipmentAssignmentDropdown
                          ?
                          <></>
                          :
                          <DropdownItem toggle={false} className="p-0">
                            <DropdownWithCheckbox
                              onClickHandler={placeEquipmentToSpace}
                              options={equipmentItemsList}
                              dropdownTitle={item.title}
                              onClose={() => setHideEquipmentAssignmentDropdown(true)}
                            />
                          </DropdownItem>
                        }
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </React.Fragment>
  );

  const getPlacedEquipments = (floorId) => {

    let floorSpaceMapping = {};

    type_of_space.forEach((space) => {
      space.details.forEach((spaceItem) => {
        floorSpaceMapping = {...floorSpaceMapping, [spaceItem.floor_id]: floorSpaceMapping[spaceItem.floor_id] && floorSpaceMapping[spaceItem.floor_id].length ? floorSpaceMapping[spaceItem.floor_id].concat(spaceItem.id) : [spaceItem.id]};
      });
    })

    if(!floorSpaceMapping[floorId])
    {
      return;
    }
    
    let equipments;

    if(equipmentData.equipmentItems && equipmentData.equipmentItems.length)
    {
      let spaceItems = floorSpaceMapping[floorId];
      let totalEquipmentQuantities = Number(equipmentData.quantity);
      let placedEquipments = equipmentData.equipmentItems.filter((w) => spaceItems.includes(w.type_of_space_item_id)).length;
      if(totalEquipmentQuantities > 0 && placedEquipments)
      {
        equipments = `${placedEquipments}/${totalEquipmentQuantities}`;
      }
    }

    return equipments;
  }

  useEffect(() => {
    if(!showSetupScreen)
    {
      setSelectTypeOfSpace({});
    }
  }, [showSetupScreen])
  

  return (
    <React.Fragment>
      {setupScreenDetails.value > 0 && showSetupScreen ? (
        <SetupScreen
          headerText={setupScreenDetails.label}
          leftListingData={typeOfSpaceListing}
          rightPortionJSX={TypeOfSpaceJSX}
          handleClose={handleCloseSetupScreen}
          handleSelectListingElement={setSelectTypeOfSpace}
          activeRecord={selectTypeOfSpace}
          allocation={getPlacedEquipments(setupScreenDetails.id)}
          tag={equipmentData.title}
        />
      ) : (
        <React.Fragment>
          <ListWithAction
            dataList={floor.map((floor) => {
              return {
                ...floor,
                label: floor.title,
                value: floor.id,
                btnText: "l_setup_equipment",
                allocation: getPlacedEquipments(floor.id),
                tag: equipmentData.title
              };
            })}
            onClick={selectFloorForSetup}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EquipmentAssignment;
